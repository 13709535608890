import React, { Suspense } from 'react';

import Box from '../common/Box';
import CT290_420S from './CT290_420S';
import F34_4Way from './F34_4Way';
import VerticalBeam from './VerticalBeam';

export default function Stack(props) {
  return (
    <group position={props.position} rotation={props.rotation}>
      <Suspense fallback={<Box />}>
        <CT290_420S
          position={[0, 225, 0]}
          rotation={[0, 0, Math.PI / 2]}
          color={props.color}
          opacity={props.opacity}
          transparent={props.transparent}
        />
      </Suspense>
      <Suspense fallback={<Box />}>
        <F34_4Way
          position={[0, 225 + 25, 0]}
          rotation={[0, 0, 0]}
          color={props.color}
          opacity={props.opacity}
          transparent={props.transparent}
        />
      </Suspense>
      <VerticalBeam
        position={[0, 225 + 25 + 25, 0]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />
      <Suspense fallback={<Box />}>
        <F34_4Way
          position={[0, 225 + 25 + 25 + 350, 0]}
          rotation={[0, 0, 0]}
          color={props.color}
          opacity={props.opacity}
          transparent={props.transparent}
        />
      </Suspense>
      <VerticalBeam
        position={[0, 275 + 350 + 25, 0]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />
      <Suspense fallback={<Box />}>
        <F34_4Way
          position={[0, 225 + 25 + 25 + 350 + 25 + 350, 0]}
          rotation={[0, 0, 0]}
          color={props.color}
          opacity={props.opacity}
          transparent={props.transparent}
        />
      </Suspense>
      <VerticalBeam
        position={[0, 275 + 350 + 25 + 350 + 25, 0]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />
      <Suspense fallback={<Box />}>
        <F34_4Way
          position={[0, 225 + 25 + 25 + 350 + 25 + 350 + 25 + 350, 0]}
          rotation={[0, 0, 0]}
          color={props.color}
          opacity={props.opacity}
          transparent={props.transparent}
        />
      </Suspense>
    </group>
  );
}

Stack.defaultProps = {
  position: [0, 0, 0],
  rotation: [0, 0, 0],
};
