import { CloudProduceAddress } from 'model/src/common/CloudProduceAddress';
import {
  isFishTank,
  isFloorNode,
  isStack,
  isSuperBeacon,
  isTier,
  isTower,
  isTug,
  toAddress,
} from 'model/src/common/CloudProduceAddressUtility';
import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';

import {
  DesktopContext,
  DesktopContextType,
} from '../../three/farmviewer/context/desktopcontext/DesktopContext';
import UiButton from '../../utopia/desktop/ui-buttons/UiButton';
import UiChevronUpIcon from '../../utopia/desktop/ui-icons/UiChevronUpIcon';
import UiLocationIcon from '../../utopia/desktop/ui-icons/UiLocationIcon';

export type ContextualInfoBarProps = {
  toggleWindow: (windowType: string) => void;
  isOpen: (windowType: string) => boolean;
};

const Bar = styled.div`
  pointer-events: auto;
  position: absolute;
  display: inline-grid;
  column-gap: var(--spacingSmall);
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-template-rows: 1;
  left: var(--spacingExtraLarge);
  bottom: var(--spacingExtraLarge);
  background-color: var(--tabBarBackground_Default);
  border-radius: var(--controlBarBorderRadius);
`;

const ContextualInfoBarContainer = props => {
  return (
    <Bar>
      {props.children}
      <UiButton>
        <UiChevronUpIcon key="open" />
      </UiButton>
    </Bar>
  );
};

const ContextualInfoBar = props => {
  const desktopContext: DesktopContextType = useContext(DesktopContext);
  const address: CloudProduceAddress = useMemo(() => {
    return toAddress(desktopContext.selectedUrl);
  }, [desktopContext.selectedUrl]);
  return (
    <ContextualInfoBarContainer>
      {
        <>
          <UiButton text="Docked">
            <UiLocationIcon />
          </UiButton>
        </>
      }
      {/* TODO(austin): implement bar for each URL structure */}
      {isFishTank(address) && <></>}
      {isFloorNode(address) && <></>}
      {isStack(address) && <></>}
      {isSuperBeacon(address) && <></>}
      {isTier(address) && <></>}
      {isTower(address) && <></>}
      {isTug(address) && <></>}
    </ContextualInfoBarContainer>
  );
};

export default ContextualInfoBar;
