import React, { PropsWithChildren, useState } from 'react';

import { OperationContext, OperationContextType } from './OperationContext';
import {
  AlignmentOperation,
  OperationType,
  PlacementOperation,
} from './OperationContexts';

export type OperationContextProviderProps = {
  // url: string;
  // operationType: OperationType;
  // operation: PlacementOperation | AlignmentOperation | undefined;
};

export default function OperationContextProvider(
  props: PropsWithChildren<OperationContextProviderProps>
) {
  const setPlacementOperation = (placementOperation, currentContextValue) => {
    setContextValue({
      ...currentContextValue,
      operationType: OperationType.PLACEMENT,
      placementOperation: placementOperation,
    });
  };

  const setAlignmentOperation = (
    alignmentOperation: AlignmentOperation,
    currentContextValue: OperationContextType
  ) => {
    setContextValue({
      ...currentContextValue,
      operationType: OperationType.ALIGNMENT,
      alignmentOperation: alignmentOperation,
    });
  };

  const clearCurrentOperation = currentContextValue => {
    if (currentContextValue.placementOperation) {
      setContextValue({
        ...currentContextValue,
        operationType: OperationType.NONE,
        placementOperation: undefined,
      });
    }
    if (currentContextValue.alignmentOperation) {
      setContextValue({
        ...currentContextValue,
        operationType: OperationType.NONE,
        placementOperation: undefined,
      });
    }
    // add clearing for other operations here
  };

  const updateOperation = (operation, currentContextValue) => {
    setContextValue({
      ...currentContextValue,
      operation: operation,
    });
  };

  const updateContext = (
    newContextValue: OperationContextType,
    currentContextValue: OperationContextType
  ) => {
    setContextValue({
      ...currentContextValue,
      ...newContextValue,
    });
  };

  const setContext = (contextValue: OperationContextType) => {
    setContextValue({
      ...contextValue,
    });
  };

  const [contextValue, setContextValue] = useState<OperationContextType>({
    operationType: OperationType.NONE,
    updateContext: updateContext,
    updateOperation: updateOperation,
    setContext: setContext,
    setAlignmentOperation: setAlignmentOperation,
    setPlacementOperation: setPlacementOperation,
    clearCurrentOperation: clearCurrentOperation,
  });
  return (
    <OperationContext.Provider value={contextValue}>
      {props.children}
    </OperationContext.Provider>
  );
}
