"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DrivetrainControlMode = void 0;
var DrivetrainControlMode;

(function (DrivetrainControlMode) {
  DrivetrainControlMode["Manual"] = "Manual";
  DrivetrainControlMode["LineFollowing"] = "LineFollowing";
  DrivetrainControlMode["Autonomous"] = "Autonomous";
  DrivetrainControlMode["Lifting"] = "Lifting";
  DrivetrainControlMode["BottomLineCentering"] = "BottomLineCentering";
  DrivetrainControlMode["TopLineCentering"] = "TopLineCentering";
  DrivetrainControlMode["MagneticAlignmentCentering"] = "MagneticAlignmentCentering";
})(DrivetrainControlMode = exports.DrivetrainControlMode || (exports.DrivetrainControlMode = {}));