import React, { forwardRef, PropsWithChildren } from 'react';
import Draggable from 'react-draggable';

import ClassNames from '../../ClassNames';
import { HeaderText } from './components/HeaderText';
import WindowContainer from './components/WindowContainer';
import WindowContent from './components/WindowContent';
import WindowDragHandle from './components/WindowDragHandle';
import {
  HeaderButtonType,
  WindowHeaderButton
} from './components/WindowHeaderButton';

export type DraggableWindowProps = {
  className?: string;
  url?: string;
  windowType?: string;
  title: string;
  onDrag: any;
  onClick: () => void;
  onDragStart: () => void;
  onDragEnd?: () => void;
  onMouseDown: () => void;
  initialWindowPosition: any;
  headerButtons: HeaderButtonType[];
  headerButtonOnClicks: (() => void)[];
  minHeight: string;
  minWidth: string;
  selected: boolean;
};

const DraggableWindow = (props: PropsWithChildren<DraggableWindowProps>) => {
  const headerButtons: any[] = [];
  for (let i = 0; i < props.headerButtons.length; i++) {
    headerButtons.push(
      <WindowHeaderButton
        key={i}
        buttonType={props.headerButtons[i]}
        onClick={props.headerButtonOnClicks[i]}
      />
    );
  }

  const defaultPosition =
    props.initialWindowPosition.x && props.initialWindowPosition.y
      ? props.initialWindowPosition
      : undefined;

  return (
    <Draggable
      bounds="parent"
      key={props.title}
      axis="both"
      handle=".handle"
      defaultPosition={defaultPosition}
      scale={1}
      onDrag={props.onDrag}
      onStop={props.onDragEnd}
      onStart={props.onDragStart}>
      <WindowContainer initialWindowPosition={props.initialWindowPosition}>
        <WindowDragHandle onClick={props.onClick} selected={props.selected}>
          <HeaderText className={ClassNames.handle}>{props.title}</HeaderText>
          <div
            onClick={e => {
              e.stopPropagation();
            }}>
            {headerButtons}
          </div>
        </WindowDragHandle>
        <WindowContent onClick={props.onClick} onMouseDown={props.onMouseDown}>
          {props.children}
        </WindowContent>
      </WindowContainer>
    </Draggable>
  );
};

export default DraggableWindow;

DraggableWindow.defaultProps = {
  title: 'window title',
  onDrag: null,
  initialWindowPosition: { x: 0, y: 0 },
  headerButtons: [HeaderButtonType.CLOSE],
  headerButtonOnClicks: [() => {}],
  minHeight: '200px',
  minWidth: '200px',
  onClick: () => {},
  selected: false,
};
