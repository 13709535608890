import { toHexString, toRgbaString } from 'model/src/common/Color';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { Button } from '../form/Button';
import { THEME } from './Theme';

type GlassPaneProps = {
  right?: number;
  left?: number;
  bottom?: number;
  top?: number;
  width?: number;
  height?: number;
  center?: boolean;
  canClose: boolean;
  onClose?: () => void;
};

const CloseButton = styled(Button)`
  position: absolute;
  right: 0px;
  top: 0px;
`;

const centerCss = `
  margin: auto;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

const GlassPaneInner = styled.div`
  color: ${toHexString(THEME.blues[0])};
  position: absolute;
  backdrop-filter: blur(${THEME.minorGridDimension}px);
  background-color: rgba(0, 0, 0, 0.3);
  width: ${props => (props.width ? props.width + 'px' : undefined)};
  height: ${props => (props.height ? props.height + 'px' : undefined)};
  left: ${props => (props.left ? props.left + 'px' : undefined)};
  right: ${props => (props.right ? props.right + 'px' : undefined)};
  top: ${props => (props.top ? props.top + 'px' : undefined)};
  bottom: ${props => (props.bottom ? props.bottom + 'px' : undefined)};
  ${props => (props.center ? centerCss : '')}
  border-radius: ${THEME.minorGridDimension}px;
  border-style: solid;
  border-width: 1px;
  border-color: ${toHexString(THEME.blues[0])};
  padding: ${THEME.standardGridDimension + 'px'};
`;

const GlassPane = (props: PropsWithChildren<GlassPaneProps>) => {
  return (
    <GlassPaneInner {...props}>
      <CloseButton
        color={toRgbaString(THEME.blues[0])}
        disabledColor={toRgbaString(THEME.blues[5])}
        showBorder={false}
        onClick={() => {
          if (props.onClose) {
            props.onClose();
          }
        }}
      />
      {props.children}
    </GlassPaneInner>
  );
};

export default GlassPane;
