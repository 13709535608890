import styled from 'styled-components';
import React, { Component } from 'react';
import { toHexString } from 'model/src/common/Color';
import { THEME } from '../globe/Theme';
import { DebounceInput } from 'react-debounce-input';

type Props = {
  label: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  readOnly: boolean;
  debounce: boolean;
  minLength: number;
};

const TextInput = styled.input`
  width: 100%;
  padding: 0px 0px;
  margin: 0 0;
  display: inline-block;
  border: 0px;
  border-bottom: 1px solid ${toHexString(THEME.blues[0])};
  outline: 0px;
  box-sizing: border-box;
  background-color: transparent;
  color: ${toHexString(THEME.blues[0])};
`;

const StyledDebounceInput = styled(DebounceInput)`
  width: 100%;
  padding: 0px 0px;
  margin: 0 0;
  display: inline-block;
  border: 0px;
  border-bottom: 1px solid ${toHexString(THEME.blues[0])};
  outline: 0px;
  box-sizing: border-box;
  background-color: transparent;
  color: ${toHexString(THEME.blues[0])};
`;

class FormItem extends Component<Props, {}> {
  render() {
    return (
      <div>
        <label>{this.props.label}</label>
        {this.props.debounce ? (
          <StyledDebounceInput
            type="text"
            defaultValue={this.props.value}
            onChange={this.props.onChange ? this.props.onChange : () => {}}
            placeholder={this.props.placeholder}
            minLength={this.props.minLength}
            debounceTimeout={1000}
          />
        ) : (
          <TextInput
            type="text"
            defaultValue={this.props.value}
            onChange={this.props.onChange}
            placeholder={this.props.placeholder}
            readOnly={this.props.readOnly}
          />
        )}
      </div>
    );
  }
}

export default FormItem;
