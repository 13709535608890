import React from 'react';
import styled from 'styled-components';

const Path = styled.path`
  fill: ${props => props.fillColour};
`;
const Svg = styled.svg`
  padding: calc(var(--switchIconHeight) * 0.25) 0px 0px
    calc(var(--switchIconHeight) * 0.25);

  height: var(--switchIconHeight);
  width: var(--switchIconWidth);
  stroke: ${props => props.fillColour};
  fill: ${props => props.fillColour};
`;

const Close = props => {
  return (
    <Svg
      fillColour={props.fillColour}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <Path
        fillColour={props.fillColour}
        d="M12 4.7L11.3 4L8 7.3L4.7 4L4 4.7L7.3 8L4 11.3L4.7 12L8 8.7L11.3 12L12 11.3L8.7 8L12 4.7Z"
      />
    </Svg>
  );
};

export default Close;
