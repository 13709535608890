import React from 'react';
import styled from 'styled-components';

import { Path } from './IconStyling';

const Svg = styled.svg`
  padding: 2.5px 0px 0px 0px;
  align: center;
  float: left;
  height: var(--controlBarIconHeight);
  width: var(--controlBarIconWidth);
`;

const UiLocationIcon = () => {
  return (
    <Svg fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.67338 15.0385L12 22.5L18.3293 15.0354C18.3628 14.9956 18.5878 14.6999 18.5878 14.6999L18.5889 14.698C19.6692 13.2749 20.2527 11.5367 20.25 9.75C20.25 7.56196 19.3808 5.46354 17.8336 3.91637C16.2865 2.36919 14.188 1.5 12 1.5C9.81197 1.5 7.71355 2.36919 6.16638 3.91637C4.6192 5.46354 3.75001 7.56196 3.75001 9.75C3.74748 11.5374 4.33141 13.2763 5.41223 14.6999C5.41223 14.6999 5.63746 14.9957 5.67338 15.0385ZM6.64541 13.8435C6.62401 13.8154 6.6096 13.7964 6.60938 13.7963C5.72587 12.6325 5.24835 11.2111 5.25001 9.75C5.25001 7.95979 5.96117 6.2429 7.22704 4.97703C8.49291 3.71116 10.2098 3 12 3C13.7902 3 15.5071 3.71116 16.773 4.97703C18.0388 6.2429 18.75 7.95979 18.75 9.75C18.7517 11.2103 18.2746 12.631 17.3916 13.7942C17.3912 13.7948 17.2154 14.027 17.1825 14.0684L12 20.1809L6.82433 14.077C6.79584 14.0416 6.69884 13.9139 6.64541 13.8435ZM9.91661 12.868C10.5333 13.2801 11.2583 13.5 12 13.5C12.9942 13.4989 13.9474 13.1034 14.6504 12.4004C15.3534 11.6974 15.7489 10.7442 15.75 9.75C15.75 9.00832 15.5301 8.2833 15.118 7.66661C14.706 7.04993 14.1203 6.56928 13.4351 6.28545C12.7498 6.00162 11.9958 5.92736 11.2684 6.07206C10.541 6.21675 9.8728 6.5739 9.34835 7.09835C8.8239 7.6228 8.46675 8.29098 8.32206 9.01841C8.17736 9.74584 8.25162 10.4998 8.53545 11.1851C8.81928 11.8703 9.29993 12.456 9.91661 12.868ZM10.75 7.8792C11.12 7.63196 11.555 7.5 12 7.5C12.5965 7.50066 13.1685 7.73792 13.5903 8.15974C14.0121 8.58155 14.2493 9.15347 14.25 9.75C14.25 10.195 14.118 10.63 13.8708 11C13.6236 11.37 13.2722 11.6584 12.861 11.8287C12.4499 11.999 11.9975 12.0436 11.561 11.9568C11.1246 11.87 10.7237 11.6557 10.409 11.341C10.0943 11.0263 9.88005 10.6254 9.79323 10.189C9.70642 9.7525 9.75098 9.3001 9.92127 8.88896C10.0916 8.47783 10.38 8.12643 10.75 7.8792Z"
        fill="white"
      />
    </Svg>
  );
};

export default UiLocationIcon;
