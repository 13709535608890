import React from 'react';
import { Point, PointId } from 'model/src/common/Point';
import { MINOR_GRID_DIMENSION } from './GridSizing';
import { Color } from 'model/src/common/Color';
import PaletteSwatch, { PaletteSwatchOptions } from './PaletteSwatch';

export type PaletteOptions = {
  colors: Color[];
  location: Point;
};

type Props = {
  options: PaletteOptions;
};

const swatchDimension = MINOR_GRID_DIMENSION;
function Palette(props: Props) {
  const paletteSwatches: any[] = [];
  let i = 0;
  props.options.colors.forEach((color: Color) => {
    const options: PaletteSwatchOptions = {
      swatchColor: color,
      swatchDimension: swatchDimension,
      location: {
        id: PointId.degenerate(),
        x: props.options.location.x,
        y: props.options.location.y + i * MINOR_GRID_DIMENSION,
      },
    };
    i++;
    paletteSwatches.push(
      <PaletteSwatch
        key={color.r + '_' + color.g + '_' + color.b}
        options={options}
      />
    );
  });
  return <>{paletteSwatches}</>;
}

export default Palette;
