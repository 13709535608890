import React from 'react';
import styled from 'styled-components';

import { Path } from './IconStyling';

const Svg = styled.svg`
  height: var(--controlBarIconContainerHeight);
  width: var(--controlBarIconContainerWidth);
`;

const UiNotificationsIcon = () => {
  return (
    <Svg fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path d="M25.5303 18.4697L23.5 16.4394V13.75C23.4976 11.8915 22.8061 10.0998 21.5593 8.72154C20.3126 7.34326 18.599 6.47622 16.75 6.2881V4.75H15.25V6.2881C13.401 6.47622 11.6874 7.34326 10.4407 8.72154C9.19386 10.0998 8.50241 11.8915 8.5 13.75V16.4394L6.46968 18.4697C6.32904 18.6104 6.25002 18.8011 6.25 19V21.25C6.25 21.4489 6.32902 21.6397 6.46967 21.7803C6.61032 21.921 6.80109 22 7 22H12.25V22.5826C12.2337 23.534 12.5691 24.458 13.1919 25.1775C13.8147 25.897 14.6811 26.3613 15.625 26.4816C16.1464 26.5334 16.6728 26.4754 17.1704 26.3115C17.668 26.1475 18.1258 25.8813 18.5143 25.5298C18.9029 25.1783 19.2135 24.7494 19.4263 24.2707C19.6391 23.7919 19.7494 23.2739 19.75 22.75V22H25C25.1989 22 25.3897 21.921 25.5303 21.7803C25.671 21.6397 25.75 21.4489 25.75 21.25V19C25.75 18.8011 25.671 18.6104 25.5303 18.4697ZM18.25 22.75C18.25 23.3467 18.0129 23.919 17.591 24.341C17.169 24.7629 16.5967 25 16 25C15.4033 25 14.831 24.7629 14.409 24.341C13.9871 23.919 13.75 23.3467 13.75 22.75V22H18.25V22.75ZM24.25 20.5H7.75V19.3106L9.78025 17.2802C9.92091 17.1396 9.99996 16.9489 10 16.75V13.75C10 12.1587 10.6321 10.6326 11.7574 9.50736C12.8826 8.38214 14.4087 7.75 16 7.75C17.5913 7.75 19.1174 8.38214 20.2426 9.50736C21.3679 10.6326 22 12.1587 22 13.75V16.75C22 16.9489 22.0791 17.1396 22.2197 17.2802L24.25 19.3106V20.5Z" />
    </Svg>
  );
};

export default UiNotificationsIcon;
