"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FarmServerToFarmType = void 0;
var FarmServerToFarmType;

(function (FarmServerToFarmType) {
  FarmServerToFarmType["AcOffCommand"] = "AcOffCommand";
  FarmServerToFarmType["AcOnCommand"] = "AcOnCommand";
  FarmServerToFarmType["BooleanUpdate"] = "BooleanUpdate";
  FarmServerToFarmType["ChangeLightLevelCommand"] = "ChangeLightLevelCommand";
  FarmServerToFarmType["CloseConnectionRequest"] = "CloseConnectionRequest";
  FarmServerToFarmType["CommandChangeValveState"] = "CommandChangeValveState";
  FarmServerToFarmType["CommandLineCommand"] = "CommandLineCommand";
  FarmServerToFarmType["DoorCloseCommand"] = "DoorCloseCommand";
  FarmServerToFarmType["DoorOpenCommand"] = "DoorOpenCommand";
  FarmServerToFarmType["DockCommand"] = "DockCommand";
  FarmServerToFarmType["DrainCloseCommand"] = "DrainCloseCommand";
  FarmServerToFarmType["DrainOpenCommand"] = "DrainOpenCommand";
  FarmServerToFarmType["UndockCommand"] = "UndockCommand";
  FarmServerToFarmType["DriveCommand"] = "DriveCommand";
  FarmServerToFarmType["DriveCommand2"] = "DriveCommand2";
  FarmServerToFarmType["EmptyTowerCommand"] = "EmptyTowerCommand";
  FarmServerToFarmType["FansOffCommand"] = "FansOffCommand";
  FarmServerToFarmType["FansOnCommand"] = "FansOnCommand";
  FarmServerToFarmType["FillTowerCommand"] = "FillTowerCommand";
  FarmServerToFarmType["GantryFindFarXCommand"] = "GantryFindFarXCommand";
  FarmServerToFarmType["GantryFindFarYCommand"] = "GantryFindFarYCommand";
  FarmServerToFarmType["GantryFindFarZCommand"] = "GantryFindFarZCommand";
  FarmServerToFarmType["GantryGoToCommand"] = "GantryGoToCommand";
  FarmServerToFarmType["GantryHardStopCommand"] = "GantryHardStopCommand";
  FarmServerToFarmType["GantryHomeCommand"] = "GantryHomeCommand";
  FarmServerToFarmType["GantryMoveByCommand"] = "GantryMoveByCommand";
  FarmServerToFarmType["GantryStopCommand"] = "GantryStopCommand";
  FarmServerToFarmType["GetExperimentsResponse"] = "GetExperimentsResponse";
  FarmServerToFarmType["InitializeCommand"] = "InitializeCommand";
  FarmServerToFarmType["LiftCommand"] = "LiftCommand";
  FarmServerToFarmType["LightsOffCommand"] = "LightsOffCommand";
  FarmServerToFarmType["LightsOnCommand"] = "LightsOnCommand";
  FarmServerToFarmType["MessageRequest"] = "MessageRequest";
  FarmServerToFarmType["NavigateCommand"] = "NavigateCommand";
  FarmServerToFarmType["Pong"] = "Pong";
  FarmServerToFarmType["PumpOffCommand"] = "PumpOffCommand";
  FarmServerToFarmType["PumpOnCommand"] = "PumpOnCommand";
  FarmServerToFarmType["PumpAutoCommand"] = "PumpAutoCommand";
  FarmServerToFarmType["PumpToEmptyCommand"] = "PumpToEmptyCommand";
  FarmServerToFarmType["RebootCommand"] = "RebootCommand";
  FarmServerToFarmType["ReturnFarmData"] = "ReturnFarmData";
  FarmServerToFarmType["ResetDeadReckoningCommand"] = "ResetDeadReckoningCommand";
  FarmServerToFarmType["ResetFlowInCommand"] = "ResetFlowInCommand";
  FarmServerToFarmType["ResetFlowOutCommand"] = "ResetFlowOutCommand";
  FarmServerToFarmType["ShutDownCommand"] = "ShutDownCommand";
  FarmServerToFarmType["UmbilicalGantryHomeCommand"] = "UmbilicalGantryHomeCommand";
  FarmServerToFarmType["UpdateFirmwareCommand"] = "UpdateFirmwareCommand";
  FarmServerToFarmType["ValveCloseCommand"] = "ValveCloseCommand";
  FarmServerToFarmType["ValveOpenCommand"] = "ValveOpenCommand";
  FarmServerToFarmType["WhoAreYouRequest"] = "WhoAreYouRequest";
  FarmServerToFarmType["WhoAmIResponse"] = "WhoAmIResponse";
})(FarmServerToFarmType = exports.FarmServerToFarmType || (exports.FarmServerToFarmType = {}));