import { BuiltinType } from 'model/src/typescript/Typescript';
import styled from 'styled-components';

export const Background = styled.div`
  height: var(--controlBarIconContainerHeight);
  cursor: pointer;
  ${props =>
    typeof props.selected == BuiltinType.UNDEFINED
      ? `
    color: var(--controlBarIcon_Default);
    background-color: transparent;
  `
      : props.selected == false
      ? `
    color: var(--controlBarIcon_Default);
    background-color: transparent;
  `
      : `
    color: var(--controlBarIcon_Active);
    background-color: var(--controlBarIconBackground_Active);
  `}
  height: var(--controlBarIconContainerHeight);
  margin: 4px;
  border-radius: var(--controlBarIconContainerBorderRadius);

  &:hover {
    color: var(--controlBarIcon_Hover);
    background-color: var(--controlBarIconBackground_Hover);
  }

  &:active {
    color: var(--controlBarIcon_Active);
    background-color: var(--controlBarIconBackground_Active);
  }

  &:disabled {
    color: var(--controlBarIcon_Disabled);
    background-color: transparent;
  }
`;
