import { BuiltinType } from 'model/src/typescript/Typescript';
import React from 'react';
import * as THREE from 'three';

import { cssVar } from '../../..';

export class MaterialFactory {
  generateMaterial(
    parentUnderInspection: boolean,
    parentHovered: boolean,
    transparent?: boolean,
    opacity?: number,
    online?: boolean
  ): any {
    return (
      <meshPhongMaterial
        attach="material"
        color={this.chooseColor(parentUnderInspection, parentHovered, online)}
        opacity={opacity !== undefined ? opacity : 1.0}
        transparent={transparent !== undefined ? transparent : false}
      />
    );
  }

  generateMaterialObject(
    parentUnderInspection: boolean,
    parentHovered: boolean,
    online?: boolean
  ): THREE.Material {
    return new THREE.MeshPhongMaterial({
      color: this.chooseColor(parentUnderInspection, parentHovered, online),
    });
  }

  private chooseColor(parentUnderInspection, parentHovered, online?) {
    if (parentUnderInspection) {
      return cssVar('--selected-color');
    } else if (parentHovered) {
      return cssVar('--hovered-color');
    } else if (typeof online === BuiltinType.BOOLEAN && !online) {
      return cssVar('--systemRed');
    } else {
      return cssVar('--standard-color');
    }
  }
}

const materialFactory: MaterialFactory = new MaterialFactory();
export default materialFactory;
