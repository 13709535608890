"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.JAVASCRIPT_MINIMUM_EVENT_LOOP_TIME_MS = exports.BuiltinType = void 0;
var BuiltinType;

(function (BuiltinType) {
  BuiltinType["BIGINT"] = "bigint";
  BuiltinType["BOOLEAN"] = "boolean";
  BuiltinType["FUNCTION"] = "function";
  BuiltinType["NUMBER"] = "number";
  BuiltinType["OBJECT"] = "object";
  BuiltinType["STRING"] = "string";
  BuiltinType["SYMBOL"] = "symbol";
  BuiltinType["UNDEFINED"] = "undefined";
})(BuiltinType = exports.BuiltinType || (exports.BuiltinType = {}));

exports.JAVASCRIPT_MINIMUM_EVENT_LOOP_TIME_MS = 5;