import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  position: relative;
  float: right;
  width: var(--dropdownMenuItemIconWidth);
  height: var(--dropdownMenuItemIconHeight);
`;

const Path = styled.path`
  fill: var(--dropdownMenuItemIcon_Default);

  &:hover {
    fill: var(--dropdownMenuItemIcon_Hover);
  }

  &:active {
    fill: var(--dropdownMenuItemIcon_Active);
  }
`;

const ChevronRightIcon = () => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg">
      <Path d="M11.0008 8.5L6.00078 13.5L5.30078 12.8L9.60078 8.5L5.30078 4.2L6.00078 3.5L11.0008 8.5Z" />
    </Svg>
  );
};

export default ChevronRightIcon;
