import { useEffect, useState } from 'react';
import * as THREE from 'three';
import { SVGLoader, SVGResult } from 'three-stdlib';

import { CSG } from '@enable3d/three-graphics/jsm/csg';

export default function useSvg(
  url: string,
  depth: number,
  transformation: (mesh: THREE.Mesh) => void,
  dependencies: any[]
) {
  const [svgMesh, setSvgMesh] = useState<THREE.Mesh>(null);

  useEffect(() => {
    async function loadSvgs(): Promise<void> {
      const mesh = await loadSvgAsMesh(url, depth);
      transformation(mesh);
      setSvgMesh(mesh);
    }
    loadSvgs();
  }, [url, depth, ...dependencies]);

  return svgMesh;
}

async function loadSvgAsMesh(
  svgUrl: string,
  depth: number
): Promise<THREE.Mesh> {
  const loader = new SVGLoader();

  const data: SVGResult = await loader.loadAsync(svgUrl);
  const allShapes: THREE.Shape[] = [];
  data.paths.forEach(path => {
    const shapes = SVGLoader.createShapes(path);
    allShapes.push(...shapes);
  });
  let existingMesh: THREE.Mesh = null;
  allShapes.forEach(shape => {
    const meshGeometry = new THREE.ExtrudeBufferGeometry(shape, {
      depth: depth,
      bevelEnabled: false,
    });
    const mesh = new THREE.Mesh(
      meshGeometry,
      new THREE.MeshPhongMaterial({ color: 0xffff00 })
    );
    if (!existingMesh) {
      existingMesh = mesh;
    } else {
      existingMesh = CSG.union(existingMesh, mesh);
    }
  });
  return existingMesh;
}
