"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PageServerToPageType = void 0;
var PageServerToPageType;

(function (PageServerToPageType) {
  PageServerToPageType["AcStateDatum"] = "AcStateDatum";
  PageServerToPageType["AlarmLevelDatum"] = "AlarmLevelDatum";
  PageServerToPageType["AutonomyStatusDatum"] = "AutonomyStatusDatum";
  PageServerToPageType["BooleanDatum"] = "BooleanDatum";
  PageServerToPageType["CenterStatusDatum"] = "CenterStatusDatum";
  PageServerToPageType["CloseConnectionRequest"] = "CloseConnectionRequest";
  PageServerToPageType["ComputedPositionDatum"] = "ComputedPositionDatum";
  PageServerToPageType["CurrentNavigationalIntentDatum"] = "CurrentNavigationalIntentDatum";
  PageServerToPageType["DeadReckoningDatum"] = "DeadReckoningDatum";
  PageServerToPageType["DeadReckoningPositionDatum"] = "DeadReckoningPositionDatum";
  PageServerToPageType["DockStatusDatum"] = "DockStatusDatum";
  PageServerToPageType["DrivetrainControlModeDatum"] = "DrivetrainControlModeDatum";
  PageServerToPageType["DrivetrainControlSignalDatum"] = "DrivetrainControlSignalDatum";
  PageServerToPageType["FanStateDatum"] = "FanStateDatum";
  PageServerToPageType["GantryStateDatum"] = "GantryStateDatum";
  PageServerToPageType["HighWaterStateDatum"] = "HighWaterStateDatum";
  PageServerToPageType["LiftStatusDatum"] = "LiftStatusDatum";
  PageServerToPageType["LineDatum"] = "LineDatum";
  PageServerToPageType["NumericalDatum"] = "NumericalDatum";
  PageServerToPageType["OccupantDatum"] = "OccupantDatum";
  PageServerToPageType["OverflowDangerStateDatum"] = "OverflowDangerStateDatum";
  PageServerToPageType["PathChanged"] = "PathChanged";
  PageServerToPageType["Ping"] = "Ping";
  PageServerToPageType["Pong"] = "Pong";
  PageServerToPageType["PumpStatusDatum"] = "PumpStatusDatum";
  PageServerToPageType["ReturnFarmData"] = "ReturnFarmData";
  PageServerToPageType["ReturnTimerTemplateData"] = "ReturnTimerTemplateData";
  PageServerToPageType["ReturnUserProfileData"] = "ReturnUserProfileData";
  PageServerToPageType["SimpleMessage"] = "SimpleMessage";
  PageServerToPageType["StringDatum"] = "StringDatum";
  PageServerToPageType["TimerTemplateUpdateRecord"] = "TimerTemplateUpdateRecord";
  PageServerToPageType["ValveStateDatum"] = "ValveStateDatum";
})(PageServerToPageType = exports.PageServerToPageType || (exports.PageServerToPageType = {}));