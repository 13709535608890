import React from 'react';
import styled from 'styled-components';

import ClassNames from '../../ClassNames';
import DataTree from '../../utopia/tree-view/DataTree';
import { HeaderButtonType } from '../../utopia/window/components/WindowHeaderButton';
import DraggableWindow from '../../utopia/window/DraggableWindow';
import WindowType from '../../utopia/window/WindowTypes';

const Content = styled.div`
  width: min(var(--treeWindowMaxWidth), var(--treeWindowMinWidth));
  height: min(var(--treeWindowMaxHeight), var(--treeWindowMinHeight));
  border-radius: var(--windowBottomBorderRadius);
  color: var(--black);
  background-color: var(--white);
  overflow: scroll;
`;

const TreeWindow = props => {
  return (
    <DraggableWindow
      title={'Data Tree'}
      onDrag={props.onDrag}
      onDragEnd={props.onDragEnd}
      initialWindowPosition={props.initialWindowPosition}
      headerButtons={[HeaderButtonType.CLOSE]}
      headerButtonOnClicks={[() => props.closeHandler()]}
      onClick={props.onClick}
      onDragStart={props.onDragStart}
      onMouseDown={props.onMouseDown}
      selected={props.selected}
      windowType={WindowType.TREE}>
      <Content className={ClassNames.title4} key={WindowType.TREE}>
        <DataTree dataMap={props.dataMap} />
      </Content>
    </DraggableWindow>
  );
};

export default TreeWindow;
