import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { cssVar } from '../..';
import ClassNames from '../../ClassNames';
import InspectionDataDivider from '../dividers/InspectionDataDivider';

export type OnlineStatusProps = {
  timeSince: number;
  online: boolean;
  ipAddress: string;
  firmwareVersion: string;
  firmwareVersionUpdate: string;
  url: string;
};

const DAY_CONVERSION = 24 * 60 * 60 * 1000;
const HOUR_CONVERSION = 60 * 60 * 1000;
const MINUTE_CONVERSION = 60 * 1000;
const SECOND_CONVERSION = 1000;
const START = '00:00:00:00';

const OnlineLabelContainer = styled.div`
  padding: var(--windowDividerPadding);
  color: var(--white);
  background-color: ${props =>
    props.online ? 'var(--systemGreen)' : 'var(--systemRed)'};
  text-align: center;
`;
const KeyFormatting = styled.div`
  float: left;
`;
const Key = props => (
  <KeyFormatting className={ClassNames.labelRegular}>
    {props.children}
  </KeyFormatting>
);

const ValueFormatting = styled.div`
  float: right;
`;
const Value = props => (
  <ValueFormatting className={ClassNames.labelMedium}>
    {props.children}
  </ValueFormatting>
);

const DataContainer = styled.div`
  color: var(--black);
  padding: var(--windowPadding);
  padding-bottom: 0;
`;

const DatumContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between,
  align-items: center,
`;

const FloatBar = () => {
  return (
    <>
      <div
        style={{
          height: cssVar('--inspectionWindowLineHeight'),
          minWidth: '2px',
        }}
      />
      <div
        style={{
          height: cssVar('--inspectionWindowLineHeight'),
          width: 0,
          border: cssVar('--dropdownMenuItemDivider'),
        }}
      />
      <div
        style={{
          height: cssVar('--inspectionWindowLineHeight'),
          minWidth: '2px',
        }}
      />
    </>
  );
};

const OnlineLabel = styled.label``; //TODO(austin): format or delete

const OnlineStatus = (props: OnlineStatusProps) => {
  const [timeSince, setTimeSince] = useState<string>(START);

  let timerRegistration;

  useEffect(() => {
    timerRegistration = setInterval(count, 1000);
    return () => {
      clearInterval(timerRegistration);
    };
  }, [setInterval]);

  const count = () => {
    const now = Date.now();
    const formatted = dhms(now - props.timeSince);
    setTimeSince(formatted);
  };

  const dhms = (ms: number) => {
    const days = Math.floor(ms / DAY_CONVERSION);
    const daysms = ms % DAY_CONVERSION;
    const hours = Math.floor(daysms / HOUR_CONVERSION);
    const hoursms = ms % HOUR_CONVERSION;
    const minutes = Math.floor(hoursms / MINUTE_CONVERSION);
    const minutesms = ms % MINUTE_CONVERSION;
    const sec = Math.floor(minutesms / SECOND_CONVERSION);
    return (
      pad(days, 2) +
      ':' +
      pad(hours, 2) +
      ':' +
      pad(minutes, 2) +
      ':' +
      pad(sec, 2)
    );
  };

  const pad = (num, size) => {
    num = num.toString();
    while (num.length < size) num = '0' + num;
    return num;
  };

  return (
    <>
      <OnlineLabelContainer
        online={props.online}
        className={ClassNames.bodyRegular}>
        <OnlineLabel>{props.online ? 'ONLINE' : 'OFFLINE'}</OnlineLabel>
      </OnlineLabelContainer>
      <DataContainer>
        <DatumContainer>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            {!!props.firmwareVersion && (
              <div style={{ float: 'left' }}>
                <DatumContainer className={ClassNames.labelRegular}>
                  {'IP Address: ' + props.ipAddress}
                </DatumContainer>
              </div>
            )}
            <FloatBar />
            <div style={{ float: 'right' }}>
              <DatumContainer className={ClassNames.labelRegular}>
                {'Firmware Version: ' + props.firmwareVersion}
              </DatumContainer>
            </div>
          </div>
        </DatumContainer>
        <InspectionDataDivider />
        <DatumContainer className={ClassNames.labelRegular}>
          <div style={{ display: 'inline' }}>
            <Key>{props.online ? 'Uptime: ' : 'Downtime: '}</Key>
            <Value>{timeSince}</Value>
          </div>
        </DatumContainer>
        <InspectionDataDivider />
      </DataContainer>
    </>
  );
};

export default OnlineStatus;
