"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ClockFactorySymbol = exports.ClockSymbol = exports.MINUTES_PER_DAY = exports.MINUTES_PER_HOUR = exports.SECONDS_PER_MINUTE = exports.MILLISECONDS_PER_DAY = exports.MILLISECONDS_PER_HOUR = exports.MILLISECONDS_PER_MINUTE = exports.NANO_SECONDS_PER_SECOND = exports.NANO_SECONDS_PER_MILLISECOND = void 0;
exports.NANO_SECONDS_PER_MILLISECOND = 1e6;
exports.NANO_SECONDS_PER_SECOND = 1e9;
exports.MILLISECONDS_PER_MINUTE = 60000;
exports.MILLISECONDS_PER_HOUR = 3.6e6;
exports.MILLISECONDS_PER_DAY = 8.64e7;
exports.SECONDS_PER_MINUTE = 60;
exports.MINUTES_PER_HOUR = 60;
exports.MINUTES_PER_DAY = 1440;
exports.ClockSymbol = Symbol.for('Clock');
exports.ClockFactorySymbol = Symbol.for('Factory<Clock>');