import styled from 'styled-components';

const ConfirmButton = styled.button`
  border: 0;
  opacity 1;
  height: 40px;

  padding: var(--primaryButtonPadding);
  
  color: var(--primaryButtonLabel_Default);
  border-radius: var(--primaryButtonBorderRadius);
  background: var(--primaryButtonBackground_Default);

  &:hover {
    background: var(--primaryButtonBackground_Hover);
    color: var(--primaryButtonLabel_Hover);
  }
`; //TODO(austin): merge primaryButton and windowButton

export default ConfirmButton;
