"use strict";

var _classCallCheck = require("/usr/src/app/packages/commandcenter/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CloudProduceAddress = exports.Segments = void 0;
var Segments;

(function (Segments) {
  Segments[Segments["FARM"] = 0] = "FARM";
  Segments[Segments["SYSTEM_TYPE"] = 1] = "SYSTEM_TYPE";
  Segments[Segments["ID"] = 2] = "ID";
  Segments[Segments["SUBSYSTEM"] = 3] = "SUBSYSTEM";
  Segments[Segments["SERIES_TYPE"] = 4] = "SERIES_TYPE";
})(Segments = exports.Segments || (exports.Segments = {}));

var CloudProduceAddress = function CloudProduceAddress(segments) {
  _classCallCheck(this, CloudProduceAddress);

  this.segments = segments;
};

exports.CloudProduceAddress = CloudProduceAddress;