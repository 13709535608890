import styled from 'styled-components';

export type ButtonProps = {
  id: number;
  text?: string;
  onClick: () => void;
  selected: boolean;
};

export const css = `
  cursor: pointer;
  fill: var(--controlBarIcon_Default);
  color: var(--controlBarIcon_Default);
  background-color: transparent;
  height: 32px;
  margin: 4px;
  border-radius: var(--controlBarIconContainerBorderRadius);

  &:active {
    fill: var(--controlBarIcon_Active);
    color: var(--controlBarIcon_Active);
    background-color: var(--controlBarIconBackground_Active);
  }

  &:disabled {
    fill: var(--controlBarIcon_Disabled);
    color: var(--controlBarIcon_Disabled);
    background-color: transparent;
  }

  &:hover {
    fill: var(--controlBarIcon_Hover);
    color: var(--controlBarIcon_Hover);
    background-color: var(--controlBarIconBackground_Hover);
  }
`;

export const ForceInline = styled.span`
  align: center;
  align-vertical: center;
  display: inline-block;
  white-space: nowrap;
`;

export const Path = styled.path`
  cursor: pointer;
  color: inherit;
  fill: currentColor;
  background-color: transparent;
`;

export const TextPadding = styled.div`
  padding-right: 2px;
  padding-top: 4px;
`;
