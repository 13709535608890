/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import CT290_430S_Model from './CT290_430S.glb';

export default function CT290_430S({ ...props }) {
  const group = useRef()
  const { nodes } = useGLTF(CT290_430S_Model)
  return (
    <group ref={group} {...props} dispose={null} position={props.position} rotation={props.rotation}>
      <mesh geometry={nodes.mesh_0.geometry}>
        <meshPhongMaterial attach="material" color={props.color} transparent={props.transparent} opacity={props.opacity} />
      </mesh>
    </group>
  )
}

CT290_430S.defaultProps = {
  position: [0, 0, 0],
  rotation: [0, 0, 0],
  transparent:false,
  opacity:1.0,
}

useGLTF.preload(CT290_430S_Model)
