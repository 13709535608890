import React from 'react';
import styled from 'styled-components';

import { cssVar } from '../../..';
import { OpenNodeIconProps } from './TreeIconProps';

const Svg = styled.svg`
  position: relative;
  display: inline-block;
  top: var(--treeNodePositionTop);
`;
const OpenNodeIcon = (props: OpenNodeIconProps) => {
  const width = cssVar('--treeConnectorIconWidth');
  const height = cssVar('--treeConnectorIconHeight');
  return (
    <Svg
      onClick={props.onClick}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="9" fill={cssVar('--connectorIcon_Expanded')} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 11H9C8.45 11 8 11.45 8 12C8 12.55 8.45 13 9 13H15C15.55 13 16 12.55 16 12C16 11.45 15.55 11 15 11ZM12 20C7.589 20 4 16.411 4 12C4 7.589 7.589 4 12 4C16.411 4 20 7.589 20 12C20 16.411 16.411 20 12 20ZM12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2Z"
        fill={cssVar('--treeConnector_Default')}
      />
      <mask
        id="mask0_507_47"
        mask-type="luminance"
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="20"
        height="20">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 11H9C8.45 11 8 11.45 8 12C8 12.55 8.45 13 9 13H15C15.55 13 16 12.55 16 12C16 11.45 15.55 11 15 11ZM12 20C7.589 20 4 16.411 4 12C4 7.589 7.589 4 12 4C16.411 4 20 7.589 20 12C20 16.411 16.411 20 12 20ZM12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2Z"
        />
      </mask>
      <g mask="url(#mask0_507_47)">
        <rect
          width={width}
          height={height}
          fill={cssVar('--connectorIconSymbol_Expanded')}
        />
      </g>
    </Svg>
  );
};

export default OpenNodeIcon;
