import React, { useContext } from 'react';

import { cssVar } from '../..';
import Box from '../scenes/common/Box';
import { ControllerContext } from './context/controllercontext/ControllerContext';

const TargetBox = () => {
  const controllerContext = useContext(ControllerContext);
  const targetCoOrdinates = controllerContext.baseRestriction.cameraSettings!.getLookAt(
    controllerContext
  );
  const cameraTarget = [
    targetCoOrdinates.x * 10, // TODO(austin): why is the 10x multiplier necessary?
    targetCoOrdinates.y * 10, // TODO(austin): why is the 10x multiplier necessary?
    targetCoOrdinates.z * 10, // TODO(austin): why is the 10x multiplier necessary?
  ];
  return (
    <group position={cameraTarget} scale={[10, 10, 10]}>
      <Box color={cssVar('--primary800')} />
    </group>
  );
};

export default TargetBox;
