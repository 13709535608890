import React, { useState } from 'react';

import SearchDisplay from './SearchDisplay';
import SubTree from './SubTree';
import { TreeNodeType } from './TreeNode';
import TreeSearchBar from './TreeSearchBar';

export type DataTreeProps = {
  dataMap: {
    all: {
      get: (key: string) => any[];
      keys: () => string[];
      entries: () => any;
    };
  };
};

function getUrlList(dataMap: Map<string, any[]>) {
  return dataMap.keys();
}

function getNodesFromUrl(url) {
  return url.split('.');
}

const getNodes = (dataMap: Map<string, any[]>) => {
  const urlList = getUrlList(dataMap);
  const nodes: string[] = [];
  for (const key of urlList) {
    nodes.push(getNodesFromUrl(key));
  }
  return nodes.sort();
};

const getTreeData = propsDataMap => {
  const dataMap = propsDataMap.all;
  const getChildren = (prefix: string[], nodeUrls: string[], index = 0) => {
    if (!nodeUrls.length) {
      return [];
    }
    const children = Array.from(nodeUrls).filter(nodeUrl => {
      return nodeUrl[index] == prefix.at(-1) && nodeUrl[index + 1];
    });
    const siblings = Array.from(
      new Set(children.map(child => child[index + 1]))
    );
    const childNodes: object[] = [];

    for (let i = 0; i < siblings.length; i++) {
      const newPrefix = [...prefix, siblings[i]];
      childNodes.push({
        ...dataMap.get(newPrefix.join('.')),
        id: siblings[i],
        url: newPrefix.join('.'),
        childNodes: getChildren(newPrefix, children, index + 1),
      });
    }
    return childNodes;
  };

  const populateTree = (nodeIds: string[], root = '1', index = 0) => {
    return {
      ...dataMap.get(root),
      id: root,
      url: root,
      childNodes: getChildren([root], nodeIds, index),
    };
  };

  const nodeIds: string[] = getNodes(propsDataMap.all);
  const dataTree: TreeNodeType = populateTree(nodeIds);

  return dataTree;
};

const DataTree = (props: DataTreeProps) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [queryRegexString, setQueryRegexString] = useState('');
  const data = getTreeData(props.dataMap);

  return (
    <div>
      <TreeSearchBar
        query={searchQuery}
        setQuery={setSearchQuery}
        setQueryRegexString={setQueryRegexString}
      />
      <div style={{ paddingLeft: 'var(--treeNodeSingleIndent)' }}>
        {!searchQuery && (
          <SubTree
            key={data.url}
            last={true}
            level={0}
            node={data}
            parentHasAttrs={false}
            parentLast={true}
            selected={false}
            lastNode={false}
          />
        )}
        {!!searchQuery && (
          <SearchDisplay
            queryRegexString={queryRegexString}
            dataMap={props.dataMap}
          />
        )}
      </div>
    </div>
  );
};

export default DataTree;
