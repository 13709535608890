import React from 'react';
import styled from 'styled-components';

import { HeaderButtonIcon } from './HeaderButtonIcon';

export enum HeaderButtonType {
  CLOSE = 'CLOSE',
  PIN = 'PIN',
  MAXIMIZE = 'MAXIMIZE',
}

const HeaderButton = styled.div<{ buttonType: HeaderButtonType }>`
  display: inline-block;
  text-align: center;

  border-radius: var(--windowHeaderButtonsBorderRadius);
  height: var(--windowHeaderButtonHeight);
  width: var(--windowHeaderButtonWidth);
  margin-left: 8px;
  margin-top: 12px;
  border: none;
  box-sizing: border-box;
  padding: 0;
  position: relative;
  z-index: 99;

  background-color: ${props =>
    props.buttonType === HeaderButtonType.CLOSE
      ? `var(--windowHeaderCloseButtonBackground_Default)`
      : props.buttonType === HeaderButtonType.PIN
      ? `var(--windowHeaderPinButtonBackground_Default)`
      : props.buttonType === HeaderButtonType.MAXIMIZE
      ? `var(--windowHeaderFullScreenButtonBackground_Default)`
      : ``};
  &:disabled {
    cursor: default;
    color: var(--cloud-produce-grey);
  }
  &:hover {
    background-color: ${props =>
      props.buttonType === HeaderButtonType.CLOSE
        ? `var(--windowHeaderCloseButtonBackground_Hover)`
        : props.buttonType === HeaderButtonType.PIN
        ? `var(--windowHeaderPinButtonBackground_Hover)`
        : props.buttonType === HeaderButtonType.MAXIMIZE
        ? `var(--windowHeaderFullScreenButtonBackground_Hover)`
        : ``};
  }
  &:active {
    background-color: ${props =>
      props.buttonType === HeaderButtonType.CLOSE
        ? `var(--windowHeaderCloseButtonBackground_Pressed)`
        : props.buttonType === HeaderButtonType.PIN
        ? `var(--windowHeaderPinButtonBackground_Pressed)`
        : props.buttonType === HeaderButtonType.MAXIMIZE
        ? `var(--windowHeaderFullScreenButtonBackground_Pressed)`
        : `purple`};
  }
`;

export const WindowHeaderButton = props => {
  return (
    <HeaderButton buttonType={props.buttonType} onClick={props.onClick}>
      <HeaderButtonIcon buttonType={props.buttonType} />
    </HeaderButton>
  );
};

export default WindowHeaderButton;
