import React from 'react';
import styled from 'styled-components';

import Close from './Close.svg';
import CloseH from './CloseH.svg';
import Maximize from './Maximize.svg';
import MaximizeH from './MaximizeH.svg';
import Pin from './Pin.svg';
import PinH from './PinH.svg';
import { HeaderButtonType } from './WindowHeaderButton';

const ButtonIcon = styled.img`
  padding: 4px;
  border-radius: var(--windowHeaderButtonsBorderRadius);
  z-index: 101;

  &:hover {
    filter: invert(100%) brightness(200%);
  }
`;

//TODO(austin): make vars constants
export const HeaderButtonIcon = props => {
  if (props.buttonType === HeaderButtonType.CLOSE) {
    var icon = Close;
    var hoverIcon = CloseH;
  } else if (props.buttonType === HeaderButtonType.PIN) {
    var icon = Pin;
    var hoverIcon = PinH;
  } else if (props.buttonType === HeaderButtonType.MAXIMIZE) {
    var icon = Maximize;
    var hoverIcon = MaximizeH;
  } else {
    var icon = '';
    var hoverIcon = '';
  }

  return (
    <ButtonIcon
      src={icon}
      OnMouseOver={e => (e.currentTarget.src = { hoverIcon })}
    />
  );
};
