import { Data } from 'model/src/dataflowprotocol/Datum';
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import * as util from 'util';

import InjectionContext, { InjectionContextType } from '../../../../injection/InjectionContext';
import { DataMapContext } from './DataMapContext';

export const DataMapContextProvider = ({ children }) => {
  const injectionContext: InjectionContextType = useContext(InjectionContext);
  const subscriptions = useRef<Map<string, ((value: any) => void)[]>>(
    new Map()
  );

  useEffect(() => {
    injectionContext.pageKernel.addOnDataUpdatedCallback(
      (data: Data<any>) => {
        const stateUpdates = subscriptions.current.get(data.id);
        stateUpdates?.forEach(callback => {
          try {
            console.log('CALLBACK! ', data)
            callback(data.data)
          } catch (err) {
            injectionContext.coreLogger.error(
              'error updating data ' + 
              util.inspect(data, {depth:10}) + 
              ':\n' + 
              err 
            )
          }
        })
      }
    )
  }, [])

  const subscribe = useCallback(
    (key: string, callback: (value: any) => void) => {
      if (!subscriptions.current.get(key)) {
        subscriptions.current.set(key, []);
      }
      subscriptions.current.get(key)!.push(callback);
    },
    []
  );

  return (
    <DataMapContext.Provider
      value={{
        subscribe: subscribe,
        subscriptions: subscriptions,
      }}>
      {children}
    </DataMapContext.Provider>
  );
};
