/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { SeriesType } from 'model/src/series/SeriesTypes';
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import * as THREE from 'three';
import { OBJLoader } from 'three-stdlib';

import {
  Base,
  Geometry,
  Intersection,
  Subtraction
} from '@react-three/csg';
import { useLoader } from '@react-three/fiber';

import SumpBody_Model from './SumpBody.obj';
import SumpBodyCutAway_Model from './SumpBodyCutAway.obj';
import SumpWater_Model from './SumpWater.obj';
import SumpWaterCutAway_Model from './SumpWaterCutAway.obj';
import { DesktopContext } from '../../../farmviewer/context/desktopcontext/DesktopContext';
import { cssVar } from '../../../..';

export default function Sump(props) {
  const desktopContext = useContext(DesktopContext);
  const group = useRef();
  const sumpUrl = desktopContext.getParentUrl(props.url)
  const sumpBodyGeometry = useLoader(OBJLoader, SumpBody_Model).children[0]
    .geometry;
  const sumpBodyCutAwayGeometry = useLoader(OBJLoader, SumpBodyCutAway_Model)
    .children[0].geometry;
  const sumpWaterGeometry = useLoader(OBJLoader, SumpWater_Model).children[0]
    .geometry;
  const sumpWaterCutAwayGeometry = useLoader(OBJLoader, SumpWaterCutAway_Model)
    .children[0].geometry;

  const waterLevelUrl = props.url + '.' + SeriesType.WATER_LEVEL;

  const waterLevelData = props.dataMap.all.get(waterLevelUrl);
  const waterLevelMm = waterLevelData.data[0].reading.value * 10;

  useEffect(() => {
    props.objectMap.set(props.url, {
      url: props.url,
      model: group.current,
    });
    return () => {
      props.objectMap.delete(props.url);
    };
  }, [props.objectMap, props.url, group]);
  useEffect(() => {
    props.objectMap.set(sumpUrl, {
      url: sumpUrl,
      model: group.current,
    });
    return () => {
      props.objectMap.delete(sumpUrl);
    };
  }, [props.objectMap, sumpUrl, group]);

  const sumpBodyMesh = useMemo(() => {
    const bodyMaterial = new THREE.MeshPhongMaterial({
      color: props.online ? cssVar('--systemRed') : '#ffffff',
    });
    return (
      <mesh material={bodyMaterial}>
        <Geometry showOperations={false}>
          <Base geometry={sumpBodyGeometry} material={bodyMaterial} />
          <Subtraction geometry={sumpBodyCutAwayGeometry}>
            <meshStandardMaterial color="red" />
          </Subtraction>
        </Geometry>
      </mesh>
    );
  }, [props.online, sumpBodyGeometry, sumpBodyCutAwayGeometry]);

  const sumpWaterMesh = useMemo(() => {
    const waterMaterial = new THREE.MeshPhongMaterial({
      color: '#0000ff',
      opacity: 0.5,
      transparent: true,
    });
    return (
      <mesh material={waterMaterial}>
        <Geometry showOperations={false}>
          <Base geometry={sumpWaterGeometry} material={waterMaterial} />
          <Intersection
            geometry={sumpWaterCutAwayGeometry}
            position={[-30, 1.5, 4.1 + waterLevelMm / 10]}
            scale={[1, 1, waterLevelMm + 41]}>
            <meshStandardMaterial color="green" />
          </Intersection>
          <Subtraction geometry={sumpBodyCutAwayGeometry}>
            <meshStandardMaterial color="red" />
          </Subtraction>
        </Geometry>
      </mesh>
    );
  }, [
    sumpWaterGeometry,
    sumpBodyCutAwayGeometry,
    sumpWaterCutAwayGeometry,
    waterLevelMm,
  ]);

  return (
    <group
      ref={group}
      {...props} 
      dispose={null}
      onClick={(e) => {
        props.onClick(sumpUrl, desktopContext); 
        e.stopPropagation();
      }}
      onContextMenu={(e) => {
        props.showMenu(sumpUrl, desktopContext); 
        e.stopPropagation();
      }}
    >
      {sumpWaterMesh}
      {sumpBodyMesh}
    </group>
  );
}

// useGLTF.preload(SumpWater_Model);
