import React from 'react';

import WindowType from '../../window/WindowTypes';

const NotificationsContent = () => {
  return (
    <div
      key={WindowType.NOTIFICATIONS}
      style={{ display: 'inline-block', width: 'inherit' }}
    />
  );
};

export default NotificationsContent;
