/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import {
  combine,
  create,
  fullString,
  toAddress,
} from 'model/src/common/CloudProduceAddressUtility';
import { Axis } from 'model/src/common/Systems';
import { Datum } from 'model/src/dataflowprotocol/Datum';
import { NumericalReading } from 'model/src/series/NumericalReading';
import { SeriesType } from 'model/src/series/SeriesTypes';
import { BuiltinType } from 'model/src/typescript/Typescript';
import React from 'react';
import PageWebSocketClient from 'services/src/pagedataintake/PageWebSocketClient';

import FishTank from './FishTank';

export type StackProps = {
  connectionToServer: PageWebSocketClient;
  dataMap: any; //TODO(austin): replace next PR
  inspectionContext: any;
  objectMap: any;
  onClick: (url?: string) => void;
  showMenu: (url?: string) => void;
  url: string;
};

export default function Stack({ ...props }: StackProps) {
  const isOnlineData = props.dataMap.all.get(
    fullString(combine(toAddress(props.url), create([SeriesType.ONLINE])))
  ).data;
  const isOnline = isOnlineData[0].reading.value;

  const pumpPwm = props.dataMap.all.get(
    fullString(combine(toAddress(props.url), create([SeriesType.PWM])))
  ).data[0].reading.value;
  const isPumpOn = pumpPwm > 0; //TODO(austin): hook up Stack Pump Status

  const fishTanks: any = [];
  props.dataMap.fishTankMap.forEach(fishTank => {
    const fishTankId = fishTank.id.replace('.' + SeriesType.NAME, '');

    const positionX: Datum<NumericalReading> = props.dataMap.all.get(
      fullString(create([fishTankId, Axis.X, SeriesType.POSITION]))
    );
    const positionY: Datum<NumericalReading> = props.dataMap.all.get(
      fullString(create([fishTankId, Axis.Y, SeriesType.POSITION]))
    );
    const positionZ: Datum<NumericalReading> = props.dataMap.all.get(
      fullString(create([fishTankId, Axis.Z, SeriesType.POSITION]))
    );

    const position = [
      positionX.datum.value / 10,
      positionY.datum.value / 10,
      positionZ.datum.value / 10,
    ];

    fishTanks.push(
      <FishTank
        key={fishTankId}
        url={fishTankId}
        online={isOnline}
        position={position}
        depth={
          props.dataMap.all.get(
            fullString(create([fishTankId, SeriesType.WATER_LEVEL]))
          ).data[0].reading.value
        }
        onClick={props.onClick}
        showMenu={props.showMenu}
        inspectionContext={props.inspectionContext}
        isPumpOn={isPumpOn}
        objectMap={props.objectMap}
      />
    );
  });

  return <group dispose={null}>{fishTanks[0]}</group>;
}
