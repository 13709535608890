import styled from 'styled-components';

export const Input = styled.input`
  border: 0;
  outline: 0;
  font-family: var(--neumorphic-font);
  font-size: var(--neumorphic-grid-size);
  border-radius: var(--neumorphic-grid-size);
  padding: var(--neumorphic-grid-size);
  background-color: var(--neumorphic-background-color);
  text-shadow: 1px 1px 0 var(--neumorphic-light-shadow);
  color: var(--neumorphic-font-color);
  margin-right: var(--neumorphic-grid-size);
  box-shadow: inset 2px 2px 5px var(--neumorphic-dark-shadow),
    inset -5px -5px 10px var(--neumorphic-light-shadow);
  width: 100%;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  appearance: none;
  -webkit-appearance: none;

  &:hover {
    box-shadow: inset 1px 1px 2px var(--neumorphic-dark-shadow),
      inset -1px -1px 2px var(--neumorphic-light-shadow);
  }

  &:focus {
    box-shadow: inset 1px 1px 2px var(--neumorphic-dark-shadow),
      inset -1px -1px 2px var(--neumorphic-light-shadow);
  }
`;
