import React from 'react';
import styled from 'styled-components';
import { Pass } from 'three-stdlib';

const PrimaryButton = styled.button`
  border: 0;
  opacity 1;
  height: var(--primaryButtonHeight);
  width: 100%;
  padding: var(--primaryButtonPadding);
  
  border-radius: var(--primaryButtonBorderRadius);

  color: var(--primaryButtonLabelDefault);
  background-color: var(--primaryButtonFillDefault);

  &:hover {
    background-color: var(--primaryButtonFillHover);
    color: var(--primaryButtonLabelHover);
  }
  &:active {
    background-color: var(--primaryButtonFillPressed);
    color: var(--primaryButtonLabelPressed);
  }
  &:disabled {
    background-color: var(--primaryButtonFillDisabled);
    color: var(--primaryButtonLabelDisabled);
  }
`;

export default PrimaryButton;
