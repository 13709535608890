import React, { useState } from 'react';
import styled from 'styled-components';

import { cssVar } from '../..';

const Toggle = styled.button`
  height: auto;
  font-family: var(--neumorphic-font);
  font-size: 12px;
  border: none;
  border-radius: 24px;
  color: #6d7587;
  background-color: var(--neumorphic-background-color);

  box-shadow: -3px -3px 8px var(--neumorphic-light-shadow),
    5px 5px 16px var(--neumorphic-dark-shadow),
    inset 0px 0px 4px rgba(255, 255, 255, 0.2),
    inset 4px 4px 6px rgba(55, 84, 170, 0),
    inset -4px -4px 6px rgba(255, 255, 255, 0),
    0px 0px 4px rgba(255, 255, 255, 0);
  padding: 12px;
  position: relative;
  margin: 0 5px;
  transition: all 0.25s ease;

  &:hover {
    box-shadow: -2px -2px 4px var(--neumorphic-light-shadow),
      2px 2px 5px var(--neumorphic-dark-shadow),
      inset 0px 0px 3px rgba(255, 255, 255, 0.2),
      inset 2px 2px 4px rgba(55, 84, 170, 0),
      inset -2px -2px 4px rgba(255, 255, 255, 0),
      0px 0px 3px rgba(255, 255, 255, 0);
  }

  &:active {
    box-shadow: inset 1px 1px 2px var(--neumorphic-dark-shadow),
      inset -1px -1px 2px var(--neumorphic-light-shadow);
  }

  &:after {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 1px solid
      ${props =>
        props.checked ? props.selectedColor : 'var(--cloud-produce-grey)'};
    background: ${props =>
      props.checked ? props.selectedColor : 'transparent'};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.25s ease;
  }

  &:hover:after {
    border: 1px solid
      ${props => (props.checked ? props.selectedColor : props.hoveredColor)};
  }
`;

const OldNeumorphicCheckBox = props => {
  const [checked, setChecked] = useState(false);
  const performClick = () => {
    setChecked(!checked);
    if (props.onChange) {
      props.onChange({ checked: !checked });
    }
  };
  return (
    <div>
      <Toggle
        type="button"
        checked={checked}
        selectedColor={
          props.selectedColor ? props.selectedColor : cssVar('--selected-color')
        }
        hoveredColor={
          props.hoveredColor ? props.hoveredColor : cssVar('--hovered-color')
        }
        onClick={performClick}
      />
    </div>
  );
};

export default OldNeumorphicCheckBox;
