"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NODE_HEIGHT_MM = exports.NODE_WIDTH_MM = exports.SCANNER_SENSOR_OFFSET_MM = exports.SCANNER_SENSOR_ARRAY_SIZE = exports.RIGHT_SCANNER_OFFSET_MM = exports.LEFT_SCANNER_OFFSET_MM = exports.BACK_SCANNER_OFFSET_MM = exports.FRONT_SCANNER_OFFSET_MM = exports.FARM_LINE_WIDTH_MM = exports.TROLLEY_GAP_MM = exports.TROLLEY_LENGTH_MM = exports.TROLLEY_WIDTH_MM = void 0;
exports.TROLLEY_WIDTH_MM = 3250;
exports.TROLLEY_LENGTH_MM = 3250;
exports.TROLLEY_GAP_MM = 250;
exports.FARM_LINE_WIDTH_MM = 50.8;
exports.FRONT_SCANNER_OFFSET_MM = 100;
exports.BACK_SCANNER_OFFSET_MM = 100;
exports.LEFT_SCANNER_OFFSET_MM = 100;
exports.RIGHT_SCANNER_OFFSET_MM = 100;
exports.SCANNER_SENSOR_ARRAY_SIZE = 5;
exports.SCANNER_SENSOR_OFFSET_MM = exports.FARM_LINE_WIDTH_MM / 2;
exports.NODE_WIDTH_MM = exports.TROLLEY_WIDTH_MM + 2 * exports.TROLLEY_GAP_MM;
exports.NODE_HEIGHT_MM = exports.TROLLEY_LENGTH_MM + 2 * exports.TROLLEY_GAP_MM;