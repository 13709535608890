import styled from 'styled-components';

export const Label = styled.label`
  border: 0;
  outline: 0;
  font-family: var(--neumorphic-font);
  font-size: var(--neumorphic-grid-size);
  text-shadow: 1px 1px 0 var(--neumorphic-light-shadow);
  color: var(--neumorphic-font-color);
`;
