import React, { useState } from 'react';
import styled from 'styled-components';

import { Input } from '../../../neumorphic/Input';
import { Label } from '../../../neumorphic/Label';
import { OldNeumorphicButton } from '../../../neumorphic/OldNeumorphicButton';

const InputArea = styled.div`
  margin-top: 6px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  > button {
    margin-top: 32px;
    width: 100%;
  }

  > label {
    margin-top: 16px;
    margin-bottom: 8px;
    width: 100%;
  }
`;

export default function AutonomousControl(props) {
  const [autonomousCommand, setAutonomousCommand] = useState('');
  const autonomousCommandHandleChange = event => {
    let value = event.target.value;
    setAutonomousCommand(value);
    event.stopPropagation();
    event.preventDefault();
  };
  return (
    <InputArea>
      <Label>Autonomous Command: </Label>
      <Input
        value={autonomousCommand}
        onChange={autonomousCommandHandleChange}
      />
      <OldNeumorphicButton
        onClick={() => {
          props.sendAutonmousCommand(autonomousCommand);
        }}>
        Execute
      </OldNeumorphicButton>
    </InputArea>
  );
}
