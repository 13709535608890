import styled from 'styled-components';

export const HeaderText = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
  line-height: 40px;
  width: 100%;
`;
