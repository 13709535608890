import React from 'react';
import styled from 'styled-components';

import { cssVar } from '../../..';
import { Path } from './IconStyling';

const Svg = styled.svg`
  padding: 2.5px 0px 0px 1px;
  align: center;
  height: var(--controlBarIconHeight);
  width: var(--controlBarIconContainerWidth);
`;

const UiChevronUpIcon = () => {
  return (
    <Svg
      viewBox={cssVar('--controlBarIconViewBox')}
      xmlns="http://www.w3.org/2000/svg">
      <Path
        d="M12 7.5L19.5 15L18.45 16.05L12 9.6L5.55 16.05L4.5 15L12 7.5Z"
        fill="white"
      />
    </Svg>
  );
};

export default UiChevronUpIcon;
