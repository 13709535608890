enum ClassNames {
  bodyBold = 'bodyBold',
  bodyMedium = 'bodyMedium',
  bodyRegular = 'bodyRegular',
  borderlessButtonText = 'borderlessButtonText',
  buttonText = 'buttonText',
  captionBold = 'captionBold',
  captionMedium = 'captionMedium',
  captionRegular = 'captionRegular',
  display = 'display',
  footerText = 'footerText',
  handle = 'handle',
  labelBold = 'labelBold',
  labelMedium = 'labelMedium',
  labelRegular = 'labelRegular',
  largeDisplay = 'largeDisplay',
  linkText = 'linkText',
  mediumBorderlessButtonText = 'mediumBorderlessButtonText',
  smallLinkText = 'smallLinkText',
  title1 = 'title1',
  title2 = 'title2',
  title3 = 'title3',
  title4 = 'title4',
  utopiaDark = 'utopiaDark',
  utopiaLight = 'utopiaLight',
}

export default ClassNames;
