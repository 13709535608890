import 'reflect-metadata';

import * as firebase from 'firebase/app';
import { PageToPageServerType } from 'model/src/dataflowprotocol/pagetopageserver/PageToPageServerType';
import { PageServerToPage } from 'model/src/dataflowprotocol/servertopage/PageServerToPage';
import UserProfile, { PermissionLevel } from 'model/src/user/UserProfile';
import React, { useContext, useMemo, useRef, useState } from 'react';

import InjectionContext, { InjectionContextType } from '../injection/InjectionContext';
import {
  UserProfileContext,
  UserProfileContextType
} from './UserProfileContext';

export type UserProfileContextProviderProps = {
  email: string;
};

const RECONNECTION_PERIOD_MS_SHORT = 800;
const RECONNECTION_PERIOD_MS_LONG = 320000;

export default function UserProfileContextProvider(props) {
  const reconnectionPeriodMs = useRef(RECONNECTION_PERIOD_MS_SHORT);
  const reconnectionTimer = useRef<NodeJS.Timeout>();
  const [userProfile, setUserProfile] = useState<UserProfile>({
    id: 'UNSET',
    email: props.email,
    connected: false,
  });
  const injectionContext: InjectionContextType = useContext(InjectionContext);

  const connectionToServer = useMemo(() => {
    const pageKernel = injectionContext.pageKernel;
    const coreLogger = pageKernel.coreLogger;
    const connectionToServer = pageKernel.getConnectionToServer();
    const attemptReconnection = () => {
      coreLogger.info(
        'UserProfileContextProvider::attemptReconnection:: Attempting reconnection Period:' +
          reconnectionPeriodMs.current
      );
      if (!reconnectionTimer.current) {
        reconnectionTimer.current = setTimeout(() => {
          if (reconnectionTimer.current) {
            reconnectionTimer.current = undefined;
          }
          attemptReconnection();
        }, reconnectionPeriodMs.current);
        coreLogger.info(
          'UserProfileContextProvider::attemptReconnection:: Attempting reconnection IN IF Period:' +
            reconnectionPeriodMs.current
        );
        connectionToServer.connect();
        if (reconnectionPeriodMs.current < RECONNECTION_PERIOD_MS_LONG) {
          reconnectionPeriodMs.current *= 2;
        }
      } else {
        coreLogger.info(
          'UserProfileContextProvider::attemptReconnection:: Attempting reconnection IN ELSE Period:' +
            reconnectionPeriodMs.current
        );
      }
    };
    connectionToServer.addOnConnectionEstablishedCallback(() => {
      coreLogger.info(
        'UserProfileContextProvider::addOnConnectionEstablishedCallback:: Connection Established:' +
          reconnectionPeriodMs.current
      );
      if (reconnectionTimer.current) {
        clearTimeout(reconnectionTimer.current);
        reconnectionTimer.current = undefined;
        reconnectionPeriodMs.current = RECONNECTION_PERIOD_MS_SHORT;
      }
      (async () => {
        const userToken = await firebase
          .auth()
          .currentUser!.getIdToken(/* forceRefresh */ true);
        connectionToServer.sendMessage({
          type: PageToPageServerType.RequestUserProfileData,
          requestUserProfileData: {
            email: props.email,
            uid: firebase.auth().currentUser!.uid,
            userToken: userToken,
          },
        });
      })();
    });
    connectionToServer.addOnConnectionClosedCallback(() => {
      coreLogger.info(
        'UserProfileContextProvider::addOnConnectionClosedCallback:: Connection Closed:' +
          reconnectionPeriodMs.current
      );
      setUserProfile({ ...userProfile, connected: false });
      attemptReconnection();
    });
    connectionToServer.addOnConnectionErrorCallback(() => {
      coreLogger.info(
        'UserProfileContextProvider::addOnConnectionErrorCallback:: Connection Closed:' +
          reconnectionPeriodMs.current
      );
      setUserProfile({ ...userProfile, connected: false });
      attemptReconnection();
    });
    connectionToServer.addOnMessageArrivedCallback(
      (message: PageServerToPage) => {
        if (message.returnUserProfileData) {
          setUserProfile({
            id: message.returnUserProfileData.id,
            authId: message.returnUserProfileData.authId,
            authToken: message.returnUserProfileData.authToken,
            email: message.returnUserProfileData.email,
            connected: true,
            userType: message.returnUserProfileData.userType,
            permissionLevel: message.returnUserProfileData.permissionLevel,
          });
          updateContext({
            ...userProfileContext,
            userProfile: {
              id: message.returnUserProfileData.id,
              authId: message.returnUserProfileData.authId,
              authToken: message.returnUserProfileData.authToken,
              email: message.returnUserProfileData.email,
              connected: true,
              userType: message.returnUserProfileData.userType,
              permissionLevel: message.returnUserProfileData.permissionLevel,
            },
          });
        }
      }
    );
    connectionToServer.connect();
    return pageKernel.getConnectionToServer();
  }, [props.email]);
  const updateContext = (contextValue: UserProfileContextType) => {
    setUserProfileContext({
      ...contextValue,
    });
  };

  const [userProfileContext, setUserProfileContext] = useState<
    UserProfileContextType
  >({
    connectionToServer: connectionToServer,
    email: props.email,
    userProfile: userProfile,
  });

  return (
    <UserProfileContext.Provider value={userProfileContext}>
      {userProfile.connected && props.children}
    </UserProfileContext.Provider>
  );
}
