import { convert, Unit } from 'model/src/series/NumericalReading';
import { Period } from 'model/src/timer/TimerTemplate';
import React, { useContext, useState } from 'react';
import Clock, {
  MILLISECONDS_PER_MINUTE,
  MINUTES_PER_DAY,
  MINUTES_PER_HOUR,
} from 'services/src/common/Clock';
import Logger from 'services/src/logging/Logger';
import {
  convertLocalToUtcMinutes,
  convertUtcToLocalMinutes,
} from 'services/src/timezone/TimezoneUtility';
import { v4 as uuid } from 'uuid';

import { getActionLabel } from '../../farm-ui/windows/SchedulerWindow';
import InjectionContext, {
  InjectionContextType,
} from '../../injection/InjectionContext';
import Toggle from '../toggle/Toggle';
import PrimaryButton from '../window/components/PrimaryButton';
import SecondaryButton from '../window/components/SecondaryButton';
import { TimerTemplateCommandSpecificFields } from './AddTimerTemplateModalPanel';
import {
  SchedulerModalOptionBox,
  SchedulerModalOptionContainer,
  SchedulerModalPanel,
  SchedulerModalTestSubmissionBox,
} from './SchedulerModalStyledComponents';

const MAX_CLICKABLE_SCHEDULE_TIME_MINUTES = 1410;

const ModifyTimerTemplateModalPanel = (props: any) => {
  const injectionContext: InjectionContextType = useContext(InjectionContext);
  const clock = injectionContext.clockFactory() as Clock;
  const coreLogger: Logger = injectionContext.coreLogger;
  const getLocalMinutesFromMidnight = (
    periodOffset_ms: number,
    timeDiff: number
  ) => {
    const utcMinutes =
      periodOffset_ms * convert(Unit.MILLISECONDS, Unit.MINUTES);
    const localMinutes = convertUtcToLocalMinutes(
      utcMinutes,
      clock.getUtcOffsetMinutes()
    );
    const localMinutesWithOffset =
      Math.round((localMinutes + timeDiff) / 15) * 15;

    return localMinutesWithOffset < 0
      ? 0
      : localMinutesWithOffset > MAX_CLICKABLE_SCHEDULE_TIME_MINUTES
      ? MAX_CLICKABLE_SCHEDULE_TIME_MINUTES
      : localMinutesWithOffset;
  };

  const getCurrentTime = (minutesFromMidnight: number) => {
    const timeHours = Math.floor(minutesFromMidnight / MINUTES_PER_HOUR);
    const timeMinutes = minutesFromMidnight - timeHours * MINUTES_PER_HOUR;

    return [timeHours, timeMinutes];
  };

  const getPeriodOffsetMs = (time: string) => {
    //TODO(austin): add variables and use conversion method
    const [hours, minutes] = time.split(':').map(Number);
    const minutesFromMidnight = hours * MINUTES_PER_HOUR + minutes;
    let utcMinutes = convertLocalToUtcMinutes(
      minutesFromMidnight,
      clock.getUtcOffsetMinutes()
    );
    if (utcMinutes >= MINUTES_PER_DAY) {
      utcMinutes = utcMinutes - MINUTES_PER_DAY;
    }
    return utcMinutes * MILLISECONDS_PER_MINUTE;
  };

  const [hours, minutes] = getCurrentTime(
    getLocalMinutesFromMidnight(
      props.modal.timerTemplate.periodOffset_ms,
      props.modal.timeDiff
    )
  );
  const [time, setTime] = useState(
    hours.toString().padStart(2, '0') +
      ':' +
      minutes.toString().padStart(2, '0')
  );
  const [timerTemplateEnabled, setTimerTemplateEnabled] = useState(
    props.modal.timerTemplate.isEnabled
  );
  const [repeatTimerTemplate, setRepeatTimerTemplate] = useState(
    props.modal.timerTemplate.repeats
  );
  const [repeatPeriod, setRepeatPeriod] = useState<Period>(
    props.modal.timerTemplate.period
  );

  const [commandFields, setCommandFields] = useState(props.modal.commandFields);
  const setCommandField = field => {
    setCommandFields({ ...commandFields, ...field });
  };

  const handleInputChange = event => {
    setTime(event.target.value);
  };

  const closeModal = () =>
    props.setModal({ commandType: null, updateType: null });

  return (
    <>
      <SchedulerModalPanel>
        <SchedulerModalOptionContainer>
          <SchedulerModalOptionBox>
            {props.modal.timerTemplate.action.targetId}
          </SchedulerModalOptionBox>
          <SchedulerModalOptionBox>
            Modify Command:{' '}
            {getActionLabel(props.modal.timerTemplate.actionType, coreLogger)}
          </SchedulerModalOptionBox>
          <SchedulerModalOptionBox>
            Enabled:
            <Toggle
              defaultPosition={timerTemplateEnabled}
              onChange={() => setTimerTemplateEnabled(value => !value)}
            />
          </SchedulerModalOptionBox>
          <SchedulerModalOptionBox>
            Time:
            <input
              type="time"
              id="timeInput"
              value={time}
              onChange={handleInputChange}
            />
          </SchedulerModalOptionBox>
          <TimerTemplateCommandSpecificFields
            commandType={props.modal.timerTemplate.actionType}
            setCommandField={setCommandField}
            coreLogger={coreLogger}
          />
          <SchedulerModalTestSubmissionBox>
            <SecondaryButton
              onClick={() => {
                closeModal();
              }}>
              Cancel
            </SecondaryButton>
            <PrimaryButton
              onClick={() => {
                props.sendRemoveTimerTemplateCommand(
                  props.modal.timerTemplate.id
                );
                props.sendAddTimerTemplateCommand({
                  id: uuid(),
                  period: Period.DAY,
                  periodOffset_ms: getPeriodOffsetMs(time),
                  action: props.modal.timerTemplate.action,
                  actionType: props.modal.timerTemplate.actionType,
                  repeats: true,
                  isEnabled: timerTemplateEnabled,
                  validations: [],
                  expirations: 0,
                });

                closeModal();
              }}>
              Submit
            </PrimaryButton>
          </SchedulerModalTestSubmissionBox>
        </SchedulerModalOptionContainer>
      </SchedulerModalPanel>
    </>
  );
};
export default ModifyTimerTemplateModalPanel;
