"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TimerTemplateUpdateType = exports.Period = void 0;
var Period;

(function (Period) {
  Period["DAY"] = "day";
  Period["WEEK"] = "week";
  Period["MONTH"] = "month";
  Period["YEAR"] = "year";
})(Period = exports.Period || (exports.Period = {}));

var TimerTemplateUpdateType;

(function (TimerTemplateUpdateType) {
  TimerTemplateUpdateType["ADD"] = "ADD";
  TimerTemplateUpdateType["REMOVE"] = "REMOVE";
  TimerTemplateUpdateType["ENABLE"] = "ENABLE";
  TimerTemplateUpdateType["DISABLE"] = "DISABLE";
  TimerTemplateUpdateType["MODIFY"] = "MODIFY";
})(TimerTemplateUpdateType = exports.TimerTemplateUpdateType || (exports.TimerTemplateUpdateType = {}));