import { PageToPageServerType } from 'model/src/dataflowprotocol/pagetopageserver/PageToPageServerType';
import TimerTemplate, {
  TimerTemplateUpdateType,
} from 'model/src/timer/TimerTemplate';
import React, { useContext } from 'react';

import InjectionContext, {
  InjectionContextType,
} from '../../injection/InjectionContext';
import {
  DesktopContext,
  DesktopContextType,
} from '../../three/farmviewer/context/desktopcontext/DesktopContext';
import AddTimerTemplateModalPanel from './AddTimerTemplateModalPanel';
import DisableTimerTemplateModalPanel from './DisableTimerTemplateModalPanel';
import EnableTimerTemplateModalPanel from './EnableTimerTemplateModalPanel';
import ModifyTimerTemplateModalPanel from './ModifyTimerTemplateModalPanel';
import RemoveTimerTemplateModalPanel from './RemoveTimerTemplateModalPanel';
import {
  ModalBackgroundPanel,
  SchedulerModalOptionBox,
} from './SchedulerModalStyledComponents';

const SchedulerTimerTemplateModal = (props: any) => {
  const desktopContext: DesktopContextType = useContext(DesktopContext);
  const injectionContext: InjectionContextType = useContext(InjectionContext);
  const connectionToServer = injectionContext.pageKernel.getConnectionToServer();

  const sendAddTimerTemplateCommand = (timerTemplate: TimerTemplate) => {
    connectionToServer.sendMessage({
      type: PageToPageServerType.ModifyTimerTemplateCommand,
      modifyTimerTemplateCommand: {
        farmId: desktopContext.selectedUrl,
        addTimer: timerTemplate,
      },
    });
  };
  const sendEnableTimerTemplateCommand = (id: string) => {
    connectionToServer.sendMessage({
      type: PageToPageServerType.ModifyTimerTemplateCommand,
      modifyTimerTemplateCommand: {
        farmId: desktopContext.selectedUrl,
        enableTimer: id,
      },
    });
  };
  const sendDisableTimerTemplateCommand = (id: string) => {
    connectionToServer.sendMessage({
      type: PageToPageServerType.ModifyTimerTemplateCommand,
      modifyTimerTemplateCommand: {
        farmId: desktopContext.selectedUrl,
        disableTimer: id,
      },
    });
  };
  const sendRemoveTimerTemplateCommand = (id: string) => {
    connectionToServer.sendMessage({
      type: PageToPageServerType.ModifyTimerTemplateCommand,
      modifyTimerTemplateCommand: {
        farmId: desktopContext.selectedUrl,
        removeTimer: id,
      },
    });
  };

  const closeModal = () =>
    props.setModal({ commandType: null, updateType: null });

  return (
    <>
      <ModalBackgroundPanel />
      {props.modal.updateType === TimerTemplateUpdateType.ADD && (
        <AddTimerTemplateModalPanel
          closeModal={closeModal}
          selectedTimerTemplate={props.selectedTimerTemplate}
          sendCommand={props.sendCommand}
          modal={props.modal}
          setModal={props.setModal}
          sendAddTimerTemplateCommand={sendAddTimerTemplateCommand}
        />
      )}
      {props.modal.updateType === TimerTemplateUpdateType.ENABLE && (
        <EnableTimerTemplateModalPanel
          closeModal={closeModal}
          selectedTimerTemplate={props.selectedTimerTemplate}
          sendCommand={props.sendCommand}
          modal={props.modal}
          setModal={props.setModal}
          sendEnableTimerTemplateCommand={sendEnableTimerTemplateCommand}
        />
      )}
      {props.modal.updateType === TimerTemplateUpdateType.DISABLE && (
        <DisableTimerTemplateModalPanel
          closeModal={closeModal}
          selectedTimerTemplate={props.selectedTimerTemplate}
          sendCommand={props.sendCommand}
          modal={props.modal}
          setModal={props.setModal}
          sendDisableTimerTemplateCommand={sendDisableTimerTemplateCommand}
        />
      )}
      {props.modal.updateType === TimerTemplateUpdateType.REMOVE && (
        <RemoveTimerTemplateModalPanel
          closeModal={closeModal}
          selectedTimerTemplate={props.selectedTimerTemplate}
          sendCommand={props.sendCommand}
          modal={props.modal}
          setModal={props.setModal}
          sendRemoveTimerTemplateCommand={sendRemoveTimerTemplateCommand}
        />
      )}
      {props.modal.updateType === TimerTemplateUpdateType.MODIFY && (
        <ModifyTimerTemplateModalPanel
          closeModal={closeModal}
          selectedTimerTemplate={props.selectedTimerTemplate}
          sendCommand={props.sendCommand}
          modal={props.modal}
          setModal={props.setModal}
          sendAddTimerTemplateCommand={sendAddTimerTemplateCommand}
          sendRemoveTimerTemplateCommand={sendRemoveTimerTemplateCommand}
        />
      )}
    </>
  );
};

export default SchedulerTimerTemplateModal;

export const ChangeLightLevelCommandFields = props => {
  const handleLightChange = e => {
    props.setCommandField({ lightLevel: e.target.value });
  };
  const handleTransitionDurationChange = e => {
    props.setCommandField({ transitionDurationMs: e.target.value });
  };
  return (
    <>
      <SchedulerModalOptionBox>
        {'Light Level: '}{' '}
        <input type="number" onChange={handleLightChange} min="1" max="100" />
      </SchedulerModalOptionBox>
      <SchedulerModalOptionBox>
        {'Transition Duration: '}{' '}
        <input
          type="number"
          onChange={handleTransitionDurationChange}
          min="0"
          max="10000"
        />
      </SchedulerModalOptionBox>
    </>
  );
};
