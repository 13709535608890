import React from 'react';

import ContextButton from './button/ContextButton';

export default function SumpContextMenu(props) {
  return (
    <>
      <ContextButton
        onClick={() => {
          props.inspect();
        }}>
        Inspect
      </ContextButton>
    </>
  );
}
