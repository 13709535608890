import React, { Suspense } from 'react';

import Box from '../common/Box';
import CT290_402S from './CT290_402S';
import CT290_415S from './CT290_415S';

export default function VerticalBeam(props) {
  return (
    <group position={props.position} rotation={props.rotation}>
      <Suspense fallback={<Box />}>
        <CT290_415S
          color={props.color}
          opacity={props.opacity}
          transparent={props.transparent}
        />
      </Suspense>
      <Suspense fallback={<Box />}>
        <CT290_402S
          position={[0, 175, 0]}
          rotation={[Math.PI / 2, 0, 0]}
          color={props.color}
          opacity={props.opacity}
          transparent={props.transparent}
        />
      </Suspense>
      <Suspense fallback={<Box />}>
        <CT290_415S
          position={[0, 175, 0]}
          color={props.color}
          opacity={props.opacity}
          transparent={props.transparent}
        />
      </Suspense>
    </group>
  );
}

VerticalBeam.defaultProps = {
  position: [0, 0, 0],
  rotation: [0, 0, 0],
};
