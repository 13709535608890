import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { cssVar } from '../../..';
import ClassNames from '../../../ClassNames';
import useWindowDimensions from '../../desktop/useWindowDimensions';
import ChevronRightIcon from './ChevronRightIcon';

const SubMenuContainer = styled.div`
  box-sizing: border-box;
  position: absolute;
  text-align: center;
  z-index: 150;
  width: var(--contextMenuWidth);
  left: ${props => props.left + 'px'};
  top: ${props => props.top + 'px'};

  background-color: var(--dropdownMenuItemBackground_Default);
  pointer-events: all;
  drop-shadow: var(--dropdownMenuDropShadow);
  border-radius: var(--dropdownMenuBorderRadius);
  font-family: var(--neumorphic-font);
  font-size: var(--neumorphic-font-size);
  display: flex;
  flex-direction: column;
  padding: 0;

  > button {
    width: 100%;
  }
`;

const ContextButtonStyling = styled.button`
  border: 0;
  opacity 1;
  line-height: var(--contextMenuButtonLineHeight);
  width: var(--contextMenuWidth);
  padding: var(--contextMenuButtonPadding);
  text-align: left;
  color: var(--dropdownMenuItemLabel_Default);
  border-radius: var(--dropdownMenuBorderRadius);
  background: var(--dropdownMenuItemBackground_Default);

  pointer-events: all;

  &:hover {
    background: var(--dropdownMenuItemBackground_Hover);
    color: var(--dropdownMenuItemLabel_Hover);
  }

  &:active {
    background: var(--dropdownMenuItemBackground_Pressed);
    color: var(--dropdownMenuItemLabel_Pressed);
  }
`;

const SubMenu = props => {
  const boundsRef = useRef<HTMLInputElement>(null);
  const [height, setHeight] = useState<number>(0);
  const [absoluteTop, setAbsoluteTop] = useState<number>(0);
  const { screenHeight } = useWindowDimensions();

  useLayoutEffect(() => {
    if (boundsRef.current) {
      setHeight(boundsRef.current.getBoundingClientRect().height);
    }
    if (props.parentRef) {
      setAbsoluteTop(props.parentRef.current.getBoundingClientRect().y);
    }
  }, []);
  useEffect(() => {
    return () => props.setOpenSubMenu('');
  }, []);
  const top = height
    ? absoluteTop + height + +cssVar('--viewPortMargin').replace('px', '') <
      screenHeight
      ? props.top
      : screenHeight -
        (absoluteTop - props.top) -
        height -
        +cssVar('--viewPortMargin').replace('px', '')
    : props.top;

  return (
    <SubMenuContainer {...props} top={top} ref={boundsRef}>
      {props.children}
    </SubMenuContainer>
  );
};

SubMenu.defaultProps = {
  left: +cssVar('--contextMenuWidth').replace('px', ''),
};

const SubMenuButton = props => {
  const [nextOpenSubMenu, setNextOpenSubMenu] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);
  const { screenWidth } = useWindowDimensions();

  const left = inputRef.current
    ? inputRef.current.getBoundingClientRect().right +
        +cssVar('--contextMenuWidth').replace('px', '') <
      screenWidth
      ? inputRef.current.offsetWidth
      : -(
          +cssVar('--contextMenuWidth').replace('px', '') +
          inputRef.current.offsetLeft
        )
    : undefined;

  const top = inputRef.current ? inputRef.current.offsetTop : 0;

  const onMouseEnter = () => {
    props.setOpenSubMenu(props.id);
  };

  const onSubmenuMouseEnter = () => {};

  const subMenuButtonProps = {
    openSubMenu: nextOpenSubMenu,
    setOpenSubMenu: setNextOpenSubMenu,
  };

  const childrenWithNewProps =
    props.openSubMenu === props.id &&
    React.Children.map(props.children, child => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, subMenuButtonProps);
      }
      return child;
    });

  return (
    <>
      <ContextButtonStyling
        {...props}
        ref={inputRef}
        root={false}
        className={ClassNames.labelRegular}
        onMouseEnter={onMouseEnter}>
        {props.buttonText}
        <ChevronRightIcon />
      </ContextButtonStyling>
      {props.openSubMenu === props.id && (
        <SubMenu
          parentRef={inputRef}
          top={top}
          left={left}
          openSubMenu={nextOpenSubMenu}
          setOpenSubMenu={setNextOpenSubMenu}
          onMouseEnter={onSubmenuMouseEnter}>
          {childrenWithNewProps}
        </SubMenu>
      )}
    </>
  );
};

export default SubMenuButton;
SubMenuButton.defaultProps = {
  left: +cssVar('--contextMenuWidth').replace('px', ''),
};
