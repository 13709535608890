"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.buildFloor = exports.FloorNodeType = exports.PREFIX = void 0;

var CloudProduceAddressUtility_1 = require("../../../../common/CloudProduceAddressUtility");

var Systems_1 = require("../../../../common/Systems");

exports.PREFIX = '_floor_node__';
var FloorNodeType;

(function (FloorNodeType) {
  FloorNodeType["NONE"] = "NONE";
  FloorNodeType["PROCESSING_SITE"] = "PROCESSING_SITE";
  FloorNodeType["BUILD_SITE"] = "BUILD_SITE";
  FloorNodeType["FORWARD_ROAD"] = "FORWARD_ROAD";
  FloorNodeType["RIGHT_ROAD"] = "RIGHT_ROAD";
  FloorNodeType["LEFT_ROAD"] = "LEFT_ROAD";
  FloorNodeType["BACKWARD_ROAD"] = "BACKWARD_ROAD";
  FloorNodeType["QUAD"] = "QUAD";
  FloorNodeType["GROW_SITE"] = "GROW_SITE";
})(FloorNodeType = exports.FloorNodeType || (exports.FloorNodeType = {}));

function buildFloor(farmId, width, height) {
  var nodes = [];
  var map = [];
  constructNodes(farmId, map, width, height, nodes);
  connectNodes(map, width, height);
  return nodes;
}

exports.buildFloor = buildFloor;

function connectNodes(map, width, height) {
  {
    var current = map[0][width + 1];
    var bottom = map[1][width + 1];
    current.bottomNeighbor = bottom.id;
  }
  {
    var _current = map[1][0];
    var right = map[1][1];
    _current.rightNeighbor = right.id;

    for (var i = 0; i < width; i++) {
      _current = map[1][i + 1];
      right = map[1][i + 2];
      _current.rightNeighbor = right.id;
    }

    _current = map[1][width + 1];
    var top = map[0][width + 1];
    var _bottom = map[2][width + 1];
    _current.topNeighbor = top.id;
    _current.bottomNeighbor = _bottom.id;
  }
  {
    for (var j = 0; j < height; j++) {
      var _current2 = map[j + 2][0];
      var _top = map[j + 1][0];
      _current2.topNeighbor = _top.id;

      for (var _i = 0; _i < width; _i++) {
        _current2 = map[j + 2][_i + 1];
        _top = map[j + 1][_i + 1];
        _current2.topNeighbor = _top.id;
      }

      _current2 = map[j + 2][width + 1];
      var _bottom2 = map[j + 3][width + 1];
      _current2.bottomNeighbor = _bottom2.id;
    }
  }
  {
    var _bottom3 = map[height + 3][0];
    var _current3 = map[height + 2][0];
    var _top2 = map[height + 1][0];
    _current3.topNeighbor = _top2.id;
    _current3.bottomNeighbor = _bottom3.id;
    _bottom3.topNeighbor = _current3.id;

    for (var _i2 = 0; _i2 < width; _i2++) {
      _current3 = map[height + 2][_i2 + 1];
      var _left = map[height + 2][_i2];
      _top2 = map[height + 1][_i2 + 1];
      _current3.leftNeighbor = _left.id;
      _current3.topNeighbor = _top2.id;
    }

    _current3 = map[height + 2][width + 1];
    var left = map[height + 2][width];
    _current3.leftNeighbor = left.id;
  }
}

function constructNodes(farmId, map, width, height, nodes) {
  var farmIdString = farmId.toString();

  for (var i = 0; i < height + 4; i++) {
    var row = [];
    map.push(row);
  }

  {
    for (var _i3 = 0; _i3 < width + 1; _i3++) {
      map[0].push(undefined);
    }

    var buildSite = {
      id: (0, CloudProduceAddressUtility_1.fullString)((0, CloudProduceAddressUtility_1.create)([farmIdString, Systems_1.FarmSubsystem.FLOOR, (width + 1).toString(), '0'])),
      type: FloorNodeType.BUILD_SITE,
      x: width + 1,
      y: 0
    };
    nodes.push(buildSite);
    map[0].push(buildSite);
  }
  {
    var backwardNode = {
      id: (0, CloudProduceAddressUtility_1.fullString)((0, CloudProduceAddressUtility_1.create)([farmIdString, Systems_1.FarmSubsystem.FLOOR, '0', '1'])),
      type: FloorNodeType.BACKWARD_ROAD,
      x: 0,
      y: 1
    };
    nodes.push(backwardNode);
    map[1].push(backwardNode);

    for (var _i4 = 1; _i4 < width + 1; _i4++) {
      var x = _i4.toFixed(0);

      var rightRoad = {
        id: (0, CloudProduceAddressUtility_1.fullString)((0, CloudProduceAddressUtility_1.create)([farmIdString, Systems_1.FarmSubsystem.FLOOR, x, '1'])),
        type: FloorNodeType.RIGHT_ROAD,
        x: _i4,
        y: 1
      };
      nodes.push(rightRoad);
      map[1].push(rightRoad);
    }

    var processingSiteQuad = {
      id: (0, CloudProduceAddressUtility_1.fullString)((0, CloudProduceAddressUtility_1.create)([farmIdString, Systems_1.FarmSubsystem.FLOOR, (width + 1).toFixed(0), '1'])),
      type: FloorNodeType.QUAD,
      x: width + 1,
      y: 1
    };
    nodes.push(processingSiteQuad);
    map[1].push(processingSiteQuad);
  }
  {
    for (var j = 0; j < height; j++) {
      var upRoad = {
        id: (0, CloudProduceAddressUtility_1.fullString)((0, CloudProduceAddressUtility_1.create)([farmIdString, Systems_1.FarmSubsystem.FLOOR, '0', (j + 2).toFixed(0)])),
        type: FloorNodeType.BACKWARD_ROAD,
        x: 0,
        y: j + 2
      };
      nodes.push(upRoad);
      map[j + 2].push(upRoad);

      for (var _i5 = 0; _i5 < width; _i5++) {
        var growSite = {
          id: (0, CloudProduceAddressUtility_1.fullString)((0, CloudProduceAddressUtility_1.create)([farmIdString, Systems_1.FarmSubsystem.FLOOR, (_i5 + 1).toFixed(0), (j + 2).toFixed(0)])),
          type: FloorNodeType.GROW_SITE,
          x: _i5 + 1,
          y: j + 2
        };
        nodes.push(growSite);
        map[j + 2].push(growSite);
      }

      var downRoad = {
        id: (0, CloudProduceAddressUtility_1.fullString)((0, CloudProduceAddressUtility_1.create)([farmIdString, Systems_1.FarmSubsystem.FLOOR, (width + 1).toFixed(0), (j + 2).toFixed(0)])),
        type: FloorNodeType.FORWARD_ROAD,
        x: width + 1,
        y: j + 2
      };
      nodes.push(downRoad);
      map[j + 2].push(downRoad);
    }
  }
  {
    var leftRoad = {
      id: (0, CloudProduceAddressUtility_1.fullString)((0, CloudProduceAddressUtility_1.create)([farmIdString, Systems_1.FarmSubsystem.FLOOR, '0', (height + 2).toFixed(0)])),
      type: FloorNodeType.QUAD,
      x: 0,
      y: height + 2
    };
    nodes.push(leftRoad);
    map[height + 2].push(leftRoad);

    for (var _i6 = 0; _i6 < width; _i6++) {
      var _leftRoad = {
        id: (0, CloudProduceAddressUtility_1.fullString)((0, CloudProduceAddressUtility_1.create)([farmIdString, Systems_1.FarmSubsystem.FLOOR, (_i6 + 1).toFixed(0), (height + 2).toFixed(0)])),
        type: FloorNodeType.LEFT_ROAD,
        x: _i6 + 1,
        y: height + 2
      };
      nodes.push(_leftRoad);
      map[height + 2].push(_leftRoad);
    }

    var _upRoad = {
      id: (0, CloudProduceAddressUtility_1.fullString)((0, CloudProduceAddressUtility_1.create)([farmIdString, Systems_1.FarmSubsystem.FLOOR, (width + 1).toFixed(0), (height + 2).toFixed(0)])),
      type: FloorNodeType.FORWARD_ROAD,
      x: width + 1,
      y: height + 2
    };
    nodes.push(_upRoad);
    map[height + 2].push(_upRoad);
  }
  {
    var processingSite = {
      id: (0, CloudProduceAddressUtility_1.fullString)((0, CloudProduceAddressUtility_1.create)([farmIdString, Systems_1.FarmSubsystem.FLOOR, '0', (height + 3).toFixed(0)])),
      type: FloorNodeType.PROCESSING_SITE,
      x: 0,
      y: height + 3
    };
    nodes.push(processingSite);
    map[height + 3].push(processingSite);
  }
}