import { TrackGraphNode } from 'model/src/farm/entities/farm/graph/TrackGraphNode';
import { VirtualTrolleyPositionSensorsPattern } from 'model/src/farm/entities/trolley/virtual/VirtualTrolleyPositionSensorsState';
import React from 'react';

type Props = { trackGraphNode: TrackGraphNode };

function getFill(type: VirtualTrolleyPositionSensorsPattern) {
  switch (type) {
    case VirtualTrolleyPositionSensorsPattern.NONE:
      return 'purple';
    case VirtualTrolleyPositionSensorsPattern.FULL:
      return 'black';
    case VirtualTrolleyPositionSensorsPattern.BACK_1:
      return 'red';
    case VirtualTrolleyPositionSensorsPattern.BACK_3:
      return 'cyan';
    case VirtualTrolleyPositionSensorsPattern.FRONT_1:
      return 'blue';
    case VirtualTrolleyPositionSensorsPattern.FRONT_3:
      return 'pink';
    case VirtualTrolleyPositionSensorsPattern.LEFT_1:
      return 'gold';
    case VirtualTrolleyPositionSensorsPattern.LEFT_3:
      return 'grey';
    case VirtualTrolleyPositionSensorsPattern.RIGHT_1:
      return 'white';
    case VirtualTrolleyPositionSensorsPattern.RIGHT_3:
      return 'yellow';
    default:
      return 'orange';
  }
}

const SvgTrackGraphNode = (props: Props) => {
  const fill = getFill(props.trackGraphNode.positionSensorPattern);
  let radius = 100;
  if (
    props.trackGraphNode.id.asString().includes('front_side') ||
    props.trackGraphNode.id.asString().includes('left_side')
    //&& props.trackGraphNode.id.asString().includes('_0_')
  ) {
    radius = 150;
  }
  return (
    <circle
      cx={props.trackGraphNode.center.x}
      cy={props.trackGraphNode.center.y}
      r={radius}
      stroke="black"
      strokeWidth="30"
      opacity={1}
      fill={fill}
    />
  );
};
export default SvgTrackGraphNode;
