"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AutonomyStatus = void 0;
var AutonomyStatus;

(function (AutonomyStatus) {
  AutonomyStatus["Autonomous"] = "Autonomous";
  AutonomyStatus["AutonomyAvailable"] = "AutonomyAvailable";
  AutonomyStatus["AutonomyUnavailable"] = "AutonomyUnavailable";
})(AutonomyStatus = exports.AutonomyStatus || (exports.AutonomyStatus = {}));