import UserProfile, { UNSET_USER_PROFILE_VALUE } from 'model/src/user/UserProfile';
import React, { Context } from 'react';
import PageWebSocketClient from 'services/src/pagedataintake/PageWebSocketClient';

export type UserProfileContextType = {
  connectionToServer: PageWebSocketClient;
  email: string;
  userProfile: UserProfile;
};

export const UserProfileContext = React.createContext<UserProfileContextType>({
  connectionToServer: {} as PageWebSocketClient,
  email: UNSET_USER_PROFILE_VALUE,
  userProfile: {
    id: UNSET_USER_PROFILE_VALUE,
    email: UNSET_USER_PROFILE_VALUE,
    connected: false,
  },
}) as Context<UserProfileContextType>;
