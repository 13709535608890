import { last, toAddress } from 'model/src/common/CloudProduceAddressUtility';
import { TierSubsystem } from 'model/src/common/Systems';
import { SeriesType } from 'model/src/series/SeriesTypes';
import { AcState } from 'model/src/status/AcState';
import { ValveState } from 'model/src/status/ValveState';
import React, {
  Suspense,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { cssVar } from '../../../..';
import { HELVETIKER_REGULAR_FONT } from '../../../../App';
import ErrorBoundary from '../../../ErrorBoundary';
import {
  ControllerContext,
  ControllerContextType,
} from '../../../farmviewer/context/controllercontext/ControllerContext';
import Box from '../../common/Box';
import Text from '../../common/Text';
import ValveIndicatorLight from '../umbilical/UmbilicalValveIndicatorLight';
import Door from './blind/Door';
import DoorFrame from './DoorFrame';
import SidePanel from './SidePanel';
import TierFrame from './TierFrame';
import TierLightColumns from './TierLightColumns';
import TierLightGantryX from './TierLightGantryX';
import TierLightGantryY from './TierLightGantryY';
import TierLightGantryZ from './TierLightGantryZ';
import TierLightsIndicatorLight from './TierLightsIndicatorLight';

export interface TierProps {
  inspectionContext;
  globals;
  objectMap;
  onClick;
  towerOnline: boolean;
  showMenu;
  parentHovered: boolean;
  parentUnderInspection: boolean;
  tierData: {
    url: string;
    parentUrl: string;
    online: boolean;
    valveState: ValveState;
    lightState: AcState;
    doorPositionMm: number;
  };
}

export const TIER_HEIGHT = 60;

export default function Tier(props: TierProps) {
  const controllerContext: ControllerContextType = useContext(
    ControllerContext
  );
  const [hovered, setHover] = useState(false);

  const isValveOpen = props.tierData.valveState === ValveState.OPEN;

  const valveInTransition =
    props.tierData.valveState === ValveState.CLOSING ||
    props.tierData.valveState === ValveState.OPENING;

  const areLightsOn = props.tierData.lightState === AcState.ON;

  const acOverride = props.tierData.lightState === AcState.ON_NO_AC;

  const underInspection = props.inspectionContext.inspectionMap.get(
    props.tierData.url
  ).underInspection;
  const group = useRef();

  const tierIndex = parseInt(last(toAddress(props.tierData.url)));
  const offset = TIER_HEIGHT * (tierIndex - 1);

  const textOptions = {
    font: HELVETIKER_REGULAR_FONT.font,
    size: 5,
    height: 1,
  };

  useEffect(() => {
    props.objectMap.set(props.tierData.url, {
      url: props.tierData.url,
      model: group.current,
    });
    return () => {
      props.objectMap.delete(props.tierData.url);
    };
  });

  const onPointerOver = controllerContext.dragMute(event => {
    setHover(true);
    event.stopPropagation();
  }, controllerContext);

  return (
    <ErrorBoundary>
      <group
        ref={group}
        onClick={e => {
          if (!controllerContext.keys.has('Alt')) {
            props.onClick(props.tierData.url);
            e.stopPropagation();
          } else {
            props.showMenu(props.tierData.url);
            e.stopPropagation();
          }
        }}
        onContextMenu={e => {
          props.showMenu(props.tierData.url);
          e.stopPropagation();
        }}
        onPointerOver={onPointerOver}
        onPointerOut={event => {
          setHover(false);
          event.stopPropagation();
        }}
        position={[0, 0, 0]}
        rotation={[0, 0, 0]}>
        <ErrorBoundary>
          <Suspense fallback={<Box />}>
            <group position={[0, 0, offset]}>
              <Text
                position={[0, 0, 50]}
                rotation={[Math.PI / 2, 0, 0]}
                text={props.tierData.url}
                textOptions={textOptions}
                color={cssVar('--cloud-produce-main-green')}
              />
              <group rotation={[0, 0, Math.PI / 2]} position={[84, 0, 50.635]}>
                <SidePanel
                  online={props.tierData.online && props.towerOnline}
                  parentHovered={hovered || props.parentHovered}
                  parentUnderInspection={
                    underInspection || props.parentUnderInspection
                  }
                />
              </group>

              <group
                rotation={[0, 0, -Math.PI / 2]}
                position={[-84, 0, 50.635]}>
                <SidePanel
                  online={props.tierData.online && props.towerOnline}
                  parentHovered={hovered || props.parentHovered}
                  parentUnderInspection={
                    underInspection || props.parentUnderInspection
                  }
                />
              </group>

              <TierFrame
                online={props.tierData.online && props.towerOnline}
                parentHovered={hovered || props.parentHovered}
                parentUnderInspection={
                  underInspection || props.parentUnderInspection
                }
              />
              <TierLightsIndicatorLight
                position={[-90, -80, 70]}
                isOn={areLightsOn}
                acOverride={acOverride}
              />
              <ValveIndicatorLight
                position={[-90, -80, 50]}
                isOn={isValveOpen}
                inTransition={valveInTransition}
              />
              <group position={[-82.0291, -75.1709, 26]}>
                <DoorFrame
                  online={props.tierData.online && props.towerOnline}
                  parentHovered={hovered || props.parentHovered}
                  parentUnderInspection={
                    underInspection || props.parentUnderInspection
                  }
                />
                <group position={[5.035, -5, 55.401]}>
                  <Door
                    online={props.tierData.online && props.towerOnline}
                    parentHovered={hovered || props.parentHovered}
                    parentUnderInspection={
                      underInspection || props.parentUnderInspection
                    }
                    doorPositionMm={props.tierData.doorPositionMm}
                  />
                </group>
              </group>
              <group position={[0, 0, 1.365]}>
                <TierLightColumns
                  online={props.tierData.online && props.towerOnline}
                  parentHovered={hovered || props.parentHovered}
                  parentUnderInspection={
                    underInspection || props.parentUnderInspection
                  }
                />
                <group>
                  <TierLightGantryZ
                    online={props.tierData.online && props.towerOnline}
                    parentHovered={hovered || props.parentHovered}
                    parentUnderInspection={
                      underInspection || props.parentUnderInspection
                    }
                  />
                  <group>
                    <TierLightGantryY
                      online={props.tierData.online && props.towerOnline}
                      parentHovered={hovered || props.parentHovered}
                      parentUnderInspection={
                        underInspection || props.parentUnderInspection
                      }
                    />
                    <group>
                      <TierLightGantryX
                        online={props.tierData.online && props.towerOnline}
                        parentHovered={hovered || props.parentHovered}
                        parentUnderInspection={
                          underInspection || props.parentUnderInspection
                        }
                      />
                    </group>
                  </group>
                </group>
              </group>
            </group>
          </Suspense>
        </ErrorBoundary>
      </group>
    </ErrorBoundary>
  );
}
