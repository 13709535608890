import { useEffect, useRef } from 'react';
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min';

import { useFrame } from '@react-three/fiber';

export interface BlinkParameters<T> {
  activate: boolean;
  to: T;
  from: T;
  duration: number;
  apply: (value: T) => void;
  dependencies;
}

function useBlinkAnimation<T>(parameters: BlinkParameters<T>) {
  const from = parameters.from;
  const to = parameters.to;
  const blinkingRef = useRef<T>(from);
  const isBlinkingRef = useRef<boolean>(true);
  //console.log('useBlinkAnimation: ' + parameters.activate);
  if (parameters.activate) {
    if (!isBlinkingRef.current) {
      isBlinkingRef.current = true;
    }
  } else {
    if (isBlinkingRef.current) {
      isBlinkingRef.current = false;
    }
  }

  const blinkInTween = useRef<TWEEN.Tween>(
    new TWEEN.Tween(blinkingRef)
      .to({ current: to }, parameters.duration)
      .easing(TWEEN.Easing.Sinusoidal.InOut)
      .onUpdate(e => {
        if (isBlinkingRef.current) {
          parameters.apply(blinkingRef.current);
        }
      })
      .onComplete(() => {
        if (isBlinkingRef.current) {
          blinkOutTween.current.start();
        } else {
          parameters.apply(from);
        }
      })
  );
  const blinkOutTween = useRef<TWEEN.Tween>(
    new TWEEN.Tween(blinkingRef)
      .to({ current: from }, parameters.duration)
      .easing(TWEEN.Easing.Sinusoidal.InOut)
      .onUpdate(e => {
        if (isBlinkingRef.current) {
          parameters.apply(blinkingRef.current);
        }
      })
      .onComplete(() => {
        if (isBlinkingRef.current) {
          blinkInTween.current.start();
        } else {
          parameters.apply(from);
        }
      })
  );

  useEffect(() => {
    // console.log('PRESTARTING');
    // console.log(isBlinkingRef.current);
    if (isBlinkingRef.current) {
      // console.log('STARTING');
      blinkInTween.current.start();
    }
  }, [isBlinkingRef.current]);

  useFrame(() => {
    TWEEN.update();
  });
}

export default useBlinkAnimation;
