import {
  isDriveTrain,
  isFishTank,
  isFloorNode,
  isSump,
  isSuperBeacon,
  isTier,
  isTower,
  isTug,
  isUmbilical,
  toAddress,
} from 'model/src/common/CloudProduceAddressUtility';
import { SeriesType } from 'model/src/series/SeriesTypes';

import { DesktopContextType } from './context/desktopcontext/DesktopContext';

export type WindowPosition = {
  url: string;
  x: number;
  y: number;
};

export type Centroid = {
  url: string;
  x: number | undefined;
  y: number | undefined;
};

export type InspectionNode = {
  url: string;
  inspectable: boolean;
  underInspection: boolean;
  children: Map<string, InspectionNode>;
};

export type InspectionContext = {
  inspectionMap: Map<string, InspectionNode>;
  flattenedInspectionMap: Map<string, InspectionNode>;
  windowPositionsMap: Map<string, WindowPosition>;
  centroidMap: Map<string, Centroid>;
};

export const uninspectAllNodes = (
  desktopContext: DesktopContextType,
  inspectionContext: InspectionContext,
  setInspectionContext: (inspectionContext: InspectionContext) => void,
  inspectionMap
) => {
  const inspectionNodes = Array.from(
    inspectionContext.flattenedInspectionMap,
    ([name, value]) => value
  );
  inspectionNodes.forEach((inspectionNode: InspectionNode) => {
    if (inspectionNode.underInspection) {
      inspectionNode.underInspection = false;
      const updatedInspectionNode = {
        ...inspectionNode,
        selected: false,
      };
      inspectionContext.flattenedInspectionMap.delete(inspectionNode.url);
      setInspectionContext({
        inspectionMap: inspectionMap.set(
          inspectionNode.url,
          updatedInspectionNode
        ),
        flattenedInspectionMap: inspectionContext.flattenedInspectionMap,
        windowPositionsMap: inspectionContext.windowPositionsMap,
        centroidMap: inspectionContext.centroidMap,
      });
    }
  });
  desktopContext.clearInspectionWindows!(desktopContext);
};

export const inspectNode = (
  url,
  desktopContext: DesktopContextType,
  inspectionContext: InspectionContext,
  inspectionMap,
  setInspectionContext: (inspectionContext: InspectionContext) => void,
  setWindowPosition,
  dataMap,
  uninspect
) => {
  const inspectionNode: InspectionNode = inspectionMap.get(url)!;

  const updatedInspectionNode = {
    ...inspectionNode,
    underInspection: true,
  };
  inspectionContext.flattenedInspectionMap.set(url, updatedInspectionNode);
  // Determine new window position here!
  setInspectionContext({
    inspectionMap: inspectionContext.inspectionMap.set(
      url,
      updatedInspectionNode
    ),
    flattenedInspectionMap: inspectionContext.flattenedInspectionMap,
    windowPositionsMap: inspectionContext.windowPositionsMap,
    centroidMap: inspectionContext.centroidMap,
  });

  const initialWindowPosition = inspectionContext.windowPositionsMap.get(
    updatedInspectionNode.url
  )!;
  const address = toAddress(updatedInspectionNode.url);

  const isDriveTrainVariable = isDriveTrain(address);
  const isFishTankVariable = isFishTank(address);
  const isFloorNodeVariable = isFloorNode(address);
  const isSumpVariable = isSump(address);
  const isSuperBeaconVariable = isSuperBeacon(address);
  const isTierVariable = isTier(address);
  const isTowerVariable = isTower(address);
  const isTugVariable = isTug(address);
  const isUmbilicalVariable = isUmbilical(address);

  const online = dataMap.all.get(updatedInspectionNode.url);

  desktopContext.pushInspectionWindow!(
    url,
    {
      initialWindowPosition: initialWindowPosition,
      url: inspectionNode.url,
      dataMap: dataMap,
      isTugVariable: isTugVariable,
      isFloorNodeVariable: isFloorNodeVariable,
      isDriveTrainVariable: isDriveTrainVariable,
      isSuperBeaconVariable: isSuperBeaconVariable,
      isSumpVariable: isSumpVariable,
      isFishTankVariable: isFishTankVariable,
      isTowerVariable: isTowerVariable,
      isTierVariable: isTierVariable,
      isUmbilicalVariable: isUmbilicalVariable,
    },
    {
      title: inspectionNode.url,
      key: inspectionNode.url,
      initialWindowPosition: initialWindowPosition!,
      url: inspectionNode.url,
      closeHandler: () => uninspect(inspectionNode.url, desktopContext),
      onDrag: (e, dragData) => {
        setWindowPosition({
          url: inspectionNode.url,
          x: dragData.x,
          y: dragData.y,
        });
      },
    },
    desktopContext
  );
};

export const uninspectNode = (
  url: string,
  desktopContext: DesktopContextType,
  inspectionContext,
  setInspectionContext,
  inspectionMap
) => {
  const inspectionNode: InspectionNode = inspectionContext.inspectionMap.get(
    url
  )!;
  const updatedInspectionNode = {
    ...inspectionNode,
    underInspection: false,
  };
  inspectionContext.flattenedInspectionMap.delete(url);
  setInspectionContext({
    inspectionMap: inspectionMap.set(url, updatedInspectionNode),
    flattenedInspectionMap: inspectionContext.flattenedInspectionMap,
    windowPositionsMap: inspectionContext.windowPositionsMap,
    centroidMap: inspectionContext.centroidMap,
  });
  desktopContext.removeInspectionWindow!(url, desktopContext);
};

export function isInspectable(url: string): boolean {
  if (url.endsWith(SeriesType.NAME)) {
    return true;
  }
  return false;
}

export function buildInspectionMap(data) {
  const inspectionMap: Map<string, InspectionNode> = new Map<
    string,
    InspectionNode
  >();
  data.farm.data.forEach(datum => {
    const id = datum.id.replace('.' + SeriesType.NAME, '');

    const inspectionNode = {
      url: id,
      inspectable: isInspectable(datum.id),
      underInspection: false,
      children: new Map<string, InspectionNode>(),
    };
    inspectionMap.set(id, inspectionNode);
  });
  return inspectionMap;
}

export function buildWindowPositionsMap(
  inspectionMap: Map<string, InspectionNode>
): Map<string, WindowPosition> {
  const windowPositionsMap = new Map<string, WindowPosition>();
  let i = 0;
  inspectionMap.forEach((inspectionNode: InspectionNode) => {
    if (inspectionNode.inspectable) {
      const initialWindowPosition = { x: 8 + i * 5, y: 32 };
      windowPositionsMap.set(inspectionNode.url, {
        url: inspectionNode.url,
        ...initialWindowPosition,
      });
      i++;
    }
  });
  return windowPositionsMap;
}

export function buildCentroidMap(
  inspectionMap: Map<string, InspectionNode>
): Map<string, Centroid> {
  const centroidMap = new Map<string, Centroid>();
  let i = 0;
  inspectionMap.forEach((inspectionNode: InspectionNode) => {
    const initialCentroid = { x: undefined, y: undefined };
    centroidMap.set(inspectionNode.url, {
      url: inspectionNode.url,
      ...initialCentroid,
    });
    i++;
  });
  return centroidMap;
}

export const inspectionNodeHandler = (
  url,
  desktopContext: DesktopContextType,
  inspectionContext: InspectionContext,
  sceneNodeClicked,
  uninspect,
  inspect
) => {
  const inspectionNode: InspectionNode = inspectionContext.inspectionMap.get(
    url
  )!;
  if (inspectionNode.underInspection) {
    uninspect(url, desktopContext);
  } else {
    if (inspectionNode.inspectable) {
      inspect(url, desktopContext);
    }
  }
  sceneNodeClicked.current = true;
};
