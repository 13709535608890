import React from 'react';

import CommandCenterBeam from './CommandCenterBeam';
import CommandCenterPillar from './CommandCenterPillar';

export default function CommandCenterWall(props) {
  return (
    <group position={props.position} rotation={props.rotation}>
      <CommandCenterPillar
        position={[0, 0, 0]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />
      <CommandCenterPillar
        position={[187.5, 0, 0]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />
      <CommandCenterPillar
        position={[187.5 + 187.5, 0, 0]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />
      <CommandCenterBeam
        position={[25, 0, 200.635]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />
      <CommandCenterBeam
        position={[25 + 187.5, 0, 200.635]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />
    </group>
  );
}

CommandCenterWall.defaultProps = {
  position: [0, 0, 0],
  rotation: [0, 0, 0],
};
