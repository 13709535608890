import styled from 'styled-components';

const SecondaryButton = styled.button`
  border: 0;
  opacity 1;
  height: var(--secondaryButtonHeight);

  padding: var(--windowButtonPadding);
  margin-right: var(--windowButtonDividerSpacing);
  
  color: var(--secondaryButtonTextDefault);
  border-radius: var(--secondaryButtonBorderRadius);
  border: 2px solid var(--secondaryButtonStrokeDefault);
  background: inherit;
  background-color: inherit;

  &:hover {
    color: var(--secondaryButtonTextHover);
    border: 2px solid var(--secondaryButtonStrokeHover);
    background-color: var(--secondaryButtonFillHover);
  }
  &:active {
    color: var(--secondaryButtonTextPressed);
    border: 2px solid var(--secondaryButtonStrokePressed);
    background-color: var(--secondaryButtonFillPressed);
  }
  &:disabled {
    color: var(--secondaryButtonTextDisabled);
    border: 2px solid var(--secondaryButtonStrokeDisabled);
    background-color: var(--secondaryButtonBackground_Hover);
  }
`;

export default SecondaryButton;
