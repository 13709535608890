import gql from 'graphql-tag';
import React, { Component } from 'react';
import { Query } from 'react-apollo';

import ExplorerControls from './ExplorerControls';

export const GET_ALL_FARMS_QUERY = gql`
  query getAllFarms {
    farms {
      id
      name
      coordinates {
        latitude
        longitude
      }
    }
  }
`;

const DELETE_FARM_MUTATION = gql`
  mutation deleteFarm($farmId: ID!) {
    deleteFarm(farmId: $farmId)
  }
`;

type Props = { index: number };
type State = {};

class Explorer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Query query={GET_ALL_FARMS_QUERY}>
        {({ data, loading, error }: any) => {
          if (loading) {
            return <h1>loading!</h1>;
          }
          if (error) {
            return <h1>call the helpline</h1>;
          }
          return <ExplorerControls index={0} farms={[...data.farms]} />;
        }}
      </Query>
    );
  }
}
export default Explorer;
