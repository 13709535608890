import styled from 'styled-components';

export const Label = styled.label`
  border: 0;
  outline: 0;
  float: left;
  padding: var(--dropdownMenuHeaderPadding);
  border-radius: var(--dropdownMenuHeaderBorderRadius);
  background-color: var(--dropdownMenuTitleBackground_Default);
  color: var(--dropdownMenuTitleText_Default);
`;
