import React from 'react';
import styled from 'styled-components';

import { Path } from './IconStyling';

const Svg = styled.svg`
  padding: 0px;
  align: center;
  float: left;
  height: var(--controlBarIconContainerHeight);
  width: var(--controlBarIconContainerWidth);
`;
const UiTreeIcon = () => {
  return (
    <Svg fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path d="M26.5 19V13H20.5V15.25H16.75V9.25C16.75 8.85218 16.592 8.47064 16.3107 8.18934C16.0294 7.90804 15.6478 7.75 15.25 7.75H11.5V5.5H5.5V11.5H11.5V9.25H15.25V22.75C15.25 23.1478 15.408 23.5294 15.6893 23.8107C15.9706 24.092 16.3522 24.25 16.75 24.25H20.5V26.5H26.5V20.5H20.5V22.75H16.75V16.75H20.5V19H26.5ZM10 10H7V7H10V10ZM22 22H25V25H22V22ZM22 14.5H25V17.5H22V14.5Z" />
    </Svg>
  );
};

export default UiTreeIcon;
