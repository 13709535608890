import './App.css';

import { InMemoryCache } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { split } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import * as firebase from 'firebase/app';
import SERVER_URL from 'model/src/URL';
import React, { useEffect, useRef, useState } from 'react';
import { ApolloProvider } from 'react-apollo';
import { BrowserRouter as Router } from 'react-router-dom';
import * as THREE from 'three';

import {
  createMuiTheme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import * as Sentry from '@sentry/browser';

import { gamepadManager } from './';
import InjectionContextProvider from './injection/InjectionContextProvider';
import MainContent from './MainContent';

type DisposeHandler = () => void;

const httpUrl = 'https://' + SERVER_URL + '/graphql';
const wsUrl = 'wss://' + SERVER_URL + '/graphql';

console.log('Connecting to ' + httpUrl + ' and ' + wsUrl);

export type ConnectedGamepad = {
  gamepad: any;
};

export const connectedGamepad: ConnectedGamepad = {
  gamepad: undefined,
};

const httpLink = createHttpLink({
  fetch: fetch,
  uri: httpUrl,
});

const wsLink = new WebSocketLink({
  uri: wsUrl,
  options: {
    reconnect: true,
  },
});

interface Definintion {
  kind: string;
  operation?: string;
}

const link = split(
  ({ query }) => {
    const { kind, operation }: Definintion = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  httpLink
);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

export const HELVETIKER_REGULAR_FONT = {
  font: null,
};

const loader = new THREE.FontLoader();
loader.load('/helvetiker_regular.typeface.json', font => {
  HELVETIKER_REGULAR_FONT.font = font;
});

const styles = {
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column' as 'column',
    height: '100%',
    backgroundColor: '#fff',
  },
  grow: {
    flexGrow: 1,
    color: '#fff',
  },
  main: {
    flexGrow: 1,
    flexShrink: 1,
    position: 'relative' as 'relative',
    overflow: 'auto' as 'auto',
  },
  menuButton: {
    color: '#fff',
    marginLeft: -12,
    marginRight: 20,
  },
};

const addDarkMode = () => {
  document.querySelector('body')!.classList.add('utopiaDark');
};

const removeDarkMode = () => {
  document.querySelector('body')!.classList.remove('utopiaDark');
};

export const changeDarkMode = (checked: boolean) => {
  if (checked) {
    addDarkMode();
  } else {
    removeDarkMode();
  }
};

export const isDarkModeEnabled = () => {
  return document.querySelector('body')!.classList.contains('utopiaDark');
};

const App = (props: WithStyles) => {
  const { classes } = props;
  const [ready, setReady] = useState(false);
  const [auth, setAuth] = useState({
    authenticated: false,
    authorized: false,
    email: null,
  });

  useEffect(() => {
    document.querySelector('body')!.classList.add('utopiaLight');
    document.querySelector('body')!.classList.add('utopiaDark');
  }, []);

  useEffect(() => {
    const registration = gamepadManager.addConnectedListener(gamepad => {
      const gp: any = navigator.getGamepads()[gamepad.index];

      connectedGamepad.gamepad = gamepad;
      console.log('done');
    });
    return () => {
      console.log('FloorTools::useEffect::deregistering');
      connectedGamepad.gamepad = null;
      registration.deregister();
    };
  }, [gamepadManager]);

  const unregisterAuthObserver = useRef<DisposeHandler | null>(null);

  const onAuthenticationChange = user => {
    if (user) {
      const { email, emailVerified, displayName, photoURL, uid } = user;
      Sentry.configureScope(scope => {
        scope.setUser({
          email,
          username: displayName,
          id: uid,
          'Email Verified': emailVerified,
        });
      });
      setReady(true);
      setAuth({
        authenticated: true,
        authorized:
          email &&
          (email.endsWith('cloudproduce.com') ||
            email === 'jessicamalonso@gmail.com') &&
          emailVerified,
        email,
      });
    } else {
      setReady(true);
    }
  };

  useEffect(() => {
    unregisterAuthObserver.current = firebase
      .auth()
      .onAuthStateChanged(onAuthenticationChange);
    return function cleanup() {
      if (unregisterAuthObserver.current != null) {
        unregisterAuthObserver.current();
      }
    };
  }, []);

  return (
    <div className={classes.root}>
      <Router>
        <ApolloProvider client={client}>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link
            rel="preconnect"
            href="https://fonts.gstatic.com"
            crossOrigin={'anonymous'}
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
            rel="stylesheet"
          />
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css?family=Roboto:300,400,500"
          />
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
          />
          <style>
            @import
            url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');{' '}
          </style>
          <main className={classes.main}>
            {ready && (
              <InjectionContextProvider>
                <MainContent
                  auth={auth}
                  onAuthenticationChange={onAuthenticationChange}
                />
              </InjectionContextProvider>
            )}
          </main>
        </ApolloProvider>
      </Router>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(App);
