import styled from 'styled-components';

export const ForceInline = styled.span`
  align: center;
  align-vertical: center;
  display: inline-block;
  white-space: nowrap;
`;

export const Path = styled.path`
  cursor: pointer;
  color: inherit;
  fill: currentColor;
  background-color: transparent;
`;

export const TextPadding = styled.div`
  padding-right: 2px;
  padding-top: 4px;
`;
