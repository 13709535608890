import React from 'react';
import styled from 'styled-components';

import { cssVar } from '../../..';
import { ClosedNodeIconProps } from './TreeIconProps';

const Svg = styled.svg`
  position: relative;
  display: inline-block;
  top: var(--treeNodePositionTop);
`;

const ClosedNodeIcon = (props: ClosedNodeIconProps) => {
  const width = cssVar('--treeConnectorIconWidth');
  const height = cssVar('--treeConnectorIconHeight');
  return (
    <Svg
      onClick={props.onClick}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect x="6" y="6" width="12" height="12" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 13H13V15C13 15.55 12.55 16 12 16C11.45 16 11 15.55 11 15V13H9C8.45 13 8 12.55 8 12C8 11.45 8.45 11 9 11H11V9C11 8.45 11.45 8 12 8C12.55 8 13 8.45 13 9V11H15C15.55 11 16 11.45 16 12C16 12.55 15.55 13 15 13ZM12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2Z"
        fill={cssVar('--connectorIcon_Collapsed')}
      />
      <mask
        id="mask0_507_235"
        mask-type="luminance"
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="20"
        height="20">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 13H13V15C13 15.55 12.55 16 12 16C11.45 16 11 15.55 11 15V13H9C8.45 13 8 12.55 8 12C8 11.45 8.45 11 9 11H11V9C11 8.45 11.45 8 12 8C12.55 8 13 8.45 13 9V11H15C15.55 11 16 11.45 16 12C16 12.55 15.55 13 15 13ZM12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2Z"
        />
      </mask>
      <g mask="url(#mask0_507_235)">
        <rect
          width={width}
          height={height}
          fill={cssVar('--connectorIconSymbol_Collapsed')}
        />
      </g>
    </Svg>
  );
};

export default ClosedNodeIcon;
