import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

import ClassNames from '../../ClassNames';
import { InlineContainer, Underline } from './TabStyles';
import TabText from './TabText';

export type TabProps = {
  title: string;
};

const StyledTab = styled.div`
  white-space: nowrap;
  background-color: var(--windowBackground);
  height: var(--tabBarHeight);
  width: inherit;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  color: var(--tabLabel_Default);
  text-overflow: elipsis;
`;

export const Tab = (props: PropsWithChildren<TabProps>) => {
  return (
    <InlineContainer text-overflow="elipsis">
      <StyledTab>
        <TabText className={ClassNames.bodyMedium}>{props.title}</TabText>
      </StyledTab>
      <Underline />
    </InlineContainer>
  );
};

Tab.defaultProps = {
  title: 'title text',
};
