import React from 'react';
import styled from 'styled-components';

import { cssVar } from '../../..';

const SvgCanvas = styled.svg`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
`;

export default function SvgPanel(props) {
  const pointerLines: any = [];

  props.inspectionContext.flattenedInspectionMap.forEach(inspectionNode => {
    const windowPositionsMap = props.inspectionContext.windowPositionsMap;
    const windowPosition = windowPositionsMap.get(inspectionNode.url);
    const centroid = props.inspectionContext.centroidMap.get(
      inspectionNode.url
    );
    if (centroid.x !== undefined && centroid.y !== undefined) {
      pointerLines.push(
        <line
          key={inspectionNode.url + '_line'}
          x1={centroid.x}
          y1={centroid.y}
          x2={windowPosition.x}
          y2={windowPosition.y + 10}
          stroke={cssVar('--pointer-color')}
          strokeWidth={1}
        />
      );
      pointerLines.push(
        <circle
          key={inspectionNode.url + '_dot'}
          cx={centroid.x}
          cy={centroid.y}
          r="2.5"
          strokeWidth="1"
          stroke={cssVar('--pointer-color')}
          fill={cssVar('--pointer-color')}
        />
      );
    }
  });

  return <SvgCanvas>{pointerLines}</SvgCanvas>;
}
