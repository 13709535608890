"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PageToPageServerType = void 0;
var PageToPageServerType;

(function (PageToPageServerType) {
  PageToPageServerType["ArchiveFarmDataCommand"] = "ArchiveFarmDataCommand";
  PageToPageServerType["ModifyTimerTemplateCommand"] = "ModifyTimerTemplateCommand";
  PageToPageServerType["Ping"] = "Ping";
  PageToPageServerType["Pong"] = "Pong";
  PageToPageServerType["RequestFarmData"] = "RequestFarmData";
  PageToPageServerType["RequestTimerTemplateData"] = "RequestTimerTemplateData";
  PageToPageServerType["RequestUserProfileData"] = "RequestUserProfileData";
  PageToPageServerType["SendAutonomousCommand"] = "SendAutonomousCommand";
  PageToPageServerType["SendCommand"] = "SendCommand";
  PageToPageServerType["SendDataUpdate"] = "SendDataUpdate";
  PageToPageServerType["SendTrafficManagerCommand"] = "SendTrafficManagerCommand";
})(PageToPageServerType = exports.PageToPageServerType || (exports.PageToPageServerType = {}));