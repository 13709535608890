/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import * as THREE from 'three';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';

import { useGLTF } from '@react-three/drei';

import { RenderingType } from '../../RenderingType';
import materialFactory from '../../trolley/MaterialFactory';
import TierLightGantryY_Model from './TierLightGantryY.glb';

export default function TierLightGantryY(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF(TierLightGantryY_Model) as GLTF;

  const material: THREE.Material = materialFactory.generateMaterialObject(
    props.parentUnderInspection,
    props.parentHovered,
    props.online
  );

  return (
    <group ref={group} {...props} dispose={null}>
      <group
        position={[61.94, -63.09, 72.41]}
        rotation={[Math.PI / 2, -Math.PI / 2, 0]}>
        <mesh
          geometry={nodes.EndPlate2020_Thin.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.EndPlate2020_Thin.material
              : material
          }
        />
      </group>
      <group
        position={[-57.05, -63.09, 72.41]}
        rotation={[Math.PI / 2, Math.PI / 2, 0]}>
        <mesh
          geometry={nodes.EndPlate_2020_Limit_Combined.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.EndPlate_2020_Limit_Combined.material
              : material
          }
        />
      </group>
      <group position={[62.57, -63.09, 72.41]} rotation={[0, -Math.PI / 2, 0]}>
        <group position={[0, -1.85, -0.6]} rotation={[-Math.PI / 2, 0, 0]}>
          <mesh
            geometry={nodes.Body1.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1.material
                : material
            }
          />
        </group>
        <group position={[0, 0, -0.13]}>
          <group
            position={[1.59, -2.45, -5.57]}
            rotation={[0, -Math.PI / 2, 0]}>
            <mesh
              geometry={nodes.Body1_10.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_10.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2.material
                  : material
              }
            />
          </group>
          <group
            position={[1.59, -2.45, -2.77]}
            rotation={[-Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Body1_11.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_11.material
                  : material
              }
            />
          </group>
          <group
            position={[-17.32, 37.41, -30.9]}
            rotation={[Math.PI / 2, 0, 0]}>
            <group
              position={[18.91, 28.6, 39.86]}
              rotation={[0, -0.66, -Math.PI / 2]}>
              <mesh
                geometry={nodes.Body1_74.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_74.material
                    : material
                }
              />
            </group>
            <group
              position={[18.91, 28.34, 39.86]}
              rotation={[0, 0.77, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Stainless_Steel_-_Satin'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Stainless_Steel_-_Satin'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Paint_-_Enamel_Glossy_(Black)'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Paint_-_Enamel_Glossy_(Black)'].material
                    : material
                }
              />
            </group>
            <group
              position={[18.91, 28.86, 39.86]}
              rotation={[-Math.PI, -0.43, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Stainless_Steel_-_Satin_1'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Stainless_Steel_-_Satin_1'].material
                    : material
                }
              />
              <mesh
                geometry={
                  nodes['Body1_Paint_-_Enamel_Glossy_(Black)_1'].geometry
                }
                material={
                  nodes['Body1_Paint_-_Enamel_Glossy_(Black)_1'].material
                }
              />
            </group>
            <group position={[18.91, 28.55, 39.86]} rotation={[0, -0.45, 0]}>
              <mesh
                geometry={nodes.Body1_77.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_77.material
                    : material
                }
              />
            </group>
          </group>
          <group
            position={[1.59, -2.45, -1.73]}
            rotation={[-Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Body1_12.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_12.material
                  : material
              }
            />
          </group>
          <group position={[1.59, -2.45, -1.73]}>
            <mesh
              geometry={nodes.Body1_13.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_13.material
                  : material
              }
            />
          </group>
          <group position={[1.59, -2.45, -0.82]}>
            <mesh
              geometry={nodes.Body1_14.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_14.material
                  : material
              }
            />
          </group>
          <group position={[1.59, -2.45, -0.51]}>
            <mesh
              geometry={nodes.Body1_15.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_15.material
                  : material
              }
            />
          </group>
          <group position={[1.59, -2.45, -0.19]}>
            <mesh
              geometry={nodes.Body1_16.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_16.material
                  : material
              }
            />
          </group>
          <group position={[1.59, -2.45, -1.73]}>
            <mesh
              geometry={nodes.Body1_17.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_17.material
                  : material
              }
            />
          </group>
        </group>
        <group position={[-3.17, 0, -0.13]}>
          <group
            position={[1.59, -2.45, -5.57]}
            rotation={[0, -Math.PI / 2, 0]}>
            <mesh
              geometry={nodes.Body1_18.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_18.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_1.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_1.material
                  : material
              }
            />
          </group>
          <group
            position={[1.59, -2.45, -2.77]}
            rotation={[-Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Body1_19.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_19.material
                  : material
              }
            />
          </group>
          <group
            position={[-17.32, 37.41, -30.9]}
            rotation={[Math.PI / 2, 0, 0]}>
            <group
              position={[18.91, 28.6, 39.86]}
              rotation={[0, -0.66, -Math.PI / 2]}>
              <mesh
                geometry={nodes.Body1_78.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_78.material
                    : material
                }
              />
            </group>
            <group
              position={[18.91, 28.34, 39.86]}
              rotation={[0, 0.77, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Stainless_Steel_-_Satin_2'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Stainless_Steel_-_Satin_2'].material
                    : material
                }
              />
              <mesh
                geometry={
                  nodes['Body1_Paint_-_Enamel_Glossy_(Black)_2'].geometry
                }
                material={
                  nodes['Body1_Paint_-_Enamel_Glossy_(Black)_2'].material
                }
              />
            </group>
            <group
              position={[18.91, 28.86, 39.86]}
              rotation={[-Math.PI, -0.43, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Stainless_Steel_-_Satin_3'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Stainless_Steel_-_Satin_3'].material
                    : material
                }
              />
              <mesh
                geometry={
                  nodes['Body1_Paint_-_Enamel_Glossy_(Black)_3'].geometry
                }
                material={
                  nodes['Body1_Paint_-_Enamel_Glossy_(Black)_3'].material
                }
              />
            </group>
            <group position={[18.91, 28.55, 39.86]} rotation={[0, -0.45, 0]}>
              <mesh
                geometry={nodes.Body1_81.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_81.material
                    : material
                }
              />
            </group>
          </group>
          <group
            position={[1.59, -2.45, -1.73]}
            rotation={[-Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Body1_20.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_20.material
                  : material
              }
            />
          </group>
          <group position={[1.59, -2.45, -1.73]}>
            <mesh
              geometry={nodes.Body1_21.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_21.material
                  : material
              }
            />
          </group>
          <group position={[1.59, -2.45, -0.82]}>
            <mesh
              geometry={nodes.Body1_22.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_22.material
                  : material
              }
            />
          </group>
          <group position={[1.59, -2.45, -0.51]}>
            <mesh
              geometry={nodes.Body1_23.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_23.material
                  : material
              }
            />
          </group>
          <group position={[1.59, -2.45, -0.19]}>
            <mesh
              geometry={nodes.Body1_24.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_24.material
                  : material
              }
            />
          </group>
          <group position={[1.59, -2.45, -1.73]}>
            <mesh
              geometry={nodes.Body1_25.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_25.material
                  : material
              }
            />
          </group>
        </group>
        <group position={[0, 4.9, -0.13]}>
          <group
            position={[1.59, -2.45, -5.57]}
            rotation={[0, -Math.PI / 2, 0]}>
            <mesh
              geometry={nodes.Body1_26.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_26.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_2.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_2.material
                  : material
              }
            />
          </group>
          <group
            position={[1.59, -2.45, -2.77]}
            rotation={[-Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Body1_27.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_27.material
                  : material
              }
            />
          </group>
          <group
            position={[-17.32, 37.41, -30.9]}
            rotation={[Math.PI / 2, 0, 0]}>
            <group
              position={[18.91, 28.6, 39.86]}
              rotation={[0, -0.66, -Math.PI / 2]}>
              <mesh
                geometry={nodes.Body1_82.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_82.material
                    : material
                }
              />
            </group>
            <group
              position={[18.91, 28.34, 39.86]}
              rotation={[0, 0.77, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Stainless_Steel_-_Satin_4'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Stainless_Steel_-_Satin_4'].material
                    : material
                }
              />
              <mesh
                geometry={
                  nodes['Body1_Paint_-_Enamel_Glossy_(Black)_4'].geometry
                }
                material={
                  nodes['Body1_Paint_-_Enamel_Glossy_(Black)_4'].material
                }
              />
            </group>
            <group
              position={[18.91, 28.86, 39.86]}
              rotation={[-Math.PI, -0.43, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Stainless_Steel_-_Satin_5'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Stainless_Steel_-_Satin_5'].material
                    : material
                }
              />
              <mesh
                geometry={
                  nodes['Body1_Paint_-_Enamel_Glossy_(Black)_5'].geometry
                }
                material={
                  nodes['Body1_Paint_-_Enamel_Glossy_(Black)_5'].material
                }
              />
            </group>
            <group position={[18.91, 28.55, 39.86]} rotation={[0, -0.45, 0]}>
              <mesh
                geometry={nodes.Body1_85.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_85.material
                    : material
                }
              />
            </group>
          </group>
          <group
            position={[1.59, -2.45, -1.73]}
            rotation={[-Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Body1_28.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_28.material
                  : material
              }
            />
          </group>
          <group position={[1.59, -2.45, -1.73]} />
          <group position={[1.59, -2.45, -0.82]} />
          <group position={[1.59, -2.45, -0.51]} />
          <group position={[1.59, -2.45, -0.19]} />
          <group position={[1.59, -2.45, -1.73]}>
            <mesh
              geometry={nodes.Body1_33.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_33.material
                  : material
              }
            />
          </group>
        </group>
        <group position={[-3.17, 4.9, -0.13]}>
          <group
            position={[1.59, -2.45, -5.57]}
            rotation={[0, -Math.PI / 2, 0]}>
            <mesh
              geometry={nodes.Body1_34.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_34.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_3.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_3.material
                  : material
              }
            />
          </group>
          <group
            position={[1.59, -2.45, -2.77]}
            rotation={[-Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Body1_35.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_35.material
                  : material
              }
            />
          </group>
          <group
            position={[-17.32, 37.41, -30.9]}
            rotation={[Math.PI / 2, 0, 0]}>
            <group
              position={[18.91, 28.6, 39.86]}
              rotation={[0, -0.66, -Math.PI / 2]}>
              <mesh
                geometry={nodes.Body1_86.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_86.material
                    : material
                }
              />
            </group>
            <group
              position={[18.91, 28.34, 39.86]}
              rotation={[0, 0.77, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Stainless_Steel_-_Satin_6'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Stainless_Steel_-_Satin_6'].material
                    : material
                }
              />
              <mesh
                geometry={
                  nodes['Body1_Paint_-_Enamel_Glossy_(Black)_6'].geometry
                }
                material={
                  nodes['Body1_Paint_-_Enamel_Glossy_(Black)_6'].material
                }
              />
            </group>
            <group
              position={[18.91, 28.86, 39.86]}
              rotation={[-Math.PI, -0.43, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Stainless_Steel_-_Satin_7'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Stainless_Steel_-_Satin_7'].material
                    : material
                }
              />
              <mesh
                geometry={
                  nodes['Body1_Paint_-_Enamel_Glossy_(Black)_7'].geometry
                }
                material={
                  nodes['Body1_Paint_-_Enamel_Glossy_(Black)_7'].material
                }
              />
            </group>
            <group position={[18.91, 28.55, 39.86]} rotation={[0, -0.45, 0]}>
              <mesh
                geometry={nodes.Body1_89.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_89.material
                    : material
                }
              />
            </group>
          </group>
          <group
            position={[1.59, -2.45, -1.73]}
            rotation={[-Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Body1_36.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_36.material
                  : material
              }
            />
          </group>
          <group position={[1.59, -2.45, -1.73]}>
            <mesh
              geometry={nodes.Body1_37.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_37.material
                  : material
              }
            />
          </group>
          <group position={[1.59, -2.45, -0.82]}>
            <mesh
              geometry={nodes.Body1_38.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_38.material
                  : material
              }
            />
          </group>
          <group position={[1.59, -2.45, -0.51]}>
            <mesh
              geometry={nodes.Body1_39.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_39.material
                  : material
              }
            />
          </group>
          <group position={[1.59, -2.45, -0.19]}>
            <mesh
              geometry={nodes.Body1_40.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_40.material
                  : material
              }
            />
          </group>
          <group position={[1.59, -2.45, -1.73]}>
            <mesh
              geometry={nodes.Body1_41.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_41.material
                  : material
              }
            />
          </group>
        </group>
        <mesh
          geometry={nodes.Small_Gantry_CartPlate_v7.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.Small_Gantry_CartPlate_v7.material
              : material
          }
        />
      </group>
      <group position={[-66.29, -63.09, 72.41]} rotation={[0, Math.PI / 2, 0]}>
        <group position={[0, -1.85, -0.6]} rotation={[-Math.PI / 2, 0, 0]}>
          <mesh
            geometry={nodes.Body1_1.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_1.material
                : material
            }
          />
        </group>
        <group position={[0, 0, -0.13]}>
          <group
            position={[1.59, -2.45, -5.57]}
            rotation={[0, -Math.PI / 2, 0]}>
            <mesh
              geometry={nodes.Body1_42.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_42.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_4.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_4.material
                  : material
              }
            />
          </group>
          <group
            position={[1.59, -2.45, -2.77]}
            rotation={[-Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Body1_43.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_43.material
                  : material
              }
            />
          </group>
          <group
            position={[-17.32, 37.41, -30.9]}
            rotation={[Math.PI / 2, 0, 0]}>
            <group
              position={[18.91, 28.6, 39.86]}
              rotation={[0, -0.66, -Math.PI / 2]}>
              <mesh
                geometry={nodes.Body1_90.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_90.material
                    : material
                }
              />
            </group>
            <group
              position={[18.91, 28.34, 39.86]}
              rotation={[0, 0.77, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Stainless_Steel_-_Satin_8'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Stainless_Steel_-_Satin_8'].material
                    : material
                }
              />
              <mesh
                geometry={
                  nodes['Body1_Paint_-_Enamel_Glossy_(Black)_8'].geometry
                }
                material={
                  nodes['Body1_Paint_-_Enamel_Glossy_(Black)_8'].material
                }
              />
            </group>
            <group
              position={[18.91, 28.86, 39.86]}
              rotation={[Math.PI, -0.43, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Stainless_Steel_-_Satin_9'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Stainless_Steel_-_Satin_9'].material
                    : material
                }
              />
              <mesh
                geometry={
                  nodes['Body1_Paint_-_Enamel_Glossy_(Black)_9'].geometry
                }
                material={
                  nodes['Body1_Paint_-_Enamel_Glossy_(Black)_9'].material
                }
              />
            </group>
            <group position={[18.91, 28.55, 39.86]} rotation={[0, -0.45, 0]}>
              <mesh
                geometry={nodes.Body1_93.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_93.material
                    : material
                }
              />
            </group>
          </group>
          <group
            position={[1.59, -2.45, -1.73]}
            rotation={[-Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Body1_44.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_44.material
                  : material
              }
            />
          </group>
          <group position={[1.59, -2.45, -1.73]}>
            <mesh
              geometry={nodes.Body1_45.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_45.material
                  : material
              }
            />
          </group>
          <group position={[1.59, -2.45, -0.82]}>
            <mesh
              geometry={nodes.Body1_46.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_46.material
                  : material
              }
            />
          </group>
          <group position={[1.59, -2.45, -0.51]}>
            <mesh
              geometry={nodes.Body1_47.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_47.material
                  : material
              }
            />
          </group>
          <group position={[1.59, -2.45, -0.19]}>
            <mesh
              geometry={nodes.Body1_48.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_48.material
                  : material
              }
            />
          </group>
          <group position={[1.59, -2.45, -1.73]}>
            <mesh
              geometry={nodes.Body1_49.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_49.material
                  : material
              }
            />
          </group>
        </group>
        <group position={[-3.17, 0, -0.13]}>
          <group
            position={[1.59, -2.45, -5.57]}
            rotation={[0, -Math.PI / 2, 0]}>
            <mesh
              geometry={nodes.Body1_50.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_50.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_5.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_5.material
                  : material
              }
            />
          </group>
          <group
            position={[1.59, -2.45, -2.77]}
            rotation={[-Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Body1_51.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_51.material
                  : material
              }
            />
          </group>
          <group
            position={[-17.32, 37.41, -30.9]}
            rotation={[Math.PI / 2, 0, 0]}>
            <group
              position={[18.91, 28.6, 39.86]}
              rotation={[0, -0.66, -Math.PI / 2]}>
              <mesh
                geometry={nodes.Body1_94.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_94.material
                    : material
                }
              />
            </group>
            <group
              position={[18.91, 28.34, 39.86]}
              rotation={[0, 0.77, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Stainless_Steel_-_Satin_10'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Stainless_Steel_-_Satin_10'].material
                    : material
                }
              />
              <mesh
                geometry={
                  nodes['Body1_Paint_-_Enamel_Glossy_(Black)_10'].geometry
                }
                material={
                  nodes['Body1_Paint_-_Enamel_Glossy_(Black)_10'].material
                }
              />
            </group>
            <group
              position={[18.91, 28.86, 39.86]}
              rotation={[Math.PI, -0.43, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Stainless_Steel_-_Satin_11'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Stainless_Steel_-_Satin_11'].material
                    : material
                }
              />
              <mesh
                geometry={
                  nodes['Body1_Paint_-_Enamel_Glossy_(Black)_11'].geometry
                }
                material={
                  nodes['Body1_Paint_-_Enamel_Glossy_(Black)_11'].material
                }
              />
            </group>
            <group position={[18.91, 28.55, 39.86]} rotation={[0, -0.45, 0]}>
              <mesh
                geometry={nodes.Body1_97.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_97.material
                    : material
                }
              />
            </group>
          </group>
          <group
            position={[1.59, -2.45, -1.73]}
            rotation={[-Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Body1_52.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_52.material
                  : material
              }
            />
          </group>
          <group position={[1.59, -2.45, -1.73]}>
            <mesh
              geometry={nodes.Body1_53.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_53.material
                  : material
              }
            />
          </group>
          <group position={[1.59, -2.45, -0.82]}>
            <mesh
              geometry={nodes.Body1_54.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_54.material
                  : material
              }
            />
          </group>
          <group position={[1.59, -2.45, -0.51]}>
            <mesh
              geometry={nodes.Body1_55.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_55.material
                  : material
              }
            />
          </group>
          <group position={[1.59, -2.45, -0.19]}>
            <mesh
              geometry={nodes.Body1_56.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_56.material
                  : material
              }
            />
          </group>
          <group position={[1.59, -2.45, -1.73]}>
            <mesh
              geometry={nodes.Body1_57.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_57.material
                  : material
              }
            />
          </group>
        </group>
        <group position={[0, 4.9, -0.13]}>
          <group
            position={[1.59, -2.45, -5.57]}
            rotation={[0, -Math.PI / 2, 0]}>
            <mesh
              geometry={nodes.Body1_58.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_58.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_6.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_6.material
                  : material
              }
            />
          </group>
          <group
            position={[1.59, -2.45, -2.77]}
            rotation={[-Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Body1_59.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_59.material
                  : material
              }
            />
          </group>
          <group
            position={[-17.32, 37.41, -30.9]}
            rotation={[Math.PI / 2, 0, 0]}>
            <group
              position={[18.91, 28.6, 39.86]}
              rotation={[0, -0.66, -Math.PI / 2]}>
              <mesh
                geometry={nodes.Body1_98.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_98.material
                    : material
                }
              />
            </group>
            <group
              position={[18.91, 28.34, 39.86]}
              rotation={[0, 0.77, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Stainless_Steel_-_Satin_12'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Stainless_Steel_-_Satin_12'].material
                    : material
                }
              />
              <mesh
                geometry={
                  nodes['Body1_Paint_-_Enamel_Glossy_(Black)_12'].geometry
                }
                material={
                  nodes['Body1_Paint_-_Enamel_Glossy_(Black)_12'].material
                }
              />
            </group>
            <group
              position={[18.91, 28.86, 39.86]}
              rotation={[Math.PI, -0.43, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Stainless_Steel_-_Satin_13'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Stainless_Steel_-_Satin_13'].material
                    : material
                }
              />
              <mesh
                geometry={
                  nodes['Body1_Paint_-_Enamel_Glossy_(Black)_13'].geometry
                }
                material={
                  nodes['Body1_Paint_-_Enamel_Glossy_(Black)_13'].material
                }
              />
            </group>
            <group position={[18.91, 28.55, 39.86]} rotation={[0, -0.45, 0]}>
              <mesh
                geometry={nodes.Body1_101.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_101.material
                    : material
                }
              />
            </group>
          </group>
          <group
            position={[1.59, -2.45, -1.73]}
            rotation={[-Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Body1_60.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_60.material
                  : material
              }
            />
          </group>
          <group position={[1.59, -2.45, -1.73]} />
          <group position={[1.59, -2.45, -0.82]} />
          <group position={[1.59, -2.45, -0.51]} />
          <group position={[1.59, -2.45, -0.19]} />
          <group position={[1.59, -2.45, -1.73]}>
            <mesh
              geometry={nodes.Body1_65.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_65.material
                  : material
              }
            />
          </group>
        </group>
        <group position={[-3.17, 4.9, -0.13]}>
          <group
            position={[1.59, -2.45, -5.57]}
            rotation={[0, -Math.PI / 2, 0]}>
            <mesh
              geometry={nodes.Body1_66.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_66.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_7.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_7.material
                  : material
              }
            />
          </group>
          <group
            position={[1.59, -2.45, -2.77]}
            rotation={[-Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Body1_67.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_67.material
                  : material
              }
            />
          </group>
          <group
            position={[-17.32, 37.41, -30.9]}
            rotation={[Math.PI / 2, 0, 0]}>
            <group
              position={[18.91, 28.6, 39.86]}
              rotation={[0, -0.66, -Math.PI / 2]}>
              <mesh
                geometry={nodes.Body1_102.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_102.material
                    : material
                }
              />
            </group>
            <group
              position={[18.91, 28.34, 39.86]}
              rotation={[0, 0.77, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Stainless_Steel_-_Satin_14'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Stainless_Steel_-_Satin_14'].material
                    : material
                }
              />
              <mesh
                geometry={
                  nodes['Body1_Paint_-_Enamel_Glossy_(Black)_14'].geometry
                }
                material={
                  nodes['Body1_Paint_-_Enamel_Glossy_(Black)_14'].material
                }
              />
            </group>
            <group
              position={[18.91, 28.86, 39.86]}
              rotation={[Math.PI, -0.43, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Stainless_Steel_-_Satin_15'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Stainless_Steel_-_Satin_15'].material
                    : material
                }
              />
              <mesh
                geometry={
                  nodes['Body1_Paint_-_Enamel_Glossy_(Black)_15'].geometry
                }
                material={
                  nodes['Body1_Paint_-_Enamel_Glossy_(Black)_15'].material
                }
              />
            </group>
            <group position={[18.91, 28.55, 39.86]} rotation={[0, -0.45, 0]}>
              <mesh
                geometry={nodes.Body1_105.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_105.material
                    : material
                }
              />
            </group>
          </group>
          <group
            position={[1.59, -2.45, -1.73]}
            rotation={[-Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Body1_68.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_68.material
                  : material
              }
            />
          </group>
          <group position={[1.59, -2.45, -1.73]}>
            <mesh
              geometry={nodes.Body1_69.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_69.material
                  : material
              }
            />
          </group>
          <group position={[1.59, -2.45, -0.82]}>
            <mesh
              geometry={nodes.Body1_70.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_70.material
                  : material
              }
            />
          </group>
          <group position={[1.59, -2.45, -0.51]}>
            <mesh
              geometry={nodes.Body1_71.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_71.material
                  : material
              }
            />
          </group>
          <group position={[1.59, -2.45, -0.19]}>
            <mesh
              geometry={nodes.Body1_72.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_72.material
                  : material
              }
            />
          </group>
          <group position={[1.59, -2.45, -1.73]}>
            <mesh
              geometry={nodes.Body1_73.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_73.material
                  : material
              }
            />
          </group>
        </group>
        <mesh
          geometry={nodes.Small_Gantry_CartPlate_v7_1.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.Small_Gantry_CartPlate_v7_1.material
              : material
          }
        />
      </group>
      <group
        position={[-6.75, -64.4, 73.72]}
        rotation={[-Math.PI / 4, -Math.PI / 2, 0]}>
        <group position={[0, 1.85, 53]} rotation={[0, 0, -0.23]}>
          <mesh
            geometry={nodes.Body1_2.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_2.material
                : material
            }
          />
        </group>
        <group position={[0, 1.85, 54.11]} rotation={[Math.PI, 0, 2.36]}>
          <mesh
            geometry={nodes['Body1_Steel_-_Satin'].geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes['Body1_Steel_-_Satin'].material
                : material
            }
          />
          <mesh
            geometry={nodes['Body1_Coating_-_Black_Oxide'].geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? materials['Coating - Black Oxide']
                : material
            }
          />
          <mesh
            geometry={nodes['Body1_Aluminum_-_Satin'].geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes['Body1_Aluminum_-_Satin'].material
                : material
            }
          />
        </group>
        <group position={[2.19, 1.85, 50.3]} rotation={[0, 0, 0.59]}>
          <mesh
            geometry={nodes.Body1_4.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_4.material
                : material
            }
          />
        </group>
        <group position={[-2.19, 1.85, 54.5]} rotation={[0, -Math.PI / 2, 0]}>
          <mesh
            geometry={nodes.Body1_5.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_5.material
                : material
            }
          />
        </group>
        <group position={[-2.19, 1.85, 50.3]} rotation={[0, 0, 0.55]}>
          <mesh
            geometry={nodes.Body1_6.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_6.material
                : material
            }
          />
        </group>
        <group position={[0, 4.04, 50.3]} rotation={[0, 0, -0.05]}>
          <mesh
            geometry={nodes.Body1_7.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_7.material
                : material
            }
          />
        </group>
        <group position={[0, 4.04, 54.5]} rotation={[0, -Math.PI / 2, 0]}>
          <mesh
            geometry={nodes.Body1_8.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_8.material
                : material
            }
          />
        </group>
        <group position={[2.19, 1.85, 54.5]} rotation={[0, -Math.PI / 2, 0]}>
          <mesh
            geometry={nodes.Body1_9.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_9.material
                : material
            }
          />
        </group>
      </group>
      <mesh
        geometry={nodes['2020'].geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes['2020'].material
            : material
        }
      />
    </group>
  );
}

useGLTF.preload(TierLightGantryY_Model);
