"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.urlCombine = exports.isRack = exports.WaterFlowSensorReadings = exports.WaterTemperatureSensorReadings = exports.TugSensorsSubsystem = exports.TugAlignerSubsystem = exports.TugSubsystem = exports.Direction2d = exports.Axis = exports.PumpSubsystem = exports.UmbilicalSubsystem = exports.TierSubsystem = exports.SumpSubsystem = exports.TowerSubsystem = exports.FishTankSubsystem = exports.FilterSubsystem = exports.AlarmLevel = exports.AlarmProperties = exports.AlarmTypes = exports.CONNECTION_TYPES = exports.StackWaterFlowSensorsSubsystem = exports.StackFlowSensors = exports.StackSubsystem = exports.LoaderSubsystem = exports.FloorSubsystem = exports.FarmSubsystem = exports.MicrocontrollerType = exports.ArduinoType = exports.ServerType = void 0;

require("reflect-metadata");

var ServerType;

(function (ServerType) {
  ServerType["DEVICE_SERVER"] = "DEVICE_SERVER";
  ServerType["PAGE_SERVER"] = "PAGE_SERVER";
  ServerType["FARM_SERVER"] = "FARM_SERVER";
})(ServerType = exports.ServerType || (exports.ServerType = {}));

var ArduinoType;

(function (ArduinoType) {
  ArduinoType["DRIVE_TRAIN"] = "DRIVE_TRAIN";
  ArduinoType["GANTRY"] = "GANTRY";
  ArduinoType["LINE_FOLLOWER"] = "LINE_FOLLOWER";
  ArduinoType["STACK"] = "STACK";
  ArduinoType["TIER"] = "TIER";
  ArduinoType["TOWER"] = "TOWER";
})(ArduinoType = exports.ArduinoType || (exports.ArduinoType = {}));

var MicrocontrollerType;

(function (MicrocontrollerType) {
  MicrocontrollerType["ARDUINO"] = "ARDUINO";
  MicrocontrollerType["RASPBERRY_PI"] = "RASPBERRY_PI";
})(MicrocontrollerType = exports.MicrocontrollerType || (exports.MicrocontrollerType = {}));

var FarmSubsystem;

(function (FarmSubsystem) {
  FarmSubsystem["SERVERS"] = "SERVERS";
  FarmSubsystem["FLOOR"] = "FLOOR";
  FarmSubsystem["LOADER"] = "LOADER";
  FarmSubsystem["STACK"] = "STACK";
  FarmSubsystem["SUPER_BEACON"] = "SUPER_BEACON";
  FarmSubsystem["TOWER"] = "TOWER";
  FarmSubsystem["TUG"] = "TUG";
})(FarmSubsystem = exports.FarmSubsystem || (exports.FarmSubsystem = {}));

var FloorSubsystem;

(function (FloorSubsystem) {
  FloorSubsystem["OCCUPANT"] = "OCCUPANT";
})(FloorSubsystem = exports.FloorSubsystem || (exports.FloorSubsystem = {}));

var LoaderSubsystem;

(function (LoaderSubsystem) {
  LoaderSubsystem["LOGGERS"] = "LOGGERS";
})(LoaderSubsystem = exports.LoaderSubsystem || (exports.LoaderSubsystem = {}));

var StackSubsystem;

(function (StackSubsystem) {
  StackSubsystem["AIR_PRESSURE_SENSOR"] = "AIR_PRESSURE_SENSOR";
  StackSubsystem["DRAIN"] = "DRAIN";
  StackSubsystem["FISH_TANK"] = "FISH_TANK";
  StackSubsystem["LIGHTS"] = "LIGHTS";
  StackSubsystem["LOGGERS"] = "LOGGERS";
  StackSubsystem["PUMP"] = "PUMP";
  StackSubsystem["TEMPERATURE_SENSOR"] = "TEMPERATURE_SENSOR";
  StackSubsystem["WATER_FLOW_SENSOR"] = "WATER_FLOW_SENSOR";
})(StackSubsystem = exports.StackSubsystem || (exports.StackSubsystem = {}));

var StackFlowSensors;

(function (StackFlowSensors) {
  StackFlowSensors[StackFlowSensors["FLOW_FROM_TANK"] = 1] = "FLOW_FROM_TANK";
  StackFlowSensors[StackFlowSensors["FLOW_TO_FILTER"] = 2] = "FLOW_TO_FILTER";
  StackFlowSensors[StackFlowSensors["FLOW_INTO_RED"] = 3] = "FLOW_INTO_RED";
  StackFlowSensors[StackFlowSensors["FLOW_AT_END_OF_RED"] = 4] = "FLOW_AT_END_OF_RED";
  StackFlowSensors[StackFlowSensors["FLOW_AT_END_OF_BLUE"] = 5] = "FLOW_AT_END_OF_BLUE";
  StackFlowSensors[StackFlowSensors["FLOW_TO_OVERFLOW"] = 6] = "FLOW_TO_OVERFLOW";
  StackFlowSensors[StackFlowSensors["FLOW_FROM_RED_TO_TOWERS"] = 7] = "FLOW_FROM_RED_TO_TOWERS";
  StackFlowSensors[StackFlowSensors["FLOW_INTO_BLUE_FROM_TOWERS"] = 8] = "FLOW_INTO_BLUE_FROM_TOWERS";
})(StackFlowSensors = exports.StackFlowSensors || (exports.StackFlowSensors = {}));

var StackWaterFlowSensorsSubsystem;

(function (StackWaterFlowSensorsSubsystem) {
  StackWaterFlowSensorsSubsystem["STACK_ACCUMULATION"] = "STACK_ACCUMULATION";
  StackWaterFlowSensorsSubsystem["FILTER_ACCUMULATION"] = "FILTER_ACCUMULATION";
  StackWaterFlowSensorsSubsystem["RED_TO_BLUE_SET_POINT"] = "RED_TO_BLUE_SET_POINT";
  StackWaterFlowSensorsSubsystem["FROM_TANK_SET_POINT"] = "FROM_TANK_SET_POINT";
})(StackWaterFlowSensorsSubsystem = exports.StackWaterFlowSensorsSubsystem || (exports.StackWaterFlowSensorsSubsystem = {}));

var CONNECTION_TYPES;

(function (CONNECTION_TYPES) {
  CONNECTION_TYPES["TUG_CONNECTION"] = "TUG_CONNECTION";
  CONNECTION_TYPES["TOWER_CONNECTION"] = "TOWER_CONNECTION";
})(CONNECTION_TYPES = exports.CONNECTION_TYPES || (exports.CONNECTION_TYPES = {}));

var AlarmTypes;

(function (AlarmTypes) {
  AlarmTypes["BOOLEAN_ALARM"] = "BOOLEAN_ALARM";
  AlarmTypes["NUMERIC_ALARM"] = "NUMERIC_ALARM";
})(AlarmTypes = exports.AlarmTypes || (exports.AlarmTypes = {}));

var AlarmProperties;

(function (AlarmProperties) {
  AlarmProperties["CURRENT_LEVEL"] = "CURRENT_LEVEL";
  AlarmProperties["CONDITION"] = "CONDITION";
})(AlarmProperties = exports.AlarmProperties || (exports.AlarmProperties = {}));

var AlarmLevel;

(function (AlarmLevel) {
  AlarmLevel["HIGH_BOUNDARY"] = "HIGH_BOUNDARY";
  AlarmLevel["HIGH_CRITICAL"] = "HIGH_CRITICAL";
  AlarmLevel["HIGH_WARNING"] = "HIGH_WARNING";
  AlarmLevel["HIGH_NORMAL"] = "HIGH_NORMAL";
  AlarmLevel["OPTIMAL"] = "OPTIMAL";
  AlarmLevel["LOW_BOUNDARY"] = "LOW_BOUNDARY";
  AlarmLevel["LOW_CRITICAL"] = "LOW_CRITICAL";
  AlarmLevel["LOW_WARNING"] = "LOW_WARNING";
  AlarmLevel["LOW_NORMAL"] = "LOW_NORMAL";
})(AlarmLevel = exports.AlarmLevel || (exports.AlarmLevel = {}));

var FilterSubsystem;

(function (FilterSubsystem) {
  FilterSubsystem["LED"] = "LED";
  FilterSubsystem["SWITCH"] = "SWITCH";
  FilterSubsystem["VALVE"] = "VALVE";
})(FilterSubsystem = exports.FilterSubsystem || (exports.FilterSubsystem = {}));

var FishTankSubsystem;

(function (FishTankSubsystem) {
  FishTankSubsystem["LIGHTS"] = "LIGHTS";
})(FishTankSubsystem = exports.FishTankSubsystem || (exports.FishTankSubsystem = {}));

var TowerSubsystem;

(function (TowerSubsystem) {
  TowerSubsystem["BATTERY"] = "BATTERY";
  TowerSubsystem["CONFIGURATION"] = "CONFIGURATION";
  TowerSubsystem["CONNECTION"] = "CONNECTION";
  TowerSubsystem["CORE"] = "CORE";
  TowerSubsystem["GANTRY"] = "GANTRY";
  TowerSubsystem["LOGGERS"] = "LOGGERS";
  TowerSubsystem["SUMP"] = "SUMP";
  TowerSubsystem["TIER"] = "TIER";
  TowerSubsystem["UMBILICAL"] = "UMBILICAL";
})(TowerSubsystem = exports.TowerSubsystem || (exports.TowerSubsystem = {}));

var SumpSubsystem;

(function (SumpSubsystem) {
  SumpSubsystem["PUMP"] = "PUMP";
})(SumpSubsystem = exports.SumpSubsystem || (exports.SumpSubsystem = {}));

var TierSubsystem;

(function (TierSubsystem) {
  TierSubsystem["DOOR"] = "DOOR";
  TierSubsystem["DRAIN_VALVE"] = "DRAIN_VALVE";
  TierSubsystem["FAN"] = "FAN";
  TierSubsystem["GANTRY"] = "GANTRY";
  TierSubsystem["HEATER_VALVE"] = "HEATER_VALVE";
  TierSubsystem["LOGGERS"] = "LOGGERS";
  TierSubsystem["PUMP"] = "PUMP";
})(TierSubsystem = exports.TierSubsystem || (exports.TierSubsystem = {}));

var UmbilicalSubsystem;

(function (UmbilicalSubsystem) {
  UmbilicalSubsystem["GANTRY"] = "GANTRY";
  UmbilicalSubsystem["IN"] = "IN";
  UmbilicalSubsystem["OUT"] = "OUT";
})(UmbilicalSubsystem = exports.UmbilicalSubsystem || (exports.UmbilicalSubsystem = {}));

var PumpSubsystem;

(function (PumpSubsystem) {
  PumpSubsystem["LOW"] = "LOW";
  PumpSubsystem["HIGH"] = "HIGH";
})(PumpSubsystem = exports.PumpSubsystem || (exports.PumpSubsystem = {}));

var Axis;

(function (Axis) {
  Axis["X"] = "X";
  Axis["Y"] = "Y";
  Axis["Z"] = "Z";
  Axis["BLIND"] = "BLIND";
})(Axis = exports.Axis || (exports.Axis = {}));

var Direction2d;

(function (Direction2d) {
  Direction2d["LEFT"] = "LEFT";
  Direction2d["RIGHT"] = "RIGHT";
  Direction2d["FRONT"] = "FRONT";
  Direction2d["REAR"] = "REAR";
})(Direction2d = exports.Direction2d || (exports.Direction2d = {}));

var TugSubsystem;

(function (TugSubsystem) {
  TugSubsystem["BATTERY"] = "BATTERY";
  TugSubsystem["BOTTOM_SENSORS"] = "BOTTOM_SENSORS";
  TugSubsystem["CONFIGURATION"] = "CONFIGURATION";
  TugSubsystem["CONNECTION"] = "CONNECTION";
  TugSubsystem["DRIVE_TRAIN"] = "DRIVE_TRAIN";
  TugSubsystem["LED"] = "LED";
  TugSubsystem["LF"] = "LF";
  TugSubsystem["LIFTER"] = "LIFTER";
  TugSubsystem["LOGGERS"] = "LOGGERS";
  TugSubsystem["LR"] = "LR";
  TugSubsystem["MAGNETIC_ALIGNER"] = "MAGNETIC_ALIGNER";
  TugSubsystem["RF"] = "RF";
  TugSubsystem["RR"] = "RR";
  TugSubsystem["TOP_SENSORS"] = "TOP_SENSORS";
})(TugSubsystem = exports.TugSubsystem || (exports.TugSubsystem = {}));

var TugAlignerSubsystem;

(function (TugAlignerSubsystem) {
  TugAlignerSubsystem["FRONT"] = "FRONT";
  TugAlignerSubsystem["REAR"] = "REAR";
})(TugAlignerSubsystem = exports.TugAlignerSubsystem || (exports.TugAlignerSubsystem = {}));

var TugSensorsSubsystem;

(function (TugSensorsSubsystem) {
  TugSensorsSubsystem["FRONT_LINE_SENSOR"] = "FRONT_LINE_SENSOR";
  TugSensorsSubsystem["REAR_LINE_SENSOR"] = "REAR_LINE_SENSOR";
  TugSensorsSubsystem["LEFT_LINE_SENSOR"] = "LEFT_LINE_SENSOR";
  TugSensorsSubsystem["RIGHT_LINE_SENSOR"] = "RIGHT_LINE_SENSOR";
})(TugSensorsSubsystem = exports.TugSensorsSubsystem || (exports.TugSensorsSubsystem = {}));

var WaterTemperatureSensorReadings;

(function (WaterTemperatureSensorReadings) {
  WaterTemperatureSensorReadings["WATER_TEMPERATURE"] = "WATER_TEMPERATURE";
})(WaterTemperatureSensorReadings = exports.WaterTemperatureSensorReadings || (exports.WaterTemperatureSensorReadings = {}));

var WaterFlowSensorReadings;

(function (WaterFlowSensorReadings) {
  WaterFlowSensorReadings["WATER_FLOW_10_SECOND_AVERAGE"] = "WATER_FLOW_10_SECOND_AVERAGE";
  WaterFlowSensorReadings["WATER_FLOW_INSTANT"] = "WATER_FLOW_INSTANT";
  WaterFlowSensorReadings["WATER_FLOW_TOTAL_VOLUME"] = "WATER_FLOW_TOTAL_VOLUME";
})(WaterFlowSensorReadings = exports.WaterFlowSensorReadings || (exports.WaterFlowSensorReadings = {}));

function isRack(subsystem) {
  return subsystem.startsWith('RACK_');
}

exports.isRack = isRack;

function urlCombine(fragments) {
  var urlFragment = '';
  fragments.forEach(function (fragment) {
    urlFragment += '.' + fragment;
  });
  return urlFragment;
}

exports.urlCombine = urlCombine;