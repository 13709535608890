import {
  combine,
  create,
  fullString,
  join,
} from 'model/src/common/CloudProduceAddressUtility';
import {
  FishTankSubsystem,
  MicrocontrollerType,
  StackFlowSensors,
  StackSubsystem,
} from 'model/src/common/Systems';
import { SeriesType } from 'model/src/series/SeriesTypes';
import { BuiltinType } from 'model/src/typescript/Typescript';
import React from 'react';

import OnlineStatus from '../../../../utopia/online-status/OnlineStatus';
import StackData, {
  FlowSensorData,
} from '../../../../utopia/stack-data/StackData';

const StackInspectionPanel = props => {
  const flowAtEndOfBlue: FlowSensorData = {
    waterFlow10SecondAverage: props.dataMap.all.get(
      fullString(
        combine(
          props.parentAddress,
          create([
            StackSubsystem.WATER_FLOW_SENSOR,
            StackFlowSensors.FLOW_AT_END_OF_BLUE.toString(),
            SeriesType.WATER_FLOW_10_SECOND_AVERAGE,
          ])
        )
      )
    ),
    waterFlowInstant: props.dataMap.all.get(
      fullString(
        combine(
          props.parentAddress,
          create([
            StackSubsystem.WATER_FLOW_SENSOR,
            StackFlowSensors.FLOW_AT_END_OF_BLUE.toString(),
            SeriesType.WATER_FLOW_INSTANT,
          ])
        )
      )
    ),
    waterFlowTotalVolume: props.dataMap.all.get(
      fullString(
        combine(
          props.parentAddress,
          create([
            StackSubsystem.WATER_FLOW_SENSOR,
            StackFlowSensors.FLOW_AT_END_OF_BLUE.toString(),
            SeriesType.WATER_FLOW_TOTAL_VOLUME,
          ])
        )
      )
    ),
  };
  const flowAtEndOfRed: FlowSensorData = {
    waterFlow10SecondAverage: props.dataMap.all.get(
      fullString(
        combine(
          props.parentAddress,
          create([
            StackSubsystem.WATER_FLOW_SENSOR,
            StackFlowSensors.FLOW_AT_END_OF_RED.toString(),
            SeriesType.WATER_FLOW_10_SECOND_AVERAGE,
          ])
        )
      )
    ),
    waterFlowInstant: props.dataMap.all.get(
      fullString(
        combine(
          props.parentAddress,
          create([
            StackSubsystem.WATER_FLOW_SENSOR,
            StackFlowSensors.FLOW_AT_END_OF_RED.toString(),
            SeriesType.WATER_FLOW_INSTANT,
          ])
        )
      )
    ),
    waterFlowTotalVolume: props.dataMap.all.get(
      fullString(
        combine(
          props.parentAddress,
          create([
            StackSubsystem.WATER_FLOW_SENSOR,
            StackFlowSensors.FLOW_AT_END_OF_RED.toString(),
            SeriesType.WATER_FLOW_TOTAL_VOLUME,
          ])
        )
      )
    ),
  };
  const flowFromRedToTowers: FlowSensorData = {
    waterFlow10SecondAverage: props.dataMap.all.get(
      fullString(
        combine(
          props.parentAddress,
          create([
            StackSubsystem.WATER_FLOW_SENSOR,
            StackFlowSensors.FLOW_FROM_RED_TO_TOWERS.toString(),
            SeriesType.WATER_FLOW_10_SECOND_AVERAGE,
          ])
        )
      )
    ),
    waterFlowInstant: props.dataMap.all.get(
      fullString(
        combine(
          props.parentAddress,
          create([
            StackSubsystem.WATER_FLOW_SENSOR,
            StackFlowSensors.FLOW_FROM_RED_TO_TOWERS.toString(),
            SeriesType.WATER_FLOW_INSTANT,
          ])
        )
      )
    ),
    waterFlowTotalVolume: props.dataMap.all.get(
      fullString(
        combine(
          props.parentAddress,
          create([
            StackSubsystem.WATER_FLOW_SENSOR,
            StackFlowSensors.FLOW_FROM_RED_TO_TOWERS.toString(),
            SeriesType.WATER_FLOW_TOTAL_VOLUME,
          ])
        )
      )
    ),
  };
  const flowFromTank: FlowSensorData = {
    waterFlow10SecondAverage: props.dataMap.all.get(
      fullString(
        combine(
          props.parentAddress,
          create([
            StackSubsystem.WATER_FLOW_SENSOR,
            StackFlowSensors.FLOW_FROM_TANK.toString(),
            SeriesType.WATER_FLOW_10_SECOND_AVERAGE,
          ])
        )
      )
    ),
    waterFlowInstant: props.dataMap.all.get(
      fullString(
        combine(
          props.parentAddress,
          create([
            StackSubsystem.WATER_FLOW_SENSOR,
            StackFlowSensors.FLOW_FROM_TANK.toString(),
            SeriesType.WATER_FLOW_INSTANT,
          ])
        )
      )
    ),
    waterFlowTotalVolume: props.dataMap.all.get(
      fullString(
        combine(
          props.parentAddress,
          create([
            StackSubsystem.WATER_FLOW_SENSOR,
            StackFlowSensors.FLOW_FROM_TANK.toString(),
            SeriesType.WATER_FLOW_TOTAL_VOLUME,
          ])
        )
      )
    ),
  };
  const flowIntoBlueFromTowers: FlowSensorData = {
    waterFlow10SecondAverage: props.dataMap.all.get(
      fullString(
        combine(
          props.parentAddress,
          create([
            StackSubsystem.WATER_FLOW_SENSOR,
            StackFlowSensors.FLOW_INTO_BLUE_FROM_TOWERS.toString(),
            SeriesType.WATER_FLOW_10_SECOND_AVERAGE,
          ])
        )
      )
    ),
    waterFlowInstant: props.dataMap.all.get(
      fullString(
        combine(
          props.parentAddress,
          create([
            StackSubsystem.WATER_FLOW_SENSOR,
            StackFlowSensors.FLOW_INTO_BLUE_FROM_TOWERS.toString(),
            SeriesType.WATER_FLOW_INSTANT,
          ])
        )
      )
    ),
    waterFlowTotalVolume: props.dataMap.all.get(
      fullString(
        combine(
          props.parentAddress,
          create([
            StackSubsystem.WATER_FLOW_SENSOR,
            StackFlowSensors.FLOW_INTO_BLUE_FROM_TOWERS.toString(),
            SeriesType.WATER_FLOW_TOTAL_VOLUME,
          ])
        )
      )
    ),
  };
  const flowIntoRed: FlowSensorData = {
    waterFlow10SecondAverage: props.dataMap.all.get(
      fullString(
        combine(
          props.parentAddress,
          create([
            StackSubsystem.WATER_FLOW_SENSOR,
            StackFlowSensors.FLOW_INTO_RED.toString(),
            SeriesType.WATER_FLOW_10_SECOND_AVERAGE,
          ])
        )
      )
    ),
    waterFlowInstant: props.dataMap.all.get(
      fullString(
        combine(
          props.parentAddress,
          create([
            StackSubsystem.WATER_FLOW_SENSOR,
            StackFlowSensors.FLOW_INTO_RED.toString(),
            SeriesType.WATER_FLOW_INSTANT,
          ])
        )
      )
    ),
    waterFlowTotalVolume: props.dataMap.all.get(
      fullString(
        combine(
          props.parentAddress,
          create([
            StackSubsystem.WATER_FLOW_SENSOR,
            StackFlowSensors.FLOW_INTO_RED.toString(),
            SeriesType.WATER_FLOW_TOTAL_VOLUME,
          ])
        )
      )
    ),
  };
  const flowToFilter: FlowSensorData = {
    waterFlow10SecondAverage: props.dataMap.all.get(
      fullString(
        combine(
          props.parentAddress,
          create([
            StackSubsystem.WATER_FLOW_SENSOR,
            StackFlowSensors.FLOW_TO_FILTER.toString(),
            SeriesType.WATER_FLOW_10_SECOND_AVERAGE,
          ])
        )
      )
    ),
    waterFlowInstant: props.dataMap.all.get(
      fullString(
        combine(
          props.parentAddress,
          create([
            StackSubsystem.WATER_FLOW_SENSOR,
            StackFlowSensors.FLOW_TO_FILTER.toString(),
            SeriesType.WATER_FLOW_INSTANT,
          ])
        )
      )
    ),
    waterFlowTotalVolume: props.dataMap.all.get(
      fullString(
        combine(
          props.parentAddress,
          create([
            StackSubsystem.WATER_FLOW_SENSOR,
            StackFlowSensors.FLOW_TO_FILTER.toString(),
            SeriesType.WATER_FLOW_TOTAL_VOLUME,
          ])
        )
      )
    ),
  };
  const flowToOverflow: FlowSensorData = {
    waterFlow10SecondAverage: props.dataMap.all.get(
      fullString(
        combine(
          props.parentAddress,
          create([
            StackSubsystem.WATER_FLOW_SENSOR,
            StackFlowSensors.FLOW_TO_OVERFLOW.toString(),
            SeriesType.WATER_FLOW_10_SECOND_AVERAGE,
          ])
        )
      )
    ),
    waterFlowInstant: props.dataMap.all.get(
      fullString(
        combine(
          props.parentAddress,
          create([
            StackSubsystem.WATER_FLOW_SENSOR,
            StackFlowSensors.FLOW_TO_OVERFLOW.toString(),
            SeriesType.WATER_FLOW_INSTANT,
          ])
        )
      )
    ),
    waterFlowTotalVolume: props.dataMap.all.get(
      fullString(
        combine(
          props.parentAddress,
          create([
            StackSubsystem.WATER_FLOW_SENSOR,
            StackFlowSensors.FLOW_TO_OVERFLOW.toString(),
            SeriesType.WATER_FLOW_TOTAL_VOLUME,
          ])
        )
      )
    ),
  };

  const airPressure = props.dataMap.all.get(
    fullString(
      combine(
        props.parentAddress,
        create([
          StackSubsystem.AIR_PRESSURE_SENSOR,
          '1', //TODO(austin): remove when URLs are simplified
          SeriesType.AIR_PRESSURE,
        ])
      )
    )
  );
  const controlledVariable = props.dataMap.all.get(
    fullString(
      combine(props.parentAddress, create([SeriesType.CONTROLLED_VARIABLE]))
    )
  );
  const drainValveState = props.dataMap.all.get(
    fullString(
      combine(
        props.parentAddress,
        create([StackSubsystem.DRAIN, SeriesType.VALVE_STATE])
      )
    )
  );
  const lightActualPoint = props.dataMap.all.get(
    fullString(
      combine(
        props.parentAddress,
        create([
          StackSubsystem.LIGHTS,
          SeriesType.BRIGHTNESS,
          SeriesType.ACTUAL_POINT,
        ])
      )
    )
  );
  const lightSetPoint = props.dataMap.all.get(
    fullString(
      combine(
        props.parentAddress,
        create([
          StackSubsystem.LIGHTS,
          SeriesType.BRIGHTNESS,
          SeriesType.SET_POINT,
        ])
      )
    )
  );
  const pumpCurrent = props.dataMap.all.get(
    fullString(
      combine(
        props.parentAddress,
        create([StackSubsystem.PUMP, SeriesType.CURRENT])
      )
    )
  );
  const pumpStatus = props.dataMap.all.get(
    fullString(
      combine(
        props.parentAddress,
        create([StackSubsystem.PUMP, SeriesType.PUMP_STATUS])
      )
    )
  );
  const pwm = props.dataMap.all.get(
    fullString(combine(props.parentAddress, create([SeriesType.PWM])))
  );
  const valveState = props.dataMap.all.get(
    fullString(combine(props.parentAddress, create([SeriesType.VALVE_STATE])))
  );
  const waterLevel = props.dataMap.all.get(
    fullString(
      combine(
        props.parentAddress,
        create([StackSubsystem.FISH_TANK, '1', SeriesType.WATER_LEVEL])
      )
    )
  );
  const waterPresent = props.dataMap.all.get(
    fullString(combine(props.parentAddress, create([SeriesType.WATER_PRESENT])))
  );
  const waterTemperature = props.dataMap.all.get(
    fullString(
      combine(
        props.parentAddress,
        create([
          StackSubsystem.TEMPERATURE_SENSOR,
          '1',
          SeriesType.WATER_TEMPERATURE,
        ])
      )
    )
  );

  const isOnlineData = props.dataMap.all.get(
    fullString(join(props.parentAddress, SeriesType.ONLINE))
  ).data;
  const isOnline = isOnlineData[0].reading.value;
  const timeSince = isOnlineData[0].timestamp;

  const firmwareVersion = props.dataMap.all.get(
    fullString(
      combine(
        props.parentAddress,
        create([
          SeriesType.VERSION,
          MicrocontrollerType.RASPBERRY_PI,
          SeriesType.FIRMWARE_VERSION,
        ])
      )
    )
  )
    ? props.dataMap.all.get(
        fullString(
          combine(
            props.parentAddress,
            create([
              SeriesType.VERSION,
              MicrocontrollerType.RASPBERRY_PI,
              SeriesType.FIRMWARE_VERSION,
            ])
          )
        )
      ).data[0].reading.value
    : props.dataMap.all.get(
        fullString(
          combine(
            props.parentAddress,
            create([SeriesType.VERSION, SeriesType.FIRMWARE_VERSION])
          )
        )
      ).data[0].reading.value;

  const firmwareVersionUpdate = props.dataMap.all.get(
    fullString(
      combine(
        props.parentAddress,
        create([
          SeriesType.VERSION,
          MicrocontrollerType.RASPBERRY_PI,
          SeriesType.FIRMWARE_VERSION_UPDATE,
        ])
      )
    )
  )
    ? props.dataMap.all.get(
        fullString(
          combine(
            props.parentAddress,
            create([
              SeriesType.VERSION,
              MicrocontrollerType.RASPBERRY_PI,
              SeriesType.FIRMWARE_VERSION_UPDATE,
            ])
          )
        )
      ).data[0].reading.value
    : props.dataMap.all.get(
        fullString(
          combine(
            props.parentAddress,
            create([SeriesType.VERSION, SeriesType.FIRMWARE_VERSION_UPDATE])
          )
        )
      ).data[0].reading.value;

  return (
    <>
      <OnlineStatus
        firmwareVersion={firmwareVersion}
        firmwareVersionUpdate={firmwareVersionUpdate}
        online={isOnline}
        ipAddress={
          props.dataMap.all.get(
            fullString(join(props.parentAddress, SeriesType.IP_ADDRESS))
          ).data[0].reading.value
        }
        timeSince={timeSince}
        url={props.url}
      />
      <StackData
        airPressure={airPressure}
        controlledVariable={controlledVariable}
        drainValveState={drainValveState}
        flowAtEndOfBlue={flowAtEndOfBlue}
        flowAtEndOfRed={flowAtEndOfRed}
        flowFromRedToTowers={flowFromRedToTowers}
        flowFromTank={flowFromTank}
        flowIntoBlueFromTowers={flowIntoBlueFromTowers}
        flowIntoRed={flowIntoRed}
        flowToFilter={flowToFilter}
        flowToOverflow={flowToOverflow}
        lightActualPoint={lightActualPoint}
        lightSetPoint={lightSetPoint}
        pwm={pwm}
        parentAddress={props.parentAddress}
        pumpCurrent={pumpCurrent}
        pumpStatus={pumpStatus}
        valveState={valveState}
        waterLevel={waterLevel}
        waterPresent={waterPresent}
        waterTemperature={waterTemperature}
      />
    </>
  );
};

export default StackInspectionPanel;
