/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';

import { useGLTF } from '@react-three/drei';

import { cssVar } from '../../..';
import WaterTankModel from './WaterTank.glb';

export default function WaterTank({ ...props }) {
  const { nodes } = useGLTF(WaterTankModel) as GLTF;
  const materialRef = useRef<THREE.Material>();
  const colorTweenRef = useRef<TWEEN.Tween>();
  const colorOverriden = useRef<boolean>(false);
  const colorOverrideRef = useRef(props.color);

  useEffect(() => {
    if (materialRef.current && !colorTweenRef.current) {
      if (
        props.colorOverride &&
        materialRef.current!.color.getHex() != props.colorOverride.getHex()
      ) {
        colorTweenRef.current = new TWEEN.Tween(materialRef.current!.color)
          .to(props.colorOverride, 1000)
          .easing(TWEEN.Easing.Sinusoidal.InOut)
          .onComplete(() => {
            colorTweenRef.current = undefined;
            colorOverriden.current = true;
          })
          .start();
      }
      if (
        !props.colorOverride &&
        '#' + materialRef.current!.color.getHexString() != props.color
      ) {
        colorTweenRef.current = new TWEEN.Tween(materialRef.current.color)
          .to(new THREE.Color(props.color), 1000)
          .easing(TWEEN.Easing.Sinusoidal.InOut)
          .onComplete(() => {
            colorTweenRef.current = null;
            colorOverriden.current = false;
          })
          .start();
      }
    }
  }, [props.colorOverride]);
  const material = (
    <meshPhongMaterial
      attach="material"
      color={colorOverriden.current ? colorOverrideRef.current : props.color}
      transparent={props.transparent}
      opacity={props.opacity}
      ref={materialRef}
    />
  );

  return (
    <group position={props.position} dispose={null} url={props.url}>
      <group position={[0, 0, 83.82]} rotation={[Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes.mesh_1.geometry}>{material}</mesh>
        <mesh geometry={nodes.mesh_2.geometry}>{material}</mesh>
        <mesh geometry={nodes.mesh_3.geometry}>{material}</mesh>
      </group>
      <group position={[0, 0, -6.9]} rotation={[-Math.PI / 2, 0, -Math.PI]}>
        <mesh geometry={nodes.mesh_0.geometry}>{material}</mesh>
      </group>
    </group>
  );
}

WaterTank.defaultProps = {
  position: [0, 0, 0],
  rotation: [0, 0, 0],
  transparent: false,
  opacity: 1.0,
  color: cssVar('--standard-color'),
};

useGLTF.preload(WaterTankModel);
