import React from 'react';
import styled from 'styled-components';

import { Path } from './IconStyling';

const Svg = styled.svg`
  padding: 0px 0x 0px 2px;
  align: center;
  float: left;
  height: var(--controlBarIconContainerHeight);
  width: var(--controlBarIconContainerWidth);
`;

const UiSliderIcon = () => {
  return (
    <Svg fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.425 10H26.5V11.5H23.425C23.05 13.225 21.55 14.5 19.75 14.5C17.95 14.5 16.45 13.225 16.075 11.5H5.5V10H16.075C16.45 8.275 17.95 7 19.75 7C21.55 7 23.05 8.275 23.425 10ZM17.5 10.75C17.5 12.025 18.475 13 19.75 13C21.025 13 22 12.025 22 10.75C22 9.475 21.025 8.5 19.75 8.5C18.475 8.5 17.5 9.475 17.5 10.75ZM8.575 22H5.5V20.5H8.575C8.95 18.775 10.45 17.5 12.25 17.5C14.05 17.5 15.55 18.775 15.925 20.5H26.5V22H15.925C15.55 23.725 14.05 25 12.25 25C10.45 25 8.95 23.725 8.575 22ZM14.5 21.25C14.5 19.975 13.525 19 12.25 19C10.975 19 10 19.975 10 21.25C10 22.525 10.975 23.5 12.25 23.5C13.525 23.5 14.5 22.525 14.5 21.25Z"
      />
    </Svg>
  );
};

export default UiSliderIcon;
