"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var DriveCommandType;

(function (DriveCommandType) {
  DriveCommandType["EMERGENCY_STOP"] = "emergencyStop";
  DriveCommandType["STOP"] = "stop";
  DriveCommandType["FORWARD"] = "forward";
  DriveCommandType["BACKWARD"] = "backward";
  DriveCommandType["LEFT"] = "left";
  DriveCommandType["RIGHT"] = "right";
  DriveCommandType["TURN_LEFT"] = "turnLeft";
  DriveCommandType["TURN_RIGHT"] = "turnRight";
  DriveCommandType["FORWARD_LEFT"] = "forwardLeft";
  DriveCommandType["FORWARD_RIGHT"] = "forwardRight";
  DriveCommandType["BACKWARD_LEFT"] = "backwardLeft";
  DriveCommandType["BACKWARD_RIGHT"] = "backwardRight";
  DriveCommandType["ACCELERATE"] = "accelerate";
})(DriveCommandType || (DriveCommandType = {}));

exports.default = DriveCommandType;