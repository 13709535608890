import React from 'react';

import TowerInspectionPanel from './TowerInspectionPanel';

const UmbilicalInspectionPanel = props => (
  <TowerInspectionPanel
    address={props.parentAddress}
    dataMap={props.dataMap}
    url={props.url}
  />
);

export default UmbilicalInspectionPanel;
