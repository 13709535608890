import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

export type TabBarProps = {
  selectedWindow: boolean;
};

const TabContainer = styled.div`
  padding-top: var(--spacingLarge);
  height: calc(var(--settingsWindowHeight) - 24px);
  border-radius: 0 0 0 var(--windowBorderRadius);
  display: inline-block;
  float: left;
  cursor: pointer;
  background-color: ${props =>
    props.selectedWindow
      ? 'var(--tabBarBackground_Default)'
      : 'var(--windowHeaderBackground_Backgrounded)'};
`;

const TabBar = (props: PropsWithChildren<TabBarProps>) => {
  return (
    <TabContainer selectedWindow={props.selectedWindow}>
      {props.children}
    </TabContainer>
  );
};

TabBar.defaultProps = { selected: false };

export default TabBar;
