import styled from 'styled-components';

const WindowDragHandle = styled.div`
  background-color: ${props =>
    props.selected
      ? 'var(--windowHeaderBackground_Default)'
      : 'var(--windowHeaderBackground_Backgrounded)'};
  border-radius: var(--windowHeaderBorderRadius);
  color: var(--black);
  cursor: pointer;
  height: var(--windowHeaderHeight);
  width: 100%;
`;

export default WindowDragHandle;
