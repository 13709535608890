/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';

import { useGLTF } from '@react-three/drei';

import { cssVar } from '../../..';
import GridArrowModel from './GridArrow.glb';

export default function GridArrow({ ...props }) {
  const group = useRef();
  const { nodes } = useGLTF(GridArrowModel) as GLTF;
  const material = (
    <meshPhongMaterial
      attach="material"
      color={cssVar('--cloud-produce-white')}
      transparent={props.transparent}
      opacity={props.opacity}
    />
  );
  return (
    <group
      ref={group}
      {...props}
      dispose={null}
      position={props.position}
      rotation={props.rotation}>
      <mesh geometry={nodes.mesh_0.geometry}>{material}</mesh>
    </group>
  );
}

GridArrow.defaultProps = {
  position: [0, 0, 0],
  rotation: [0, 0, 0],
  transparent: false,
  opacity: 1.0,
};

useGLTF.preload(GridArrowModel);
