import React, { Context } from 'react';

import {
  AlignmentOperation,
  OperationType,
  PlacementOperation,
} from './OperationContexts';

export const OperationContext = React.createContext<OperationContextType>({
  operationType: OperationType.NONE,
  updateContext: (
    newContextValue: OperationContextType,
    currentContextValue: OperationContextType
  ) => {},
  updateOperation: (
    operation: OperationType,
    contextValue: OperationContextType
  ) => {},
  setPlacementOperation: (
    placementOperation: PlacementOperation,
    currentContextValue: OperationContextType
  ) => {},
  setAlignmentOperation: (
    alignmentOperation: AlignmentOperation,
    currentContextValue: OperationContextType
  ) => {},
  setContext: contextValue => {},
  clearCurrentOperation: currentContextValue => {},
}) as Context<OperationContextType>;

export type OperationContextType = {
  operationType: OperationType;
  alignmentOperation?: AlignmentOperation;
  placementOperation?: PlacementOperation;
  updateOperation: (
    operation: OperationType,
    currentContextValue: OperationContextType
  ) => void;
  updateContext: (
    newContextValue: OperationContextType,
    currentContextValue: OperationContextType
  ) => void;
  setAlignmentOperation: (
    alignmentOperation: AlignmentOperation,
    currentContextValue: OperationContextType
  ) => void;
  setPlacementOperation: (
    placementOperation,
    currentContextValue: OperationContextType
  ) => void;
  clearCurrentOperation: (currentContextValue: OperationContextType) => void;
  setContext: (contextValue: OperationContextType) => void;
};
