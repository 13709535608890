import styled from 'styled-components';

export const OldNeumorphicButton = styled.button`
  width: fit-content;
  border: 0;
  outline: 0;
  font-family: var(--neumorphic-font);
  font-size: var(--neumorphic-grid-size);
  border-radius: var(--neumorphic-grid-size);
  padding: var(--neumorphic-grid-size);
  background-color: var(--neumorphic-background-color);
  text-shadow: 1px 1px 0 var(--neumorphic-light-shadow);
  color: var(--neumorphic-font-color);
  // box-shadow: -5px -5px 20px var(--neumorphic-light-shadow),
  // 5px 5px 20px var(--neumorphic-dark-shadow);
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:disabled {
    cursor: default;
    color: var(--cloud-produce-grey);
    // box-shadow: -0.5px -0.5px 1px var(--neumorphic-dark-shadow),
    // 0.5px 0.5px 1px var(--neumorphic-light-shadow);
  }

  &:hover:enabled {
    // box-shadow: -2px -2px 5px var(--neumorphic-light-shadow),
    // 2px 2px 5px var(--neumorphic-dark-shadow);
  }

  &:active:enabled {
    // box-shadow: inset 1px 1px 2px var(--neumorphic-dark-shadow),
    // inset -1px -1px 2px var(--neumorphic-light-shadow);
  }
`;
