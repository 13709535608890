/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';

import { useGLTF } from '@react-three/drei';

import { cssVar } from '../../..';
import LampsonFloorModel from './LampsonFloor.glb';

export default function LampsonFloor({ ...props }) {
  let color = cssVar('--mapFloor');
  const group = useRef();
  const { nodes } = useGLTF(LampsonFloorModel) as GLTF;
  const material: any = <meshPhongMaterial attach="material" color={color} />;
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh geometry={nodes.mesh_0.geometry}>{material}</mesh>
      <mesh geometry={nodes.mesh_1.geometry}>{material}</mesh>
    </group>
  );
}

useGLTF.preload(LampsonFloorModel);
