import React, { useContext } from 'react';
import styled from 'styled-components';

import UiHomeIcon from '../../../utopia/desktop/ui-icons/UiHomeIcon';
import {
  ControllerContext,
  ControllerContextType,
} from '../context/controllercontext/ControllerContext';
import {
  DesktopContext,
  DesktopContextType,
} from '../context/desktopcontext/DesktopContext';

const HomeContainer = styled.div`
  position: absolute;
  right: 116px; // TODO: get home icon spacing from Jamie
  top: 88px; // TODO: get home icon spacing from Jamie
`;

const Highlight = styled.div`
  height: var(--controlBarIconContainerHeight);
  cursor: pointer;
  background-color: transparent;
  color: var(--controlBarIcon_Default);

  &:hover {
    color: var(--controlBarIcon_Hover);
  }

  &:active {
    color: var(--controlBarIconBackground_Active);
  }

  &:disabled {
    color: var(--controlBarIcon_Disabled);
  }
`;

const HomeButton = props => {
  const controllerContext: ControllerContextType = useContext(
    ControllerContext
  );
  const desktopContext: DesktopContextType = useContext(DesktopContext);
  return (
    <HomeContainer>
      <Highlight
        onClick={() =>
          props.select(
            desktopContext.selectedUrl,
            controllerContext,
            desktopContext
          )
        }
        key={props.id}
        selected={props.selected}>
        <UiHomeIcon />
      </Highlight>
    </HomeContainer>
  );
};

export default HomeButton;
