import React, { PropsWithChildren } from 'react';

import ClassNames from '../../../ClassNames';
import { Background } from './Background';
import { ButtonProps, ForceInline, TextPadding } from './ButtonStyling';

const UiButton = (props: PropsWithChildren<ButtonProps>) => {
  return (
    <Background
      onClick={props.onClick}
      key={props.id}
      selected={props.selected}>
      {props.children}
      {props.text && (
        <ForceInline className={ClassNames.bodyRegular}>
          <TextPadding>{props.text}</TextPadding>
        </ForceInline>
      )}
    </Background>
  );
};

export default UiButton;

UiButton.defaultProps = {
  onClick: () => {},
  text: '',
  selected: false,
  id: -1,
};
