import {
  combine,
  create,
  fullString,
  join,
} from 'model/src/common/CloudProduceAddressUtility';
import { MicrocontrollerType } from 'model/src/common/Systems';
import { PageToPageServer } from 'model/src/dataflowprotocol/pagetopageserver/PageToPageServer';
import { PageToPageServerType } from 'model/src/dataflowprotocol/pagetopageserver/PageToPageServerType';
import { SeriesType } from 'model/src/series/SeriesTypes';
import React, { useContext } from 'react';

import { ConfirmationBoxWindowProps } from '../../../farm-ui/windows/ConfirmationBoxWindow';
import {
  DesktopContext,
  DesktopContextType,
} from '../../../three/farmviewer/context/desktopcontext/DesktopContext';
import WindowType from '../../window/WindowTypes';
import ContextButton from './ContextButton';

export default function RaspberryPiFirmwareContextButton(props) {
  const desktopContext: DesktopContextType = useContext(DesktopContext);

  let versionUrl = join(props.baseUrl, SeriesType.VERSION);
  let newVersionUrl = combine(
    props.baseUrl,
    create([SeriesType.VERSION, MicrocontrollerType.RASPBERRY_PI])
  );
  if (
    props.secondaryTargetUrl &&
    props.secondaryTargetUrl.segments.length === 5 &&
    props.secondaryTargetUrl.segments[3] === 'TIER'
  ) {
    versionUrl = join(props.secondaryTargetUrl, SeriesType.VERSION);
  }
  const firmwareVersion: string = props.dataMap.all.get(
    fullString(join(versionUrl, SeriesType.FIRMWARE_VERSION))
  )
    ? props.dataMap.all.get(
        fullString(join(versionUrl, SeriesType.FIRMWARE_VERSION))
      ).data[0].reading.value
    : props.dataMap.all.get(
        fullString(join(newVersionUrl, SeriesType.FIRMWARE_VERSION))
      ).data[0].reading.value;

  const firmwareVersionUpdate: string = props.dataMap.all.get(
    fullString(join(versionUrl, SeriesType.FIRMWARE_VERSION_UPDATE))
  )
    ? props.dataMap.all.get(
        fullString(join(versionUrl, SeriesType.FIRMWARE_VERSION_UPDATE))
      ).data[0].reading.value
    : props.dataMap.all.get(
        fullString(join(newVersionUrl, SeriesType.FIRMWARE_VERSION_UPDATE))
      ).data[0].reading.value;

  const firmwareUpdateNeeded: boolean =
    firmwareVersion !== firmwareVersionUpdate;

  const onClickWithConfirmation = (windowProps: ConfirmationBoxWindowProps) => {
    props.hideContextMenu();
    if (desktopContext.setModal) {
      desktopContext.setModal(
        WindowType.CONFIRMATION,
        windowProps,
        desktopContext
      );
    }
  };

  return (
    <>
      {firmwareUpdateNeeded && (
        <ContextButton
          onClick={() =>
            onClickWithConfirmation({
              yesButtonEventHandler: () => {
                const newCommand: PageToPageServer = {
                  type: PageToPageServerType.SendCommand,
                  sendCommand: {
                    targetId: fullString(props.targetUrl),
                    updateFirmwareCommand: {
                      targetId: props.secondaryTargetUrl
                        ? fullString(props.secondaryTargetUrl)
                        : undefined,
                      microcontrollerType: MicrocontrollerType.RASPBERRY_PI,
                    },
                  },
                };
                props.connectionToServer.sendMessage(newCommand);
                props.hideContextMenu();
              },
              queryText: 'Update raspberry pi firmware and reboot?',
            })
          }>
          Update Raspberry Pi firmware from {firmwareVersion} to{' '}
          {firmwareVersionUpdate}.
        </ContextButton>
      )}
    </>
  );
}
