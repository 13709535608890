import 'firebase/auth';

import * as firebase from 'firebase/app';
import React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import util from 'util';

import { Button } from '@material-ui/core';

const uiConfig = {
  signInFlow: 'popup',
  signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
};

const Unauthorized = () => {
  return (
    <div>
      <h1>Sorry, you do not have access to the dashboard.</h1>
      <Button
        variant="contained"
        color="primary"
        onClick={async () => {
          await firebase.auth().signOut();
          window.location.reload();
        }}>
        Sign Out
      </Button>
    </div>
  );
};

const SignIn = ({ onAuthenticationChange }: any) => {
  return (
    <StyledFirebaseAuth
      uiConfig={{
        ...uiConfig,
        callbacks: {
          signInSuccessWithAuthResult: ({ user }) => {
            onAuthenticationChange(user);
            return false;
          },
        },
      }}
      firebaseAuth={firebase.auth()}
    />
  );
};

const Auth = ({ auth, onAuthenticationChange }) => {
  const { authenticated, authorized } = auth;
  if (!authenticated) {
    return <SignIn onAuthenticationChange={onAuthenticationChange} />;
  }
  if (!authorized) {
    return <Unauthorized />;
  }
  return (
    <>
      <h1>YIKES</h1>
    </>
  );
};

export default Auth;
