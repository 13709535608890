import React, { useState } from 'react';
import ResizeObserver from 'react-resize-observer';
import {
  MAJOR_TICK_DIMENSION,
  STANDARD_TICK_DIMENSION,
  MINOR_TICK_DIMENSION,
} from './GridSizing';
import GridPoint, { GridPointOptions } from './GridPoint';
import { PointId } from 'model/src/common/Point';
import Palette from './Palette';
import { THEME } from './Theme';

type Props = {};

function Grid(props: Props) {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const gridPoints: any[] = [];
  for (let i = 0; i < width; i += THEME.minorGridDimension) {
    for (let j = 0; j < height; j += THEME.minorGridDimension) {
      let gridOptions: GridPointOptions;
      if (
        i % THEME.majorGridDimension === 0 &&
        j % THEME.majorGridDimension === 0
      ) {
        gridOptions = {
          color: 'gray',
          lineWidth: 1,
          tickDimension: MAJOR_TICK_DIMENSION,
          location: { id: PointId.degenerate(), x: i, y: j },
        };
      } else if (
        i % THEME.standardGridDimension === 0 &&
        j % THEME.standardGridDimension === 0
      ) {
        gridOptions = {
          color: 'gray',
          lineWidth: 0.75,
          tickDimension: STANDARD_TICK_DIMENSION,
          location: { id: PointId.degenerate(), x: i, y: j },
        };
      } else {
        gridOptions = {
          color: 'gray',
          lineWidth: 0.5,
          tickDimension: MINOR_TICK_DIMENSION,
          location: { id: PointId.degenerate(), x: i, y: j },
        };
      }
      gridPoints.push(<GridPoint key={i + '_' + j} options={gridOptions} />);
    }
  }

  return (
    <div
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        color: 'red',
        backgroundColor: 'transparent',
        pointerEvents: 'none',
      }}>
      <ResizeObserver
        onResize={rect => {
          setWidth(rect.width);
          setHeight(rect.height);
        }}
        onPosition={rect => {
          // No op.
        }}
      />
      <svg
        style={{
          position: 'absolute',
          left: '0px',
          top: '0px',
          width: '100%',
          height: '100%',
          pointerEvents: 'none',
        }}>
        {gridPoints}
        <Palette
          options={{
            colors: THEME.blues,
            location: {
              id: PointId.degenerate(),
              x: 2 * THEME.minorGridDimension,
              y: 2 * THEME.minorGridDimension,
            },
          }}
        />
        <Palette
          options={{
            colors: THEME.yellows,
            location: {
              id: PointId.degenerate(),
              x: 3 * THEME.minorGridDimension,
              y: 2 * THEME.minorGridDimension,
            },
          }}
        />
        <Palette
          options={{
            colors: THEME.reds,
            location: {
              id: PointId.degenerate(),
              x: 4 * THEME.minorGridDimension,
              y: 2 * THEME.minorGridDimension,
            },
          }}
        />
        <Palette
          options={{
            colors: THEME.greens,
            location: {
              id: PointId.degenerate(),
              x: 5 * THEME.minorGridDimension,
              y: 2 * THEME.minorGridDimension,
            },
          }}
        />
      </svg>
    </div>
  );
}

export default Grid;
