import TimerTemplate, {
  TimerTemplateUpdateType,
} from 'model/src/timer/TimerTemplate';
import React from 'react';

import ContextButton from './button/ContextButton';

export default function ModifyTimerTemplateContextMenu(props) {
  const timerTemplate: TimerTemplate = props.windowClickInfo.timerTemplate;
  const openModal = props.windowClickInfo.openModal;
  const hideContextMenu = props.windowClickInfo.hideContextMenu;

  return (
    <>
      {!timerTemplate.isEnabled && (
        <ContextButton
          onClick={() => {
            openModal(TimerTemplateUpdateType.ENABLE);
            hideContextMenu();
          }}>
          Enable
        </ContextButton>
      )}
      {timerTemplate.isEnabled && (
        <ContextButton
          onClick={() => {
            openModal(TimerTemplateUpdateType.DISABLE);
            hideContextMenu();
          }}>
          Disable
        </ContextButton>
      )}
      <ContextButton
        onClick={() => {
          openModal(TimerTemplateUpdateType.MODIFY);
          hideContextMenu();
        }}>
        Modify
      </ContextButton>
      <ContextButton
        onClick={() => {
          openModal(TimerTemplateUpdateType.REMOVE);
          hideContextMenu();
        }}>
        Remove
      </ContextButton>
    </>
  );
}
