import { farmId, toAddress } from 'model/src/common/CloudProduceAddressUtility';
import { PageToPageServer } from 'model/src/dataflowprotocol/pagetopageserver/PageToPageServer';
import { PageToPageServerType } from 'model/src/dataflowprotocol/pagetopageserver/PageToPageServerType';
import React, { useContext, useState } from 'react';
import {
  MILLISECONDS_PER_DAY,
  MILLISECONDS_PER_MINUTE,
} from 'services/src/common/Clock';
import styled from 'styled-components';

import ClassNames from '../../../ClassNames';
import { ConfirmationBoxWindowProps } from '../../../farm-ui/windows/ConfirmationBoxWindow';
import InjectionContext, {
  InjectionContextType,
} from '../../../injection/InjectionContext';
import {
  DesktopContext,
  DesktopContextType,
} from '../../../three/farmviewer/context/desktopcontext/DesktopContext';
import PrimaryButton from '../../window/components/PrimaryButton';
import WindowType from '../../window/WindowTypes';

const TitleLine = styled.div`
  padding-top: var(--spacingLarge);
  padding-left: var(--spacingMedium);
  padding-bottom: var(--spacingMedium);
  display: inline-block;
  width: auto;
`;

const TextLine = styled.div`
  padding-top: var(--spacingLarge);
  padding-left: var(--spacingMedium);
  display: inline-block;
  white-space: nowrap;
`;

const ForceRight = styled.div`
  padding-top: 20px;
  display: inline-block;
  float: right;
  padding-right: 16px;
`;

const Input = styled.input`
  color: var(--white);
  background-color: var(--black);
  width: 60px;
`;

const DataSettingsContent = props => {
  const desktopContext: DesktopContextType = useContext(DesktopContext);
  const injectionContext: InjectionContextType = useContext(InjectionContext);
  const [dataCutoffMs, setDataCutoffMs] = useState<number>(
    MILLISECONDS_PER_DAY
  );
  const updateDataCutoffMs = event => {
    setDataCutoffMs(event.target.value);
  };
  const onClickWithConfirmation = (windowProps: ConfirmationBoxWindowProps) => {
    if (desktopContext.setModal) {
      desktopContext.setModal(
        WindowType.CONFIRMATION,
        windowProps,
        desktopContext
      );
    }
  };
  return (
    <div>
      <TitleLine className={ClassNames.title3}>Data Settings</TitleLine>
      <br />
      <TextLine className={ClassNames.bodyRegular}>
        {'Archive Data older than (ms):'}
      </TextLine>
      <ForceRight>
        <Input
          type="number"
          value={dataCutoffMs}
          onChange={updateDataCutoffMs}
        />
        <PrimaryButton
          onClick={() =>
            onClickWithConfirmation({
              yesButtonEventHandler: () => {
                const newCommand: PageToPageServer = {
                  type: PageToPageServerType.ArchiveFarmDataCommand,
                  archiveFarmDataCommand: {
                    farmId: farmId(desktopContext.selectedUrl),
                    ageCutoffMs: dataCutoffMs,
                  },
                };
                const connectionToServer = injectionContext.pageKernel.getConnectionToServer();
                connectionToServer.sendMessage(newCommand);
              },
              queryText:
                'Archive all farm data older than ' +
                dataCutoffMs +
                ' milliseconds?',
            })
          }>
          {'Archive'}
        </PrimaryButton>
      </ForceRight>
    </div>
  );
};

export default DataSettingsContent;
