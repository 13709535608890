import {
  combine,
  create,
  fullString,
} from 'model/src/common/CloudProduceAddressUtility';
import { MicrocontrollerType } from 'model/src/common/Systems';
import { SeriesType } from 'model/src/series/SeriesTypes';
import { BuiltinType } from 'model/src/typescript/Typescript';
import React from 'react';

import OnlineStatus from '../../../../utopia/online-status/OnlineStatus';
import TrolleyPanel from '../TrolleyPanel';

const TugInspectionPanel = props => {
  const isOnlineData = props.dataMap.all.get(
    fullString(combine(props.address, create([SeriesType.ONLINE])))
  ).data;

  const isOnline = isOnlineData[0].reading.value;
  const timeSince = isOnlineData[0].timestamp;

  const firmwareVersion = props.dataMap.all.get(
    fullString(
      combine(
        props.address,
        create([
          SeriesType.VERSION,
          MicrocontrollerType.RASPBERRY_PI,
          SeriesType.FIRMWARE_VERSION,
        ])
      )
    )
  )
    ? props.dataMap.all.get(
        fullString(
          combine(
            props.address,
            create([
              SeriesType.VERSION,
              MicrocontrollerType.RASPBERRY_PI,
              SeriesType.FIRMWARE_VERSION,
            ])
          )
        )
      ).data[0].reading.value
    : props.dataMap.all.get(
        fullString(
          combine(
            props.address,
            create([SeriesType.VERSION, SeriesType.FIRMWARE_VERSION])
          )
        )
      ).data[0].reading.value;

  const firmwareVersionUpdate = props.dataMap.all.get(
    fullString(
      combine(
        props.address,
        create([
          SeriesType.VERSION,
          MicrocontrollerType.RASPBERRY_PI,
          SeriesType.FIRMWARE_VERSION_UPDATE,
        ])
      )
    )
  )
    ? props.dataMap.all.get(
        fullString(
          combine(
            props.address,
            create([
              SeriesType.VERSION,
              MicrocontrollerType.RASPBERRY_PI,
              SeriesType.FIRMWARE_VERSION_UPDATE,
            ])
          )
        )
      ).data[0].reading.value
    : props.dataMap.all.get(
        fullString(
          combine(
            props.address,
            create([SeriesType.VERSION, SeriesType.FIRMWARE_VERSION_UPDATE])
          )
        )
      ).data[0].reading.value;

  return (
    <>
      <OnlineStatus
        firmwareVersion={firmwareVersion}
        firmwareVersionUpdate={firmwareVersionUpdate}
        online={isOnline}
        ipAddress={
          props.dataMap.all.get(
            fullString(combine(props.address, create([SeriesType.IP_ADDRESS])))
          ).data[0].reading.value
        }
        timeSince={timeSince}
        url={props.url}
      />
      <div style={{ margin: 'auto' }}>
        <TrolleyPanel
          url={props.url}
          datum={props.dataMap.all.get(props.url + '.' + SeriesType.NAME)}
          // position={props.dataMap.all.get(
          //   props.url +
          //     urlCombine([SeriesType.POSITION])
          // )}
          online={isOnline}
          connectionToServer={props.connectionToServer}
          dataMap={props.dataMap}
        />
      </div>
    </>
  );
};

export default TugInspectionPanel;
