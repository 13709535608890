import React from 'react';
import { withStyles, Theme, WithStyles } from '@material-ui/core';

import { Component } from 'react';
import GlassPane from './GlassPane';
import Grid from './Grid';
import AddressForm from '../form/CreateFarmForm';
import { THEME } from './Theme';
//import util from 'util';
import Globe from './Globe';
import SelectedFarmControl from './SelectedFarmControl';
import styled from 'styled-components';
import { Button } from '../form/Button';
import { toRgbaString } from 'model/src/common/Color';

const AddFarmButton = styled(Button)`
  position: absolute;
  left: ${2 * THEME.standardGridDimension}px;
  bottom: ${2 * THEME.standardGridDimension}px;
  max-width: ${8 * THEME.standardGridDimension}px;
  min-width: ${8 * THEME.standardGridDimension}px;
  max-height: ${2 * THEME.standardGridDimension}px;
  min-height: ${2 * THEME.standardGridDimension}px;
`;

const GridButton = styled(Button)`
  position: absolute;
  right: ${1 * THEME.standardGridDimension}px;
  bottom: ${1 * THEME.standardGridDimension}px;
`;

const styles = (theme: Theme) => ({
  root: {
    position: 'absolute' as 'absolute',
    width: '100%',
    height: '100%',
  },
});

type Props = WithStyles & {
  farms: any[];
  index: number;
};
type State = {
  addingFarm: boolean;
  gridOn: boolean;
  showAddFarmMarker: boolean;
  farms: number;
  newFarm?: {
    name?: string;
    stacks?: number;
    stackSize?: number;
    coordinates?: any;
  };
  selectedFarm?: {
    id: string;
    name: string;
    coordinates: any;
  };
  index: number;
};

class ExplorerControls extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      addingFarm: false,
      gridOn: false,
      farms: 0,
      showAddFarmMarker: false,
      index: 0,
    };
  }

  enterAddFarmMode() {
    this.setState(prevState => {
      return {
        addingFarm: true,
      };
    });
  }

  render() {
    return (
      <>
        {this.state.addingFarm && (
          <GlassPane
            canClose={true}
            bottom={5 * THEME.standardGridDimension}
            left={2 * THEME.standardGridDimension}
            width={4 * THEME.majorGridDimension}
            height={6 * THEME.majorGridDimension}
            onClose={() => {
              this.setState(prevState => {
                return {
                  addingFarm: false,
                  newFarm: undefined,
                };
              });
            }}>
            <AddressForm
              onFacilitySituated={(
                name?: string,
                stacks?: number,
                stackSize?: number,
                coordinates?: any
              ) => {
                this.setState(prevState => {
                  return {
                    newFarm: {
                      name: name,
                      stacks: stacks,
                      stackSize: stackSize,
                      coordinates: coordinates,
                    },
                  };
                });
              }}
              onFarmCreated={(newFarm: {
                id: string;
                name: string;
                coordinates: { longitude: number; latitude: number };
              }) => {
                this.setState(
                  prevState => {
                    return {
                      addingFarm: false,
                      newFarm: undefined,
                      selectedFarm: {
                        id: newFarm.id,
                        name: newFarm.name,
                        coordinates: [
                          newFarm.coordinates.latitude,
                          newFarm.coordinates.longitude,
                        ],
                      },
                    };
                  },
                  () => {
                    this.doTimeOut();
                  }
                );
              }}
            />
          </GlassPane>
        )}

        {this.state.gridOn && <Grid />}

        {!this.state.selectedFarm && (
          <AddFarmButton
            color={toRgbaString(THEME.blues[0])}
            disabledColor={toRgbaString(THEME.blues[5])}
            showBorder={true}
            onClick={() => {
              this.enterAddFarmMode();
            }}>
            Add Farm
          </AddFarmButton>
        )}

        {this.state.selectedFarm && (
          <SelectedFarmControl
            farm={this.state.selectedFarm}
            farmDeselected={() => {
              console.log('from selected control: farm deselected');
              this.setState(prevState => {
                return {
                  selectedFarm: undefined,
                };
              });
            }}
          />
        )}

        <GridButton
          color={toRgbaString(THEME.blues[0])}
          disabledColor={toRgbaString(THEME.blues[5])}
          showBorder={false}
          onClick={() => {
            this.setState(prevState => {
              console.log('prevState: ' + prevState.gridOn);
              return {
                gridOn: !prevState.gridOn,
              };
            });
          }}>
          {!this.state.gridOn && 'GO'}
          {this.state.gridOn && 'GX'}
        </GridButton>

        <Globe
          addingFarm={this.state.addingFarm}
          farms={this.props.farms}
          newFarm={this.state.newFarm}
          selectedFarm={this.state.selectedFarm}
          index={this.state.index}
          onFarmSelected={(
            id: string,
            name: string,
            latitude: number,
            longitude: number
          ) => {
            console.log('on farm selected');
            if (!this.state.selectedFarm) {
              this.setState(prevState => {
                return {
                  selectedFarm: {
                    id: id,
                    name: name,
                    coordinates: [latitude, longitude],
                  },
                };
              });
            }
          }}
          onFarmDeselected={() => {
            console.log('on farm deselected');
            this.setState(prevState => {
              return {
                selectedFarm: undefined,
              };
            });
          }}
        />
      </>
    );
  }

  doTimeOut() {
    const self = this;
    setTimeout(function() {
      self.setState(prevState => {
        return {
          index: prevState.index + 1,
        };
      });
      if (i < limit) {
        i++;
        self.doTimeOut();
      } else {
        i = 0;
      }
    }, 3500);
  }
}

let i = 0;
const limit = 3;

export default withStyles(styles, { withTheme: true })(ExplorerControls);
