/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';

import { useGLTF } from '@react-three/drei';

import UmbilicalGantryZAxisSlide_Model from './UmbilicalGantryZAxisSlide.glb';

export default function UmbilicalGantryZAxisSlide(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF(UmbilicalGantryZAxisSlide_Model) as GLTF;
  return (
    <group ref={group} {...props} dispose={null}>
      <group
        position={[-81.03, -51.2, 50.51]}
        rotation={[Math.PI / 2, Math.PI / 2, 0]}>
        <mesh
          geometry={nodes.Slide_Plate.geometry}
          material={nodes.Slide_Plate.material}
        />
      </group>
      <group position={[-79.61, -52, 75.67]} rotation={[0, 0, -Math.PI / 2]}>
        <group position={[0, 0, 3.94]}>
          <mesh
            geometry={nodes.Body1_2.geometry}
            material={nodes.Body1_2.material}
          />
        </group>
        <group position={[0, 0, -0.8]} rotation={[Math.PI, 0, Math.PI]}>
          <mesh
            geometry={nodes.Body1_3.geometry}
            material={nodes.Body1_3.material}
          />
        </group>
        <group rotation={[Math.PI, 0, Math.PI]}>
          <mesh
            geometry={nodes.Body1_4.geometry}
            material={nodes.Body1_4.material}
          />
        </group>
        <group position={[0, 0, 4.74]}>
          <mesh
            geometry={nodes.Body1_5.geometry}
            material={nodes.Body1_5.material}
          />
        </group>
        <mesh
          geometry={nodes.HGW15CC1.geometry}
          material={nodes.HGW15CC1.material}
        />
      </group>
      <group position={[79.61, -52, 75.67]} rotation={[0, 0, Math.PI / 2]}>
        <group position={[0, 0, 3.94]}>
          <mesh
            geometry={nodes.Body1_6.geometry}
            material={nodes.Body1_6.material}
          />
        </group>
        <group position={[0, 0, -0.8]} rotation={[Math.PI, 0, Math.PI]}>
          <mesh
            geometry={nodes.Body1_7.geometry}
            material={nodes.Body1_7.material}
          />
        </group>
        <group rotation={[-Math.PI, 0, Math.PI]}>
          <mesh
            geometry={nodes.Body1_8.geometry}
            material={nodes.Body1_8.material}
          />
        </group>
        <group position={[0, 0, 4.74]}>
          <mesh
            geometry={nodes.Body1_9.geometry}
            material={nodes.Body1_9.material}
          />
        </group>
        <mesh
          geometry={nodes.HGW15CC1_1.geometry}
          material={nodes.HGW15CC1_1.material}
        />
      </group>
      <group
        position={[-78.79, -57, 79.29]}
        rotation={[-Math.PI, 0, -Math.PI / 2]}>
        <mesh geometry={nodes.Body1.geometry} material={nodes.Body1.material} />
      </group>
      <group
        position={[-74.79, -55, 50.51]}
        rotation={[Math.PI / 2, -Math.PI / 2, 0]}>
        <mesh
          geometry={nodes.NutConnector.geometry}
          material={nodes.NutConnector.material}
        />
      </group>
      <group
        position={[78.79, -57, 79.29]}
        rotation={[Math.PI, 0, Math.PI / 2]}>
        <mesh
          geometry={nodes.Body1_1.geometry}
          material={nodes.Body1_1.material}
        />
      </group>
      <group
        position={[81.03, -55, 50.51]}
        rotation={[Math.PI / 2, -Math.PI / 2, 0]}>
        <mesh
          geometry={nodes.NutConnector_1.geometry}
          material={nodes.NutConnector_1.material}
        />
      </group>
      <group
        position={[81.04, -55, 50.51]}
        rotation={[Math.PI / 2, -Math.PI / 2, 0]}>
        <mesh
          geometry={nodes.Slide_Plate_1.geometry}
          material={nodes.Slide_Plate_1.material}
        />
      </group>
    </group>
  );
}

useGLTF.preload(UmbilicalGantryZAxisSlide_Model);
