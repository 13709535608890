import React from 'react';
import styled from 'styled-components';

import { Canvas } from '@react-three/fiber';

import ControllerContextProvider from '../context/controllercontext/ControllerContextProvider';
import HomeButton from './HomeButton';
import { ViewCubeScene } from './ViewCubeScene';

const Background = styled.div`
  background-color: transparent;
  display: inline;
  height: calc(var(--spacingGiant) * 2);
  width: calc(var(--spacingGiant) * 2);

  position: absolute;
  top: calc(var(--spacingGiant) + var(--spacingExtraLarge));
  right: var(--spacingMedium);
`;

export const ViewCubeContainer = ({
  cameraMatrix,
  select,
  setViewCubeRotation,
}) => {
  return (
    <>
      <Background>
        <Canvas>
          <ControllerContextProvider>
            <ViewCubeScene
              cameraMatrix={cameraMatrix}
              setViewCubeRotation={setViewCubeRotation}
            />
          </ControllerContextProvider>
        </Canvas>
      </Background>
      <HomeButton select={select} />
    </>
  );
};
