/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { Suspense, useRef } from 'react';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';

import { useGLTF } from '@react-three/drei';

import Box from '../common/Box';
import materialFactory from '../trolley/MaterialFactory';
import TugFirstFloorModel from './TugFirstFloor.glb';

export default function TugFirstFloor(props) {
  const group = useRef();
  const { nodes } = useGLTF(TugFirstFloorModel) as GLTF;

  const material: any = materialFactory.generateMaterial(
    props.parentUnderInspection,
    props.parentHovered,
    props.visualOverrides.transparent,
    props.visualOverrides.opacity,
    props.online
  );

  return (
    <Suspense fallback={<Box />}>
      <group ref={group} {...props} dispose={null}>
        <group name="FirstFloor_v1">
          <group
            name="Base_Top_Connector_Plate_v4"
            position={[58.5, 0.32, 8]}
            rotation={[0, 0, Math.PI / 2]}>
            <mesh name="mesh_0" geometry={nodes.mesh_0.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="Base_Top_Connector_Plate_v4_1"
            position={[-57.86, 0.32, 8]}
            rotation={[0, 0, Math.PI / 2]}>
            <mesh name="mesh_1" geometry={nodes.mesh_1.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="Base_Top_Connector_Plate_v4_2"
            position={[0.32, -58.5, 8]}>
            <mesh name="mesh_2" geometry={nodes.mesh_2.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="Base_Top_Connector_Plate_v4_3"
            position={[0.32, 57.86, 8]}>
            <mesh name="mesh_3" geometry={nodes.mesh_3.geometry}>
              {material}
            </mesh>
          </group>
          <group name="RH_Wheel" rotation={[0, 0, -Math.PI]}>
            <group
              name="DriveTrain_Mount_v30_(1)"
              position={[40.03, -42.52, 4]}
              rotation={[0, 0, -Math.PI / 2]}>
              <group name="Box_(1)_1" position={[0, -0.03, 0]}>
                <group
                  name="am-2986_FR8ZZ_Hex_Bearing_v2_v1_6"
                  position={[0, 0.54, 0]}
                  rotation={[-Math.PI / 2, 0, -Math.PI / 6]}>
                  <mesh name="mesh_4" geometry={nodes.mesh_4.geometry}>
                    {material}
                  </mesh>
                </group>
                <group
                  name="am-2986_FR8ZZ_Hex_Bearing_v2_v1_7"
                  position={[0, 5.17, 0]}
                  rotation={[-Math.PI / 2, 0, -Math.PI / 6]}>
                  <mesh name="mesh_5" geometry={nodes.mesh_5.geometry}>
                    {material}
                  </mesh>
                </group>
                <group
                  name="REV-11-1271_v2_2"
                  position={[0, 1.58, 0]}
                  rotation={[-Math.PI / 2, 0, -1.83]}>
                  <group name="REV-11-1271-1_2" rotation={[0, 0, -0.42]}>
                    <mesh name="mesh_6" geometry={nodes.mesh_6.geometry}>
                      {material}
                    </mesh>
                  </group>
                  <group
                    name="REV-11-1271-4_2"
                    position={[1.74, -0.77, 0.68]}
                    rotation={[-Math.PI, 0, 1.34]}>
                    <mesh name="mesh_7" geometry={nodes.mesh_7.geometry}>
                      {material}
                    </mesh>
                  </group>
                  <group
                    name="REV-11-1271-0_2"
                    position={[1.74, -0.77, 0.98]}
                    rotation={[-Math.PI, 0, 0.42]}>
                    <mesh name="mesh_8" geometry={nodes.mesh_8.geometry}>
                      {material}
                    </mesh>
                  </group>
                  <group
                    name="6703-ZZ_Bearing_4"
                    position={[0, 0, 0.1]}
                    rotation={[0, 0, -0.37]}>
                    <mesh name="mesh_9" geometry={nodes.mesh_9.geometry}>
                      {material}
                    </mesh>
                  </group>
                  <group
                    name="128-2Z_Bearing_7804K145_2"
                    position={[1.74, -0.77, 0.34]}
                    rotation={[Math.PI, 0, -3.12]}>
                    <mesh name="mesh_10" geometry={nodes.mesh_10.geometry}>
                      {material}
                    </mesh>
                    <mesh name="mesh_11" geometry={nodes.mesh_11.geometry}>
                      {material}
                    </mesh>
                    <mesh name="mesh_12" geometry={nodes.mesh_12.geometry}>
                      {material}
                    </mesh>
                    <mesh name="mesh_13" geometry={nodes.mesh_13.geometry}>
                      {material}
                    </mesh>
                    <mesh name="mesh_14" geometry={nodes.mesh_14.geometry}>
                      {material}
                    </mesh>
                    <mesh name="mesh_15" geometry={nodes.mesh_15.geometry}>
                      {material}
                    </mesh>
                    <mesh name="mesh_16" geometry={nodes.mesh_16.geometry}>
                      {material}
                    </mesh>
                    <mesh name="mesh_17" geometry={nodes.mesh_17.geometry}>
                      {material}
                    </mesh>
                    <mesh name="mesh_18" geometry={nodes.mesh_18.geometry}>
                      {material}
                    </mesh>
                    <mesh name="mesh_19" geometry={nodes.mesh_19.geometry}>
                      {material}
                    </mesh>
                    <mesh name="mesh_20" geometry={nodes.mesh_20.geometry}>
                      {material}
                    </mesh>
                    <mesh name="mesh_21" geometry={nodes.mesh_21.geometry}>
                      {material}
                    </mesh>
                    <mesh name="mesh_22" geometry={nodes.mesh_22.geometry}>
                      {material}
                    </mesh>
                    <mesh name="mesh_23" geometry={nodes.mesh_23.geometry}>
                      {material}
                    </mesh>
                    <mesh name="mesh_24" geometry={nodes.mesh_24.geometry}>
                      {material}
                    </mesh>
                    <mesh name="mesh_25" geometry={nodes.mesh_25.geometry}>
                      {material}
                    </mesh>
                  </group>
                  <group
                    name="REV-11-1271-5_2"
                    position={[1.74, -0.77, 0.42]}
                    rotation={[Math.PI / 2, 1.02, 0]}>
                    <mesh name="mesh_26" geometry={nodes.mesh_26.geometry}>
                      {material}
                    </mesh>
                  </group>
                  <group
                    name="S6B-PH-K-S_2"
                    position={[1.65, -2.02, 0.22]}
                    rotation={[Math.PI / 2, 1.15, -Math.PI]}>
                    <mesh name="mesh_27" geometry={nodes.mesh_27.geometry}>
                      {material}
                    </mesh>
                  </group>
                  <group
                    name="cross_recessed_thread_forming_screws_gb_GB_CROSS_SCREWS_TYPE138_M2X8X72-N_8"
                    position={[2.31, -2.18, 1.32]}
                    rotation={[Math.PI / 2, 1.15, -Math.PI / 2]}>
                    <mesh name="mesh_28" geometry={nodes.mesh_28.geometry}>
                      {material}
                    </mesh>
                  </group>
                  <group
                    name="cross_recessed_thread_forming_screws_gb_GB_CROSS_SCREWS_TYPE138_M2X8X72-N_9"
                    position={[3.17, -0.26, 1.32]}
                    rotation={[Math.PI / 2, 1.15, -Math.PI / 2]}>
                    <mesh name="mesh_29" geometry={nodes.mesh_29.geometry}>
                      {material}
                    </mesh>
                  </group>
                  <group
                    name="cross_recessed_thread_forming_screws_gb_GB_CROSS_SCREWS_TYPE138_M2X8X72-N_10"
                    position={[1.43, 0.46, 1.32]}
                    rotation={[Math.PI / 2, 1.15, -Math.PI / 2]}>
                    <mesh name="mesh_30" geometry={nodes.mesh_30.geometry}>
                      {material}
                    </mesh>
                  </group>
                  <group
                    name="cross_recessed_thread_forming_screws_gb_GB_CROSS_SCREWS_TYPE138_M2X8X72-N_11"
                    position={[0.62, -1.37, 1.32]}
                    rotation={[Math.PI / 2, 1.15, -Math.PI / 2]}>
                    <mesh name="mesh_31" geometry={nodes.mesh_31.geometry}>
                      {material}
                    </mesh>
                  </group>
                  <group
                    name="REV-11-1271-3_Alt_2"
                    position={[0, 0, 0.55]}
                    rotation={[0, 0, -2.09]}>
                    <mesh name="mesh_32" geometry={nodes.mesh_32.geometry}>
                      {material}
                    </mesh>
                  </group>
                  <group
                    name="6703-ZZ_Bearing_5"
                    position={[0, 0, 0.85]}
                    rotation={[0, 0, -0.42]}>
                    <mesh name="mesh_33" geometry={nodes.mesh_33.geometry}>
                      {material}
                    </mesh>
                  </group>
                  <group
                    name="REV-11-1271-2_ALT_2"
                    position={[3.87, -0.21, 1.23]}
                    rotation={[Math.PI, 0, 0.42]}>
                    <mesh name="mesh_34" geometry={nodes.mesh_34.geometry}>
                      {material}
                    </mesh>
                  </group>
                </group>
                <group
                  name="am-2986_FR8ZZ_Hex_Bearing_v2_v1_8"
                  position={[-0.02, 17.86, 0]}
                  rotation={[-Math.PI / 2, 0, -Math.PI / 6]}>
                  <mesh name="mesh_35" geometry={nodes.mesh_35.geometry}>
                    {material}
                  </mesh>
                </group>
                <group
                  name="Precision_Shim_10_x_5_x_1_v2_8"
                  position={[2.54, 1.18, 0]}>
                  <mesh name="mesh_36" geometry={nodes.mesh_36.geometry}>
                    {material}
                  </mesh>
                </group>
                <group
                  name="Precision_Shim_10_x_5_x_1_v2_9"
                  position={[2.54, 1.28, 0]}>
                  <mesh name="mesh_37" geometry={nodes.mesh_37.geometry}>
                    {material}
                  </mesh>
                </group>
                <group
                  name="Precision_Shim_10_x_5_x_1_v2_10"
                  position={[-2.54, 1.28, 0]}>
                  <mesh name="mesh_38" geometry={nodes.mesh_38.geometry}>
                    {material}
                  </mesh>
                </group>
                <group
                  name="Precision_Shim_10_x_5_x_1_v2_11"
                  position={[-2.54, 1.18, 0]}>
                  <mesh name="mesh_39" geometry={nodes.mesh_39.geometry}>
                    {material}
                  </mesh>
                </group>
                <group
                  name="Aluminum_Spacer_5mm_v3_8"
                  position={[-1.8, -0.01, -1.8]}
                  rotation={[-Math.PI / 2, 0, 0]}>
                  <mesh name="mesh_40" geometry={nodes.mesh_40.geometry}>
                    {material}
                  </mesh>
                </group>
                <group
                  name="Aluminum_Spacer_5mm_v3_9"
                  position={[1.8, -0.01, 1.8]}
                  rotation={[-Math.PI / 2, 0, 0]}>
                  <mesh name="mesh_41" geometry={nodes.mesh_41.geometry}>
                    {material}
                  </mesh>
                </group>
                <group
                  name="Aluminum_Spacer_5mm_v3_10"
                  position={[-2.54, -0.01, 0]}
                  rotation={[-Math.PI / 2, 0, 0]}>
                  <mesh name="mesh_42" geometry={nodes.mesh_42.geometry}>
                    {material}
                  </mesh>
                </group>
                <group
                  name="Aluminum_Spacer_5mm_v3_11"
                  position={[2.54, -0.01, 0]}
                  rotation={[-Math.PI / 2, 0, 0]}>
                  <mesh name="mesh_43" geometry={nodes.mesh_43.geometry}>
                    {material}
                  </mesh>
                </group>
                <mesh name="mesh_44" geometry={nodes.mesh_44.geometry}>
                  {material}
                </mesh>
                <mesh name="mesh_45" geometry={nodes.mesh_45.geometry}>
                  {material}
                </mesh>
                <mesh name="mesh_46" geometry={nodes.mesh_46.geometry}>
                  {material}
                </mesh>
              </group>
              <mesh name="mesh_47" geometry={nodes.mesh_47.geometry}>
                {material}
              </mesh>
              <mesh name="mesh_48" geometry={nodes.mesh_48.geometry}>
                {material}
              </mesh>
              <mesh name="mesh_49" geometry={nodes.mesh_49.geometry}>
                {material}
              </mesh>
            </group>
          </group>
          <group name="LH_Wheel" rotation={[0, 0, -Math.PI]}>
            <group
              name="DriveTrain_Mount_v30"
              position={[-40.03, -42.48, 4]}
              rotation={[0, 0, Math.PI / 2]}>
              <group
                name="am-2986_FR8ZZ_Hex_Bearing_v2_v1_9"
                position={[0, 0.54, 0]}
                rotation={[-Math.PI / 2, 0, -Math.PI / 6]}>
                <mesh name="mesh_50" geometry={nodes.mesh_50.geometry}>
                  {material}
                </mesh>
              </group>
              <group
                name="am-2986_FR8ZZ_Hex_Bearing_v2_v1_10"
                position={[0, 5.79, 0]}
                rotation={[-Math.PI / 2, 0, -Math.PI / 6]}>
                <mesh name="mesh_51" geometry={nodes.mesh_51.geometry}>
                  {material}
                </mesh>
              </group>
              <group
                name="REV-11-1271_v2_3"
                rotation={[-Math.PI / 2, 0, -2.62]}>
                <group
                  name="REV-11-1271-4_3"
                  position={[1.9, 0, 0.68]}
                  rotation={[-Math.PI, 0, 0.92]}>
                  <mesh name="mesh_52" geometry={nodes.mesh_52.geometry}>
                    {material}
                  </mesh>
                </group>
                <group
                  name="REV-11-1271-0_3"
                  position={[1.9, 0, 0.98]}
                  rotation={[-Math.PI, 0, 0]}>
                  <mesh name="mesh_53" geometry={nodes.mesh_53.geometry}>
                    {material}
                  </mesh>
                </group>
                <group
                  name="6703-ZZ_Bearing_6"
                  position={[0, 0, 0.1]}
                  rotation={[0, 0, 0.05]}>
                  <mesh name="mesh_54" geometry={nodes.mesh_54.geometry}>
                    {material}
                  </mesh>
                </group>
                <group
                  name="128-2Z_Bearing_7804K145_3"
                  position={[1.9, 0, 0.34]}
                  rotation={[Math.PI, 0, 2.75]}>
                  <mesh name="mesh_55" geometry={nodes.mesh_55.geometry}>
                    {material}
                  </mesh>
                  <mesh name="mesh_56" geometry={nodes.mesh_56.geometry}>
                    {material}
                  </mesh>
                  <mesh name="mesh_57" geometry={nodes.mesh_57.geometry}>
                    {material}
                  </mesh>
                  <mesh name="mesh_58" geometry={nodes.mesh_58.geometry}>
                    {material}
                  </mesh>
                  <mesh name="mesh_59" geometry={nodes.mesh_59.geometry}>
                    {material}
                  </mesh>
                  <mesh name="mesh_60" geometry={nodes.mesh_60.geometry}>
                    {material}
                  </mesh>
                  <mesh name="mesh_61" geometry={nodes.mesh_61.geometry}>
                    {material}
                  </mesh>
                  <mesh name="mesh_62" geometry={nodes.mesh_62.geometry}>
                    {material}
                  </mesh>
                  <mesh name="mesh_63" geometry={nodes.mesh_63.geometry}>
                    {material}
                  </mesh>
                  <mesh name="mesh_64" geometry={nodes.mesh_64.geometry}>
                    {material}
                  </mesh>
                  <mesh name="mesh_65" geometry={nodes.mesh_65.geometry}>
                    {material}
                  </mesh>
                  <mesh name="mesh_66" geometry={nodes.mesh_66.geometry}>
                    {material}
                  </mesh>
                  <mesh name="mesh_67" geometry={nodes.mesh_67.geometry}>
                    {material}
                  </mesh>
                  <mesh name="mesh_68" geometry={nodes.mesh_68.geometry}>
                    {material}
                  </mesh>
                  <mesh name="mesh_69" geometry={nodes.mesh_69.geometry}>
                    {material}
                  </mesh>
                  <mesh name="mesh_70" geometry={nodes.mesh_70.geometry}>
                    {material}
                  </mesh>
                </group>
                <group
                  name="REV-11-1271-5_3"
                  position={[1.9, 0, 0.42]}
                  rotation={[Math.PI / 2, 1.44, 0]}>
                  <mesh name="mesh_71" geometry={nodes.mesh_71.geometry}>
                    {material}
                  </mesh>
                </group>
                <group
                  name="S6B-PH-K-S_3"
                  position={[2.33, -1.17, 0.22]}
                  rotation={[-Math.PI / 2, Math.PI / 2, 0]}>
                  <mesh name="mesh_72" geometry={nodes.mesh_72.geometry}>
                    {material}
                  </mesh>
                </group>
                <group
                  name="cross_recessed_thread_forming_screws_gb_GB_CROSS_SCREWS_TYPE138_M2X8X72-N_12"
                  position={[3, -1.05, 1.32]}
                  rotation={[0, 1.57, 0]}>
                  <mesh name="mesh_73" geometry={nodes.mesh_73.geometry}>
                    {material}
                  </mesh>
                </group>
                <group
                  name="cross_recessed_thread_forming_screws_gb_GB_CROSS_SCREWS_TYPE138_M2X8X72-N_13"
                  position={[3, 1.05, 1.32]}
                  rotation={[0, 1.57, 0]}>
                  <mesh name="mesh_74" geometry={nodes.mesh_74.geometry}>
                    {material}
                  </mesh>
                </group>
                <group
                  name="cross_recessed_thread_forming_screws_gb_GB_CROSS_SCREWS_TYPE138_M2X8X72-N_14"
                  position={[1.13, 1, 1.32]}
                  rotation={[0, 1.57, 0]}>
                  <mesh name="mesh_75" geometry={nodes.mesh_75.geometry}>
                    {material}
                  </mesh>
                </group>
                <group
                  name="cross_recessed_thread_forming_screws_gb_GB_CROSS_SCREWS_TYPE138_M2X8X72-N_15"
                  position={[1.13, -1, 1.32]}
                  rotation={[0, 1.57, 0]}>
                  <mesh name="mesh_76" geometry={nodes.mesh_76.geometry}>
                    {material}
                  </mesh>
                </group>
                <group
                  name="REV-11-1271-3_Alt_3"
                  position={[0, 0, 0.55]}
                  rotation={[0, 0, -2.09]}>
                  <mesh name="mesh_77" geometry={nodes.mesh_77.geometry}>
                    {material}
                  </mesh>
                </group>
                <group name="6703-ZZ_Bearing_7" position={[0, 0, 0.85]}>
                  <mesh name="mesh_78" geometry={nodes.mesh_78.geometry}>
                    {material}
                  </mesh>
                </group>
                <group
                  name="REV-11-1271-2_ALT_3"
                  position={[3.62, 1.38, 1.23]}
                  rotation={[Math.PI, 0, 0]}>
                  <mesh name="mesh_79" geometry={nodes.mesh_79.geometry}>
                    {material}
                  </mesh>
                </group>
                <mesh name="mesh_80" geometry={nodes.mesh_80.geometry}>
                  {material}
                </mesh>
              </group>
              <group
                name="am-2986_FR8ZZ_Hex_Bearing_v2_v1_11"
                position={[0, 17.85, 0]}
                rotation={[Math.PI / 2, 0, 2.62]}>
                <mesh name="mesh_81" geometry={nodes.mesh_81.geometry}>
                  {material}
                </mesh>
              </group>
              <group
                name="Precision_Shim_10_x_5_x_1_v2_12"
                position={[2.54, 1.15, 0]}>
                <mesh name="mesh_82" geometry={nodes.mesh_82.geometry}>
                  {material}
                </mesh>
              </group>
              <group
                name="Precision_Shim_10_x_5_x_1_v2_13"
                position={[2.54, 1.25, 0]}>
                <mesh name="mesh_83" geometry={nodes.mesh_83.geometry}>
                  {material}
                </mesh>
              </group>
              <group
                name="Precision_Shim_10_x_5_x_1_v2_14"
                position={[-2.54, 1.25, 0]}>
                <mesh name="mesh_84" geometry={nodes.mesh_84.geometry}>
                  {material}
                </mesh>
              </group>
              <group
                name="Precision_Shim_10_x_5_x_1_v2_15"
                position={[-2.54, 1.15, 0]}>
                <mesh name="mesh_85" geometry={nodes.mesh_85.geometry}>
                  {material}
                </mesh>
              </group>
              <group
                name="Aluminum_Spacer_5mm_v3_12"
                position={[-1.8, -0.03, -1.8]}
                rotation={[-Math.PI / 2, 0, 0]}>
                <mesh name="mesh_86" geometry={nodes.mesh_86.geometry}>
                  {material}
                </mesh>
              </group>
              <group
                name="Aluminum_Spacer_5mm_v3_13"
                position={[1.8, -0.03, 1.8]}
                rotation={[-Math.PI / 2, 0, 0]}>
                <mesh name="mesh_87" geometry={nodes.mesh_87.geometry}>
                  {material}
                </mesh>
              </group>
              <group
                name="Aluminum_Spacer_5mm_v3_14"
                position={[-2.54, -0.03, 0]}
                rotation={[-Math.PI / 2, 0, 0]}>
                <mesh name="mesh_88" geometry={nodes.mesh_88.geometry}>
                  {material}
                </mesh>
              </group>
              <group
                name="Aluminum_Spacer_5mm_v3_15"
                position={[2.54, -0.03, 0]}
                rotation={[-Math.PI / 2, 0, 0]}>
                <mesh name="mesh_89" geometry={nodes.mesh_89.geometry}>
                  {material}
                </mesh>
              </group>
              <mesh name="mesh_90" geometry={nodes.mesh_90.geometry}>
                {material}
              </mesh>
              <mesh name="mesh_91" geometry={nodes.mesh_91.geometry}>
                {material}
              </mesh>
              <mesh name="mesh_92" geometry={nodes.mesh_92.geometry}>
                {material}
              </mesh>
              <mesh name="mesh_93" geometry={nodes.mesh_93.geometry}>
                {material}
              </mesh>
              <mesh name="mesh_94" geometry={nodes.mesh_94.geometry}>
                {material}
              </mesh>
            </group>
          </group>
          <mesh name="mesh_95" geometry={nodes.mesh_95.geometry}>
            {material}
          </mesh>
          <mesh name="mesh_96" geometry={nodes.mesh_96.geometry}>
            {material}
          </mesh>
          <mesh name="mesh_97" geometry={nodes.mesh_97.geometry}>
            {material}
          </mesh>
          <mesh name="mesh_98" geometry={nodes.mesh_98.geometry}>
            {material}
          </mesh>
          <group
            name="RH_Wheel_1"
            position={[40.03, -42.52, 4]}
            rotation={[0, 0, -Math.PI / 2]}>
            <group
              name="am-2986_FR8ZZ_Hex_Bearing_v2_v1"
              position={[0, 0.54, 0]}
              rotation={[-Math.PI / 2, 0, -Math.PI / 6]}>
              <mesh name="mesh_99" geometry={nodes.mesh_99.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="am-2986_FR8ZZ_Hex_Bearing_v2_v1_1"
              position={[0, 5.79, 0]}
              rotation={[-Math.PI / 2, 0, -Math.PI / 6]}>
              <mesh name="mesh_100" geometry={nodes.mesh_100.geometry}>
                {material}
              </mesh>
            </group>
            <group name="REV-11-1271_v2" rotation={[-Math.PI / 2, 0, -2.62]}>
              <group
                name="REV-11-1271-4"
                position={[1.9, 0, 0.68]}
                rotation={[-Math.PI, 0, 0.92]}>
                <mesh name="mesh_101" geometry={nodes.mesh_101.geometry}>
                  {material}
                </mesh>
              </group>
              <group
                name="REV-11-1271-0"
                position={[1.9, 0, 0.98]}
                rotation={[-Math.PI, 0, 0]}>
                <mesh name="mesh_102" geometry={nodes.mesh_102.geometry}>
                  {material}
                </mesh>
              </group>
              <group
                name="6703-ZZ_Bearing"
                position={[0, 0, 0.1]}
                rotation={[0, 0, 0.05]}>
                <mesh name="mesh_103" geometry={nodes.mesh_103.geometry}>
                  {material}
                </mesh>
              </group>
              <group
                name="128-2Z_Bearing_7804K145"
                position={[1.9, 0, 0.34]}
                rotation={[-Math.PI, 0, 2.75]}>
                <mesh name="mesh_104" geometry={nodes.mesh_104.geometry}>
                  {material}
                </mesh>
                <mesh name="mesh_105" geometry={nodes.mesh_105.geometry}>
                  {material}
                </mesh>
                <mesh name="mesh_106" geometry={nodes.mesh_106.geometry}>
                  {material}
                </mesh>
                <mesh name="mesh_107" geometry={nodes.mesh_107.geometry}>
                  {material}
                </mesh>
                <mesh name="mesh_108" geometry={nodes.mesh_108.geometry}>
                  {material}
                </mesh>
                <mesh name="mesh_109" geometry={nodes.mesh_109.geometry}>
                  {material}
                </mesh>
                <mesh name="mesh_110" geometry={nodes.mesh_110.geometry}>
                  {material}
                </mesh>
                <mesh name="mesh_111" geometry={nodes.mesh_111.geometry}>
                  {material}
                </mesh>
                <mesh name="mesh_112" geometry={nodes.mesh_112.geometry}>
                  {material}
                </mesh>
                <mesh name="mesh_113" geometry={nodes.mesh_113.geometry}>
                  {material}
                </mesh>
                <mesh name="mesh_114" geometry={nodes.mesh_114.geometry}>
                  {material}
                </mesh>
                <mesh name="mesh_115" geometry={nodes.mesh_115.geometry}>
                  {material}
                </mesh>
                <mesh name="mesh_116" geometry={nodes.mesh_116.geometry}>
                  {material}
                </mesh>
                <mesh name="mesh_117" geometry={nodes.mesh_117.geometry}>
                  {material}
                </mesh>
                <mesh name="mesh_118" geometry={nodes.mesh_118.geometry}>
                  {material}
                </mesh>
                <mesh name="mesh_119" geometry={nodes.mesh_119.geometry}>
                  {material}
                </mesh>
              </group>
              <group
                name="REV-11-1271-5"
                position={[1.9, 0, 0.42]}
                rotation={[Math.PI / 2, 1.44, 0]}>
                <mesh name="mesh_120" geometry={nodes.mesh_120.geometry}>
                  {material}
                </mesh>
              </group>
              <group
                name="S6B-PH-K-S"
                position={[2.33, -1.17, 0.22]}
                rotation={[-Math.PI / 2, Math.PI / 2, 0]}>
                <mesh name="mesh_121" geometry={nodes.mesh_121.geometry}>
                  {material}
                </mesh>
              </group>
              <group
                name="cross_recessed_thread_forming_screws_gb_GB_CROSS_SCREWS_TYPE138_M2X8X72-N"
                position={[3, -1.05, 1.32]}
                rotation={[0, 1.57, 0]}>
                <mesh name="mesh_122" geometry={nodes.mesh_122.geometry}>
                  {material}
                </mesh>
              </group>
              <group
                name="cross_recessed_thread_forming_screws_gb_GB_CROSS_SCREWS_TYPE138_M2X8X72-N_1"
                position={[3, 1.05, 1.32]}
                rotation={[0, 1.57, 0]}>
                <mesh name="mesh_123" geometry={nodes.mesh_123.geometry}>
                  {material}
                </mesh>
              </group>
              <group
                name="cross_recessed_thread_forming_screws_gb_GB_CROSS_SCREWS_TYPE138_M2X8X72-N_2"
                position={[1.13, 1, 1.32]}
                rotation={[0, 1.57, 0]}>
                <mesh name="mesh_124" geometry={nodes.mesh_124.geometry}>
                  {material}
                </mesh>
              </group>
              <group
                name="cross_recessed_thread_forming_screws_gb_GB_CROSS_SCREWS_TYPE138_M2X8X72-N_3"
                position={[1.13, -1, 1.32]}
                rotation={[0, 1.57, 0]}>
                <mesh name="mesh_125" geometry={nodes.mesh_125.geometry}>
                  {material}
                </mesh>
              </group>
              <group
                name="REV-11-1271-3_Alt"
                position={[0, 0, 0.55]}
                rotation={[0, 0, -2.09]}>
                <mesh name="mesh_126" geometry={nodes.mesh_126.geometry}>
                  {material}
                </mesh>
              </group>
              <group name="6703-ZZ_Bearing_1" position={[0, 0, 0.85]}>
                <mesh name="mesh_127" geometry={nodes.mesh_127.geometry}>
                  {material}
                </mesh>
              </group>
              <group
                name="REV-11-1271-2_ALT"
                position={[3.62, 1.38, 1.23]}
                rotation={[Math.PI, 0, 0]}>
                <mesh name="mesh_128" geometry={nodes.mesh_128.geometry}>
                  {material}
                </mesh>
              </group>
              <mesh name="mesh_129" geometry={nodes.mesh_129.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="am-2986_FR8ZZ_Hex_Bearing_v2_v1_2"
              position={[0, 17.85, 0]}
              rotation={[Math.PI / 2, 0, 2.62]}>
              <mesh name="mesh_130" geometry={nodes.mesh_130.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="Precision_Shim_10_x_5_x_1_v2"
              position={[2.54, 1.15, 0]}>
              <mesh name="mesh_131" geometry={nodes.mesh_131.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="Precision_Shim_10_x_5_x_1_v2_1"
              position={[2.54, 1.25, 0]}>
              <mesh name="mesh_132" geometry={nodes.mesh_132.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="Precision_Shim_10_x_5_x_1_v2_2"
              position={[-2.54, 1.25, 0]}>
              <mesh name="mesh_133" geometry={nodes.mesh_133.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="Precision_Shim_10_x_5_x_1_v2_3"
              position={[-2.54, 1.15, 0]}>
              <mesh name="mesh_134" geometry={nodes.mesh_134.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="Aluminum_Spacer_5mm_v3"
              position={[-1.8, -0.03, -1.8]}
              rotation={[-Math.PI / 2, 0, 0]}>
              <mesh name="mesh_135" geometry={nodes.mesh_135.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="Aluminum_Spacer_5mm_v3_1"
              position={[1.8, -0.03, 1.8]}
              rotation={[-Math.PI / 2, 0, 0]}>
              <mesh name="mesh_136" geometry={nodes.mesh_136.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="Aluminum_Spacer_5mm_v3_2"
              position={[-2.54, -0.03, 0]}
              rotation={[-Math.PI / 2, 0, 0]}>
              <mesh name="mesh_137" geometry={nodes.mesh_137.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="Aluminum_Spacer_5mm_v3_3"
              position={[2.54, -0.03, 0]}
              rotation={[-Math.PI / 2, 0, 0]}>
              <mesh name="mesh_138" geometry={nodes.mesh_138.geometry}>
                {material}
              </mesh>
            </group>
            <mesh name="mesh_139" geometry={nodes.mesh_139.geometry}>
              {material}
            </mesh>
            <mesh name="mesh_140" geometry={nodes.mesh_140.geometry}>
              {material}
            </mesh>
            <mesh name="mesh_141" geometry={nodes.mesh_141.geometry}>
              {material}
            </mesh>
            <mesh name="mesh_142" geometry={nodes.mesh_142.geometry}>
              {material}
            </mesh>
            <mesh name="mesh_143" geometry={nodes.mesh_143.geometry}>
              {material}
            </mesh>
            <mesh name="mesh_144" geometry={nodes.mesh_144.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="LH_Wheel_1"
            position={[-40.03, -42.48, 4]}
            rotation={[0, 0, Math.PI / 2]}>
            <group name="Box" position={[0, -0.03, 0]}>
              <group
                name="am-2986_FR8ZZ_Hex_Bearing_v2_v1_3"
                position={[0, 0.54, 0]}
                rotation={[-Math.PI / 2, 0, -Math.PI / 6]}>
                <mesh name="mesh_145" geometry={nodes.mesh_145.geometry}>
                  {material}
                </mesh>
              </group>
              <group
                name="am-2986_FR8ZZ_Hex_Bearing_v2_v1_4"
                position={[0, 5.17, 0]}
                rotation={[-Math.PI / 2, 0, -Math.PI / 6]}>
                <mesh name="mesh_146" geometry={nodes.mesh_146.geometry}>
                  {material}
                </mesh>
              </group>
              <group
                name="REV-11-1271_v2_1"
                position={[0, 1.58, 0]}
                rotation={[-Math.PI / 2, 0, -1.83]}>
                <group name="REV-11-1271-1_1" rotation={[0, 0, -0.42]}>
                  <mesh name="mesh_147" geometry={nodes.mesh_147.geometry}>
                    {material}
                  </mesh>
                </group>
                <group
                  name="REV-11-1271-4_1"
                  position={[1.74, -0.77, 0.68]}
                  rotation={[-Math.PI, 0, 1.34]}>
                  <mesh name="mesh_148" geometry={nodes.mesh_148.geometry}>
                    {material}
                  </mesh>
                </group>
                <group
                  name="REV-11-1271-0_1"
                  position={[1.74, -0.77, 0.98]}
                  rotation={[-Math.PI, 0, 0.42]}>
                  <mesh name="mesh_149" geometry={nodes.mesh_149.geometry}>
                    {material}
                  </mesh>
                </group>
                <group
                  name="6703-ZZ_Bearing_2"
                  position={[0, 0, 0.1]}
                  rotation={[0, 0, -0.37]}>
                  <mesh name="mesh_150" geometry={nodes.mesh_150.geometry}>
                    {material}
                  </mesh>
                </group>
                <group
                  name="128-2Z_Bearing_7804K145_1"
                  position={[1.74, -0.77, 0.34]}
                  rotation={[-Math.PI, 0, -3.12]}>
                  <mesh name="mesh_151" geometry={nodes.mesh_151.geometry}>
                    {material}
                  </mesh>
                  <mesh name="mesh_152" geometry={nodes.mesh_152.geometry}>
                    {material}
                  </mesh>
                  <mesh name="mesh_153" geometry={nodes.mesh_153.geometry}>
                    {material}
                  </mesh>
                  <mesh name="mesh_154" geometry={nodes.mesh_154.geometry}>
                    {material}
                  </mesh>
                  <mesh name="mesh_155" geometry={nodes.mesh_155.geometry}>
                    {material}
                  </mesh>
                  <mesh name="mesh_156" geometry={nodes.mesh_156.geometry}>
                    {material}
                  </mesh>
                  <mesh name="mesh_157" geometry={nodes.mesh_157.geometry}>
                    {material}
                  </mesh>
                  <mesh name="mesh_158" geometry={nodes.mesh_158.geometry}>
                    {material}
                  </mesh>
                  <mesh name="mesh_159" geometry={nodes.mesh_159.geometry}>
                    {material}
                  </mesh>
                  <mesh name="mesh_160" geometry={nodes.mesh_160.geometry}>
                    {material}
                  </mesh>
                  <mesh name="mesh_161" geometry={nodes.mesh_161.geometry}>
                    {material}
                  </mesh>
                  <mesh name="mesh_162" geometry={nodes.mesh_162.geometry}>
                    {material}
                  </mesh>
                  <mesh name="mesh_163" geometry={nodes.mesh_163.geometry}>
                    {material}
                  </mesh>
                  <mesh name="mesh_164" geometry={nodes.mesh_164.geometry}>
                    {material}
                  </mesh>
                  <mesh name="mesh_165" geometry={nodes.mesh_165.geometry}>
                    {material}
                  </mesh>
                  <mesh name="mesh_166" geometry={nodes.mesh_166.geometry}>
                    {material}
                  </mesh>
                </group>
                <group
                  name="REV-11-1271-5_1"
                  position={[1.74, -0.77, 0.42]}
                  rotation={[Math.PI / 2, 1.02, 0]}>
                  <mesh name="mesh_167" geometry={nodes.mesh_167.geometry}>
                    {material}
                  </mesh>
                </group>
                <group
                  name="S6B-PH-K-S_1"
                  position={[1.65, -2.02, 0.22]}
                  rotation={[Math.PI / 2, 1.15, -Math.PI]}>
                  <mesh name="mesh_168" geometry={nodes.mesh_168.geometry}>
                    {material}
                  </mesh>
                </group>
                <group
                  name="cross_recessed_thread_forming_screws_gb_GB_CROSS_SCREWS_TYPE138_M2X8X72-N_4"
                  position={[2.31, -2.18, 1.32]}
                  rotation={[Math.PI / 2, 1.15, -Math.PI / 2]}>
                  <mesh name="mesh_169" geometry={nodes.mesh_169.geometry}>
                    {material}
                  </mesh>
                </group>
                <group
                  name="cross_recessed_thread_forming_screws_gb_GB_CROSS_SCREWS_TYPE138_M2X8X72-N_5"
                  position={[3.17, -0.26, 1.32]}
                  rotation={[Math.PI / 2, 1.15, -Math.PI / 2]}>
                  <mesh name="mesh_170" geometry={nodes.mesh_170.geometry}>
                    {material}
                  </mesh>
                </group>
                <group
                  name="cross_recessed_thread_forming_screws_gb_GB_CROSS_SCREWS_TYPE138_M2X8X72-N_6"
                  position={[1.43, 0.46, 1.32]}
                  rotation={[Math.PI / 2, 1.15, -Math.PI / 2]}>
                  <mesh name="mesh_171" geometry={nodes.mesh_171.geometry}>
                    {material}
                  </mesh>
                </group>
                <group
                  name="cross_recessed_thread_forming_screws_gb_GB_CROSS_SCREWS_TYPE138_M2X8X72-N_7"
                  position={[0.62, -1.37, 1.32]}
                  rotation={[Math.PI / 2, 1.15, -Math.PI / 2]}>
                  <mesh name="mesh_172" geometry={nodes.mesh_172.geometry}>
                    {material}
                  </mesh>
                </group>
                <group
                  name="REV-11-1271-3_Alt_1"
                  position={[0, 0, 0.55]}
                  rotation={[0, 0, -2.09]}>
                  <mesh name="mesh_173" geometry={nodes.mesh_173.geometry}>
                    {material}
                  </mesh>
                </group>
                <group
                  name="6703-ZZ_Bearing_3"
                  position={[0, 0, 0.85]}
                  rotation={[0, 0, -0.42]}>
                  <mesh name="mesh_174" geometry={nodes.mesh_174.geometry}>
                    {material}
                  </mesh>
                </group>
                <group
                  name="REV-11-1271-2_ALT_1"
                  position={[3.87, -0.21, 1.23]}
                  rotation={[Math.PI, 0, 0.42]}>
                  <mesh name="mesh_175" geometry={nodes.mesh_175.geometry}>
                    {material}
                  </mesh>
                </group>
              </group>
              <group
                name="am-2986_FR8ZZ_Hex_Bearing_v2_v1_5"
                position={[-0.02, 17.86, 0]}
                rotation={[-Math.PI / 2, 0, -Math.PI / 6]}>
                <mesh name="mesh_176" geometry={nodes.mesh_176.geometry}>
                  {material}
                </mesh>
              </group>
              <group
                name="Precision_Shim_10_x_5_x_1_v2_4"
                position={[2.54, 1.18, 0]}>
                <mesh name="mesh_177" geometry={nodes.mesh_177.geometry}>
                  {material}
                </mesh>
              </group>
              <group
                name="Precision_Shim_10_x_5_x_1_v2_5"
                position={[2.54, 1.28, 0]}>
                <mesh name="mesh_178" geometry={nodes.mesh_178.geometry}>
                  {material}
                </mesh>
              </group>
              <group
                name="Precision_Shim_10_x_5_x_1_v2_6"
                position={[-2.54, 1.28, 0]}>
                <mesh name="mesh_179" geometry={nodes.mesh_179.geometry}>
                  {material}
                </mesh>
              </group>
              <group
                name="Precision_Shim_10_x_5_x_1_v2_7"
                position={[-2.54, 1.18, 0]}>
                <mesh name="mesh_180" geometry={nodes.mesh_180.geometry}>
                  {material}
                </mesh>
              </group>
              <group
                name="Aluminum_Spacer_5mm_v3_4"
                position={[-1.8, -0.01, -1.8]}
                rotation={[-Math.PI / 2, 0, 0]}>
                <mesh name="mesh_181" geometry={nodes.mesh_181.geometry}>
                  {material}
                </mesh>
              </group>
              <group
                name="Aluminum_Spacer_5mm_v3_5"
                position={[1.8, -0.01, 1.8]}
                rotation={[-Math.PI / 2, 0, 0]}>
                <mesh name="mesh_182" geometry={nodes.mesh_182.geometry}>
                  {material}
                </mesh>
              </group>
              <group
                name="Aluminum_Spacer_5mm_v3_6"
                position={[-2.54, -0.01, 0]}
                rotation={[-Math.PI / 2, 0, 0]}>
                <mesh name="mesh_183" geometry={nodes.mesh_183.geometry}>
                  {material}
                </mesh>
              </group>
              <group
                name="Aluminum_Spacer_5mm_v3_7"
                position={[2.54, -0.01, 0]}
                rotation={[-Math.PI / 2, 0, 0]}>
                <mesh name="mesh_184" geometry={nodes.mesh_184.geometry}>
                  {material}
                </mesh>
              </group>
              <mesh name="mesh_185" geometry={nodes.mesh_185.geometry}>
                {material}
              </mesh>
              <mesh name="mesh_186" geometry={nodes.mesh_186.geometry}>
                {material}
              </mesh>
              <mesh name="mesh_187" geometry={nodes.mesh_187.geometry}>
                {material}
              </mesh>
            </group>
            <mesh name="mesh_188" geometry={nodes.mesh_188.geometry}>
              {material}
            </mesh>
            <mesh name="mesh_189" geometry={nodes.mesh_189.geometry}>
              {material}
            </mesh>
          </group>
          <mesh name="mesh_190" geometry={nodes.mesh_190.geometry}>
            {material}
          </mesh>
        </group>
      </group>
    </Suspense>
  );
}

useGLTF.preload(TugFirstFloorModel);
