/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import * as THREE from 'three';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';

import { useGLTF } from '@react-three/drei';

import { RenderingType } from '../../RenderingType';
import materialFactory from '../../trolley/MaterialFactory';
import UmbilicalGantryYAxisBeam_Model from './UmbilicalGantryYAxisBeam.glb';

export default function UmbilicalGantryYAxisBeam(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF(UmbilicalGantryYAxisBeam_Model) as GLTF;

  const material: THREE.Material = materialFactory.generateMaterialObject(
    props.parentUnderInspection,
    props.parentHovered,
    props.online
  );

  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[-0.77, 4, 0.63]} rotation={[0, 0, -Math.PI / 2]}>
        <mesh
          geometry={nodes.Body1.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? materials['Opaque(229,229,229)']
              : material
          }
        />
      </group>
      <group position={[-1.77, 1, 0.63]}>
        <mesh
          geometry={nodes.Body1_1.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? materials['Aluminum - Satin']
              : material
          }
        />
      </group>
      <group
        position={[0.47, -1, 50.94]}
        rotation={[-Math.PI / 2, -Math.PI / 2, 0]}>
        <mesh
          geometry={nodes.Body1_2.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.Body1_2.material
              : material
          }
        />
      </group>
      <group
        position={[0.47, -1, 0.34]}
        rotation={[Math.PI / 2, -Math.PI / 2, 0]}>
        <mesh
          geometry={nodes.Body1_3.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.Body1_3.material
              : material
          }
        />
      </group>
      <mesh
        geometry={nodes.Wobbler.geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes.Wobbler.material
            : material
        }
      />
      <mesh
        geometry={nodes['Wobbler_(1)'].geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes['Wobbler_(1)'].material
            : material
        }
      />
      <mesh
        geometry={nodes['Wobbler_(2)'].geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes['Wobbler_(2)'].material
            : material
        }
      />
      <mesh
        geometry={nodes['Wobbler_(3)'].geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes['Wobbler_(3)'].material
            : material
        }
      />
    </group>
  );
}

useGLTF.preload(UmbilicalGantryYAxisBeam_Model);
