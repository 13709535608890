import {
  fullString,
  toAddress,
} from 'model/src/common/CloudProduceAddressUtility';
import React from 'react';
import styled from 'styled-components';

import ClassNames from '../../ClassNames';
import { useDataMap } from '../../three/farmviewer/context/datamapcontext/useDataMap';
import { ListItem } from './DataList';
import ClosedNodeIcon from './tree-icons/ClosedNode';
import HorizontalLineUrl from './tree-icons/HorizontalLineUrl';
import OpenNodeIcon from './tree-icons/OpenNode';

export type TreeNodeProps = {
  node: TreeNodeType;
  level: number;
  selected: boolean;
  onToggle?: () => void;
};

export type TreeNodeType = {
  data: any;
  datum: any;
  level: number;
  selected: boolean;
  childNodes?: TreeNodeType[];
  url: string;
};

const isRoot = (level: number) => {
  return level <= 1;
};

export const NodeTextBox = styled.span`
  pointer-event: auto;
  position: relative;
  display: inline-block;
  padding-left: calc(var(--treeNodeMinIndent) * 2);
  padding-right: calc(var(--treeNodeMinIndent) * 2);
  border-radius: var(--treeLeafStaticBorderRadius);
  ${props =>
    !isRoot(props.level) && !props.selected
      ? `
  background-color: var(--treeNodeRegularBackground_Default);
  color: var(--treeNodeRegularLabel_Default);
  &:hover {
    background-color: var(--treeNodeRegularBackground_Hover);
    color: var(--treeNodeRegularLabel_Hover);
  }
  &:active {
    background-color: var(--treeNodeRegularBackground_Active);
    color: var(--treeNodeRegularLabel_Active);
  }
  `
      : !isRoot(props.level) && props.selected
      ? `
    background-color: var(--treeNodeRegularBackground_OnPath);
    color: var(--treeNodeRegularLabel_OnPath);
  `
      : isRoot(props.level) && !props.selected
      ? `
              background-color: var(--treeNodeStartBackground_Default);
              color: var(--treeNodeStartLabel_Default);
              &:hover {
                background-color: var(--treeNodeStartBackground_Hover);
                color: var(--treeNodeStartLabel_Hover);
              }
              &:active {
                background-color: var(--treeNodeStartBackground_Selected);
                color: var(--treeNodeStartLabel_Active);
              }
  `
      : `
            background-color: var(--treeNodeStartBackground_OnPath);
            color: var(--treeNodeStartLabel_OnPath);
  `}
`;

const NodeLine = styled.li`
  position: relative;
  display: inline-block;
  width: var(--nodeLineGap);
  height: var(--nodeLineContainerHeight);
  margin-left: -var(--treeNodeLineWidth);
  bottom: ${props =>
    isRoot(props.level)
      ? `var(--treeNodeMinIndent)`
      : `calc(var(--treeNodeMinIndent) - 2px)`};
  background-image: url('${props =>
    props.continue
      ? HorizontalLineUrl('--treeConnector_Default')
      : HorizontalLineUrl('--treeConnector_Default')}');
`;

const BoldText = styled.text`
  font-weight: bold;
`;

const TreeNode = (props: TreeNodeProps) => {
  const mostRecentValue = useDataMap(toAddress(props.node.url));
  return (
    <ListItem>
      {props.selected ? (
        <OpenNodeIcon onClick={props.onToggle} />
      ) : (
        <ClosedNodeIcon onClick={props.onToggle} />
      )}
      <NodeLine />
      <NodeTextBox
        level={props.level}
        selected={props.selected}
        onClick={props.onToggle}
        className={
          isRoot(props.level) ? ClassNames.bodyMedium : ClassNames.labelRegular
        }>
        {props.node.url}{' '}
        {mostRecentValue && (
          <BoldText
            className={
              isRoot(props.level)
                ? ClassNames.bodyMedium
                : ClassNames.labelRegular
            }>
            {' :   ' + mostRecentValue.value}
          </BoldText>
        )}
      </NodeTextBox>
    </ListItem>
  );
};

export default TreeNode;
