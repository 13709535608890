import { isRoot } from 'model/src/common/CloudProduceAddressUtility';
import { BuiltinType } from 'model/src/typescript/Typescript';
import React, { PropsWithChildren, useState } from 'react';
import { DataMap } from 'services/src/pagedataintake/PageDataIntake';
import { useMap } from 'usehooks-ts';

import {
  InspectedContentProps,
  StaticInspectionProps,
} from '../../../../farm-ui/windows/InspectionWindow';
import {
  TowerOverlay,
  WaterTankOverlay,
} from '../../../../farm-ui/windows/ViewFiltersWindow';
import { WindowPosition } from '../../InspectionNodeUtility';
import { ControllerContextType } from '../controllercontext/ControllerContext';
import { DesktopContext, DesktopContextType } from './DesktopContext';

export type DesktopContextProviderProps = {
  dataMap: DataMap;
  selectedUrl: string;
  selectedOverlay?: TowerOverlay | WaterTankOverlay | undefined;
  select: (
    url: string,
    controllerContext: ControllerContextType,
    desktopContext: DesktopContextType,
    operation?: string
  ) => void;
};

export default function DesktopContextProvider(
  props: PropsWithChildren<DesktopContextProviderProps>
) {
  const modal = '';
  const openInspectedContent = new Map<string, StaticInspectionProps>([]);
  const openWindowMap = new Map<string | number, WindowPosition>([]);
  const windowPropsMap = new Map<string | number, any>([]);

  const getParentUrl = (url: string, depth?: number) => {
    if (isRoot(url)) {
      return url;
    }
    if (typeof depth === BuiltinType.NUMBER && (depth as number) < 1) {
      return url;
    }
    let recurse: number;
    if (typeof depth === BuiltinType.NUMBER) {
      recurse = depth as number;
    } else {
      recurse = 1;
    }
    return getParentUrl(
      url.replace(
        new RegExp(
          '.' +
            url
              .split(/[.:]/)
              .slice(url.split(/[.:]/).length - 1)
              .join('.') +
            '$'
        ),
        ''
      ),
      recurse - 1
    );
  };
  const selectOverlay = (
    overlay: WaterTankOverlay | TowerOverlay,
    currentContextValue: DesktopContextType
  ) => {
    let newUrl: string;
    if (currentContextValue.selectedOverlay) {
      if (currentContextValue.selectedOverlay === overlay) {
        newUrl = currentContextValue.selectedUrl.replace(
          '.' + currentContextValue.selectedOverlay,
          ''
        );
        setContext({
          ...currentContextValue,
          selectedUrl: newUrl,
          selectedOverlay: undefined,
        });
        return {
          ...currentContextValue,
          selectedUrl: newUrl,
          selectedOverlay: undefined,
        };
      }
      newUrl =
        currentContextValue.selectedUrl.replace(
          '.' + currentContextValue.selectedOverlay,
          ''
        ) +
        '.' +
        overlay;
    } else {
      newUrl = currentContextValue.selectedUrl + '.' + overlay;
    }
    setContext({
      ...currentContextValue,
      selectedUrl: newUrl,
      selectedOverlay: overlay,
    });
    return {
      ...currentContextValue,
      selectedUrl: newUrl,
      selectedOverlay: overlay,
    };
  };

  const updateContext = (
    newContextValue: DesktopContextType,
    currentContextValue: DesktopContextType
  ) => {
    setContextValue({
      ...currentContextValue,
      ...newContextValue,
    });
  };

  const updateSelectedUrl = (url, currentContextValue) => {
    setContextValue({
      ...currentContextValue,
      selectedOverlay: undefined,
      selectedUrl: url,
    });
  };

  const setContext = (contextValue: DesktopContextType) => {
    setContextValue({
      selectedOverlay: undefined,
      ...contextValue,
    });
  };

  const setModal = (id, windowProps, currentContext: DesktopContextType) => {
    const windowPropsMap = currentContext.windowPropsMap;
    windowPropsMap.set(id, windowProps);

    setContext({
      ...currentContext,
      modal: id,
      windowPropsMap: windowPropsMap,
    });
  };
  const setOpenInspectedContent = {
    set: (
      id,
      props: StaticInspectionProps,
      currentContext: DesktopContextType
    ) => {
      const openInspectedContent = currentContext.openInspectedContent;

      openInspectedContent.set(id, props);
      setContext({
        ...currentContext,
        openInspectedContent: openInspectedContent,
      });
    },
    remove: (id, currentContext: DesktopContextType) => {
      const openInspectedContent = currentContext.openInspectedContent;

      openInspectedContent.delete(id);
      setContext({
        ...currentContext,
        openInspectedContent: openInspectedContent,
      });
    },
  };
  const setOpenWindowMap = {
    set: (id, props: WindowPosition, currentContext: DesktopContextType) => {
      const openWindowMap = currentContext.openWindowMap;

      openWindowMap.set(id, props);
      setContext({
        ...currentContext,
        openWindowMap: openWindowMap,
      });
    },
    remove: (id, currentContext: DesktopContextType) => {
      const openWindowMap = currentContext.openWindowMap;

      openWindowMap.delete(id);
      setContext({
        ...currentContext,
        openWindowMap: openWindowMap,
      });
    },
    select: (id, currentContext: DesktopContextType) => {
      const openWindowMap = currentContext.openWindowMap;
      const position = openWindowMap.get(id);
      openWindowMap.delete(id);
      openWindowMap.set(id, position);
      setContext({
        ...currentContext,
        openWindowMap: openWindowMap,
      });
    },
    setPosition: (id, position, currentContext: DesktopContextType) => {
      const openWindowMap = currentContext.openWindowMap;
      openWindowMap.delete(id);
      openWindowMap.set(id, position);
      setContext({
        ...currentContext,
        openWindowMap: openWindowMap,
      });
    },
  };
  const setWindowPropsMap = {
    set: (id, windowProps, currentContext) => {
      const windowPropsMap = currentContext.windowPropsMap;

      windowPropsMap.set(id, windowProps);
      setContext({ ...currentContext, windowPropsMap: windowPropsMap });
    },
    remove: (id, currentContext: DesktopContextType) => {
      const windowPropsMap = currentContext.windowPropsMap;

      windowPropsMap.delete(id);
      setContext({
        ...currentContext,
        windowPropsMap: windowPropsMap,
      });
    },
  };

  const removeInspectionWindow = (id, currentContext) => {
    const openInspectedContent = currentContext.openInspectedContent;
    const windowPropsMap = currentContext.windowPropsMap;
    const openWindowMap = currentContext.openWindowMap;

    openInspectedContent.delete(id);
    windowPropsMap.delete(id);
    openWindowMap.delete(id);
    setContext({
      ...currentContext,
      windowPropsMap: windowPropsMap,
      openInspectedContent: openInspectedContent,
      openWindowMap: openWindowMap,
    });
  };
  const pushInspectionWindow = (
    id,
    staticInspectionProps: StaticInspectionProps,
    inspectedContentProps: InspectedContentProps,
    currentContext: DesktopContextType
  ) => {
    const openInspectedContent = currentContext.openInspectedContent;
    const openWindowMap = currentContext.openWindowMap;
    const windowPropsMap = currentContext.windowPropsMap;

    openInspectedContent.set(id, staticInspectionProps);
    openWindowMap.set(id, inspectedContentProps!.initialWindowPosition);
    windowPropsMap.set(id, inspectedContentProps);
    setContext({
      ...currentContext,
      openInspectedContent: openInspectedContent,
      openWindowMap: openWindowMap,
      windowPropsMap: windowPropsMap,
    });
  };

  const clearInspectionWindows = (desktopContext: DesktopContextType) => {
    const openInspectedContent = desktopContext.openInspectedContent;
    const openWindowMap = desktopContext.openWindowMap;

    openWindowMap.forEach((key, value) => {
      if (openInspectedContent.has(key)) {
        openWindowMap.delete(key);
      }
      openInspectedContent.clear();
    });
    setContext({
      ...desktopContext,
      openInspectedContent: openInspectedContent,
      openWindowMap: openWindowMap,
    });
  };

  const [contextValue, setContextValue] = useState<DesktopContextType>({
    dataMap: props.dataMap,
    openWindow: null,
    clearInspectionWindows: clearInspectionWindows,
    pushInspectionWindow: pushInspectionWindow,
    removeInspectionWindow: removeInspectionWindow,
    selectedUrl: props.selectedUrl,
    select: props.select,
    selectOverlay: selectOverlay,
    getParentUrl: getParentUrl,
    updateContext: updateContext,
    updateSelectedUrl: updateSelectedUrl,
    setContext: setContext,

    modal: modal,
    setModal: setModal,
    openInspectedContent: openInspectedContent,
    setOpenInspectedContent: setOpenInspectedContent,
    openWindowMap: openWindowMap,
    setOpenWindowMap: setOpenWindowMap,
    windowPropsMap: windowPropsMap,
    setWindowPropsMap: setWindowPropsMap,
  });

  return (
    <DesktopContext.Provider value={contextValue}>
      {props.children}
    </DesktopContext.Provider>
  );
}
