import React, { useState } from 'react';
import * as THREE from 'three';

import { cssVar } from '../../..';

export const ViewCubeSegment = props => {
  const [hovered, setHovered] = useState(false);
  const color = hovered ? cssVar('--systemPurple') : cssVar('--black');
  const material = <meshPhongMaterial attach="material" color={color} />;

  return (
    <group
      onPointerOver={event => {
        setHovered(true);
        event.stopPropagation();
      }}
      onPointerOut={event => {
        setHovered(false);
        event.stopPropagation();
      }}
      onClick={event => {
        const deltaRotation = new THREE.Vector3(0, 0, 0);
        const targetRotation = new THREE.Vector3(
          props.targetRotation[0],
          props.targetRotation[1],
          props.targetRotation[2]
        );
        const currentRotation = new THREE.Vector3(
          props.rotation.x,
          props.rotation.y,
          props.rotation.z
        );
        deltaRotation.subVectors(targetRotation, currentRotation);
        props.animateTo(
          new THREE.Euler().setFromVector3(targetRotation, 'XYZ'),
          props.sphericalTargetRotation
        );

        event.stopPropagation();
      }}>
      <mesh position={props.position}>
        <boxGeometry args={props.dimensions} />
        {material}
      </mesh>
    </group>
  );
};
