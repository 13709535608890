import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Auth from './Auth';
import FarmPage from './old/Farm/creator/FarmPage';
import Explorer from './old/globe/Explorer';
import FarmHmi from './three/farmviewer/FarmHmi';
import UserProfileContextProvider from './user/UserProfileContextProvider';

const AuthorizedRoutes = () => (
  <Switch>
    <Route path="/" exact component={Explorer} />
    <Route path="/v2/:farmId" component={FarmHmi} />
    <Route path="/farm/:farmId" component={FarmPage} />
    <Redirect to="/" />
  </Switch>
);

const MainContent = ({ auth, onAuthenticationChange }) => {
  return auth.authorized ? (
    <UserProfileContextProvider email={auth.email}>
      <AuthorizedRoutes />
    </UserProfileContextProvider>
  ) : (
    <Auth auth={auth} onAuthenticationChange={onAuthenticationChange} />
  );
};

export default MainContent;
