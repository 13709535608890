import React from 'react';
import styled from 'styled-components';

import { isDarkModeEnabled } from '../../App';
import ClassNames from '../../ClassNames';
import DataSettingsContent from '../../utopia/desktop/meta-tool-bar/DataSettingsContent';
import GeneralSettingsContent from '../../utopia/desktop/meta-tool-bar/GeneralSettingsContent';
import LoggingStatusContent from '../../utopia/desktop/meta-tool-bar/LoggingStatusContent';
import PermissionsContent from '../../utopia/desktop/meta-tool-bar/PermissionsContent';
import { TabControl } from '../../utopia/vertical-tab-bar/TabControl';
import { HeaderButtonType } from '../../utopia/window/components/WindowHeaderButton';
import DraggableWindow from '../../utopia/window/DraggableWindow';
import WindowType from '../../utopia/window/WindowTypes';

const Content = styled.div`
  width: var(--settingsWindowWidth);
  height: var(--settingsWindowHeight);
  border-radius: var(--windowBottomBorderRadius);

  color: var(--black);
  background-color: var(--dropdownMenuItemBackground_Default);
`;
const SettingsWindow = props => {
  return (
    <DraggableWindow
      title="Settings"
      url={'Farm Data'}
      onDrag={props.onDrag}
      onDragEnd={props.onDragEnd}
      initialWindowPosition={props.initialWindowPosition}
      headerButtons={[HeaderButtonType.CLOSE]}
      headerButtonOnClicks={[() => props.closeHandler()]}
      onClick={props.onClick}
      onDragStart={props.onDragStart}
      onMouseDown={props.onMouseDown}
      selected={props.selected}
      windowType={WindowType.SETTINGS}>
      <Content className={ClassNames.bodyRegular} key={WindowType.SETTINGS}>
        <TabControl selected={0}>
          <GeneralSettingsContent
            className={ClassNames.bodyRegular}
            checked={isDarkModeEnabled()}
            title="General"
          />
          <PermissionsContent title="Permissions" />
          <DataSettingsContent title="Data" />
          <LoggingStatusContent title="Logging" />
        </TabControl>
      </Content>
    </DraggableWindow>
  );
};

export default SettingsWindow;
