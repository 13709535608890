/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import * as THREE from 'three';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';

import { useGLTF } from '@react-three/drei';

import { RenderingType } from '../../../RenderingType';
import materialFactory from '../../../trolley/MaterialFactory';
import BlindBottom_Model from './BlindBottom.glb';

export default function BlindBottom(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF(BlindBottom_Model) as GLTF;

  const material: THREE.Material = materialFactory.generateMaterialObject(
    props.parentUnderInspection,
    props.parentHovered,
    props.online
  );

  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[156, -1.75, -5.7]} rotation={[0, 0, -Math.PI / 2]}>
        <mesh
          geometry={nodes.EdgeCap.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.EdgeCap.material
              : material
          }
        />
      </group>
      <group position={[0, -2.65, -5.7]} rotation={[0, 0, Math.PI / 2]}>
        <mesh
          geometry={nodes.EdgeCap_1.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.EdgeCap_1.material
              : material
          }
        />
      </group>
      <mesh
        geometry={nodes.Body1.geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? materials['Plastic - Glossy (White)']
            : material
        }
      />
    </group>
  );
}

useGLTF.preload(BlindBottom_Model);
