import { toAddress } from 'model/src/common/CloudProduceAddressUtility';
import { PageToPageServer } from 'model/src/dataflowprotocol/pagetopageserver/PageToPageServer';
import { PageToPageServerType } from 'model/src/dataflowprotocol/pagetopageserver/PageToPageServerType';
import { FloorNodeType } from 'model/src/farm/entities/farm/v2/FloorNode';
import React, { useContext, useRef, useState } from 'react';
import * as THREE from 'three';

import { cssVar } from '../../..';
import { HELVETIKER_REGULAR_FONT } from '../../../App';
import {
  UserProfileContext,
  UserProfileContextType,
} from '../../../user/UserProfileContext';
import {
  ControllerContext,
  ControllerContextType,
} from '../../farmviewer/context/controllercontext/ControllerContext';
import {
  DesktopContext,
  DesktopContextType,
} from '../../farmviewer/context/desktopcontext/DesktopContext';
import {
  OperationContext,
  OperationContextType,
} from '../../farmviewer/context/operationcontext/OperationContext';
// import {
//   EdgeDirection,
//   EdgeTurn,
//   PathContext,
//   PathContextType,
//   PathEdge,
// } from '../../farmviewer/context/pathcontext/PathContext';
import Text from '../common/Text';
import GridArrow from './GridArrow';

// import PathSegment from './PathSegment';

export default function GridSquare(props: {
  url: string;
  type: FloorNodeType;
  x: number;
  y: number;
  width: number;
  depth: number;
  height: number;
  upArrow: boolean;
  downArrow: boolean;
  leftArrow: boolean;
  rightArrow: boolean;
  tug1HomeSquareUrl: string;
}) {
  const textOptions = {
    font: HELVETIKER_REGULAR_FONT.font,
    size: 5,
    height: 1,
  };

  const controllerContext: ControllerContextType = useContext(
    ControllerContext
  );
  const desktopContext: DesktopContextType = useContext(DesktopContext);
  const operationContext: OperationContextType = useContext(OperationContext);
  // const pathContext: PathContextType = useContext(PathContext);
  const userProfileContext: UserProfileContextType = useContext(
    UserProfileContext
  );

  // const tugHomeUrl =
  //   '1' +
  //   urlCombine([
  //     FarmSubsystem.TUG,
  //     '1',
  //     SeriesType.HOME,
  //     SeriesType.GRID_SQUARE_URL,
  //   ]);
  // const tug1HomeSquareDatum: Data<StringReading> = props.dataMap.all.get(
  //   tugHomeUrl
  // );
  // const tug1HomeSquareUrl = tug1HomeSquareDatum.data[0].reading.value;
  const isTug1Home = props.tug1HomeSquareUrl === props.url;

  // const occupantDatum: Data<OccupantReading> = props.dataMap.all.get(
  //   props.url + urlCombine([FloorSubsystem.OCCUPANT])
  // );

  // const occupantId = occupantDatum.data[0].reading.occupant;
  // const overlappingArea = occupantDatum.data[0].reading.overlappingArea / 15625;

  const mesh = useRef<THREE.Mesh>(null!);
  const [hovered, setHover] = useState(false);
  const [active, setActive] = useState(false);
  let color = cssVar('--mapFloor');

  const onClick = event => {
    if (operationContext.placementOperation) {
      operationContext.setPlacementOperation(
        {
          targetUrl: operationContext.placementOperation.targetUrl,
          placementLocationUrl: toAddress(props.url),
        },
        operationContext
      );
      const newCommand: PageToPageServer = {
        type: PageToPageServerType.SendCommand,
        sendCommand: {
          targetId: operationContext.placementOperation.targetUrl!.segments.join(),
          placeCommand: {
            targetUrl: operationContext.placementOperation.targetUrl!,
            squareUrl: toAddress(props.url),
          },
        },
      };
      userProfileContext.connectionToServer.sendMessage(newCommand);
      operationContext.clearCurrentOperation(operationContext);
      desktopContext.select(
        desktopContext.getParentUrl(desktopContext.selectedUrl),
        controllerContext,
        desktopContext
      ); // TODO(austin): make select() a callback on tower position updates from cloud
    } else {
      setActive(!active);
    }
  };
  const onContextMenu = event => {
    setActive(!active);
  };
  const onPointerOver = controllerContext.dragMute(event => {
    setHover(true);
    event.stopPropagation();
  }, controllerContext);

  if (hovered) {
    color = cssVar('--hovered-color');
  } else if (controllerContext.baseRestriction.renderFloorNodeTypes) {
    switch (props.type) {
      case FloorNodeType.GROW_SITE: {
        color = cssVar('--grow-site-color');
        break;
      }
      case FloorNodeType.FORWARD_ROAD:
        color = cssVar('--forward-road-color');
        break;
      case FloorNodeType.BACKWARD_ROAD:
        color = cssVar('--backward-road-color');
        break;
      case FloorNodeType.LEFT_ROAD:
        color = cssVar('--left-road-color');
        break;
      case FloorNodeType.RIGHT_ROAD:
        color = cssVar('--right-road-color');
        break;
      case FloorNodeType.QUAD:
        color = cssVar('--quad-color');
        break;
      case FloorNodeType.BUILD_SITE:
        color = cssVar('--build-site-color');
        break;
      case FloorNodeType.PROCESSING_SITE:
        color = cssVar('--processing-site-color');
        break;
    }
  }
  // else if (occupantId && overlappingArea > 0.5) {
  //   color = cssVar('--cloud-produce-daytona-yellow');
  // }
  else if (isTug1Home) {
    color = cssVar('--cloud-produce-daytona-yellow');
  }
  const material: any = <meshPhongMaterial attach="material" color={color} />;

  // const pathEdges: PathEdge[] = pathContext.getEdgesByFloorNode(props.url);
  // let pathColor = cssVar('--cloud-produce-black');
  // if (pathContext.tugUrl && pathEdges.length > 0) {
  //   const colorDatum: Datum<ColorReading> = props.dataMap.all.get(
  //     pathContext.tugUrl +
  //       urlCombine([TugSubsystem.CONFIGURATION, SeriesType.COLOR])
  //   );
  //   pathColor = colorDatum.datum.color;
  // }
  // const edges: any[] = useMemo(() => {
  //   const edges: any[] = [];
  //   for (let i = 0; i < pathEdges.length; i++) {
  //     const pathEdge = pathEdges[i];
  //     const channel = pathContext.getPathSegmentChannel(pathEdge.edgeDistance);
  //     // if (i === 0) {
  //     const isSource = fullString(pathEdge.sourceFloorNodeId) === props.url;
  //     const x1 = props.x;
  //     const y1 = props.y;

  //     const previousEdge = pathContext.getEdgeByDistance(
  //       pathEdge.edgeDistance - 1
  //     );
  //     const nextEdge = pathContext.getEdgeByDistance(pathEdge.edgeDistance + 1);

  //     const turnsFromPrevious: boolean =
  //       previousEdge && previousEdge.edgeAxis !== pathEdge.edgeAxis;
  //     const turnsToNext: boolean =
  //       nextEdge && nextEdge.edgeAxis !== pathEdge.edgeAxis;

  //     let edgeTurn: EdgeTurn = EdgeTurn.NONE;
  //     let nextEdgeChannel = 0;
  //     let previousEdgeChannel = 0;

  //     if (isSource && previousEdge) {
  //       previousEdgeChannel = pathContext.getPathSegmentChannel(
  //         previousEdge.edgeDistance
  //       );
  //     }

  //     if (!isSource && nextEdge) {
  //       nextEdgeChannel = pathContext.getPathSegmentChannel(
  //         nextEdge.edgeDistance
  //       );
  //     }

  //     if (turnsToNext && !isSource) {
  //       switch (pathEdge.edgeDirection) {
  //         case EdgeDirection.FORWARD: {
  //           if (nextEdge.edgeDirection === EdgeDirection.LEFT) {
  //             edgeTurn = EdgeTurn.FORWARD_TO_LEFT;
  //           } else {
  //             edgeTurn = EdgeTurn.FORWARD_TO_RIGHT;
  //           }
  //           break;
  //         }
  //         case EdgeDirection.BACK: {
  //           if (nextEdge.edgeDirection === EdgeDirection.LEFT) {
  //             edgeTurn = EdgeTurn.BACK_TO_LEFT;
  //           } else {
  //             edgeTurn = EdgeTurn.BACK_TO_RIGHT;
  //           }
  //           break;
  //         }
  //         case EdgeDirection.LEFT: {
  //           if (nextEdge.edgeDirection === EdgeDirection.FORWARD) {
  //             edgeTurn = EdgeTurn.LEFT_TO_FRONT;
  //           } else {
  //             edgeTurn = EdgeTurn.LEFT_TO_BACK;
  //           }
  //           break;
  //         }
  //         case EdgeDirection.RIGHT: {
  //           if (nextEdge.edgeDirection === EdgeDirection.FORWARD) {
  //             edgeTurn = EdgeTurn.RIGHT_TO_FRONT;
  //           } else {
  //             edgeTurn = EdgeTurn.RIGHT_TO_BACK;
  //           }
  //           break;
  //         }
  //       }
  //     }

  //     const x2 =
  //       pathEdge.edgeDirection === EdgeDirection.FORWARD ||
  //       pathEdge.edgeDirection === EdgeDirection.BACK
  //         ? x1
  //         : (pathEdge.edgeDirection === EdgeDirection.RIGHT && isSource) ||
  //           (pathEdge.edgeDirection === EdgeDirection.LEFT && !isSource)
  //         ? x1 - FarmConstants.GRID_NODE_SIDE_LENGTH_CM / 2
  //         : x1 + FarmConstants.GRID_NODE_SIDE_LENGTH_CM / 2;

  //     const y2 =
  //       pathEdge.edgeDirection === EdgeDirection.LEFT ||
  //       pathEdge.edgeDirection === EdgeDirection.RIGHT
  //         ? y1
  //         : (pathEdge.edgeDirection === EdgeDirection.FORWARD && isSource) ||
  //           (pathEdge.edgeDirection === EdgeDirection.BACK && !isSource)
  //         ? y1 - FarmConstants.GRID_NODE_SIDE_LENGTH_CM / 2
  //         : y1 + FarmConstants.GRID_NODE_SIDE_LENGTH_CM / 2;

  //     const points: number[][] = [];
  //     points.push([x1, y1]);
  //     points.push([x2, y2]);

  //     edges.push(
  //       <PathSegment
  //         key={pathEdge.id}
  //         color={pathColor}
  //         points={points}
  //         edgeAxis={pathEdge.edgeAxis}
  //         edgeDirection={pathEdge.edgeDirection}
  //         channel={channel}
  //         isSource={isSource}
  //         turnsFromPrevious={turnsFromPrevious}
  //         turnsToNext={turnsToNext}
  //         edgeTurn={edgeTurn}
  //         nextEdgeChannel={nextEdgeChannel}
  //         previousEdgeChannel={previousEdgeChannel}
  //       />
  //     );
  //     // }
  //   }
  //   return edges;
  // }, [pathContext]);

  return (
    <group>
      <mesh
        {...props}
        ref={mesh}
        position={[props.x, props.y, 0]}
        onClick={onClick}
        onContextMenu={onContextMenu}
        onPointerOver={onPointerOver}
        onPointerOut={event => setHover(false)}>
        <boxGeometry
          args={[props.width, props.depth, props.height]}
          onClick={onClick}
        />
        {material}
      </mesh>
      {controllerContext.baseRestriction.renderFloorNodeTypes &&
        props.upArrow && (
          <GridArrow
            position={[props.x, props.y - 65, 0.5]}
            rotation={[0, 0, Math.PI]}
          />
        )}
      {controllerContext.baseRestriction.renderFloorNodeTypes &&
        props.downArrow && (
          <GridArrow
            position={[props.x, props.y + 65, 0.5]}
            rotation={[0, 0, 0]}
          />
        )}
      {controllerContext.baseRestriction.renderFloorNodeTypes &&
        props.leftArrow && (
          <GridArrow
            position={[props.x - 65, props.y, 0.5]}
            rotation={[0, 0, Math.PI / 2]}
          />
        )}
      {controllerContext.baseRestriction.renderFloorNodeTypes &&
        props.rightArrow && (
          <GridArrow
            position={[props.x + 65, props.y, 0.5]}
            rotation={[0, 0, (3 * Math.PI) / 2]}
          />
        )}
      <Text
        position={[props.x, props.y, 10]}
        rotation={[Math.PI / 2, 0, 0]}
        text={props.url + ' (' + props.x + ', ' + props.y + ')'}
        textOptions={textOptions}
        color={cssVar('--black')} // TODO(austin): get color token for floor nodes
      />
      {/* {drawGhostNode && (
        <Svg
          url={'/svg/path/node.svg'}
          color={white}
          depth={5}
          bevel={false}
          position={[props.x + 50, props.y + 50, 1]}
          rotation={[0, 0, Math.PI]}
          scale={[1, 1, 1]}
        />
      )}
      {drawRealNode && (
        <Svg
          url={'/svg/path/node.svg'}
          color={cssVar('--cloud-produce-black')}
          depth={5}
          bevel={false}
          position={[props.x + 50, props.y + 50, 1]}
          rotation={[0, 0, Math.PI]}
          scale={[1, 1, 1]}
        />
      )} */}
      {/* {edges} */}
    </group>
  );
}
