import { CloudProduceAddress } from 'model/src/common/CloudProduceAddress';
import {
  isFishTank,
  isFloorNode,
  isStack,
  isSuperBeacon,
  isTier,
  isTower,
  isTug,
  toAddress,
} from 'model/src/common/CloudProduceAddressUtility';
import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';

import {
  DesktopContext,
  DesktopContextType,
} from '../../three/farmviewer/context/desktopcontext/DesktopContext';
import UiButton from '../../utopia/desktop/ui-buttons/UiButton';
import UiNotificationsIcon from '../../utopia/desktop/ui-icons/UiNotificationsIcon';
import UiSettingsIcon from '../../utopia/desktop/ui-icons/UiSettingsIcon';
import UiSliderIcon from '../../utopia/desktop/ui-icons/UiSliderIcon';
import WindowType from '../../utopia/window/WindowTypes';

export type MetaToolBarProps = {
  toggleWindow: (windowType: string) => void;
  isOpen: (windowType: string) => boolean;
};

const SettingsNotificationsWrapper = styled.div`
  pointer-events: auto;
  position: absolute;
  display: inline-grid;
  column-gap: var(--spacingSmall);
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-template-rows: 1;
  right: var(--spacingExtraLarge);
  top: var(--spacingExtraLarge);
  background-color: var(--tabBarBackground_Default);
  border-radius: var(--controlBarBorderRadius);
`;

const MetaToolBarContainer = (props: MetaToolBarProps) => {
  const desktopContext: DesktopContextType = useContext(DesktopContext);
  const address: CloudProduceAddress = useMemo(() => {
    return toAddress(desktopContext.selectedUrl);
  }, [desktopContext.selectedUrl]);

  return (
    <>
      <SettingsNotificationsWrapper>
        {
          <>
            {/* TODO(austin): customize for farm URL */}
            <UiButton
              text=""
              onClick={() => props.toggleWindow(WindowType.VIEW_FILTERS)}
              selected={props.isOpen(WindowType.VIEW_FILTERS)}>
              <UiSliderIcon />
            </UiButton>
            <UiButton
              key={0}
              id={0}
              onClick={() => props.toggleWindow(WindowType.SETTINGS)}
              selected={props.isOpen(WindowType.SETTINGS)}
              text="">
              <UiSettingsIcon />
            </UiButton>
            <UiButton
              key={1}
              id={1}
              onClick={() => {
                props.toggleWindow(WindowType.NOTIFICATIONS);
              }}
              selected={props.isOpen(WindowType.NOTIFICATIONS)}
              text="">
              <UiNotificationsIcon />
            </UiButton>
          </>
        }
        {/* TODO(austin): implement bar for each URL structure */}
        {isFishTank(address) && <></>}
        {isFloorNode(address) && <></>}
        {isStack(address) && <></>}
        {isSuperBeacon(address) && <></>}
        {isTier(address) && <></>}
        {isTower(address) && <></>}
        {isTug(address) && <></>}
      </SettingsNotificationsWrapper>
    </>
  );
};

const MetaToolBar = props => {
  return (
    <MetaToolBarContainer
      toggleWindow={props.toggleWindow}
      isOpen={props.isOpen}
    />
  );
};

export default MetaToolBar;
