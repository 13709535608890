import { CloudProduceAddress } from 'model/src/common/CloudProduceAddress';
import {
  isFishTank,
  isFloorNode,
  isRoot,
  isStack,
  isSuperBeacon,
  isTier,
  isTower,
  isTug,
  toAddress,
} from 'model/src/common/CloudProduceAddressUtility';
import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';

import {
  ControllerContext,
  ControllerContextType,
} from '../../three/farmviewer/context/controllercontext/ControllerContext';
import {
  DesktopContext,
  DesktopContextType,
} from '../../three/farmviewer/context/desktopcontext/DesktopContext';
import { OperationContext } from '../../three/farmviewer/context/operationcontext/OperationContext';
import UiButton from '../../utopia/desktop/ui-buttons/UiButton';
import UiTextIcon from '../../utopia/desktop/ui-icons/UiTextIcon';
import UiUpArrowIcon from '../../utopia/desktop/ui-icons/UiUpArrowIcon';

export type NavigatorBarProps = {
  select: any; //TODO(austin): get select() info
};

const Bar = styled.div`
  pointer-events: auto;
  position: absolute;
  display: block;
  bottom: var(--spacingExtraLarge);
  background-color: transparent;
  border-radius: var(--controlBarBorderRadius);
  height: 40px;
  left: 45%;
  float: none;
  width: auto;

  text-align: center;

  margin: auto;
  white-space: nowrap;
`;

const FloatSpacer = styled.div`
  display: inline-block;
  background-color: var(--tabBarBackground_Default);
  border-radius: var(--controlBarBorderRadius);

  float: none;
  align: center;
  align-vertical: center;
  white-space: nowrap;

  margin-left: var(--spacingSmall);
  margin-right: var(--spacingSmall);
`;

const UpArrowShift = styled.div`
  position: relative;
  bottom: 0px;
  float: left;
`;

const NavigatorBarContainer = props => {
  return <Bar>{props.children}</Bar>;
};

const NavigatorBar = (props: NavigatorBarProps) => {
  const desktopContext: DesktopContextType = useContext(DesktopContext);
  const address: CloudProduceAddress = useMemo(() => {
    return toAddress(desktopContext.selectedUrl);
  }, [desktopContext.selectedUrl]);
  const controllerContext: ControllerContextType = useContext(
    ControllerContext
  );
  const operationContext = useContext(OperationContext);

  return (
    <NavigatorBarContainer>
      {/* TODO(austin): customize for farm URL */}
      {!isRoot(address) && (
        <UpArrowShift>
          <FloatSpacer>
            <UiButton
              key={0}
              onClick={() => {
                operationContext.clearCurrentOperation(operationContext);
                desktopContext.select(
                  desktopContext.getParentUrl(desktopContext.selectedUrl),
                  controllerContext,
                  desktopContext
                );
              }}>
              <UiUpArrowIcon />
            </UiButton>
          </FloatSpacer>
        </UpArrowShift>
      )}
      {
        <FloatSpacer>
          <UiButton text={desktopContext.selectedUrl} key={1}>
            <UiTextIcon />
          </UiButton>
        </FloatSpacer>
      }
      {/* TODO(austin): implement bar for each URL structure */}
      {isFishTank(address) && <></>}
      {isFloorNode(address) && <></>}
      {isStack(address) && <></>}
      {isSuperBeacon(address) && <></>}
      {isTier(address) && <></>}
      {isTower(address) && <></>}
      {isTug(address) && <></>}
    </NavigatorBarContainer>
  );
};

export default NavigatorBar;
