import React, { useRef } from 'react';

import { PerspectiveCamera } from '@react-three/drei';

import { ViewCube } from './ViewCube';

export const ViewCubeScene = ({ cameraMatrix, setViewCubeRotation }) => {
  const perspectiveCameraRef = useRef();
  return (
    <>
      {/* <color attach="background" args={[cssVar('--mapBackground')]} /> */}
      <ambientLight intensity={0.25} />
      <pointLight position={[10, 10, 10]} />
      <PerspectiveCamera
        fov={50}
        aspect={1}
        near={0.1}
        far={1000}
        up={[0, 0, 1]}
        ref={perspectiveCameraRef}
        key={undefined}
        attach={undefined}
        attachArray={undefined}
        attachObject={undefined}
        args={undefined}
        onUpdate={undefined}
        view={undefined}
        id={undefined}
        lookAt={undefined}
        name={undefined}
        clear={undefined}
        zoom={undefined}
        add={undefined}
        visible={undefined}
        clone={undefined}
        copy={undefined}
        type={undefined}
        uuid={undefined}
        parent={undefined}
        modelViewMatrix={undefined}
        normalMatrix={undefined}
        matrixWorld={undefined}
        matrixAutoUpdate={undefined}
        matrixWorldNeedsUpdate={undefined}
        castShadow={undefined}
        receiveShadow={undefined}
        frustumCulled={undefined}
        renderOrder={undefined}
        animations={undefined}
        userData={undefined}
        customDepthMaterial={undefined}
        customDistanceMaterial={undefined}
        isObject3D={undefined}
        onBeforeRender={undefined}
        onAfterRender={undefined}
        applyMatrix4={undefined}
        applyQuaternion={undefined}
        setRotationFromAxisAngle={undefined}
        setRotationFromEuler={undefined}
        setRotationFromMatrix={undefined}
        setRotationFromQuaternion={undefined}
        rotateOnAxis={undefined}
        rotateOnWorldAxis={undefined}
        rotateX={undefined}
        rotateY={undefined}
        rotateZ={undefined}
        translateOnAxis={undefined}
        translateX={undefined}
        translateY={undefined}
        translateZ={undefined}
        localToWorld={undefined}
        worldToLocal={undefined}
        remove={undefined}
        getObjectById={undefined}
        getObjectByName={undefined}
        getObjectByProperty={undefined}
        getWorldPosition={undefined}
        getWorldQuaternion={undefined}
        getWorldScale={undefined}
        getWorldDirection={undefined}
        raycast={undefined}
        traverse={undefined}
        traverseVisible={undefined}
        traverseAncestors={undefined}
        updateMatrix={undefined}
        updateMatrixWorld={undefined}
        updateWorldMatrix={undefined}
        toJSON={undefined}
        addEventListener={undefined}
        hasEventListener={undefined}
        removeEventListener={undefined}
        dispatchEvent={undefined}
        updateProjectionMatrix={undefined}
        setViewOffset={undefined}
        clearViewOffset={undefined}
        matrixWorldInverse={undefined}
        projectionMatrix={undefined}
        projectionMatrixInverse={undefined}
        isCamera={undefined}
        isPerspectiveCamera={undefined}
        focus={undefined}
        filmGauge={undefined}
        filmOffset={undefined}
        setFocalLength={undefined}
        getFocalLength={undefined}
        getEffectiveFOV={undefined}
        getFilmWidth={undefined}
        getFilmHeight={undefined}
        setLens={undefined}
      />
      <ViewCube
        cameraMatrix={cameraMatrix}
        setViewCubeRotation={setViewCubeRotation}
      />
    </>
  );
};
