import {
  combine,
  create,
  fullString,
} from 'model/src/common/CloudProduceAddressUtility';
import {
  Axis,
  MicrocontrollerType,
  SumpSubsystem,
  TowerSubsystem,
  TugSubsystem,
  UmbilicalSubsystem,
} from 'model/src/common/Systems';
import { SeriesType } from 'model/src/series/SeriesTypes';
import React from 'react';

import OnlineStatus from '../../../../utopia/online-status/OnlineStatus';
import TowerData from '../../../../utopia/tower-data/TowerData';

const TowerInspectionPanel = props => {
  const isOnlineData = props.dataMap.all.get(
    fullString(combine(props.address, create([SeriesType.ONLINE])))
  ).data;
  const isOnline = isOnlineData[0].reading.value;
  const timeSince = isOnlineData[0].timestamp;

  const firmwareVersion = props.dataMap.all.get(
    fullString(
      combine(
        props.address,
        create([
          SeriesType.VERSION,
          MicrocontrollerType.RASPBERRY_PI,
          SeriesType.FIRMWARE_VERSION,
        ])
      )
    )
  )
    ? props.dataMap.all.get(
        fullString(
          combine(
            props.address,
            create([
              SeriesType.VERSION,
              MicrocontrollerType.RASPBERRY_PI,
              SeriesType.FIRMWARE_VERSION,
            ])
          )
        )
      ).data[0].reading.value
    : props.dataMap.all.get(
        fullString(
          combine(
            props.address,
            create([SeriesType.VERSION, SeriesType.FIRMWARE_VERSION])
          )
        )
      ).data[0].reading.value;

  const firmwareVersionUpdate = props.dataMap.all.get(
    fullString(
      combine(
        props.address,
        create([
          SeriesType.VERSION,
          MicrocontrollerType.RASPBERRY_PI,
          SeriesType.FIRMWARE_VERSION_UPDATE,
        ])
      )
    )
  )
    ? props.dataMap.all.get(
        fullString(
          combine(
            props.address,
            create([
              SeriesType.VERSION,
              MicrocontrollerType.RASPBERRY_PI,
              SeriesType.FIRMWARE_VERSION_UPDATE,
            ])
          )
        )
      ).data[0].reading.value
    : props.dataMap.all.get(
        fullString(
          combine(
            props.address,
            create([SeriesType.VERSION, SeriesType.FIRMWARE_VERSION_UPDATE])
          )
        )
      ).data[0].reading.value;

  return (
    <>
      <OnlineStatus
        firmwareVersion={firmwareVersion}
        firmwareVersionUpdate={firmwareVersionUpdate}
        online={isOnline}
        ipAddress={
          props.dataMap.all.get(
            fullString(combine(props.address, create([SeriesType.IP_ADDRESS])))
          ).data[0].reading.value
        }
        timeSince={timeSince}
        url={props.url}
      />
      <TowerData
        url={props.address}
        batteryLevel={props.dataMap.all.get(
          fullString(
            combine(
              props.address,
              create([TugSubsystem.BATTERY, SeriesType.BATTERY_LEVEL])
            )
          )
        )}
        connectionId={props.dataMap.all.get(
          fullString(
            combine(
              props.address,
              create([TugSubsystem.CONNECTION, SeriesType.CONNECTED_ID])
            )
          )
        )}
        isConnected={props.dataMap.all.get(
          fullString(
            combine(
              props.address,
              create([TugSubsystem.CONNECTION, SeriesType.IS_CONNECTED])
            )
          )
        )}
        dockStatus={props.dataMap.all.get(
          fullString(combine(props.address, create([SeriesType.DOCK_STATUS])))
        )}
        homeSquare={props.dataMap.all.get(
          fullString(
            combine(
              props.address,
              create([SeriesType.HOME, SeriesType.GRID_SQUARE_URL])
            )
          )
        )}
        homePositionX={props.dataMap.all.get(
          fullString(
            combine(
              props.address,
              create([SeriesType.HOME, Axis.X, SeriesType.POSITION])
            )
          )
        )}
        homePositionY={props.dataMap.all.get(
          fullString(
            combine(
              props.address,
              create([SeriesType.HOME, Axis.Y, SeriesType.POSITION])
            )
          )
        )}
        homePositionZ={props.dataMap.all.get(
          fullString(
            combine(
              props.address,
              create([SeriesType.HOME, Axis.Z, SeriesType.POSITION])
            )
          )
        )}
        isAcPresent={props.dataMap.all.get(
          fullString(
            combine(
              props.address,
              create([TowerSubsystem.UMBILICAL, SeriesType.AC_STATE])
            )
          )
        )}
        isCharging={props.dataMap.all.get(
          fullString(
            combine(
              props.address,
              create([TowerSubsystem.BATTERY, SeriesType.IS_CHARGING])
            )
          )
        )}
        positionX={props.dataMap.all.get(
          fullString(
            combine(props.address, create([Axis.X, SeriesType.POSITION]))
          )
        )}
        positionY={props.dataMap.all.get(
          fullString(
            combine(props.address, create([Axis.Y, SeriesType.POSITION]))
          )
        )}
        positionZ={props.dataMap.all.get(
          fullString(
            combine(props.address, create([Axis.Z, SeriesType.POSITION]))
          )
        )}
        pumpStatus={props.dataMap.all.get(
          fullString(
            combine(
              props.address,
              create([
                TowerSubsystem.SUMP,
                SumpSubsystem.PUMP,
                SeriesType.PUMP_STATUS,
              ])
            )
          )
        )}
        rotationX={props.dataMap.all.get(
          fullString(
            combine(props.address, create([Axis.X, SeriesType.ROTATION]))
          )
        )}
        rotationY={props.dataMap.all.get(
          fullString(
            combine(props.address, create([Axis.Y, SeriesType.ROTATION]))
          )
        )}
        rotationZ={props.dataMap.all.get(
          fullString(
            combine(props.address, create([Axis.Z, SeriesType.ROTATION]))
          )
        )}
        towerEgressFlowLM={props.dataMap.all.get(
          fullString(
            combine(
              props.address,
              create([
                TowerSubsystem.SUMP,
                SumpSubsystem.PUMP,
                SeriesType.EGRESS_FLOW_LM,
              ])
            )
          )
        )}
        towerEgressTotalLiters={props.dataMap.all.get(
          fullString(
            combine(
              props.address,
              create([
                TowerSubsystem.SUMP,
                SumpSubsystem.PUMP,
                SeriesType.EGRESS_TOTAL_LITERS_SINCE_RESET,
              ])
            )
          )
        )}
        towerIngressFlowLM={props.dataMap.all.get(
          fullString(
            combine(
              props.address,
              create([
                TowerSubsystem.SUMP,
                SumpSubsystem.PUMP,
                SeriesType.INGRESS_FLOW_LM,
              ])
            )
          )
        )}
        towerIngressTotalLiters={props.dataMap.all.get(
          fullString(
            combine(
              props.address,
              create([
                TowerSubsystem.SUMP,
                SumpSubsystem.PUMP,
                SeriesType.INGRESS_TOTAL_LITERS_SINCE_RESET,
              ])
            )
          )
        )}
        umbilicalAcState={props.dataMap.all.get(
          fullString(
            combine(
              props.address,
              create([TowerSubsystem.UMBILICAL, SeriesType.AC_STATE])
            )
          )
        )}
        umbilicalConnectedState={props.dataMap.all.get(
          fullString(
            combine(
              props.address,
              create([TowerSubsystem.UMBILICAL, SeriesType.CONNECTED_STATE])
            )
          )
        )}
        umbilicalValveState={props.dataMap.all.get(
          fullString(
            combine(
              props.address,
              create([TowerSubsystem.UMBILICAL, SeriesType.VALVE_STATE])
            )
          )
        )}
        umbilicalPositionX={props.dataMap.all.get(
          fullString(
            combine(
              props.address,
              create([
                TowerSubsystem.UMBILICAL,
                UmbilicalSubsystem.GANTRY,
                Axis.X,
                SeriesType.POSITION,
              ])
            )
          )
        )}
        umbilicalPositionY={props.dataMap.all.get(
          fullString(
            combine(
              props.address,
              create([
                TowerSubsystem.UMBILICAL,
                UmbilicalSubsystem.GANTRY,
                Axis.Y,
                SeriesType.POSITION,
              ])
            )
          )
        )}
        umbilicalPositionZ={props.dataMap.all.get(
          fullString(
            combine(
              props.address,
              create([
                TowerSubsystem.UMBILICAL,
                UmbilicalSubsystem.GANTRY,
                Axis.Z,
                SeriesType.POSITION,
              ])
            )
          )
        )}
      />
    </>
  );
};

export default TowerInspectionPanel;
