import {
  combine,
  create,
  fullString,
  toAddress,
} from 'model/src/common/CloudProduceAddressUtility';
import { Axis } from 'model/src/common/Systems';
import { SeriesType } from 'model/src/series/SeriesTypes';
import { BuiltinType } from 'model/src/typescript/Typescript';
import React, {
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import * as THREE from 'three';

import { cssVar } from '../../../..';
import {
  ControllerContext,
  ControllerContextType,
} from '../../../farmviewer/context/controllercontext/ControllerContext';
import { useDataMap } from '../../../farmviewer/context/datamapcontext/useDataMap';
import Box from '../../common/Box';
import FishTankWater from '../FishTankWater';
import WaterTank from '../WaterTank';
import StackPumpIndicatorLight from './StackPumpIndicatorLight';

export default function FishTank(props) {
  const controllerContext: ControllerContextType = useContext(
    ControllerContext
  );
  const [hovered, setHover] = useState(false);
  const underInspection = props.inspectionContext.inspectionMap.get(props.url)
    .underInspection;
  const group = useRef<THREE.Group>();

  const positionX = useDataMap(
    combine(toAddress(props.url), create([Axis.X, SeriesType.POSITION]))
  );
  const positionY = useDataMap(
    combine(toAddress(props.url), create([Axis.Y, SeriesType.POSITION]))
  );
  const positionZ = useDataMap(
    combine(toAddress(props.url), create([Axis.Z, SeriesType.POSITION]))
  );

  const [position, setPosition] = useState([
    positionX.value / 10,
    positionY.value / 10,
    positionZ.value / 10,
  ]);

  useEffect(() => {
    setPosition([
      positionX.value / 10,
      positionY.value / 10,
      positionZ.value / 10,
    ]);
  }, [positionX, positionY, positionZ]);

  const onPointerOver = controllerContext.dragMute(event => {
    setHover(true);
    event.stopPropagation();
  }, controllerContext);

  useEffect(() => {
    props.objectMap.set(props.url, {
      url: props.url,
      model: group.current,
    });
    return () => {
      props.objectMap.delete(props.url);
    };
  });

  const chooseColor = () => {
    if (underInspection) {
      return cssVar('--selected-color');
    } else if (hovered) {
      return cssVar('--hovered-color');
    } else if (typeof props.online === BuiltinType.BOOLEAN && !props.online) {
      return cssVar('--systemRed');
    } else {
      return cssVar('--standard-color');
    }
  };

  const color = chooseColor();
  let waterTankColorOverride = null;
  let waterTankInspectableOverride = props.inspectable;
  if (controllerContext.overridingRestriction) {
    if (controllerContext.overridingRestriction.propertyMap.has(props.url)) {
      const property = controllerContext.overridingRestriction.propertyMap.get(
        props.url
      );
      if (property.color) {
        waterTankColorOverride = property.color;
      }
      if (property.inspectable !== undefined) {
        waterTankInspectableOverride = property.inspectable;
      }
    }
  }
  const onClick = useCallback(
    e => {
      if (waterTankInspectableOverride) {
        props.onClick(props.url);
        e.stopPropagation();
      }
    },
    [props.onClick]
  );

  return (
    <group
      ref={group}
      position={position}
      onClick={onClick}
      onContextMenu={e => {
        props.showMenu(props.url);
        e.stopPropagation();
      }}
      onPointerOver={event => {
        if (waterTankInspectableOverride) {
          onPointerOver(event);
        }
      }}
      onPointerOut={event => {
        if (waterTankInspectableOverride) {
          setHover(false);
          event.stopPropagation();
        }
      }}>
      <Suspense fallback={<Box />}>
        <WaterTank
          color={color}
          colorOverride={waterTankColorOverride}
          id={props.url}
        />
        <FishTankWater
          // depth={props.depth[props.depth.length - 1].waterLevel}
          depth={120}
        />
      </Suspense>
      <StackPumpIndicatorLight
        isOn={props.isPumpOn}
        position={[100, -100, 325]}
      />
    </group>
  );
}

FishTank.defaultProps = {
  inspectable: true,
  position: [0, 0, 0],
  rotation: [0, 0, 0],
};
