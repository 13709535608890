import React, { useContext } from 'react';
import styled from 'styled-components';

import ClassNames from '../../../ClassNames';
import {
  UserProfileContext,
  UserProfileContextType
} from '../../../user/UserProfileContext';

const PermissionsContainer = styled.div`
  display: inline-block;
  height: auto;
  width: auto;
  white-space: nowrap;
`;

const TitleLine = styled.div`
  padding-top: var(--spacingLarge);
  padding-left: var(--spacingMedium);
  padding-bottom: var(--spacingMedium);
  display: inline-block;
`;
const TextLine = styled.div`
  padding-left: var(--spacingMedium);
  display: inline;
`;

const PermissionsContent = props => {
  const userProfileContext: UserProfileContextType = useContext(
    UserProfileContext
  );

  return (
    <PermissionsContainer>
      <TitleLine className={ClassNames.title3}>Permissions</TitleLine>
      <div>
        <TextLine className={ClassNames.bodyBold}>Email: </TextLine>
        <TextLine className={ClassNames.bodyRegular}>
          {userProfileContext.userProfile.email}
        </TextLine>
      </div>
      <div>
        <TextLine className={ClassNames.bodyBold}>User Type: </TextLine>
        <TextLine className={ClassNames.bodyRegular}>
          {userProfileContext.userProfile.userType &&
            userProfileContext.userProfile.userType.toLocaleLowerCase()}
        </TextLine>
      </div>
      <div>
        <TextLine className={ClassNames.bodyBold}>Permission Level: </TextLine>
        <TextLine className={ClassNames.bodyRegular}>
          {userProfileContext.userProfile.permissionLevel &&
            userProfileContext.userProfile.permissionLevel.toLocaleLowerCase()}
        </TextLine>
      </div>
    </PermissionsContainer>
  );
};

export default PermissionsContent;
