import React, { PropsWithChildren, useState } from 'react';
import styled from 'styled-components';

import { SelectedTab } from './SelectedTab';
import { Tab } from './Tab';
import TabBar from './TabBar';

const SizedWindow = styled.div`
  height: 100%;
  min-width: var(--windowMinWidth);
`;

export type TabControlProps = {
  selected: number;
};

export const TabControl = (props: PropsWithChildren<TabControlProps>) => {
  const [selected, setSelected] = useState(0);
  const tabContents = React.Children.toArray(props.children);
  const titleMap: string[] = [];
  React.Children.forEach(props.children, element => {
    if (!React.isValidElement(element)) {
      return;
    }
    const title = element.props.title;
    titleMap.push(title);
  });
  const displayTab = tabContents[selected];
  const generateTabs = tabContents => {
    var tabList: any[] = [];
    for (let i = 0; tabContents.length > i; i++) {
      tabList.push(
        <div key={i} title={titleMap[i]} onClick={() => setSelected(i)}>
          {i == selected ? (
            <SelectedTab title={titleMap[i]} />
          ) : (
            <Tab title={titleMap[i]} />
          )}
        </div>
      );
    }
    return tabList;
  };
  return (
    <SizedWindow>
      <TabBar selectedWindow={false}>{generateTabs(tabContents)}</TabBar>
      {displayTab}
    </SizedWindow>
  );
};
