/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import { useGLTF } from '@react-three/drei';
import CommandCenterFloor_Model from './CommandCenterFloor.glb';
import { cssVar } from '../../..';

export default function CommandCenterFloor({ ...props }) {
  const group = useRef();
  const { nodes } = useGLTF(CommandCenterFloor_Model);
  const material = (
    <meshPhongMaterial
      attach="material"
      color={cssVar('--cloud-produce-white')}
      transparent={props.transparent}
      opacity={props.opacity}
    />
  );
  return (
    <group
      ref={group}
      {...props}
      dispose={null}
      position={props.position}
      rotation={props.rotation}>
      <group position={[-225, -2537.5, -127.82]}>
        <group position={[143, -371.86, 0]}>
          <mesh geometry={nodes.mesh_0.geometry}>{material}</mesh>
          <mesh geometry={nodes.mesh_1.geometry}>{material}</mesh>
          <mesh geometry={nodes.mesh_2.geometry}>{material}</mesh>
          <mesh geometry={nodes.mesh_3.geometry}>{material}</mesh>
        </group>
        <group position={[-2730.99, 2942.5, 0]} rotation={[0, 0, -Math.PI / 2]}>
          <mesh geometry={nodes.mesh_4.geometry}>{material}</mesh>
          <mesh geometry={nodes.mesh_5.geometry}>{material}</mesh>
          <mesh geometry={nodes.mesh_6.geometry}>{material}</mesh>
          <mesh geometry={nodes.mesh_7.geometry}>{material}</mesh>
        </group>
        <group position={[-2412.89, 2942.5, 0]} rotation={[0, 0, -Math.PI / 2]}>
          <mesh geometry={nodes.mesh_8.geometry}>{material}</mesh>
          <mesh geometry={nodes.mesh_9.geometry}>{material}</mesh>
          <mesh geometry={nodes.mesh_10.geometry}>{material}</mesh>
          <mesh geometry={nodes.mesh_11.geometry}>{material}</mesh>
        </group>
        <group position={[-2630.99, 2942.5, 0]} rotation={[0, 0, -Math.PI / 2]}>
          <mesh geometry={nodes.mesh_12.geometry}>{material}</mesh>
          <mesh geometry={nodes.mesh_13.geometry}>{material}</mesh>
          <mesh geometry={nodes.mesh_14.geometry}>{material}</mesh>
          <mesh geometry={nodes.mesh_15.geometry}>{material}</mesh>
        </group>
        <group position={[-175.1, -371.86, 0]}>
          <mesh geometry={nodes.mesh_16.geometry}>{material}</mesh>
          <mesh geometry={nodes.mesh_17.geometry}>{material}</mesh>
          <mesh geometry={nodes.mesh_18.geometry}>{material}</mesh>
          <mesh geometry={nodes.mesh_19.geometry}>{material}</mesh>
        </group>
        <group position={[-2512.89, 2942.5, 0]} rotation={[0, 0, -Math.PI / 2]}>
          <mesh geometry={nodes.mesh_20.geometry}>{material}</mesh>
          <mesh geometry={nodes.mesh_21.geometry}>{material}</mesh>
          <mesh geometry={nodes.mesh_22.geometry}>{material}</mesh>
          <mesh geometry={nodes.mesh_23.geometry}>{material}</mesh>
        </group>
        <mesh geometry={nodes.mesh_24.geometry}>{material}</mesh>
        <mesh geometry={nodes.mesh_25.geometry}>{material}</mesh>
        <mesh geometry={nodes.mesh_26.geometry}>{material}</mesh>
        <mesh geometry={nodes.mesh_27.geometry}>{material}</mesh>
        <mesh geometry={nodes.mesh_28.geometry}>{material}</mesh>
      </group>
      <group
        position={[-2737.5, 750.02, -127.82]}
        rotation={[0, 0, -Math.PI / 2]}>
        <group position={[143, -371.86, 0]}>
          <mesh geometry={nodes.mesh_29.geometry}>{material}</mesh>
          <mesh geometry={nodes.mesh_30.geometry}>{material}</mesh>
          <mesh geometry={nodes.mesh_31.geometry}>{material}</mesh>
          <mesh geometry={nodes.mesh_32.geometry}>{material}</mesh>
        </group>
        <group position={[-2730.99, 2942.5, 0]} rotation={[0, 0, -Math.PI / 2]}>
          <mesh geometry={nodes.mesh_33.geometry}>{material}</mesh>
          <mesh geometry={nodes.mesh_34.geometry}>{material}</mesh>
          <mesh geometry={nodes.mesh_35.geometry}>{material}</mesh>
          <mesh geometry={nodes.mesh_36.geometry}>{material}</mesh>
        </group>
        <group position={[-2412.89, 2942.5, 0]} rotation={[0, 0, -Math.PI / 2]}>
          <mesh geometry={nodes.mesh_37.geometry}>{material}</mesh>
          <mesh geometry={nodes.mesh_38.geometry}>{material}</mesh>
          <mesh geometry={nodes.mesh_39.geometry}>{material}</mesh>
          <mesh geometry={nodes.mesh_40.geometry}>{material}</mesh>
        </group>
        <group position={[-2630.99, 2942.5, 0]} rotation={[0, 0, -Math.PI / 2]}>
          <mesh geometry={nodes.mesh_41.geometry}>{material}</mesh>
          <mesh geometry={nodes.mesh_42.geometry}>{material}</mesh>
          <mesh geometry={nodes.mesh_43.geometry}>{material}</mesh>
          <mesh geometry={nodes.mesh_44.geometry}>{material}</mesh>
        </group>
        <group position={[-175.1, -371.86, 0]}>
          <mesh geometry={nodes.mesh_45.geometry}>{material}</mesh>
          <mesh geometry={nodes.mesh_46.geometry}>{material}</mesh>
          <mesh geometry={nodes.mesh_47.geometry}>{material}</mesh>
          <mesh geometry={nodes.mesh_48.geometry}>{material}</mesh>
        </group>
        <group position={[-2512.89, 2942.5, 0]} rotation={[0, 0, -Math.PI / 2]}>
          <mesh geometry={nodes.mesh_49.geometry}>{material}</mesh>
          <mesh geometry={nodes.mesh_50.geometry}>{material}</mesh>
          <mesh geometry={nodes.mesh_51.geometry}>{material}</mesh>
          <mesh geometry={nodes.mesh_52.geometry}>{material}</mesh>
        </group>
        <mesh geometry={nodes.mesh_53.geometry}>{material}</mesh>
        <mesh geometry={nodes.mesh_54.geometry}>{material}</mesh>
        <mesh geometry={nodes.mesh_55.geometry}>{material}</mesh>
        <mesh geometry={nodes.mesh_56.geometry}>{material}</mesh>
        <mesh geometry={nodes.mesh_57.geometry}>{material}</mesh>
      </group>
      <mesh geometry={nodes.mesh_58.geometry}>{material}</mesh>
      <mesh geometry={nodes.mesh_59.geometry}>{material}</mesh>
      <mesh geometry={nodes.mesh_60.geometry}>{material}</mesh>
      <mesh geometry={nodes.mesh_61.geometry}>{material}</mesh>
      <mesh geometry={nodes.mesh_62.geometry}>{material}</mesh>
      <mesh geometry={nodes.mesh_63.geometry}>{material}</mesh>
      <mesh geometry={nodes.mesh_64.geometry}>{material}</mesh>
    </group>
  );
}

CommandCenterFloor.defaultProps = {
  position: [0, 0, 0],
  rotation: [0, 0, 0],
  transparent: false,
  opacity: 1.0,
};

useGLTF.preload(CommandCenterFloor_Model);
