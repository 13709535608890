import React from 'react';

import { cssVar } from '../../../..';
import IndicatorLightBox from '../../farm/indicatorLights/IndicatorLightBox';

const TierLightsIndicatorLight = props => {
  const onColor = cssVar('--systemGreen');
  const offColor = cssVar('--systemRed');
  const acOverrideColor = cssVar('--systemYellow');
  return (
    <IndicatorLightBox
      color={
        !props.isOn ? offColor : props.acOverride ? acOverrideColor : onColor
      }
      position={props.position}
    />
  );
};
export default TierLightsIndicatorLight;
