import styled from 'styled-components';

const CancelButton = styled.button`
  border: 0;
  opacity 1;
  height: 40px;

  padding: var(--windowButtonPadding);
  margin-right: var(--windowButtonDividerSpacing);
  
  color: var(--secondaryButtonLabel_Default);
  border-radius: var(--secondaryButtonBorderRadius);
  border: 2px solid var(--secondaryButtonLabel_Default);
  background-color: var(--secondaryButtonBackground_Default);

  &:hover {
    color: var(--secondaryButtonLabel_Hover);
    background-color: var(--secondaryButtonBackground_Hover);
  }
`;

export default CancelButton;
