import { RegexPhrases, RegexTokens } from 'model/src/common/Regex';
import {
  FarmSubsystem,
  StackSubsystem,
  TowerSubsystem,
} from 'model/src/common/Systems';

import { ProjectionType } from '../farmviewer/context/controllercontext/ControllerContext';

export type ContextViewType = {
  absoluteTargetPosition?: boolean;
  cameraPositionOffset: { x: number; y: number; z: number };
  cameraTargetOffset: { x: number; y: number; z: number };
  projectionType: ProjectionType;
  trussProperties: { transparent: boolean; opacity: number };
};

const farmContextView = {
  absoluteTargetPosition: true,
  cameraPositionOffset: { x: 35, y: -90, z: 90 },
  cameraTargetOffset: { x: 35, y: 150, z: 0 },
  projectionType: ProjectionType.PERSPECTIVE,
  trussProperties: { transparent: false, opacity: 1 },
};

const farmContextViewKey = new RegExp(
  RegexPhrases.ANY_NUMBER_START + RegexTokens.END
);

const tierContextView = {
  cameraPositionOffset: { x: 0, y: -70, z: 0 },
  cameraTargetOffset: { x: 0, y: 0, z: 0 },
  projectionType: ProjectionType.ORTHOGRAPHIC,
  trussProperties: { transparent: true, opacity: 0 },
};
const tierContextViewKey = new RegExp(
  RegexPhrases.ANY_NUMBER_START +
    RegexPhrases.DOT +
    FarmSubsystem.TOWER +
    RegexPhrases.DOT +
    RegexPhrases.ANY_NUMBER +
    RegexPhrases.DOT +
    TowerSubsystem.TIER +
    RegexPhrases.DOT +
    RegexPhrases.ANY_NUMBER_END
);

const towerContextView = {
  cameraPositionOffset: { x: 125, y: -110, z: 110 },
  cameraTargetOffset: { x: 0, y: 0, z: 0 },
  projectionType: ProjectionType.ORTHOGRAPHIC,
  trussProperties: { transparent: true, opacity: 0 },
};
const towerContextViewKey = new RegExp(
  RegexPhrases.ANY_NUMBER_START +
    RegexPhrases.DOT +
    FarmSubsystem.TOWER +
    RegexPhrases.DOT +
    RegexPhrases.ANY_NUMBER_END
);

const towerPlacementContextView: ContextViewType = {
  absoluteTargetPosition: true,
  cameraPositionOffset: { x: 0, y: -0.001, z: 500 },
  cameraTargetOffset: { x: 38, y: 175, z: 0 },
  projectionType: ProjectionType.ORTHOGRAPHIC,
  trussProperties: { transparent: true, opacity: 0 },
};
const towerPlacementContextViewKey: RegExp = new RegExp(
  RegexPhrases.ANY_NUMBER_START +
    RegexPhrases.DOT +
    FarmSubsystem.TOWER +
    RegexPhrases.DOT +
    RegexPhrases.ANY_NUMBER +
    RegexPhrases.OPERATION +
    RegexPhrases.PLACEMENT_END
);

const tugContextView = {
  cameraPositionOffset: { x: 80, y: -80, z: 140 },
  cameraTargetOffset: { x: 0, y: 0, z: 0 },
  projectionType: ProjectionType.ORTHOGRAPHIC,
  trussProperties: { transparent: true, opacity: 0 },
};
const tugContextViewKey = new RegExp(
  RegexPhrases.ANY_NUMBER_START +
    RegexPhrases.DOT +
    FarmSubsystem.TUG +
    RegexPhrases.DOT +
    RegexPhrases.ANY_NUMBER_END
);

const tugPlacementContextView: ContextViewType = {
  absoluteTargetPosition: true,
  cameraPositionOffset: { x: 0, y: -0.001, z: 500 },
  cameraTargetOffset: { x: 38, y: 175, z: 0 },
  projectionType: ProjectionType.ORTHOGRAPHIC,
  trussProperties: { transparent: true, opacity: 0 },
};

const tugPlacementContextViewKey = new RegExp(
  RegexPhrases.ANY_NUMBER_START +
    RegexPhrases.DOT +
    FarmSubsystem.TUG +
    RegexPhrases.DOT +
    RegexPhrases.ANY_NUMBER +
    RegexPhrases.OPERATION +
    RegexPhrases.PLACEMENT_END
);

const tugAlignmentContextViewKey = new RegExp(
  RegexPhrases.ANY_NUMBER_START +
    RegexPhrases.DOT +
    FarmSubsystem.TUG +
    RegexPhrases.DOT +
    RegexPhrases.ANY_NUMBER +
    RegexPhrases.OPERATION +
    RegexPhrases.ALIGNMENT_END
);
const tugAlignmentContextView: ContextViewType = {
  absoluteTargetPosition: false,
  cameraPositionOffset: { x: 0, y: 0.001, z: 30 },
  cameraTargetOffset: { x: 0, y: 0, z: 0 },
  projectionType: ProjectionType.ORTHOGRAPHIC,
  trussProperties: { transparent: true, opacity: 0 },
};

const umbilicalContextView = {
  cameraPositionOffset: { x: 0, y: -60, z: 0 },
  cameraTargetOffset: { x: 0, y: 0, z: 0 },
  projectionType: ProjectionType.ORTHOGRAPHIC,
  trussProperties: { transparent: true, opacity: 0 },
};

const umbilicalContextViewKey = new RegExp(
  RegexPhrases.ANY_NUMBER_START +
    RegexPhrases.DOT +
    FarmSubsystem.TOWER +
    RegexPhrases.DOT +
    RegexPhrases.ANY_NUMBER +
    RegexPhrases.DOT +
    TowerSubsystem.UMBILICAL +
    RegexTokens.END
);

const waterTankContextView = {
  cameraPositionOffset: { x: 0, y: -60, z: 0 },
  cameraTargetOffset: { x: 0, y: 0, z: 0 },
  projectionType: ProjectionType.ORTHOGRAPHIC,
  trussProperties: { transparent: true, opacity: 0 },
};
const waterTankContextViewKey = new RegExp(
  RegexPhrases.ANY_NUMBER_START +
    RegexPhrases.DOT +
    FarmSubsystem.STACK +
    RegexPhrases.DOT +
    RegexPhrases.ANY_NUMBER +
    RegexPhrases.DOT +
    StackSubsystem.FISH_TANK +
    RegexPhrases.DOT +
    RegexPhrases.ANY_NUMBER_END
);

const buildContextViewMap = () => {
  const data = new Map<RegExp, ContextViewType>([
    [farmContextViewKey, farmContextView],
    [tierContextViewKey, tierContextView],
    [towerContextViewKey, towerContextView],
    [towerPlacementContextViewKey, towerPlacementContextView],
    [tugContextViewKey, tugContextView],
    [tugAlignmentContextViewKey, tugAlignmentContextView],
    [tugPlacementContextViewKey, tugPlacementContextView],
    [umbilicalContextViewKey, umbilicalContextView],
    [waterTankContextViewKey, waterTankContextView],
  ]);
  const getContextView: (url: string) => ContextViewType | undefined = (
    url: string
  ) => {
    for (let [key, value] of data) {
      // TODO(austin): optimize
      if (key.test(url)) {
        if (value) {
          return value;
        }
      }
    }
  };
  return {
    data: data,
    getContextView: getContextView,
  };
};

export default buildContextViewMap;
