import React from 'react';
import styled from 'styled-components';

export const ModalBackgroundPanel = styled.div`
  position: absolute;
  height: calc(100% - var(--windowHeaderHeight));
  width: 100%;
  border-radius: var(--windowBottomBorderRadius);
  pointer-events: all;
  background-color: black;
  opacity: 0.5;
  z-index: 200;
`;

export const SchedulerModalPanel = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  width: auto;
  min-width: var(--schedulerModalMinWidth);
  background-color: var(--cardBackgroundLight);
  opacity: 1;
  display: inline-flex;
  padding: var(--spacingXS);
  flex-direction: column;
  align-items: stretch;
  gap: var(--spacingMedium);
  border-radius: var(--windowBorderRadius);
  z-index: 201;
`;

export const SchedulerModalOptionContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacingXS);
  align-self: stretch;
`;
export const SchedulerModalOptionBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--spacingXS);
  align-self: stretch;
  width: auto;
`;

export const SchedulerModalTestSubmissionBox = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
  align-self: stretch;
  gap: var(--spacingXXS);
  height: 34px;
`;

export const SchedulerModalDropdownMenuContainer = styled.div`
  position: relative;
  display: block;
  width: 100px;
`;

export const SchedulerModalDropdownMenuButtonStyling = styled.div`
  position: relative;
  z-index: 101;
  display: flex;
  flex-direction: column;
  width: 100px;
  padding-top: var(--spacingMini);
  padding-bottom: var(--spacingMini);
  margin-left: calc(-1 * var(--borderSize));
  margin-right: calc(-1 * var(--borderSize));
  justify-content: center;
  align-items: center;
  border: ${props =>
    props.isOpen
      ? 'var(--borderSize) solid var(--dropdownStrokeActive)'
      : 'var(--borderSize) solid var(--dropdownStrokeColorDefault)'};
  border-radius: var(--schedulerButtonBorderRadius);
  background: var(--dropdownFillColorDefault);

  &:active {
    border: var(--borderSize) solid var(--dropdownStrokeActive);
  }

  &:disabled {
  }
`;

export const SchedulerModalDropdownMenuStyling = styled.div`
  position: absolute;
  z-index: 101;
  top: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: inherit;
`;
export const SchedulerModalDropdownMenuOption = styled.div`
  position: relative;
  z-index: 101;
  display: block;
  width: inherit;
  text-align: center;
  padding: var(--spacingXXXS) 0px;
  background-color: var(--dropdownFillDefault);
  color: var(--dropdownTextDefault);
  border-bottom: ${props =>
    props.isLast
      ? '0px'
      : 'var(--borderSize) solid var(--dropdownStrokeColorDefault)'};
`;
