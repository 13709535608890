import React, { PropsWithChildren } from 'react';

import { WindowPosition } from '../../three/farmviewer/InspectionNodeUtility';
import { HeaderButtonType } from '../../utopia/window/components/WindowHeaderButton';
import DraggableWindow from '../../utopia/window/DraggableWindow';
import WindowType from '../../utopia/window/WindowTypes';

export type StaticInspectionProps = {
  initialWindowPosition: WindowPosition;
  url: string;
  dataMap: any;
  isTugVariable: boolean;
  isFloorNodeVariable: boolean;
  isDriveTrainVariable: boolean;
  isSuperBeaconVariable: boolean;
  isSumpVariable: boolean;
  isFishTankVariable: boolean;
  isTowerVariable: boolean;
  isTierVariable: boolean;
  isUmbilicalVariable: boolean;
};
export type InspectedContentProps = {
  title: string;
  key: string;
  initialWindowPosition: WindowPosition;
  url: string;
  closeHandler: () => void;
  onDrag: (e, dragData) => void;
};

export type InspectionWindowProps = {
  title: string;
  initialWindowPosition: WindowPosition;
  url: string;
  onClick: () => void;
  onDragStart: () => void;
  onDragEnd: () => void;
  onMouseDown: () => void;
  closeHandler: () => void;
  onDrag: (e, dragData) => void;
  headerButtons: HeaderButtonType[];
  headerButtonOnClicks: (() => void)[];
  selected: boolean;
  windowType: WindowType;
};

const InspectionWindow = (props: PropsWithChildren<InspectionWindowProps>) => {
  return (
    <DraggableWindow
      title={props.title}
      url={props.url}
      onDrag={props.onDrag}
      onDragEnd={props.onDragEnd}
      initialWindowPosition={props.initialWindowPosition}
      headerButtons={[HeaderButtonType.CLOSE]}
      headerButtonOnClicks={[() => props.closeHandler()]}
      onClick={props.onClick}
      onDragStart={props.onDragStart}
      onMouseDown={props.onMouseDown}
      selected={props.selected}
      windowType={WindowType.INSPECTION}>
      {props.children}
    </DraggableWindow>
  );
};

export default InspectionWindow;
