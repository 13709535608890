import { toAddress } from 'model/src/common/CloudProduceAddressUtility';
import { Operations, RegexPhrases } from 'model/src/common/Regex';
import { TugSubsystem, urlCombine } from 'model/src/common/Systems';
import { Data } from 'model/src/dataflowprotocol/Datum';
import DriveCommandType from 'model/src/dataflowprotocol/pagetopageserver/commands/DriveCommandType';
import { PageToPageServer } from 'model/src/dataflowprotocol/pagetopageserver/PageToPageServer';
import { PageToPageServerType } from 'model/src/dataflowprotocol/pagetopageserver/PageToPageServerType';
import {
  NavigationalIntent,
  NavigationalIntentType,
} from 'model/src/navigation/NavigationalIntent';
import { AutonomyStatus } from 'model/src/series/AutonomyStatus';
import { AutonomyStatusReading } from 'model/src/series/AutonomyStatusReading';
import { DrivetrainControlMode } from 'model/src/series/DrivetrainControlMode';
import { SeriesType } from 'model/src/series/SeriesTypes';
import React, { useContext, useRef, useState } from 'react';
import styled from 'styled-components';

import { OldNeumorphicButton } from '../../neumorphic/OldNeumorphicButton';
import {
  ControllerContext,
  ControllerContextType,
} from '../context/controllercontext/ControllerContext';
import {
  DesktopContext,
  DesktopContextType,
} from '../context/desktopcontext/DesktopContext';
import {
  OperationContext,
  OperationContextType,
} from '../context/operationcontext/OperationContext';
import GamepadController from '../gamepad/GamepadController';
import AutonomousControl from './tug/AutonomousControl';
import BatteryIndicator from './tug/BatteryIndicator';
import DrivetrainControlSignal from './tug/DrivetrainControlSignal';
import LiftControl from './tug/LiftControl';

const VerticalPanel = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const HorizontalPanel = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const BatteryHolder = styled.div`
  position: absolute;
  right: 32px;
  margin-top: 16px;
  width: fit-content;
  block-size: fit-content;
`;

const NwArrow = styled.div`
  color: inherit;
  width: 20px;
  height: 20px;
  transform: rotate(-45deg);
  font-size: 20px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
`;

const NArrow = styled.div`
  color: inherit;
  width: 20px;
  height: 20px;
  font-size: 20px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
`;

const NeArrow = styled.div`
  color: inherit;
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  font-size: 20px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
`;

const WArrow = styled.div`
  color: inherit;
  width: 20px;
  height: 20px;
  transform: rotate(-90deg);
  font-size: 20px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
`;

const Icon = styled.div`
  color: inherit;
  width: 20px;
  height: 20px;
  font-size: 20px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
`;

const EArrow = styled.div`
  color: inherit;
  width: 20px;
  height: 20px;
  transform: rotate(90deg);
  font-size: 20px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
`;

const SwArrow = styled.div`
  color: inherit;
  width: 20px;
  height: 20px;
  transform: rotate(-135deg);
  font-size: 20px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
`;

const SArrow = styled.div`
  color: inherit;
  width: 20px;
  height: 20px;
  transform: rotate(180deg);
  font-size: 20px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
`;

const SeArrow = styled.div`
  color: inherit;
  width: 20px;
  height: 20px;
  font-size: 20px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
`;

const TrolleyContainer = styled.div`
  height: 800px;
  width: 256px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const ButtonPanel = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  user-select: none;
`;

const ButtonRow = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;

  > button {
    margin: 6px;
  }
`;

const GamepadContainer = styled.div`
  margin-top: 32px;
`;

export default function TrolleyPanel(props) {
  if (!props.connectionToServer) {
    throw new Error(
      'TrolleyPanel:: props.connectionToServer:' + props.connectionToServer
    );
  }
  const operationContext: OperationContextType = useContext(OperationContext);
  const desktopContext: DesktopContextType = useContext(DesktopContext);
  const controllerContext: ControllerContextType = useContext(
    ControllerContext
  );

  const [controlType, setControlType] = useState(DrivetrainControlMode.Lifting);

  const command = useRef('');
  const commandInput = useRef();
  const activeDriveCommandType = useRef(DriveCommandType.STOP);
  const activeSpeed = useRef(0.1);

  const lastCommand = useRef<PageToPageServer | null>(null);

  const tugControllerUrl = props.url;

  // const sendDriveCommand = () => {
  //   const url = props.url
  //   props.connectionToServer.sendMessage({
  //   type: PageToPageServerType.SendCommand,
  //     sendCommand: {
  //       targetId: url,
  //       driveCommand: {
  //         commandType: activeDriveCommandType.current,
  //         speed: activeSpeed.current,
  //       },
  //     },
  //   });
  // };

  const sendNavigateCommand = (navigationalIntent: NavigationalIntent) => {
    const newCommand: PageToPageServer = {
      type: PageToPageServerType.SendCommand,
      sendCommand: {
        targetId: tugControllerUrl,
        navigateCommand: {
          navigationalIntent: navigationalIntent,
        },
      },
    };
    //if (JSON.stringify(newCommand) !== JSON.stringify(lastCommand.current)) {
    props.connectionToServer.sendMessage(newCommand);
    lastCommand.current = newCommand;
    //}
  };

  const autonomyStatusData: Data<AutonomyStatusReading> = props.dataMap.all.get(
    tugControllerUrl + urlCombine([SeriesType.AUTONOMY_STATUS])
  );
  const autonomyStatus: AutonomyStatus =
    autonomyStatusData.data[0].reading.value;
  const sendDriveCommand2 = (
    linearVelocity,
    angle,
    angularVelocity,
    maxWheelSpeedMS
  ) => {
    const url = props.url;

    const newCommand: PageToPageServer = {
      type: PageToPageServerType.SendCommand,
      sendCommand: {
        targetId: url,
        driveCommand2: {
          linearVelocity,
          angle,
          angularVelocity,
          maxWheelSpeedMS,
        },
      },
    };

    if (
      !(
        linearVelocity === 0 &&
        angularVelocity === 0 &&
        JSON.stringify(newCommand) === JSON.stringify(lastCommand.current)
      )
    ) {
      props.connectionToServer.sendMessage(newCommand);
      lastCommand.current = newCommand;
    }
  };

  const sendLiftCommand = (
    absolutePosition: number,
    power: number,
    weight: number,
    speed: number,
    shouldHome: boolean
  ) => {
    const url = props.url;

    const newCommand: PageToPageServer = {
      type: PageToPageServerType.SendCommand,
      sendCommand: {
        targetId: url,
        liftCommand: {
          absolutePosition,
          power,
          weight,
          speed,
          shouldHome,
        },
      },
    };

    if (JSON.stringify(newCommand) !== JSON.stringify(lastCommand.current)) {
      props.connectionToServer.sendMessage(newCommand);
    }
    lastCommand.current = newCommand;
  };

  const sendAutonomousCommand = (autonomousCommand: string) => {
    const url = props.url;

    const command: PageToPageServer = {
      type: PageToPageServerType.SendAutonomousCommand,
      sendAutonomousCommand: {
        targetId: url,
        autonomousCommand: autonomousCommand,
      },
    };

    props.connectionToServer.sendMessage(command);
  };

  // const commandDriveButtonClicked = (driveCommandType: DriveCommandType) => {
  //   activeDriveCommandType.current = driveCommandType;
  //   sendDriveCommand();
  // };

  // const speedChanged = speed => {
  //   activeSpeed.current = speed;
  //   sendDriveCommand();
  // };

  // const commandInputHandleChange = event => {
  //   command.current = event.target.value;
  //   event.stopPropagation();
  //   event.preventDefault();
  // };

  // const commandSubmitButtonClicked = () => {
  //   const url = props.url
  //   props.connectionToServer.sendMessage({
  //   type: PageToPageServerType.SendCommand,
  //     sendCommand: {
  //       targetId: url,
  //       commandLineCommand: {
  //         command: command.current,
  //       },
  //     },
  //   });
  //   commandInput.current.value = '';
  //   command.current = '';
  // };

  return (
    <TrolleyContainer>
      <HorizontalPanel>
        <VerticalPanel>
          <BatteryHolder>
            <BatteryIndicator
              url={props.url + urlCombine([TugSubsystem.BATTERY])}
              dataMap={props.dataMap}
            />
          </BatteryHolder>
        </VerticalPanel>
      </HorizontalPanel>
      {/* <Orientation position={props.position.position[0].position} /> */}
      <DrivetrainControlSignal
        url={props.url + urlCombine([TugSubsystem.DRIVE_TRAIN])}
        dataMap={props.dataMap}
      />
      <div>
        <input
          type="radio"
          value={DrivetrainControlMode.Lifting}
          name={props.url + '_controlType_' + DrivetrainControlMode.Lifting}
          checked={controlType === DrivetrainControlMode.Lifting}
          onChange={(event: any) => {
            setControlType(DrivetrainControlMode.Lifting);
          }}
        />{' '}
        Lifting
        <input
          type="radio"
          value={DrivetrainControlMode.Manual}
          name={props.url + '_controlType_' + DrivetrainControlMode.Manual}
          checked={controlType === DrivetrainControlMode.Manual}
          onChange={(event: any) => {
            setControlType(DrivetrainControlMode.Manual);
          }}
        />{' '}
        Manual
        <input
          type="radio"
          value={DrivetrainControlMode.LineFollowing}
          name={
            props.url + '_controlType_' + DrivetrainControlMode.LineFollowing
          }
          checked={controlType === DrivetrainControlMode.LineFollowing}
          onChange={(event: any) => {
            setControlType(DrivetrainControlMode.LineFollowing);
          }}
        />{' '}
        Line Following
        <input
          type="radio"
          disabled={
            !(
              autonomyStatus === AutonomyStatus.AutonomyAvailable ||
              autonomyStatus === AutonomyStatus.Autonomous
            )
          }
          value={DrivetrainControlMode.Autonomous}
          name={props.url + '_controlType_' + DrivetrainControlMode.Autonomous}
          checked={controlType === DrivetrainControlMode.Autonomous}
          onChange={(event: any) => {
            setControlType(DrivetrainControlMode.Autonomous);
          }}
        />{' '}
        Autonomous
      </div>
      {controlType !== DrivetrainControlMode.Lifting &&
        controlType !== DrivetrainControlMode.Autonomous && (
          <>
            <GamepadContainer>
              <GamepadController
                controlType={controlType}
                buttonXClicked={() => {
                  const intent: NavigationalIntent = {
                    type: NavigationalIntentType.CENTER,
                    speedMps: 0.1,
                    distance: 10,
                  };
                  sendNavigateCommand(intent);
                }}
                buttonYClicked={() => {
                  const intent: NavigationalIntent = {
                    type: NavigationalIntentType.CENTER_TOP,
                    speedMps: 0.1,
                    distance: 10,
                  };
                  sendNavigateCommand(intent);
                }}
                gamepadStateUpdated={(
                  linearVelocity,
                  angle,
                  angularVelocity,
                  maxWheelSpeedMS
                ) => {
                  if (controlType === DrivetrainControlMode.Manual) {
                    sendDriveCommand2(
                      linearVelocity,
                      angle,
                      angularVelocity,
                      maxWheelSpeedMS
                    );
                  }
                }}
                navigationalIntentUpdated={(intent: NavigationalIntent) => {
                  if (controlType === DrivetrainControlMode.LineFollowing) {
                    sendNavigateCommand(intent);
                  }
                }}
              />
            </GamepadContainer>
            <VerticalPanel>
              <OldNeumorphicButton
                style={{ width: '100%', top: 20 }}
                onClick={() => {
                  console.log('ALIGNING!');
                  operationContext.setAlignmentOperation(
                    {
                      targetUrl: toAddress(props.url), //TODO(austin): add alignOperation (SendCommand) when making alignment autonomous
                    },
                    operationContext
                  );
                  desktopContext.select(
                    props.url,
                    controllerContext,
                    desktopContext,
                    RegexPhrases.OPERATION + Operations.ALIGNMENT
                  );
                }}>
                Align by Magnet
              </OldNeumorphicButton>
            </VerticalPanel>
          </>
        )}
      {controlType === DrivetrainControlMode.Lifting && (
        <LiftControl
          onSubmit={(position, power, weight, speed, shouldHome) => {
            sendLiftCommand(position, power, weight, speed, shouldHome);
          }}
          sendNavigateCommand={sendNavigateCommand}
        />
      )}
      {controlType === DrivetrainControlMode.Autonomous && (
        <AutonomousControl
          sendAutonmousCommand={(autonomousCommand: string) => {
            sendAutonomousCommand(autonomousCommand);
          }}
        />
      )}
    </TrolleyContainer>
  );
}
