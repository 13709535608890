import 'reflect-metadata';

import InjectionContext from 'commandcenter/src/injection/InjectionContext';
import { Container } from 'inversify';
import React, { useMemo, useState } from 'react';
import { bindPageEnvironment } from 'services/src/injection/pageclient/PageModule';
import { bindPageProdEnvironment } from 'services/src/injection/pageclient/PageProdModule';
import Logger from 'services/src/logging/Logger';
import PageKernel from 'services/src/pageKernel/PageKernel';

import { InjectionContextType } from './InjectionContext';

export type InjectionContextProviderProps = {
  email: string;
};

export default function InjectionContextProvider(props) {
  const { coreLogger, clockFactory, pageKernel } = useMemo(() => {
    const container = new Container();
    bindPageEnvironment(container);
    bindPageProdEnvironment(container);

    const pageKernel: PageKernel = container.get(PageKernel);

    const coreLogger: Logger = pageKernel.coreLogger;
    const clockFactory = pageKernel.clockFactory;

    return {
      coreLogger,
      clockFactory,
      pageKernel,
    };
  }, []);

  const updateContext = (contextValue: InjectionContextType) => {
    setInjectionContext({
      ...contextValue,
    });
  };

  const [injectionContext, setInjectionContext] = useState<
    InjectionContextType
  >({
    coreLogger: coreLogger,
    clockFactory: clockFactory,
    pageKernel: pageKernel,
  });

  return (
    <InjectionContext.Provider value={injectionContext}>
      {props.children}
    </InjectionContext.Provider>
  );
}
