import React from 'react';
import styled from 'styled-components';

import ClassNames from '../../ClassNames';
import NotificationsContent from '../../utopia/desktop/meta-tool-bar/GlobalNotificationsContent';
import { HeaderButtonType } from '../../utopia/window/components/WindowHeaderButton';
import DraggableWindow from '../../utopia/window/DraggableWindow';
import WindowType from '../../utopia/window/WindowTypes';

const Content = styled.div`
  width: var(--notificationsWindowWidth);
  height: var(--notificationsWindowHeight);
  border-radius: var(--windowBottomBorderRadius);
  color: var(--black);
  background-color: var(--dropdownMenuItemBackground_Default);
`;

const NotificationsWindow = props => {
  return (
    <DraggableWindow
      onDrag={props.onDrag}
      onDragEnd={props.onDragEnd}
      initialWindowPosition={props.initialWindowPosition}
      title="Notifications"
      headerButtons={[HeaderButtonType.CLOSE]}
      headerButtonOnClicks={[() => props.closeHandler()]}
      onClick={props.onClick}
      onDragStart={props.onDragStart}
      onMouseDown={props.onMouseDown}
      selected={props.selected}>
      <Content className={ClassNames.title4} key={WindowType.NOTIFICATIONS}>
        <NotificationsContent />
      </Content>
    </DraggableWindow>
  );
};

export default NotificationsWindow;
