"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NavigationalIntentType = void 0;
var NavigationalIntentType;

(function (NavigationalIntentType) {
  NavigationalIntentType["MANUAL"] = "MANUAL";
  NavigationalIntentType["FORWARD"] = "FORWARD";
  NavigationalIntentType["LEFT"] = "LEFT";
  NavigationalIntentType["RIGHT"] = "RIGHT";
  NavigationalIntentType["BACK"] = "BACK";
  NavigationalIntentType["STOP"] = "STOP";
  NavigationalIntentType["CENTER"] = "CENTER";
  NavigationalIntentType["CENTER_TOP"] = "CENTER_TOP";
  NavigationalIntentType["LIFT_UP"] = "LIFT_UP";
  NavigationalIntentType["PLACE"] = "PLACE";
  NavigationalIntentType["MAGNETIC_CENTER"] = "MAGNETIC_CENTER";
})(NavigationalIntentType = exports.NavigationalIntentType || (exports.NavigationalIntentType = {}));