import React from 'react';
import styled from 'styled-components';

const Path = styled.path`
  fill: ${props => props.fillColour};
`;
const Svg = styled.svg`
  padding: calc(var(--switchIconHeight) * 0.25) 0px 0px
    calc(var(--switchIconHeight) * 0.25);

  height: var(--switchIconHeight);
  width: var(--switchIconWidth);
  stroke: ${props => props.fillColour};
  fill: ${props => props.fillColour};
`;

const Check = props => {
  return (
    <Svg
      fillColour={props.fillColour}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <Path
        fillColour={props.fillColour}
        d="M6.5 12L2 7.49997L2.707 6.79297L6.5 10.5855L13.293 3.79297L14 4.49997L6.5 12Z"
        fill="#B1E29C"
      />
    </Svg>
  );
};

export default Check;
