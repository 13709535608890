import { CloudProduceAddress } from 'model/src/common/CloudProduceAddress';
import { create } from 'model/src/common/CloudProduceAddressUtility';
// import { FloorNode } from 'model/src/farm/entities/farm/v2/FloorNode';
// import { Path, PathNode } from 'model/src/navigation/Path';
import React, { Context } from 'react';

export enum EdgeDirection {
  FORWARD = 'FORWARD',
  BACK = 'BACK',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export enum EdgeAxis {
  VERTICAL = 'VERTICAL',
  HORIZONTAL = 'HORIZONTAL',
}

export enum EdgeTurn {
  FORWARD_TO_LEFT = 'FORWARD_TO_LEFT',
  FORWARD_TO_RIGHT = 'FORWARD_TO_RIGHT',
  BACK_TO_LEFT = 'BACK_TO_LEFT',
  BACK_TO_RIGHT = 'BACK_TO_RIGHT',
  LEFT_TO_FRONT = 'LEFT_TO_FRONT',
  LEFT_TO_BACK = 'LEFT_TO_BACK',
  RIGHT_TO_FRONT = 'RIGHT_TO_FRONT',
  RIGHT_TO_BACK = 'RIGHT_TO_BACK',
  NONE = 'NONE',
}

export type PathEdge = {
  id: string;
  sourceNodeId: string;
  sourceFloorNodeId: CloudProduceAddress;
  sourceDistance: number;
  sourceChannel: number;
  destinationNodeId: string;
  destinationFloorNodeId: CloudProduceAddress;
  destinationDistance: number;
  destinationChannel: number;

  edgeAxis: EdgeAxis;
  edgeDirection: EdgeDirection;
  edgeDistance: number;
};

export type PathContextType = {
  // setGraph(graph: Map<string, FloorNode>);

  // setPathSource(sourceUrl: string);

  // setPathDestination(destinationUrl: string);

  // newPath?: Path;
  // createNewPath: () => void;
  tugUrl?: string;
  getEdgesByFloorNode(floorNodeId: string): PathEdge[];
  getEdgeByDistance(distance: number): PathEdge;
  getPathSegmentChannel(distance: number): number;
  renderPath: boolean;
  setRenderPath: (boolean) => void;

  // ghostNodeUrl: string;
  // setGhostNodeUrl: (string) => void;
};

export const PathContext = React.createContext<PathContextType>({
  // setGraph: () => {},
  // setPathSource: () => {},
  // setPathDestination: () => {},
  // newPath: undefined,
  // createNewPath: () => {},
  tugUrl: undefined,
  getEdgesByFloorNode: () => {
    return [];
  },
  getEdgeByDistance: () => {
    return {
      id: '',
      sourceNodeId: '',
      sourceFloorNodeId: create(['']),
      sourceDistance: 0,
      sourceChannel: 0,
      destinationNodeId: '',
      destinationFloorNodeId: create(['']),
      destinationDistance: 0,
      destinationChannel: 0,

      edgeAxis: EdgeAxis.HORIZONTAL,
      edgeDirection: EdgeDirection.BACK,
      edgeDistance: 0,
    };
  },
  getPathSegmentChannel: () => {
    return 0;
  },
  renderPath: false,
  setRenderPath: () => {},
  // ghostNodeUrl: '',
  // setGhostNodeUrl: () => {},
}) as Context<PathContextType>;
