import TimerTemplate, {
  TimerTemplateUpdateType,
} from 'model/src/timer/TimerTemplate';
import React, { useState } from 'react';
import Draggable from 'react-draggable';
import styled from 'styled-components';

import { cssVar } from '../..';
import WindowType from '../window/WindowTypes';

const TriangleContainer = styled.div`
  position: absolute;
  display: inline-block;
  left: calc(
    calc(var(--spacingLarge) + var(--spacingSmall)) + ${props => props.left}px
  );
  margin-left: var(--spacingSmall);
  top: calc(var(--scheduleTimerTopOffset) + ${props => props.top});
  width: fit-content;
  background-color: ${props => props.color};
  opacity: 0.8;
  color: var(--color_neutralsGray800);
  padding: 2px;
  font-size: 14px;
`;

const Triangle = styled.div`
  position: absolute;
  left: calc(-1 * calc(var(--spacingMicro) + var(--spacingMini)));
  top: 0px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 7px 0 0;
  border-color: ${props => props.color} transparent transparent transparent;
  transform: scaleX(-1);
`;

const TimerTemplateBlock = props => {
  const [deltaY, setDeltaY] = useState(0);

  const MINUTE_TO_PIXEL_MULTIPLIER =
    Number(cssVar('--spacingExtraLarge').replace('px', '')) / 60;
  const getTimeFromPixels = (pixels: number) => {
    const totalMinutes = pixels / MINUTE_TO_PIXEL_MULTIPLIER;
    return totalMinutes;
  };

  const onContextMenu = e => {
    props.showContextMenu('Modify Timer', {
      windowType: WindowType.SCHEDULER,
      timerTemplate: props.timerTemplate,
      openModal: (updateType: TimerTemplateUpdateType) =>
        props.setModal({
          commandType: null,
          updateType: updateType,
          timerTemplate: props.timerTemplate,
        }),
      hideContextMenu: props.hideContextMenu,
    });
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Draggable
      bounds="parent"
      onDrag={(e, dragData) => {
        setDeltaY(dragData.y);
      }}
      onStop={e => {
        props.setModal({
          commandType: null,
          updateType: TimerTemplateUpdateType.MODIFY,
          timerTemplate: props.timerTemplate,
          timeDiff: getTimeFromPixels(deltaY),
        });
      }}>
      <TriangleContainer
        top={props.time}
        color={props.color}
        left={props.left}
        onContextMenu={onContextMenu}>
        <Triangle color={props.color} />
        {props.text}
      </TriangleContainer>
    </Draggable>
  );
};

export default TimerTemplateBlock;
