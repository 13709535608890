import React from 'react';
import styled from 'styled-components';

import { Path } from './IconStyling';

const Svg = styled.svg`
  padding: 0px 0x 0px 0px;
  align: center;
  float: left;
  height: var(--controlBarIconContainerHeight);
  width: var(--controlBarIconContainerWidth);
`;

const UiUpArrowIcon = () => {
  return (
    <Svg fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path d="M16 4L6 14L7.41 15.41L15 7.83V28H17V7.83L24.59 15.41L26 14L16 4Z" />
    </Svg>
  );
};

export default UiUpArrowIcon;
