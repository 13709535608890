"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.buildTrackGraph = void 0;

var TrackGraphNode_1 = require("./TrackGraphNode");

var TrackGraph_1 = require("./TrackGraph");

var VirtualTrolleyPositionSensorsState_1 = require("../../trolley/virtual/VirtualTrolleyPositionSensorsState");

var PhysicalSize_1 = require("../../trolley/physical/PhysicalSize");

function buildTrackGraph(physicalFarm, trackId, trackNodes) {
  var grid = gridifyTrackNodes(trackNodes);
  var trackGraphNodes = buildAllGraphNodes(physicalFarm, grid);
  adjustNodePositions(trackGraphNodes);
  connectAllGraphNodes(trackGraphNodes);
  var trackGraph = new TrackGraph_1.TrackGraph();
  trackGraph.id = TrackGraph_1.TrackGraphId.create(trackId.asString());

  for (var i = 0; i < trackGraphNodes.length; i++) {
    for (var j = 0; j < trackGraphNodes[i].length; j++) {
      var trackNodeId = trackGraphNodes[i][j][0].trackNodeId.asString();

      if (!trackGraph.nodeMap[trackNodeId]) {
        trackGraph.nodeMap[trackNodeId] = trackGraphNodes[i][j];
      }

      for (var k = 0; k < trackGraphNodes[i][j].length; k++) {
        trackGraph.nodes.push(trackGraphNodes[i][j][k]);
        trackGraph.nodesById[trackGraphNodes[i][j][k].id.asString()] = trackGraphNodes[i][j][k];
      }
    }
  }

  return trackGraph;
}

exports.buildTrackGraph = buildTrackGraph;

function adjustNodePositions(trackGraphNodes) {
  for (var i = 0; i < trackGraphNodes.length; i++) {
    for (var j = 0; j < trackGraphNodes[i].length; j++) {
      var full = trackGraphNodes[i][j][TrackGraph_1.FULL_INDEX];
      {
        var frontBack1 = trackGraphNodes[i][j][TrackGraph_1.FRONT_SIDE_BACK_1_INDEX];
        frontBack1.center = {
          x: frontBack1.center.x,
          y: full.center.y + (PhysicalSize_1.TROLLEY_LENGTH_MM / 2 - PhysicalSize_1.BACK_SCANNER_OFFSET_MM - 2 * (PhysicalSize_1.TROLLEY_GAP_MM + PhysicalSize_1.BACK_SCANNER_OFFSET_MM)) + (PhysicalSize_1.TROLLEY_LENGTH_MM / 2 - PhysicalSize_1.BACK_SCANNER_OFFSET_MM + 2 * (PhysicalSize_1.TROLLEY_GAP_MM + PhysicalSize_1.BACK_SCANNER_OFFSET_MM))
        };
      }
      {
        var frontFront3 = trackGraphNodes[i][j][TrackGraph_1.FRONT_SIDE_FRONT_3_INDEX];
        frontFront3.center = {
          x: frontFront3.center.x,
          y: full.center.y + (PhysicalSize_1.TROLLEY_LENGTH_MM / 2 + 2 * PhysicalSize_1.TROLLEY_GAP_MM + PhysicalSize_1.FRONT_SCANNER_OFFSET_MM)
        };
      }
      {
        var frontBack3 = trackGraphNodes[i][j][TrackGraph_1.FRONT_SIDE_BACK_3_INDEX];
        frontBack3.center = {
          x: frontBack3.center.x,
          y: full.center.y + (PhysicalSize_1.TROLLEY_LENGTH_MM / 2 - PhysicalSize_1.BACK_SCANNER_OFFSET_MM)
        };
      }
      {
        var frontFront1 = trackGraphNodes[i][j][TrackGraph_1.FRONT_SIDE_FRONT_1_INDEX];
        frontFront1.center = {
          x: frontFront1.center.x,
          y: full.center.y + 2 * (PhysicalSize_1.TROLLEY_GAP_MM + PhysicalSize_1.BACK_SCANNER_OFFSET_MM)
        };
      }
      {
        var backFront1 = trackGraphNodes[i][j][TrackGraph_1.BACK_SIDE_FRONT_1_INDEX];
        backFront1.center = {
          x: backFront1.center.x,
          y: full.center.y - (PhysicalSize_1.TROLLEY_LENGTH_MM / 2 - PhysicalSize_1.BACK_SCANNER_OFFSET_MM - 2 * (PhysicalSize_1.TROLLEY_GAP_MM + PhysicalSize_1.BACK_SCANNER_OFFSET_MM)) - (PhysicalSize_1.TROLLEY_LENGTH_MM / 2 - PhysicalSize_1.BACK_SCANNER_OFFSET_MM + 2 * (PhysicalSize_1.TROLLEY_GAP_MM + PhysicalSize_1.BACK_SCANNER_OFFSET_MM))
        };
      }
      {
        var backBack3 = trackGraphNodes[i][j][TrackGraph_1.BACK_SIDE_BACK_3_INDEX];
        backBack3.center = {
          x: backBack3.center.x,
          y: full.center.y - (PhysicalSize_1.TROLLEY_LENGTH_MM / 2 - PhysicalSize_1.BACK_SCANNER_OFFSET_MM) - 2 * (PhysicalSize_1.TROLLEY_GAP_MM + PhysicalSize_1.BACK_SCANNER_OFFSET_MM)
        };
      }
      {
        var backFront3 = trackGraphNodes[i][j][TrackGraph_1.BACK_SIDE_FRONT_3_INDEX];
        backFront3.center = {
          x: backFront3.center.x,
          y: full.center.y - (PhysicalSize_1.TROLLEY_LENGTH_MM / 2 - PhysicalSize_1.BACK_SCANNER_OFFSET_MM)
        };
      }
      {
        var backBack1 = trackGraphNodes[i][j][TrackGraph_1.BACK_SIDE_BACK_1_INDEX];
        backBack1.center = {
          x: backBack1.center.x,
          y: full.center.y - 2 * (PhysicalSize_1.TROLLEY_GAP_MM + PhysicalSize_1.FRONT_SCANNER_OFFSET_MM)
        };
      }
      {
        var leftRight1 = trackGraphNodes[i][j][TrackGraph_1.LEFT_SIDE_RIGHT_1_INDEX];
        leftRight1.center = {
          y: leftRight1.center.y,
          x: full.center.x + (PhysicalSize_1.TROLLEY_WIDTH_MM / 2 - PhysicalSize_1.RIGHT_SCANNER_OFFSET_MM - 2 * (PhysicalSize_1.TROLLEY_GAP_MM + PhysicalSize_1.RIGHT_SCANNER_OFFSET_MM)) + (PhysicalSize_1.TROLLEY_WIDTH_MM / 2 - PhysicalSize_1.RIGHT_SCANNER_OFFSET_MM + 2 * (PhysicalSize_1.TROLLEY_GAP_MM + PhysicalSize_1.RIGHT_SCANNER_OFFSET_MM))
        };
      }
      {
        var leftLeft3 = trackGraphNodes[i][j][TrackGraph_1.LEFT_SIDE_LEFT_3_INDEX];
        leftLeft3.center = {
          y: leftLeft3.center.y,
          x: full.center.x + (PhysicalSize_1.TROLLEY_WIDTH_MM / 2 + 2 * PhysicalSize_1.TROLLEY_GAP_MM + PhysicalSize_1.RIGHT_SCANNER_OFFSET_MM)
        };
      }
      {
        var leftRight3 = trackGraphNodes[i][j][TrackGraph_1.LEFT_SIDE_RIGHT_3_INDEX];
        leftRight3.center = {
          y: leftRight3.center.y,
          x: full.center.x + (PhysicalSize_1.TROLLEY_WIDTH_MM / 2 - PhysicalSize_1.RIGHT_SCANNER_OFFSET_MM)
        };
      }
      {
        var leftLeft1 = trackGraphNodes[i][j][TrackGraph_1.LEFT_SIDE_LEFT_1_INDEX];
        leftLeft1.center = {
          y: leftLeft1.center.y,
          x: full.center.x + 2 * (PhysicalSize_1.TROLLEY_GAP_MM + PhysicalSize_1.RIGHT_SCANNER_OFFSET_MM)
        };
      }
      {
        var rightLeft1 = trackGraphNodes[i][j][TrackGraph_1.RIGHT_SIDE_LEFT_1_INDEX];
        rightLeft1.center = {
          y: rightLeft1.center.y,
          x: full.center.x - (PhysicalSize_1.TROLLEY_WIDTH_MM / 2 - PhysicalSize_1.LEFT_SCANNER_OFFSET_MM - 2 * (PhysicalSize_1.TROLLEY_GAP_MM + PhysicalSize_1.LEFT_SCANNER_OFFSET_MM)) - (PhysicalSize_1.TROLLEY_WIDTH_MM / 2 - PhysicalSize_1.LEFT_SCANNER_OFFSET_MM + 2 * (PhysicalSize_1.TROLLEY_GAP_MM + PhysicalSize_1.LEFT_SCANNER_OFFSET_MM))
        };
      }
      {
        var rightRight3 = trackGraphNodes[i][j][TrackGraph_1.RIGHT_SIDE_RIGHT_3_INDEX];
        rightRight3.center = {
          y: rightRight3.center.y,
          x: full.center.x - (PhysicalSize_1.TROLLEY_WIDTH_MM / 2 - PhysicalSize_1.LEFT_SCANNER_OFFSET_MM) - 2 * (PhysicalSize_1.TROLLEY_GAP_MM + PhysicalSize_1.LEFT_SCANNER_OFFSET_MM)
        };
      }
      {
        var rightLeft3 = trackGraphNodes[i][j][TrackGraph_1.RIGHT_SIDE_LEFT_3_INDEX];
        rightLeft3.center = {
          y: rightLeft3.center.y,
          x: full.center.x - (PhysicalSize_1.TROLLEY_WIDTH_MM / 2 - PhysicalSize_1.LEFT_SCANNER_OFFSET_MM)
        };
      }
      {
        var rightRight1 = trackGraphNodes[i][j][TrackGraph_1.RIGHT_SIDE_RIGHT_1_INDEX];
        trackGraphNodes[i][j][TrackGraph_1.BACK_SIDE_BACK_1_INDEX];
        rightRight1.center = {
          x: full.center.x - 2 * (PhysicalSize_1.TROLLEY_GAP_MM + PhysicalSize_1.LEFT_SCANNER_OFFSET_MM),
          y: rightRight1.center.y
        };
      }
    }
  }
}

function connectAllGraphNodes(trackGraphNodes) {
  for (var i = 0; i < trackGraphNodes.length; i++) {
    for (var j = 0; j < trackGraphNodes[i].length; j++) {
      var full = trackGraphNodes[i][j][TrackGraph_1.FULL_INDEX];
      var frontBack1 = trackGraphNodes[i][j][TrackGraph_1.FRONT_SIDE_BACK_1_INDEX];
      var frontFront3 = trackGraphNodes[i][j][TrackGraph_1.FRONT_SIDE_FRONT_3_INDEX];
      var frontBack3 = trackGraphNodes[i][j][TrackGraph_1.FRONT_SIDE_BACK_3_INDEX];
      var frontFront1 = trackGraphNodes[i][j][TrackGraph_1.FRONT_SIDE_FRONT_1_INDEX];
      var backFront1 = trackGraphNodes[i][j][TrackGraph_1.BACK_SIDE_FRONT_1_INDEX];
      var backBack3 = trackGraphNodes[i][j][TrackGraph_1.BACK_SIDE_BACK_3_INDEX];
      var backFront3 = trackGraphNodes[i][j][TrackGraph_1.BACK_SIDE_FRONT_3_INDEX];
      var backBack1 = trackGraphNodes[i][j][TrackGraph_1.BACK_SIDE_BACK_1_INDEX];
      var leftRight1 = trackGraphNodes[i][j][TrackGraph_1.LEFT_SIDE_RIGHT_1_INDEX];
      var leftLeft3 = trackGraphNodes[i][j][TrackGraph_1.LEFT_SIDE_LEFT_3_INDEX];
      var leftRight3 = trackGraphNodes[i][j][TrackGraph_1.LEFT_SIDE_RIGHT_3_INDEX];
      var leftLeft1 = trackGraphNodes[i][j][TrackGraph_1.LEFT_SIDE_LEFT_1_INDEX];
      var rightLeft1 = trackGraphNodes[i][j][TrackGraph_1.RIGHT_SIDE_LEFT_1_INDEX];
      var rightRight3 = trackGraphNodes[i][j][TrackGraph_1.RIGHT_SIDE_RIGHT_3_INDEX];
      var rightLeft3 = trackGraphNodes[i][j][TrackGraph_1.RIGHT_SIDE_LEFT_3_INDEX];
      var rightRight1 = trackGraphNodes[i][j][TrackGraph_1.RIGHT_SIDE_RIGHT_1_INDEX];
      connectBack(frontBack1, frontFront3);
      connectBack(frontFront3, frontBack3);
      connectBack(frontBack3, frontFront1);
      connectBack(frontFront1, full);
      connectForward(backFront1, backBack3);
      connectForward(backBack3, backFront3);
      connectForward(backFront3, backBack1);
      connectForward(backBack1, full);
      connectRight(leftRight1, leftLeft3);
      connectRight(leftLeft3, leftRight3);
      connectRight(leftRight3, leftLeft1);
      connectRight(leftLeft1, full);
      connectLeft(rightLeft1, rightRight3);
      connectLeft(rightRight3, rightLeft3);
      connectLeft(rightLeft3, rightRight1);
      connectLeft(rightRight1, full);
    }
  }

  for (var _i = 0; _i < trackGraphNodes.length; _i++) {
    for (var _j = 0; _j < trackGraphNodes[_i].length; _j++) {
      var _full = trackGraphNodes[_i][_j][TrackGraph_1.FULL_INDEX];

      if (_j === 0) {
        if (_i === trackGraphNodes.length - 2) {
          var behind = trackGraphNodes[_i][_j + 1][TrackGraph_1.FRONT_SIDE_BACK_1_INDEX];

          _full.backNeightbors.push(behind.id);
        } else if (_i === trackGraphNodes.length - 1) {} else {
          var toRight = trackGraphNodes[_i + 1][_j][TrackGraph_1.LEFT_SIDE_RIGHT_1_INDEX];

          _full.rightNeightbors.push(toRight.id);
        }
      } else if (_j === trackGraphNodes[_i].length - 2) {
        if (_i === trackGraphNodes.length - 2) {
          var _behind = trackGraphNodes[_i][_j + 1][TrackGraph_1.FRONT_SIDE_BACK_1_INDEX];

          _full.backNeightbors.push(_behind.id);

          var _toRight = trackGraphNodes[_i + 1][_j][TrackGraph_1.LEFT_SIDE_RIGHT_1_INDEX];

          _full.rightNeightbors.push(_toRight.id);

          var toLeft = trackGraphNodes[_i - 1][_j][TrackGraph_1.RIGHT_SIDE_LEFT_1_INDEX];

          _full.leftNeightbors.push(toLeft.id);
        } else if (_i === trackGraphNodes.length - 1) {
          var _toLeft = trackGraphNodes[_i - 1][_j][TrackGraph_1.RIGHT_SIDE_LEFT_1_INDEX];

          _full.leftNeightbors.push(_toLeft.id);
        } else if (_i === 0) {
          var infront = trackGraphNodes[_i][_j - 1][TrackGraph_1.BACK_SIDE_FRONT_1_INDEX];

          _full.frontNeightbors.push(infront.id);
        } else {
          var _toLeft2 = trackGraphNodes[_i - 1][_j][TrackGraph_1.RIGHT_SIDE_LEFT_1_INDEX];

          _full.leftNeightbors.push(_toLeft2.id);

          var _infront = trackGraphNodes[_i][_j - 1][TrackGraph_1.BACK_SIDE_FRONT_1_INDEX];

          _full.frontNeightbors.push(_infront.id);
        }
      } else if (_j === trackGraphNodes[_i].length - 1) {
        if (_i === trackGraphNodes.length - 2) {
          var _infront2 = trackGraphNodes[_i][_j - 1][TrackGraph_1.BACK_SIDE_FRONT_1_INDEX];

          _full.frontNeightbors.push(_infront2.id);
        }
      } else {
        if (_i === trackGraphNodes.length - 2) {
          var _behind2 = trackGraphNodes[_i][_j + 1][TrackGraph_1.FRONT_SIDE_BACK_1_INDEX];

          _full.backNeightbors.push(_behind2.id);
        } else if (_i === trackGraphNodes.length - 1) {} else {
          var _infront3 = trackGraphNodes[_i][_j - 1][TrackGraph_1.BACK_SIDE_FRONT_1_INDEX];

          _full.frontNeightbors.push(_infront3.id);
        }
      }
    }
  }
}

function connectForward(first, second) {
  first.frontNeightbors.push(second.id);
}

function connectBack(first, second) {
  first.backNeightbors.push(second.id);
}

function connectLeft(first, second) {
  first.leftNeightbors.push(second.id);
}

function connectRight(first, second) {
  first.rightNeightbors.push(second.id);
}

function gridifyTrackNodes(trackNodes) {
  var grid = [];
  trackNodes.forEach(function (trackNode) {
    if (!grid[trackNode.x]) {
      grid[trackNode.x] = [];
    }

    grid[trackNode.x][trackNode.y] = trackNode;
  });
  return grid;
}

function buildAllGraphNodes(physicalFarm, grid) {
  var trackGraphNodes = [];

  for (var i = 0; i < grid.length; i++) {
    trackGraphNodes[i] = [];

    for (var j = 0; j < grid[i].length; j++) {
      var trackNode = grid[i][j];
      var ii = 3 * i + 1;
      var jj = 3 * j + 1;
      var horizontal = physicalFarm.horizontals[jj];
      var vertical = physicalFarm.verticals[ii];
      buildGraphNodesForTrackNode(trackNode, i, j, trackGraphNodes, {
        x: vertical,
        y: horizontal
      });
    }
  }

  return trackGraphNodes;
}

function buildGraphNodesForTrackNode(trackNode, i, j, trackGraphNodes, center) {
  trackGraphNodes[i][j] = [];
  {
    var frontBack1 = new TrackGraphNode_1.TrackGraphNode();
    frontBack1.id = TrackGraphNode_1.TrackGraphNodeId.create(i + '_' + j + '_' + trackNode.id.asString() + '_' + 'front_side_back_1');
    frontBack1.trackNodeId = trackNode.id;
    frontBack1.positionSensorPattern = VirtualTrolleyPositionSensorsState_1.VirtualTrolleyPositionSensorsPattern.BACK_1;
    frontBack1.center = {
      x: center.x,
      y: center.y
    };
    trackGraphNodes[i][j][TrackGraph_1.FRONT_SIDE_BACK_1_INDEX] = frontBack1;
  }
  {
    var frontBack3 = new TrackGraphNode_1.TrackGraphNode();
    frontBack3.id = TrackGraphNode_1.TrackGraphNodeId.create(i + '_' + j + '_' + trackNode.id.asString() + '_' + 'front_side_back_3');
    frontBack3.trackNodeId = trackNode.id;
    frontBack3.positionSensorPattern = VirtualTrolleyPositionSensorsState_1.VirtualTrolleyPositionSensorsPattern.BACK_3;
    frontBack3.center = {
      x: center.x,
      y: center.y
    };
    trackGraphNodes[i][j][TrackGraph_1.FRONT_SIDE_BACK_3_INDEX] = frontBack3;
  }
  {
    var frontFront1 = new TrackGraphNode_1.TrackGraphNode();
    frontFront1.id = TrackGraphNode_1.TrackGraphNodeId.create(i + '_' + j + '_' + trackNode.id.asString() + '_' + 'front_side_front_1');
    frontFront1.trackNodeId = trackNode.id;
    frontFront1.positionSensorPattern = VirtualTrolleyPositionSensorsState_1.VirtualTrolleyPositionSensorsPattern.FRONT_1;
    frontFront1.center = {
      x: center.x,
      y: center.y
    };
    trackGraphNodes[i][j][TrackGraph_1.FRONT_SIDE_FRONT_1_INDEX] = frontFront1;
  }
  {
    var frontFront3 = new TrackGraphNode_1.TrackGraphNode();
    frontFront3.id = TrackGraphNode_1.TrackGraphNodeId.create(i + '_' + j + '_' + trackNode.id.asString() + '_' + 'front_side_front_3');
    frontFront3.trackNodeId = trackNode.id;
    frontFront3.positionSensorPattern = VirtualTrolleyPositionSensorsState_1.VirtualTrolleyPositionSensorsPattern.FRONT_3;
    frontFront3.center = {
      x: center.x,
      y: center.y
    };
    trackGraphNodes[i][j][TrackGraph_1.FRONT_SIDE_FRONT_3_INDEX] = frontFront3;
  }
  {
    var backBack1 = new TrackGraphNode_1.TrackGraphNode();
    backBack1.id = TrackGraphNode_1.TrackGraphNodeId.create(i + '_' + j + '_' + trackNode.id.asString() + '_' + 'back_side_back_1');
    backBack1.trackNodeId = trackNode.id;
    backBack1.positionSensorPattern = VirtualTrolleyPositionSensorsState_1.VirtualTrolleyPositionSensorsPattern.BACK_1;
    backBack1.center = {
      x: center.x,
      y: center.y
    };
    trackGraphNodes[i][j][TrackGraph_1.BACK_SIDE_BACK_1_INDEX] = backBack1;
  }
  {
    var backBack3 = new TrackGraphNode_1.TrackGraphNode();
    backBack3.id = TrackGraphNode_1.TrackGraphNodeId.create(i + '_' + j + '_' + trackNode.id.asString() + '_' + 'back_side_back_3');
    backBack3.trackNodeId = trackNode.id;
    backBack3.positionSensorPattern = VirtualTrolleyPositionSensorsState_1.VirtualTrolleyPositionSensorsPattern.BACK_3;
    backBack3.center = {
      x: center.x,
      y: center.y
    };
    trackGraphNodes[i][j][TrackGraph_1.BACK_SIDE_BACK_3_INDEX] = backBack3;
  }
  {
    var backFront1 = new TrackGraphNode_1.TrackGraphNode();
    backFront1.id = TrackGraphNode_1.TrackGraphNodeId.create(i + '_' + j + '_' + trackNode.id.asString() + '_' + 'back_side_front_1');
    backFront1.trackNodeId = trackNode.id;
    backFront1.positionSensorPattern = VirtualTrolleyPositionSensorsState_1.VirtualTrolleyPositionSensorsPattern.FRONT_1;
    backFront1.center = {
      x: center.x,
      y: center.y
    };
    trackGraphNodes[i][j][TrackGraph_1.BACK_SIDE_FRONT_1_INDEX] = backFront1;
  }
  {
    var backFront3 = new TrackGraphNode_1.TrackGraphNode();
    backFront3.id = TrackGraphNode_1.TrackGraphNodeId.create(i + '_' + j + '_' + trackNode.id.asString() + '_' + 'back_side_front_3');
    backFront3.trackNodeId = trackNode.id;
    backFront3.positionSensorPattern = VirtualTrolleyPositionSensorsState_1.VirtualTrolleyPositionSensorsPattern.FRONT_3;
    backFront3.center = {
      x: center.x,
      y: center.y
    };
    trackGraphNodes[i][j][TrackGraph_1.BACK_SIDE_FRONT_3_INDEX] = backFront3;
  }
  {
    var leftRight1 = new TrackGraphNode_1.TrackGraphNode();
    leftRight1.id = TrackGraphNode_1.TrackGraphNodeId.create(i + '_' + j + '_' + trackNode.id.asString() + '_' + 'left_side_right_1');
    leftRight1.trackNodeId = trackNode.id;
    leftRight1.positionSensorPattern = VirtualTrolleyPositionSensorsState_1.VirtualTrolleyPositionSensorsPattern.RIGHT_1;
    leftRight1.center = {
      x: center.x,
      y: center.y
    };
    trackGraphNodes[i][j][TrackGraph_1.LEFT_SIDE_RIGHT_1_INDEX] = leftRight1;
  }
  {
    var leftRight3 = new TrackGraphNode_1.TrackGraphNode();
    leftRight3.id = TrackGraphNode_1.TrackGraphNodeId.create(i + '_' + j + '_' + trackNode.id.asString() + '_' + 'left_side_right_3');
    leftRight3.trackNodeId = trackNode.id;
    leftRight3.positionSensorPattern = VirtualTrolleyPositionSensorsState_1.VirtualTrolleyPositionSensorsPattern.RIGHT_3;
    leftRight3.center = {
      x: center.x,
      y: center.y
    };
    trackGraphNodes[i][j][TrackGraph_1.LEFT_SIDE_RIGHT_3_INDEX] = leftRight3;
  }
  {
    var leftLeft1 = new TrackGraphNode_1.TrackGraphNode();
    leftLeft1.id = TrackGraphNode_1.TrackGraphNodeId.create(i + '_' + j + '_' + trackNode.id.asString() + '_' + 'left_side_left_1');
    leftLeft1.trackNodeId = trackNode.id;
    leftLeft1.positionSensorPattern = VirtualTrolleyPositionSensorsState_1.VirtualTrolleyPositionSensorsPattern.LEFT_1;
    leftLeft1.center = {
      x: center.x,
      y: center.y
    };
    trackGraphNodes[i][j][TrackGraph_1.LEFT_SIDE_LEFT_1_INDEX] = leftLeft1;
  }
  {
    var leftLeft3 = new TrackGraphNode_1.TrackGraphNode();
    leftLeft3.id = TrackGraphNode_1.TrackGraphNodeId.create(i + '_' + j + '_' + trackNode.id.asString() + '_' + 'left_side_left_3');
    leftLeft3.trackNodeId = trackNode.id;
    leftLeft3.positionSensorPattern = VirtualTrolleyPositionSensorsState_1.VirtualTrolleyPositionSensorsPattern.LEFT_3;
    leftLeft3.center = {
      x: center.x,
      y: center.y
    };
    trackGraphNodes[i][j][TrackGraph_1.LEFT_SIDE_LEFT_3_INDEX] = leftLeft3;
  }
  {
    var rightRight1 = new TrackGraphNode_1.TrackGraphNode();
    rightRight1.id = TrackGraphNode_1.TrackGraphNodeId.create(i + '_' + j + '_' + trackNode.id.asString() + '_' + 'right_side_right_1');
    rightRight1.trackNodeId = trackNode.id;
    rightRight1.positionSensorPattern = VirtualTrolleyPositionSensorsState_1.VirtualTrolleyPositionSensorsPattern.RIGHT_1;
    rightRight1.center = {
      x: center.x,
      y: center.y
    };
    trackGraphNodes[i][j][TrackGraph_1.RIGHT_SIDE_RIGHT_1_INDEX] = rightRight1;
  }
  {
    var rightRight3 = new TrackGraphNode_1.TrackGraphNode();
    rightRight3.id = TrackGraphNode_1.TrackGraphNodeId.create(i + '_' + j + '_' + trackNode.id.asString() + '_' + 'right_side_right_3');
    rightRight3.trackNodeId = trackNode.id;
    rightRight3.positionSensorPattern = VirtualTrolleyPositionSensorsState_1.VirtualTrolleyPositionSensorsPattern.RIGHT_3;
    rightRight3.center = {
      x: center.x,
      y: center.y
    };
    trackGraphNodes[i][j][TrackGraph_1.RIGHT_SIDE_RIGHT_3_INDEX] = rightRight3;
  }
  {
    var rightLeft1 = new TrackGraphNode_1.TrackGraphNode();
    rightLeft1.id = TrackGraphNode_1.TrackGraphNodeId.create(i + '_' + j + '_' + trackNode.id.asString() + '_' + 'right_side_left_1');
    rightLeft1.trackNodeId = trackNode.id;
    rightLeft1.positionSensorPattern = VirtualTrolleyPositionSensorsState_1.VirtualTrolleyPositionSensorsPattern.LEFT_1;
    rightLeft1.center = {
      x: center.x,
      y: center.y
    };
    trackGraphNodes[i][j][TrackGraph_1.RIGHT_SIDE_LEFT_1_INDEX] = rightLeft1;
  }
  {
    var rightLeft3 = new TrackGraphNode_1.TrackGraphNode();
    rightLeft3.id = TrackGraphNode_1.TrackGraphNodeId.create(i + '_' + j + '_' + trackNode.id.asString() + '_' + 'right_side_left_3');
    rightLeft3.trackNodeId = trackNode.id;
    rightLeft3.positionSensorPattern = VirtualTrolleyPositionSensorsState_1.VirtualTrolleyPositionSensorsPattern.LEFT_3;
    rightLeft3.center = {
      x: center.x,
      y: center.y
    };
    trackGraphNodes[i][j][TrackGraph_1.RIGHT_SIDE_LEFT_3_INDEX] = rightLeft3;
  }
  {
    var full = new TrackGraphNode_1.TrackGraphNode();
    full.id = TrackGraphNode_1.TrackGraphNodeId.create(i + '_' + j + '_' + trackNode.id.asString() + '_' + 'full');
    full.trackNodeId = trackNode.id;
    full.positionSensorPattern = VirtualTrolleyPositionSensorsState_1.VirtualTrolleyPositionSensorsPattern.FULL;
    full.center = {
      x: center.x,
      y: center.y
    };
    trackGraphNodes[i][j][TrackGraph_1.FULL_INDEX] = full;
  }
}