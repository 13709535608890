import 'reflect-metadata';

import ReturnFarmData from 'model/src/dataflowprotocol/servertopage/ReturnFarmData';
import { PermissionLevel, UserType } from 'model/src/user/UserProfile';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import InjectionContext, { InjectionContextType } from '../../injection/InjectionContext';
import {
  UserProfileContext,
  UserProfileContextType
} from '../../user/UserProfileContext';
import { DataMapContextProvider } from './context/datamapcontext/DataMapContextProvider';
import FarmViewer from './FarmViewer';

type Props = RouteComponentProps<{ farmId: string }>;

const FarmHmi = ({
  history,
  match: {
    params: { farmId },
  },
}: Props) => {
  const userProfileContext: UserProfileContextType = useContext(
    UserProfileContext
  );
  const injectionContext: InjectionContextType = useContext(InjectionContext);
  const [farmData, setFarmData] = useState<any>(null);

  const [permittedToViewFarm, setPermittedToViewFarm] = useState<boolean>(
    false
  );

  const pageKernel = injectionContext.pageKernel;

  useEffect(() => {
    setPermittedToViewFarm(
      !!userProfileContext &&
        !!userProfileContext.userProfile &&
        !!userProfileContext.userProfile.userType &&
        !!(userProfileContext.userProfile.userType !== UserType.INTERLOPER) &&
        !!(
          userProfileContext.userProfile.userType === UserType.ADMIN ||
          userProfileContext.userProfile.userType === UserType.MEMBER
        ) &&
        !!userProfileContext.userProfile.permissionLevel &&
        !!(
          userProfileContext.userProfile.permissionLevel !==
          PermissionLevel.NONE
        ) &&
        !!(
          userProfileContext.userProfile.permissionLevel ===
            PermissionLevel.READ ||
          !!(
            userProfileContext.userProfile.permissionLevel ===
            PermissionLevel.WRITE
          )
        )
    );
  }, [userProfileContext, injectionContext]);

  useEffect(() => {
    pageKernel.addOnFreshFarmDataCallback((data: ReturnFarmData) =>
      setFarmData(data)
    );
    pageKernel.initialize(farmId);
    return () => {
      pageKernel.deregisterForUpdates();
    };
  }, []);

  if (farmData && permittedToViewFarm) {
    //TODO(austin): get permission before farmData & don't send if not authorized
    return (
      <DataMapContextProvider>
        <FarmViewer
          data={farmData}
          connectionToServer={userProfileContext.connectionToServer}
          pageKernel={pageKernel}
        />
      </DataMapContextProvider>
    );
  } else {
    return <div />;
  }
};

export default FarmHmi;
