/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';

import { useGLTF } from '@react-three/drei';

import materialFactory from '../trolley/MaterialFactory';
import RightHandWheelModel from './RightHandWheel.glb';

export default function RightHandWheel({ ...props }) {
  const group = useRef();
  const { nodes } = useGLTF(RightHandWheelModel) as GLTF;

  const material: any = materialFactory.generateMaterial(
    props.parentUnderInspection,
    props.parentHovered,
    undefined,
    undefined,
    props.online
  );

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Right_Hand_Wheel_v1">
        <group
          name="am-3340R_-_8in_MK_Mecanum_Right_Wheel_-_Rev11_v2"
          position={[-9.68, -0.01, 0]}
          rotation={[0.26, -1.57, 0]}>
          <group name="right_ring" position={[0, 0, -8.17]}>
            <mesh name="mesh_0" geometry={nodes.mesh_0.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="right_ring_1"
            position={[0, 0, -0.72]}
            rotation={[-Math.PI, 0, 2.09]}>
            <mesh name="mesh_1" geometry={nodes.mesh_1.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="812_roller_(1)"
            position={[1.74, 3.26, -9.69]}
            rotation={[-0.22, -0.77, -0.15]}>
            <group name="812_roller_core_(1)" position={[2.46, 4.5, 5.69]}>
              <mesh name="mesh_2" geometry={nodes.mesh_2.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="812_roller_skin_(1)"
              position={[2.46, 4.5, 5.69]}
              rotation={[-0.09, 0, 0]}>
              <mesh name="mesh_3" geometry={nodes.mesh_3.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="Axle_tube_(1)"
              position={[-1.33, 4.5, 5.69]}
              rotation={[-2.98, Math.PI / 2, 0]}>
              <mesh name="mesh_4" geometry={nodes.mesh_4.geometry}>
                {material}
              </mesh>
            </group>
          </group>
          <group
            name="Hub_Half_(1)"
            position={[0, 0, -8.01]}
            rotation={[1.57, -1.57, 1.57]}>
            <mesh name="mesh_5" geometry={nodes.mesh_5.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="Hub_Half_(1)_1"
            position={[0, 0, -0.88]}
            rotation={[-Math.PI / 2, 1.04, Math.PI / 2]}>
            <mesh name="mesh_6" geometry={nodes.mesh_6.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="812_roller_(1)_1"
            position={[3.13, 1.95, -9.69]}
            rotation={[-0.6, -0.56, -0.76]}>
            <group name="812_roller_core_(1)_1" position={[2.46, 4.5, 5.69]}>
              <mesh name="mesh_7" geometry={nodes.mesh_7.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="812_roller_skin_(1)_1"
              position={[2.46, 4.5, 5.69]}
              rotation={[-0.09, 0, 0]}>
              <mesh name="mesh_8" geometry={nodes.mesh_8.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="Axle_tube_(1)_1"
              position={[-1.33, 4.5, 5.69]}
              rotation={[-2.98, Math.PI / 2, 0]}>
              <mesh name="mesh_9" geometry={nodes.mesh_9.geometry}>
                {material}
              </mesh>
            </group>
          </group>
          <group
            name="812_roller_(1)_2"
            position={[3.69, 0.12, -9.69]}
            rotation={[-0.77, -0.22, -1.2]}>
            <group name="812_roller_core_(1)_2" position={[2.46, 4.5, 5.69]}>
              <mesh name="mesh_10" geometry={nodes.mesh_10.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="812_roller_skin_(1)_2"
              position={[2.46, 4.5, 5.69]}
              rotation={[-0.09, 0, 0]}>
              <mesh name="mesh_11" geometry={nodes.mesh_11.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="Axle_tube_(1)_2"
              position={[-1.33, 4.5, 5.69]}
              rotation={[-2.98, Math.PI / 2, 0]}>
              <mesh name="mesh_12" geometry={nodes.mesh_12.geometry}>
                {material}
              </mesh>
            </group>
          </group>
          <group
            name="812_roller_(1)_3"
            position={[3.26, -1.74, -9.69]}
            rotation={[-Math.PI / 4, 0.15, -Math.PI / 2]}>
            <group name="812_roller_core_(1)_3" position={[2.46, 4.5, 5.69]}>
              <mesh name="mesh_13" geometry={nodes.mesh_13.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="812_roller_skin_(1)_3"
              position={[2.46, 4.5, 5.69]}
              rotation={[-0.09, 0, 0]}>
              <mesh name="mesh_14" geometry={nodes.mesh_14.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="Axle_tube_(1)_3"
              position={[-1.33, 4.5, 5.69]}
              rotation={[-2.98, Math.PI / 2, 0]}>
              <mesh name="mesh_15" geometry={nodes.mesh_15.geometry}>
                {material}
              </mesh>
            </group>
          </group>
          <group
            name="812_roller_(1)_4"
            position={[1.95, -3.13, -9.69]}
            rotation={[-0.65, 0.5, -1.99]}>
            <group name="812_roller_core_(1)_4" position={[2.46, 4.5, 5.69]}>
              <mesh name="mesh_16" geometry={nodes.mesh_16.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="812_roller_skin_(1)_4"
              position={[2.46, 4.5, 5.69]}
              rotation={[-0.09, 0, 0]}>
              <mesh name="mesh_17" geometry={nodes.mesh_17.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="Axle_tube_(1)_4"
              position={[-1.33, 4.5, 5.69]}
              rotation={[-2.98, Math.PI / 2, 0]}>
              <mesh name="mesh_18" geometry={nodes.mesh_18.geometry}>
                {material}
              </mesh>
            </group>
          </group>
          <group
            name="812_roller_(1)_5"
            position={[0.12, -3.69, -9.69]}
            rotation={[-0.3, 0.75, -2.56]}>
            <group name="812_roller_core_(1)_5" position={[2.46, 4.5, 5.69]}>
              <mesh name="mesh_19" geometry={nodes.mesh_19.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="812_roller_skin_(1)_5"
              position={[2.46, 4.5, 5.69]}
              rotation={[-0.09, 0, 0]}>
              <mesh name="mesh_20" geometry={nodes.mesh_20.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="Axle_tube_(1)_5"
              position={[-1.33, 4.5, 5.69]}
              rotation={[-2.98, Math.PI / 2, 0]}>
              <mesh name="mesh_21" geometry={nodes.mesh_21.geometry}>
                {material}
              </mesh>
            </group>
          </group>
          <group
            name="812_roller_(1)_6"
            position={[-1.74, -3.26, -9.69]}
            rotation={[0.22, 0.77, 2.99]}>
            <group name="812_roller_core_(1)_6" position={[2.46, 4.5, 5.69]}>
              <mesh name="mesh_22" geometry={nodes.mesh_22.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="812_roller_skin_(1)_6"
              position={[2.46, 4.5, 5.69]}
              rotation={[-0.09, 0, 0]}>
              <mesh name="mesh_23" geometry={nodes.mesh_23.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="Axle_tube_(1)_6"
              position={[-1.33, 4.5, 5.69]}
              rotation={[-2.98, Math.PI / 2, 0]}>
              <mesh name="mesh_24" geometry={nodes.mesh_24.geometry}>
                {material}
              </mesh>
            </group>
          </group>
          <group
            name="812_roller_(1)_7"
            position={[-3.13, -1.95, -9.69]}
            rotation={[0.6, 0.56, 2.38]}>
            <group name="812_roller_core_(1)_7" position={[2.46, 4.5, 5.69]}>
              <mesh name="mesh_25" geometry={nodes.mesh_25.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="812_roller_skin_(1)_7"
              position={[2.46, 4.5, 5.69]}
              rotation={[-0.09, 0, 0]}>
              <mesh name="mesh_26" geometry={nodes.mesh_26.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="Axle_tube_(1)_7"
              position={[-1.33, 4.5, 5.69]}
              rotation={[-2.98, Math.PI / 2, 0]}>
              <mesh name="mesh_27" geometry={nodes.mesh_27.geometry}>
                {material}
              </mesh>
            </group>
          </group>
          <group
            name="812_roller_(1)_8"
            position={[-3.69, -0.12, -9.69]}
            rotation={[0.77, 0.22, 1.94]}>
            <group name="812_roller_core_(1)_8" position={[2.46, 4.5, 5.69]}>
              <mesh name="mesh_28" geometry={nodes.mesh_28.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="812_roller_skin_(1)_8"
              position={[2.46, 4.5, 5.69]}
              rotation={[-0.09, 0, 0]}>
              <mesh name="mesh_29" geometry={nodes.mesh_29.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="Axle_tube_(1)_8"
              position={[-1.33, 4.5, 5.69]}
              rotation={[-2.98, Math.PI / 2, 0]}>
              <mesh name="mesh_30" geometry={nodes.mesh_30.geometry}>
                {material}
              </mesh>
            </group>
          </group>
          <group
            name="812_roller_(1)_9"
            position={[-3.26, 1.74, -9.69]}
            rotation={[Math.PI / 4, -0.15, Math.PI / 2]}>
            <group name="812_roller_core_(1)_9" position={[2.46, 4.5, 5.69]}>
              <mesh name="mesh_31" geometry={nodes.mesh_31.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="812_roller_skin_(1)_9"
              position={[2.46, 4.5, 5.69]}
              rotation={[-0.09, 0, 0]}>
              <mesh name="mesh_32" geometry={nodes.mesh_32.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="Axle_tube_(1)_9"
              position={[-1.33, 4.5, 5.69]}
              rotation={[-2.98, Math.PI / 2, 0]}>
              <mesh name="mesh_33" geometry={nodes.mesh_33.geometry}>
                {material}
              </mesh>
            </group>
          </group>
          <group
            name="812_roller_(1)_10"
            position={[-1.95, 3.13, -9.69]}
            rotation={[0.65, -0.5, 1.16]}>
            <group name="812_roller_core_(1)_10" position={[2.46, 4.5, 5.69]}>
              <mesh name="mesh_34" geometry={nodes.mesh_34.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="812_roller_skin_(1)_10"
              position={[2.46, 4.5, 5.69]}
              rotation={[-0.09, 0, 0]}>
              <mesh name="mesh_35" geometry={nodes.mesh_35.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="Axle_tube_(1)_10"
              position={[-1.33, 4.5, 5.69]}
              rotation={[-2.98, Math.PI / 2, 0]}>
              <mesh name="mesh_36" geometry={nodes.mesh_36.geometry}>
                {material}
              </mesh>
            </group>
          </group>
          <group
            name="812_roller_(1)_11"
            position={[-0.12, 3.69, -9.69]}
            rotation={[0.3, -0.75, 0.58]}>
            <group name="812_roller_core_(1)_11" position={[2.46, 4.5, 5.69]}>
              <mesh name="mesh_37" geometry={nodes.mesh_37.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="812_roller_skin_(1)_11"
              position={[2.46, 4.5, 5.69]}
              rotation={[-0.09, 0, 0]}>
              <mesh name="mesh_38" geometry={nodes.mesh_38.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="Axle_tube_(1)_11"
              position={[-1.33, 4.5, 5.69]}
              rotation={[-2.98, Math.PI / 2, 0]}>
              <mesh name="mesh_39" geometry={nodes.mesh_39.geometry}>
                {material}
              </mesh>
            </group>
          </group>
          <group
            name="10-32x3500_shcs_HX-SHCS_019-32x0375x0375-C_(1)"
            position={[3.2, 8.6, -1.19]}
            rotation={[3.14, 0.79, 3.14]}>
            <mesh name="mesh_40" geometry={nodes.mesh_40.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-32x3500_shcs_HX-SHCS_019-32x0375x0375-C_(1)_1"
            position={[7.07, 5.85, -1.19]}
            rotation={[2.68, 0.66, -2.46]}>
            <mesh name="mesh_41" geometry={nodes.mesh_41.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-32x3500_shcs_HX-SHCS_019-32x0375x0375-C_(1)_2"
            position={[9.04, 1.53, -1.19]}
            rotation={[2.43, 0.36, -1.96]}>
            <mesh name="mesh_42" geometry={nodes.mesh_42.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-32x3500_shcs_HX-SHCS_019-32x0375x0375-C_(1)_3"
            position={[8.6, -3.2, -1.19]}
            rotation={[2.36, 0, -1.58]}>
            <mesh name="mesh_43" geometry={nodes.mesh_43.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-32x3500_shcs_HX-SHCS_019-32x0375x0375-C_(1)_4"
            position={[5.85, -7.07, -1.19]}
            rotation={[2.43, -0.36, -1.19]}>
            <mesh name="mesh_44" geometry={nodes.mesh_44.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-32x3500_shcs_HX-SHCS_019-32x0375x0375-C_(1)_5"
            position={[1.53, -9.04, -1.19]}
            rotation={[2.68, -0.66, -0.69]}>
            <mesh name="mesh_45" geometry={nodes.mesh_45.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-32x3500_shcs_HX-SHCS_019-32x0375x0375-C_(1)_6"
            position={[-3.2, -8.6, -1.19]}
            rotation={[-3.14, -0.79, 0]}>
            <mesh name="mesh_46" geometry={nodes.mesh_46.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-32x3500_shcs_HX-SHCS_019-32x0375x0375-C_(1)_7"
            position={[-7.07, -5.85, -1.19]}
            rotation={[-2.68, -0.66, 0.68]}>
            <mesh name="mesh_47" geometry={nodes.mesh_47.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-32x3500_shcs_HX-SHCS_019-32x0375x0375-C_(1)_8"
            position={[-9.04, -1.53, -1.19]}
            rotation={[-2.43, -0.36, 1.18]}>
            <mesh name="mesh_48" geometry={nodes.mesh_48.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-32x3500_shcs_HX-SHCS_019-32x0375x0375-C_(1)_9"
            position={[-8.6, 3.2, -1.19]}
            rotation={[-2.36, 0, 1.57]}>
            <mesh name="mesh_49" geometry={nodes.mesh_49.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-32x3500_shcs_HX-SHCS_019-32x0375x0375-C_(1)_10"
            position={[-5.85, 7.07, -1.19]}
            rotation={[-2.43, 0.36, 1.95]}>
            <mesh name="mesh_50" geometry={nodes.mesh_50.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-32x3500_shcs_HX-SHCS_019-32x0375x0375-C_(1)_11"
            position={[-1.53, 9.04, -1.19]}
            rotation={[-2.68, 0.66, 2.45]}>
            <mesh name="mesh_51" geometry={nodes.mesh_51.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_(1)"
            position={[4.15, 4.16, -7.53]}
            rotation={[-Math.PI / 2, -0.29, 0]}>
            <mesh name="mesh_52" geometry={nodes.mesh_52.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_(1)_1"
            position={[4.13, 4.18, -1.36]}
            rotation={[-Math.PI / 2, -0.11, -Math.PI]}>
            <mesh name="mesh_53" geometry={nodes.mesh_53.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_(1)_2"
            position={[5.67, 1.53, -7.53]}
            rotation={[-Math.PI / 2, 0.24, 0]}>
            <mesh name="mesh_54" geometry={nodes.mesh_54.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_(1)_3"
            position={[5.68, -1.51, -7.53]}
            rotation={[-Math.PI / 2, 0.76, 0]}>
            <mesh name="mesh_55" geometry={nodes.mesh_55.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_(1)_4"
            position={[4.16, -4.15, -7.53]}
            rotation={[-Math.PI / 2, 1.29, 0]}>
            <mesh name="mesh_56" geometry={nodes.mesh_56.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_(1)_5"
            position={[1.53, -5.67, -7.53]}
            rotation={[Math.PI / 2, 1.33, -Math.PI]}>
            <mesh name="mesh_57" geometry={nodes.mesh_57.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_(1)_6"
            position={[-1.51, -5.68, -7.53]}
            rotation={[Math.PI / 2, 0.81, -Math.PI]}>
            <mesh name="mesh_58" geometry={nodes.mesh_58.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_(1)_7"
            position={[-4.15, -4.16, -7.53]}
            rotation={[Math.PI / 2, 0.29, -Math.PI]}>
            <mesh name="mesh_59" geometry={nodes.mesh_59.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_(1)_8"
            position={[-5.67, -1.53, -7.53]}
            rotation={[Math.PI / 2, -0.24, -Math.PI]}>
            <mesh name="mesh_60" geometry={nodes.mesh_60.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_(1)_9"
            position={[-5.68, 1.51, -7.53]}
            rotation={[Math.PI / 2, -0.76, -Math.PI]}>
            <mesh name="mesh_61" geometry={nodes.mesh_61.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_(1)_10"
            position={[-4.16, 4.15, -7.53]}
            rotation={[Math.PI / 2, -1.29, -Math.PI]}>
            <mesh name="mesh_62" geometry={nodes.mesh_62.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_(1)_11"
            position={[-1.53, 5.67, -7.53]}
            rotation={[-Math.PI / 2, -1.33, 0]}>
            <mesh name="mesh_63" geometry={nodes.mesh_63.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_(1)_12"
            position={[1.51, 5.68, -7.53]}
            rotation={[-Math.PI / 2, -0.81, 0]}>
            <mesh name="mesh_64" geometry={nodes.mesh_64.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_(1)_13"
            position={[5.66, 1.55, -1.36]}
            rotation={[-Math.PI / 2, 0.42, -Math.PI]}>
            <mesh name="mesh_65" geometry={nodes.mesh_65.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_(1)_14"
            position={[5.68, -1.49, -1.36]}
            rotation={[-Math.PI / 2, 0.94, -Math.PI]}>
            <mesh name="mesh_66" geometry={nodes.mesh_66.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_(1)_15"
            position={[4.18, -4.13, -1.36]}
            rotation={[-Math.PI / 2, 1.46, -Math.PI]}>
            <mesh name="mesh_67" geometry={nodes.mesh_67.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_(1)_16"
            position={[1.55, -5.66, -1.36]}
            rotation={[Math.PI / 2, 1.15, 0]}>
            <mesh name="mesh_68" geometry={nodes.mesh_68.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_(1)_17"
            position={[-1.49, -5.68, -1.36]}
            rotation={[Math.PI / 2, 0.63, 0]}>
            <mesh name="mesh_69" geometry={nodes.mesh_69.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_(1)_18"
            position={[-4.13, -4.18, -1.36]}
            rotation={[Math.PI / 2, 0.11, 0]}>
            <mesh name="mesh_70" geometry={nodes.mesh_70.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_(1)_19"
            position={[-5.66, -1.55, -1.36]}
            rotation={[Math.PI / 2, -0.42, 0]}>
            <mesh name="mesh_71" geometry={nodes.mesh_71.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_(1)_20"
            position={[-5.68, 1.49, -1.36]}
            rotation={[Math.PI / 2, -0.94, 0]}>
            <mesh name="mesh_72" geometry={nodes.mesh_72.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_(1)_21"
            position={[-4.18, 4.13, -1.36]}
            rotation={[Math.PI / 2, -1.46, 0]}>
            <mesh name="mesh_73" geometry={nodes.mesh_73.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_(1)_22"
            position={[-1.55, 5.66, -1.36]}
            rotation={[-Math.PI / 2, -1.15, -Math.PI]}>
            <mesh name="mesh_74" geometry={nodes.mesh_74.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_(1)_23"
            position={[1.49, 5.68, -1.36]}
            rotation={[-Math.PI / 2, -0.63, -Math.PI]}>
            <mesh name="mesh_75" geometry={nodes.mesh_75.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-24_Hex_Washer_Rolling_x_1_93882A213_(1)"
            position={[3.68, 2.14, -3.73]}>
            <mesh name="mesh_76" geometry={nodes.mesh_76.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-24_Hex_Washer_Rolling_x_1_93882A213_(1)_1"
            position={[2.11, 3.69, -5.16]}
            rotation={[-Math.PI, 0, 2.75]}>
            <mesh name="mesh_77" geometry={nodes.mesh_77.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-24_Hex_Washer_Rolling_x_1_93882A213_(1)_2"
            position={[3.69, -2.11, -3.73]}
            rotation={[0, 0, -Math.PI / 3]}>
            <mesh name="mesh_78" geometry={nodes.mesh_78.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-24_Hex_Washer_Rolling_x_1_93882A213_(1)_3"
            position={[0.02, -4.25, -3.73]}
            rotation={[0, 0, -2.09]}>
            <mesh name="mesh_79" geometry={nodes.mesh_79.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-24_Hex_Washer_Rolling_x_1_93882A213_(1)_4"
            position={[-3.68, -2.14, -3.73]}
            rotation={[0, 0, Math.PI]}>
            <mesh name="mesh_80" geometry={nodes.mesh_80.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-24_Hex_Washer_Rolling_x_1_93882A213_(1)_5"
            position={[-3.69, 2.11, -3.73]}
            rotation={[0, 0, 2.09]}>
            <mesh name="mesh_81" geometry={nodes.mesh_81.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-24_Hex_Washer_Rolling_x_1_93882A213_(1)_6"
            position={[-0.02, 4.25, -3.73]}
            rotation={[0, 0, Math.PI / 3]}>
            <mesh name="mesh_82" geometry={nodes.mesh_82.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-24_Hex_Washer_Rolling_x_1_93882A213_(1)_7"
            position={[4.25, 0.02, -5.16]}
            rotation={[-Math.PI, 0, -2.49]}>
            <mesh name="mesh_83" geometry={nodes.mesh_83.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-24_Hex_Washer_Rolling_x_1_93882A213_(1)_8"
            position={[2.14, -3.68, -5.16]}
            rotation={[-Math.PI, 0, -1.44]}>
            <mesh name="mesh_84" geometry={nodes.mesh_84.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-24_Hex_Washer_Rolling_x_1_93882A213_(1)_9"
            position={[-2.11, -3.69, -5.16]}
            rotation={[-Math.PI, 0, -0.39]}>
            <mesh name="mesh_85" geometry={nodes.mesh_85.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-24_Hex_Washer_Rolling_x_1_93882A213_(1)_10"
            position={[-4.25, -0.02, -5.16]}
            rotation={[Math.PI, 0, 0.65]}>
            <mesh name="mesh_86" geometry={nodes.mesh_86.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-24_Hex_Washer_Rolling_x_1_93882A213_(1)_11"
            position={[-2.14, 3.68, -5.16]}
            rotation={[-Math.PI, 0, 1.7]}>
            <mesh name="mesh_87" geometry={nodes.mesh_87.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-32_Nylock_Nut_(1)"
            position={[1.76, 8.88, -7.36]}
            rotation={[-2.68, -0.66, -1.93]}>
            <mesh name="mesh_88" geometry={nodes.mesh_88.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-32_Nylock_Nut_(1)_1"
            position={[5.96, 6.81, -7.36]}
            rotation={[-2.43, -0.36, -0.91]}>
            <mesh name="mesh_89" geometry={nodes.mesh_89.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-32_Nylock_Nut_(1)_2"
            position={[-2.92, 8.57, -7.36]}
            rotation={[-Math.PI, -Math.PI / 4, -Math.PI]}>
            <mesh name="mesh_90" geometry={nodes.mesh_90.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-32_Nylock_Nut_(1)_3"
            position={[8.57, 2.92, -7.36]}
            rotation={[-2.36, 0, 0]}>
            <mesh name="mesh_91" geometry={nodes.mesh_91.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-32_Nylock_Nut_(1)_4"
            position={[8.88, -1.76, -7.36]}
            rotation={[-2.43, 0.36, 0.91]}>
            <mesh name="mesh_92" geometry={nodes.mesh_92.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-32_Nylock_Nut_(1)_5"
            position={[6.81, -5.96, -7.36]}
            rotation={[-2.68, 0.66, 1.93]}>
            <mesh name="mesh_93" geometry={nodes.mesh_93.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-32_Nylock_Nut_(1)_6"
            position={[2.92, -8.57, -7.36]}
            rotation={[-Math.PI, Math.PI / 4, Math.PI]}>
            <mesh name="mesh_94" geometry={nodes.mesh_94.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-32_Nylock_Nut_(1)_7"
            position={[-1.76, -8.88, -7.36]}
            rotation={[2.68, 0.66, -1.93]}>
            <mesh name="mesh_95" geometry={nodes.mesh_95.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-32_Nylock_Nut_(1)_8"
            position={[-5.96, -6.81, -7.36]}
            rotation={[2.43, 0.36, -0.91]}>
            <mesh name="mesh_96" geometry={nodes.mesh_96.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-32_Nylock_Nut_(1)_9"
            position={[-8.57, -2.92, -7.36]}
            rotation={[2.36, 0, 0]}>
            <mesh name="mesh_97" geometry={nodes.mesh_97.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-32_Nylock_Nut_(1)_10"
            position={[-8.88, 1.76, -7.36]}
            rotation={[2.43, -0.36, 0.91]}>
            <mesh name="mesh_98" geometry={nodes.mesh_98.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-32_Nylock_Nut_(1)_11"
            position={[-6.81, 5.96, -7.36]}
            rotation={[2.68, -0.66, 1.93]}>
            <mesh name="mesh_99" geometry={nodes.mesh_99.geometry}>
              {material}
            </mesh>
          </group>
        </group>
        <group
          name="am-3947-125_375H_x_125IN_Spacer_v2"
          position={[0, 0, -0.01]}
          rotation={[0, 1.57, 0]}
        />
        <group
          name="am-4124_500_Hex_Hub_v2"
          position={[-0.32, 0, 0]}
          rotation={[0, -1.57, 0]}>
          <mesh name="mesh_100" geometry={nodes.mesh_100.geometry}>
            {material}
          </mesh>
        </group>
        <group
          name="am-1523_#10_Fender_Washer_v1"
          position={[-2.54, 0, -0.01]}
          rotation={[0, -1.57, 0]}>
          <mesh name="mesh_101" geometry={nodes.mesh_101.geometry}>
            {material}
          </mesh>
        </group>
        <group
          name="am-4124_500_Hex_Hub_v2_1"
          position={[-10.16, -0.01, 0.01]}
          rotation={[-Math.PI, 1.57, 0]}>
          <mesh name="mesh_102" geometry={nodes.mesh_102.geometry}>
            {material}
          </mesh>
        </group>
        <group
          name="500_Hex_Shaft_33425mm_v11"
          position={[-9.88, 0, -0.01]}
          rotation={[0, -1.57, 0]}>
          <mesh name="mesh_103" geometry={nodes.mesh_103.geometry}>
            {material}
          </mesh>
        </group>
        <group
          name="am-1523_#10_Fender_Washer_v1_1"
          position={[-7.78, -0.01, 0.01]}
          rotation={[0, -1.57, 0]}>
          <mesh name="mesh_104" geometry={nodes.mesh_104.geometry}>
            {material}
          </mesh>
        </group>
        <group
          name="am-1523_#10_Fender_Washer_v1_2"
          position={[-11.28, 0, -0.01]}
          rotation={[0, -1.57, 0]}>
          <mesh name="mesh_105" geometry={nodes.mesh_105.geometry}>
            {material}
          </mesh>
        </group>
        <group
          name="500_Hex_Shaft_4in_v2"
          position={[2.57, 0, -0.01]}
          rotation={[0, -1.57, 0]}>
          <mesh name="mesh_106" geometry={nodes.mesh_106.geometry}>
            {material}
          </mesh>
        </group>
      </group>
    </group>
  );
}

useGLTF.preload(RightHandWheelModel);
