import _, { camelCase } from 'lodash';
import { CloudProduceAddress } from 'model/src/common/CloudProduceAddress';
import {
  combine,
  create,
  cutTo,
  fullString,
  toAddress,
} from 'model/src/common/CloudProduceAddressUtility';
import { PageToPageServer } from 'model/src/dataflowprotocol/pagetopageserver/PageToPageServer';
import { PageToPageServerType } from 'model/src/dataflowprotocol/pagetopageserver/PageToPageServerType';
import React, { useContext } from 'react';
import { getLoggerMapFromDataMap } from 'services/src/logging/LoggerMapUtility';

import { ConfirmationBoxWindowProps } from '../../farm-ui/windows/ConfirmationBoxWindow';
import {
  ControllerContext,
  ControllerContextType,
} from '../../three/farmviewer/context/controllercontext/ControllerContext';
import {
  WATER_TANK_0,
  WATER_TANK_1,
} from '../../three/farmviewer/context/controllercontext/ControllerContexts';
import {
  DesktopContext,
  DesktopContextType,
} from '../../three/farmviewer/context/desktopcontext/DesktopContext';
import WindowType from '../window/WindowTypes';
import ContextButton from './button/ContextButton';
import RaspberryPiFirmwareContextButton from './button/RaspberryPiFirmwareContextButton';
import Divider from './Divider';

export default function FishTankContextMenu(props) {
  const desktopContext: DesktopContextType = useContext(DesktopContext);
  const controllerContext: ControllerContextType = useContext(
    ControllerContext
  );

  const baseStackUrl: CloudProduceAddress = cutTo(toAddress(props.url), -2);

  const baseStackLoggersUrl: CloudProduceAddress = combine(
    baseStackUrl,
    create(['LOGGERS'])
  );

  const loggerMap = getLoggerMapFromDataMap(baseStackLoggersUrl, props.dataMap);

  const titleCase = (string: string) => {
    return _.startCase(_.camelCase(string));
  };

  const onClickWithConfirmation = (windowProps: ConfirmationBoxWindowProps) => {
    props.hideContextMenu();
    if (desktopContext.setModal) {
      desktopContext.setModal(
        WindowType.CONFIRMATION,
        windowProps,
        desktopContext
      );
    }
  };

  return (
    <>
      <RaspberryPiFirmwareContextButton
        baseUrl={baseStackUrl}
        targetUrl={baseStackUrl}
        connectionToServer={props.connectionToServer}
        dataMap={props.dataMap}
        hideContextMenu={props.hideContextMenu}
      />
      <ContextButton
        onClick={() =>
          onClickWithConfirmation({
            yesButtonEventHandler: () => {
              const newCommand: PageToPageServer = {
                type: PageToPageServerType.SendCommand,
                sendCommand: {
                  targetId: fullString(baseStackUrl),
                  rebootCommand: {
                    targetId: fullString(baseStackUrl),
                    dummyData: true,
                  },
                },
              };
              props.connectionToServer.sendMessage(newCommand);
            },
            queryText: props.url + ':\n Reboot?',
          })
        }>
        Reboot
      </ContextButton>
      <ContextButton
        onClick={() =>
          onClickWithConfirmation({
            yesButtonEventHandler: () => {
              const newCommand: PageToPageServer = {
                type: PageToPageServerType.SendCommand,
                sendCommand: {
                  targetId: fullString(baseStackUrl),
                  shutdownCommand: {
                    targetId: fullString(baseStackUrl),
                    dummyData: true,
                  },
                },
              };
              props.connectionToServer.sendMessage(newCommand);
            },
            queryText: props.url + ':\n Shut Down?',
          })
        }>
        Shut Down
      </ContextButton>
      <Divider />
      {Array.from(loggerMap.entries()).map(([loggerName, data]) => {
        return (
          //TODO(austin): add onClick
          <ContextButton
            key={loggerName}
            onClick={() => {
              onClickWithConfirmation({
                yesButtonEventHandler: () => {
                  const newDataUpdate: PageToPageServer = {
                    type: PageToPageServerType.SendDataUpdate,
                    sendDataUpdate: {
                      id: fullString(data.address),
                      datum: { value: !data.isEnabled },
                    },
                  };
                  props.connectionToServer.sendMessage(newDataUpdate);
                },
                queryText:
                  props.url +
                  ':\n ' +
                  (data.isEnabled ? 'Disable ' : 'Enable ') +
                  titleCase(loggerName) +
                  '?',
              });
            }}>
            {data.isEnabled ? 'Disable ' : 'Enable '} {titleCase(loggerName)}
          </ContextButton>
        );
      })}
      <Divider />
      <ContextButton
        onClick={() => {
          props.inspect();
        }}>
        Inspect
      </ContextButton>
      <ContextButton
        onClick={() => {
          props.select(props.url, controllerContext, desktopContext);
        }}>
        Select
      </ContextButton>
      <ContextButton
        onClick={() =>
          onClickWithConfirmation({
            yesButtonEventHandler: () => {
              const newCommand: PageToPageServer = {
                type: PageToPageServerType.SendCommand,
                sendCommand: {
                  targetId: fullString(baseStackUrl),
                  changeLightLevelCommand: {
                    lightLevel: 100,
                    transitionDurationMs: 30000,
                  },
                },
              };
              props.connectionToServer.sendMessage(newCommand);
            },
            queryText: props.url + ':\n Turn fishtank lights on?',
          })
        }>
        Lights On
      </ContextButton>
      <ContextButton
        onClick={() =>
          onClickWithConfirmation({
            yesButtonEventHandler: () => {
              const newCommand: PageToPageServer = {
                type: PageToPageServerType.SendCommand,
                sendCommand: {
                  targetId: fullString(baseStackUrl),
                  changeLightLevelCommand: {
                    lightLevel: 0,
                    transitionDurationMs: 30000,
                  },
                },
              };
              props.connectionToServer.sendMessage(newCommand);
            },
            queryText: props.url + ':\n Turn fishtank lights off?',
          })
        }>
        Lights Off
      </ContextButton>
      <ContextButton
        onClick={() =>
          onClickWithConfirmation({
            yesButtonEventHandler: () => {
              const newCommand: PageToPageServer = {
                type: PageToPageServerType.SendCommand,
                sendCommand: {
                  targetId: fullString(baseStackUrl),
                  pumpOnCommand: { dummyData: true },
                },
              };
              props.connectionToServer.sendMessage(newCommand);
            },
            queryText: props.url + ':\n Turn fishtank pump on?',
          })
        }>
        Pump On
      </ContextButton>
      <ContextButton
        onClick={() =>
          onClickWithConfirmation({
            yesButtonEventHandler: () => {
              const newCommand: PageToPageServer = {
                type: PageToPageServerType.SendCommand,
                sendCommand: {
                  targetId: fullString(baseStackUrl),
                  pumpOffCommand: { dummyData: true },
                },
              };
              props.connectionToServer.sendMessage(newCommand);
            },
            queryText: props.url + ':\n Turn fishtank pump off?',
          })
        }>
        Pump Off
      </ContextButton>
      <ContextButton
        onClick={() =>
          onClickWithConfirmation({
            yesButtonEventHandler: () => {
              const newCommand: PageToPageServer = {
                type: PageToPageServerType.SendCommand,
                sendCommand: {
                  targetId: fullString(baseStackUrl),
                  drainOpenCommand: { dummyData: true },
                },
              };
              props.connectionToServer.sendMessage(newCommand);
            },
            queryText: props.url + ':\n Open Drain?',
          })
        }>
        Drain Valve Open
      </ContextButton>
      <ContextButton
        onClick={() =>
          onClickWithConfirmation({
            yesButtonEventHandler: () => {
              const newCommand: PageToPageServer = {
                type: PageToPageServerType.SendCommand,
                sendCommand: {
                  targetId: fullString(baseStackUrl),
                  drainCloseCommand: { dummyData: true },
                },
              };
              props.connectionToServer.sendMessage(newCommand);
            },
            queryText: props.url + ':\n Close Drain?',
          })
        }>
        Drain Valve Close
      </ContextButton>
      <ContextButton
        onClick={() => {
          props.applyOverridingRestriction(props.url, WATER_TANK_0);
        }}>
        {WATER_TANK_0.name}
      </ContextButton>
      <ContextButton
        onClick={() => {
          props.applyOverridingRestriction(props.url, WATER_TANK_1);
        }}>
        {WATER_TANK_1.name}
      </ContextButton>
    </>
  );
}
