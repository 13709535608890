import React from 'react';
import styled from 'styled-components';
import { cssVar } from '../../..';
import GamepadBackground from './GamepadBackground';

export const Window = styled.div`
  box-sizing: border-box;
  position: relative;
`;

export const Background = styled.img`
  width: 256px;
  height: 160px;
`;

export const Foreground = styled.svg`
  position: absolute;
  left: 0px;
  width: 256px;
  height: 160px;
`;

export type GamepadProps = {
  gamepadConnected: boolean;
  gamepadControlUpPressed: boolean;
  gamepadControlDownPressed: boolean;
  gamepadControlLeftPressed: boolean;
  gamepadControlRightPressed: boolean;
  xButtonPressed: boolean;
  yButtonPressed: boolean;
  aButtonPressed: boolean;
  bButtonPressed: boolean;
  startButtonPressed: boolean;
  backButtonPressed: boolean;
  modeButtonPressed: boolean;
  vibrationButtonPressed: boolean;
  leftJoystickXAxis: number;
  leftJoystickYAxis: number;
  rightJoystickXAxis: number;
  rightJoystickYAxis: number;
};

Gamepad.defaultProps = {
  gamepadControlUpPressed: false,
  gamepadControlDownPressed: false,
  gamepadControlLeftPressed: false,
  gamepadControlRightPressed: false,
  xButtonPressed: false,
  yButtonPressed: false,
  aButtonPressed: false,
  bButtonPressed: false,
  startButtonPressed: false,
  backButtonPressed: false,
  modeButtonPressed: false,
  vibrationButtonPressed: false,
  leftJoystickXAxis: 0,
  leftJoystickYAxis: 0,
  rightJoystickXAxis: 0,
  rightJoystickYAxis: 0,
};

export default function Gamepad(props: GamepadProps) {
  const color = cssVar('--cloud-produce-hot-pink');
  return (
    <Window>
      <GamepadBackground gamepadConnected={props.gamepadConnected} />
      <Foreground viewBox="0 0 1280 800" xmlns="<http://www.w3.org/2000/svg>">
        {/* Game Pad Control Area */}
        <circle
          cx="256"
          cy="256"
          r="104"
          strokeWidth="4"
          stroke={color}
          fill="none"
        />

        {/* Left Control */}
        <rect
          x="160"
          y="224"
          width="64"
          height="64"
          rx="8"
          strokeWidth="4"
          stroke={color}
          fill={props.gamepadControlLeftPressed ? color : 'none'}
        />

        {/* Right Control */}
        <rect
          x="288"
          y="224"
          width="64"
          height="64"
          rx="8"
          strokeWidth="4"
          stroke={color}
          fill={props.gamepadControlRightPressed ? color : 'none'}
        />

        {/* Up Control */}
        <rect
          x="224"
          y="160"
          width="64"
          height="64"
          rx="8"
          strokeWidth="4"
          stroke={color}
          fill={props.gamepadControlUpPressed ? color : 'none'}
        />

        {/* Down Control */}
        <rect
          x="224"
          y="288"
          width="64"
          height="64"
          rx="8"
          strokeWidth="4"
          stroke={color}
          fill={props.gamepadControlDownPressed ? color : 'none'}
        />

        {/* Button Area */}
        <circle
          cx="1024"
          cy="256"
          r="104"
          strokeWidth="4"
          stroke={color}
          fill="none"
        />

        {/* Y Button */}
        <circle
          cx="1024"
          cy="192"
          r="32"
          strokeWidth="4"
          stroke={color}
          fill={props.yButtonPressed ? color : 'none'}
        />

        {/* A Button */}
        <circle
          cx="1024"
          cy="320"
          r="32"
          strokeWidth="4"
          stroke={color}
          fill={props.aButtonPressed ? color : 'none'}
        />

        {/* X Button */}
        <circle
          cx="960"
          cy="256"
          r="32"
          strokeWidth="4"
          stroke={color}
          fill={props.xButtonPressed ? color : 'none'}
        />

        {/* B Button */}
        <circle
          cx="1088"
          cy="256"
          r="32"
          strokeWidth="4"
          stroke={color}
          fill={props.bButtonPressed ? color : 'none'}
        />

        {/* Left Joystick Area */}
        <circle
          cx="436"
          cy="408"
          r="104"
          strokeWidth="4"
          stroke={color}
          fill="none"
        />
        {/* Left Joystick */}
        <circle
          cx={436 + 64 * props.leftJoystickXAxis}
          cy={408 + 64 * props.leftJoystickYAxis}
          r="64"
          strokeWidth="4"
          stroke={color}
          fill={color}
        />
        {/* Right Joystick Area */}
        <circle
          cx="844"
          cy="408"
          r="104"
          strokeWidth="4"
          stroke={color}
          fill="none"
        />
        {/* Right Joystick */}
        <circle
          cx={844 + 64 * props.rightJoystickXAxis}
          cy={408 + 64 * props.rightJoystickYAxis}
          r="64"
          strokeWidth="4"
          stroke={color}
          fill={color}
        />

        {/* Back Button */}
        <rect
          x="512"
          y="160"
          width="64"
          height="32"
          rx="16"
          strokeWidth="4"
          stroke={color}
          fill={props.backButtonPressed ? color : 'none'}
        />

        {/* Mode Button */}
        <rect
          x="512"
          y="256"
          width="64"
          height="32"
          rx="16"
          strokeWidth="4"
          stroke={color}
          fill={props.modeButtonPressed ? color : 'none'}
        />

        {/* Start Button */}
        <rect
          x="704"
          y="160"
          width="64"
          height="32"
          rx="16"
          strokeWidth="4"
          stroke={color}
          fill={props.startButtonPressed ? color : 'none'}
        />

        {/* Vibration Button */}
        <rect
          x="704"
          y="256"
          width="64"
          height="32"
          rx="16"
          strokeWidth="4"
          stroke={color}
          fill={props.vibrationButtonPressed ? color : 'none'}
        />
      </Foreground>
    </Window>
  );
}
