import React, { useState } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three-stdlib';

import useWindowDimensions from '../../../../utopia/desktop/useWindowDimensions';
import { AlignmentOperation } from '../operationcontext/OperationContexts';
import {
  CameraLockType,
  ControllerContext,
  ControllerContextType,
  PlacementOperation,
  ProjectionType
} from './ControllerContext';

export default function ControllerContextProvider(props) {
  const { screenHeight, screenWidth } = useWindowDimensions();

  const getCamera = (contextValue: ControllerContextType) => {
    if (cameraAndControlExist(contextValue)) {
      return contextValue.baseRestriction.cameraSettings!.controls.object;
    }
  };
  const getCameraLockType = (contextValue: ControllerContextType) => {
    if (contextValue.baseRestriction.cameraSettings) {
      return contextValue.baseRestriction.cameraSettings!.cameraLockType;
    }
  };
  const getFirstRaycastObject = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    scene: THREE.Scene,
    contextValue: ControllerContextType
  ) => {
    const raycaster = new THREE.Raycaster();
    const pointer = new THREE.Vector2();
    pointer.x = (event.clientX / screenWidth) * 2 - 1;
    pointer.y = -(event.clientY / screenHeight) * 2 + 1;
    raycaster.setFromCamera(
      pointer,
      contextValue.baseRestriction.cameraSettings!.getCamera(contextValue)
    );
    const intersects = raycaster.intersectObjects(scene.children, true);
    if (intersects.length > 0) {
      return intersects[0];
    }
  };

  const setAlignmentOperation = (
    alignmentOperation: AlignmentOperation,
    contextValue: ControllerContextType
  ) => {
    setContextValue({
      ...contextValue,
      alignmentOperation: { ...alignmentOperation },
    });
  };

  const setPlacementOperation = (
    placementOperation: PlacementOperation,
    contextValue: ControllerContextType
  ) => {
    setContextValue({
      ...contextValue,
      placementOperation: { ...placementOperation },
    });
  };

  const dragMute = (
    eventHandler: (e) => void,
    contextValue: ControllerContextType
  ) => {
    if (contextValue.inDrag) {
      return () => {};
    } else {
      return eventHandler;
    }
  };
  const setInDrag = (inDrag: boolean, contextValue: ControllerContextType) => {
    setContextValue({
      ...contextValue,
      inDrag: inDrag,
    });
  };

  const cameraAndControlExist = (contextValue: ControllerContextType) => {
    return (
      contextValue.baseRestriction.cameraSettings &&
      contextValue.baseRestriction.cameraSettings!.controls
    );
  };

  const getFov = (contextValue: ControllerContextType) => {
    if (cameraAndControlExist(contextValue))
      return contextValue.baseRestriction.cameraSettings!.controls.object.fov;
  };
  const getLookAt = (contextValue: ControllerContextType) => {
    if (cameraAndControlExist(contextValue))
      return contextValue.baseRestriction.cameraSettings!.controls.target;
  };
  const getPosition = (currentContextValue: ControllerContextType) => {
    if (
      currentContextValue.baseRestriction.cameraSettings &&
      currentContextValue.baseRestriction.cameraSettings?.controls &&
      currentContextValue.baseRestriction.cameraSettings?.controls.object.positon
    ) {
      return new THREE.Vector3(
        currentContextValue.baseRestriction.cameraSettings!.controls.object.positon.x,
        currentContextValue.baseRestriction.cameraSettings!.controls.object.positon.y,
        currentContextValue.baseRestriction.cameraSettings!.controls.object.positon.z
      );
    } else {
      return currentContextValue.baseRestriction.cameraSettings!.initialPosition;
    }
  };
  const getProjectionType = (contextValue: ControllerContextType) => {
    if (cameraAndControlExist(contextValue)) {
      return contextValue.baseRestriction.cameraSettings!.controls.object
        .isPerspectiveCamera
        ? ProjectionType.PERSPECTIVE
        : contextValue.baseRestriction.cameraSettings!.controls.object
            .isPerspectiveCamera
        ? ProjectionType.ORTHOGRAPHIC
        : undefined;
    }
  };

  const removeControls = (contextValue: ControllerContextType) => {
    setContextValue({
      ...contextValue,
      baseRestriction: {
        ...contextValue.baseRestriction,
        cameraSettings: {
          ...contextValue.baseRestriction.cameraSettings!,
          controls: null,
        },
      },
    });
  };

  const setLookAt = (
    lookAt: THREE.Vector3,
    contextValue: ControllerContextType
  ) => {
    if (cameraAndControlExist(contextValue)) {
      contextValue.baseRestriction.cameraSettings!.controls.target = lookAt;
      contextValue.baseRestriction.cameraSettings!.controls.update();
    }
  };
  const setPosition = (
    position: THREE.Vector3,
    contextValue: ControllerContextType
  ) => {
    if (cameraAndControlExist(contextValue)) {
      contextValue.baseRestriction.cameraSettings!.controls.object.position.set(
        position.x,
        position.y,
        position.z
      );
      contextValue.baseRestriction.cameraSettings!.controls.update();
    }
  };
  const setProjectionType = (
    projectionType: ProjectionType,
    contextValue: ControllerContextType
  ) => {
    if (
      contextValue.baseRestriction.cameraSettings &&
      contextValue.baseRestriction.cameraSettings.controls
    ) {
      setContextValue({
        ...contextValue,
        baseRestriction: {
          ...contextValue.baseRestriction,
          cameraSettings: {
            ...contextValue.baseRestriction.cameraSettings!,
            projectionType: projectionType,
          },
        },
      });
    }
  };
  const setControls = (
    controls: THREE.OrbitControls,
    contextValue: ControllerContextType
  ) => {
    setContextValue({
      ...contextValue,
      baseRestriction: {
        ...contextValue.baseRestriction,
        cameraSettings: {
          ...contextValue.baseRestriction.cameraSettings!,
          controls: controls,
        },
      },
    });
  };

  const updateContext = (
    newContextValue: ControllerContextType,
    currentContextValue: ControllerContextType
  ) => {
    return setContext({
      ...currentContextValue,
      ...newContextValue,
    });
  };

  const setContext = (newContextValue: ControllerContextType) => {
    setContextValue({
      ...newContextValue,
    });
    return {
      ...newContextValue,
    }
  };

  const [renderFloorNodeTypes, setRenderFloorNodeTypes] = useState(false);
  const [contextValue, setContextValue] = useState<ControllerContextType>({
    lastModifiedBy: ['useState'],
    keys: props.keys,
    baseRestriction: {
      cameraSettings: {
        cameraLockType: CameraLockType.FREE,
        controls: new OrbitControls(new THREE.Camera()),
        initialPosition: new THREE.Vector3(0, -100, 20),
        projectionType: ProjectionType.PERSPECTIVE,
        getCamera: getCamera,
        getCameraLockType: getCameraLockType,
        getFirstRaycastObject: getFirstRaycastObject,
        getFov: getFov,
        getPosition: getPosition,
        getLookAt: getLookAt,
        getProjectionType: getProjectionType,
        removeControls: removeControls,
        setControls: setControls,
        setPosition: setPosition,
        setProjectionType: setProjectionType,
        setLookAt: setLookAt,
      },
      renderFloorNodeTypes: renderFloorNodeTypes,
      setRenderFloorNodeTypes: setRenderFloorNodeTypes,
    },
    overridingRestriction: props.hydratedOverridingRestriction,
    inDrag: false,
    dragMute: dragMute,
    setInDrag: setInDrag,
    updateContext: updateContext,
    setContext: setContext,
    setAlignmentOperation: setAlignmentOperation,
    setPlacementOperation: setPlacementOperation,
    alignmentOperation: {},
    placementOperation: {},
  });

  return (
    <ControllerContext.Provider value={contextValue}>
      {props.children}
    </ControllerContext.Provider>
  );
}
