import { toHexString } from 'model/src/common/Color';
import { BuiltinType } from 'model/src/typescript/Typescript';
import React from 'react';
import styled from 'styled-components';

import Button from '@material-ui/core/Button';

import GlassPane from './GlassPane';
import { THEME } from './Theme';

type Props = {
  farm: any;
  farmDeselected: () => void;
};

// const StyledGlassPane = styled.div`
//   margin: auto;
//   width: ${6 * THEME.majorGridDimension + 'px'};
// `;

const TitleText = styled.div`
  color: ${toHexString(THEME.blues[0])};
  text-align: center;
  width: 100%;
  font-family: arial;
  font-size: ${THEME.standardGridDimension + 'px'};
  height: ${THEME.majorGridDimension + 'px'};
  padding: ${THEME.minorGridDimension + 'px'};
`;

const SelectedFarmControl = (props: Props) => {
  function openFarm() {
    if (typeof window !== BuiltinType.UNDEFINED) {
      window.location.href = `/farm/${props.farm.id}`;
    }
  }

  return (
    <GlassPane
      center={true}
      width={6 * THEME.majorGridDimension}
      height={4 * THEME.majorGridDimension}
      canClose={true}
      onClose={() => {
        props.farmDeselected();
      }}>
      <TitleText>{props.farm.name}</TitleText>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          openFarm();
        }}>
        Open
      </Button>
    </GlassPane>
  );
};

export default SelectedFarmControl;
