import React from 'react';

import { cssVar } from '../..';

const Divider = () => {
  return (
    <div
      style={{
        paddingLeft: cssVar('--dropdownMenuItemIconPadding'),
        paddingRight: cssVar('--dropdownMenuItemIconPadding'),
      }}>
      {' '}
      <div
        style={{
          height: 0,
          border: cssVar('--dropdownMenuItemDivider'),
        }}
      />
    </div>
  );
};

export default Divider;
