import React from 'react';
import {
  NODE_WIDTH_MM,
  NODE_HEIGHT_MM,
  FARM_LINE_WIDTH_MM,
  TROLLEY_GAP_MM,
  LEFT_SCANNER_OFFSET_MM,
  RIGHT_SCANNER_OFFSET_MM,
  FRONT_SCANNER_OFFSET_MM,
  BACK_SCANNER_OFFSET_MM,
} from 'model/src/farm/entities/trolley/physical/PhysicalSize';
import { toRgbaString } from 'model/src/common/Color';
import { THEME } from '../../../globe/Theme';

type Props = {
  trackNode: any;
  x: number;
  y: number;
  onNodePressed: (nodeId: string) => void;
  onLauncherPressed: (trolleyLaunchSite: {
    id: string;
    x: number;
    y: number;
  }) => void;
  onMouseEntered: (trackNodeId: string) => void;
};

function getFill(type: string) {
  switch (type) {
    case 'NONE':
      return 'transparent';
    case 'HARVEST_SITE':
      return toRgbaString(THEME.greens[4]);
    case 'QUAD':
      return toRgbaString(THEME.blues[8]);
    case 'TROLLEY_SITE':
      return toRgbaString(THEME.blues[5]);
    case 'LAUNCH_SITE':
      return toRgbaString(THEME.reds[7]);
    case 'ROAD':
      return toRgbaString(THEME.blues[8]);
    default:
      return 'white';
  }
}

const SvgFarmNode = (props: Props) => {
  function onClick(event) {
    console.log(
      'SvgFarmNode: onClick ' +
        props.x +
        ',' +
        props.y +
        ',' +
        props.trackNode.id
    );

    switch (props.trackNode.type) {
      case 'NONE':
        break;
      case 'HARVEST_SITE':
        break;
      case 'QUAD':
        break;
      case 'TROLLEY_SITE':
        break;
      case 'LAUNCH_SITE':
        props.onLauncherPressed({
          id: props.x + '_' + props.y + '_' + props.trackNode.id + '_' + 'full',
          x: props.x,
          y: props.y,
        });
        break;
      case 'ROAD':
        break;
      default:
        break;
    }

    props.onNodePressed(props.trackNode.id);
  }

  function onMouseEnter(event) {
    if (props.trackNode.type !== 'NONE') {
      props.onMouseEntered(props.trackNode.id);
    }
  }

  if (props.trackNode.type === 'NONE') {
    return <></>;
  }
  const xCoord = props.x * NODE_WIDTH_MM;
  const yCoord = props.y * NODE_HEIGHT_MM;
  const path = `M${xCoord} ${yCoord} v ${NODE_HEIGHT_MM} h ${NODE_WIDTH_MM} v -${NODE_HEIGHT_MM} h -${NODE_WIDTH_MM}`;
  const fill = getFill(props.trackNode.type);
  const leftDownX: number = xCoord + TROLLEY_GAP_MM + LEFT_SCANNER_OFFSET_MM;
  const middleDownX: number = xCoord + NODE_WIDTH_MM / 2;
  const rightDownX: number =
    xCoord + NODE_WIDTH_MM - TROLLEY_GAP_MM - RIGHT_SCANNER_OFFSET_MM;
  const downYStart: number = yCoord;
  const downYEnd: number = NODE_HEIGHT_MM;
  const crossXStart: number = xCoord;
  const crossXEnd: number = NODE_WIDTH_MM;
  const topCrossY: number = yCoord + TROLLEY_GAP_MM + FRONT_SCANNER_OFFSET_MM;
  const middleCrossY: number = yCoord + NODE_HEIGHT_MM / 2;
  const bottomCrossY: number =
    yCoord + NODE_HEIGHT_MM - TROLLEY_GAP_MM - BACK_SCANNER_OFFSET_MM;
  const linesPath = `
      M${leftDownX} ${downYStart} v ${downYEnd}
      M${middleDownX} ${downYStart} v ${downYEnd}
      M${rightDownX} ${downYStart} v ${downYEnd}
      M${crossXStart} ${topCrossY} h ${crossXEnd} 
      M${crossXStart} ${middleCrossY} h ${crossXEnd}
      M${crossXStart} ${bottomCrossY} h ${crossXEnd}
    `;
  return (
    <g onClick={onClick} onMouseEnter={onMouseEnter}>
      <path d={path} stroke="transparent" fill={fill} />
      <path
        d={linesPath}
        strokeWidth={FARM_LINE_WIDTH_MM}
        stroke={toRgbaString(THEME.yellows[4])}
      />
    </g>
  );
};
export default SvgFarmNode;
