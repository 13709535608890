import {
  NavigationalIntent,
  NavigationalIntentType,
} from 'model/src/navigation/NavigationalIntent';
import { DrivetrainControlMode } from 'model/src/series/DrivetrainControlMode';
import React, { useRef, useState } from 'react';
import { useInterval } from 'usehooks-ts';

import { connectedGamepad } from '../../../App';
import Gamepad from './Gamepad';
import { LogitechGamepadAxes, LogitechGamepadButtons } from './GamePadManager';

export type GamepadControllerProps = {
  controlType: DrivetrainControlMode;
  gamepadStateUpdated: (
    linearVelocity: number,
    angle: number,
    angularVelocity: number,
    maxWheelSpeedMS: number
  ) => void;
  navigationalIntentUpdated: (intent: NavigationalIntent) => void;
  buttonXClicked?: () => void;
  buttonYClicked?: () => void;
};

export default function GamepadController(props: GamepadControllerProps) {
  const GAMEPAD_DEAD_ZONE = 0.1;
  const GAMEPAD_POLLING_PERIOD_MS = 100;
  const [gamepad, setGamepad] = useState<any>(null);

  const buttonXPressed = useRef(false);
  const buttonYPressed = useRef(false);

  const vectorLength = (x: number, y: number): number => {
    return x * x + y * y;
  };

  const normalize = value => {
    let newValue = 0;
    if (value >= 0) {
      newValue = value - GAMEPAD_DEAD_ZONE;
      if (newValue < 0) {
        newValue = 0;
      }
    } else {
      newValue = value + GAMEPAD_DEAD_ZONE;
      if (newValue > 0) {
        newValue = 0;
      }
    }
    return newValue;
  };

  const calculateControlState = gp => {
    if (props.controlType === DrivetrainControlMode.Manual) {
      const leftX = normalize(gp.axes[LogitechGamepadAxes.AXIS_LEFT_X]);
      const rightX = normalize(gp.axes[LogitechGamepadAxes.AXIS_RIGHT_X]);
      const rightY = normalize(gp.axes[LogitechGamepadAxes.AXIS_RIGHT_Y]);

      let angle =
        rightY === rightX && rightX === 0
          ? 0
          : -(Math.atan2(rightY, rightX) + Math.PI / 2);
      if (angle < -Math.PI) {
        angle += 2 * Math.PI;
      } else if (angle > Math.PI) {
        angle -= 2 * Math.PI;
      }
      let linearVelocity = vectorLength(rightX, rightY);
      if (linearVelocity > 1) {
        linearVelocity = 1;
      }
      const angularVelocity = leftX;

      let maxWheelSpeedMS = 0.01;

      if (gp.buttons[LogitechGamepadButtons.Button_RT].pressed) {
        maxWheelSpeedMS = 0.2;
      }

      if (gp.buttons[LogitechGamepadButtons.Button_LT].pressed) {
        maxWheelSpeedMS = 0.1;
      }

      if (gp.buttons[LogitechGamepadButtons.CONTROLLER_UP].pressed) {
        linearVelocity = 1;
        angle = 0;
      }

      if (gp.buttons[LogitechGamepadButtons.CONTROLLER_DOWN].pressed) {
        linearVelocity = 1;
        angle = Math.PI;
      }

      if (gp.buttons[LogitechGamepadButtons.CONTROLLER_RIGHT].pressed) {
        linearVelocity = 1;
        angle = -Math.PI / 2;
      }

      if (gp.buttons[LogitechGamepadButtons.CONTROLLER_LEFT].pressed) {
        linearVelocity = 1;
        angle = Math.PI / 2;
      }

      props.gamepadStateUpdated(
        linearVelocity,
        angle,
        angularVelocity,
        maxWheelSpeedMS
      );

      if (gp.buttons[LogitechGamepadButtons.Button_X].pressed) {
        buttonXPressed.current = true;
      } else if (buttonXPressed.current) {
        buttonXPressed.current = false;
        if (props.buttonXClicked) {
          props.buttonXClicked();
        }
      }

      if (gp.buttons[LogitechGamepadButtons.Button_Y].pressed) {
        buttonYPressed.current = true;
      } else if (buttonYPressed.current) {
        buttonYPressed.current = false;
        if (props.buttonYClicked) {
          props.buttonYClicked();
        }
      }
    } else if (props.controlType == DrivetrainControlMode.LineFollowing) {
      let navigationalIntentType: NavigationalIntentType =
        NavigationalIntentType.STOP;
      if (gp.buttons[LogitechGamepadButtons.CONTROLLER_UP].pressed) {
        navigationalIntentType = NavigationalIntentType.FORWARD;
      }

      if (gp.buttons[LogitechGamepadButtons.CONTROLLER_DOWN].pressed) {
        navigationalIntentType = NavigationalIntentType.BACK;
      }

      if (gp.buttons[LogitechGamepadButtons.CONTROLLER_RIGHT].pressed) {
        navigationalIntentType = NavigationalIntentType.RIGHT;
      }

      if (gp.buttons[LogitechGamepadButtons.CONTROLLER_LEFT].pressed) {
        navigationalIntentType = NavigationalIntentType.LEFT;
      }
      props.navigationalIntentUpdated({
        type: navigationalIntentType,
        speedMps: 0.1,
        distance: 10,
      });

      if (gp.buttons[LogitechGamepadButtons.Button_X].pressed) {
        buttonXPressed.current = true;
      } else if (buttonXPressed.current) {
        buttonXPressed.current = false;
        if (props.buttonXClicked) {
          props.buttonXClicked();
        }
      }

      if (gp.buttons[LogitechGamepadButtons.Button_Y].pressed) {
        buttonYPressed.current = true;
      } else if (buttonYPressed.current) {
        buttonYPressed.current = false;
        if (props.buttonYClicked) {
          props.buttonYClicked();
        }
      }
    }
  };

  useInterval(() => {
    if (connectedGamepad.gamepad) {
      const gp: any = navigator.getGamepads()[connectedGamepad.gamepad.index];
      calculateControlState(gp);
      setGamepad(gp);
    } else {
      setGamepad(null);
    }
  }, GAMEPAD_POLLING_PERIOD_MS);

  const gamepadProps = {
    gamepadConnected: false,
    gamepadControlUpPressed: false,
    gamepadControlDownPressed: false,
    gamepadControlLeftPressed: false,
    gamepadControlRightPressed: false,
    xButtonPressed: false,
    yButtonPressed: false,
    aButtonPressed: false,
    bButtonPressed: false,
    startButtonPressed: false,
    backButtonPressed: false,
    modeButtonPressed: false,
    vibrationButtonPressed: false,
    leftJoystickXAxis: 0,
    leftJoystickYAxis: 0,
    rightJoystickXAxis: 0,
    rightJoystickYAxis: 0,
  };

  if (gamepad) {
    gamepadProps.gamepadConnected = gamepad.connected;
    for (let i = 0; i < gamepad.buttons.length; i++) {
      const buttonState = gamepad.buttons[i].pressed;
      switch (i) {
        case LogitechGamepadButtons.Button_A: {
          gamepadProps.aButtonPressed = buttonState;
          break;
        }
        case LogitechGamepadButtons.Button_B: {
          gamepadProps.bButtonPressed = buttonState;
          break;
        }
        case LogitechGamepadButtons.Button_X: {
          gamepadProps.xButtonPressed = buttonState;
          break;
        }
        case LogitechGamepadButtons.Button_Y: {
          gamepadProps.yButtonPressed = buttonState;
          break;
        }
        case LogitechGamepadButtons.Button_Back: {
          gamepadProps.backButtonPressed = buttonState;
          break;
        }
        case LogitechGamepadButtons.Button_Start: {
          gamepadProps.startButtonPressed = buttonState;
          break;
        }
        case LogitechGamepadButtons.CONTROLLER_UP: {
          gamepadProps.gamepadControlUpPressed = buttonState;
          break;
        }
        case LogitechGamepadButtons.CONTROLLER_DOWN: {
          gamepadProps.gamepadControlDownPressed = buttonState;
          break;
        }
        case LogitechGamepadButtons.CONTROLLER_LEFT: {
          gamepadProps.gamepadControlLeftPressed = buttonState;
          break;
        }
        case LogitechGamepadButtons.CONTROLLER_RIGHT: {
          gamepadProps.gamepadControlRightPressed = buttonState;
          break;
        }
      }
    }

    for (let i = 0; i < gamepad.axes.length; i++) {
      const axisState = gamepad.axes[i].toFixed(4);
      switch (i) {
        case LogitechGamepadAxes.AXIS_LEFT_X: {
          gamepadProps.leftJoystickXAxis = axisState;
          break;
        }
        case LogitechGamepadAxes.AXIS_LEFT_Y: {
          gamepadProps.leftJoystickYAxis = axisState;
          break;
        }
        case LogitechGamepadAxes.AXIS_RIGHT_X: {
          gamepadProps.rightJoystickXAxis = axisState;
          break;
        }
        case LogitechGamepadAxes.AXIS_RIGHT_Y: {
          gamepadProps.rightJoystickYAxis = axisState;
          break;
        }
      }
    }
  }
  return <Gamepad {...gamepadProps} />;
}
