import { CloudProduceAddress } from 'model/src/common/CloudProduceAddress';
import {
  combine,
  create,
  fullString,
} from 'model/src/common/CloudProduceAddressUtility';
import { Operations, RegexPhrases } from 'model/src/common/Regex';
import {
  Axis,
  TugAlignerSubsystem,
  TugSubsystem,
} from 'model/src/common/Systems';
import { SeriesType } from 'model/src/series/SeriesTypes';
import React, { useContext, useMemo } from 'react';

import { cssVar } from '../../..';
import { useDataMap } from '../../farmviewer/context/datamapcontext/useDataMap';
import {
  DesktopContext,
  DesktopContextType,
} from '../../farmviewer/context/desktopcontext/DesktopContext';
import Box from '../common/Box';

const SCALE = 5;
const MAX_ALIGNMENT_ERROR_BOUND = 8;
const Z_PLANE_HEIGHT = 10;
const Z_PLANE_TARGET_ESPILON = 0.1;

const TugMagneticAlignmentNodes = (props: { url: CloudProduceAddress }) => {
  const desktopContext: DesktopContextType = useContext(DesktopContext);

  const currentlyAligning = useMemo(
    () =>
      desktopContext.selectedUrl ===
      fullString(props.url) + RegexPhrases.OPERATION + Operations.ALIGNMENT,
    [desktopContext]
  );

  const frontX = useDataMap(
    combine(
      props.url,
      create([
        TugSubsystem.MAGNETIC_ALIGNER,
        TugAlignerSubsystem.FRONT,
        SeriesType.PERCENTAGE,
        Axis.X,
      ])
    )
  );
  const frontY = useDataMap(
    combine(
      props.url,
      create([
        TugSubsystem.MAGNETIC_ALIGNER,
        TugAlignerSubsystem.FRONT,
        SeriesType.PERCENTAGE,
        Axis.Y,
      ])
    )
  );
  const frontZ = useDataMap(
    combine(
      props.url,
      create([
        TugSubsystem.MAGNETIC_ALIGNER,
        TugAlignerSubsystem.FRONT,
        SeriesType.PERCENTAGE,
        Axis.Z,
      ])
    )
  );

  const frontTargetPosition = useMemo(() => {
    const normalizedX = -(frontX.value / 100);
    const normalizedY = -(frontY.value / 100);
    return [normalizedX * SCALE, -SCALE + normalizedY * SCALE, Z_PLANE_HEIGHT];
  }, [frontX, frontY, frontZ]);

  const rearX = useDataMap(
    combine(
      props.url,
      create([
        TugSubsystem.MAGNETIC_ALIGNER,
        TugAlignerSubsystem.REAR,
        SeriesType.PERCENTAGE,
        Axis.X,
      ])
    )
  );
  const rearY = useDataMap(
    combine(
      props.url,
      create([
        TugSubsystem.MAGNETIC_ALIGNER,
        TugAlignerSubsystem.REAR,
        SeriesType.PERCENTAGE,
        Axis.Y,
      ])
    )
  );
  const rearZ = useDataMap(
    combine(
      props.url,
      create([
        TugSubsystem.MAGNETIC_ALIGNER,
        TugAlignerSubsystem.REAR,
        SeriesType.PERCENTAGE,
        Axis.Z,
      ])
    )
  );

  const rearTargetPosition = useMemo(() => {
    const normalizedX = -(rearX.value / 100);
    const normalizedY = -(rearY.value / 100);
    return [normalizedX * SCALE, SCALE + normalizedY * SCALE, Z_PLANE_HEIGHT];
  }, [rearX, rearY, rearZ]);

  const frontSensorColor = useMemo(() => cssVar('--systemBlue'), []);
  const frontTargetColor = useMemo(() => {
    return Math.abs(frontX.value) < MAX_ALIGNMENT_ERROR_BOUND &&
      Math.abs(frontY.value) < MAX_ALIGNMENT_ERROR_BOUND
      ? cssVar('--systemGreen')
      : cssVar('--systemRed');
  }, [frontX, frontY]);

  const rearSensorColor = useMemo(() => cssVar('--systemBlue'), []);
  const rearTargetColor = useMemo(() => {
    return Math.abs(rearX.value) < MAX_ALIGNMENT_ERROR_BOUND &&
      Math.abs(rearY.value) < MAX_ALIGNMENT_ERROR_BOUND
      ? cssVar('--systemGreen')
      : cssVar('--systemRed');
  }, [rearX, rearY]);

  return (
    <group scale={[SCALE, SCALE, SCALE]}>
      {currentlyAligning && (
        <>
          <Box
            color={frontSensorColor}
            opacity={1}
            position={[0, -SCALE, Z_PLANE_HEIGHT + Z_PLANE_TARGET_ESPILON]}
          />
          <Box
            color={frontTargetColor}
            opacity={1}
            position={frontTargetPosition}
          />
          <Box
            color={rearSensorColor}
            opacity={1}
            position={[0, SCALE, Z_PLANE_HEIGHT + Z_PLANE_TARGET_ESPILON]}
          />
          <Box
            color={rearTargetColor}
            opacity={1}
            position={rearTargetPosition}
          />
        </>
      )}
    </group>
  );
};

export default TugMagneticAlignmentNodes;
