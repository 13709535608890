import React from 'react';
import { Point } from 'model/src/common/Point';
import { toRgbaString, Color } from 'model/src/common/Color';

export type PaletteSwatchOptions = {
  swatchColor: Color;
  swatchDimension: number;
  location: Point;
};

type Props = {
  options: PaletteSwatchOptions;
};

function PaletteSwatch(props: Props) {
  return (
    <rect
      x={props.options.location.x}
      y={props.options.location.y}
      width={props.options.swatchDimension}
      height={props.options.swatchDimension}
      fill={toRgbaString(props.options.swatchColor)}
      strokeWidth="0"
    />
  );
}

export default PaletteSwatch;
