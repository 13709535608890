export enum Keys {
  a = 'a',
  b = 'b',
  d = 'd',
  e = 'e',
  f = 'f',
  m = 'm',
  q = 'q',
  r = 'r',
  s = 's',
  w = 'w',
  x = 'x',
  z = 'z',
  Escape = 'Escape',
}

export type MovementKeys = {
  back: string;
  down: string;
  forward: string;
  left: string;
  right: string;
  up: string;

  creativeMode: string;
  survivalMode: string;
  targetLock: string;

  mapPanToggle: string;
};

export const defaultMovementKeys: MovementKeys = {
  back: Keys.s,
  down: Keys.f,
  forward: Keys.w,
  left: Keys.a,
  right: Keys.d,
  up: Keys.r,

  creativeMode: Keys.e,
  survivalMode: Keys.q,
  targetLock: Keys.x,

  mapPanToggle: Keys.m,
};
