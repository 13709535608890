"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UNSET_USER_PROFILE_VALUE = exports.PermissionLevel = exports.UserType = void 0;
var UserType;

(function (UserType) {
  UserType["ADMIN"] = "ADMIN";
  UserType["MEMBER"] = "MEMBER";
  UserType["INTERLOPER"] = "INTERLOPER";
})(UserType = exports.UserType || (exports.UserType = {}));

var PermissionLevel;

(function (PermissionLevel) {
  PermissionLevel["NONE"] = "NONE";
  PermissionLevel["READ"] = "READ";
  PermissionLevel["WRITE"] = "WRITE";
})(PermissionLevel = exports.PermissionLevel || (exports.PermissionLevel = {}));

exports.UNSET_USER_PROFILE_VALUE = 'UNSET_USER_PROFILE_VALUE';