import gql from 'graphql-tag';
import React, { Component } from 'react';
import { useQuery } from 'react-apollo';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import util from 'util';

import GlassPane from '../../globe/GlassPane';
import { THEME } from '../../globe/Theme';
import SvgFarm from './svg/SvgFarm';
import TrolleyCreator from './TrolleyCreator';

const UPDATE_VIRTUAL_TROLLEYS_SUBSCRIPTION = gql`
  subscription {
    virtualFarmUpdated {
      farm {
        id
        name
        tracks {
          id
          name
          trolleys {
            id
            name
            virtual {
              id
              state
              offset {
                cX
                cY
              }
              velocity {
                x
                y
              }
              sensors {
                id
                state
                front {
                  id
                  state
                  sensors {
                    id
                    state
                    level
                  }
                }
                back {
                  id
                  state
                  sensors {
                    id
                    state
                    level
                  }
                }
                left {
                  id
                  state
                  sensors {
                    id
                    state
                    level
                  }
                }
                right {
                  id
                  state
                  sensors {
                    id
                    state
                    level
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const UPDATE_LOGICAL_TROLLEYS_SUBSCRIPTION = gql`
  subscription {
    logicalFarmUpdated {
      farm {
        id
        name
        tracks {
          id
          name
          trolleys {
            id
            name
            logical {
              id
              currentLocation
              currentDirection
              currentOccupation
              path {
                id
                trackNodeIds
                directions
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_FARM_QUERY = gql`
  query getFarm($farmId: ID!) {
    farm(farmId: $farmId) {
      id
      name
      tracks {
        id
        name
        trolleys {
          id
          name
          logical {
            id
            currentLocation
            currentDirection
            currentOccupation
            path {
              id
              trackNodeIds
              directions
            }
          }
          virtual {
            id
            state
            offset {
              cX
              cY
            }
            velocity {
              x
              y
            }
            sensors {
              id
              state
              front {
                id
                state
                sensors {
                  id
                  state
                  level
                }
              }
              back {
                id
                state
                sensors {
                  id
                  state
                  level
                }
              }
              left {
                id
                state
                sensors {
                  id
                  state
                  level
                }
              }
              right {
                id
                state
                sensors {
                  id
                  state
                  level
                }
              }
            }
          }
        }
        stacks {
          id
          trackNodes {
            id
            type
            width
            length
            x
            y
          }
        }
      }
    }
  }
`;

type Props = RouteComponentProps<{ farmId: string }>;

const SvgFarmContainer = styled.div`
  position: absolute;
  width: 1200px;
  height: 80%;
  margin: auto;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

const FarmPage = ({
  history,
  match: {
    params: { farmId },
  },
}: Props) => {
  const { loading, error, data, subscribeToMore } = useQuery(GET_FARM_QUERY, {
    variables: { farmId },
  });

  if (loading) {
    return <h1>loading!</h1>;
  }
  if (error) {
    return <h1>call the helpline</h1>;
  }

  const more = () => {
    subscribeToMore({
      document: UPDATE_VIRTUAL_TROLLEYS_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        // console.log(
        //   'FarmPage:more ' + util.inspect(subscriptionData, { depth: 14 })
        // );
        if (!subscriptionData.data) {
          return prev;
        }
        return prev;
      },
    });
  };

  const more2 = () => {
    subscribeToMore({
      document: UPDATE_LOGICAL_TROLLEYS_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        console.log(
          'FarmPage:more2 ' + util.inspect(subscriptionData, { depth: 14 })
        );
        if (!subscriptionData.data) {
          return prev;
        }
        return prev;
      },
    });
  };
  return (
    <FarmPageView history={history} more={more} more2={more2} data={data} />
  );
};

type ViewProps = {
  history;
  more: any;
  more2: any;
  data: any;
};

type ViewState = {
  clock: number;
  showTrolleyBuilder: boolean;
};

class FarmPageView extends Component<ViewProps, ViewState> {
  trolleyLaunchSite = {
    id: '',
    x: 0,
    y: 0,
  };
  constructor(props: ViewProps) {
    super(props);
    this.state = {
      clock: 0,
      showTrolleyBuilder: false,
    };
  }

  componentDidMount() {
    console.log('FarmPageView componentDidMount start');
    this.props.more();
    console.log('FarmPageView componentDidMount more');
    this.props.more2();
    console.log('FarmPageView componentDidMount more2');
  }

  openTrolleyBuilder() {
    this.setState({
      showTrolleyBuilder: true,
    });
  }

  render() {
    return (
      <SvgFarmContainer>
        <SvgFarm
          track={this.props.data.farm.tracks[0]}
          trolleys={this.props.data.farm.tracks[0].trolleys}
          onTrolleyLaunch={(trolleyLaunchSite: {
            id: string;
            x: number;
            y: number;
          }) => {
            this.trolleyLaunchSite = trolleyLaunchSite;
            this.setState({
              showTrolleyBuilder: true,
            });
          }}
        />
        {this.state.showTrolleyBuilder && (
          <GlassPane
            canClose={true}
            width={4 * THEME.majorGridDimension}
            height={4 * THEME.majorGridDimension}
            center={true}
            onClose={() => {
              this.setState(prevState => {
                return {
                  showTrolleyBuilder: false,
                };
              });
            }}>
            <TrolleyCreator
              farmId={this.props.data.farm.id}
              track={this.props.data.farm.tracks[0]}
              location={this.trolleyLaunchSite}
              onTrolleyCreated={() => {
                this.setState({
                  showTrolleyBuilder: false,
                });
              }}
            />
          </GlassPane>
        )}
      </SvgFarmContainer>
    );
  }
}

export default FarmPage;
