/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import * as THREE from 'three';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';

import { useGLTF } from '@react-three/drei';

import { RenderingType } from '../../../RenderingType';
import materialFactory from '../../../trolley/MaterialFactory';
import BlindRoll_Model from './BlindRoll.glb';

export default function BlindRoll(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF(BlindRoll_Model) as GLTF;

  const material: THREE.Material = materialFactory.generateMaterialObject(
    props.parentUnderInspection,
    props.parentHovered,
    props.online
  );

  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        geometry={nodes.Body1.geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? materials['Plastic - Glossy (White)']
            : material
        }
      />
    </group>
  );
}

useGLTF.preload(BlindRoll_Model);
