"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.buildPhysicalFarm = void 0;

var PhysicalSize_1 = require("./PhysicalSize");

function buildPhysicalFarm(numStacks, stackSize, stacks) {
  var horizontals = [];
  var verticals = [];
  var v = 0;
  var h = 0;

  for (var x = 0; x < numStacks; x++) {
    var left = x * PhysicalSize_1.NODE_WIDTH_MM + PhysicalSize_1.TROLLEY_GAP_MM + PhysicalSize_1.LEFT_SCANNER_OFFSET_MM;
    var center = x * PhysicalSize_1.NODE_WIDTH_MM + PhysicalSize_1.NODE_WIDTH_MM / 2;
    var right = x * PhysicalSize_1.NODE_WIDTH_MM + PhysicalSize_1.NODE_WIDTH_MM - PhysicalSize_1.TROLLEY_GAP_MM - PhysicalSize_1.RIGHT_SCANNER_OFFSET_MM;
    verticals[v++] = left;
    verticals[v++] = center;
    verticals[v++] = right;
  }

  for (var y = 0; y < stackSize; y++) {
    var top = y * PhysicalSize_1.NODE_HEIGHT_MM + PhysicalSize_1.TROLLEY_GAP_MM + PhysicalSize_1.FRONT_SCANNER_OFFSET_MM;
    var middle = y * PhysicalSize_1.NODE_HEIGHT_MM + PhysicalSize_1.NODE_HEIGHT_MM / 2;
    var bottom = y * PhysicalSize_1.NODE_HEIGHT_MM + PhysicalSize_1.NODE_HEIGHT_MM - PhysicalSize_1.TROLLEY_GAP_MM - PhysicalSize_1.BACK_SCANNER_OFFSET_MM;
    horizontals[h++] = top;
    horizontals[h++] = middle;
    horizontals[h++] = bottom;
  }

  return {
    horizontals: horizontals,
    verticals: verticals,
    stacks: stacks
  };
}

exports.buildPhysicalFarm = buildPhysicalFarm;