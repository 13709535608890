import {
  create,
  farmId,
  fullString,
  toAddress,
} from 'model/src/common/CloudProduceAddressUtility';
import {
  GRID_NODE_INSIDE_LENGTH_CM,
  GRID_NODE_OFFSET_X_CM,
  GRID_NODE_OFFSET_Y_CM,
  GRID_NODE_SIDE_LENGTH_CM,
} from 'model/src/common/FarmConstants';
import { Datum } from 'model/src/dataflowprotocol/Datum';
import { FloorNode } from 'model/src/farm/entities/farm/v2/FloorNode';
import { SeriesType } from 'model/src/series/SeriesTypes';
import React, { useContext, useMemo } from 'react';

import { useDataMap } from '../../farmviewer/context/datamapcontext/useDataMap';
import {
  DesktopContext,
  DesktopContextType,
} from '../../farmviewer/context/desktopcontext/DesktopContext';
import GridSquare from './GridSquare';

export default function Floor(props) {
  const desktopContext: DesktopContextType = useContext(DesktopContext);
  const farmUrl = farmId(desktopContext.selectedUrl);
  const gridSizeX = useDataMap(
    create([farmUrl, SeriesType.SIZE_OF_GRID, SeriesType.X])
  );
  const totalGridWidth = +gridSizeX.value + 1;
  const gridSizeY = useDataMap(
    create([farmUrl, SeriesType.SIZE_OF_GRID, SeriesType.Y])
  );
  const totalGridHeight = +gridSizeY.value + 3;
  const grid = props.floor.map(floorNode => {
    return (
      <GridSquare
        url={floorNode.id}
        key={floorNode.id}
        type={floorNode.datum.type}
        width={GRID_NODE_INSIDE_LENGTH_CM}
        depth={GRID_NODE_INSIDE_LENGTH_CM}
        height={1}
        x={
          GRID_NODE_OFFSET_X_CM +
          (totalGridWidth - floorNode.datum.x) * GRID_NODE_SIDE_LENGTH_CM
        }
        y={
          GRID_NODE_OFFSET_Y_CM +
          (totalGridHeight - floorNode.datum.y) * GRID_NODE_SIDE_LENGTH_CM
        }
        upArrow={floorNode.datum.topNeighbor !== undefined}
        downArrow={floorNode.datum.bottomNeighbor !== undefined}
        leftArrow={floorNode.datum.leftNeighbor !== undefined}
        rightArrow={floorNode.datum.rightNeighbor !== undefined}
        tug1HomeSquareUrl={props.tug1HomeSquareUrl}
      />
    );
  });
  return <group>{grid}</group>;
}
