enum WindowType {
  CONFIRMATION = 'CONFIRMATION',
  INSPECTION = 'INSPECTION',
  NOTIFICATIONS = 'NOTIFICATIONS',
  SCHEDULER = 'SCHEDULER',
  SETTINGS = 'SETTINGS',
  TREE = 'TREE',
  TOWER_WATER_CYCLE = 'TOWER_CYCLE',
  VIEW_FILTERS = 'VIEW_FILTERS',
}

export default WindowType;
