"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var LoggerName;

(function (LoggerName) {
  LoggerName["ANOTHER_LOGGER"] = "ANOTHER_LOGGER";
  LoggerName["CORE_LOGGER"] = "CORE_LOGGER";
  LoggerName["FARM_CONNECTION_LOGGER"] = "FARM_CONNECTION_LOGGER";
  LoggerName["FARM_INITIALIZER_LOGGER"] = "FARM_INITIALIZER_LOGGER";
  LoggerName["PAGE_CONNECTION_LOGGER"] = "PAGE_CONNECTION_LOGGER";
})(LoggerName || (LoggerName = {}));

exports.default = LoggerName;