import RegisteredArray, {
  Registration,
} from 'model/src/common/RegisteredArray';

export enum LogitechGamepadButtons {
  Button_A = 0,
  Button_B = 1,
  Button_X = 2,
  Button_Y = 3,
  Button_LB = 4,
  Button_RB = 5,
  Button_LT = 6,
  Button_RT = 7,
  Button_Back = 8,
  Button_Start = 9,
  LEFT_JOYSTICK_DOWN = 10,
  RIGHT_JOYSTICK_DOWN = 11,
  CONTROLLER_UP = 12,
  CONTROLLER_DOWN = 13,
  CONTROLLER_LEFT = 14,
  CONTROLLER_RIGHT = 15,
}

export enum LogitechGamepadAxes {
  AXIS_LEFT_X = 0,
  AXIS_LEFT_Y = 1,
  AXIS_RIGHT_X = 2,
  AXIS_RIGHT_Y = 3,
}

export default class GamepadManager {
  private connectedListeners: RegisteredArray<
    (gamepad) => void
  > = new RegisteredArray();

  constructor() {
    window.addEventListener('gamepadconnected', e => {
      this.connectedListeners.forEach(listener => {
        listener(e.gamepad);
      });
    });
  }

  addConnectedListener(connectedListener: (gamepad) => void): Registration {
    return this.connectedListeners.register(connectedListener);
  }
}
