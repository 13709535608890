"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NONE = exports.TUG_SIDE_LENGTH_CM = exports.GRID_NODE_INSIDE_LENGTH_CM = exports.GRID_NODE_SIDE_LENGTH_CM = exports.GRID_NODE_OFFSET_Y_CM = exports.GRID_NODE_OFFSET_X_CM = exports.GRID_NODE_OFFSET_PROCESSOR_Y_CM = exports.GRID_NODE_OFFSET_PROCESSOR_X_CM = void 0;
exports.GRID_NODE_OFFSET_PROCESSOR_X_CM = -134;
exports.GRID_NODE_OFFSET_PROCESSOR_Y_CM = 158;
exports.GRID_NODE_OFFSET_X_CM = -81.25;
exports.GRID_NODE_OFFSET_Y_CM = 906.25;
exports.GRID_NODE_SIDE_LENGTH_CM = 187.5;
exports.GRID_NODE_INSIDE_LENGTH_CM = 184.96;
exports.TUG_SIDE_LENGTH_CM = 125;
exports.NONE = 'NONE';