import {
  combine,
  create,
  fullString,
  toAddress,
  towerId,
} from 'model/src/common/CloudProduceAddressUtility';
import { SumpSubsystem, TowerSubsystem } from 'model/src/common/Systems';
import { SeriesType } from 'model/src/series/SeriesTypes';
import React from 'react';

import SumpData from '../../../../utopia/sump-data/SumpData';

const SumpInspectionPanel = props => {
  const pumpUrl = combine(toAddress(props.url), create([SumpSubsystem.PUMP]));

  const towerUrl = towerId(toAddress(props.url));
  const accumulationTimeS = props.dataMap.all.get(
    fullString(combine(pumpUrl, create([SeriesType.ACCUMULATION_TIME_S])))
  ).data[0].reading;
  const batteryLevelV = props.dataMap.all.get(
    fullString(
      combine(
        towerUrl,
        create([TowerSubsystem.BATTERY, SeriesType.BATTERY_LEVEL])
      )
    )
  ).data[0].reading;
  const batteryLevelLowFromLedConnector = props.dataMap.all.get(
    fullString(
      combine(
        pumpUrl,
        create([SeriesType.BATTERY_LEVEL_LOW_FROM_LED_CONNECTOR])
      )
    )
  ).data[0].reading;

  const batteryLevelLowFromRelay = props.dataMap.all.get(
    fullString(
      combine(pumpUrl, create([SeriesType.BATTERY_LEVEL_LOW_FROM_RELAY]))
    )
  ).data[0].reading;
  const egressFlowLM = props.dataMap.all.get(
    fullString(combine(pumpUrl, create([SeriesType.EGRESS_FLOW_LM])))
  ).data[0].reading;

  const egressFlow10SecondAverageLM = props.dataMap.all.get(
    fullString(
      combine(pumpUrl, create([SeriesType.EGRESS_FLOW_10_SECOND_AVERAGE_LM]))
    )
  ).data[0].reading;

  const egressFlowTotalLitersSinceReset = props.dataMap.all.get(
    fullString(
      combine(pumpUrl, create([SeriesType.EGRESS_TOTAL_LITERS_SINCE_RESET]))
    )
  ).data[0].reading;

  const ingressFlowLM = props.dataMap.all.get(
    fullString(combine(pumpUrl, create([SeriesType.INGRESS_FLOW_LM])))
  ).data[0].reading;

  const ingressFlow10SecondAverageLM = props.dataMap.all.get(
    fullString(
      combine(pumpUrl, create([SeriesType.INGRESS_FLOW_10_SECOND_AVERAGE_LM]))
    )
  ).data[0].reading;

  const ingressFlowTotalLitersSinceReset = props.dataMap.all.get(
    fullString(
      combine(pumpUrl, create([SeriesType.INGRESS_TOTAL_LITERS_SINCE_RESET]))
    )
  ).data[0].reading;

  const isPumpRunning = props.dataMap.all.get(
    fullString(combine(pumpUrl, create([SeriesType.IS_PUMP_RUNNING])))
  ).data[0].reading;

  const maxPwm = props.dataMap.all.get(
    fullString(combine(pumpUrl, create([SeriesType.MAX_PWM])))
  ).data[0].reading;

  const override = props.dataMap.all.get(
    fullString(combine(pumpUrl, create([SeriesType.OVERRIDE])))
  ).data[0].reading;

  const pumpStatus = props.dataMap.all.get(
    fullString(combine(pumpUrl, create([SeriesType.PUMP_STATUS])))
  ).data[0].reading;

  const pumpElectricCurrent = props.dataMap.all.get(
    fullString(combine(pumpUrl, create([SeriesType.CURRENT])))
  ).data[0].reading;

  const pwm = props.dataMap.all.get(
    fullString(combine(pumpUrl, create([SeriesType.PWM])))
  ).data[0].reading;

  const timeToEmpty = props.dataMap.all.get(
    fullString(combine(pumpUrl, create([SeriesType.TIME_TO_EMPTY])))
  ).data[0].reading;

  const upperLevelSwitch = props.dataMap.all.get(
    fullString(combine(pumpUrl, create([SeriesType.UPPER_LEVEL_SWITCH])))
  ).data[0].reading;

  const waterLevel = props.dataMap.all.get(
    fullString(combine(pumpUrl, create([SeriesType.WATER_LEVEL])))
  ).data[0].reading;
  return (
    <SumpData
      accumulationTimeS={accumulationTimeS}
      batteryLevelV={batteryLevelV}
      batteryLevelLowFromLedConnector={batteryLevelLowFromLedConnector}
      batteryLevelLowFromRelay={batteryLevelLowFromRelay}
      egressFlowLM={egressFlowLM}
      egressFlow10SecondAverageLM={egressFlow10SecondAverageLM}
      egressFlowTotalLitersSinceReset={egressFlowTotalLitersSinceReset}
      ingressFlowLM={ingressFlowLM}
      ingressFlow10SecondAverageLM={ingressFlow10SecondAverageLM}
      ingressFlowTotalLitersSinceReset={ingressFlowTotalLitersSinceReset}
      isPumpRunning={isPumpRunning}
      maxPwm={maxPwm}
      override={override}
      pumpElectricCurrent={pumpElectricCurrent}
      pumpStatus={pumpStatus}
      pwm={pwm}
      timeToEmpty={timeToEmpty}
      upperLevelSwitch={upperLevelSwitch}
      waterLevel={waterLevel}
    />
  );
};

export default SumpInspectionPanel;
