import React from 'react';

import PrimaryButton from '../window/components/PrimaryButton';
import SecondaryButton from '../window/components/SecondaryButton';
import {
  SchedulerModalOptionBox,
  SchedulerModalOptionContainer,
  SchedulerModalPanel,
  SchedulerModalTestSubmissionBox,
} from './SchedulerModalStyledComponents';

const EnableTimerTemplateModalPanel = (props: any) => {
  return (
    <SchedulerModalPanel>
      <SchedulerModalOptionContainer>
        <SchedulerModalOptionBox>
          {props.modal.timerTemplate.actionType}
        </SchedulerModalOptionBox>
      </SchedulerModalOptionContainer>
      <SchedulerModalTestSubmissionBox>
        <SecondaryButton
          onClick={() => {
            props.closeModal();
          }}>
          Cancel
        </SecondaryButton>
        <PrimaryButton
          onClick={() => {
            props.sendEnableTimerTemplateCommand(props.modal.timerTemplate.id);
            props.closeModal();
          }}>
          Enable
        </PrimaryButton>
      </SchedulerModalTestSubmissionBox>
    </SchedulerModalPanel>
  );
};

export default EnableTimerTemplateModalPanel;
