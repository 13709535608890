import { PermissionLevel } from 'model/src/user/UserProfile';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import ClassNames from '../../../ClassNames';
import {
  UserProfileContext,
  UserProfileContextType
} from '../../../user/UserProfileContext';

const ContextButtonStyling = styled.button`
  border: 0;
  opacity 1;
  line-height: var(--contextMenuButtonLineHeight);
  width: var(--contextMenuWidth);
  padding: var(--contextMenuButtonPadding);
  text-align: left;
  color: ${props =>
    props.writePermission
      ? `var(--dropdownMenuItemLabel_Default);`
      : `var(--systemGray);`}
  border-radius: var(--dropdownMenuBorderRadius);
  background: var(--dropdownMenuItemBackground_Default);

  &:hover {
    background: var(--dropdownMenuItemBackground_Hover);
    color: var(--dropdownMenuItemLabel_Hover);
  }

  &:active {
    background: var(--dropdownMenuItemBackground_Pressed);
    color: var(--dropdownMenuItemLabel_Pressed);
  }
`;

const ContextButton = props => {
  const userProfileContext: UserProfileContextType = useContext(
    UserProfileContext
  );
  const [writePermission, setWritePermission] = useState(
    userProfileContext.userProfile.permissionLevel === PermissionLevel.WRITE
  );

  useEffect(() => {
    setWritePermission(
      userProfileContext.userProfile.permissionLevel === PermissionLevel.WRITE
    );
  }, [userProfileContext]);

  return (
    <ContextButtonStyling
      {...props}
      onClick={e => {
        writePermission && props.onClick(e);
      }}
      writePermission={writePermission}
      className={ClassNames.labelRegular}
      onMouseEnter={() => {
        if (props.setOpenSubMenu) {
          props.setOpenSubMenu('');
        }
      }}>
      {props.children}
    </ContextButtonStyling>
  );
};

export default ContextButton;
