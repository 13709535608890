import {
  NavigationalIntent,
  NavigationalIntentType,
} from 'model/src/navigation/NavigationalIntent';
import React, { useState } from 'react';
import styled from 'styled-components';

import { Input } from '../../../neumorphic/Input';
import { Label } from '../../../neumorphic/Label';
import { OldNeumorphicButton } from '../../../neumorphic/OldNeumorphicButton';
import OldNeumorphicCheckBox from '../../../neumorphic/OldNeumorphicCheckBox';

const InputArea = styled.div`
  margin-top: 6px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  > button {
    margin-top: 32px;
    width: 100%;
  }

  > label {
    margin-top: 16px;
    margin-bottom: 8px;
    width: 100%;
  }
`;

export default function LiftControl(props) {
  const [position, setPosition] = useState(0);
  const [power, setPower] = useState(1.0);
  const [weight, setWeight] = useState(700);
  const [speed, setSpeed] = useState(0.5);
  const [shouldHome, setShouldHome] = useState(false);

  const postionInputHandleChange = event => {
    let value = event.target.value;
    if (value.length === 0) {
      value = '0';
    }
    setPosition(parseInt(value));
    event.stopPropagation();
    event.preventDefault();
  };

  const powerInputHandleChange = event => {
    let value = event.target.value;
    if (value.length === 0) {
      value = '0';
    }
    setPower(parseInt(value));
    event.stopPropagation();
    event.preventDefault();
  };

  const weightInputHandleChange = event => {
    let value = event.target.value;
    if (value.length === 0) {
      value = '0';
    }
    setWeight(parseInt(value));
    event.stopPropagation();
    event.preventDefault();
  };

  const speedInputHandleChange = event => {
    let value = event.target.value;
    if (value.length === 0) {
      value = '0';
    }
    setSpeed(parseInt(value));
    event.stopPropagation();
    event.preventDefault();
  };

  const handleShouldHomeChanged = event => {
    setShouldHome(event.checked);
  };

  return (
    <InputArea>
      <Label>Position: </Label>
      <Input value={position} onChange={postionInputHandleChange} />
      <Label>Power: </Label>
      <Input value={power} onChange={powerInputHandleChange} />
      <Label>Weight: </Label>
      <Input value={weight} onChange={weightInputHandleChange} />
      <Label>Speed: </Label>
      <Input value={speed} onChange={speedInputHandleChange} />
      <Label> Should Home: </Label>
      <OldNeumorphicCheckBox
        checked={shouldHome}
        onChange={handleShouldHomeChanged}
      />
      <OldNeumorphicButton
        onClick={() => {
          props.onSubmit(position, power, weight, speed, shouldHome);
        }}>
        Lift
      </OldNeumorphicButton>
      <OldNeumorphicButton
        onClick={() => {
          const intent: NavigationalIntent = {
            type: NavigationalIntentType.CENTER_TOP,
            speedMps: 0.1,
            distance: 10,
          };
          props.sendNavigateCommand(intent);
        }}>
        Center Top
      </OldNeumorphicButton>
      <OldNeumorphicButton
        onClick={() => {
          const intent: NavigationalIntent = {
            type: NavigationalIntentType.CENTER,
            speedMps: 0.1,
            distance: 10,
          };
          props.sendNavigateCommand(intent);
        }}>
        Center
      </OldNeumorphicButton>
    </InputArea>
  );
}
