import styled from 'styled-components';

export const Button = styled.button`
  cursor: pointer;
  background: transparent;
  font-family: jura;
  font-size: 16px;
  border-radius: 3px;
  box-sizing: 'border-box';
  color: ${props => props.color};
  border: ${props =>
    '1px solid ' + (props.showBorder ? props.color : 'transparent')};
  padding: 0.25em 1em;
  transition: 0.5s all ease-out;

  &:hover {
    color: black;
    background-color: ${props => props.color};
  }

  &:disabled {
    color: black;
    border: ${props =>
      '1px solid ' + (props.showBorder ? props.disabledColor : 'transparent')};
    background-color: ${props => props.disabledColor};
  }
`;
