/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import F34_6Way_Model from './F34_6Way.glb';

export default function F34_6Way({ ...props }) {
  const group = useRef()
  const { nodes } = useGLTF(F34_6Way_Model)
  return (
    <group ref={group} {...props} dispose={null} position={props.position} rotation={props.rotation}>
      <mesh geometry={nodes.mesh_0.geometry} >
        <meshPhongMaterial attach="material" color={props.color} transparent={props.transparent} opacity={props.opacity} />
      </mesh>
    </group>
  )
}

F34_6Way.defaultProps = {
  position: [0, 0, 0],
  rotation: [0, 0, 0],
  transparent:false,
  opacity:1.0,
}

useGLTF.preload(F34_6Way_Model)
