import React from 'react';
import {
  NODE_WIDTH_MM,
  FARM_LINE_WIDTH_MM,
} from 'model/src/farm/entities/trolley/physical/PhysicalSize';
import { toRgbaString } from 'model/src/common/Color';
import { THEME } from '../../../globe/Theme';

type Props = {
  coordinates: { x: number; y: number };
};

const SvgPathNode = (props: Props) => {
  return (
    <rect
      x={props.coordinates.x - NODE_WIDTH_MM / 16}
      y={props.coordinates.y - NODE_WIDTH_MM / 16}
      width={NODE_WIDTH_MM / 8}
      height={NODE_WIDTH_MM / 8}
      stroke={toRgbaString(THEME.blues[0])}
      strokeWidth={2 * FARM_LINE_WIDTH_MM}
      fill={'transparent'}
    />
  );
};
export default SvgPathNode;
