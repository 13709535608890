"use strict";

var _classCallCheck = require("/usr/src/app/packages/commandcenter/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Ping = void 0;

var Ping = function Ping() {
  _classCallCheck(this, Ping);
};

exports.Ping = Ping;