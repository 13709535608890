import React from 'react';

import { cssVar } from '../..';

const InspectionDataDivider = () => {
  return (
    <div
      style={{
        paddingTop: cssVar('--dropdownMenuItemIconPadding'),
        paddingBottom: cssVar('--dropdownMenuItemIconPadding'),
      }}>
      {' '}
      <div
        style={{
          height: 0,
          border: cssVar('--dropdownMenuItemDivider'),
        }}
      />
    </div>
  );
};

export default InspectionDataDivider;
