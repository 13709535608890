import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DataMap } from 'services/src/pagedataintake/PageDataIntake';
import PageWebSocketClient from 'services/src/pagedataintake/PageWebSocketClient';
import PageKernel from 'services/src/pageKernel/PageKernel';
import styled from 'styled-components';
import * as THREE from 'three';
import { useInterval } from 'usehooks-ts';

import { SITE_BUILD_VERSION_NUMBER } from '../../environment/environment';
import Logo from '../../logo.svg';
import DesktopController from '../../utopia/desktop/DesktopController';
import ModelViewer, { CameraAnimation } from '../ModelViewer';
import {
  ControllerContextType,
  HydratedOverridingRestriction,
  OverridingRestriction,
} from './context/controllercontext/ControllerContext';
import ControllerContextProvider from './context/controllercontext/ControllerContextProvider';
import { DesktopContextType } from './context/desktopcontext/DesktopContext';
import DesktopContextProvider from './context/desktopcontext/DesktopContextProvider';
import OperationContextProvider from './context/operationcontext/OperationContextProvider';
import PathContextProvider from './context/pathcontext/PathContextProvider';
import ContextMenu from './ContextMenu';
import {
  buildCentroidMap,
  buildInspectionMap,
  buildWindowPositionsMap,
  Centroid,
  InspectionNode,
  inspectionNodeHandler,
  inspectNode,
  uninspectAllNodes,
  uninspectNode,
  WindowPosition,
} from './InspectionNodeUtility';
import { defaultMovementKeys, Keys } from './MovementKeys';
import SvgPanel from './tools/SvgPanel';
import { ViewCubeContainer } from './viewcube/ViewCubeContainer';

export const FarmContainer = styled.div`
  box-sizing: border-box;
  height: 100%;
  position: relative;
`;

export const Header = styled.div`
  position: absolute;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  background-color: transparent;
  padding: 2px;
  z-index: 2;
`;
export const VersionHeader = styled.div`
  position: absolute;
  background-color: transparent;
  color: var(--black);
  padding: 2px;
  padding-left: 30px;
  z-index: 2;
`;

const RenderWorkspace = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const DragFrame = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  pointer-events: none;
  overflow: hidden;
`;

export type InspectionContext = {
  inspectionMap: Map<string, InspectionNode>;
  flattenedInspectionMap: Map<string, InspectionNode>;
  windowPositionsMap: Map<string, WindowPosition>;
  centroidMap: Map<string, Centroid>;
};

export enum TargetLockState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

const movementKeys = defaultMovementKeys;

type FarmViewerProps = {
  data: any;
  connectionToServer: PageWebSocketClient;
  pageKernel: PageKernel;
};

const FarmViewer = (props: FarmViewerProps) => {
  const container = useRef<HTMLInputElement>();
  const sceneNodeClicked = useRef(false);
  const orbited = useRef(0);
  const mousePosition = useRef({ x: 0, y: 0 });
  const screenSpacePanning = useRef(false);
  const targetForwardDirection = useRef(false);
  const cameraMovementRequests = useRef(new Set<string>());
  const targetLockState = useRef<TargetLockState>(TargetLockState.INACTIVE);
  const [cameraMatrix, setCameraMatrix] = useState<THREE.Matrix4>(
    new THREE.Matrix4()
  );
  const [viewCubeRotation, setViewCubeRotation] = useState<THREE.Spherical>(
    new THREE.Spherical()
  );
  const [
    cameraAnimation,
    setCameraAnimation,
  ] = useState<CameraAnimation | null>(null);

  const [keys, setKeys] = useState<Set<any>>(new Set());
  const [
    hydratedOverridingRestriction,
    setHydratedOverridingRestriction,
  ] = useState<HydratedOverridingRestriction | null>(null);
  const [isCameraControlsEnabled, setIsCameraControlsEnabled] = useState(true);

  const select = (
    url: string,
    controllerContext: ControllerContextType,
    desktopContext: DesktopContextType,
    operation?
  ) => {
    if (
      controllerContext.baseRestriction.cameraSettings &&
      controllerContext.baseRestriction.cameraSettings.setScene
    ) {
      if (operation) {
        desktopContext.updateSelectedUrl(url + operation, desktopContext);
        controllerContext.baseRestriction.cameraSettings.setScene(
          url,
          operation,
          controllerContext
        );
      } else {
        desktopContext.updateSelectedUrl(url, desktopContext);
        controllerContext.baseRestriction.cameraSettings.setScene(
          url,
          '',
          controllerContext
        );
      }
    }
  };

  //TODO(austin): merge this inspection section with InspectionNodeUtility and make it a component inside desktopController
  // (maybe exclude ObjectMap)
  const inspectionMap = useMemo(() => {
    return buildInspectionMap(props.data);
  }, [props.data]);
  const windowPositionsMap = useMemo(() => {
    return buildWindowPositionsMap(inspectionMap);
  }, [props.data]);
  const centroidMap = useMemo(() => {
    return buildCentroidMap(inspectionMap);
  }, [props.data]);
  const objectMap = useMemo(() => {
    return buildObjectMap(centroidMap);
  }, [props.data]);
  const [inspectionContext, setInspectionContext] = useState<InspectionContext>(
    {
      inspectionMap: inspectionMap,
      flattenedInspectionMap: new Map<string, InspectionNode>(),
      windowPositionsMap: windowPositionsMap,
      centroidMap: centroidMap,
    }
  );
  const inspect = (url: string, desktopContext: DesktopContextType) => {
    inspectNode(
      url,
      desktopContext,
      inspectionContext,
      inspectionMap,
      setInspectionContext,
      setWindowPosition,
      dataMap,
      uninspect
    );
  };
  const uninspectAll = (desktopContext: DesktopContextType) => {
    uninspectAllNodes(
      desktopContext,
      inspectionContext,
      setInspectionContext,
      inspectionMap
    );
  };
  const uninspect = (url: string, desktopContext: DesktopContextType) => {
    uninspectNode(
      url,
      desktopContext,
      inspectionContext,
      setInspectionContext,
      inspectionMap
    );
  };
  const inspectionHandler = (url, desktopContext: DesktopContextType) => {
    inspectionNodeHandler(
      url,
      desktopContext,
      inspectionContext,
      sceneNodeClicked,
      uninspect,
      inspect
    );
  };

  const contextMenuDataRef = useRef<any>();
  const contextMenuEvents = {
    inspect: inspect,
    uninspect: uninspect,
    uninspectAll: uninspectAll,
    select: select,
  };
  const closeHandler = () => {
    if (container.current) {
      container.current.focus();
    }
  };

  useInterval(() => {
    setDataMap({ ...props.pageKernel.getDataMap() });
  }, 500);

  const [dataMap, setDataMap] = useState<DataMap>(
    props.pageKernel.getDataMap()
  );

  const onKeyDown = event => {
    const hasFocus = document.activeElement === container.current;
    if (hasFocus) {
      keys.add(event.key);
      setKeys(new Set(keys.values()));

      if (event.key === Keys.a) {
        // setIsCameraControlsEnabled(false);
      }

      if (event.key === Keys.b) {
        const animation: CameraAnimation = {
          position: new THREE.Vector3(100, 100, 100),
          target: new THREE.Vector3(0, 300, 0),
          duration: 1000,
        };
        setCameraAnimation(animation);
      }

      if (event.key === movementKeys.targetLock) {
        targetLockState.current = TargetLockState.ACTIVE;
      }

      if (event.key == movementKeys.creativeMode) {
        targetForwardDirection.current = true;
      }

      if (event.key == movementKeys.survivalMode) {
        targetForwardDirection.current = false;
      }

      if (event.key == movementKeys.mapPanToggle) {
        screenSpacePanning.current = !screenSpacePanning.current;
      }

      if (event.key === Keys.Escape) {
        // uninspectAll(); //TODO(austin): re-implement escape key with a desktopContext reference
        contextMenuDataRef.current &&
          contextMenuDataRef.current.hideContextMenu();
        setIsCameraControlsEnabled(true);
        setHydratedOverridingRestriction(null);
      }

      if (Object.values(defaultMovementKeys).includes(event.key)) {
        cameraMovementRequests.current.add(event.key);
      }
    }
  };

  const onKeyUp = event => {
    const hasFocus = document.activeElement === container.current;
    if (hasFocus) {
      keys.delete(event.key);
      setKeys(new Set(keys.values()));

      if (event.key === movementKeys.targetLock) {
        targetLockState.current = TargetLockState.INACTIVE;
      }

      if (Object.values(defaultMovementKeys).includes(event.key)) {
        cameraMovementRequests.current.delete(event.key);
      }
    }
  };

  const onMouseMove = event => {
    mousePosition.current.x = event.pageX;
    mousePosition.current.y = event.pageY;
  };

  const onCanvasClick = event => {
    if (contextMenuDataRef.current && contextMenuDataRef.current.shouldShow) {
      contextMenuDataRef.current.hideContextMenu();
    }
  };

  const updateCentroidMap = () => {
    setInspectionContext({
      inspectionMap: inspectionContext.inspectionMap,
      flattenedInspectionMap: inspectionContext.flattenedInspectionMap,
      windowPositionsMap: windowPositionsMap,
      centroidMap: inspectionContext.centroidMap,
    });
  };

  const setWindowPosition = ({ url, x, y }) => {
    windowPositionsMap.set(url, { url, x, y });
    setInspectionContext({
      inspectionMap: inspectionContext.inspectionMap,
      flattenedInspectionMap: inspectionContext.flattenedInspectionMap,
      windowPositionsMap: windowPositionsMap,
      centroidMap: inspectionContext.centroidMap,
    });
  };

  const onBackgroundClicked = desktopContext => {
    if (!sceneNodeClicked.current && orbited.current == 0) {
      uninspectAll(desktopContext);
    } else {
      sceneNodeClicked.current = false;
      orbited.current = 0;
    }
  };
  const applyOverridingRestriction = (
    url: string,
    overridingRestriction: OverridingRestriction
  ) => {
    setHydratedOverridingRestriction(
      hydrateOverridingRestriction(url, overridingRestriction)
    );
    if (contextMenuDataRef) {
      contextMenuDataRef.current.hideContextMenu();
    }
  };

  const hydrateUrl = (url: string, template: string): string => {
    let urlSegments = url.split('.');
    const templateSegments = template.split('.');
    let templateIndex = 0;
    for (
      templateIndex = 0;
      templateIndex < templateSegments.length;
      templateIndex++
    ) {
      if (templateSegments[templateIndex] === '_') {
        // Do nothing and move on
      } else if (templateSegments[templateIndex] === '^') {
        urlSegments.pop();
      } else {
        urlSegments.push(templateSegments[templateIndex]);
      }
    }
    return urlSegments.join('.');
  };

  const hydrateOverridingRestriction = (
    url: string,
    overridingRestriction: OverridingRestriction
  ): HydratedOverridingRestriction => {
    const hydratedFocusUrl = hydrateUrl(
      url,
      overridingRestriction.cameraRestriction.focus
    );
    const hydratedProperties = new Map<string, any>([]);

    const focussedModel = objectMap.get(hydratedFocusUrl)!.model;
    const focussedModelBoundingBox = new THREE.Box3().setFromObject(
      focussedModel
    );
    const hydratedFocussedModelTarget = new THREE.Vector3(0, 0, 0);
    focussedModelBoundingBox.getCenter(hydratedFocussedModelTarget);

    const hydratedCameraPostion = new THREE.Vector3(
      hydratedFocussedModelTarget.x,
      hydratedFocussedModelTarget.y,
      hydratedFocussedModelTarget.z
    );
    hydratedCameraPostion.x += overridingRestriction.cameraRestriction.offset.x;
    hydratedCameraPostion.y += overridingRestriction.cameraRestriction.offset.y;
    hydratedCameraPostion.z += overridingRestriction.cameraRestriction.offset.z;

    overridingRestriction.propertyMap.forEach((value, key) => {
      const hydratedPropertyUrl = hydrateUrl(url, key);
      hydratedProperties.set(hydratedPropertyUrl, value);
    });

    const restriction: HydratedOverridingRestriction = {
      baseUrl: url,
      cameraRestriction: {
        cameraLockType: overridingRestriction.cameraRestriction.cameraLockType,
        projectionType: overridingRestriction.cameraRestriction.projectionType,
        position: hydratedCameraPostion,
        target: hydratedFocussedModelTarget,
      },
      propertyMap: hydratedProperties,
    };
    return restriction;
  };

  const getParentUrl = contextMenuData => {
    return contextMenuData.url.replace(
      new RegExp(
        '.' +
          contextMenuData.url
            .split('.')
            .slice(contextMenuData.url.split('.').length - 2)
            .join('.') +
          '$'
      ),
      ''
    );
  };
  return (
    <FarmContainer
      ref={container}
      tabIndex={0}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onMouseMove={onMouseMove}>
      <Header>
        <img
          src={Logo}
          style={{ height: 19, width: 19, color: 'red', padding: 2 }}
          alt="website logo"
        />
      </Header>
      <VersionHeader>{SITE_BUILD_VERSION_NUMBER}</VersionHeader>
      <DesktopContextProvider
        dataMap={dataMap}
        selectedUrl={props.data.farm.id}
        select={select}>
        <PathContextProvider
          dataMap={dataMap}
          inspectionContext={inspectionContext}>
          <ControllerContextProvider
            keys={keys}
            hydratedOverridingRestriction={hydratedOverridingRestriction}>
            <OperationContextProvider>
              <RenderWorkspace onMouseDown={onCanvasClick}>
                <SvgPanel
                  inspectionContext={inspectionContext}
                  orbited={orbited.current}
                />
                <ModelViewer
                  targetForwardDirection={targetForwardDirection}
                  targetLockState={targetLockState}
                  cameraAnimation={cameraAnimation}
                  screenSpacePanning={screenSpacePanning}
                  setCameraMatrix={setCameraMatrix}
                  viewCubeRotation={viewCubeRotation}
                  isControlsEnabled={isCameraControlsEnabled}
                  cameraMovementRequests={cameraMovementRequests}
                  dataMap={dataMap}
                  objectMap={objectMap}
                  inspectionContext={inspectionContext}
                  onClick={inspectionHandler}
                  showMenu={
                    contextMenuDataRef.current
                      ? contextMenuDataRef.current.showContextMenu
                      : null
                  }
                  onBackgroundClicked={onBackgroundClicked}
                  connectionToServer={props.connectionToServer}
                  orbited={() => {
                    const newOrbited = orbited.current + 1;
                    orbited.current = newOrbited;
                    updateCentroidMap();
                  }}
                />
              </RenderWorkspace>

              <DragFrame>
                <DesktopController
                  dataMap={dataMap}
                  closeHandler={closeHandler}
                  select={select}
                  inspectionMap={inspectionMap}
                  setInspectionWindowPosition={setWindowPosition}
                  connectionToServer={props.connectionToServer}
                  showContextMenu={
                    contextMenuDataRef.current
                      ? contextMenuDataRef.current.showContextMenu
                      : null
                  }
                  hideContextMenu={
                    contextMenuDataRef.current
                      ? contextMenuDataRef.current.hideContextMenu
                      : null
                  }
                />
                {/* <DraggableWindow
              url={'floorTools'}
              title='Floor Tools'
              onDrag={(e, dragData) => {}}
              initialWindowPosition={{
                x: 100,
                y: 100,
              }}>
              <FloorTools connectionToServer={props.connectionToServer} />
            </UtopiaDraggableWindow> */}
                {/* <DraggableWindow
              url={'Farm Data'}
              onDrag={(e, dragData) => {}}
              initialWindowPosition={{
                x: 120,
                y: 120,
              }}>
              <FarmDataView dataMap={dataMap} />
            </DraggableWindow> */}
                {/* <ExampleUtopiaDraggableWindow/> */}
                {/* {inspectionWindows} */}
              </DragFrame>
              <ViewCubeContainer
                cameraMatrix={cameraMatrix}
                setViewCubeRotation={setViewCubeRotation}
                select={select}
              />
              <ContextMenu
                applyOverridingRestriction={applyOverridingRestriction}
                connectionToServer={props.connectionToServer}
                objectMap={objectMap}
                dataMap={dataMap}
                events={contextMenuEvents}
                contextMenuData={contextMenuDataRef}
                mousePosition={mousePosition}
              />
            </OperationContextProvider>
          </ControllerContextProvider>
        </PathContextProvider>
      </DesktopContextProvider>
    </FarmContainer>
  );
};

export default FarmViewer;

function reportUnknownDatum(
  dataMap: {
    all: Map<string, any>;
    tugMap: Map<string, any>;
    floorMap: Map<string, any>;
    superBeaconMap: Map<string, any>;
    fishTankMap: Map<string, any>;
    towerMap: Map<string, any>;
    tierMap: Map<string, any>;
  },
  id: string
) {
  let keys = '';
  for (const key in dataMap.tugMap.keys) {
    keys += key + '\n';
  }
}

type Model = {
  url: string;
  model: THREE.Object3d;
};

function buildObjectMap(
  centroidMap: Map<string, { url: string }>
): Map<string, Model> {
  const objectMap = new Map<string, Model>();
  centroidMap.forEach((centroid: { url: string }) => {
    objectMap.set(centroid.url, {
      url: centroid.url,
      model: undefined,
    });
  });
  return objectMap;
}
