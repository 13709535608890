import React from 'react';

import CommandCenterLongBeam from './CommandCenterLongBeam';
import CommandCenterPillar from './CommandCenterPillar';
import CommandCenterShortBeam from './CommandCenterShortBeam';
import CommandCenterWall from './CommandCenterWall';

export default function CommandCenterSupport(props) {
  return (
    <group position={props.position} rotation={props.rotation}>
      <CommandCenterWall
        position={[200, 2562.5, 0]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />
      <CommandCenterWall
        position={[50, 2712.5, 0]}
        rotation={[0, 0, Math.PI / 2]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />

      <CommandCenterPillar
        position={[387.5, 2900, 0]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />
      <CommandCenterPillar
        position={[387.5, 3025, 0]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />

      <CommandCenterLongBeam
        position={[75, 2900, 200.635]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />
      <CommandCenterLongBeam
        position={[200 + 187.5, 2562.5 + 25, 200.635]}
        rotation={[0, 0, Math.PI / 2]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />

      <CommandCenterShortBeam
        position={[200 + 187.5, 2900.5 + 25, 200.635]}
        rotation={[0, 0, Math.PI / 2]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />
    </group>
  );
}

CommandCenterSupport.defaultProps = {
  position: [0, 0, 0],
  rotation: [0, 0, 0],
};
