import { CloudProduceAddress } from 'model/src/common/CloudProduceAddress';
import {
  combine,
  create,
  fullString,
  join,
  toAddress,
} from 'model/src/common/CloudProduceAddressUtility';
import { FarmSubsystem, TowerSubsystem } from 'model/src/common/Systems';
import { SeriesType } from 'model/src/series/SeriesTypes';
import React, { useMemo } from 'react';
import { DataMap } from 'services/src/pagedataintake/PageDataIntake';
import styled from 'styled-components';

const ButtonStyling = styled.div`
  position: relative;
  display: inline-flex;
  width: 200px;
  padding-top: var(--spacingMini);
  padding-bottom: var(--spacingMini);
  margin-left: calc(-1 * var(--borderSize));
  margin-right: calc(-1 * var(--borderSize));
  justify-content: center;
  align-items: center;
  border: ${props =>
    props.isOpen
      ? 'var(--borderSize) solid var(--dropdownStrokeActive)'
      : 'var(--borderSize) solid var(--dropdownStrokeColorDefault)'};
  border-radius: var(--schedulerButtonBorderRadius);
  background: var(--dropdownFillColorDefault);

  &:active {
    border: var(--borderSize) solid var(--dropdownStrokeActive);
  }

  &:disabled {
  }
`;

const Button = props => {
  return (
    <ButtonStyling
      onClick={e => {
        props.toggle();
        e.stopPropagation();
      }}
      enabled={props.enabled}
      disabled={!props.enabled}
      active={props.isOpen}
      isOpen={props.isOpen}>
      {props.text}
    </ButtonStyling>
  );
};

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: var(--spacingMedium);
  gap: var(--spacingMedium);
`;

const SchedulerDropdownMenuStyling = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacingMedium);
`;

const DropdownExpandedMenu = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: inherit;
  border-radius: var(--borderRadiusMedium);
  background-color: var(--dropdownFillDefault);
  border: var(--borderSize) solid var(--dropdownStrokeColorDefault);
  margin-left: var(--dropdownMenuBorderOffset);
`;

const SelectionMenuContainer = styled.div`
  position: absolute;
  z-index: 101;
  width: 200px;
`;

const Option = styled.div`
  display: block;
  width: inherit;
  text-align: center;
  padding: 4px 0px;
  border-bottom: ${props =>
    props.isLast
      ? '0px'
      : 'var(--borderSize) solid var(--dropdownStrokeColorDefault)'};
`;

const SelectionButton = props => {
  return (
    <div>
      <Button toggle={props.toggle} text={props.text} isOpen={props.isOpen} />
      {props.isOpen && props.children}
    </div>
  );
};

const StackDropdownOptions = props => {
  const stackInstances = useMemo(
    () =>
      getDeviceInstances(
        create([props.currentFarm, FarmSubsystem.STACK]),
        props.dataMap
      ),
    [props.currentFarm]
  );
  return (
    <SelectionMenuContainer>
      {stackInstances && (
        <DropdownExpandedMenu>
          {props.selectedStack !== '' && (
            <Option
              key={'Clear'}
              onClick={e => {
                props.select('');
                props.close();
                e.stopPropagation();
              }}
              isLast={false}>
              {'Clear Stack'}
            </Option>
          )}
          {stackInstances.map((stackId, index) => (
            <Option
              key={stackId}
              onClick={e => {
                props.select(stackId);
                props.close();
                e.stopPropagation();
              }}
              isLast={index === stackInstances.length - 1}>
              {stackId}
            </Option>
          ))}
        </DropdownExpandedMenu>
      )}
    </SelectionMenuContainer>
  );
};

const TowerDropdownOptions = props => {
  const towerInstances = useMemo(
    () =>
      getDeviceInstances(
        create([props.currentFarm, FarmSubsystem.TOWER]),
        props.dataMap
      ),
    [props.currentFarm]
  );
  return (
    <SelectionMenuContainer>
      {towerInstances && (
        <DropdownExpandedMenu>
          {props.selectedTower !== '' && (
            <Option
              key={'Clear'}
              onClick={e => {
                props.select('');
                props.close();
                e.stopPropagation();
              }}
              isLast={false}>
              {'Clear Tower'}
            </Option>
          )}
          {towerInstances.map((towerId, index) => (
            <Option
              key={towerId}
              onClick={e => {
                props.select(towerId);
                props.close();
                e.stopPropagation();
              }}
              isLast={index === towerInstances.length - 1}>
              {towerId}
            </Option>
          ))}
        </DropdownExpandedMenu>
      )}
    </SelectionMenuContainer>
  );
};

const TierDropdownOptions = props => {
  let tierInstances;
  if (props.selectedTower) {
    tierInstances = getDeviceInstances(
      join(toAddress(props.selectedTower), TowerSubsystem.TIER),
      props.dataMap
    );
  } else {
    tierInstances = [];
  }

  return (
    <SelectionMenuContainer>
      {tierInstances && (
        <DropdownExpandedMenu>
          {props.selectedTier !== '' && (
            <Option
              key={'clear'}
              onClick={e => {
                props.select('');
                props.close();
                e.stopPropagation();
              }}
              isLast={false}>
              {'Clear Tier'}
            </Option>
          )}
          {tierInstances.map((tierId, index) => (
            <Option
              key={tierId}
              onClick={e => {
                props.select(tierId);
                props.close();
                e.stopPropagation();
              }}
              isLast={index === tierInstances.length - 1}>
              {tierId}
            </Option>
          ))}
        </DropdownExpandedMenu>
      )}
    </SelectionMenuContainer>
  );
};

const TugDropdownOptions = props => {
  const TugInstances = useMemo(
    () =>
      getDeviceInstances(
        create([props.currentFarm, FarmSubsystem.TUG]),
        props.dataMap
      ),
    [props.currentFarm]
  );
  return (
    <SelectionMenuContainer>
      {TugInstances && (
        <DropdownExpandedMenu>
          {props.selectedTug !== '' && (
            <Option
              key={'Clear'}
              onClick={e => {
                props.select('');
                props.close();
                e.stopPropagation();
              }}
              isLast={false}>
              {'Clear Tug'}
            </Option>
          )}
          {TugInstances.map((stackId, index) => (
            <Option
              key={stackId}
              onClick={e => {
                props.select(stackId);
                props.close();
                e.stopPropagation();
              }}
              isLast={index === TugInstances.length - 1}>
              {stackId}
            </Option>
          ))}
        </DropdownExpandedMenu>
      )}
    </SelectionMenuContainer>
  );
};

const getDeviceInstances = (
  baseDeviceUrl: CloudProduceAddress,
  dataMap: DataMap
) => {
  const instances: string[] = [];
  let count = 1;
  while (
    dataMap.all.get(
      fullString(
        combine(baseDeviceUrl, create([count.toString(), SeriesType.NAME]))
      )
    )
  ) {
    instances.push(fullString(join(baseDeviceUrl, count.toString())));
    count++;
  }
  return instances;
};

const SchedulerDropdownMenu = props => {
  const openStackMenu = () => {
    closeTierMenu();
    closeTowerMenu();
    closeTugMenu();
    props.setStackMenuIsOpen(true);
  };
  const openTierMenu = () => {
    closeStackMenu();
    closeTowerMenu();
    closeTugMenu();
    props.setTierMenuIsOpen(true);
  };
  const openTowerMenu = () => {
    closeStackMenu();
    closeTierMenu();
    closeTugMenu();
    props.setTowerMenuIsOpen(true);
  };
  const openTugMenu = () => {
    closeStackMenu();
    closeTierMenu();
    closeTowerMenu();
    props.setTugMenuIsOpen(true);
  };

  const closeStackMenu = () => props.setStackMenuIsOpen(false);
  const closeTierMenu = () => props.setTierMenuIsOpen(false);
  const closeTowerMenu = () => props.setTowerMenuIsOpen(false);
  const closeTugMenu = () => props.setTugMenuIsOpen(false);

  const toggleStackMenu = () => props.setStackMenuIsOpen(value => !value);
  const toggleTierMenu = () => props.setTierMenuIsOpen(value => !value);
  const toggleTowerMenu = () => props.setTowerMenuIsOpen(value => !value);
  const toggleTugMenu = () => props.setTugMenuIsOpen(value => !value);

  const tierLabel = props.selectedTier ? props.selectedTier : '- Select Tier -';
  const stackLabel = props.selectedStack
    ? props.selectedStack
    : '- Select Stack -';
  const tugLabel = props.selectedTug ? props.selectedTug : '- Select Tug -';
  const towerLabel = props.selectedTower
    ? props.selectedTower
    : '- Select Tower -';

  return (
    <MenuContainer
      onClick={() => {
        closeTierMenu();
        closeTowerMenu();
      }}>
      <SchedulerDropdownMenuStyling>
        <SelectionButton
          isOpen={props.towerMenuIsOpen}
          toggle={() => {
            !props.towerMenuIsOpen && closeTierMenu();
            toggleTowerMenu();
          }}
          text={towerLabel}>
          <TowerDropdownOptions
            select={id => {
              props.setSelectedTier('');
              props.setSelectedTug('');
              props.setSelectedStack('');
              props.setSelectedTower(id);
            }}
            selectedTower={props.selectedTower}
            close={closeTowerMenu}
            closeTierMenu={closeTierMenu}
            open={() => {
              openTowerMenu();
            }}
            currentFarm={props.currentFarm}
            dataMap={props.dataMap}
          />
        </SelectionButton>
        <SelectionButton
          isOpen={props.tierMenuIsOpen}
          toggle={() => {
            props.selectedTower !== '' && toggleTierMenu();
          }}
          text={tierLabel}>
          <TierDropdownOptions
            currentFarm={props.currentFarm}
            dataMap={props.dataMap}
            select={id => {
              props.setSelectedStack('');
              props.setSelectedTug('');
              props.setSelectedTier(id);
            }}
            open={openTierMenu}
            close={closeTierMenu}
            selectedTier={props.selectedTier}
            selectedTower={props.selectedTower}
          />
        </SelectionButton>
      </SchedulerDropdownMenuStyling>
      <SchedulerDropdownMenuStyling>
        <SelectionButton
          isOpen={props.stackMenuIsOpen}
          toggle={toggleStackMenu}
          text={stackLabel}>
          <StackDropdownOptions
            select={id => {
              props.setSelectedTier('');
              props.setSelectedTug('');
              props.setSelectedTower('');
              props.setSelectedStack(id);
            }}
            selectedStack={props.selectedStack}
            close={closeStackMenu}
            open={openStackMenu}
            currentFarm={props.currentFarm}
            dataMap={props.dataMap}
          />
        </SelectionButton>
        <SelectionButton
          isOpen={props.tugMenuIsOpen}
          toggle={toggleTugMenu}
          text={tugLabel}>
          <TugDropdownOptions
            select={id => {
              props.setSelectedTier('');
              props.setSelectedStack('');
              props.setSelectedTower('');
              props.setSelectedTug(id);
            }}
            selectedTug={props.selectedTug}
            close={closeTugMenu}
            open={openTugMenu}
            currentFarm={props.currentFarm}
            dataMap={props.dataMap}
          />
        </SelectionButton>
      </SchedulerDropdownMenuStyling>
    </MenuContainer>
  );
};

export default SchedulerDropdownMenu;
