import _ from 'lodash';
import { CloudProduceAddress } from 'model/src/common/CloudProduceAddress';
import {
  combine,
  create,
  fullString,
  join,
  toAddress,
} from 'model/src/common/CloudProduceAddressUtility';
import { Operations, RegexPhrases } from 'model/src/common/Regex';
import { TowerSubsystem } from 'model/src/common/Systems';
import { PageToPageServer } from 'model/src/dataflowprotocol/pagetopageserver/PageToPageServer';
import { PageToPageServerType } from 'model/src/dataflowprotocol/pagetopageserver/PageToPageServerType';
import React, { useContext } from 'react';
import { getLoggerMapFromDataMap } from 'services/src/logging/LoggerMapUtility';

import { ConfirmationBoxWindowProps } from '../../farm-ui/windows/ConfirmationBoxWindow';
import {
  ControllerContext,
  ControllerContextType,
} from '../../three/farmviewer/context/controllercontext/ControllerContext';
import {
  DesktopContext,
  DesktopContextType,
} from '../../three/farmviewer/context/desktopcontext/DesktopContext';
import {
  OperationContext,
  OperationContextType,
} from '../../three/farmviewer/context/operationcontext/OperationContext';
import WindowType from '../window/WindowTypes';
import ContextButton from './button/ContextButton';
import RaspberryPiFirmwareContextButton from './button/RaspberryPiFirmwareContextButton';
import SubMenuButton from './button/SubMenuButton';
import Divider from './Divider';

const MAX_TIER_X = 1100;
const MAX_TIER_Y = 1175;
const MAX_TIER_Z = -312;

enum TierSubCategories {
  GANTRY = 'GANTRY',
  INSPECT = 'INSPECT',
  SELECT = 'SELECT',
}

export default function TierContextMenu(props) {
  const controllerContext: ControllerContextType = useContext(
    ControllerContext
  );
  const desktopContext: DesktopContextType = useContext(DesktopContext);
  const operationContext: OperationContextType = useContext(OperationContext);

  const placeTower = RegexPhrases.OPERATION + Operations.PLACEMENT;

  const baseTowerUrl = toAddress(props.parentUrl);
  const baseTierUrl = toAddress(props.url);

  const baseTierLoggersUrl: CloudProduceAddress = combine(
    baseTierUrl,
    create(['LOGGERS'])
  );

  const loggerMap = getLoggerMapFromDataMap(baseTierLoggersUrl, props.dataMap);

  const titleCase = (string: string) => {
    return _.startCase(_.camelCase(string));
  };

  const onClickWithConfirmation = (windowProps: ConfirmationBoxWindowProps) => {
    props.hideContextMenu();
    if (desktopContext.setModal) {
      desktopContext.setModal(
        WindowType.CONFIRMATION,
        windowProps,
        desktopContext
      );
    }
  };

  return (
    <>
      <RaspberryPiFirmwareContextButton
        baseUrl={baseTierUrl}
        targetUrl={baseTowerUrl}
        secondaryTargetUrl={baseTierUrl}
        connectionToServer={props.connectionToServer}
        dataMap={props.dataMap}
        hideContextMenu={props.hideContextMenu}
        setOpenSubMenu={props.setOpenSubMenu}
      />
      <ContextButton
        onClick={() =>
          onClickWithConfirmation({
            yesButtonEventHandler: () => {
              const newCommand: PageToPageServer = {
                type: PageToPageServerType.SendCommand,
                sendCommand: {
                  targetId: fullString(toAddress(props.parentUrl)),
                  rebootCommand: {
                    targetId: props.url,
                    dummyData: true,
                  },
                },
              };
              props.connectionToServer.sendMessage(newCommand);
              props.hideContextMenu();
            },
            queryText: props.url + ':\n Reboot Tier?',
          })
        }
        setOpenSubMenu={props.setOpenSubMenu}>
        Reboot
      </ContextButton>
      <ContextButton
        onClick={() =>
          onClickWithConfirmation({
            yesButtonEventHandler: () => {
              const newCommand: PageToPageServer = {
                type: PageToPageServerType.SendCommand,
                sendCommand: {
                  targetId: fullString(toAddress(props.parentUrl)),
                  shutdownCommand: {
                    targetId: props.url,
                    dummyData: true,
                  },
                },
              };
              props.connectionToServer.sendMessage(newCommand);
              props.hideContextMenu();
            },
            queryText: props.url + ':\n Shut Down Tier?',
          })
        }
        setOpenSubMenu={props.setOpenSubMenu}>
        Shut Down
      </ContextButton>
      <Divider />
      {Array.from(loggerMap.entries()).map(([loggerName, data]) => {
        return (
          //TODO(austin): add onClick
          <ContextButton
            key={loggerName}
            onClick={() => {
              onClickWithConfirmation({
                yesButtonEventHandler: () => {
                  const newDataUpdate: PageToPageServer = {
                    type: PageToPageServerType.SendDataUpdate,
                    sendDataUpdate: {
                      id: fullString(data.address),
                      datum: { value: !data.isEnabled },
                    },
                  };
                  props.connectionToServer.sendMessage(newDataUpdate);
                },
                queryText:
                  props.url +
                  ':\n ' +
                  (data.isEnabled ? 'Disable ' : 'Enable ') +
                  titleCase(loggerName) +
                  '?',
              });
            }}>
            {data.isEnabled ? 'Disable ' : 'Enable '} {titleCase(loggerName)}
          </ContextButton>
        );
      })}
      <Divider />
      <ContextButton
        onClick={() => {
          operationContext.setPlacementOperation(
            {
              targetUrl: toAddress(props.parentUrl),
              placementLocationUrl: undefined,
            },
            operationContext
          );
          props.select(
            props.parentUrl,
            controllerContext,
            desktopContext,
            placeTower
          );
        }}
        setOpenSubMenu={props.setOpenSubMenu}>
        Place Tower
      </ContextButton>
      <SubMenuButton
        {...props}
        id={TierSubCategories.INSPECT}
        openSubMenu={props.openSubMenu}
        buttonText={'Inspect'}
        setOpenSubMenu={props.setOpenSubMenu}>
        <ContextButton
          onClick={() => {
            props.inspect(props.url);
          }}
          setOpenSubMenu={props.setOpenSubMenu}>
          Inspect Tier
        </ContextButton>
        <ContextButton
          onClick={() => {
            props.inspect(props.parentUrl);
          }}
          setOpenSubMenu={props.setOpenSubMenu}>
          Inspect Tower
        </ContextButton>
      </SubMenuButton>
      <SubMenuButton
        {...props}
        id={TierSubCategories.SELECT}
        openSubMenu={props.openSubMenu}
        buttonText={'Select'}
        setOpenSubMenu={props.setOpenSubMenu}>
        <ContextButton
          onClick={() => {
            props.select(props.url, controllerContext, desktopContext);
          }}
          setOpenSubMenu={props.setOpenSubMenu}>
          Select Tier
        </ContextButton>
        <ContextButton
          onClick={() => {
            props.select(props.parentUrl, controllerContext, desktopContext);
          }}
          setOpenSubMenu={props.setOpenSubMenu}>
          Select Tower
        </ContextButton>
      </SubMenuButton>
      <Divider />
      <ContextButton
        onClick={() =>
          onClickWithConfirmation({
            yesButtonEventHandler: () => {
              const newCommand: PageToPageServer = {
                type: PageToPageServerType.SendCommand,
                sendCommand: {
                  targetId: fullString(toAddress(props.parentUrl)),
                  doorOpenCommand: { targetId: props.url, dummyData: true },
                },
              };
              props.connectionToServer.sendMessage(newCommand);
            },
            queryText: props.url + ':\n Open door?',
          })
        }
        setOpenSubMenu={props.setOpenSubMenu}>
        Open Door!
      </ContextButton>
      <ContextButton
        onClick={() => {
          onClickWithConfirmation({
            yesButtonEventHandler: () => {
              const towerUrl = toAddress(props.parentUrl);
              const newCommand: PageToPageServer = {
                type: PageToPageServerType.SendCommand,
                sendCommand: {
                  targetId: fullString(towerUrl),
                  doorCloseCommand: { targetId: props.url, dummyData: true },
                },
              };
              props.connectionToServer.sendMessage(newCommand);
            },
            queryText: props.url + ':\n Close door?',
          });
        }}
        setOpenSubMenu={props.setOpenSubMenu}>
        Close Door!
      </ContextButton>

      <ContextButton
        onClick={() =>
          onClickWithConfirmation({
            yesButtonEventHandler: () => {
              const towerUrl = toAddress(props.parentUrl);
              const newCommand: PageToPageServer = {
                type: PageToPageServerType.SendCommand,
                sendCommand: {
                  targetId: fullString(towerUrl),
                  acOnCommand: { targetId: props.url, dummyData: true },
                },
              };
              props.connectionToServer.sendMessage(newCommand);
            },
            queryText: props.url + ':\n Turn AC on?',
          })
        }
        setOpenSubMenu={props.setOpenSubMenu}>
        AC On
      </ContextButton>
      <ContextButton
        onClick={() =>
          onClickWithConfirmation({
            yesButtonEventHandler: () => {
              const newCommand: PageToPageServer = {
                type: PageToPageServerType.SendCommand,
                sendCommand: {
                  targetId: fullString(toAddress(props.parentUrl)),
                  acOffCommand: { targetId: props.url, dummyData: true },
                },
              };
              props.connectionToServer.sendMessage(newCommand);
            },
            queryText: props.url + ':\n Turn AC off?',
          })
        }
        setOpenSubMenu={props.setOpenSubMenu}>
        AC Off
      </ContextButton>
      <ContextButton
        onClick={() =>
          onClickWithConfirmation({
            yesButtonEventHandler: () => {
              const newCommand: PageToPageServer = {
                type: PageToPageServerType.SendCommand,
                sendCommand: {
                  targetId: fullString(toAddress(props.parentUrl)),
                  fansOnCommand: { targetId: props.url, dummyData: true },
                },
              };
              props.connectionToServer.sendMessage(newCommand);
            },
            queryText: props.url + ':\n Turn fans on?',
          })
        }
        setOpenSubMenu={props.setOpenSubMenu}>
        Fans On
      </ContextButton>
      <ContextButton
        onClick={() =>
          onClickWithConfirmation({
            yesButtonEventHandler: () => {
              const newCommand: PageToPageServer = {
                type: PageToPageServerType.SendCommand,
                sendCommand: {
                  targetId: fullString(toAddress(props.parentUrl)),
                  fansOffCommand: { targetId: props.url, dummyData: true },
                },
              };
              props.connectionToServer.sendMessage(newCommand);
            },
            queryText: props.url + ':\n Turn fans off?',
          })
        }
        setOpenSubMenu={props.setOpenSubMenu}>
        Fans Off
      </ContextButton>
      <ContextButton
        onClick={() =>
          onClickWithConfirmation({
            yesButtonEventHandler: () => {
              const newCommand: PageToPageServer = {
                type: PageToPageServerType.SendCommand,
                sendCommand: {
                  targetId: fullString(toAddress(props.parentUrl)),
                  lightsOnCommand: { targetId: props.url, dummyData: true },
                },
              };
              props.connectionToServer.sendMessage(newCommand);
            },
            queryText: props.url + ':\n Turn lights on?',
          })
        }
        setOpenSubMenu={props.setOpenSubMenu}>
        Lights On
      </ContextButton>
      <ContextButton
        onClick={() =>
          onClickWithConfirmation({
            yesButtonEventHandler: () => {
              const newCommand: PageToPageServer = {
                type: PageToPageServerType.SendCommand,
                sendCommand: {
                  targetId: fullString(toAddress(props.parentUrl)),
                  lightsOffCommand: { targetId: props.url, dummyData: true },
                },
              };
              props.connectionToServer.sendMessage(newCommand);
            },
            queryText: props.url + ':\n Turn lights off?',
          })
        }
        setOpenSubMenu={props.setOpenSubMenu}>
        Lights Off
      </ContextButton>
      <ContextButton
        onClick={() =>
          onClickWithConfirmation({
            yesButtonEventHandler: () => {
              const newCommand: PageToPageServer = {
                type: PageToPageServerType.SendCommand,
                sendCommand: {
                  targetId: fullString(toAddress(props.parentUrl)),
                  pumpOnCommand: { targetId: props.url, dummyData: true },
                },
              };
              props.connectionToServer.sendMessage(newCommand);
            },
            queryText: props.url + ':\n Turn pump on?',
          })
        }
        setOpenSubMenu={props.setOpenSubMenu}>
        Pump On
      </ContextButton>
      <ContextButton
        onClick={() =>
          onClickWithConfirmation({
            yesButtonEventHandler: () => {
              const newCommand: PageToPageServer = {
                type: PageToPageServerType.SendCommand,
                sendCommand: {
                  targetId: fullString(toAddress(props.parentUrl)),
                  pumpOffCommand: { targetId: props.url, dummyData: true },
                },
              };
              props.connectionToServer.sendMessage(newCommand);
            },
            queryText: props.url + ':\n Turn this pump off?',
          })
        }
        setOpenSubMenu={props.setOpenSubMenu}>
        Pump Off
      </ContextButton>
      <ContextButton
        onClick={() =>
          onClickWithConfirmation({
            yesButtonEventHandler: () => {
              const newCommand: PageToPageServer = {
                type: PageToPageServerType.SendCommand,
                sendCommand: {
                  targetId: fullString(toAddress(props.parentUrl)),
                  valveOpenCommand: { targetId: props.url, dummyData: true },
                },
              };
              props.connectionToServer.sendMessage(newCommand);
            },
            queryText: props.url + ':\n Open valve?',
          })
        }
        setOpenSubMenu={props.setOpenSubMenu}>
        Open Valve
      </ContextButton>
      <ContextButton
        onClick={() =>
          onClickWithConfirmation({
            yesButtonEventHandler: () => {
              const newCommand: PageToPageServer = {
                type: PageToPageServerType.SendCommand,
                sendCommand: {
                  targetId: fullString(toAddress(props.parentUrl)),
                  valveCloseCommand: { targetId: props.url, dummyData: true },
                },
              };
              props.connectionToServer.sendMessage(newCommand);
            },
            queryText: props.url + ':\n Close valve?',
          })
        }
        setOpenSubMenu={props.setOpenSubMenu}>
        Close Valve
      </ContextButton>
      <Divider />
      <ContextButton
        onClick={() =>
          onClickWithConfirmation({
            yesButtonEventHandler: () => {
              const newCommand: PageToPageServer = {
                type: PageToPageServerType.SendCommand,
                sendCommand: {
                  targetId: fullString(toAddress(props.parentUrl)),
                  resetFlowInCommand: { targetId: props.url, dummyData: true },
                },
              };
              props.connectionToServer.sendMessage(newCommand);
            },
            queryText: props.url + ':\n Reset flow-in tracking?',
          })
        }
        setOpenSubMenu={props.setOpenSubMenu}>
        Reset Total Flow In Tracking
      </ContextButton>
      <ContextButton
        onClick={() =>
          onClickWithConfirmation({
            yesButtonEventHandler: () => {
              const newCommand: PageToPageServer = {
                type: PageToPageServerType.SendCommand,
                sendCommand: {
                  targetId: fullString(toAddress(props.parentUrl)),
                  resetFlowOutCommand: { targetId: props.url, dummyData: true },
                },
              };
              props.connectionToServer.sendMessage(newCommand);
            },
            queryText: props.url + ':\n Reset flow-out tracking?',
          })
        }
        setOpenSubMenu={props.setOpenSubMenu}>
        Reset Total Flow Out Tracking
      </ContextButton>
      <Divider />
      <SubMenuButton
        id={TierSubCategories.GANTRY}
        openSubMenu={props.openSubMenu}
        buttonText={'Gantry'}
        setOpenSubMenu={props.setOpenSubMenu}>
        <ContextButton
          onClick={() =>
            onClickWithConfirmation({
              yesButtonEventHandler: () => {
                const newCommand: PageToPageServer = {
                  type: PageToPageServerType.SendCommand,
                  sendCommand: {
                    targetId: fullString(toAddress(props.parentUrl)),
                    gantryMoveByCommand: {
                      targetId: props.url,
                      x_mm: 0,
                      y_mm: 0,
                      z_mm: 10,
                    },
                  },
                };
                props.connectionToServer.sendMessage(newCommand);
              },
              queryText: props.url + ':\n Move Gantry up 10mm?',
            })
          }
          setOpenSubMenu={props.setOpenSubMenu}>
          Move Gantry Up 10mm
        </ContextButton>
        <ContextButton
          onClick={() =>
            onClickWithConfirmation({
              yesButtonEventHandler: () => {
                const newCommand: PageToPageServer = {
                  type: PageToPageServerType.SendCommand,
                  sendCommand: {
                    targetId: fullString(toAddress(props.parentUrl)),
                    gantryMoveByCommand: {
                      targetId: props.url,
                      x_mm: 0,
                      y_mm: 0,
                      z_mm: -10,
                    },
                  },
                };
                props.connectionToServer.sendMessage(newCommand);
              },
              queryText: props.url + ':\n Move Gantry down 10mm?',
            })
          }
          setOpenSubMenu={props.setOpenSubMenu}>
          Move Gantry Down 10mm
        </ContextButton>
        <ContextButton
          onClick={() =>
            onClickWithConfirmation({
              yesButtonEventHandler: () => {
                const newCommand: PageToPageServer = {
                  type: PageToPageServerType.SendCommand,
                  sendCommand: {
                    targetId: fullString(toAddress(props.parentUrl)),
                    gantryFindFarXCommand: {
                      targetId: props.url,
                      dummyData: true,
                    },
                  },
                };
                props.connectionToServer.sendMessage(newCommand);
              },
              queryText: props.url + ':\n Gantry: Find far X?',
            })
          }
          setOpenSubMenu={props.setOpenSubMenu}>
          Gantry Find Far X
        </ContextButton>
        <ContextButton
          onClick={() =>
            onClickWithConfirmation({
              yesButtonEventHandler: () => {
                const newCommand: PageToPageServer = {
                  type: PageToPageServerType.SendCommand,
                  sendCommand: {
                    targetId: fullString(toAddress(props.parentUrl)),
                    gantryFindFarYCommand: {
                      targetId: props.url,
                      dummyData: true,
                    },
                  },
                };
                props.connectionToServer.sendMessage(newCommand);
              },
              queryText: props.url + ':\n Gantry: Find far Y?',
            })
          }
          setOpenSubMenu={props.setOpenSubMenu}>
          Gantry Find Far Y
        </ContextButton>
        <ContextButton
          onClick={() =>
            onClickWithConfirmation({
              yesButtonEventHandler: () => {
                const newCommand: PageToPageServer = {
                  type: PageToPageServerType.SendCommand,
                  sendCommand: {
                    targetId: fullString(toAddress(props.parentUrl)),
                    gantryFindFarZCommand: {
                      targetId: props.url,
                      dummyData: true,
                    },
                  },
                };
                props.connectionToServer.sendMessage(newCommand);
              },
              queryText: props.url + ':\n Gantry: Find far Z?',
            })
          }
          setOpenSubMenu={props.setOpenSubMenu}>
          Gantry Find Far Z
        </ContextButton>

        <ContextButton
          onClick={() =>
            onClickWithConfirmation({
              yesButtonEventHandler: () => {
                const x = 0;
                const y = 0;
                const z = 0;

                const newCommand: PageToPageServer = {
                  type: PageToPageServerType.SendCommand,
                  sendCommand: {
                    targetId: fullString(toAddress(props.parentUrl)),
                    gantryGoToCommand: {
                      targetId: props.url,
                      x_mm: x,
                      y_mm: y,
                      z_mm: z,
                    },
                  },
                };
                props.connectionToServer.sendMessage(newCommand);
              },
              queryText: props.url + ':\n Gantry: Go to (0,0,0)?',
            })
          }
          setOpenSubMenu={props.setOpenSubMenu}>
          Gantry Goto 0 0 0
        </ContextButton>

        <ContextButton
          onClick={() =>
            onClickWithConfirmation({
              yesButtonEventHandler: () => {
                const x = 0;
                const y = MAX_TIER_Y;
                const z = 0;

                const newCommand: PageToPageServer = {
                  type: PageToPageServerType.SendCommand,
                  sendCommand: {
                    targetId: fullString(toAddress(props.parentUrl)),
                    gantryGoToCommand: {
                      targetId: props.url,
                      x_mm: x,
                      y_mm: y,
                      z_mm: z,
                    },
                  },
                };
                props.connectionToServer.sendMessage(newCommand);
              },
              queryText: props.url + ':\n Park gantry for growing?',
            })
          }
          setOpenSubMenu={props.setOpenSubMenu}>
          Gantry Park For Growing
        </ContextButton>

        <ContextButton
          onClick={() =>
            onClickWithConfirmation({
              yesButtonEventHandler: () => {
                const x = Math.random() * MAX_TIER_X;
                const y = Math.random() * MAX_TIER_Y;
                const z = Math.random() * MAX_TIER_Z;

                const newCommand: PageToPageServer = {
                  type: PageToPageServerType.SendCommand,
                  sendCommand: {
                    targetId: fullString(toAddress(props.parentUrl)),
                    gantryGoToCommand: {
                      targetId: props.url,
                      x_mm: x,
                      y_mm: y,
                      z_mm: z,
                    },
                  },
                };
                props.connectionToServer.sendMessage(newCommand);
              },
              queryText: props.url + ':\n Gantry: go to <random>?',
            })
          }
          setOpenSubMenu={props.setOpenSubMenu}>
          Gantry Goto Random
        </ContextButton>
        <ContextButton
          onClick={() =>
            onClickWithConfirmation({
              yesButtonEventHandler: () => {
                const newCommand: PageToPageServer = {
                  type: PageToPageServerType.SendCommand,
                  sendCommand: {
                    targetId: fullString(toAddress(props.parentUrl)),
                    gantryHardStopCommand: {
                      targetId: props.url,
                      dummyData: true,
                    },
                  },
                };
                props.connectionToServer.sendMessage(newCommand);
              },
              queryText: props.url + ':\n Gantry: Hard stop?',
            })
          }
          setOpenSubMenu={props.setOpenSubMenu}>
          Gantry Hard Stop
        </ContextButton>
        <ContextButton
          onClick={() =>
            onClickWithConfirmation({
              yesButtonEventHandler: () => {
                const newCommand: PageToPageServer = {
                  type: PageToPageServerType.SendCommand,
                  sendCommand: {
                    targetId: fullString(toAddress(props.parentUrl)),
                    gantryStopCommand: { targetId: props.url, dummyData: true },
                  },
                };
                props.connectionToServer.sendMessage(newCommand);
              },
              queryText: props.url + ':\n Gantry: Stop?',
            })
          }
          setOpenSubMenu={props.setOpenSubMenu}>
          Gantry Stop
        </ContextButton>
        <ContextButton
          onClick={() =>
            onClickWithConfirmation({
              yesButtonEventHandler: () => {
                const newCommand: PageToPageServer = {
                  type: PageToPageServerType.SendCommand,
                  sendCommand: {
                    targetId: fullString(toAddress(props.parentUrl)),
                    gantryHomeCommand: { targetId: props.url, dummyData: true },
                  },
                };
                props.connectionToServer.sendMessage(newCommand);
              },
              queryText: props.url + ':\n Gantry: Go home?',
            })
          }
          setOpenSubMenu={props.setOpenSubMenu}>
          Gantry Home
        </ContextButton>
      </SubMenuButton>
    </>
  );
}
