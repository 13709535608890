import React, { useState } from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { GET_FARM_QUERY } from './FarmPage';
import FormItem from '../../form/FormItem';
import { Button } from '../../form/Button';
import { toRgbaString } from 'model/src/common/Color';
import { THEME } from '../../globe/Theme';

const createTrolleyMutation = gql`
  mutation createTrolley(
    $newName: String!
    $newTrackId: String!
    $levels: Int!
    $locationId: String!
    $locationX: Int!
    $locationY: Int!
  ) {
    createTrolley(
      createTrolleyInput: {
        name: $newName
        trackId: $newTrackId
        levels: $levels
        locationId: $locationId
        locationX: $locationX
        locationY: $locationY
      }
    ) {
      id
      name
    }
  }
`;

type Props = {
  farmId: String;
  track: any;
  location: {
    id: String;
    x: number;
    y: number;
  };
  onTrolleyCreated: () => void;
};

const TrolleyCreator = (props: Props) => {
  const [name, setName] = useState('');
  const [levels, setLevels] = useState(3);

  function changeName(event: React.ChangeEvent<HTMLInputElement>): void {
    console.log('changeName ' + event.target.value);
    const name = event.target.value;
    setName(name);
  }

  function changeSize(event: React.ChangeEvent<HTMLInputElement>): void {
    console.log('changeSize ' + event.target.value);
    const levels = parseInt(event.target.value);
    setLevels(levels);
  }

  return (
    <Mutation
      mutation={createTrolleyMutation}
      refetchQueries={[
        { query: GET_FARM_QUERY, variables: { farmId: props.farmId } },
      ]}>
      {(createTrolley: any, { loading, error }: any) => {
        return (
          <form
            onSubmit={event => {
              event.preventDefault();
              if (!loading && name.length > 0) {
                createTrolley({
                  variables: {
                    newName: name,
                    levels: levels,
                    newTrackId: props.track.id,
                    locationId: props.location.id,
                    locationX: props.location.x,
                    locationY: props.location.y,
                  },
                }).then((result: any) => {
                  setName('');
                  setLevels(3);
                  props.onTrolleyCreated();
                });
              }
            }}>
            <div>
              <FormItem
                label="Trolley Name"
                placeholder="Enter trolley name..."
                readOnly={false}
                debounce={true}
                onChange={changeName}
                minLength={1}
              />
              <FormItem
                label="Trolley Size"
                placeholder="Enter number of racks..."
                readOnly={false}
                debounce={true}
                onChange={changeSize}
                minLength={1}
              />
              <Button
                type="submit"
                disabled={loading || name.length === 0}
                color={toRgbaString(THEME.blues[0])}
                disabledColor={toRgbaString(THEME.blues[5])}
                showBorder={true}>
                Add Trolley
              </Button>
            </div>
          </form>
        );
      }}
    </Mutation>
  );
};

export default TrolleyCreator;
