import {
  combine,
  create,
  fullString,
  join,
  toAddress,
} from 'model/src/common/CloudProduceAddressUtility';
import { urlCombine } from 'model/src/common/Systems';
import { Data } from 'model/src/dataflowprotocol/Datum';
import { DrivetrainControlSignalReading } from 'model/src/series/DrivetrainControlSignalReading';
import { SeriesType } from 'model/src/series/SeriesTypes';
import React from 'react';
import styled from 'styled-components';

const Main = styled.div`
  width: 140px;
  height: 140px;
  box-sizing: border-box;
  isolation: isolate;
  position: relative;
  margin: 20px;
  overflow: hidden;
`;

const Outside = styled.div`
  top: 0px;
  left: 0px;
  width: 140px;
  height: 140px;
  box-sizing: border-box;
  position: absolute;
  border-radius: 70px;
  overflow: hidden;
  box-shadow: inset 3px 3px 5px var(--neumorphic-dark-shadow),
    inset -4px -4px 10px var(--neumorphic-light-shadow);
  transition: all 0.2s ease-in-out;
  appearance: none;
  -webkit-appearance: none;
`;

const Inside = styled.div`
  width: 50px;
  height: 50px;
  box-sizing: border-box;
  isolation: isolate;
  position: absolute;
  margin: 0px;
  border-radius: 25px;
  overflow: hidden;
  box-shadow: 3px 3px 5px var(--neumorphic-light-shadow),
    -4px -4px 10px var(--neumorphic-dark-shadow);
  transition: all 0.2s ease-in-out;
  appearance: none;
  -webkit-appearance: none;
  top: 45px;
  left: 45px;
  background-color: var(--neumorphic-background-color);
`;

const ArrowHolder = styled.div`
  bottom: 0px;
  left: 60px;
  position: absolute;
  width: 20px;
  height: 140px;
  transform: rotateZ(${props => props.orientation}deg);
  box-sizing: border-box;
`;

const Arrow = styled.div`
  top: 0px;
  left: 0px;
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid var(--neumorphic-font-color);
`;

const Stem = styled.div`
  bottom: 70px;
  left: 0px;
  position: absolute;
  width: 20px;
  height: 50px;
  background-color: var(--neumorphic-font-color);
`;

const Speed = styled.div`
  position: absolute;
  bottom: 70px;
  width: 15px;
  height: ${props => props.speed}px;
  left: 2.5px;
  background-color: var(--cloud-produce-carribean-blue);
`;

const Canvas = styled.svg`
  position: absolute;
  width: 140px;
  height: 140px;
`;

export default function DrivetrainControlSignal(props) {
  const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    };
  };

  const describeArc = (x, y, radius, startAngle, endAngle) => {
    const start = polarToCartesian(x, y, radius, endAngle);
    const end = polarToCartesian(x, y, radius, startAngle);

    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

    let actualStart = start;
    let actualEnd = end;
    if (endAngle - startAngle < 0) {
      actualStart = end;
      actualEnd = start;
    }

    const d = [
      'M',
      actualStart.x,
      actualStart.y,
      'A',
      radius,
      radius,
      0,
      largeArcFlag,
      0,
      actualEnd.x,
      actualEnd.y,
    ].join(' ');

    return d;
  };

  const direction =
    (props.dataMap.all.get(
      fullString(
        combine(
          toAddress(props.url),
          create([SeriesType.DRIVETRAIN_CONTROL_SIGNAL, SeriesType.ANGLE])
        )
      )
    ).data[0].reading.value *
      -180) /
    Math.PI;

  const linearVelocity = props.dataMap.all.get(
    fullString(
      combine(
        toAddress(props.url),
        create([
          SeriesType.DRIVETRAIN_CONTROL_SIGNAL,
          SeriesType.LINEAR_VELOCITY,
        ])
      )
    )
  ).data[0].reading.value;
  const arcAngle =
    props.dataMap.all.get(
      fullString(
        combine(
          toAddress(props.url),
          create([
            SeriesType.DRIVETRAIN_CONTROL_SIGNAL,
            SeriesType.ANGULAR_VELOCITY,
          ])
        )
      )
    ).data[0].reading.value * 90;

  const maxWheelSpeed = props.dataMap.all.get(
    fullString(
      combine(
        toAddress(props.url),
        create([
          SeriesType.DRIVETRAIN_CONTROL_SIGNAL,
          SeriesType.MAX_WHEEL_SPEED,
        ])
      )
    )
  ).data[0].reading.value;
  const speed = (50 * linearVelocity * maxWheelSpeed) / 0.2;

  const d = describeArc(70, 70, 70, 0, arcAngle);

  return (
    <Main>
      <Canvas>
        <path
          d={d + ' L 70 70 Z'}
          fill="var(--cloud-produce-light-purple)"
          stroke="black"
          strokeWidth="0"
        />
      </Canvas>
      <ArrowHolder orientation={direction}>
        <Arrow />
        <Stem />
        <Speed speed={speed} />
      </ArrowHolder>
      {/* <Inside /> */}
      <Outside />
    </Main>
  );
}
