import React, { Component } from 'react';

// import ReactGlobe from 'react-globe';

type Props = {
  addingFarm: boolean;
  newFarm?: {
    name?: string;
    stacks?: number;
    stackSize?: number;
    coordinates?: any;
  };
  farms: {
    id: string;
    name: string;
    coordinates: {
      latitude: number;
      longitude: number;
    };
  }[];
  selectedFarm?: {
    id: string;
    coordinates: any;
  };
  index: number;
  onFarmSelected: (
    id: string,
    name: string,
    latitude: number,
    longitude: number
  ) => void;
  onFarmDeselected: () => void;
};
type State = {
  lastFocus?: any;
};

export enum MarkerType {
  DUMMY,
  FARM,
  NEW_FARM,
}

class Globe extends Component<Props, State> {
  lastFocus: any;

  constructor(props: Props) {
    super(props);
    this.state = {};
    this.onClickMarker = this.onClickMarker.bind(this);
    this.onDefocus = this.onDefocus.bind(this);
  }

  onDefocus(previousCoordinates, event) {
    this.props.onFarmDeselected();
  }

  onClickMarker(marker, markerObject, event) {
    this.props.onFarmSelected(
      marker.id,
      marker.name,
      marker.coordinates[0],
      marker.coordinates[1]
    );
  }

  determineFocus() {
    if (this.props.selectedFarm) {
      this.lastFocus = [
        this.props.selectedFarm.coordinates[0],
        this.props.selectedFarm.coordinates[1],
      ];
    } else if (this.props.newFarm && this.props.newFarm.coordinates) {
      this.lastFocus = [
        this.props.newFarm.coordinates[0],
        this.props.newFarm.coordinates[1],
      ];
    } else {
      this.lastFocus = undefined;
    }
  }

  isInteractive(): boolean {
    return !this.props.addingFarm && !this.props.selectedFarm;
  }

  render() {
    this.determineFocus();
    const farmMarkers = this.props.farms.map(farm => {
      return {
        id: farm.id,
        name: farm.name,
        coordinates: [farm.coordinates.latitude, farm.coordinates.longitude],
        value: 135,
        type: MarkerType.FARM,
      };
    });

    console.log('Globe: NUM FARM MARKERS: ' + farmMarkers.length);

    const allMarkers: any[] = farmMarkers;

    if (this.props.newFarm && this.props.newFarm.coordinates) {
      const newFarmMarker = {
        id: 'NEW',
        name: this.props.newFarm.name || '',
        coordinates: [
          this.props.newFarm.coordinates[0],
          this.props.newFarm.coordinates[1],
        ],
        value: 135,
        type: MarkerType.NEW_FARM,
      };
      allMarkers.push(newFarmMarker);
    }
    return (
      <div />
      // <ReactGlobe
      //   cameraOptions={{
      //     enableAutoRotate: this.isInteractive(),
      //     enableRotate: this.isInteractive(),
      //     enableZoom: this.isInteractive(),
      //   }}
      //   focus={this.lastFocus}
      //   focusOptions={{
      //     animationDuration: 3000,
      //     distanceRadiusScale: 1.2,
      //     easingFunction: ['Cubic', 'InOut'],
      //     enableDefocus: false,
      //   }}
      //   globeOptions={{
      //     texture: EarthTexture,
      //     glowCoefficient: 0.35,
      //     glowColor: toHexString(THEME.blues[0]),
      //     glowPower: 8,
      //     glowRadiusScale: 0.225,
      //     enableGlow: true,
      //     cloudsOpacity: 0.8,
      //     enableClouds: false,
      //   }}
      //   lightOptions={{
      //     pointLightColor: 'white',
      //     pointLightIntensity: 1,
      //     pointLightPositionRadiusScales: [1.5, 1.5, 1.5],
      //   }}
      //   markers={allMarkers}
      //   markerOptions={{
      //     offsetRadiusScale: 0.005,
      //     enableTooltip: true,
      //     enterAnimationDuration: 3000,
      //     enterEasingFunction: ['Cubic', 'InOut'],
      //     exitAnimationDuration: 3000,
      //     exitEasingFunction: ['Cubic', 'Out'],
      //     getTooltipContent: marker =>
      //       `${marker.name} (Sales: ${marker.value}.0M)`,
      //     radiusScaleRange: [0.01, 0.05],
      //     renderer: renderFarmMarker,
      //   }}
      //   onClickMarker={this.onClickMarker}
      //   onDefocus={this.onDefocus}
      // />
    );
  }
}

export default Globe;
