
const defaultConfig = {
  firebase: {
    apiKey: "AIzaSyBz34PVAA-qZ5NUYXs9MGZb05jSeX72PHg",
    authDomain: "sunlit-sweep-233922.firebaseapp.com",
    databaseURL: "https://sunlit-sweep-233922.firebaseio.com",
    projectId: "sunlit-sweep-233922",
    storageBucket: "sunlit-sweep-233922.appspot.com",
    messagingSenderId: "453241729472",
    appId: "1:453241729472:web:25eaccbe0f001d22c61825",
    measurementId: "G-XRBEVHKKY1"
  },
  sentry: {
    debug: false,
    dsn: "https://dcd41e74a1794a8db13f68537f8216cc@o467566.ingest.sentry.io/5494157",
    enabled: false,
    environment: "production",
  },
};

export default defaultConfig;
