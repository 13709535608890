"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RegexPhrases = exports.Operations = exports.LiteralTokens = exports.RegexTokens = void 0;
var RegexTokens;

(function (RegexTokens) {
  RegexTokens["ANY_CAPITAL_LETTER"] = "[A-Z]";
  RegexTokens["ANY_DIGIT"] = "\\d";
  RegexTokens["END"] = "$";
  RegexTokens["ONE_OR_MORE"] = "+";
  RegexTokens["START"] = "^";
  RegexTokens["ZERO_OR_MORE"] = "*";
  RegexTokens["BACKSLASH"] = "\\\\";
  RegexTokens["BRACKET_LEFT"] = "\\(";
  RegexTokens["BRACKET_RIGHT"] = "\\)";
  RegexTokens["CIRCUMFLEX"] = "\\^";
  RegexTokens["CURLY_BRACE_LEFT"] = "\\{";
  RegexTokens["CURLY_BRACE_RIGHT"] = "\\}";
  RegexTokens["DOLLAR_SIGN"] = "\\$";
  RegexTokens["PERIOD"] = "\\.";
  RegexTokens["PIPE"] = "\\|";
  RegexTokens["PLUS"] = "\\+";
  RegexTokens["QUESTION_MARK"] = "\\?";
  RegexTokens["SQUARE_BRACKET_LEFT"] = "\\[";
  RegexTokens["SQUARE_BRACKET_RIGHT"] = "\\]";
  RegexTokens["STAR"] = "\\*";
})(RegexTokens = exports.RegexTokens || (exports.RegexTokens = {}));

var LiteralTokens;

(function (LiteralTokens) {
  LiteralTokens["PERIOD"] = ".";
  LiteralTokens["OPERATION"] = ":";
})(LiteralTokens = exports.LiteralTokens || (exports.LiteralTokens = {}));

var Operations;

(function (Operations) {
  Operations["ALIGNMENT"] = "ALIGNMENT";
  Operations["PLACEMENT"] = "PLACEMENT";
})(Operations = exports.Operations || (exports.Operations = {}));

var RegexPhrases;

(function (RegexPhrases) {
  RegexPhrases["ANY_ALLCAPS_STRING"] = "([A-Z]+(_[A-Z]+)*)";
  RegexPhrases["ANY_NUMBER"] = "\\d+";
  RegexPhrases["ANY_NUMBER_END"] = "\\d+$";
  RegexPhrases["ANY_NUMBER_START"] = "^\\d+";
  RegexPhrases["PLACEMENT_END"] = "PLACEMENT$";
  RegexPhrases["ALIGNMENT_END"] = "ALIGNMENT$";
  RegexPhrases["DOT"] = "\\.";
  RegexPhrases["OPERATION"] = ":";
  RegexPhrases["OPERATION_PLACEMENT_END"] = ":PLACEMENT$";
})(RegexPhrases = exports.RegexPhrases || (exports.RegexPhrases = {}));