import { BuiltinType } from 'model/src/typescript/Typescript';
import React, { useState } from 'react';
import styled from 'styled-components';

import { cssVar } from '../..';
import DataList, { ListDatum } from './DataList';
import HorizontalLineUrl from './tree-icons/HorizontalLineUrl';
import VerticalLineUrl from './tree-icons/VerticalLineUrl';
import TreeNode, { TreeNodeType } from './TreeNode';

export type SubTreeProps = {
  level: number;
  last: boolean;
  lastNode: boolean;
  node: TreeNodeType;
  parentLast: boolean;
  parentHasAttrs: boolean;
  selected: boolean;
};

export const LeafTextBox = styled.span`
  position: relative;
  display: inline-block;
  user-select: text;
  padding: var(--treeTextBoxPadding);
  border-radius: var(--treeLeafStaticBorderRadius);;
  bottom: calc(var(--treeNodeLineWidth) * -2);

  background-color: transparent;
  color: var--treeLeafEditableLabel_Default);
`;

export const SolidText = styled.p`
  position: relative;
  display: inline-block;
  margin: 0;
  background-color: var(--white);
  bottom: calc(var(--treeNodeLineWidth) * -2);
`;

export const NodeLine = styled.div`
  position: relative;
  display: inline-block;
  height: var(--nodeLineContainerHeight);
  width: var(--nodeLineContainerWidth);
  bottom: -1px;
  background-image: url('${props =>
    props.continue
      ? HorizontalLineUrl('--treeConnector_Default')
      : HorizontalLineUrl('--treeConnector_Default')}');
`;

export const ListContainer = styled.li`
  positon: relative;
  pointer-events: none;
  display: flex;
  flex: none;
  list-style: none; 
  border: 0;
  margin-left: var(--treeNodeSingleIndent);
  background-image: url('${props =>
    props.continue ? VerticalLineUrl('--treeConnector_Default') : ''}');
  background-repeat: repeat-y;
`;

const SubTree = (props: SubTreeProps) => {
  const [selected, setSelected] = useState(props.node.selected ? true : false);
  const dataOrDatum = props.node.data
    ? props.node.data
    : props.node.datum
    ? props.node.datum
    : false;

  const renderCurrentNode = () => {
    return (
      <ListContainer continue={props.level !== 0}>
        <DataList level={props.level} selected={props.node.selected}>
          <TreeNode
            key={props.node.url}
            node={props.node}
            selected={selected}
            level={props.level}
            onToggle={toggleSelected}
          />
        </DataList>
      </ListContainer>
    );
  };

  const renderChildNodeSubTrees = () => {
    return (
      <>
        {props.node.childNodes &&
          props.node.childNodes.map((child, index) => {
            return (
              <SubTree
                selected={false}
                key={child.url}
                node={child}
                level={props.level + 1}
                last={
                  index === props.node.childNodes!.length - 1 &&
                  !props.node.datum &&
                  !props.node.data
                }
                lastNode={index === props.node.childNodes!.length - 1}
                parentHasAttrs={props.node.data || props.node.datum}
                parentLast={props.last}
              />
            );
          })}
        {dataOrDatum && (
          <div style={{ paddingTop: cssVar('--treeNodeHalfVerticalSpacing') }}>
            <ListContainer
              continue={true}
              style={{
                marginTop: '-' + cssVar('--treeNodeHalfVerticalSpacing'),
              }}>
              <DataList level={props.level} selected={props.selected}>
                {Object.entries(dataOrDatum).map(datumKeyValuePair => {
                  return (
                    <ListDatum
                      key={props.node.url + '.' + datumKeyValuePair[0]}>
                      <NodeLine />
                      <SolidText>{datumKeyValuePair[0] + ': '}</SolidText>{' '}
                      {typeof datumKeyValuePair[1] == BuiltinType.OBJECT ? (
                        <LeafTextBox>
                          {JSON.stringify(datumKeyValuePair[1])}
                        </LeafTextBox>
                      ) : typeof datumKeyValuePair[1] == BuiltinType.BOOLEAN ? (
                        <LeafTextBox>
                          {datumKeyValuePair[1] ? 'True' : 'False'}
                        </LeafTextBox>
                      ) : (
                        <LeafTextBox>{datumKeyValuePair[1]}</LeafTextBox>
                      )}
                    </ListDatum>
                  );
                })}
              </DataList>
            </ListContainer>
          </div>
        )}
      </>
    );
  };

  const toggleSelected = () => {
    setSelected(!selected);
  };

  return (
    <>
      {renderCurrentNode()}
      {selected && (
        <ListContainer continue={!(props.last && !props.parentHasAttrs)}>
          <DataList level={props.level} selected={props.node.selected}>
            {renderChildNodeSubTrees()}
          </DataList>
        </ListContainer>
      )}
    </>
  );
};

export default SubTree;
