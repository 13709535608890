import React from 'react';

import { cssVar } from '../../../..';
import IndicatorLightBox from '../indicatorLights/IndicatorLightBox';

const StackPumpIndicatorLight = props => {
  const onColor = cssVar('--systemGreen');
  const offColor = cssVar('--systemRed');
  return (
    <IndicatorLightBox
      color={props.isOn ? onColor : offColor}
      position={props.position}
    />
  );
};
export default StackPumpIndicatorLight;
