import { isRoot } from 'model/src/common/CloudProduceAddressUtility';
import { LiteralTokens } from 'model/src/common/Regex';
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min';

import { OrthographicCamera, PerspectiveCamera } from '@react-three/drei';
import { Canvas, useFrame, useThree } from '@react-three/fiber';

import { cssVar } from '../';
import InjectionContext from '../injection/InjectionContext';
import {
  UserProfileContext,
  UserProfileContextType,
} from '../user/UserProfileContext';
import useWindowDimensions from '../utopia/desktop/useWindowDimensions';
import {
  ControllerContext,
  ControllerContextType,
  ProjectionType,
} from './farmviewer/context/controllercontext/ControllerContext';
import {
  DesktopContext,
  DesktopContextType,
} from './farmviewer/context/desktopcontext/DesktopContext';
import {
  OperationContext,
  OperationContextType,
} from './farmviewer/context/operationcontext/OperationContext';
import {
  PathContext,
  PathContextType,
} from './farmviewer/context/pathcontext/PathContext';
import { TargetLockState } from './farmviewer/FarmViewer';
import { Centroid, InspectionNode } from './farmviewer/InspectionNodeUtility';
import { defaultMovementKeys } from './farmviewer/MovementKeys';
import buildContextViewMap from './scenes/ContextViewMap';
import Farm from './scenes/farm/Farm';

const PAN_SPEED = 5;
const ONE_SECOND = 1000;

export type CameraAnimation = {
  position: THREE.Vector3;
  target: THREE.Vector3;
  duration: number;
};

function processCameraMovementRequest(
  request: string,
  controls: OrbitControls,
  targetForwardDirection: boolean
) {
  const movementKeys = defaultMovementKeys;

  const directionVector = new THREE.Vector3().subVectors(
    controls.target,
    controls.object.position
  );
  const mapDirectionVector = new THREE.Vector2(
    directionVector.x,
    directionVector.y
  );
  switch (request) {
    case movementKeys.back:
      if (targetForwardDirection) {
        directionVector.normalize().multiplyScalar(PAN_SPEED);
        controls.target.sub(directionVector);
        controls.object.position.sub(directionVector);
      } else {
        mapDirectionVector.normalize().multiplyScalar(PAN_SPEED);
        controls.target.sub(
          new THREE.Vector3(mapDirectionVector.x, mapDirectionVector.y, 0)
        );
        controls.object.position.sub(
          new THREE.Vector3(mapDirectionVector.x, mapDirectionVector.y, 0)
        );
      }
      break;
    case movementKeys.down:
      controls.target.z -= PAN_SPEED / 2;
      controls.object.position.z -= PAN_SPEED / 2;
      if (controls.target.z < 0) {
        controls.target.z = 0;
      }
      if (controls.object.position.z < 0) {
        controls.object.position.z = 0;
      }
      break;
    case movementKeys.forward:
      if (targetForwardDirection) {
        directionVector.normalize().multiplyScalar(PAN_SPEED);
        controls.target.add(directionVector);
        controls.object.position.add(directionVector);
      } else {
        mapDirectionVector.normalize().multiplyScalar(PAN_SPEED);
        controls.target.add(
          new THREE.Vector3(mapDirectionVector.x, mapDirectionVector.y, 0)
        );
        controls.object.position.add(
          new THREE.Vector3(mapDirectionVector.x, mapDirectionVector.y, 0)
        );
      }
      break;
    case movementKeys.left:
      mapDirectionVector.normalize().multiplyScalar(PAN_SPEED);
      controls.target.add(
        new THREE.Vector3(-mapDirectionVector.y, mapDirectionVector.x, 0)
      );
      mapDirectionVector.normalize().multiplyScalar(PAN_SPEED);
      controls.object.position.add(
        new THREE.Vector3(-mapDirectionVector.y, mapDirectionVector.x, 0)
      );
      break;
    case movementKeys.right:
      mapDirectionVector.normalize().multiplyScalar(PAN_SPEED);
      controls.target.add(
        new THREE.Vector3(mapDirectionVector.y, -mapDirectionVector.x, 0)
      );
      controls.object.position.add(
        new THREE.Vector3(mapDirectionVector.y, -mapDirectionVector.x, 0)
      );
      break;
    case movementKeys.up:
      controls.target.z += PAN_SPEED / 2;
      controls.object.position.z += PAN_SPEED / 2;
      break;
  }
}

let targetTween;
let positionTween;
let viewCubeTween;

const globals = {
  centroidFinder: () => {},
};

const CameraController = props => {
  const { camera, gl } = useThree();
  const inspectionSize = useRef(0);
  const cameraAnimation = useRef(null);
  const cameraRestriction = useRef(null);

  const currentTarget = useRef(null);
  const currentPosition = useRef(null);

  const controls = useRef<OrbitControls>(null);
  const canvasSize = useRef<THREE.Vector2>(new THREE.Vector2(0, 0));

  const controllerContext = useContext(ControllerContext);

  const centroidFinder = () => {
    findCentroids(
      camera,
      canvasSize,
      props.objectMap,
      props.inspectionContext.flattenedInspectionMap,
      props.inspectionContext.centroidMap,
      props.orbited,
      inspectionSize
    );
  };

  globals.centroidFinder = centroidFinder;

  const cameraMatrix = useMemo(() => {
    return new THREE.Matrix4();
  }, []);

  useEffect(() => {
    controls.current = new OrbitControls(camera, gl.domElement);
    controllerContext.baseRestriction.cameraSettings!.setControls(
      controls.current,
      controllerContext
    );
    controls.current.update();
    controls.current.addEventListener('change', centroidFinder);
    // controls.current.listenToKeyEvents(window);
    return () => {
      controls.current.dispose();
      controls.current = null;
      controllerContext.baseRestriction.cameraSettings!.removeControls(
        controllerContext
      );
    };
  }, [camera, gl]);

  useEffect(() => {
    const currentWidth = canvasSize.current.x;
    const currentHeight = canvasSize.current.y;
    gl.getSize(canvasSize.current);

    if (
      currentWidth != canvasSize.current.x ||
      currentHeight != canvasSize.current.y
    ) {
      findCentroids(
        controls.current.object,
        canvasSize,
        props.objectMap,
        props.inspectionContext.flattenedInspectionMap,
        props.inspectionContext.centroidMap,
        props.orbited,
        inspectionSize
      );
    }
  });

  useEffect(() => {
    if (
      controllerContext.baseRestriction.cameraSettings &&
      controllerContext.baseRestriction.cameraSettings.controls
    ) {
      controllerContext.baseRestriction.cameraSettings.controls.screenSpacePanning =
        props.screenSpacePanning.current;
      controllerContext.baseRestriction.cameraSettings.controls.update();
    }
  }, [props.screenSpacePanning.current]);

  useFrame(() => {
    cameraMatrix.copy(controls.current.object.matrix).invert();
    props.setCameraMatrix(cameraMatrix);
  });
  useFrame(({ clock }) => {
    TWEEN.update();
  });

  useFrame(() => {
    const centerPosition = controls.current.target.clone();
    centerPosition.z = 0;
    const groundPosition = controls.current.object.position.clone();
    groundPosition.z = 0;
    const d = centerPosition.distanceTo(groundPosition);
    const origin = new THREE.Vector2(controls.current.target.z, 0);
    const remote = new THREE.Vector2(0, d);
    const angleRadians = Math.atan2(remote.y - origin.y, remote.x - origin.x);
    controls.current.maxPolarAngle = angleRadians;

    if (controls.current.target.z < 0) {
      controls.current.target.z = 0;
    }
    controls.current.update();
  });

  useFrame(() => {
    if (controls.current) {
      props.cameraMovementRequests.current.forEach(request => {
        processCameraMovementRequest(
          request,
          controls.current,
          props.targetForwardDirection.current
        );
      });
      controls.current.update();
    }
  });

  useEffect(() => {
    if (
      props.inspectionContext.flattenedInspectionMap.size >
      inspectionSize.current
    ) {
      findCentroids(
        controls.current.object,
        canvasSize,
        props.objectMap,
        props.inspectionContext.flattenedInspectionMap,
        props.inspectionContext.centroidMap,
        props.orbited,
        inspectionSize
      );
    } else {
      inspectionSize.current =
        props.inspectionContext.flattenedInspectionMap.size;
    }
  });

  useEffect(() => {
    if (controls.current) {
      controls.current.enabled = props.isControlsEnabled;
      controls.current.update();
    }
  });

  useEffect(() => {
    if (
      props.cameraAnimation &&
      props.cameraAnimation !== cameraAnimation.current
    ) {
      cameraAnimation.current = props.cameraAnimation;
      animateTo(props.cameraAnimation);
    }
  });

  useEffect(() => {
    if (controls.current) {
      controls.current.enabled = false;
      const cameraVector = new THREE.Vector3().subVectors(
        controls.current.object.position,
        controls.current.target
      );
      const cameraDistance = cameraVector.length();

      const radialAdjustedViewCube = new THREE.Spherical(
        cameraDistance,
        props.viewCubeRotation.phi,
        props.viewCubeRotation.theta
      );
      const cartesianViewCubeRotation = new THREE.Vector3().addVectors(
        new THREE.Vector3().setFromSpherical(radialAdjustedViewCube),
        controls.current.target
      );
      if (controls.current) {
        viewCubeTween = new TWEEN.Tween(controls.current.object.position)
          .to(cartesianViewCubeRotation, ONE_SECOND)
          .onStart(() => {
            controls.current.enabled = false;
          })
          .onUpdate(e => {
            controls.current.target = new THREE.Vector3(
              controls.current.target.x,
              controls.current.target.y,
              controls.current.target.z
            );
            controls.current.update();

            cameraMatrix.copy(controls.current.object.matrix).invert();
            props.setCameraMatrix(cameraMatrix);
          })
          .onComplete(() => {
            controls.current.enabled = true;
            viewCubeTween = null;

            controls.current.enabled = true;
          })
          .easing(TWEEN.Easing.Sinusoidal.InOut);

        viewCubeTween.start();
      }
    }
  }, [props.viewCubeRotation]);

  useEffect(() => {
    if (
      props.cameraRestriction &&
      props.cameraRestriction != cameraRestriction.current
    ) {
      if (!cameraRestriction.current) {
        currentTarget.current = { ...controls.current.target };
        currentPosition.current = { ...controls.current.object.position };
      }
      cameraRestriction.current = props.cameraRestriction;
      const cameraAnimation: CameraAnimation = {
        target: props.cameraRestriction.target,
        position: props.cameraRestriction.position,
        duration: ONE_SECOND,
      };
      animateTo(cameraAnimation);
    } else if (
      !props.cameraRestriction &&
      props.cameraRestriction != cameraRestriction.current
    ) {
      cameraRestriction.current = null;
      const cameraAnimation: CameraAnimation = {
        target: currentTarget.current,
        position: currentPosition.current,
        duration: ONE_SECOND,
      };
      animateTo(cameraAnimation);
    }
  });
  const animateTo = (animation: CameraAnimation) => {
    controls.current.enabled = false;

    positionTween = new TWEEN.Tween(controls.current.object.position)
      .to(animation.position, animation.duration)
      .easing(TWEEN.Easing.Sinusoidal.InOut)
      .onComplete(() => {
        positionTween = null;
      });
    targetTween = new TWEEN.Tween(controls.current.target)
      .to(animation.target, animation.duration)
      .onUpdate(e => {
        controls.current.update();
      })
      .onComplete(() => {
        controls.current.enabled = true;
        targetTween = null;
      })
      .easing(TWEEN.Easing.Sinusoidal.InOut);
    positionTween.start();
    targetTween.start();
  };
  return null;
};

function findCentroids(
  camera,
  canvasSize,
  objectMap,
  flattenedInspectionMap,
  centroidMap,
  orbited,
  inspectionSize
) {
  camera.updateMatrixWorld();
  camera.updateProjectionMatrix();

  inspectionSize.current = flattenedInspectionMap.size;

  flattenedInspectionMap.forEach((inspectionNode: InspectionNode) => {
    const model: THREE.Object3d = objectMap.get(inspectionNode.url).model;
    const bbox = new THREE.Box3().setFromObject(model);
    const vector = new THREE.Vector3(0, 0, 0);
    bbox.getCenter(vector);
    const point = vector.project(camera);

    const centroid: Centroid = centroidMap.get(inspectionNode.url);
    centroid.x = Math.round(
      (canvasSize.current.x + point.x * canvasSize.current.x) / 2
    );
    centroid.y = Math.round(
      (canvasSize.current.y - point.y * canvasSize.current.y) / 2
    );
  });

  orbited();
}

const perspectiveToOrthographic = (
  screenWidth,
  screenHeight,
  controllerContext: ControllerContextType
) => {
  if (!controllerContext.baseRestriction.cameraSettings) {
    return {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    };
  }

  const fov_y = controllerContext.baseRestriction.cameraSettings.getFov(
    controllerContext
  );
  const distCameraToTarget = controllerContext.baseRestriction.cameraSettings
    .getLookAt(controllerContext)
    .clone()
    .sub(
      controllerContext.baseRestriction.cameraSettings.getPosition(
        controllerContext
      )
    );
  const depth = distCameraToTarget.length();

  const aspect = screenWidth / screenHeight;

  const height_ortho = depth * 2 * Math.atan((fov_y * (Math.PI / 180)) / 2);
  const width_ortho = height_ortho * aspect;
  return {
    left: width_ortho / -2,
    right: width_ortho / 2,
    top: height_ortho / 2,
    bottom: height_ortho / -2,
  };
};

export default function ModelViewer(props) {
  const { screenHeight, screenWidth } = useWindowDimensions();
  const dimensions = useRef({ left: 0, right: 0, top: 0, bottom: 0 });
  const orthographicCamera = useRef<THREE.OrthographicCamera>();
  const perspectiveCamera = useRef<THREE.PerspectiveCamera>();
  const scene = useRef<object>();
  const clickTracker = useRef<boolean>(false);

  const injectionContext = useContext(InjectionContext);
  const desktopContext: DesktopContextType = useContext(DesktopContext);
  const controllerContext: ControllerContextType = useContext(
    ControllerContext
  );
  const operationContext: OperationContextType = useContext(OperationContext);
  const pathContext: PathContextType = useContext(PathContext);
  const userProfileContext: UserProfileContextType = useContext(
    UserProfileContext
  );

  const contextViewMap = useMemo(() => {
    return buildContextViewMap();
  }, []);

  const targetLock = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (props.targetLockState.current !== TargetLockState.ACTIVE) {
      return;
    }
    if (
      scene.current &&
      controllerContext.baseRestriction &&
      controllerContext.baseRestriction.cameraSettings
    ) {
      const targetObject = controllerContext.baseRestriction.cameraSettings.getFirstRaycastObject(
        event,
        scene.current,
        controllerContext
      );
      if (targetObject) {
        controllerContext.baseRestriction.cameraSettings.setLookAt(
          targetObject.point,
          controllerContext
        );
      }
    }
  };

  const animateTo = (
    animation: CameraAnimation,
    controllerContext: ControllerContextType
  ) => {
    if (controllerContext.baseRestriction.cameraSettings) {
      controllerContext.baseRestriction.cameraSettings!.controls.enabled = false;
      const cameraPosition = {
        ...controllerContext.baseRestriction.cameraSettings!.controls.object
          .position,
      };
      const cameraTarget = controllerContext.baseRestriction.cameraSettings!.getLookAt(
        controllerContext
      );
      positionTween = new TWEEN.Tween(cameraPosition)
        .to(animation.position, animation.duration)
        .easing(TWEEN.Easing.Sinusoidal.InOut)
        .onUpdate(e => {
          controllerContext.baseRestriction.cameraSettings!.setPosition(
            e,
            controllerContext
          );
        })
        .onComplete(() => {
          positionTween = null;
        });
      targetTween = new TWEEN.Tween(cameraTarget)
        .to(animation.target, animation.duration)
        .onUpdate(e => {
          controllerContext.baseRestriction.cameraSettings!.setLookAt(
            e,
            controllerContext
          );
        })
        .onComplete(() => {
          controllerContext.baseRestriction.cameraSettings!.controls.enabled = true;
          targetTween = null;
        })
        .easing(TWEEN.Easing.Sinusoidal.InOut);
      positionTween.start();
      targetTween.start();
    }
  };

  const targetLockMuteOnClicks = (onClick: (arg1?, arg2?) => void) => {
    if (props.targetLockState.current !== TargetLockState.ACTIVE) {
      return onClick;
    } else {
      return () => {};
    }
  };

  const optionalMuteOnClicks = (onClick: (arg1?, arg2?) => void) => {
    return controllerContext.dragMute(
      targetLockMuteOnClicks(onClick),
      controllerContext
    );
  };

  if (
    controllerContext.baseRestriction.cameraSettings &&
    controllerContext.baseRestriction.cameraSettings.getProjectionType(
      controllerContext
    ) === ProjectionType.ORTHOGRAPHIC
  ) {
    dimensions.current = perspectiveToOrthographic(
      screenHeight,
      screenWidth,
      controllerContext
    );
  }
  const fov = controllerContext.baseRestriction.cameraSettings!.getFov(
    controllerContext
  );
  useEffect(() => {
    if (
      controllerContext.baseRestriction.cameraSettings &&
      !controllerContext.baseRestriction.cameraSettings.setScene
    ) {
      const setActiveContextView = (
        objectUrl,
        operation,
        controllerContext
      ) => {
        const operationUrl = objectUrl + operation;
        if (
          controllerContext.baseRestriction.cameraSettings &&
          contextViewMap.getContextView(operationUrl) &&
          !isRoot(objectUrl)
        ) {
          let targetObject;
          if (objectUrl.includes(LiteralTokens.OPERATION)) {
            targetObject = props.objectMap.get(
              desktopContext.getParentUrl(objectUrl)
            );
          } else {
            targetObject = props.objectMap.get(objectUrl);
          }
          const boundingBox = new THREE.Box3().setFromObject(
            targetObject.model
          );
          const targetCenterVector = new THREE.Vector3(0, 0, 0);
          !contextViewMap.getContextView(operationUrl)!
            .absoluteTargetPosition &&
            boundingBox.getCenter(targetCenterVector);
          const newTarget = targetCenterVector
            .clone()
            .add(
              contextViewMap.getContextView(operationUrl)!.cameraTargetOffset
            );
          const newCameraPosition = newTarget
            .clone()
            .add(
              contextViewMap.getContextView(operationUrl)!.cameraPositionOffset
            );

          animateTo(
            {
              position: newCameraPosition,
              target: newTarget,
              duration: ONE_SECOND,
            },
            controllerContext
          );
        } else if (
          //TODO(austin): remove once bounding box value for whole farm exists
          controllerContext.baseRestriction.cameraSettings &&
          contextViewMap.getContextView(operationUrl) &&
          isRoot(objectUrl)
        ) {
          const newTarget = contextViewMap.getContextView(operationUrl)!
            .cameraTargetOffset;
          const newCameraPosition = contextViewMap.getContextView(operationUrl)!
            .cameraPositionOffset;
          animateTo(
            {
              position: newCameraPosition,
              target: newTarget,
              duration: 1000,
            },
            controllerContext
          );
        }
      };

      controllerContext.setContext({
        ...controllerContext,
        baseRestriction: {
          ...controllerContext.baseRestriction,
          cameraSettings: {
            ...controllerContext.baseRestriction.cameraSettings!,
            setScene: setActiveContextView,
          },
        },
      });
    }
  }, []);

  const displayTruss = !(contextViewMap.getContextView(
    desktopContext.selectedUrl
  )
    ? contextViewMap.getContextView(desktopContext.selectedUrl)!.trussProperties
        .transparent
    : false);

  return (
    <div
      style={{ position: 'relative', width: '100%', height: '100%' }}
      onClick={e => {
        const trussColor = cssVar('--truss');
        e.stopPropagation();
        targetLock(e);
        props.onBackgroundClicked(desktopContext);
      }}
      onMouseDown={() => {
        clickTracker.current = true;
      }}
      onMouseMove={() => {
        if (clickTracker.current) {
          controllerContext.setInDrag(true, controllerContext);
        }
      }}
      onMouseUp={() => {
        clickTracker.current = false;
        controllerContext.setInDrag(false, controllerContext);
      }}>
      <Canvas>
        <color attach="background" args={[cssVar('--mapBackground')]} />
        {controllerContext.baseRestriction.cameraSettings &&
          controllerContext.baseRestriction.cameraSettings.projectionType ===
            ProjectionType.PERSPECTIVE && (
            <PerspectiveCamera
              makeDefault
              position={controllerContext.baseRestriction.cameraSettings.getPosition(
                controllerContext
              )}
              up={[0, 0, 1]}
              ref={perspectiveCamera}
              fov={fov}
              key={undefined}
              attach={undefined}
              attachArray={undefined}
              attachObject={undefined}
              args={undefined}
              onUpdate={undefined}
              view={undefined}
              id={undefined}
              lookAt={undefined}
              name={undefined}
              clear={undefined}
              zoom={undefined}
              add={undefined}
              visible={undefined}
              clone={undefined}
              copy={undefined}
              type={undefined}
              uuid={undefined}
              parent={undefined}
              modelViewMatrix={undefined}
              normalMatrix={undefined}
              matrixWorld={undefined}
              matrixAutoUpdate={undefined}
              matrixWorldNeedsUpdate={undefined}
              castShadow={undefined}
              receiveShadow={undefined}
              frustumCulled={undefined}
              renderOrder={undefined}
              animations={undefined}
              userData={undefined}
              customDepthMaterial={undefined}
              customDistanceMaterial={undefined}
              isObject3D={undefined}
              onBeforeRender={undefined}
              onAfterRender={undefined}
              applyMatrix4={undefined}
              applyQuaternion={undefined}
              setRotationFromAxisAngle={undefined}
              setRotationFromEuler={undefined}
              setRotationFromMatrix={undefined}
              setRotationFromQuaternion={undefined}
              rotateOnAxis={undefined}
              rotateOnWorldAxis={undefined}
              rotateX={undefined}
              rotateY={undefined}
              rotateZ={undefined}
              translateOnAxis={undefined}
              translateX={undefined}
              translateY={undefined}
              translateZ={undefined}
              localToWorld={undefined}
              worldToLocal={undefined}
              remove={undefined}
              getObjectById={undefined}
              getObjectByName={undefined}
              getObjectByProperty={undefined}
              getWorldPosition={undefined}
              getWorldQuaternion={undefined}
              getWorldScale={undefined}
              getWorldDirection={undefined}
              raycast={undefined}
              traverse={undefined}
              traverseVisible={undefined}
              traverseAncestors={undefined}
              updateMatrix={undefined}
              updateMatrixWorld={undefined}
              updateWorldMatrix={undefined}
              toJSON={undefined}
              addEventListener={undefined}
              hasEventListener={undefined}
              removeEventListener={undefined}
              dispatchEvent={undefined}
              near={undefined}
              far={undefined}
              updateProjectionMatrix={undefined}
              setViewOffset={undefined}
              clearViewOffset={undefined}
              matrixWorldInverse={undefined}
              projectionMatrix={undefined}
              projectionMatrixInverse={undefined}
              isCamera={undefined}
              isPerspectiveCamera={undefined}
              aspect={undefined}
              focus={undefined}
              filmGauge={undefined}
              filmOffset={undefined}
              setFocalLength={undefined}
              getFocalLength={undefined}
              getEffectiveFOV={undefined}
              getFilmWidth={undefined}
              getFilmHeight={undefined}
              setLens={undefined}
            />
          )}
        {controllerContext.baseRestriction.cameraSettings &&
          controllerContext.baseRestriction.cameraSettings.projectionType ===
            ProjectionType.ORTHOGRAPHIC && (
            <OrthographicCamera
              makeDefault
              position={controllerContext.baseRestriction.cameraSettings.getPosition(
                controllerContext
              )}
              left={dimensions.current.left}
              right={dimensions.current.right}
              top={dimensions.current.top}
              bottom={dimensions.current.bottom}
              up={[0, 0, 1]}
              ref={orthographicCamera}
              key={undefined}
              attach={undefined}
              attachArray={undefined}
              attachObject={undefined}
              args={undefined}
              onUpdate={undefined}
              view={undefined}
              id={undefined}
              lookAt={undefined}
              name={undefined}
              clear={undefined}
              zoom={undefined}
              add={undefined}
              visible={undefined}
              clone={undefined}
              copy={undefined}
              type={undefined}
              uuid={undefined}
              parent={undefined}
              modelViewMatrix={undefined}
              normalMatrix={undefined}
              matrixWorld={undefined}
              matrixAutoUpdate={undefined}
              matrixWorldNeedsUpdate={undefined}
              castShadow={undefined}
              receiveShadow={undefined}
              frustumCulled={undefined}
              renderOrder={undefined}
              animations={undefined}
              userData={undefined}
              customDepthMaterial={undefined}
              customDistanceMaterial={undefined}
              isObject3D={undefined}
              onBeforeRender={undefined}
              onAfterRender={undefined}
              applyMatrix4={undefined}
              applyQuaternion={undefined}
              setRotationFromAxisAngle={undefined}
              setRotationFromEuler={undefined}
              setRotationFromMatrix={undefined}
              setRotationFromQuaternion={undefined}
              rotateOnAxis={undefined}
              rotateOnWorldAxis={undefined}
              rotateX={undefined}
              rotateY={undefined}
              rotateZ={undefined}
              translateOnAxis={undefined}
              translateX={undefined}
              translateY={undefined}
              translateZ={undefined}
              localToWorld={undefined}
              worldToLocal={undefined}
              remove={undefined}
              getObjectById={undefined}
              getObjectByName={undefined}
              getObjectByProperty={undefined}
              getWorldPosition={undefined}
              getWorldQuaternion={undefined}
              getWorldScale={undefined}
              getWorldDirection={undefined}
              raycast={undefined}
              traverse={undefined}
              traverseVisible={undefined}
              traverseAncestors={undefined}
              updateMatrix={undefined}
              updateMatrixWorld={undefined}
              updateWorldMatrix={undefined}
              toJSON={undefined}
              addEventListener={undefined}
              hasEventListener={undefined}
              removeEventListener={undefined}
              dispatchEvent={undefined}
              near={undefined}
              far={undefined}
              updateProjectionMatrix={undefined}
              setViewOffset={undefined}
              clearViewOffset={undefined}
              matrixWorldInverse={undefined}
              projectionMatrix={undefined}
              projectionMatrixInverse={undefined}
              isCamera={undefined}
              isOrthographicCamera={undefined}
            />
          )}
        <ambientLight intensity={0.25} />
        <pointLight position={[10, 10, 100]} />
        <scene scale={[0.1, 0.1, 0.1]} ref={scene}>
          <InjectionContext.Provider value={injectionContext}>
            <UserProfileContext.Provider value={userProfileContext}>
              <DesktopContext.Provider value={desktopContext}>
                <PathContext.Provider value={pathContext}>
                  <ControllerContext.Provider value={controllerContext}>
                    <OperationContext.Provider value={operationContext}>
                      <Farm
                        dataMap={props.dataMap}
                        onClick={optionalMuteOnClicks(url =>
                          props.onClick(url, desktopContext)
                        )}
                        truss={displayTruss}
                        showMenu={props.showMenu}
                        inspectionContext={props.inspectionContext}
                        objectMap={props.objectMap}
                        connectionToServer={props.connectionToServer}
                        globals={globals}
                      />
                    </OperationContext.Provider>
                  </ControllerContext.Provider>
                </PathContext.Provider>
              </DesktopContext.Provider>
            </UserProfileContext.Provider>
          </InjectionContext.Provider>
        </scene>
        <UserProfileContext.Provider value={userProfileContext}>
          <ControllerContext.Provider value={controllerContext}>
            <CameraController
              setCameraMatrix={props.setCameraMatrix}
              viewCubeRotation={props.viewCubeRotation}
              cameraAnimation={props.cameraAnimation}
              cameraMovementRequests={props.cameraMovementRequests}
              cameraRestriction={
                controllerContext.overridingRestriction
                  ? controllerContext.overridingRestriction.cameraRestriction
                  : null
              }
              isControlsEnabled={props.isControlsEnabled}
              orbited={props.orbited}
              objectMap={props.objectMap}
              screenSpacePanning={props.screenSpacePanning}
              inspectionContext={props.inspectionContext}
              targetForwardDirection={props.targetForwardDirection}
              useOrthographicCamera={
                controllerContext.baseRestriction.cameraSettings &&
                controllerContext.baseRestriction.cameraSettings
                  .projectionType !== ProjectionType.PERSPECTIVE
              }
            />
          </ControllerContext.Provider>
        </UserProfileContext.Provider>
      </Canvas>
    </div>
  );
}
