import {
  fullString,
  isDriveTrain,
  isFishTank,
  isFloorNode,
  isStack,
  isSump,
  isSuperBeacon,
  isTier,
  isTower,
  isTug,
  isUmbilical,
  toAddress,
  towerId,
} from 'model/src/common/CloudProduceAddressUtility';
import React, { useEffect, useState } from 'react';

import ContextMenuContainer from '../../utopia/context-menu/ContextMenuContainer';
import WindowType from '../../utopia/window/WindowTypes';
import { ControllerContextType } from './context/controllercontext/ControllerContext';
import { DesktopContextType } from './context/desktopcontext/DesktopContext';

export type WindowClickInformation = { windowType: WindowType } & Record<
  string,
  any
>;

export type ContextMenuData = {
  shouldShow: boolean;
  url: string;
  position: {
    x: number;
    y: number;
  };
  isController: boolean;
  isFishTank: boolean;
  isFloorNode: boolean;
  isSuperBeacon: boolean;
  isStack: boolean;
  isSump: boolean;
  isTier: boolean;
  isTower: boolean;
  isTug: boolean;
  isUmbilical: boolean;
  isSchedulerWindow: boolean;
  isTimerTemplate: boolean;
  windowClickInfo: WindowClickInformation | undefined;
  inspect: (url, desktopContext) => void;
  select: (
    url: string,
    constollerContext: ControllerContextType,
    desktopContext: DesktopContextType,
    operation: string
  ) => void;
  hideContextMenu: () => void;
  showContextMenu: (url: string) => void;
};

export type ContextMenuMethods = {
  inspect: (url: string, desktopContext: DesktopContextType) => void;
  uninspect: (url: string, desktopContext: DesktopContextType) => void;
  uninspectAll: (desktopContext: DesktopContextType) => void;
  select: (
    url: string,
    controllerContext: ControllerContextType,
    desktopContext: DesktopContextType,
    operation?: string
  ) => void;
  setContextMenuData: (contextMenuData: ContextMenuData) => void;
  hideContextMenu: () => void;
};

export default function ContextMenu(props) {
  const hideContextMenu = () => {
    setContextMenuData({
      ...contextMenuData,
      shouldShow: false,
    });
  };
  const showContextMenu = (
    url: string,
    windowClickInfo?: WindowClickInformation
  ) => {
    const address = toAddress(url);
    const isSumpVariable = isSump(address);
    const isTugVariable = isTug(address);
    const isTowerVariable = isTower(address);
    const isTierVariable = isTier(address);
    const isUmbilicalVariable = isUmbilical(address);

    const isFloorNodeVariable = isFloorNode(address);
    const isControllerVariable = isDriveTrain(address);
    const isSuperBeaconVariable = isSuperBeacon(address);
    const isFishTankVariable = isFishTank(address);
    const isStackVariable = isStack(address);

    const isSchedulerWindow =
      !!windowClickInfo &&
      windowClickInfo.windowType === WindowType.SCHEDULER &&
      !windowClickInfo.timerTemplate;

    const isTimerTemplate =
      !!windowClickInfo &&
      windowClickInfo.windowType === WindowType.SCHEDULER &&
      !!windowClickInfo.timerTemplate;

    setContextMenuData({
      shouldShow: true,
      url: url,
      position: {
        x: props.mousePosition.current.x,
        y: props.mousePosition.current.y,
      },
      isController: isControllerVariable,
      isFishTank: isFishTankVariable,
      isFloorNode: isFloorNodeVariable,
      isStack: isStackVariable,
      isSuperBeacon: isSuperBeaconVariable,
      isSump: isSumpVariable,
      isTier: isTierVariable,
      isTower: isTowerVariable,
      isTug: isTugVariable,
      isUmbilical: isUmbilicalVariable,
      isSchedulerWindow: isSchedulerWindow,
      isTimerTemplate: isTimerTemplate,

      windowClickInfo: windowClickInfo,

      inspect: (url, desktopContext: DesktopContextType) => {
        contextMenuMethods.inspect(url, desktopContext);
        contextMenuMethods.hideContextMenu();
      },
      select: (url, controllerContext, desktopContext, operation) => {
        contextMenuMethods.select(
          url,
          controllerContext,
          desktopContext,
          operation
        );

        contextMenuMethods.hideContextMenu();
      },
      hideContextMenu: contextMenuMethods.hideContextMenu,
      showContextMenu: showContextMenu,
    });
  };

  const defaultContextMenuValues: ContextMenuData = {
    shouldShow: false,
    url: '',
    position: {
      x: 0,
      y: 0,
    },
    isController: false,
    isFishTank: false,
    isFloorNode: false,
    isSuperBeacon: false,
    isStack: false,
    isSump: false,
    isTier: false,
    isTower: false,
    isTug: false,
    isUmbilical: false,
    isSchedulerWindow: false,
    isTimerTemplate: false,
    windowClickInfo: undefined,
    inspect: props.events.inspect,
    select: props.events.select,
    hideContextMenu: hideContextMenu,
    showContextMenu: showContextMenu,
  };
  const [contextMenuData, setContextMenuData] = useState<ContextMenuData>(
    defaultContextMenuValues
  );
  useEffect(() => {
    props.contextMenuData.current = contextMenuData;
  }, [contextMenuData]);

  const contextMenuMethods: ContextMenuMethods = {
    ...props.events,
    setContextMenuData: props.setContextMenuData,
    hideContextMenu: hideContextMenu,
  };

  return (
    <>
      {contextMenuData.shouldShow && (
        <ContextMenuContainer
          {...props}
          {...contextMenuData}
          parentUrl={
            contextMenuData.isTier &&
            fullString(towerId(toAddress(contextMenuData.url)))
          }
          top={contextMenuData.position.y}
          left={contextMenuData.position.x}
        />
      )}
    </>
  );
}
