import React, { useContext } from 'react';
import styled from 'styled-components';

import ClassNames from '../../ClassNames';
import {
  DesktopContext,
  DesktopContextType,
} from '../../three/farmviewer/context/desktopcontext/DesktopContext';
import Toggle from '../../utopia/toggle/Toggle';
import { HeaderButtonType } from '../../utopia/window/components/WindowHeaderButton';
import DraggableWindow from '../../utopia/window/DraggableWindow';

export enum TowerOverlay {
  TOWER_DOCKING_STATUS = 'TOWER_DOCKING_STATUS',
  TOWER_ONLINE_OFFLINE = 'TOWER_ONLINE_OFFLINE',
  TOWER_WATER_STATUS = 'TOWER_WATER_STATUS',
  TOWER_UNKNOWN = 'TOWER_UNKNOWN',
}

export enum WaterTankOverlay {
  WATER_TANK_WATER_LEVEL = 'WATER_TANK_WATER_LEVEL',
  WATER_TANK_WATER_STATUS = 'WATER_TANK_WATER_STATUS',
}

const Content = styled.div`
  width: var(--viewFilterWindowWidth);
  padding: var(--windowPadding);
  pointer-events: any;
  border-radius: var(--windowBottomBorderRadius);
  color: var(--black);
  background-color: var(--dropdownMenuItemBackground_Default);
`;

const ItemDiv = styled.div`
  padding-top: var(--windowPadding);
  padding-bottom: var(--windowPadding);
`;

const TextLine = styled.div`
  padding-top: var(--spacingLarge);
  padding-left: var(--spacingMedium);
  display: inline-block;
  white-space: nowrap;
  color: var(--black);
`;

const ForceRight = styled.div`
  // padding-top: 20px;
  display: inline-block;
  float: right;
  // padding-right: 16px;
`; // TODO(austin): space checkboxes off of right side within ForceRight

const ViewFiltersWindow = props => {
  const desktopContext: DesktopContextType = useContext(DesktopContext);

  const handleOverlayChange = overlay => {
    desktopContext.selectOverlay(overlay, desktopContext);
  };

  return (
    <DraggableWindow
      onDrag={props.onDrag}
      onDragEnd={props.onDragEnd}
      initialWindowPosition={props.initialWindowPosition}
      title="View Filters"
      headerButtons={[HeaderButtonType.CLOSE]}
      headerButtonOnClicks={[() => props.closeHandler()]}
      onClick={props.onClick}
      onDragStart={props.onDragStart}
      onMouseDown={props.onMouseDown}
      selected={props.selected}>
      {
        <>
          <TextLine className={ClassNames.title3}>Overlay</TextLine>
          <Content>
            <TextLine className={ClassNames.title4}>Towers</TextLine>
            <ItemDiv>
              Offline/Online
              <ForceRight>
                <Toggle
                  defaultPosition={
                    desktopContext.selectedOverlay ===
                    TowerOverlay.TOWER_ONLINE_OFFLINE
                  }
                  onChange={() => {
                    handleOverlayChange(TowerOverlay.TOWER_ONLINE_OFFLINE);
                  }}
                />
              </ForceRight>
            </ItemDiv>
            <ItemDiv>
              Water Status
              <ForceRight>
                <Toggle
                  defaultPosition={
                    desktopContext.selectedOverlay ===
                    TowerOverlay.TOWER_WATER_STATUS
                  }
                  onChange={() =>
                    handleOverlayChange(TowerOverlay.TOWER_WATER_STATUS)
                  }
                />
              </ForceRight>
            </ItemDiv>
            <ItemDiv>
              Docking Status
              <ForceRight>
                <Toggle
                  defaultPosition={
                    desktopContext.selectedOverlay ===
                    TowerOverlay.TOWER_DOCKING_STATUS
                  }
                  onChange={() =>
                    handleOverlayChange(TowerOverlay.TOWER_DOCKING_STATUS)
                  }
                />
              </ForceRight>
            </ItemDiv>
            <ItemDiv>
              Unknown
              <ForceRight>
                <Toggle
                  defaultPosition={
                    desktopContext.selectedOverlay ===
                    TowerOverlay.TOWER_UNKNOWN
                  }
                  onChange={() =>
                    handleOverlayChange(TowerOverlay.TOWER_UNKNOWN)
                  }
                />
              </ForceRight>
            </ItemDiv>
          </Content>
          <Content>
            <TextLine className={ClassNames.title4}>Water Tanks</TextLine>
            <ItemDiv>
              Water Level
              <ForceRight>
                <Toggle
                  defaultPosition={
                    desktopContext.selectedOverlay ===
                    WaterTankOverlay.WATER_TANK_WATER_LEVEL
                  }
                  onChange={() =>
                    handleOverlayChange(WaterTankOverlay.WATER_TANK_WATER_LEVEL)
                  }
                />
              </ForceRight>
            </ItemDiv>
            <ItemDiv>
              Water Status
              <ForceRight>
                <Toggle
                  defaultPosition={
                    desktopContext.selectedOverlay ===
                    WaterTankOverlay.WATER_TANK_WATER_STATUS
                  }
                  onChange={() =>
                    handleOverlayChange(
                      WaterTankOverlay.WATER_TANK_WATER_STATUS
                    )
                  }
                />
              </ForceRight>
            </ItemDiv>
          </Content>
        </>
      }
    </DraggableWindow>
  ); // TODO(austin): rework checkboxes: accommodate multiple lists
  //TODO(austin): ask if we should now accommodate multiple simultaneous overlays
};

export default ViewFiltersWindow;
