/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import {
  create,
  getAt,
  toAddress,
} from 'model/src/common/CloudProduceAddressUtility';
import { GRID_NODE_SIDE_LENGTH_CM } from 'model/src/common/FarmConstants';
import { Axis } from 'model/src/common/Systems';
import { SeriesType } from 'model/src/series/SeriesTypes';
import React, { useContext, useRef } from 'react';

import { cssVar } from '../../..';
import { useDataMap } from '../../farmviewer/context/datamapcontext/useDataMap';
import {
  DesktopContext,
  DesktopContextType,
} from '../../farmviewer/context/desktopcontext/DesktopContext';
import Box from '../common/Box';

export default function CoordinateSpace({ ...props }) {
  const desktopContext: DesktopContextType = useContext(DesktopContext);
  const farmId = getAt(toAddress(desktopContext.selectedUrl), 0);
  const group = useRef();
  const space = useRef();
  const squaresAcross =
    +useDataMap(create([farmId, SeriesType.SIZE_OF_GRID, Axis.X])).value + 2;
  const realOrigin = (
    <group dispose={null} scale={[10, 10, 10]}>
      <Box color={cssVar('--neumorphic-palette-light-purple')} />
    </group>
  );
  const spaceOrigin = (
    <group
      dispose={null}
      scale={[10, 10, 10]}
      position={[-squaresAcross * GRID_NODE_SIDE_LENGTH_CM, 0, 0]}>
      <Box color={cssVar('--neumorphic-palette-blue')} />
    </group>
  );
  const negativeX = (
    <group
      dispose={null}
      position={[-(squaresAcross - 2) * GRID_NODE_SIDE_LENGTH_CM, 0, 0]}
      scale={[10, 10, 10]}>
      <Box color={cssVar('--neumorphic-palette-red')} />
    </group>
  );
  const positiveY = (
    <group
      dispose={null}
      position={[
        -squaresAcross * GRID_NODE_SIDE_LENGTH_CM,
        2 * GRID_NODE_SIDE_LENGTH_CM,
        0,
      ]}
      scale={[10, 10, 10]}>
      <Box color={cssVar('--neumorphic-palette-red')} />
    </group>
  );
  return (
    <group ref={group} dispose={null}>
      {realOrigin}
      <group
        ref={space}
        position={[-175, 2500, 0]}
        rotation={[0, 0, Math.PI]}
        dispose={null}>
        {spaceOrigin}
        {negativeX}
        {positiveY}
        {props.tugs}
        {props.towers}
      </group>
    </group>
  );
}
