import { toAddress } from 'model/src/common/CloudProduceAddressUtility';
import { urlCombine } from 'model/src/common/Systems';
import { SeriesType } from 'model/src/series/SeriesTypes';
import React, { useContext, useMemo } from 'react';

import { Label } from '../../neumorphic/Label';
import {
  DesktopContext,
  DesktopContextType,
} from '../context/desktopcontext/DesktopContext';
import DriveTrainPanel from '../tools/DriveTrainPanel';
import StackInspectionPanel from '../tools/inspection-panels/StackInspectionPanel';
import SumpInspectionPanel from '../tools/inspection-panels/SumpInspectionPanel';
import TierInspectionPanel from '../tools/inspection-panels/TierInspectionPanel';
import TowerInspectionPanel from '../tools/inspection-panels/TowerInspectionPanel';
import TugInspectionPanel from '../tools/inspection-panels/TugInspectionPanel';
import UmbilicalInspectionPanel from '../tools/inspection-panels/UmbilicalInspectionPanel';

export default function InspectedContent(props) {
  const desktopContext: DesktopContextType = useContext(DesktopContext);
  const address = useMemo(() => toAddress(props.url), [props.url]);
  const parentAddress = useMemo(
    () => toAddress(desktopContext.getParentUrl(props.url, 2)),
    [props.url]
  );
  const umbilicalParentAddress = useMemo(
    () => toAddress(desktopContext.getParentUrl(props.url, 1)),
    [props.url]
  );
  return (
    <>
      {props.isTugVariable && (
        <TugInspectionPanel
          address={address}
          connectionToServer={props.connectionToServer}
          dataMap={props.dataMap}
          url={props.url}
        />
      )}
      {props.isDriveTrainVariable && (
        <DriveTrainPanel
          url={props.url}
          datum={props.dataMap.all.get(props.url)}
          pwm={props.dataMap.all.get(props.url + urlCombine([SeriesType.PWM]))}
          radiansPerSecond={
            props.dataMap.all.get(props.url + urlCombine([SeriesType.ROTATION]))
              .rotation[0].radiansPerSecond
          }
        />
      )}
      {props.isFloorNodeVariable && <Label>FLOOR NODE</Label>}
      {props.isSuperBeaconVariable && (
        <Label>
          {props.dataMap.all.get(props.url + '.' + SeriesType.NAME).value}
        </Label>
      )}
      {props.isFishTankVariable && (
        <StackInspectionPanel
          address={address}
          dataMap={props.dataMap}
          parentAddress={parentAddress}
          url={props.url}
        />
      )}
      {props.isSumpVariable && (
        <SumpInspectionPanel
          address={address}
          dataMap={props.dataMap}
          parentAddress={parentAddress}
          url={props.url}
        />
      )}
      {props.isTowerVariable && (
        <TowerInspectionPanel
          address={address}
          dataMap={props.dataMap}
          url={props.url}
        />
      )}
      {props.isTierVariable && (
        <TierInspectionPanel
          address={address}
          dataMap={props.dataMap}
          parentAddress={parentAddress}
          url={props.url}
        />
      )}
      {props.isUmbilicalVariable && (
        <UmbilicalInspectionPanel
          address={address}
          dataMap={props.dataMap}
          parentAddress={umbilicalParentAddress}
          url={props.url}
        />
      )}
    </>
  );
}
