import { CloudProduceAddress } from 'model/src/common/CloudProduceAddress';
import {
  combine,
  create,
  fullString,
  getAt,
  toAddress,
  tugId,
} from 'model/src/common/CloudProduceAddressUtility';
import { Operations, RegexPhrases } from 'model/src/common/Regex';
import { TugSensorsSubsystem, TugSubsystem } from 'model/src/common/Systems';
import { SeriesType } from 'model/src/series/SeriesTypes';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { cssVar } from '../../..';
import { useDataMap } from '../../farmviewer/context/datamapcontext/useDataMap';
import {
  DesktopContext,
  DesktopContextType,
} from '../../farmviewer/context/desktopcontext/DesktopContext';
import Box from '../common/Box';

export type LineSensorProps = {
  url: CloudProduceAddress;
};

export default function LineSensor(props: LineSensorProps) {
  const desktopContext: DesktopContextType = useContext(DesktopContext);

  const doNotRender =
    desktopContext.selectedUrl ===
    fullString(tugId(props.url)) +
      RegexPhrases.OPERATION +
      Operations.ALIGNMENT;

  const chooseSensorColor = useCallback((index: number, line: string) => {
    if (line.charAt(index) === '0') {
      return cssVar('--systemRed');
    } else if (line.charAt(index) === '1') {
      return cssVar('--systemGreen40');
    } else {
      return cssVar('--cloud-produce-black');
    }
  }, []);
  const line = useDataMap(
    combine(props.url, create([SeriesType.LINE]))
  ).value.trim();

  const [position, setPosition] = useState(
    computeLineSensorOrientation(props.url)[0]
  );
  const [rotation, setRotation] = useState(
    computeLineSensorOrientation(props.url)[1]
  );
  useEffect(() => {
    const newOrientation = computeLineSensorOrientation(props.url);
    setPosition(newOrientation.position);
    setRotation(newOrientation.rotation);
  }, [props.url]);

  const [colors, setColors] = useState([
    chooseSensorColor(0, line),
    chooseSensorColor(1, line),
    chooseSensorColor(2, line),
    chooseSensorColor(3, line),
    chooseSensorColor(4, line),
    chooseSensorColor(5, line),
    chooseSensorColor(6, line),
    chooseSensorColor(7, line),
  ]);
  useEffect(() => {
    setColors([
      chooseSensorColor(0, line),
      chooseSensorColor(1, line),
      chooseSensorColor(2, line),
      chooseSensorColor(3, line),
      chooseSensorColor(4, line),
      chooseSensorColor(5, line),
      chooseSensorColor(6, line),
      chooseSensorColor(7, line),
    ]);
  }, [line]);

  const boxPositions = [
    [7, 0, 0],
    [5, 0, 0],
    [3, 0, 0],
    [1, 0, 0],
    [-1, 0, 0],
    [-3, 0, 0],
    [-5, 0, 0],
    [-7, 0, 0],
  ];

  const box = (
    <group
      dispose={null}
      rotation={rotation}
      position={position}
      scale={[5, 5, 5]}>
      <Box position={boxPositions[0]} color={colors[0]} />
      <Box position={boxPositions[1]} color={colors[1]} />
      <Box position={boxPositions[2]} color={colors[2]} />
      <Box position={boxPositions[3]} color={colors[3]} />
      <Box position={boxPositions[4]} color={colors[4]} />
      <Box position={boxPositions[5]} color={colors[5]} />
      <Box position={boxPositions[6]} color={colors[6]} />
      <Box position={boxPositions[7]} color={colors[7]} />
    </group>
  );
  return <group dispose={null}>{!doNotRender && box}</group>;
}

const SENSOR_POSITION = {
  bottomFront: [0, -75, 50],
  bottomLeft: [75, 0, 50],
  bottomRear: [0, 75, 50],
  bottomRight: [-75, 0, 50],
  topFront: [0, -75, 100],
  topLeft: [75, 0, 100],
  topRear: [0, 75, 100],
  topRight: [-75, 0, 100],
};
const SENSOR_ROTATION = {
  bottomFront: [0, 0, 0],
  bottomLeft: [0, 0, Math.PI / 2],
  bottomRear: [0, 0, 0],
  bottomRight: [0, 0, Math.PI / 2],
  topFront: [0, 0, 0],
  topLeft: [0, 0, Math.PI / 2],
  topRear: [0, 0, 0],
  topRight: [0, 0, Math.PI / 2],
};

function computeLineSensorOrientation(
  url: CloudProduceAddress
): { position: number[]; rotation: number[] } {
  const verticalOrientation = getAt(url, -2);
  const planarOrientation = getAt(url, -1);
  switch (verticalOrientation) {
    case TugSubsystem.BOTTOM_SENSORS: {
      switch (planarOrientation) {
        case TugSensorsSubsystem.FRONT_LINE_SENSOR: {
          return {
            position: SENSOR_POSITION.bottomFront,
            rotation: SENSOR_ROTATION.bottomFront,
          };
        }
        case TugSensorsSubsystem.LEFT_LINE_SENSOR: {
          return {
            position: SENSOR_POSITION.bottomLeft,
            rotation: SENSOR_ROTATION.bottomLeft,
          };
        }
        case TugSensorsSubsystem.REAR_LINE_SENSOR: {
          return {
            position: SENSOR_POSITION.bottomRear,
            rotation: SENSOR_ROTATION.bottomRear,
          };
        }
        case TugSensorsSubsystem.RIGHT_LINE_SENSOR: {
          return {
            position: SENSOR_POSITION.bottomRight,
            rotation: SENSOR_ROTATION.bottomRight,
          };
        }
      }
    }
    case TugSubsystem.TOP_SENSORS: {
      switch (planarOrientation) {
        case TugSensorsSubsystem.FRONT_LINE_SENSOR: {
          return {
            position: SENSOR_POSITION.topFront,
            rotation: SENSOR_ROTATION.topFront,
          };
        }
        case TugSensorsSubsystem.LEFT_LINE_SENSOR: {
          return {
            position: SENSOR_POSITION.topLeft,
            rotation: SENSOR_ROTATION.topLeft,
          };
        }
        case TugSensorsSubsystem.REAR_LINE_SENSOR: {
          return {
            position: SENSOR_POSITION.topRear,
            rotation: SENSOR_ROTATION.topRear,
          };
        }
        case TugSensorsSubsystem.RIGHT_LINE_SENSOR: {
          return {
            position: SENSOR_POSITION.topRight,
            rotation: SENSOR_ROTATION.topRight,
          };
        }
      }
    }
    default: {
      throw new Error('Line Sensor not found');
    }
  }
}
