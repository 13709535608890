import { CloudProduceAddress } from 'model/src/common/CloudProduceAddress';

export enum OperationType {
  ALIGNMENT = 'ALIGNMENT',
  PLACEMENT = 'PLACEMENT',
  NONE = 'NONE',
}

export type PlacementOperation = {
  targetUrl?: CloudProduceAddress;
  placementLocationUrl?: CloudProduceAddress;
};

export type AlignmentOperation = {
  targetUrl?: CloudProduceAddress;
};
