/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';

import { useGLTF } from '@react-three/drei';

import LampsonDecalsModel from './LampsonDecals.glb';

export default function LampsonDecals({ ...props }) {
  const group = useRef();
  const { nodes } = useGLTF(LampsonDecalsModel) as GLTF;
  const material = (
    <meshPhongMaterial
      attach="material"
      color={props.color}
      transparent={props.transparent}
      opacity={props.opacity}
    />
  );
  return (
    <group ref={group} {...props} dispose={null} scale={[1.0, 1.0, 20.0]}>
      <mesh geometry={nodes.mesh_0.geometry}>{material}</mesh>
      <mesh geometry={nodes.mesh_1.geometry}>{material}</mesh>
      <mesh geometry={nodes.mesh_2.geometry}>{material}</mesh>
      <mesh geometry={nodes.mesh_3.geometry}>{material}</mesh>
      <mesh geometry={nodes.mesh_4.geometry}>{material}</mesh>
      <mesh geometry={nodes.mesh_5.geometry}>{material}</mesh>
      <mesh geometry={nodes.mesh_6.geometry}>{material}</mesh>
      <mesh geometry={nodes.mesh_7.geometry}>{material}</mesh>
      <mesh geometry={nodes.mesh_8.geometry}>{material}</mesh>
      <mesh geometry={nodes.mesh_9.geometry}>{material}</mesh>
      <mesh geometry={nodes.mesh_10.geometry}>{material}</mesh>
      <mesh geometry={nodes.mesh_11.geometry}>{material}</mesh>
      <mesh geometry={nodes.mesh_12.geometry}>{material}</mesh>
      <mesh geometry={nodes.mesh_13.geometry}>{material}</mesh>
      <mesh geometry={nodes.mesh_14.geometry}>{material}</mesh>
      <mesh geometry={nodes.mesh_15.geometry}>{material}</mesh>
      <mesh geometry={nodes.mesh_16.geometry}>{material}</mesh>
      <mesh geometry={nodes.mesh_17.geometry}>{material}</mesh>
      <mesh geometry={nodes.mesh_18.geometry}>{material}</mesh>
      <mesh geometry={nodes.mesh_19.geometry}>{material}</mesh>
    </group>
  );
}

useGLTF.preload(LampsonDecalsModel);
