import { fullString, getAt } from 'model/src/common/CloudProduceAddressUtility';
import { TugSubsystem } from 'model/src/common/Systems';
import React, {
  Suspense,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { ControllerContext } from '../../../farmviewer/context/controllercontext/ControllerContext';
import Box from '../../common/Box';
import DrivetrainGroup from '../../tug/Drivetrain';
import MecanumWheel from './MecanumWheel';

export default function DriveTrain(props) {
  const [hovered, setHover] = useState(false);
  const underInspection = props.inspectionContext.inspectionMap.get(
    fullString(props.url)
  ).underInspection;
  const group = useRef();

  const controllerContext = useContext(ControllerContext);

  const orientation: {
    position: number[];
    rotation: number[];
  } = useMemo(() => {
    const tugWheelPosition = getAt(props.url, -1);
    let leftSide: boolean;
    let frontSide: boolean;
    switch (tugWheelPosition) {
      case TugSubsystem.LF: {
        leftSide = true;
        frontSide = true;
        break;
      }
      case TugSubsystem.LR: {
        leftSide = true;
        frontSide = false;
        break;
      }
      case TugSubsystem.RF: {
        leftSide = false;
        frontSide = true;
        break;
      }
      case TugSubsystem.RR: {
        leftSide = false;
        frontSide = false;
        break;
      }
    }
    return {
      position: [
        (props.leftSide ? 1 : -1) * 40.0253,
        (props.frontSide ? -1 : 1) * 42.52,
        4,
      ],
      rotation: [0, 0, ((props.leftSide ? -1 : 1) * Math.PI) / 2],
    };
  }, [props.url]);

  const onPointerOver = controllerContext.dragMute(event => {
    setHover(true);
    event.stopPropagation();
  }, controllerContext);

  useEffect(() => {
    props.objectMap.set(fullString(props.url), {
      url: fullString(props.url),
      model: group.current,
    });
    return () => {
      props.objectMap.delete(props.url);
    };
  });

  return (
    <group
      ref={group}
      position={props.position}
      onClick={e => {
        props.onClick(fullString(props.url));
        e.stopPropagation();
      }}
      onPointerOver={onPointerOver}
      onPointerOut={event => {
        setHover(false);
        event.stopPropagation();
      }}>
      <Suspense fallback={<Box />}>
        <DrivetrainGroup
          online={props.online}
          parentHovered={hovered || props.parentHovered}
          parentUnderInspection={underInspection || props.parentUnderInspection}
          position={orientation.position}
          rotation={orientation.rotation}
        />
        <MecanumWheel
          url={props.url}
          online={props.online}
          parentHovered={hovered || props.parentHovered}
          parentUnderInspection={underInspection || props.parentUnderInspection}
          pwm={props.pwm}
        />
      </Suspense>
    </group>
  );
}
