"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AcState = void 0;
var AcState;

(function (AcState) {
  AcState["UNKNOWN"] = "UNKNOWN";
  AcState["ON"] = "ON";
  AcState["OFF"] = "OFF";
  AcState["ON_NO_AC"] = "ON_NO_AC";
  AcState["OFF_NO_AC"] = "OFF_NO_AC";
})(AcState = exports.AcState || (exports.AcState = {}));