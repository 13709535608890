/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import * as THREE from 'three';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';

import { useGLTF } from '@react-three/drei';

import { RenderingType } from '../../RenderingType';
import materialFactory from '../../trolley/MaterialFactory';
import TierLightGantryZ_Model from './TierLightGantryZ.glb';

export default function TierLightGantryZ(props) {
  const group = useRef();
  const { nodes } = useGLTF(TierLightGantryZ_Model) as GLTF;

  const material: THREE.Material = materialFactory.generateMaterialObject(
    props.parentUnderInspection,
    props.parentHovered,
    props.online
  );

  return (
    <group ref={group} {...props} dispose={null}>
      <group
        position={[80.79, 0, 68.18]}
        rotation={[-Math.PI / 2, Math.PI / 2, 0]}>
        <group position={[0, 0, 0.11]}>
          <group
            position={[-1, 1, -0.7]}
            rotation={[Math.PI / 2, Math.PI / 3, Math.PI]}>
            <mesh
              geometry={nodes.Body1_22.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_22.material
                  : material
              }
            />
          </group>
          <group
            position={[1, 1, -0.7]}
            rotation={[-Math.PI / 2, Math.PI / 3, 0]}>
            <mesh
              geometry={nodes.Body1_23.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_23.material
                  : material
              }
            />
          </group>
          <group position={[0, 0, -0.6]} rotation={[-Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Body1_24.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_24.material
                  : material
              }
            />
          </group>
        </group>
        <group position={[-5.03, -5.03, -2.81]} rotation={[Math.PI / 2, 0, 0]}>
          <group rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_25.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_25.material
                  : material
              }
            />
          </group>
          <group position={[0, -0.3, 0]} rotation={[0, 1.43, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_26.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_26.material
                  : material
              }
            />
          </group>
          <group position={[0, -0.05, 0]} rotation={[0, 0.21, 0]}>
            <mesh
              geometry={nodes.Body1_27.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_27.material
                  : material
              }
            />
          </group>
          <group position={[0, -3.99, 0]} rotation={[0, -1.36, Math.PI]} />
          <group
            position={[0, 0.3, 0]}
            rotation={[Math.PI, -1.09, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_29.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_29.material
                  : material
              }
            />
          </group>
          <group position={[0, 0.55, 0]} rotation={[0, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_30.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_30.material
                  : material
              }
            />
          </group>
          <group position={[0, -0.55, 0]} rotation={[Math.PI, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_31.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_31.material
                  : material
              }
            />
          </group>
          <group position={[0, -1.25, 0]} rotation={[-Math.PI / 2, 0, 2.34]}>
            <mesh
              geometry={nodes.Body1_32.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_32.material
                  : material
              }
            />
          </group>
          <group position={[0, -2.3, 0]} rotation={[0, 1.43, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_33.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_33.material
                  : material
              }
            />
          </group>
          <group position={[0, -2.55, 0]} rotation={[Math.PI, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_34.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_34.material
                  : material
              }
            />
          </group>
          <group position={[0, -2.05, 0]} rotation={[0, 0.21, 0]}>
            <mesh
              geometry={nodes.Body1_35.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_35.material
                  : material
              }
            />
          </group>
          <group position={[0, -1.45, 0]} rotation={[0, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_36.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_36.material
                  : material
              }
            />
          </group>
          <group position={[0, -2, 0]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_37.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_37.material
                  : material
              }
            />
          </group>
          <group
            position={[0, -1.7, 0]}
            rotation={[Math.PI, -1.09, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_38.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_38.material
                  : material
              }
            />
          </group>
          <group position={[0, -1.35, 0]} rotation={[0, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_39.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_39.material
                  : material
              }
            />
          </group>
          <group position={[0, 0.65, 0]} rotation={[0, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_40.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_40.material
                  : material
              }
            />
          </group>
          <group
            position={[0, 1.75, 0]}
            rotation={[Math.PI / 2, 0, Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_41.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_41.material
                  : material
              }
            />
          </group>
          <group
            position={[0, -2.65, 0]}
            rotation={[Math.PI / 2, 0, Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_42.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_42.material
                  : material
              }
            />
          </group>
        </group>
        <group position={[0.05, -2, -7.08]} rotation={[Math.PI / 2, 0, 0]}>
          <mesh
            geometry={nodes.Body1.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1.material
                : material
            }
          />
        </group>
        <group position={[0.05, -2, 0.11]} rotation={[Math.PI / 2, 0, 0]}>
          <mesh
            geometry={nodes.Body1_1.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_1.material
                : material
            }
          />
        </group>
        <group position={[5.03, -2.03, -1.09]} rotation={[0, 0, Math.PI / 2]}>
          <mesh
            geometry={nodes.Body1_2.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_2.material
                : material
            }
          />
        </group>
        <group position={[-4.94, -1.97, -1.09]} rotation={[0, 0, Math.PI / 2]}>
          <mesh
            geometry={nodes.Body1_3.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_3.material
                : material
            }
          />
        </group>
        <group position={[4.94, -5.03, -2.81]} rotation={[Math.PI / 2, 0, 0]}>
          <group rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_43.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_43.material
                  : material
              }
            />
          </group>
          <group position={[0, -0.3, 0]} rotation={[0, 1.43, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_44.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_44.material
                  : material
              }
            />
          </group>
          <group position={[0, -0.05, 0]} rotation={[0, 0.21, 0]}>
            <mesh
              geometry={nodes.Body1_45.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_45.material
                  : material
              }
            />
          </group>
          <group position={[0, -3.99, 0]} rotation={[0, -1.36, Math.PI]} />
          <group
            position={[0, 0.3, 0]}
            rotation={[Math.PI, -1.09, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_47.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_47.material
                  : material
              }
            />
          </group>
          <group position={[0, 0.55, 0]} rotation={[0, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_48.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_48.material
                  : material
              }
            />
          </group>
          <group position={[0, -0.55, 0]} rotation={[Math.PI, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_49.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_49.material
                  : material
              }
            />
          </group>
          <group position={[0, -1.25, 0]} rotation={[-Math.PI / 2, 0, 2.34]}>
            <mesh
              geometry={nodes.Body1_50.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_50.material
                  : material
              }
            />
          </group>
          <group position={[0, -2.3, 0]} rotation={[0, 1.43, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_51.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_51.material
                  : material
              }
            />
          </group>
          <group position={[0, -2.55, 0]} rotation={[Math.PI, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_52.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_52.material
                  : material
              }
            />
          </group>
          <group position={[0, -2.05, 0]} rotation={[0, 0.21, 0]}>
            <mesh
              geometry={nodes.Body1_53.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_53.material
                  : material
              }
            />
          </group>
          <group position={[0, -1.45, 0]} rotation={[0, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_54.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_54.material
                  : material
              }
            />
          </group>
          <group position={[0, -2, 0]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_55.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_55.material
                  : material
              }
            />
          </group>
          <group
            position={[0, -1.7, 0]}
            rotation={[Math.PI, -1.09, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_56.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_56.material
                  : material
              }
            />
          </group>
          <group position={[0, -1.35, 0]} rotation={[0, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_57.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_57.material
                  : material
              }
            />
          </group>
          <group position={[0, 0.65, 0]} rotation={[0, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_58.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_58.material
                  : material
              }
            />
          </group>
          <group
            position={[0, 1.75, 0]}
            rotation={[Math.PI / 2, 0, Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_59.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_59.material
                  : material
              }
            />
          </group>
          <group
            position={[0, -2.65, 0]}
            rotation={[Math.PI / 2, 0, Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_60.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_60.material
                  : material
              }
            />
          </group>
        </group>
        <group position={[4.94, 1.03, -2.81]} rotation={[Math.PI / 2, 0, 0]}>
          <group rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_61.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_61.material
                  : material
              }
            />
          </group>
          <group position={[0, -0.3, 0]} rotation={[0, 1.43, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_62.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_62.material
                  : material
              }
            />
          </group>
          <group position={[0, -0.05, 0]} rotation={[0, 0.21, 0]}>
            <mesh
              geometry={nodes.Body1_63.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_63.material
                  : material
              }
            />
          </group>
          <group position={[0, -3.99, 0]} rotation={[0, -1.36, Math.PI]} />
          <group
            position={[0, 0.3, 0]}
            rotation={[Math.PI, -1.09, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_65.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_65.material
                  : material
              }
            />
          </group>
          <group position={[0, 0.55, 0]} rotation={[0, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_66.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_66.material
                  : material
              }
            />
          </group>
          <group position={[0, -0.55, 0]} rotation={[Math.PI, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_67.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_67.material
                  : material
              }
            />
          </group>
          <group position={[0, -1.25, 0]} rotation={[-Math.PI / 2, 0, 2.34]}>
            <mesh
              geometry={nodes.Body1_68.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_68.material
                  : material
              }
            />
          </group>
          <group position={[0, -2.3, 0]} rotation={[0, 1.43, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_69.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_69.material
                  : material
              }
            />
          </group>
          <group position={[0, -2.55, 0]} rotation={[Math.PI, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_70.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_70.material
                  : material
              }
            />
          </group>
          <group position={[0, -2.05, 0]} rotation={[0, 0.21, 0]}>
            <mesh
              geometry={nodes.Body1_71.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_71.material
                  : material
              }
            />
          </group>
          <group position={[0, -1.45, 0]} rotation={[0, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_72.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_72.material
                  : material
              }
            />
          </group>
          <group position={[0, -2, 0]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_73.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_73.material
                  : material
              }
            />
          </group>
          <group
            position={[0, -1.7, 0]}
            rotation={[Math.PI, -1.09, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_74.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_74.material
                  : material
              }
            />
          </group>
          <group position={[0, -1.35, 0]} rotation={[0, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_75.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_75.material
                  : material
              }
            />
          </group>
          <group position={[0, 0.65, 0]} rotation={[0, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_76.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_76.material
                  : material
              }
            />
          </group>
          <group
            position={[0, 1.75, 0]}
            rotation={[Math.PI / 2, 0, Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_77.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_77.material
                  : material
              }
            />
          </group>
          <group
            position={[0, -2.65, 0]}
            rotation={[Math.PI / 2, 0, Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_78.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_78.material
                  : material
              }
            />
          </group>
        </group>
        <group position={[-5.03, 1.03, -2.81]} rotation={[Math.PI / 2, 0, 0]}>
          <group rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_79.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_79.material
                  : material
              }
            />
          </group>
          <group position={[0, -0.3, 0]} rotation={[0, 1.43, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_80.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_80.material
                  : material
              }
            />
          </group>
          <group position={[0, -0.05, 0]} rotation={[0, 0.21, 0]}>
            <mesh
              geometry={nodes.Body1_81.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_81.material
                  : material
              }
            />
          </group>
          <group position={[0, -3.99, 0]} rotation={[0, -1.36, Math.PI]} />
          <group
            position={[0, 0.3, 0]}
            rotation={[Math.PI, -1.09, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_83.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_83.material
                  : material
              }
            />
          </group>
          <group position={[0, 0.55, 0]} rotation={[0, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_84.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_84.material
                  : material
              }
            />
          </group>
          <group position={[0, -0.55, 0]} rotation={[Math.PI, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_85.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_85.material
                  : material
              }
            />
          </group>
          <group position={[0, -1.25, 0]} rotation={[-Math.PI / 2, 0, 2.34]}>
            <mesh
              geometry={nodes.Body1_86.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_86.material
                  : material
              }
            />
          </group>
          <group position={[0, -2.3, 0]} rotation={[0, 1.43, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_87.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_87.material
                  : material
              }
            />
          </group>
          <group position={[0, -2.55, 0]} rotation={[Math.PI, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_88.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_88.material
                  : material
              }
            />
          </group>
          <group position={[0, -2.05, 0]} rotation={[0, 0.21, 0]}>
            <mesh
              geometry={nodes.Body1_89.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_89.material
                  : material
              }
            />
          </group>
          <group position={[0, -1.45, 0]} rotation={[0, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_90.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_90.material
                  : material
              }
            />
          </group>
          <group position={[0, -2, 0]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_91.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_91.material
                  : material
              }
            />
          </group>
          <group
            position={[0, -1.7, 0]}
            rotation={[Math.PI, -1.09, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_92.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_92.material
                  : material
              }
            />
          </group>
          <group position={[0, -1.35, 0]} rotation={[0, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_93.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_93.material
                  : material
              }
            />
          </group>
          <group position={[0, 0.65, 0]} rotation={[0, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_94.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_94.material
                  : material
              }
            />
          </group>
          <group
            position={[0, 1.75, 0]}
            rotation={[Math.PI / 2, 0, Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_95.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_95.material
                  : material
              }
            />
          </group>
          <group
            position={[0, -2.65, 0]}
            rotation={[Math.PI / 2, 0, Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_96.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_96.material
                  : material
              }
            />
          </group>
        </group>
      </group>
      <group position={[0.64, 0, -5]}>
        <group position={[0, 1.78, 82.2]} rotation={[0, 0, Math.PI / 2]}>
          <mesh
            geometry={nodes.Body1_4.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_4.material
                : material
            }
          />
        </group>
        <group position={[0, 0, 83.52]}>
          <mesh
            geometry={nodes.Body1_5.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_5.material
                : material
            }
          />
        </group>
        <group position={[0, -62.42, 82.2]} rotation={[0, 0, Math.PI / 2]}>
          <mesh
            geometry={nodes.Body1_6.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_6.material
                : material
            }
          />
        </group>
        <group position={[0, -41.02, 82.2]} rotation={[0, 0, Math.PI / 2]}>
          <mesh
            geometry={nodes.Body1_7.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_7.material
                : material
            }
          />
        </group>
        <group position={[-0.7, 0, 83.52]} rotation={[-Math.PI, 0, -Math.PI]}>
          <mesh
            geometry={nodes.Body1_8.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_8.material
                : material
            }
          />
        </group>
        <group position={[0, 55.28, 82.2]} rotation={[0, 0, Math.PI / 2]}>
          <mesh
            geometry={nodes.Body1_9.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_9.material
                : material
            }
          />
        </group>
        <group position={[0, 44.58, 82.2]} rotation={[0, 0, Math.PI / 2]}>
          <mesh
            geometry={nodes.Body1_10.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_10.material
                : material
            }
          />
        </group>
        <group position={[0, 33.88, 82.2]} rotation={[0, 0, Math.PI / 2]}>
          <mesh
            geometry={nodes.Body1_11.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_11.material
                : material
            }
          />
        </group>
        <group position={[0, 23.18, 82.2]} rotation={[0, 0, Math.PI / 2]}>
          <mesh
            geometry={nodes.Body1_12.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_12.material
                : material
            }
          />
        </group>
        <group position={[0, 12.48, 82.2]} rotation={[0, 0, Math.PI / 2]}>
          <mesh
            geometry={nodes.Body1_13.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_13.material
                : material
            }
          />
        </group>
        <group position={[0, -8.92, 82.2]} rotation={[0, 0, Math.PI / 2]}>
          <mesh
            geometry={nodes.Body1_14.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_14.material
                : material
            }
          />
        </group>
        <group position={[0, -19.62, 82.2]} rotation={[0, 0, Math.PI / 2]}>
          <mesh
            geometry={nodes.Body1_15.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_15.material
                : material
            }
          />
        </group>
        <group position={[0, -30.32, 82.2]} rotation={[0, 0, Math.PI / 2]}>
          <mesh
            geometry={nodes.Body1_16.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_16.material
                : material
            }
          />
        </group>
        <group position={[0, -51.72, 82.2]} rotation={[0, 0, Math.PI / 2]}>
          <mesh
            geometry={nodes.Body1_17.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_17.material
                : material
            }
          />
        </group>
        <group
          position={[-67.53, 56.03, 77.41]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}>
          <mesh
            geometry={nodes.EndPlate2040_Limit_Combined.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.EndPlate2040_Limit_Combined.material
                : material
            }
          />
        </group>
        <group
          position={[-66.22, 5.73, 78.72]}
          rotation={[-Math.PI / 2, 0, Math.PI / 4]}>
          <group position={[0, 1.85, 53]} rotation={[0, 0, -0.23]}>
            <mesh
              geometry={nodes.Body1_176.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_176.material
                  : material
              }
            />
          </group>
          <group position={[0, 1.85, 54.11]} rotation={[Math.PI, 0, 2.36]}>
            <mesh
              geometry={nodes['Body1_Steel_-_Satin'].geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes['Body1_Steel_-_Satin'].material
                  : material
              }
            />
            <mesh
              geometry={nodes['Body1_Coating_-_Black_Oxide'].geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes['Body1_Coating_-_Black_Oxide'].material
                  : material
              }
            />
            <mesh
              geometry={nodes['Body1_Aluminum_-_Satin'].geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes['Body1_Aluminum_-_Satin'].material
                  : material
              }
            />
          </group>
          <group position={[2.19, 1.85, 50.3]} rotation={[0, 0, 0.59]}>
            <mesh
              geometry={nodes.Body1_178.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_178.material
                  : material
              }
            />
          </group>
          <group position={[-2.19, 1.85, 54.5]} rotation={[0, -Math.PI / 2, 0]}>
            <mesh
              geometry={nodes.Body1_179.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_179.material
                  : material
              }
            />
          </group>
          <group position={[-2.19, 1.85, 50.3]} rotation={[0, 0, 0.55]}>
            <mesh
              geometry={nodes.Body1_180.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_180.material
                  : material
              }
            />
          </group>
          <group position={[0, 4.04, 50.3]} rotation={[0, 0, -0.05]}>
            <mesh
              geometry={nodes.Body1_181.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_181.material
                  : material
              }
            />
          </group>
          <group position={[0, 4.04, 54.5]} rotation={[0, -Math.PI / 2, 0]}>
            <mesh
              geometry={nodes.Body1_182.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_182.material
                  : material
              }
            />
          </group>
          <group position={[2.19, 1.85, 54.5]} rotation={[0, -Math.PI / 2, 0]}>
            <mesh
              geometry={nodes.Body1_183.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_183.material
                  : material
              }
            />
          </group>
        </group>
        <group
          position={[-72.35, 47.87, 78.81]}
          rotation={[Math.PI / 2, -Math.PI / 2, 0]}>
          <group position={[25, 2.4, -1]} rotation={[0, -Math.PI / 2, 0]} />
          <mesh
            geometry={nodes.Body1_97.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_97.material
                : material
            }
          />
        </group>
        <group
          position={[-67.53, -71.66, 77.41]}
          rotation={[-Math.PI / 2, 0, Math.PI / 2]}>
          <mesh
            geometry={nodes.EndPlate2040_Small.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.EndPlate2040_Small.material
                : material
            }
          />
        </group>
        <group
          position={[-72.35, 22.87, 78.81]}
          rotation={[Math.PI / 2, -Math.PI / 2, 0]}>
          <group position={[25, 2.4, -1]} rotation={[0, -Math.PI / 2, 0]} />
          <mesh
            geometry={nodes.Body1_98.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_98.material
                : material
            }
          />
        </group>
        <group
          position={[-72.35, -28.5, 78.81]}
          rotation={[Math.PI / 2, -Math.PI / 2, 0]}>
          <mesh
            geometry={nodes.Lighting_Spacer.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Lighting_Spacer.material
                : material
            }
          />
        </group>
        <group
          position={[-72.35, -68.5, 78.81]}
          rotation={[Math.PI / 2, -Math.PI / 2, 0]}>
          <mesh
            geometry={nodes.Lighting_Spacer_1.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Lighting_Spacer_1.material
                : material
            }
          />
        </group>
        <mesh
          geometry={nodes['2040_(1)_(1)_1'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2040_(1)_(1)_1'].material
              : material
          }
        />
        <group
          position={[67.35, 32.87, 78.81]}
          rotation={[Math.PI / 2, Math.PI / 2, 0]}>
          <group
            position={[4.17, 18.93, 1.51]}
            rotation={[0, -Math.PI / 2, 0]}
          />
          <mesh
            geometry={nodes.Body1_99.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_99.material
                : material
            }
          />
        </group>
        <group
          position={[67.35, -40.5, 78.81]}
          rotation={[Math.PI / 2, Math.PI / 2, 0]}>
          <group
            position={[19.06, 18.93, 1.51]}
            rotation={[0, -Math.PI / 2, 0]}
          />
          <mesh
            geometry={nodes.Body1_100.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_100.material
                : material
            }
          />
        </group>
        <group
          position={[62.53, 56.03, 77.41]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}>
          <mesh
            geometry={nodes.EndPlate2040_Limit_Combined_1.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.EndPlate2040_Limit_Combined_1.material
                : material
            }
          />
        </group>
        <group
          position={[61.22, 5.73, 78.72]}
          rotation={[-Math.PI / 2, 0, -Math.PI / 4]}>
          <group position={[0, 1.85, 53]} rotation={[0, 0, -0.23]}>
            <mesh
              geometry={nodes.Body1_188.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_188.material
                  : material
              }
            />
          </group>
          <group position={[0, 1.85, 54.11]} rotation={[Math.PI, 0, 2.36]}>
            <mesh
              geometry={nodes['Body1_Steel_-_Satin_1'].geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes['Body1_Steel_-_Satin_1'].material
                  : material
              }
            />
            <mesh
              geometry={nodes['Body1_Coating_-_Black_Oxide_1'].geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes['Body1_Coating_-_Black_Oxide_1'].material
                  : material
              }
            />
            <mesh
              geometry={nodes['Body1_Aluminum_-_Satin_1'].geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes['Body1_Aluminum_-_Satin_1'].material
                  : material
              }
            />
          </group>
          <group position={[2.19, 1.85, 50.3]} rotation={[0, 0, 0.59]}>
            <mesh
              geometry={nodes.Body1_190.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_190.material
                  : material
              }
            />
          </group>
          <group position={[-2.19, 1.85, 54.5]} rotation={[0, -Math.PI / 2, 0]}>
            <mesh
              geometry={nodes.Body1_191.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_191.material
                  : material
              }
            />
          </group>
          <group position={[-2.19, 1.85, 50.3]} rotation={[0, 0, 0.55]}>
            <mesh
              geometry={nodes.Body1_192.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_192.material
                  : material
              }
            />
          </group>
          <group position={[0, 4.04, 50.3]} rotation={[0, 0, -0.05]}>
            <mesh
              geometry={nodes.Body1_193.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_193.material
                  : material
              }
            />
          </group>
          <group position={[0, 4.04, 54.5]} rotation={[0, -Math.PI / 2, 0]}>
            <mesh
              geometry={nodes.Body1_194.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_194.material
                  : material
              }
            />
          </group>
          <group position={[2.19, 1.85, 54.5]} rotation={[0, -Math.PI / 2, 0]}>
            <mesh
              geometry={nodes.Body1_195.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_195.material
                  : material
              }
            />
          </group>
        </group>
        <group
          position={[62.53, -71.66, 77.41]}
          rotation={[-Math.PI / 2, 0, -Math.PI / 2]}>
          <mesh
            geometry={nodes.EndPlate2040_Small_1.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.EndPlate2040_Small_1.material
                : material
            }
          />
        </group>
        <mesh
          geometry={nodes['2040_1'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2040_1'].material
              : material
          }
        />
        <mesh
          geometry={nodes['2040_(2)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2040_(2)'].material
              : material
          }
        />
        <mesh
          geometry={nodes['2040_(1)_(2)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2040_(1)_(2)'].material
              : material
          }
        />
        <mesh
          geometry={nodes['2040_(1)_(1)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2040_(1)_(1)'].material
              : material
          }
        />
        <mesh
          geometry={nodes['2040'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2040'].material
              : material
          }
        />
        <mesh
          geometry={nodes['2020'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2020'].material
              : material
          }
        />
        <mesh
          geometry={nodes['2020_(1)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2020_(1)'].material
              : material
          }
        />
        <mesh
          geometry={nodes['2080_180_(1)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2080_180_(1)'].material
              : material
          }
        />
        <mesh
          geometry={nodes['2080_180'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2080_180'].material
              : material
          }
        />
      </group>
      <group
        position={[-80.79, 0, 68.18]}
        rotation={[-Math.PI / 2, -Math.PI / 2, 0]}>
        <group position={[0, 0, 0.11]}>
          <group
            position={[-1, 1, -0.7]}
            rotation={[Math.PI / 2, Math.PI / 3, Math.PI]}>
            <mesh
              geometry={nodes.Body1_101.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_101.material
                  : material
              }
            />
          </group>
          <group
            position={[1, 1, -0.7]}
            rotation={[-Math.PI / 2, Math.PI / 3, 0]}>
            <mesh
              geometry={nodes.Body1_102.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_102.material
                  : material
              }
            />
          </group>
          <group position={[0, 0, -0.6]} rotation={[-Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Body1_103.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_103.material
                  : material
              }
            />
          </group>
        </group>
        <group position={[-5.03, -5.03, -2.81]} rotation={[Math.PI / 2, 0, 0]}>
          <group rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_104.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_104.material
                  : material
              }
            />
          </group>
          <group position={[0, -0.3, 0]} rotation={[0, 1.43, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_105.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_105.material
                  : material
              }
            />
          </group>
          <group position={[0, -0.05, 0]} rotation={[0, 0.21, 0]}>
            <mesh
              geometry={nodes.Body1_106.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_106.material
                  : material
              }
            />
          </group>
          <group position={[0, -3.99, 0]} rotation={[0, -1.36, Math.PI]} />
          <group
            position={[0, 0.3, 0]}
            rotation={[-Math.PI, -1.09, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_108.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_108.material
                  : material
              }
            />
          </group>
          <group position={[0, 0.55, 0]} rotation={[0, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_109.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_109.material
                  : material
              }
            />
          </group>
          <group position={[0, -0.55, 0]} rotation={[Math.PI, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_110.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_110.material
                  : material
              }
            />
          </group>
          <group position={[0, -1.25, 0]} rotation={[-Math.PI / 2, 0, 2.34]}>
            <mesh
              geometry={nodes.Body1_111.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_111.material
                  : material
              }
            />
          </group>
          <group position={[0, -2.3, 0]} rotation={[0, 1.43, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_112.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_112.material
                  : material
              }
            />
          </group>
          <group position={[0, -2.55, 0]} rotation={[Math.PI, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_113.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_113.material
                  : material
              }
            />
          </group>
          <group position={[0, -2.05, 0]} rotation={[0, 0.21, 0]}>
            <mesh
              geometry={nodes.Body1_114.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_114.material
                  : material
              }
            />
          </group>
          <group position={[0, -1.45, 0]} rotation={[0, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_115.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_115.material
                  : material
              }
            />
          </group>
          <group position={[0, -2, 0]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_116.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_116.material
                  : material
              }
            />
          </group>
          <group
            position={[0, -1.7, 0]}
            rotation={[Math.PI, -1.09, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_117.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_117.material
                  : material
              }
            />
          </group>
          <group position={[0, -1.35, 0]} rotation={[0, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_118.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_118.material
                  : material
              }
            />
          </group>
          <group position={[0, 0.65, 0]} rotation={[0, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_119.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_119.material
                  : material
              }
            />
          </group>
          <group
            position={[0, 1.75, 0]}
            rotation={[Math.PI / 2, 0, Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_120.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_120.material
                  : material
              }
            />
          </group>
          <group
            position={[0, -2.65, 0]}
            rotation={[Math.PI / 2, 0, Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_121.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_121.material
                  : material
              }
            />
          </group>
        </group>
        <group position={[0.05, -2, -7.08]} rotation={[Math.PI / 2, 0, 0]}>
          <mesh
            geometry={nodes.Body1_18.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_18.material
                : material
            }
          />
        </group>
        <group position={[0.05, -2, 0.11]} rotation={[Math.PI / 2, 0, 0]}>
          <mesh
            geometry={nodes.Body1_19.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_19.material
                : material
            }
          />
        </group>
        <group position={[5.03, -2.03, -1.09]} rotation={[0, 0, Math.PI / 2]}>
          <mesh
            geometry={nodes.Body1_20.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_20.material
                : material
            }
          />
        </group>
        <group position={[-4.94, -1.97, -1.09]} rotation={[0, 0, Math.PI / 2]}>
          <mesh
            geometry={nodes.Body1_21.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_21.material
                : material
            }
          />
        </group>
        <group position={[4.94, -5.03, -2.81]} rotation={[Math.PI / 2, 0, 0]}>
          <group rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_122.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_122.material
                  : material
              }
            />
          </group>
          <group position={[0, -0.3, 0]} rotation={[0, 1.43, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_123.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_123.material
                  : material
              }
            />
          </group>
          <group position={[0, -0.05, 0]} rotation={[0, 0.21, 0]}>
            <mesh
              geometry={nodes.Body1_124.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_124.material
                  : material
              }
            />
          </group>
          <group position={[0, -3.99, 0]} rotation={[0, -1.36, Math.PI]} />
          <group
            position={[0, 0.3, 0]}
            rotation={[-Math.PI, -1.09, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_126.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_126.material
                  : material
              }
            />
          </group>
          <group position={[0, 0.55, 0]} rotation={[0, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_127.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_127.material
                  : material
              }
            />
          </group>
          <group position={[0, -0.55, 0]} rotation={[Math.PI, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_128.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_128.material
                  : material
              }
            />
          </group>
          <group position={[0, -1.25, 0]} rotation={[-Math.PI / 2, 0, 2.34]}>
            <mesh
              geometry={nodes.Body1_129.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_129.material
                  : material
              }
            />
          </group>
          <group position={[0, -2.3, 0]} rotation={[0, 1.43, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_130.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_130.material
                  : material
              }
            />
          </group>
          <group position={[0, -2.55, 0]} rotation={[Math.PI, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_131.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_131.material
                  : material
              }
            />
          </group>
          <group position={[0, -2.05, 0]} rotation={[0, 0.21, 0]}>
            <mesh
              geometry={nodes.Body1_132.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_132.material
                  : material
              }
            />
          </group>
          <group position={[0, -1.45, 0]} rotation={[0, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_133.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_133.material
                  : material
              }
            />
          </group>
          <group position={[0, -2, 0]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_134.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_134.material
                  : material
              }
            />
          </group>
          <group
            position={[0, -1.7, 0]}
            rotation={[Math.PI, -1.09, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_135.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_135.material
                  : material
              }
            />
          </group>
          <group position={[0, -1.35, 0]} rotation={[0, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_136.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_136.material
                  : material
              }
            />
          </group>
          <group position={[0, 0.65, 0]} rotation={[0, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_137.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_137.material
                  : material
              }
            />
          </group>
          <group
            position={[0, 1.75, 0]}
            rotation={[Math.PI / 2, 0, Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_138.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_138.material
                  : material
              }
            />
          </group>
          <group
            position={[0, -2.65, 0]}
            rotation={[Math.PI / 2, 0, Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_139.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_139.material
                  : material
              }
            />
          </group>
        </group>
        <group position={[4.94, 1.03, -2.81]} rotation={[Math.PI / 2, 0, 0]}>
          <group rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_140.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_140.material
                  : material
              }
            />
          </group>
          <group position={[0, -0.3, 0]} rotation={[0, 1.43, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_141.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_141.material
                  : material
              }
            />
          </group>
          <group position={[0, -0.05, 0]} rotation={[0, 0.21, 0]}>
            <mesh
              geometry={nodes.Body1_142.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_142.material
                  : material
              }
            />
          </group>
          <group position={[0, -3.99, 0]} rotation={[0, -1.36, Math.PI]} />
          <group
            position={[0, 0.3, 0]}
            rotation={[-Math.PI, -1.09, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_144.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_144.material
                  : material
              }
            />
          </group>
          <group position={[0, 0.55, 0]} rotation={[0, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_145.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_145.material
                  : material
              }
            />
          </group>
          <group position={[0, -0.55, 0]} rotation={[Math.PI, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_146.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_146.material
                  : material
              }
            />
          </group>
          <group position={[0, -1.25, 0]} rotation={[-Math.PI / 2, 0, 2.34]}>
            <mesh
              geometry={nodes.Body1_147.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_147.material
                  : material
              }
            />
          </group>
          <group position={[0, -2.3, 0]} rotation={[0, 1.43, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_148.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_148.material
                  : material
              }
            />
          </group>
          <group position={[0, -2.55, 0]} rotation={[Math.PI, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_149.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_149.material
                  : material
              }
            />
          </group>
          <group position={[0, -2.05, 0]} rotation={[0, 0.21, 0]}>
            <mesh
              geometry={nodes.Body1_150.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_150.material
                  : material
              }
            />
          </group>
          <group position={[0, -1.45, 0]} rotation={[0, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_151.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_151.material
                  : material
              }
            />
          </group>
          <group position={[0, -2, 0]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_152.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_152.material
                  : material
              }
            />
          </group>
          <group
            position={[0, -1.7, 0]}
            rotation={[Math.PI, -1.09, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_153.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_153.material
                  : material
              }
            />
          </group>
          <group position={[0, -1.35, 0]} rotation={[0, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_154.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_154.material
                  : material
              }
            />
          </group>
          <group position={[0, 0.65, 0]} rotation={[0, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_155.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_155.material
                  : material
              }
            />
          </group>
          <group
            position={[0, 1.75, 0]}
            rotation={[Math.PI / 2, 0, Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_156.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_156.material
                  : material
              }
            />
          </group>
          <group
            position={[0, -2.65, 0]}
            rotation={[Math.PI / 2, 0, Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_157.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_157.material
                  : material
              }
            />
          </group>
        </group>
        <group position={[-5.03, 1.03, -2.81]} rotation={[Math.PI / 2, 0, 0]}>
          <group rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_158.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_158.material
                  : material
              }
            />
          </group>
          <group position={[0, -0.3, 0]} rotation={[0, 1.43, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_159.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_159.material
                  : material
              }
            />
          </group>
          <group position={[0, -0.05, 0]} rotation={[0, 0.21, 0]}>
            <mesh
              geometry={nodes.Body1_160.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_160.material
                  : material
              }
            />
          </group>
          <group position={[0, -3.99, 0]} rotation={[0, -1.36, Math.PI]} />
          <group
            position={[0, 0.3, 0]}
            rotation={[-Math.PI, -1.09, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_162.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_162.material
                  : material
              }
            />
          </group>
          <group position={[0, 0.55, 0]} rotation={[0, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_163.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_163.material
                  : material
              }
            />
          </group>
          <group position={[0, -0.55, 0]} rotation={[Math.PI, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_164.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_164.material
                  : material
              }
            />
          </group>
          <group position={[0, -1.25, 0]} rotation={[-Math.PI / 2, 0, 2.34]}>
            <mesh
              geometry={nodes.Body1_165.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_165.material
                  : material
              }
            />
          </group>
          <group position={[0, -2.3, 0]} rotation={[0, 1.43, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_166.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_166.material
                  : material
              }
            />
          </group>
          <group position={[0, -2.55, 0]} rotation={[Math.PI, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_167.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_167.material
                  : material
              }
            />
          </group>
          <group position={[0, -2.05, 0]} rotation={[0, 0.21, 0]}>
            <mesh
              geometry={nodes.Body1_168.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_168.material
                  : material
              }
            />
          </group>
          <group position={[0, -1.45, 0]} rotation={[0, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_169.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_169.material
                  : material
              }
            />
          </group>
          <group position={[0, -2, 0]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_170.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_170.material
                  : material
              }
            />
          </group>
          <group
            position={[0, -1.7, 0]}
            rotation={[Math.PI, -1.09, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_171.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_171.material
                  : material
              }
            />
          </group>
          <group position={[0, -1.35, 0]} rotation={[0, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_172.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_172.material
                  : material
              }
            />
          </group>
          <group position={[0, 0.65, 0]} rotation={[0, 0.89, 0]}>
            <mesh
              geometry={nodes.Body1_173.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_173.material
                  : material
              }
            />
          </group>
          <group
            position={[0, 1.75, 0]}
            rotation={[Math.PI / 2, 0, Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_174.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_174.material
                  : material
              }
            />
          </group>
          <group
            position={[0, -2.65, 0]}
            rotation={[Math.PI / 2, 0, Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_175.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_175.material
                  : material
              }
            />
          </group>
        </group>
      </group>
      <mesh
        geometry={nodes['CBeam_48_(1)'].geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes['CBeam_48_(1)'].material
            : material
        }
      />
      <mesh
        geometry={nodes.CBeam_48.geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes.CBeam_48.material
            : material
        }
      />
    </group>
  );
}

useGLTF.preload(TierLightGantryZ_Model);
