/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import BasePlate2x2_Model from './BasePlate2x2.glb';

export default function BasePlate2x2({ ...props }) {
  const group = useRef()
  const { nodes } = useGLTF(BasePlate2x2_Model)
  const material = <meshPhongMaterial attach="material" color={props.color} transparent={props.transparent} opacity={props.opacity} />;
  return (
    <group ref={group} {...props} dispose={null} position={props.position} rotation={props.rotation}>
      <mesh geometry={nodes.mesh_0.geometry}>
        {material}
      </mesh>
      <mesh geometry={nodes.mesh_1.geometry}>
        {material}
      </mesh>
      <mesh geometry={nodes.mesh_2.geometry}>
        {material}
      </mesh>
      <mesh geometry={nodes.mesh_3.geometry}>
        {material}
      </mesh>
      <mesh geometry={nodes.mesh_4.geometry}>
        {material}
      </mesh>
    </group>
  )
}

BasePlate2x2.defaultProps = {
  position: [0, 0, 0],
  rotation: [0, 0, 0],
  transparent:false,
  opacity:1.0,
}

useGLTF.preload(BasePlate2x2_Model)
