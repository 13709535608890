import React from 'react';

import { Cone, Cylinder } from '@react-three/drei';

const MAX_CONE_DEPTH = 66.21;
const CONE_ANGLE_RAD = 0.5113815;
const CONE_OFFSET = 26.635;

export default function FishTankWater(props) {
  const coneFilled = props.depth > MAX_CONE_DEPTH;
  const coneHeight = coneFilled ? MAX_CONE_DEPTH : props.depth;
  const hypothenuse = coneHeight / Math.sin(CONE_ANGLE_RAD);
  const coneBase = hypothenuse * Math.cos(CONE_ANGLE_RAD);
  const conePosition = coneHeight / 2 + CONE_OFFSET;

  const cylinderHeight = coneFilled ? props.depth - coneHeight : 0;
  const cylinderPosition = conePosition + coneHeight / 2 + cylinderHeight;
  return (
    <group position={props.position} rotation={props.rotation}>
      {!coneFilled && (
        <Cone
          args={[coneBase, coneHeight, 64]}
          position={[0, 0, conePosition, coneFilled]}
          rotation={[-Math.PI / 2, 0, 0]}
          key={undefined}
          attach={undefined}
          attachArray={undefined}
          attachObject={undefined}
          onUpdate={undefined}
          up={undefined}
          scale={undefined}
          matrix={undefined}
          quaternion={undefined}
          layers={undefined}
          dispose={undefined}
          material={undefined}
          onClick={undefined}
          onContextMenu={undefined}
          onDoubleClick={undefined}
          onPointerUp={undefined}
          onPointerDown={undefined}
          onPointerOver={undefined}
          onPointerOut={undefined}
          onPointerEnter={undefined}
          onPointerLeave={undefined}
          onPointerMove={undefined}
          onPointerMissed={undefined}
          onPointerCancel={undefined}
          onWheel={undefined}
          geometry={undefined}
          id={undefined}
          lookAt={undefined}
          name={undefined}
          clear={undefined}
          add={undefined}
          visible={undefined}
          clone={undefined}
          copy={undefined}
          type={undefined}
          uuid={undefined}
          parent={undefined}
          modelViewMatrix={undefined}
          normalMatrix={undefined}
          matrixWorld={undefined}
          matrixAutoUpdate={undefined}
          matrixWorldNeedsUpdate={undefined}
          castShadow={undefined}
          receiveShadow={undefined}
          frustumCulled={undefined}
          renderOrder={undefined}
          animations={undefined}
          userData={undefined}
          customDepthMaterial={undefined}
          customDistanceMaterial={undefined}
          isObject3D={undefined}
          onBeforeRender={undefined}
          onAfterRender={undefined}
          applyMatrix4={undefined}
          applyQuaternion={undefined}
          setRotationFromAxisAngle={undefined}
          setRotationFromEuler={undefined}
          setRotationFromMatrix={undefined}
          setRotationFromQuaternion={undefined}
          rotateOnAxis={undefined}
          rotateOnWorldAxis={undefined}
          rotateX={undefined}
          rotateY={undefined}
          rotateZ={undefined}
          translateOnAxis={undefined}
          translateX={undefined}
          translateY={undefined}
          translateZ={undefined}
          localToWorld={undefined}
          worldToLocal={undefined}
          remove={undefined}
          getObjectById={undefined}
          getObjectByName={undefined}
          getObjectByProperty={undefined}
          getWorldPosition={undefined}
          getWorldQuaternion={undefined}
          getWorldScale={undefined}
          getWorldDirection={undefined}
          raycast={undefined}
          traverse={undefined}
          traverseVisible={undefined}
          traverseAncestors={undefined}
          updateMatrix={undefined}
          updateMatrixWorld={undefined}
          updateWorldMatrix={undefined}
          toJSON={undefined}
          addEventListener={undefined}
          hasEventListener={undefined}
          removeEventListener={undefined}
          dispatchEvent={undefined}
          morphTargetInfluences={undefined}
          morphTargetDictionary={undefined}
          isMesh={undefined}
          updateMorphTargets={undefined}>
          <meshPhongMaterial
            attach="material"
            color="#0000ff"
            opacity={0.5}
            transparent={true}
          />
        </Cone>
      )}
      {coneFilled && (
        <Cylinder
          args={[coneBase, coneBase, cylinderHeight, 64]}
          position={[0, 0, cylinderPosition]}
          rotation={[-Math.PI / 2, 0, 0]}
          key={undefined}
          attach={undefined}
          attachArray={undefined}
          attachObject={undefined}
          onUpdate={undefined}
          up={undefined}
          scale={undefined}
          matrix={undefined}
          quaternion={undefined}
          layers={undefined}
          dispose={undefined}
          material={undefined}
          onClick={undefined}
          onContextMenu={undefined}
          onDoubleClick={undefined}
          onPointerUp={undefined}
          onPointerDown={undefined}
          onPointerOver={undefined}
          onPointerOut={undefined}
          onPointerEnter={undefined}
          onPointerLeave={undefined}
          onPointerMove={undefined}
          onPointerMissed={undefined}
          onPointerCancel={undefined}
          onWheel={undefined}
          geometry={undefined}
          id={undefined}
          lookAt={undefined}
          name={undefined}
          clear={undefined}
          add={undefined}
          visible={undefined}
          clone={undefined}
          copy={undefined}
          type={undefined}
          uuid={undefined}
          parent={undefined}
          modelViewMatrix={undefined}
          normalMatrix={undefined}
          matrixWorld={undefined}
          matrixAutoUpdate={undefined}
          matrixWorldNeedsUpdate={undefined}
          castShadow={undefined}
          receiveShadow={undefined}
          frustumCulled={undefined}
          renderOrder={undefined}
          animations={undefined}
          userData={undefined}
          customDepthMaterial={undefined}
          customDistanceMaterial={undefined}
          isObject3D={undefined}
          onBeforeRender={undefined}
          onAfterRender={undefined}
          applyMatrix4={undefined}
          applyQuaternion={undefined}
          setRotationFromAxisAngle={undefined}
          setRotationFromEuler={undefined}
          setRotationFromMatrix={undefined}
          setRotationFromQuaternion={undefined}
          rotateOnAxis={undefined}
          rotateOnWorldAxis={undefined}
          rotateX={undefined}
          rotateY={undefined}
          rotateZ={undefined}
          translateOnAxis={undefined}
          translateX={undefined}
          translateY={undefined}
          translateZ={undefined}
          localToWorld={undefined}
          worldToLocal={undefined}
          remove={undefined}
          getObjectById={undefined}
          getObjectByName={undefined}
          getObjectByProperty={undefined}
          getWorldPosition={undefined}
          getWorldQuaternion={undefined}
          getWorldScale={undefined}
          getWorldDirection={undefined}
          raycast={undefined}
          traverse={undefined}
          traverseVisible={undefined}
          traverseAncestors={undefined}
          updateMatrix={undefined}
          updateMatrixWorld={undefined}
          updateWorldMatrix={undefined}
          toJSON={undefined}
          addEventListener={undefined}
          hasEventListener={undefined}
          removeEventListener={undefined}
          dispatchEvent={undefined}
          morphTargetInfluences={undefined}
          morphTargetDictionary={undefined}
          isMesh={undefined}
          updateMorphTargets={undefined}>
          <meshPhongMaterial
            attach="material"
            color="#0000ff"
            opacity={0.5}
            transparent={true}
          />
        </Cylinder>
      )}
    </group>
  );
}

FishTankWater.defaultProps = {
  position: [0, 0, 0],
  rotation: [0, 0, 0],
  depth: 45,
};
