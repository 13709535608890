import React, { useRef } from 'react';
import * as THREE from 'three';
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min';

import { useFrame } from '@react-three/fiber';

import { cssVar } from '../../..';
import { ViewCubeSegment } from './ViewCubeSegment';

const DEBUG = false;

export const ANGLE_TO_CORNER_FROM_EQUATOR = 0.6154797077;
export const ANGLE_TO_CORNER_FROM_POLE = 0.9553166138;

export enum CORNERS {
  TOP_RIGHT_FRONT = 0,
  TOP_LEFT_FRONT = 1,
  BOTTOM_RIGHT_FRONT = 2,
  BOTTOM_LEFT_FRONT = 3,
  TOP_RIGHT_BACK = 4,
  TOP_LEFT_BACK = 5,
  BOTTOM_RIGHT_BACK = 6,
  BOTTOM_LEFT_BACK = 7,
}

export enum EDGES {
  TOP_FRONT = 0,
  BOTTOM_FRONT = 1,
  RIGHT_FRONT = 2,
  LEFT_FRONT = 3,
  TOP_RIGHT = 4,
  BOTTOM_RIGHT = 5,
  TOP_LEFT = 6,
  BOTTOM_LEFT = 7,
  TOP_BACK = 8,
  BOTTOM_BACK = 9,
  RIGHT_BACK = 10,
  LEFT_BACK = 11,
}

export enum FACES {
  FRONT = 0,
  BACK = 1,
  TOP = 2,
  BOTTOM = 3,
  RIGHT = 4,
  LEFT = 5,
}

const EDGE_DIMENSION = 0.5;
const TOTAL_BOX_DIMENSION = 3;
const FACE_DIMENSION = TOTAL_BOX_DIMENSION - 2 * EDGE_DIMENSION;

const COMPONENT_OFFSET = 0.025;

const CORNER_SIZE = [
  EDGE_DIMENSION - COMPONENT_OFFSET,
  EDGE_DIMENSION - COMPONENT_OFFSET,
  EDGE_DIMENSION - COMPONENT_OFFSET,
];

const CORNER_VECTOR_MAGNITUDE = FACE_DIMENSION / 2 + EDGE_DIMENSION / 2;

const CORNER_ANGLES = new Map<CORNERS, number[]>([
  [CORNERS.TOP_RIGHT_FRONT, [-Math.PI / 4, 0, -Math.PI / 4]],
  [CORNERS.TOP_LEFT_FRONT, [-Math.PI / 4, 0, Math.PI / 4]],
  [CORNERS.BOTTOM_RIGHT_FRONT, [(-3 * Math.PI) / 4, 0, -Math.PI / 4]],
  [CORNERS.BOTTOM_LEFT_FRONT, [(-3 * Math.PI) / 4, 0, Math.PI / 4]],

  [CORNERS.TOP_RIGHT_BACK, [-Math.PI / 4, 0, (-3 * Math.PI) / 4]],
  [CORNERS.TOP_LEFT_BACK, [-Math.PI / 4, 0, (3 * Math.PI) / 4]],
  [CORNERS.BOTTOM_RIGHT_BACK, [(-3 * Math.PI) / 4, 0, (-3 * Math.PI) / 4]],
  [CORNERS.BOTTOM_LEFT_BACK, [(-3 * Math.PI) / 4, 0, (3 * Math.PI) / 4]],
]);

const CORNER_SPHERICAL_ANGLES = new Map<CORNERS, number[]>([
  [
    CORNERS.TOP_RIGHT_FRONT,
    [Math.PI / 2 + ANGLE_TO_CORNER_FROM_EQUATOR, Math.PI / 4],
  ],
  [
    CORNERS.TOP_LEFT_FRONT,
    [Math.PI / 2 + ANGLE_TO_CORNER_FROM_EQUATOR, -Math.PI / 4],
  ],
  [
    CORNERS.BOTTOM_RIGHT_FRONT,
    [Math.PI / 2 + ANGLE_TO_CORNER_FROM_EQUATOR, (Math.PI * 3) / 4],
  ],
  [
    CORNERS.BOTTOM_LEFT_FRONT,
    [Math.PI / 2 + ANGLE_TO_CORNER_FROM_EQUATOR, (-Math.PI * 3) / 4],
  ],

  [CORNERS.TOP_RIGHT_BACK, [ANGLE_TO_CORNER_FROM_POLE, Math.PI / 4]],
  [CORNERS.TOP_LEFT_BACK, [ANGLE_TO_CORNER_FROM_POLE, -Math.PI / 4]],
  [CORNERS.BOTTOM_RIGHT_BACK, [ANGLE_TO_CORNER_FROM_POLE, (Math.PI * 3) / 4]],
  [CORNERS.BOTTOM_LEFT_BACK, [ANGLE_TO_CORNER_FROM_POLE, (-Math.PI * 3) / 4]],
]);

const CORNER_POSITIONS = new Map<CORNERS, number[]>([
  [
    CORNERS.TOP_RIGHT_FRONT,
    [
      CORNER_VECTOR_MAGNITUDE,
      -CORNER_VECTOR_MAGNITUDE,
      CORNER_VECTOR_MAGNITUDE,
    ],
  ],
  [
    CORNERS.TOP_LEFT_FRONT,
    [
      -CORNER_VECTOR_MAGNITUDE,
      -CORNER_VECTOR_MAGNITUDE,
      CORNER_VECTOR_MAGNITUDE,
    ],
  ],
  [
    CORNERS.BOTTOM_RIGHT_FRONT,
    [
      CORNER_VECTOR_MAGNITUDE,
      -CORNER_VECTOR_MAGNITUDE,
      -CORNER_VECTOR_MAGNITUDE,
    ],
  ],
  [
    CORNERS.BOTTOM_LEFT_FRONT,
    [
      -CORNER_VECTOR_MAGNITUDE,
      -CORNER_VECTOR_MAGNITUDE,
      -CORNER_VECTOR_MAGNITUDE,
    ],
  ],
  [
    CORNERS.TOP_RIGHT_BACK,
    [CORNER_VECTOR_MAGNITUDE, CORNER_VECTOR_MAGNITUDE, CORNER_VECTOR_MAGNITUDE],
  ],
  [
    CORNERS.TOP_LEFT_BACK,
    [
      -CORNER_VECTOR_MAGNITUDE,
      CORNER_VECTOR_MAGNITUDE,
      CORNER_VECTOR_MAGNITUDE,
    ],
  ],
  [
    CORNERS.BOTTOM_RIGHT_BACK,
    [
      CORNER_VECTOR_MAGNITUDE,
      CORNER_VECTOR_MAGNITUDE,
      -CORNER_VECTOR_MAGNITUDE,
    ],
  ],
  [
    CORNERS.BOTTOM_LEFT_BACK,
    [
      -CORNER_VECTOR_MAGNITUDE,
      CORNER_VECTOR_MAGNITUDE,
      -CORNER_VECTOR_MAGNITUDE,
    ],
  ],
]);

const EDGE_ANGLES = new Map<EDGES, number[]>([
  [EDGES.TOP_FRONT, [-Math.PI / 4, 0, 0]],
  [EDGES.BOTTOM_FRONT, [(-3 * Math.PI) / 4, 0, 0]],

  [EDGES.TOP_BACK, [-Math.PI / 4, 0, Math.PI]],
  [EDGES.BOTTOM_BACK, [(-3 * Math.PI) / 4, 0, Math.PI]],

  [EDGES.TOP_RIGHT, [-Math.PI / 4, 0, -Math.PI / 2]],
  [EDGES.BOTTOM_RIGHT, [(-3 * Math.PI) / 4, 0, -Math.PI / 2]],

  [EDGES.TOP_LEFT, [-Math.PI / 4, 0, Math.PI / 2]],
  [EDGES.BOTTOM_LEFT, [(-3 * Math.PI) / 4, 0, Math.PI / 2]],

  [EDGES.RIGHT_FRONT, [-Math.PI / 2, 0, -Math.PI / 4]],
  [EDGES.LEFT_FRONT, [-Math.PI / 2, 0, Math.PI / 4]],

  [EDGES.RIGHT_BACK, [-Math.PI / 2, 0, (-3 * Math.PI) / 4]],
  [EDGES.LEFT_BACK, [-Math.PI / 2, 0, (3 * Math.PI) / 4]],
]);

const EDGE_SPHERICAL_ANGLES = new Map<EDGES, number[]>([
  [EDGES.TOP_FRONT, [(Math.PI * 3) / 4, 0]],
  [EDGES.BOTTOM_FRONT, [(Math.PI * 5) / 4, 0]],

  [EDGES.TOP_BACK, [(Math.PI * 1) / 4, 0]],
  [EDGES.BOTTOM_BACK, [(Math.PI * 7) / 4, 0]],

  [EDGES.TOP_RIGHT, [Math.PI / 2, Math.PI / 4]],
  [EDGES.BOTTOM_RIGHT, [Math.PI / 2, (Math.PI * 3) / 4]],

  [EDGES.TOP_LEFT, [Math.PI / 2, -Math.PI / 4]],
  [EDGES.BOTTOM_LEFT, [Math.PI / 2, (-Math.PI * 3) / 4]],

  [EDGES.RIGHT_FRONT, [(Math.PI * 3) / 4, Math.PI / 2]],
  [EDGES.LEFT_FRONT, [(Math.PI * 3) / 4, -Math.PI / 2]],

  [EDGES.RIGHT_BACK, [Math.PI / 4, Math.PI / 2]],
  [EDGES.LEFT_BACK, [Math.PI / 4, -Math.PI / 2]],
]);

const EDGE_POSITIONS = new Map<EDGES, number[]>([
  [EDGES.TOP_FRONT, [0, -CORNER_VECTOR_MAGNITUDE, CORNER_VECTOR_MAGNITUDE]],
  [EDGES.BOTTOM_FRONT, [0, -CORNER_VECTOR_MAGNITUDE, -CORNER_VECTOR_MAGNITUDE]],

  [EDGES.TOP_BACK, [0, CORNER_VECTOR_MAGNITUDE, CORNER_VECTOR_MAGNITUDE]],
  [EDGES.BOTTOM_BACK, [0, CORNER_VECTOR_MAGNITUDE, -CORNER_VECTOR_MAGNITUDE]],

  [EDGES.TOP_RIGHT, [CORNER_VECTOR_MAGNITUDE, 0, CORNER_VECTOR_MAGNITUDE]],
  [EDGES.BOTTOM_RIGHT, [CORNER_VECTOR_MAGNITUDE, 0, -CORNER_VECTOR_MAGNITUDE]],

  [EDGES.TOP_LEFT, [-CORNER_VECTOR_MAGNITUDE, 0, CORNER_VECTOR_MAGNITUDE]],
  [EDGES.BOTTOM_LEFT, [-CORNER_VECTOR_MAGNITUDE, 0, -CORNER_VECTOR_MAGNITUDE]],

  [EDGES.RIGHT_FRONT, [CORNER_VECTOR_MAGNITUDE, -CORNER_VECTOR_MAGNITUDE, 0]],
  [EDGES.LEFT_FRONT, [-CORNER_VECTOR_MAGNITUDE, -CORNER_VECTOR_MAGNITUDE, 0]],

  [EDGES.RIGHT_BACK, [CORNER_VECTOR_MAGNITUDE, CORNER_VECTOR_MAGNITUDE, 0]],
  [EDGES.LEFT_BACK, [-CORNER_VECTOR_MAGNITUDE, CORNER_VECTOR_MAGNITUDE, 0]],
]);

const EDGE_SIZES = new Map<EDGES, number[]>([
  [
    EDGES.TOP_FRONT,
    [
      FACE_DIMENSION - COMPONENT_OFFSET,
      EDGE_DIMENSION - COMPONENT_OFFSET,
      EDGE_DIMENSION - COMPONENT_OFFSET,
    ],
  ],
  [
    EDGES.BOTTOM_FRONT,
    [
      FACE_DIMENSION - COMPONENT_OFFSET,
      EDGE_DIMENSION - COMPONENT_OFFSET,
      EDGE_DIMENSION - COMPONENT_OFFSET,
    ],
  ],

  [
    EDGES.TOP_BACK,
    [
      FACE_DIMENSION - COMPONENT_OFFSET,
      EDGE_DIMENSION - COMPONENT_OFFSET,
      EDGE_DIMENSION - COMPONENT_OFFSET,
    ],
  ],
  [
    EDGES.BOTTOM_BACK,
    [
      FACE_DIMENSION - COMPONENT_OFFSET,
      EDGE_DIMENSION - COMPONENT_OFFSET,
      EDGE_DIMENSION - COMPONENT_OFFSET,
    ],
  ],

  [
    EDGES.TOP_RIGHT,
    [
      EDGE_DIMENSION - COMPONENT_OFFSET,
      FACE_DIMENSION - COMPONENT_OFFSET,
      EDGE_DIMENSION - COMPONENT_OFFSET,
    ],
  ],
  [
    EDGES.BOTTOM_RIGHT,
    [
      EDGE_DIMENSION - COMPONENT_OFFSET,
      FACE_DIMENSION - COMPONENT_OFFSET,
      EDGE_DIMENSION - COMPONENT_OFFSET,
    ],
  ],

  [
    EDGES.TOP_LEFT,
    [
      EDGE_DIMENSION - COMPONENT_OFFSET,
      FACE_DIMENSION - COMPONENT_OFFSET,
      EDGE_DIMENSION - COMPONENT_OFFSET,
    ],
  ],
  [
    EDGES.BOTTOM_LEFT,
    [
      EDGE_DIMENSION - COMPONENT_OFFSET,
      FACE_DIMENSION - COMPONENT_OFFSET,
      EDGE_DIMENSION - COMPONENT_OFFSET,
    ],
  ],

  [
    EDGES.RIGHT_FRONT,
    [
      EDGE_DIMENSION - COMPONENT_OFFSET,
      EDGE_DIMENSION - COMPONENT_OFFSET,
      FACE_DIMENSION - COMPONENT_OFFSET,
    ],
  ],
  [
    EDGES.LEFT_FRONT,
    [
      EDGE_DIMENSION - COMPONENT_OFFSET,
      EDGE_DIMENSION - COMPONENT_OFFSET,
      FACE_DIMENSION - COMPONENT_OFFSET,
    ],
  ],

  [
    EDGES.RIGHT_BACK,
    [
      EDGE_DIMENSION - COMPONENT_OFFSET,
      EDGE_DIMENSION - COMPONENT_OFFSET,
      FACE_DIMENSION - COMPONENT_OFFSET,
    ],
  ],
  [
    EDGES.LEFT_BACK,
    [
      EDGE_DIMENSION - COMPONENT_OFFSET,
      EDGE_DIMENSION - COMPONENT_OFFSET,
      FACE_DIMENSION - COMPONENT_OFFSET,
    ],
  ],
]);

const FACE_ANGLES = new Map<FACES, number[]>([
  [FACES.FRONT, [-Math.PI / 2, 0, 0]],
  [FACES.BACK, [-Math.PI / 2, 0, Math.PI]],
  [FACES.RIGHT, [-Math.PI / 2, 0, -Math.PI / 2]],
  [FACES.LEFT, [-Math.PI / 2, 0, Math.PI / 2]],
  [FACES.TOP, [0, 0, 0]],
  [FACES.BOTTOM, [-Math.PI, 0, 0]],
]);

const FACE_SPHERICAL_ANGLES = new Map<FACES, number[]>([
  [FACES.FRONT, [Math.PI, 0]], // Theta, Phi
  [FACES.BACK, [0, 0]],
  [FACES.RIGHT, [Math.PI / 2, Math.PI / 2]],
  [FACES.LEFT, [-Math.PI / 2, Math.PI / 2]],
  [FACES.TOP, [Math.PI / 2, 0]],
  [FACES.BOTTOM, [-Math.PI / 2, 0]],
]);

const FACE_POSITIONS = new Map<FACES, number[]>([
  [FACES.FRONT, [0, -CORNER_VECTOR_MAGNITUDE, 0]],
  [FACES.BACK, [0, CORNER_VECTOR_MAGNITUDE, 0]],
  [FACES.TOP, [0, 0, CORNER_VECTOR_MAGNITUDE]],
  [FACES.BOTTOM, [0, 0, -CORNER_VECTOR_MAGNITUDE]],
  [FACES.RIGHT, [CORNER_VECTOR_MAGNITUDE, 0, 0]],
  [FACES.LEFT, [-CORNER_VECTOR_MAGNITUDE, 0, 0]],
]);

const FACE_SIZES = new Map<FACES, number[]>([
  [
    FACES.FRONT,
    [
      FACE_DIMENSION - COMPONENT_OFFSET,
      EDGE_DIMENSION - COMPONENT_OFFSET,
      FACE_DIMENSION - COMPONENT_OFFSET,
    ],
  ],
  [
    FACES.BACK,
    [
      FACE_DIMENSION - COMPONENT_OFFSET,
      EDGE_DIMENSION - COMPONENT_OFFSET,
      FACE_DIMENSION - COMPONENT_OFFSET,
    ],
  ],
  [
    FACES.TOP,
    [
      FACE_DIMENSION - COMPONENT_OFFSET,
      FACE_DIMENSION - COMPONENT_OFFSET,
      EDGE_DIMENSION - COMPONENT_OFFSET,
    ],
  ],
  [
    FACES.BOTTOM,
    [
      FACE_DIMENSION - COMPONENT_OFFSET,
      FACE_DIMENSION - COMPONENT_OFFSET,
      EDGE_DIMENSION - COMPONENT_OFFSET,
    ],
  ],
  [
    FACES.RIGHT,
    [
      EDGE_DIMENSION - COMPONENT_OFFSET,
      FACE_DIMENSION - COMPONENT_OFFSET,
      FACE_DIMENSION - COMPONENT_OFFSET,
    ],
  ],

  [
    FACES.LEFT,
    [
      EDGE_DIMENSION - COMPONENT_OFFSET,
      FACE_DIMENSION - COMPONENT_OFFSET,
      FACE_DIMENSION - COMPONENT_OFFSET,
    ],
  ],
]);

export const ViewCube = ({ cameraMatrix, setViewCubeRotation }) => {
  const groupRef = useRef<THREE.Group>();
  const currentCameraAngleRef = useRef<THREE.Group>();
  const currentCameraRef = useRef<THREE.Mesh>();
  const targetCameraAngleRef = useRef<THREE.Group>();
  const targetCameraRef = useRef<THREE.Mesh>();

  useFrame(() => {
    TWEEN.update();
  });

  const animateTo = (
    targetRotation: THREE.Euler,
    sphericalTargetRotation: THREE.Spherical
  ) => {
    const currentCameraAngleMatrix = new THREE.Matrix4();
    currentCameraAngleMatrix.copy(groupRef.current.matrix).invert();
    if (DEBUG) {
      currentCameraAngleRef.current.quaternion.setFromRotationMatrix(
        currentCameraAngleMatrix
      );
    }

    const targetCameraAngleMatrix = new THREE.Matrix4()
      .makeRotationFromEuler(targetRotation)
      .invert();
    if (DEBUG) {
      targetCameraAngleRef.current.quaternion.setFromRotationMatrix(
        targetCameraAngleMatrix
      );
    }

    setViewCubeRotation(new THREE.Spherical(1, ...sphericalTargetRotation));
  };

  const corners: any[] = [];
  const edges: any[] = [];
  const faces: any[] = [];

  if (groupRef.current) {
    groupRef.current.quaternion.setFromRotationMatrix(cameraMatrix);
    const matrix = new THREE.Matrix4();
    matrix.copy(cameraMatrix).invert();

    if (DEBUG) {
      currentCameraAngleRef.current.quaternion.setFromRotationMatrix(matrix);
    }

    for (let corner in CORNERS) {
      corners.push(
        <ViewCubeSegment
          key={corner}
          name={CORNERS[corner]}
          position={CORNER_POSITIONS.get(Number(corner))}
          dimensions={CORNER_SIZE}
          targetRotation={CORNER_ANGLES.get(Number(corner))}
          sphericalTargetRotation={CORNER_SPHERICAL_ANGLES.get(Number(corner))}
          rotation={groupRef.current.rotation}
          animateTo={animateTo}
        />
      );
    }

    for (let edge in EDGES) {
      edges.push(
        <ViewCubeSegment
          key={edge}
          name={EDGES[edge]}
          position={EDGE_POSITIONS.get(Number(edge))}
          dimensions={EDGE_SIZES.get(Number(edge))}
          targetRotation={EDGE_ANGLES.get(Number(edge))}
          sphericalTargetRotation={EDGE_SPHERICAL_ANGLES.get(Number(edge))}
          rotation={groupRef.current.rotation}
          animateTo={animateTo}
        />
      );
    }

    for (let face in FACES) {
      faces.push(
        <ViewCubeSegment
          key={face}
          name={FACES[face]}
          position={FACE_POSITIONS.get(Number(face))}
          dimensions={FACE_SIZES.get(Number(face))}
          targetRotation={FACE_ANGLES.get(Number(face))}
          sphericalTargetRotation={FACE_SPHERICAL_ANGLES.get(Number(face))}
          rotation={groupRef.current.rotation}
          animateTo={animateTo}
        />
      );
    }
  }

  return (
    <group ref={groupRef}>
      {corners}
      {edges}
      {faces}
      {DEBUG && (
        <group ref={targetCameraAngleRef} display="none">
          <mesh ref={targetCameraRef} position={[0, 0, 1]}>
            <boxBufferGeometry args={[0.5, 0.5, 3]} attach="geometry" />
            <meshPhongMaterial attach="material" color={0xffff00} />
          </mesh>
        </group>
      )}
      {DEBUG && (
        <group ref={currentCameraAngleRef} display="none">
          <mesh ref={currentCameraRef} position={[0, 0, 1]}>
            <boxBufferGeometry args={[0.5, 0.5, 3]} attach="geometry" />
            <meshPhongMaterial attach="material" color={0xff00ff} />
          </mesh>
        </group>
      )}
    </group>
  );
};
