"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FillCycleState = void 0;
var FillCycleState;

(function (FillCycleState) {
  FillCycleState["CYCLING"] = "CYCLING";
  FillCycleState["DONE_CYCLING"] = "DONE_CYCLING";
  FillCycleState["EMPTY"] = "EMPTY";
  FillCycleState["EMPTYING"] = "EMPTYING";
  FillCycleState["EMPTYING_WITH_INGRESS"] = "EMPTYING_WITH_INGRESS";
  FillCycleState["EVALUATING"] = "EVALUATING";
  FillCycleState["OPENING_VALVE"] = "OPENING_VALVE";
  FillCycleState["CLOSING_VALVE"] = "CLOSING_VALVE";
  FillCycleState["FILLING"] = "FILLING";
  FillCycleState["FILLING_NO_INGRESS"] = "FILLING_NO_INGRESS";
  FillCycleState["FULL"] = "FULL";
  FillCycleState["INITIALIZING"] = "INITIALIZING";
  FillCycleState["PASS_THROUGH"] = "PASS_THROUGH";
  FillCycleState["UNKNOWN"] = "UNKNOWN";
})(FillCycleState = exports.FillCycleState || (exports.FillCycleState = {}));