"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PhysicalSensorState = exports.PREFIX = void 0;
exports.PREFIX = '_physical_sensor__';
var PhysicalSensorState;

(function (PhysicalSensorState) {
  PhysicalSensorState["ON"] = "ON";
  PhysicalSensorState["OFF"] = "OFF";
  PhysicalSensorState["MIDDLE"] = "MIDDLE";
})(PhysicalSensorState = exports.PhysicalSensorState || (exports.PhysicalSensorState = {}));