import { CloudProduceAddress } from 'model/src/common/CloudProduceAddress';
import {
  combine,
  create,
  fullString,
} from 'model/src/common/CloudProduceAddressUtility';
import * as FarmConstants from 'model/src/common/FarmConstants';
import { AtomicReading } from 'model/src/dataflowprotocol/AtomicReading';
import Reading from 'model/src/dataflowprotocol/Reading';
import {
  NavigationalIntent,
  NavigationalIntentType,
} from 'model/src/navigation/NavigationalIntent';
import { AutonomyStatus } from 'model/src/series/AutonomyStatus';
import { CurrentNavigationalIntentReading } from 'model/src/series/CurrentNavigationalIntentReading';
import { SeriesType } from 'model/src/series/SeriesTypes';
import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';

import { CSG } from '@enable3d/three-graphics/jsm/csg';
import { useFrame } from '@react-three/fiber';

import { cssVar } from '../../../..';
import useSvg from '../../../../common/useSvg';
import { useDataMap } from '../../../farmviewer/context/datamapcontext/useDataMap';

const insideSize_cm = FarmConstants.TUG_SIDE_LENGTH_CM;
const insideConstrictionPeriod_s = 1.5;

const outsideSize_cm = insideSize_cm * 1.25;
const orbitRadius_cm = insideSize_cm * 1.125;
const orbitPeriod_s = 5;
const discDepth_cm = 2;

export type TugStatusDiscProps = {
  url: CloudProduceAddress;
  parentHovered: boolean;
  parentUnderInspection: boolean;
};

export default function TugStatusDisc(props: TugStatusDiscProps) {
  const [orbitAngle, setOrbitAngle] = useState(0);
  const [satellitePosition, setSatellitePosition] = useState({
    x_cm: orbitRadius_cm,
    y_cm: 0,
    z_cm: 0,
  });

  const [insideConstriction_cm, setInsideConstriction_cm] = useState(0);

  const currentNavigationalIntentReading = useDataMap(
    combine(props.url, create([SeriesType.CURRENT_NAVIGATIONAL_INTENT]))
  );

  const [currentNavigationalIntent, setCurrentNavigationalIntent] = useState(
    ((currentNavigationalIntentReading as unknown) as CurrentNavigationalIntentReading)
      .intent
  );
  useEffect(() => {
    setCurrentNavigationalIntent(
      ((currentNavigationalIntentReading as unknown) as CurrentNavigationalIntentReading)
        .intent
    );
  }, [currentNavigationalIntentReading]);

  const autonomyStatusReading = useDataMap(
    combine(props.url, create([SeriesType.AUTONOMY_STATUS]))
  );
  const autonomyStatus = autonomyStatusReading.value;

  const position = [0, 0, -1];

  const color = chooseColor(props);
  const group = useRef<THREE.Group>();

  const arrowSvgMesh: THREE.Mesh = useSvg(
    '/svg/tug/arrow.svg',
    discDepth_cm,
    (mesh: THREE.Mesh) => {
      let rotationZ = 0;
      switch (currentNavigationalIntent.type) {
        case NavigationalIntentType.FORWARD: {
          rotationZ = -Math.PI / 2;
          break;
        }
        case NavigationalIntentType.LEFT: {
          rotationZ = 0;
          break;
        }
        case NavigationalIntentType.RIGHT: {
          rotationZ = Math.PI;
          break;
        }
        case NavigationalIntentType.BACK: {
          rotationZ = Math.PI / 2;
          break;
        }
      }
      mesh.rotateZ(rotationZ);
      mesh.translateX(-50);
      mesh.translateY(-50);
      mesh.translateX(200);
      mesh.material = new THREE.MeshPhongMaterial({ color: color });
    },
    [color, currentNavigationalIntentReading]
  );

  let autonomyStatusSvgUrl = '/svg/tug/error.svg';
  switch (autonomyStatus) {
    case AutonomyStatus.Autonomous: {
      autonomyStatusSvgUrl = '/svg/tug/autonomous.svg';
      break;
    }
    case AutonomyStatus.AutonomyAvailable: {
      autonomyStatusSvgUrl = '/svg/tug/autonomyAvailable.svg';
      break;
    }
    case AutonomyStatus.AutonomyUnavailable: {
      autonomyStatusSvgUrl = '/svg/tug/autonomyUnavailable.svg';
      break;
    }
  }
  const autonomousModeSvgMesh: THREE.Mesh = useSvg(
    autonomyStatusSvgUrl,
    discDepth_cm,
    mesh => {
      mesh.scale.y *= -1;
      mesh.scale.x *= 0.6;
      mesh.scale.y *= 0.6;
      mesh.scale.z *= 1;
      mesh.translateX(-30);
      mesh.translateY(30);
      mesh.material = new THREE.MeshPhongMaterial({
        color: color,
      });
    },
    [color, autonomyStatus]
  );

  useFrame((state, delta) => {
    let newConstriction_cm =
      insideConstriction_cm +
      (delta * insideSize_cm) / insideConstrictionPeriod_s;
    if (newConstriction_cm >= insideSize_cm) {
      newConstriction_cm = 0;
    }
    setInsideConstriction_cm(newConstriction_cm);
    const angleDelta = (2 * Math.PI * delta) / orbitPeriod_s;
    let newAngle = orbitAngle + angleDelta;
    if (newAngle > 2 * Math.PI) {
      newAngle -= 2 * Math.PI;
    }
    const satellitePositionX_cm = Math.cos(newAngle) * orbitRadius_cm;
    const satellitePositionY_cm = Math.sin(newAngle) * orbitRadius_cm;
    setOrbitAngle(newAngle);
    setSatellitePosition({
      x_cm: satellitePositionX_cm,
      y_cm: satellitePositionY_cm,
      z_cm: 0,
    });
  });

  useEffect(() => {
    if (group.current) {
      group.current.clear();
      const outside = new THREE.Mesh(
        new THREE.CylinderGeometry(
          outsideSize_cm,
          outsideSize_cm,
          discDepth_cm / 2,
          32
        )
      );
      outside.rotateX(Math.PI / 2);

      const inside = new THREE.Mesh(
        new THREE.CylinderGeometry(
          insideSize_cm -
            (currentNavigationalIntent.type === NavigationalIntentType.CENTER
              ? insideConstriction_cm
              : 0),
          insideSize_cm -
            (currentNavigationalIntent.type === NavigationalIntentType.CENTER
              ? insideConstriction_cm
              : 0),
          discDepth_cm / 2,
          32
        )
      );
      inside.rotateX(Math.PI / 2);

      const satelliteGroup = new THREE.Group();
      satelliteGroup.rotateZ(-Math.PI / 2);
      satelliteGroup.position.set(
        satellitePosition.x_cm,
        satellitePosition.y_cm,
        satellitePosition.z_cm
      );

      const satelliteCutOut = new THREE.Mesh(
        new THREE.CylinderGeometry(40, 40, discDepth_cm, 32)
      );
      satelliteCutOut.rotateX(-Math.PI / 2);
      satelliteCutOut.translateX(satellitePosition.x_cm);
      satelliteCutOut.translateY(satellitePosition.z_cm);
      satelliteCutOut.translateZ(satellitePosition.y_cm);

      if (autonomousModeSvgMesh) {
        satelliteGroup.add(autonomousModeSvgMesh);
      }

      const stencil = CSG.union(inside, satelliteCutOut);
      const sub = CSG.subtract(outside, stencil);

      sub.material = new THREE.MeshPhongMaterial({
        color: color,
      });

      group.current.add(sub);
      group.current.add(satelliteGroup);
      if (
        arrowSvgMesh &&
        (currentNavigationalIntent.type === NavigationalIntentType.FORWARD ||
          currentNavigationalIntent.type === NavigationalIntentType.BACK ||
          currentNavigationalIntent.type === NavigationalIntentType.LEFT ||
          currentNavigationalIntent.type === NavigationalIntentType.RIGHT)
      ) {
        group.current.add(arrowSvgMesh);
      }
    }
  });

  if (props.parentUnderInspection) {
    return (
      <group
        ref={group}
        position={position}
        scale={[
          FarmConstants.TUG_SIDE_LENGTH_CM /
            FarmConstants.GRID_NODE_SIDE_LENGTH_CM,
          FarmConstants.TUG_SIDE_LENGTH_CM /
            FarmConstants.GRID_NODE_SIDE_LENGTH_CM,
          1,
        ]}
      />
    );
  } else {
    return <group />;
  }
}

function chooseColor(props) {
  if (props.parentUnderInspection) {
    return cssVar('--selected-color');
  } else if (props.parentHovered) {
    return cssVar('--hovered-color');
  } else {
    return cssVar('--standard-color');
  }
}
