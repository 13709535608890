import React from 'react';

import { cssVar } from '../../..';
import CommandCenterSupport from './CommandCenterSupport';
import CrossBeam from './CrossBeam';
import Pillar from './Pillar';
import SideBeam from './SideBeam';
import SideSupport from './SideSupport';
import Stack from './Stack';

export default function Truss(props) {
  return (
    <group>
      <Pillar
        position={[200, 937.5, 0]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />
      <Pillar
        position={[575, 937.5, 0]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />

      <Pillar
        position={[-225, 1187.5, 0]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />
      <Pillar
        position={[-225, 1562.5, 0]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />
      <Pillar
        position={[-225, 1937.5, 0]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />
      <Pillar
        position={[-225, 2312.5, 0]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />
      <Pillar
        position={[-225, 2687.5, 0]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />

      <Pillar
        position={[1000, 1187.5, 0]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />
      <Pillar
        position={[1000, 1562.5, 0]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />
      <Pillar
        position={[1000, 1937.5, 0]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />
      <Pillar
        position={[1000, 2312.5, 0]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />
      <Pillar
        position={[1000, 2687.5, 0]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />

      <Stack
        position={[200, 937.5, 525.635]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />
      <Stack
        position={[575, 937.5, 525.635]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />

      <CrossBeam
        position={[525, 937.5, 525.635]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />
      <CrossBeam
        position={[525, 1187.5, 525.635]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />
      <CrossBeam
        position={[525, 1562.5, 525.635]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />
      <CrossBeam
        position={[525, 1937.5, 525.635]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />
      <CrossBeam
        position={[525, 2312.5, 525.635]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />
      <CrossBeam
        position={[525, 2687.5, 525.635]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />

      <SideBeam
        position={[175, 1187.5, 525.635]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />
      <SideBeam
        position={[175, 1562.5, 525.635]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />
      <SideBeam
        position={[175, 1937.5, 525.635]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />
      <SideBeam
        position={[175, 2312.5, 525.635]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />
      <SideBeam
        position={[175, 2687.5, 525.635]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />

      <SideBeam
        position={[600, 1187.5, 525.635]}
        rotation={[0, 0, Math.PI]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />
      <SideBeam
        position={[600, 1562.5, 525.635]}
        rotation={[0, 0, Math.PI]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />
      <SideBeam
        position={[600, 1937.5, 525.635]}
        rotation={[0, 0, Math.PI]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />
      <SideBeam
        position={[600, 2312.5, 525.635]}
        rotation={[0, 0, Math.PI]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />
      <SideBeam
        position={[600, 2687.5, 525.635]}
        rotation={[0, 0, Math.PI]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />

      <SideSupport
        position={[-225, 937.5, 525.635]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />
      <SideSupport
        position={[1000, 937.5, 525.635]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />

      <CommandCenterSupport color={props.color} />
    </group>
  );
}

Truss.defaultProps = {
  color: cssVar('--truss'),
  transparent: true,
  opacity: 0.3,
};
