"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.buildPhysicalTrolley = void 0;

var PhysicalSize_1 = require("model/src/farm/entities/trolley/physical/PhysicalSize");

function buildPhysicalTrolley(physicalTrolleyId, physicalTrolleyName) {
  return {
    id: physicalTrolleyId,
    name: physicalTrolleyName,
    topExtent: PhysicalSize_1.TROLLEY_GAP_MM,
    leftExtent: PhysicalSize_1.TROLLEY_GAP_MM,
    rightExtent: PhysicalSize_1.NODE_WIDTH_MM - 2 * PhysicalSize_1.TROLLEY_GAP_MM,
    bottomExtent: PhysicalSize_1.NODE_HEIGHT_MM - 2 * PhysicalSize_1.TROLLEY_GAP_MM,
    x: 0,
    y: 0,
    sensorArrayTop: buildSensorArray(physicalTrolleyId, 'front', {
      cX: PhysicalSize_1.TROLLEY_GAP_MM + PhysicalSize_1.NODE_WIDTH_MM / 2 - PhysicalSize_1.TROLLEY_GAP_MM,
      cY: PhysicalSize_1.TROLLEY_GAP_MM + PhysicalSize_1.FRONT_SCANNER_OFFSET_MM
    }),
    sensorArrayLeft: buildSensorArray(physicalTrolleyId, 'left', {
      cX: PhysicalSize_1.TROLLEY_GAP_MM + PhysicalSize_1.LEFT_SCANNER_OFFSET_MM,
      cY: PhysicalSize_1.TROLLEY_GAP_MM + PhysicalSize_1.NODE_HEIGHT_MM / 2 - PhysicalSize_1.TROLLEY_GAP_MM
    }),
    sensorArrayRight: buildSensorArray(physicalTrolleyId, 'right', {
      cX: PhysicalSize_1.TROLLEY_GAP_MM + PhysicalSize_1.NODE_WIDTH_MM - 2 * PhysicalSize_1.TROLLEY_GAP_MM - PhysicalSize_1.RIGHT_SCANNER_OFFSET_MM,
      cY: PhysicalSize_1.TROLLEY_GAP_MM + PhysicalSize_1.NODE_HEIGHT_MM / 2 - PhysicalSize_1.TROLLEY_GAP_MM
    }),
    sensorArrayBottom: buildSensorArray(physicalTrolleyId, 'back', {
      cX: PhysicalSize_1.TROLLEY_GAP_MM + PhysicalSize_1.NODE_WIDTH_MM / 2 - PhysicalSize_1.TROLLEY_GAP_MM,
      cY: PhysicalSize_1.TROLLEY_GAP_MM + PhysicalSize_1.NODE_HEIGHT_MM - 2 * PhysicalSize_1.TROLLEY_GAP_MM - PhysicalSize_1.BACK_SCANNER_OFFSET_MM
    })
  };
}

exports.buildPhysicalTrolley = buildPhysicalTrolley;

function buildSensorArray(id, sensorArrayId, offset) {
  var physicalSensorArrayId = id + '_' + sensorArrayId;
  var sensors = [];
  var x = offset.cX - (PhysicalSize_1.SCANNER_SENSOR_ARRAY_SIZE - 1) / 2 * PhysicalSize_1.SCANNER_SENSOR_OFFSET_MM;
  var y = offset.cY - (PhysicalSize_1.SCANNER_SENSOR_ARRAY_SIZE - 1) / 2 * PhysicalSize_1.SCANNER_SENSOR_OFFSET_MM;

  for (var i = 0; i < PhysicalSize_1.SCANNER_SENSOR_ARRAY_SIZE; i++) {
    sensors[i] = [];

    for (var j = 0; j < PhysicalSize_1.SCANNER_SENSOR_ARRAY_SIZE; j++) {
      var sensorX = x + i * PhysicalSize_1.SCANNER_SENSOR_OFFSET_MM;
      var sensorY = y + j * PhysicalSize_1.SCANNER_SENSOR_OFFSET_MM;
      sensors[i][j] = {
        id: physicalSensorArrayId + '_' + i + '_' + j,
        centerX: sensorX,
        centerY: sensorY
      };
    }
  }

  var physicalSensorArray = {
    id: physicalSensorArrayId,
    centerX: offset.cX,
    centerY: offset.cY,
    sensors: sensors
  };
  return physicalSensorArray;
}