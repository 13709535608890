import React, { Component } from 'react';
import AddressSuggest from './AddressSuggest';

import Geocode from 'react-geocode';
import FormItem from './FormItem';
import util from 'util';
import { gql } from 'apollo-boost';
import { Mutation } from 'react-apollo';
import { GET_ALL_FARMS_QUERY } from '../globe/Explorer';

type Props = {
  onFacilitySituated: (
    name?: string,
    stacks?: number,
    stackSize?: number,
    coordinates?: any
  ) => void;
  onFarmCreated: (newFarm: {
    id: string;
    name: string;
    coordinates: { longitude: number; latitude: number };
  }) => void;
};
type State = {
  query?: string;
  name?: string;
  stacks?: number;
  stackSize?: number;
  coordinates?: number[];
};

const API_KEY: string = 'AIzaSyDtlL4YuowGMexaqWPOIptKgunRcIJb0jg';
const CREATE_FARM_MUTATION = gql`
  mutation createFarm(
    $newName: String!
    $newStacks: Int!
    $newStackSize: Int!
    $latitude: Float!
    $longitude: Float!
  ) {
    createFarm(
      createFarmInput: {
        name: $newName
        stacks: $newStacks
        stackSize: $newStackSize
        latitude: $latitude
        longitude: $longitude
      }
    ) {
      id
      name
      coordinates {
        longitude
        latitude
      }
    }
  }
`;

class CreateFarmForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
    this.onQuery = this.onQuery.bind(this);
    this.setName = this.setName.bind(this);
    this.setStacks = this.setStacks.bind(this);
    this.setStackSize = this.setStackSize.bind(this);

    Geocode.setApiKey(API_KEY);
    Geocode.setLanguage('en');
    Geocode.setRegion('us');
    Geocode.enableDebug();
  }

  render() {
    return (
      <Mutation
        mutation={CREATE_FARM_MUTATION}
        refetchQueries={[{ query: GET_ALL_FARMS_QUERY }]}>
        {(createFarm: any, { loading, error }: any) => {
          return (
            <form
              onSubmit={event => {
                event.preventDefault();
                if (
                  !this.state.name ||
                  this.state.name.length === 0 ||
                  !this.state.coordinates ||
                  !this.state.stackSize ||
                  !this.state.stacks
                ) {
                  return;
                }

                if (!loading && this.state.name.length > 0) {
                  createFarm({
                    variables: {
                      newName: this.state.name,
                      newStacks: this.state.stacks,
                      newStackSize: this.state.stackSize,
                      latitude: this.state.coordinates[0],
                      longitude: this.state.coordinates[1],
                    },
                  }).then((result: any) => {
                    console.log(
                      'FARM CREATED: ' + util.inspect(result, { depth: 14 })
                    );
                    this.props.onFarmCreated({
                      id: result.data.createFarm.id,
                      name: result.data.createFarm.name,
                      coordinates: {
                        longitude: result.data.createFarm.coordinates.longitude,
                        latitude: result.data.createFarm.coordinates.latitude,
                      },
                    });
                  });
                }
              }}>
              <div>
                <FormItem
                  label="Farm Name"
                  placeholder="Enter farm name..."
                  readOnly={false}
                  debounce={true}
                  onChange={this.setName}
                  minLength={3}
                />
                <AddressSuggest
                  query={this.state.query ? this.state.query : ''}
                  onChange={this.onQuery}
                />
                <FormItem
                  label="Number Of Stacks"
                  placeholder="Enter number of stacks..."
                  readOnly={false}
                  debounce={true}
                  onChange={this.setStacks}
                  minLength={1}
                />
                <FormItem
                  label="Stack Size"
                  placeholder="Enter stack size..."
                  readOnly={false}
                  debounce={true}
                  onChange={this.setStackSize}
                  minLength={1}
                />
                <br />
                <button type="submit">Create Farm</button>
              </div>
            </form>
          );
        }}
      </Mutation>
    );
  }

  setName(event) {
    console.log('setName ' + event.target.value);
    const name = event.target.value;
    this.setState(
      {
        name: name,
      },
      this.facilitySituated
    );
  }

  setStacks(event) {
    console.log('setStacks ' + event.target.value);
    if (isNaN(event.target.value)) {
      this.setState(
        {
          stacks: undefined,
        },
        this.facilitySituated
      );
    } else {
      const stacks = parseInt(event.target.value);
      this.setState(
        {
          stacks: stacks,
        },
        this.facilitySituated
      );
    }
  }

  setStackSize(event) {
    console.log('setStackSize ' + event.target.value);
    if (isNaN(event.target.value)) {
      this.setState(
        {
          stackSize: undefined,
        },
        this.facilitySituated
      );
    } else {
      const stackSize = parseInt(event.target.value);
      this.setState(
        {
          stackSize: stackSize,
        },
        this.facilitySituated
      );
    }
  }

  onQuery(event) {
    const query = event.target.value;
    const self = this;
    //console.log('Trying ' + query);
    Geocode.fromAddress(query).then(
      response => {
        const { lat, lng } = response.results[0].geometry.location;
        //console.log(util.inspect(response, { depth: 14 }));
        self.setState(
          {
            query: query,
            coordinates: [lat, lng],
          },
          this.facilitySituated
        );
      },
      error => {
        console.error(error);
      }
    );
  }

  facilitySituated() {
    this.props.onFacilitySituated(
      this.state.name || undefined,
      this.state.stacks || undefined,
      this.state.stackSize || undefined,
      this.state.coordinates || undefined
    );
  }
}

export default CreateFarmForm;
