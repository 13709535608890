import { cssVar } from '../../..';

const VerticalLineUrl = (color: string) => {
  return (  
    'data:image/svg+xml,' +
    '<svg width="5" height="20000" viewBox="0 0 5 20000" xmlns="http://www.w3.org/2000/svg">' +
      '<line x1="2" y1="2" x2="2" y2="20000" stroke="'+cssVar(color).replace('#', '%23')+'" stroke-width="2" stroke-linecap="round" stroke-dasharray="3 3"/>' +
    '</svg>'
  );
};

export default VerticalLineUrl;
