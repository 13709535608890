import { CloudProduceAddress } from 'model/src/common/CloudProduceAddress';
import {
  combine,
  create,
  fullString,
  join,
  toAddress,
} from 'model/src/common/CloudProduceAddressUtility';
import {
  TugSensorsSubsystem,
  TugSubsystem,
  urlCombine,
} from 'model/src/common/Systems';
import { Data } from 'model/src/dataflowprotocol/Datum';
import { AutonomyStatusReading } from 'model/src/series/AutonomyStatusReading';
import { ComputedPositionReading } from 'model/src/series/ComputedPositionReading';
import {
  convert,
  NumericalReading,
  Unit,
} from 'model/src/series/NumericalReading';
import { SeriesType } from 'model/src/series/SeriesTypes';
import { StringReading } from 'model/src/series/StringReading';
import React, {
  Suspense,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { cssVar } from '../../..';
import { HELVETIKER_REGULAR_FONT } from '../../../App';
import useBlinkAnimation from '../../../common/useBlinkAnimation';
import ErrorBoundary from '../../ErrorBoundary';
import {
  ControllerContext,
  ControllerContextType,
} from '../../farmviewer/context/controllercontext/ControllerContext';
import { useDataMap } from '../../farmviewer/context/datamapcontext/useDataMap';
import Box from '../common/Box';
import Text from '../common/Text';
import LiftPlateDynamic from '../tug/LiftPlateDynamic';
import LiftPlateStatic from '../tug/LiftPlateStatic';
import TugFirstFloor from '../tug/TugFirstFloor';
import TugMagneticAlignmentNodes from '../tug/TugMagneticAlignmentNodes';
import LineSensor from './LineSensor';
import DriveTrain from './models/DriveTrain';
import TugStatusDisc from './statusdisc/TugStatusDisc';

export const NEUTRAL_PWM = 2000;
export const PWM_RANGE = 1000;

export const CM_PER_INCH = 2.54;
export const ACTUATOR_STEPS_PER_INCH = 200;

export type VisualOverrides = {
  transparent?: boolean;
  opacity?: number;
};

function TrolleyFrame(props) {
  const lifterPosition =
    (props.lifterPosition / ACTUATOR_STEPS_PER_INCH) * CM_PER_INCH;

  return (
    <Suspense fallback={<Box />}>
      <ErrorBoundary>
        <TugFirstFloor
          online={props.online}
          parentHovered={props.parentHovered}
          parentUnderInspection={props.parentUnderInspection}
          visualOverrides={props.visualOverrides}
        />

        {/* <TugSecondFloor
        parentHovered={props.parentHovered}
        parentUnderInspection={props.parentUnderInspection}
      />
      <TugThirdFloor
        parentHovered={props.parentHovered}
        parentUnderInspection={props.parentUnderInspection}
      /> */}
        <LiftPlateStatic
          online={props.online}
          parentHovered={props.parentHovered}
          parentUnderInspection={props.parentUnderInspection}
          visualOverrides={props.visualOverrides}
        />
        <group position={[0, 0, lifterPosition]}>
          <LiftPlateDynamic
            online={props.online}
            parentHovered={props.parentHovered}
            parentUnderInspection={props.parentUnderInspection}
            visualOverrides={props.visualOverrides}
          />
        </group>
      </ErrorBoundary>
    </Suspense>
  );
}

export interface TrolleyProps {
  url: CloudProduceAddress;
  inspectionContext;
  dataMap;
  globals;
  positionX: Data<NumericalReading>;
  positionY: Data<NumericalReading>;
  positionZ: Data<NumericalReading>;
  computedPosition: Data<ComputedPositionReading>;
  objectMap;
  onClick;
  showMenu;
}

export default function Trolley(props: TrolleyProps) {
  const controllerContext: ControllerContextType = useContext(
    ControllerContext
  );

  const [visualOverrides, setVisualOverrides] = useState<VisualOverrides>({
    opacity: undefined,
    transparent: undefined,
  });

  const [hovered, setHover] = useState(false);
  const underInspection = props.inspectionContext.inspectionMap.get(
    fullString(props.url)
  ).underInspection;
  const group = useRef();

  const isOnline = useDataMap(combine(props.url, create([SeriesType.ONLINE])))
    .value;

  const onPointerOver = controllerContext.dragMute(event => {
    setHover(true);
    event.stopPropagation();
  }, controllerContext);

  const hasCentered = useDataMap(
    combine(props.url, create([SeriesType.HAS_CENTERED]))
  );

  useBlinkAnimation<number>({
    activate: !hasCentered,
    to: 0,
    from: 1.0,
    duration: 2000,
    apply: (value: number) => {
      const transparent: boolean = value < 1;
      setVisualOverrides({ opacity: value, transparent: transparent });
    },
    dependencies: [],
  });

  // const isBlinkingRef = useRef(true);
  // if (hasCentered) {
  //   if (isBlinkingRef.current) {
  //     isBlinkingRef.current = false;
  //     setVisualOverrides({ opacity: 1.0, transparent: false });
  //   }
  // } else {
  //   if (!isBlinkingRef.current) {
  //     isBlinkingRef.current = true;
  //   }
  // }

  // const opacityRef = useRef(0.0);
  // const completeBlinkIn = () => {
  //   if (isBlinkingRef.current) {
  //     blinkOutTween.current.start();
  //   }
  // };

  // const completeBlinkOut = () => {
  //   if (isBlinkingRef.current) {
  //     blinkInTween.current.start();
  //   }
  // };

  // const blinkInTween = useRef(
  //   new TWEEN.Tween(opacityRef)
  //     .to({ current: 1.0 }, 2000)
  //     .easing(TWEEN.Easing.Sinusoidal.InOut)
  //     .onUpdate(e => {
  //       setVisualOverrides({ opacity: opacityRef.current, transparent: true });
  //     })
  //     .onComplete(() => {
  //       completeBlinkIn();
  //     })
  // );

  // const blinkOutTween = useRef(
  //   new TWEEN.Tween(opacityRef)
  //     .to({ current: 0.0 }, 2000)
  //     .easing(TWEEN.Easing.Sinusoidal.InOut)
  //     .onUpdate(e => {
  //       setVisualOverrides({ opacity: opacityRef.current, transparent: true });
  //     })
  //     .onComplete(() => {
  //       completeBlinkOut();
  //     })
  // );

  // useEffect(() => {
  //   if (isBlinkingRef.current) {
  //     blinkInTween.current.start();
  //   }
  // }, [isBlinkingRef.current]);

  // useFrame((state, delta) => {
  //   TWEEN.update();
  // });

  const textOptions = {
    font: HELVETIKER_REGULAR_FONT.font,
    size: 5,
    height: 1,
  };

  const lifterPositionLfDatum: Data<NumericalReading> = props.dataMap.all.get(
    fullString(
      combine(
        props.url,
        create([
          TugSubsystem.DRIVE_TRAIN,
          SeriesType.LF,
          SeriesType.LIFTER_POSITION,
        ])
      )
    )
  );

  const lifterPositionRfDatum: Data<NumericalReading> = props.dataMap.all.get(
    fullString(
      combine(
        props.url,
        create([
          TugSubsystem.DRIVE_TRAIN,
          SeriesType.RF,
          SeriesType.LIFTER_POSITION,
        ])
      )
    )
  );

  const lifterPositionLrDatum: Data<NumericalReading> = props.dataMap.all.get(
    fullString(
      combine(
        props.url,
        create([
          TugSubsystem.DRIVE_TRAIN,
          SeriesType.LR,
          SeriesType.LIFTER_POSITION,
        ])
      )
    )
  );

  const lifterPositionRrDatum: Data<NumericalReading> = props.dataMap.all.get(
    fullString(
      combine(
        props.url,
        create([
          TugSubsystem.DRIVE_TRAIN,
          SeriesType.RR,
          SeriesType.LIFTER_POSITION,
        ])
      )
    )
  );

  const lifterPositionLeftFront = lifterPositionLfDatum.data[0].reading.value;
  const lifterPositionRightFront = lifterPositionRfDatum.data[0].reading.value;
  const lifterPositionLeftRear = lifterPositionLrDatum.data[0].reading.value;
  const lifterPositionRightRear = lifterPositionRrDatum.data[0].reading.value;

  const lifterPosition =
    (lifterPositionLeftFront +
      lifterPositionRightFront +
      lifterPositionLeftRear +
      lifterPositionRightRear) /
    4;

  useEffect(() => {
    props.objectMap.set(fullString(props.url), {
      url: fullString(props.url),
      model: group.current,
    });
    return () => {
      props.objectMap.delete(fullString(props.url));
    };
  });

  const determinePosition: (
    url: string,
    dataMap: any,
    positionX: NumericalReading,
    positionY: NumericalReading
  ) => { x: number; y: number } = (
    url: string,
    dataMap: any,
    positionX: NumericalReading,
    positionY: NumericalReading
  ) => {
    const tugHomeUrl =
      url + urlCombine([SeriesType.HOME, SeriesType.GRID_SQUARE_URL]);
    const tugHomeSquareDatum: Data<StringReading> = dataMap.all.get(tugHomeUrl);
    const tugHomeSquare = tugHomeSquareDatum.data[0].reading.value; //TODO(austin): do we need to ref home square in this file?
    const isHomedUrl = url + urlCombine([SeriesType.IS_HOMED]);
    const tugIsHomed: boolean = dataMap.all.get(isHomedUrl).data[0].reading
      .value;

    if (tugIsHomed) {
      const tugHomeUrl = toAddress(url + urlCombine([SeriesType.HOME]));
      const tugHomePositionXUrl = combine(
        tugHomeUrl,
        create([SeriesType.X, SeriesType.POSITION])
      );
      const tugHomePositionYUrl = combine(
        tugHomeUrl,
        create([SeriesType.Y, SeriesType.POSITION])
      );

      const tugHomePositionX: number = dataMap.all.get(
        fullString(tugHomePositionXUrl)
      ).data[0].reading.value;

      const tugHomePositionY: number = dataMap.all.get(
        fullString(tugHomePositionYUrl)
      ).data[0].reading.value;

      const deadReckoningXUrl =
        url +
        urlCombine([
          SeriesType.DEAD_RECKONING,
          SeriesType.X,
          SeriesType.POSITION,
        ]);

      const deadReckoningX: Data<NumericalReading> = dataMap.all.get(
        deadReckoningXUrl
      );

      const deadReckoningYUrl =
        url +
        urlCombine([
          SeriesType.DEAD_RECKONING,
          SeriesType.Y,
          SeriesType.POSITION,
        ]);

      const deadReckoningY: Data<NumericalReading> = dataMap.all.get(
        deadReckoningYUrl
      );

      const realPosition = {
        x:
          tugHomePositionX / convert(Unit.CM, Unit.MM) -
          deadReckoningX.data[0].reading.value,
        y:
          tugHomePositionY / convert(Unit.CM, Unit.MM) +
          deadReckoningY.data[0].reading.value,
      };
      return realPosition;
    } else {
      const realPosition = {
        x: positionX.value / convert(Unit.CM, Unit.MM),
        y: positionY.value / convert(Unit.CM, Unit.MM),
      };
      return realPosition;
    }
  };

  const realPosition = determinePosition(
    fullString(props.url),
    props.dataMap,
    props.positionX.data[0].reading,
    props.positionY.data[0].reading
  );

  useEffect(() => {
    if (underInspection) {
      props.globals.centroidFinder();
    }
  }, [realPosition.x, realPosition.y]);

  const bottomFrontSensorUrl = combine(
    props.url,
    create([TugSubsystem.BOTTOM_SENSORS, TugSensorsSubsystem.FRONT_LINE_SENSOR])
  );

  const topFrontSensorUrl = combine(
    props.url,
    create([TugSubsystem.TOP_SENSORS, TugSensorsSubsystem.FRONT_LINE_SENSOR])
  );

  const bottomRearSensorUrl = combine(
    props.url,
    create([TugSubsystem.BOTTOM_SENSORS, TugSensorsSubsystem.REAR_LINE_SENSOR])
  );

  const topRearSensorUrl = combine(
    props.url,
    create([TugSubsystem.TOP_SENSORS, TugSensorsSubsystem.REAR_LINE_SENSOR])
  );

  const bottomLeftSensorUrl = combine(
    props.url,
    create([TugSubsystem.BOTTOM_SENSORS, TugSensorsSubsystem.LEFT_LINE_SENSOR])
  );

  const topLeftSensorUrl = combine(
    props.url,
    create([TugSubsystem.TOP_SENSORS, TugSensorsSubsystem.LEFT_LINE_SENSOR])
  );

  const bottomRightSensorUrl = combine(
    props.url,
    create([TugSubsystem.BOTTOM_SENSORS, TugSensorsSubsystem.RIGHT_LINE_SENSOR])
  );

  const topRightSensorUrl = combine(
    props.url,
    create([TugSubsystem.TOP_SENSORS, TugSensorsSubsystem.RIGHT_LINE_SENSOR])
  );

  const trolleyFrame = useMemo(() => {
    return (
      <TrolleyFrame
        online={isOnline}
        parentHovered={hovered}
        parentUnderInspection={underInspection}
        lifterPosition={lifterPosition}
        visualOverrides={visualOverrides}
      />
    );
  }, [
    isOnline,
    hovered,
    underInspection,
    lifterPosition,
    visualOverrides.opacity,
    visualOverrides.transparent,
  ]);

  return (
    <ErrorBoundary>
      <group
        ref={group}
        onClick={e => {
          if (!controllerContext.keys.has('Alt')) {
            props.onClick(fullString(props.url));
            e.stopPropagation();
          } else {
            props.showMenu(fullString(props.url));
            e.stopPropagation();
          }
        }}
        onContextMenu={e => {
          props.showMenu(fullString(props.url));
          e.stopPropagation();
        }}
        onPointerOver={onPointerOver}
        onPointerOut={event => {
          setHover(false);
          event.stopPropagation();
        }}
        position={[realPosition.x, realPosition.y, 5]}
        rotation={[
          0,
          0,
          (Math.PI * (180 - 0)) / // TODO(philipp): Use rotation from data.
            180,
        ]}>
        <LineSensor url={bottomFrontSensorUrl} />
        <LineSensor url={topFrontSensorUrl} />
        <LineSensor url={topRearSensorUrl} />
        <LineSensor url={bottomRearSensorUrl} />
        <LineSensor url={bottomLeftSensorUrl} />
        <LineSensor url={topLeftSensorUrl} />
        <LineSensor url={bottomRightSensorUrl} />
        <LineSensor url={topRightSensorUrl} />
        <ErrorBoundary>
          <DriveTrain
            url={combine(
              props.url,
              create([TugSubsystem.DRIVE_TRAIN, TugSubsystem.LF])
            )}
            online={isOnline}
            parentHovered={hovered}
            parentUnderInspection={underInspection}
            onClick={props.onClick}
            inspectionContext={props.inspectionContext}
            objectMap={props.objectMap}
            pwm={1500}
          />
          <DriveTrain
            url={combine(
              props.url,
              create([TugSubsystem.DRIVE_TRAIN, TugSubsystem.LR])
            )}
            online={isOnline}
            parentHovered={hovered}
            parentUnderInspection={underInspection}
            onClick={props.onClick}
            inspectionContext={props.inspectionContext}
            objectMap={props.objectMap}
            pwm={1500}
          />
          <DriveTrain
            url={combine(
              props.url,
              create([TugSubsystem.DRIVE_TRAIN, TugSubsystem.RF])
            )}
            online={isOnline}
            parentHovered={hovered}
            parentUnderInspection={underInspection}
            onClick={props.onClick}
            inspectionContext={props.inspectionContext}
            objectMap={props.objectMap}
            pwm={1500}
          />
          <DriveTrain
            url={combine(
              props.url,
              create([TugSubsystem.DRIVE_TRAIN, TugSubsystem.RR])
            )}
            online={isOnline}
            parentHovered={hovered}
            parentUnderInspection={underInspection}
            onClick={props.onClick}
            inspectionContext={props.inspectionContext}
            objectMap={props.objectMap}
            pwm={1500}
          />
          {trolleyFrame}
          <TugMagneticAlignmentNodes url={props.url} />
          <Text
            position={[0, 0, 50]}
            rotation={[Math.PI / 2, 0, 0]}
            text={
              lifterPositionLeftFront +
              ', ' +
              lifterPositionRightFront +
              ', ' +
              lifterPositionLeftRear +
              ', ' +
              lifterPositionRightRear
            }
            textOptions={textOptions}
            color={cssVar('--cloud-produce-black')}
          />
        </ErrorBoundary>
        <TugStatusDisc
          url={props.url}
          parentHovered={hovered}
          parentUnderInspection={underInspection}
        />
      </group>
    </ErrorBoundary>
  );
}
