import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import ClassNames from '../../../ClassNames';
import {
  UserProfileContext,
  UserProfileContextType,
} from '../../../user/UserProfileContext';
import Toggle from '../../toggle/Toggle';

const ForceRight = styled.div`
  display: inline-block;
  float: right;
  padding-right: var(--spacingXS);
`;

const TitleLine = styled.div`
  padding-top: var(--spacingLarge);
  padding-left: var(--spacingMedium);
  padding-bottom: var(--spacingMedium);
  display: inline-block;
`;
const TextLine = styled.div`
  padding-left: var(--spacingMedium);
  display: inline;
`;
const ServerPadding = styled.div`
  padding-bottom: var(--spacingXS);
`;

const LoggingStatusContent = props => {
  const userProfileContext: UserProfileContextType = useContext(
    UserProfileContext
  );

  const [pageServerCoreLogging, setPageServerCoreLogging] = useState(true);
  const [farmServerCoreLogging, setFarmServerCoreLogging] = useState(true);
  const [alarmMonitorCoreLogging, setAlarmMonitorCoreLogging] = useState(true);
  const [TimerManagerCoreLogging, setTimerManagerCoreLogging] = useState(true);
  //TODO(austin): update based on dataMap

  return (
    <div>
      <TitleLine className={ClassNames.title3}>Server Logging</TitleLine>
      <br />
      <ServerPadding>
        <TextLine className={ClassNames.title4}>Page Server</TextLine>
        <ForceRight>
          <TextLine className={ClassNames.bodyRegular}>Core Logger </TextLine>
          <Toggle
            defaultPosition={pageServerCoreLogging}
            onChange={() => {}} //TODO(austin): send data update
          />
        </ForceRight>
      </ServerPadding>
      <ServerPadding>
        <TextLine className={ClassNames.title4}>Farm Server</TextLine>
        <ForceRight>
          <TextLine className={ClassNames.bodyRegular}>Core Logger </TextLine>
          <Toggle
            defaultPosition={pageServerCoreLogging}
            onChange={() => {}} //TODO(austin): send data update
          />
        </ForceRight>
      </ServerPadding>
      <ServerPadding>
        <TextLine className={ClassNames.title4}>Alarm Monitor</TextLine>
        <ForceRight>
          <TextLine className={ClassNames.bodyRegular}>Core Logger </TextLine>
          <Toggle
            defaultPosition={pageServerCoreLogging}
            onChange={() => {}} //TODO(austin): send data update
          />
        </ForceRight>
      </ServerPadding>
      <ServerPadding>
        <TextLine className={ClassNames.title4}>Timer Manager</TextLine>
        <ForceRight>
          <TextLine className={ClassNames.bodyRegular}>Core Logger </TextLine>
          <Toggle
            defaultPosition={pageServerCoreLogging}
            onChange={() => {}} //TODO(austin): send data update
          />
        </ForceRight>
      </ServerPadding>
    </div>
  );
};

export default LoggingStatusContent;
