import './index.css';
import 'firebase/auth';

import * as firebase from 'firebase/app';
import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from '@sentry/browser';

import App from './App';
import config from './config';
import * as serviceWorker from './serviceWorker';
import GamepadManager from './three/farmviewer/gamepad/GamePadManager';

export const gamepadManager = new GamepadManager();

firebase.initializeApp(config.firebase);
Sentry.init(config.sentry);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

export function cssVar(name: string): string {
  return getComputedStyle(document.body)
    .getPropertyValue(name)
    .trim();
}
