import styled from 'styled-components';

const WindowContent = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-radius: var(--windowBorderRadius);
`;

export default WindowContent;
