import React from 'react';
import styled from 'styled-components';

import Check from './Check';
import Close from './Close';

const Label = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  border: 0;
  outline: 0;
`;

const Background = styled.div`
  isolation: isolate;
  position: relative;
  overflow: hidden;

  background: ${props =>
    props.checked
      ? 'var(--switchBackground_Active)'
      : 'var(--switchBackground_Default)'};
  border-radius: var(--white);

  width: var(--switchWidth);
  height: var(--switchHeight);

  border-radius: var(--switchBackgroundBorderRadius);
`;

const ToggleState = styled.input`
  display: none;
  transition: all 0.4s ease-in-out;

  ${props =>
    props.defaultPosition
      ? `
    :checked ~ div {
      transform: translate3d(-100%, 0, 0);
    }
    `
      : `
    :checked ~ div {
      transform: translate3d(100%, 0, 0);
    }
    `}
`;

const Indicator = styled.div`
  height: var(--switchHandleHeight);
  width: var(--switchHandleWidth);
  margin: var(--switchHandleMargin);
  background: var(--switchHandleBackground_Default);
  border-radius: var(--switchHandleBorderRadius);
  transition: transform 0.5s cubic-bezier(0.85, 0.05, 0.18, 1.35),
    box-shadow 0.2s ease-in-out;
`;

const Toggle = props => {
  return (
    <>
      <Label>
        <Background checked={props.defaultPosition}>
          <ToggleState
            type="checkbox"
            name="check"
            display="none"
            checked={props.defaultPosition}
            onChange={e => {
              if (props.onChange) {
                props.onChange(props.defaultPosition);
              }
            }}
          />
          <Indicator>
            {props.defaultPosition ? (
              <Check fillColour="var(--switchHandleIcon_Active)" />
            ) : (
              <Close fillColour="var(--switchHandleIcon_Default)" />
            )}
          </Indicator>
        </Background>
      </Label>
    </>
  );
};

Toggle.defaultProps = {
  onChange: null,
  defaultPosition: true,
};

export default Toggle;
