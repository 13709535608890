"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ValveState = void 0;
var ValveState;

(function (ValveState) {
  ValveState["UNKNOWN"] = "UNKNOWN";
  ValveState["OPEN"] = "OPEN";
  ValveState["CLOSED"] = "CLOSED";
  ValveState["OPENING"] = "OPENING";
  ValveState["CLOSING"] = "CLOSING";
  ValveState["ERROR"] = "ERROR";
})(ValveState = exports.ValveState || (exports.ValveState = {}));