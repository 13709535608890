import React from 'react';

import VerticalBeam from './VerticalBeam';

export default function SideSupport(props) {
  return (
    <group position={props.position} rotation={props.rotation}>
      <VerticalBeam
        position={[0, 225 + 25 + 25, 0]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />
      <VerticalBeam
        position={[0, 275 + 350 + 25, 0]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />
      <VerticalBeam
        position={[0, 275 + 350 + 25 + 350 + 25, 0]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />
      <VerticalBeam
        position={[0, 275 + 350 + 25 + 350 + 25 + 350 + 25, 0]}
        color={props.color}
        opacity={props.opacity}
        transparent={props.transparent}
      />
    </group>
  );
}

SideSupport.defaultProps = {
  position: [0, 0, 0],
  rotation: [0, 0, 0],
};
