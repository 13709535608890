import React, { useLayoutEffect, useRef, useState } from 'react';
import { DataMap } from 'services/src/pagedataintake/PageDataIntake';
import PageWebSocketClient from 'services/src/pagedataintake/PageWebSocketClient';
import styled from 'styled-components';

import { cssVar } from '../..';
import ClassNames from '../../ClassNames';
import { WindowClickInformation } from '../../three/farmviewer/ContextMenu';
import useWindowDimensions from '../desktop/useWindowDimensions';
import { Label } from '../label/Label';
import FishTankContextMenu from './FishTankContextMenu';
import ModifyTimerTemplateContextMenu from './ModifyTimerTemplateContextMenu';
import SchedulerWindowAddToScheduleContextMenu from './SchedulerWindowAddToScheduleContextMenu';
import SumpContextMenu from './SumpContextMenu';
import TierContextMenu from './TierContextMenu';
import TowerContextMenu from './TowerContextMenu';
import TugContextMenu from './TugContextMenu';
import UmbilicalContextMenu from './UmbilicalContextMenu';

const ContextMenuStyling = styled.div`
  box-sizing: border-box;
  position: absolute;
  text-align: center;
  z-index: 150;

  background-color: var(--dropdownMenuItemBackground_Default);
  pointer-events: all;
  drop-shadow: var(--dropdownMenuDropShadow);
  border-radius: var(--dropdownMenuBorderRadius);
  font-family: var(--neumorphic-font);
  font-size: var(--neumorphic-font-size);
  display: flex;
  flex-direction: column;
  padding: 0;
  width: var(--contextMenuWidth);

  left: ${props => props.left + 'px'};
  top: ${props => props.top + 'px'};

  > button {
    width: 100%;
  }
`;

export type ContextMenuProps = {
  applyOverridingRestriction;
  connectionToServer: PageWebSocketClient
  dataMap: DataMap;

  hideContextMenu: () => void;
  inspect: (url) => void;
  select: (url) => void;

  isFishTank: boolean
  isSump: boolean
  isTier: boolean
  isTower: boolean
  isTug: boolean;
  isUmbilical: boolean;
  isSchedulerWindow: boolean;
  isTimerTemplate: boolean;

  windowClickInfo?: WindowClickInformation;

  left: number
  right: number
  top: number

  url: string;
  parentUrl?: string;
}

export default function ContextMenuContainer(props: ContextMenuProps) {
  const [openSubMenu, setOpenSubMenu] = useState<string>('');
  const [containerHeight, setContainerHeight] = useState<number>(0);
  const boundsRef = useRef<HTMLInputElement>(null);
  const { screenWidth, screenHeight } = useWindowDimensions();

  useLayoutEffect(() => {
    const height = boundsRef.current?.getBoundingClientRect().height;
    if (height) setContainerHeight(height);
  }, []);

  const contextMenuWidth = +cssVar('--contextMenuWidth').replace('px', '');

  const left =
    contextMenuWidth +
      props.left +
      +cssVar('--viewPortMargin').replace('px', '') <
    screenWidth
      ? props.left
      : screenWidth -
          contextMenuWidth -
          +cssVar('--viewPortMargin').replace('px', '') <
        props.left - contextMenuWidth
      ? screenWidth -
        contextMenuWidth -
        +cssVar('--viewPortMargin').replace('px', '')
      : props.left - contextMenuWidth;

  const top = containerHeight
    ? containerHeight +
        props.top +
        +cssVar('--viewPortMargin').replace('px', '') <
      screenHeight
      ? props.top
      : screenHeight - containerHeight! - +cssVar('--viewPortMargin').replace('px', '')
    : props.top;

  return (
    <ContextMenuStyling left={left} top={top} ref={boundsRef}>
      <Label className={ClassNames.labelMedium}>{props.url}</Label>
      {props.isFishTank && (
        <FishTankContextMenu
          left={left}
          right={props.right}
          openSubMenu={openSubMenu}
          setOpenSubMenu={setOpenSubMenu}
          applyOverridingRestriction={props.applyOverridingRestriction}
          inspect={props.inspect}
          select={props.select}
          connectionToServer={props.connectionToServer}
          url={props.url}
          hideContextMenu={props.hideContextMenu}
          dataMap={props.dataMap}
        />
      )}
      {props.isSump && (
        <SumpContextMenu
          left={left}
          right={props.right}
          openSubMenu={openSubMenu}
          setOpenSubMenu={setOpenSubMenu}
          inspect={props.inspect}
          select={props.select}
          connectionToServer={props.connectionToServer}
          url={props.url}
          hideContextMenu={props.hideContextMenu}
          dataMap={props.dataMap}
        />
      )}
      {props.isTier && (
        <TierContextMenu
          left={left}
          right={props.right}
          openSubMenu={openSubMenu}
          setOpenSubMenu={setOpenSubMenu}
          inspect={props.inspect}
          parentUrl={props.parentUrl}
          select={props.select}
          connectionToServer={props.connectionToServer}
          url={props.url}
          hideContextMenu={props.hideContextMenu}
          dataMap={props.dataMap}
        />
      )}
      {props.isTower && (
        <TowerContextMenu
          left={left}
          right={props.right}
          openSubMenu={openSubMenu}
          setOpenSubMenu={setOpenSubMenu}
          inspect={props.inspect}
          select={props.select}
          connectionToServer={props.connectionToServer}
          url={props.url}
          hideContextMenu={props.hideContextMenu}
          dataMap={props.dataMap}
        />
      )}
      {props.isTug && (
        <TugContextMenu
          left={left}
          right={props.right}
          openSubMenu={openSubMenu}
          setOpenSubMenu={setOpenSubMenu}
          inspect={props.inspect}
          select={props.select}
          connectionToServer={props.connectionToServer}
          url={props.url}
          hideContextMenu={props.hideContextMenu}
          dataMap={props.dataMap}
        />
      )}
      {props.isUmbilical && (
        <UmbilicalContextMenu
          left={left}
          right={props.right}
          openSubMenu={openSubMenu}
          setOpenSubMenu={setOpenSubMenu}
          inspect={props.inspect}
          select={props.select}
          connectionToServer={props.connectionToServer}
          url={props.url}
          hideContextMenu={props.hideContextMenu}
          dataMap={props.dataMap}
        />
      )}
      {props.isSchedulerWindow && <SchedulerWindowAddToScheduleContextMenu
        windowClickInfo={props.windowClickInfo}
        openSubMenu={openSubMenu}
        setOpenSubMenu={setOpenSubMenu}
        />
      }
      {props.isTimerTemplate && <ModifyTimerTemplateContextMenu windowClickInfo={props.windowClickInfo}/>}
    </ContextMenuStyling>
  );
}
