import { RegexTokens } from 'model/src/common/Regex';
import React from 'react';
import styled from 'styled-components';

import { cssVar } from '../..';

export type TreeSearchBarProps = {
  setQuery: (query: string) => void;
  setQueryRegexString: (query: string) => void;
  query: string;
};

const SearchBarContainer = styled.div`
  margin: 12px; //TODO(austin): format CSS for bar and remove literal
  color: var(--black);
  display: inline;
`;

const TreeSearchBar = (props: TreeSearchBarProps) => {
  const trailingSpaces = ' ' + RegexTokens.ONE_OR_MORE + '$';
  const multipleSpaces = ' ' + RegexTokens.ONE_OR_MORE;
  const getQueryRegexString = (query: string) => {
    return (
      '^' +
      query
        .toUpperCase()
        .replaceAll('?', RegexTokens.QUESTION_MARK)
        .replaceAll('.', RegexTokens.PERIOD)
        .replaceAll('(', RegexTokens.BRACKET_LEFT)
        .replaceAll(')', RegexTokens.BRACKET_RIGHT)
        .replaceAll('[', RegexTokens.SQUARE_BRACKET_LEFT)
        .replaceAll(']', RegexTokens.SQUARE_BRACKET_RIGHT)
        .replaceAll('{', RegexTokens.CURLY_BRACE_LEFT)
        .replaceAll('}', RegexTokens.CURLY_BRACE_RIGHT)
        .replaceAll('^', RegexTokens.CIRCUMFLEX)
        .replaceAll('+', RegexTokens.PLUS)
        .replaceAll('|', RegexTokens.PIPE)
        .replaceAll('*', '.*')
        .replaceAll(new RegExp(trailingSpaces, 'g'), '')
        .replaceAll(new RegExp(multipleSpaces, 'g'), ' ')
        .replaceAll(' ', '$|^') +
      '$'
    );
  };

  return (
    <SearchBarContainer>
      Search
      <input
        name={'Search'}
        value={props.query}
        onChange={e => {
          const query = getQueryRegexString(e.target.value);
          props.setQuery(e.target.value);
          props.setQueryRegexString(query);
        }}
        style={{
          backgroundColor: cssVar('--cardGray'),
          color: cssVar('--black'),
        }}
        type={'text'}
      />
    </SearchBarContainer>
  );
};

export default TreeSearchBar;
