import { Unit } from 'model/src/series/NumericalReading';
import { BuiltinType } from 'model/src/typescript/Typescript';
import React from 'react';
import styled from 'styled-components';

import { ListDatum } from './DataList';
import { NodeLine } from './SubTree';

export const LeafTextBox = styled.span`
  position: relative;
  display: inline-block;
  padding: var(--treeTextBoxPadding);
  border-radius: var(--treeLeafStaticBorderRadius);
  bottom: calc(var(--treeNodeLineWidth) * -2);

  user-select: text;

  background-color: transparent;
  color: var(--treeLeafEditableLabel_Default);
`;

export const DateTimeTextBox = styled.span`
  position: relative;
  display: inline-block;
  padding: var(--treeTextBoxPadding);
  border-radius: var(--treeLeafStaticBorderRadius);
  top: var(--treeNodeLineWidth);
  background-color: transparent;
  color: var(--textLightGray);
  user-select: text;
`;

export const SolidText = styled.p`
  position: relative;
  display: inline-block;
  margin: 0;
  background-color: var(--white);
  bottom: calc(var(--treeNodeLineWidth) * -2);
  user-select: text;
`;
export const NodeTextBox = styled.span`
  margin: 0;
  pointer-events: auto;
  position: relative;
  padding-left: var(--treeNodeMinIndent);
  padding-right: var(--treeNodeMinIndent);
  display: inline-block;
  border-radius: var(--treeLeafStaticBorderRadius);
  background-color: var(--treeNodeRegularBackground_OnPath);
  color: var(--treeNodeRegularLabel_OnPath);
  top: calc(var(--treeNodeLineWidth) * 2);
`;

const SearchDisplay = props => {
  const search = queryRegexString => {
    const leaves: (string | Object)[] = Array.from(props.dataMap.all.entries());
    const regexes = [new RegExp(queryRegexString)];
    const matched = leaves.filter(item =>
      regexes.some(regex => item[0].match(regex))
    );
    return matched;
  };

  const renderMatchedNodes = queryRegexString => {
    const matchedEntries = search(queryRegexString);

    const dataRenderValues = matchedEntries.map(item => {
      let valuesToRender = item[1]
        ? item[1].datum
          ? [item[0], JSON.stringify(item[1].datum).replaceAll('"', '')] //TODO(austin): find + parse for all datum formats
          : item[1].data
          ? item[1].data[0]
            ? typeof item[1].data[0].timestamp !== BuiltinType.UNDEFINED &&
              typeof item[1].data[0].reading !== BuiltinType.UNDEFINED
              ? typeof item[1].data[0].reading.value !==
                  BuiltinType.UNDEFINED &&
                item[1].data[0].reading.value !== null
                ? [
                    item[0],
                    item[1].data[0].reading.value.toString() +
                      ' ' +
                      (typeof item[1].data[0].reading.unit !==
                      BuiltinType.UNDEFINED
                        ? Unit[item[1].data[0].reading.unit]
                        : ''),
                    '(Updated ' +
                      new Date(item[1].data[0].timestamp * 1000).toString() +
                      ')',
                  ]
                : [
                    item[0],
                    JSON.stringify(item[1].data[0].reading).replaceAll('"', ''),
                    '(Updated ' +
                      new Date(item[1].data[0].timestamp * 1000).toString() +
                      ')',
                  ]
              : [item[0], JSON.stringify(item[1].data[0]).replaceAll('"', '')]
            : [item[0], JSON.stringify(item[1].data).replaceAll('"', '')]
          : [item[0], 'N/A']
        : [item[0], 'N/A'];

      return valuesToRender;
    });
    const sortedValues = dataRenderValues.sort((a, b) => {
      if (a[0] < b[0]) {
        return -1;
      }
      if (a[0] > b[0]) {
        return 1;
      }
      return 0;
    });

    return (
      <>
        {sortedValues.map(item => (
          <ListDatum key={item[0]}>
            <NodeTextBox>{item[0]}</NodeTextBox>
            <NodeLine />
            <LeafTextBox>{item[1]}</LeafTextBox>
            {item[2] && (
              <div>
                <DateTimeTextBox>{item[2]}</DateTimeTextBox>
              </div>
            )}
            {/* )} */}
          </ListDatum>
        ))}
      </>
    );
  };

  return renderMatchedNodes(props.queryRegexString);
};

export default SearchDisplay;
