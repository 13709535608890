import React, { Suspense } from 'react';

import Box from '../common/Box';
import CT290_402S from './CT290_402S';
import CT290_405S from './CT290_405S';
import CT290_430S from './CT290_430S';

export default function SideBeam(props) {
  return (
    <group position={props.position} rotation={props.rotation}>
      <Suspense fallback={<Box />}>
        <CT290_430S
          color={props.color}
          opacity={props.opacity}
          transparent={props.transparent}
        />
      </Suspense>
      <Suspense fallback={<Box />}>
        <CT290_402S
          position={[-325, 0, 0]}
          rotation={[0, Math.PI / 2, 0]}
          color={props.color}
          opacity={props.opacity}
          transparent={props.transparent}
        />
      </Suspense>
      <Suspense fallback={<Box />}>
        <CT290_405S
          position={[-375, 0, 0]}
          rotation={[0, 0, 0]}
          color={props.color}
          opacity={props.opacity}
          transparent={props.transparent}
        />
      </Suspense>
    </group>
  );
}

SideBeam.defaultProps = {
  position: [0, 0, 0],
  rotation: [0, 0, 0],
};
