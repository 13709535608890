import React from 'react';
import { PhysicalTrolley } from 'model/src/farm/entities/trolley/physical/PhysicalTrolley';
import { Component } from 'react';
import { LogicalTrolleySnapShot } from './SvgFarm';
import { Occupation } from 'model/src/farm/entities/trolley/logical/LogicalTrolleyState';

type Props = {
  physicalTrolley: PhysicalTrolley;
  logicalTrolleySnapShot: LogicalTrolleySnapShot;
  onUnlaunchedTrolleyClicked: (
    trolleyId: string,
    currentTrackNode: string
  ) => void;
};

type State = {};

class SvgLogicalTrolley extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
    this.onClick = this.onClick.bind(this);
  }

  onClick(event) {
    if (
      this.props.logicalTrolleySnapShot.currentOccupation ===
      Occupation.UNLAUNCHED
    ) {
      this.props.onUnlaunchedTrolleyClicked(
        this.props.logicalTrolleySnapShot.id,
        this.props.logicalTrolleySnapShot.currentTrackNode
      );
    }
  }

  render() {
    const outlinePath = `
      M${this.props.physicalTrolley.leftExtent +
        this.props.logicalTrolleySnapShot.currentLocation.x} ${this.props
      .physicalTrolley.topExtent +
      this.props.logicalTrolleySnapShot.currentLocation.y} 
      v ${this.props.physicalTrolley.bottomExtent}
      h ${this.props.physicalTrolley.rightExtent} 
      v ${-this.props.physicalTrolley.bottomExtent}
      h ${-this.props.physicalTrolley.rightExtent}
    `;

    let strokeColor: string = 'green';

    return (
      <g onClick={this.onClick}>
        <path
          d={outlinePath}
          fill="yellow"
          fillOpacity={1.0}
          stroke={strokeColor}
          strokeWidth={20}
        />
        <text
          x={
            this.props.physicalTrolley.sensorArrayTop.centerX +
            this.props.logicalTrolleySnapShot.currentLocation.x
          }
          y={
            this.props.physicalTrolley.sensorArrayLeft.centerY +
            this.props.logicalTrolleySnapShot.currentLocation.y -
            125
          }
          textAnchor="middle"
          alignmentBaseline="central"
          fontSize="250"
          fill="black">
          {this.props.physicalTrolley.name}
        </text>
      </g>
    );
  }
}

export default SvgLogicalTrolley;
