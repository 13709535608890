import * as THREE from 'three';

import {
  CameraLockType,
  OverridingRestriction,
  ProjectionType,
} from './ControllerContext';

export const WATER_TANK_0: OverridingRestriction = {
  name: 'Water Tank 0',
  cameraRestriction: {
    cameraLockType: CameraLockType.STATIC,
    projectionType: ProjectionType.ORTHOGRAPHIC,
    focus: '_',
    offset: new THREE.Vector3(0, -50, 0),
  },
  propertyMap: new Map([
    ['_', { color: new THREE.Color('#ff0000'), inspectable: false }],
    ['^.^.truss', { opacity: 0.5 }],
    ['^.^.commandcenter', { opacity: 0.1 }],
  ]),
};

export const WATER_TANK_1: OverridingRestriction = {
  name: 'Water Tank 1',
  cameraRestriction: {
    cameraLockType: CameraLockType.STATIC,
    projectionType: ProjectionType.ORTHOGRAPHIC,
    focus: '_',
    offset: new THREE.Vector3(0, 0, 50),
  },
  propertyMap: new Map([
    ['_', { color: new THREE.Color('#00ff00'), inspectable: false }],
    ['^.^.truss', { opacity: 0.5 }],
    ['^.^.commandcenter', { opacity: 0.1 }],
  ]),
};
