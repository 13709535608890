import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';

import { cssVar } from '../../..';

export default function Text(props) {
  const [position, setPosition] = useState(props.position);
  const mesh = useRef<THREE.Mesh>(null!);

  useEffect(() => {
    if (mesh.current !== null) {
      const center = new THREE.Vector3();
      mesh.current.geometry.computeBoundingBox();
      mesh.current.geometry.boundingBox.getCenter(center);
      setPosition([
        props.position[0] - center.x,
        props.position[1] - center.y,
        props.position[2],
      ]);
    }
  }, [mesh, props.text]);

  return (
    <mesh ref={mesh} position={position} rotation={props.rotation}>
      <textGeometry attach="geometry" args={[props.text, props.textOptions]} />
      <meshPhongMaterial
        attach="material"
        color={props.color ? props.color : cssVar('--white')}
      />
    </mesh>
  );
}
