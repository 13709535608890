import { CloudProduceAddress } from 'model/src/common/CloudProduceAddress';
import React, { Context } from 'react';
import * as THREE from 'three';

import { AlignmentOperation } from '../operationcontext/OperationContexts';

export const ControllerContext = React.createContext<ControllerContextType>({
  lastModifiedBy: ['ControllerContext'],
  keys: new Set(),
  baseRestriction: {
    renderFloorNodeTypes: false,
    setRenderFloorNodeTypes: (value: boolean) => {},
  },
  overridingRestriction: undefined,
  inDrag: false,
  dragMute: (
    eventHandler: (e) => void,
    contextValue: ControllerContextType
  ) => {
    return () => {};
  },
  setInDrag: () => {},
  updateContext: (
    newContextValue: ControllerContextType,
    currentContextValue: ControllerContextType
  ) => newContextValue,
  setContext: contextValue => contextValue,
  setAlignmentOperation: (
    alignmentOperation: AlignmentOperation,
    contextValue: ControllerContextType
  ) => {},
  setPlacementOperation: (placementOperation: PlacementOperation) => {},
  alignmentOperation: {},
  placementOperation: {},
}) as Context<ControllerContextType>;

export type ControllerContextType = {
  lastModifiedBy: string[];
  keys: Set<any>;
  baseRestriction: FarmRestriction;
  overridingRestriction?: HydratedOverridingRestriction;
  inDrag: boolean;
  dragMute: (
    eventHandler: (e) => void,
    contextValue: ControllerContextType
  ) => ((e) => void) | (() => void);
  setInDrag: (inDrag: boolean, contextValue: ControllerContextType) => void;
  updateContext: (
    newContextValue: ControllerContextType,
    currentContextValue: ControllerContextType
  ) => ControllerContextType;
  setContext: (contextValue: ControllerContextType) => ControllerContextType;
  setAlignmentOperation: (
    alignmentOperation: AlignmentOperation,
    contextValue: ControllerContextType
  ) => void;
  setPlacementOperation: (
    placementOperation: PlacementOperation,
    contextValue: ControllerContextType
  ) => void;
  alignmentOperation: AlignmentOperation;
  placementOperation: PlacementOperation;
};

export type PlacementOperation = {
  placeeUrl?: CloudProduceAddress;
  locationUrl?: CloudProduceAddress;
};

export type FarmRestriction = {
  renderFloorNodeTypes: boolean;
  setRenderFloorNodeTypes: (value: boolean) => void;
  cameraSettings?: CameraSettings;
};

export type OverridingRestriction = {
  name: string;
  cameraRestriction: CameraRestriction;
  propertyMap: Map<string, any>;
};

export type HydratedOverridingRestriction = {
  baseUrl: string;
  cameraRestriction: HydratedCameraRestriction;
  propertyMap: Map<string, any>;
};

export enum CameraLockType {
  FREE,
  STATIC,
  DYNAMIC,
}

export enum ProjectionType {
  ORTHOGRAPHIC,
  PERSPECTIVE,
}

export type CameraSettings = {
  cameraLockType: CameraLockType;
  controls: THREE.OrbitControls | null;
  initialPosition: THREE.Vector3;
  projectionType: ProjectionType; // TODO(austin): implement getProjectionType, test switching views with OrthographicCamera, then remove

  getFirstRaycastObject: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    scene: THREE.Scene,
    contextValue: ControllerContextType
  ) => THREE.Vector3;
  getCamera: (contextValue: ControllerContextType) => any;
  getCameraLockType: (
    contextValue: ControllerContextType
  ) => CameraLockType | undefined;
  getFov: (contextValue: ControllerContextType) => number;
  getPosition: (contextValue: ControllerContextType) => THREE.Vector3;
  getLookAt: (contextValue: ControllerContextType) => THREE.Vector3;
  getProjectionType: (
    contextValue: ControllerContextType
  ) => ProjectionType | undefined;
  removeControls: (context: ControllerContextType) => void;
  setScene?: (
    objectUrl: string,
    operation: string,
    contextValue: ControllerContextType
  ) => void;
  setControls: (
    controls: THREE.OrbitControls,
    contextValue: ControllerContextType
  ) => void;
  setPosition: (
    position: THREE.Vector3,
    context: ControllerContextType
  ) => void;
  setProjectionType: (
    projectionType: ProjectionType,
    contextValue: ControllerContextType
  ) => void;
  setLookAt: (lookAt: THREE.Vector3, context: ControllerContextType) => void;
};

export type CameraRestriction = {
  cameraLockType: CameraLockType;
  projectionType: ProjectionType;
  focus: string;
  offset: THREE.Vector3;
};

export type HydratedCameraRestriction = {
  cameraLockType: CameraLockType;
  projectionType: ProjectionType;
  position: THREE.Vector3;
  target: THREE.Vector3;
};
