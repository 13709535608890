import { Color, createColor } from 'model/src/common/Color';

export const SWATCH_STEPS: number = 10;

const BASE_BLUE: Color = createColor(40, 220, 220);
const BASE_YELLOW: Color = createColor(240, 255, 200);
const BASE_RED: Color = createColor(255, 80, 80);
const BASE_GREEN: Color = createColor(80, 255, 80);

export const THEME = {
  blues: buildSwatch(BASE_BLUE),
  yellows: buildSwatch(BASE_YELLOW),
  reds: buildSwatch(BASE_RED),
  greens: buildSwatch(BASE_GREEN),

  majorGridDimension: 100,
  standardGridDimension: 20,
  minorGridDimension: 10,
  majorTickDimension: 5,
  standardTickDimension: 3,
  minorTickDimension: 1,
};

function buildSwatch(baseColor: Color): Color[] {
  const swatch: Color[] = [];
  for (let i = SWATCH_STEPS; i >= 0; i--) {
    const darkened: Color = darken(baseColor, i);
    swatch.push(darkened);
  }
  return swatch;
}

function darken(baseColor: Color, level: number): Color {
  const ratio = level / SWATCH_STEPS;
  return createColor(
    Math.round(ratio * baseColor.r),
    Math.round(ratio * baseColor.g),
    Math.round(ratio * baseColor.b)
  );
}
