/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import { useGLTF } from '@react-three/drei';
import CT290_415S_Model from './CT290_415S.glb';

export default function CT290_415S({ ...props }) {
  const group = useRef();
  const { nodes } = useGLTF(CT290_415S_Model);
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh geometry={nodes.mesh_0.geometry} position={[-12, 0, 12]}>
        <meshPhongMaterial
          attach="material"
          color={props.color}
          transparent={props.transparent}
          opacity={props.opacity}
        />
      </mesh>
    </group>
  );
}

CT290_415S.defaultProps = {
  position: [0, 0, 0],
  rotation: [0, 0, 0],
  transparent: false,
  opacity: 1.0,
};

useGLTF.preload(CT290_415S_Model);
