import React from 'react';

import Box from '../../common/Box';

const IndicatorLightBox = props => {
  return (
    <group position={props.position} scale={[5, 5, 5]}>
      <Box color={props.color} />
    </group>
  );
};
export default IndicatorLightBox;
