import { interfaces } from 'inversify';
import React, { Context } from 'react';
import Clock from 'services/src/common/Clock';
import Logger from 'services/src/logging/Logger';
import PageKernel from 'services/src/pageKernel/PageKernel';

export type InjectionContextType = {
  clockFactory: interfaces.Factory<Clock>;
  coreLogger: Logger;
  pageKernel: PageKernel;
};

const InjectionContext = React.createContext<InjectionContextType>({
  clockFactory: {} as interfaces.Factory<Clock>,
  coreLogger: {} as Logger,
  pageKernel: {} as PageKernel,
}) as Context<InjectionContextType>;

export default InjectionContext;
