import { convert, Unit } from 'model/src/series/NumericalReading';
import React, { Suspense, useRef } from 'react';

import ErrorBoundary from '../../../../ErrorBoundary';
import Box from '../../../common/Box';
import Blind from './Blind';
import BlindBottom from './BlindBottom';
import BlindRoll from './BlindRoll';

export interface DoorProps {
  doorPositionMm: number;
  online: boolean;
  parentUnderInspection: boolean;
  parentHovered: boolean;
}

export default function Door(props: DoorProps) {
  const group = useRef();

  const doorPositionCm =
    52.10855 - props.doorPositionMm / convert(Unit.CM, Unit.MM);

  return (
    <ErrorBoundary>
      <group ref={group}>
        <ErrorBoundary>
          <Suspense fallback={<Box />}>
            <group>
              <BlindRoll
                online={props.online}
                parentUnderInspection={props.parentUnderInspection}
                parentHovered={props.parentHovered}
              />
              <group position={[78, -2, -2.4]}>
                <Blind
                  online={props.online}
                  parentUnderInspection={props.parentUnderInspection}
                  parentHovered={props.parentHovered}
                  meshScale={[1, 1, doorPositionCm * 10]}
                />
              </group>
              <group>
                <BlindBottom
                  online={props.online}
                  parentUnderInspection={props.parentUnderInspection}
                  parentHovered={props.parentHovered}
                  position={[0, 0, -(doorPositionCm - 0.1)]}
                />
              </group>
            </group>
          </Suspense>
        </ErrorBoundary>
      </group>
    </ErrorBoundary>
  );
}
