import styled from 'styled-components';

export const Underline = styled.div`
  height: 1px;
  width: auto;
  display: flexbox;
  align-self: center;
  background-color: var(--tabLine_Default);
`;

export const Highlight = styled.div`
  height: 2px;
  width: auto;
  display: block;
  align-self: center;
  background-color: var(--tabLine_Active);
  margin-top: -2px;
`;

export const InlineContainer = styled.div`
  height: var(--tabBarHeight);
  width: auto;
`;
