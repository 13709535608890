import { gql } from 'apollo-boost';
import { TrackGraph } from 'model/src/farm/entities/farm/graph/TrackGraph';
import { buildTrackGraph } from 'model/src/farm/entities/farm/graph/TrackGraphFactory';
import {
  TrackGraphNode,
  TrackGraphNodeId
} from 'model/src/farm/entities/farm/graph/TrackGraphNode';
import { TrackId } from 'model/src/farm/entities/farm/Track';
import { TrackNode, TrackNodeId } from 'model/src/farm/entities/farm/TrackNode';
import { Direction } from 'model/src/farm/entities/state/TrolleyState';
import { Occupation } from 'model/src/farm/entities/trolley/logical/LogicalTrolleyState';
import { PhysicalFarm } from 'model/src/farm/entities/trolley/physical/PhysicalFarm';
import { buildPhysicalFarm } from 'model/src/farm/entities/trolley/physical/PhysicalFarmFactory';
import {
  NODE_HEIGHT_MM,
  NODE_WIDTH_MM,
  SCANNER_SENSOR_ARRAY_SIZE
} from 'model/src/farm/entities/trolley/physical/PhysicalSize';
import { PhysicalTrolley } from 'model/src/farm/entities/trolley/physical/PhysicalTrolley';
import { buildPhysicalTrolley } from 'model/src/farm/entities/trolley/physical/PhysicalTrolleyFactory';
import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import { VirtualFarmSnapShot } from 'services/src/virtualruntime/VirtualFarmRuntime';
import {
  VirtualPositionSensorSnapShot,
  VirtualTrolleySnapShot
} from 'services/src/virtualruntime/VirtualTrolleyRuntime';
import styled from 'styled-components';
import util from 'util';

import SvgFarmNode from './SvgFarmNode';
import SvgLogicalTrolley from './SvgLogicalTrolley';
import SvgPath from './SvgPath';
import SvgTrackGraphEdge from './SvgTrackGraphEdge';
import SvgTrackGraphNode from './SvgTrackGraphNode';
import SvgVirtualTrolley from './SvgVirtualTrolley';

export const MM_TO_PIXELS = 50;
export const NODE_WIDTH: number = NODE_WIDTH_MM / MM_TO_PIXELS;
export const NODE_HEIGHT: number = NODE_HEIGHT_MM / MM_TO_PIXELS;

export interface LogicalTrolleySnapShot {
  id: string;
  currentTrackNode: string;
  currentLocation: { x: number; y: number };
  currentDirection: Direction;
  currentOccupation: Occupation;
}

export type PathScaffolding = {
  trolleyId: string;
  startPoint: string;
  endPoint: string;
  allNodeIds: string[];
  allPoints: { x: number; y: number }[];
  directions: Direction[];
};

const updatePathMutation = gql`
  mutation updatePath($updatePathInput: UpdatePathInput!) {
    updatePath(updatePathInput: $updatePathInput)
  }
`;

const SvgFarmHolder = styled.div`
  width: 100%;
  height: 100%;
  outline: 0;
`;

type Props = {
  track: any;
  trolleys: any[];
  onTrolleyLaunch: (trolleyLaunchSite: {
    id: string;
    x: number;
    y: number;
  }) => void;
};

type State = {
  pathScaffolding?: PathScaffolding;
  virtualFarmSnapShot: VirtualFarmSnapShot;
};

class SvgFarm extends Component<Props, State> {
  svgWidth: number;
  svgHeight: number;

  physicalFarm: PhysicalFarm;
  physicalTrolleys: PhysicalTrolley[];
  virtualTrolleySnapshots: VirtualTrolleySnapShot[];
  virtualTrolleySnapshotMap: VirtualTrolleySnapShot[];

  logicalTrolleySnapshots: LogicalTrolleySnapShot[];
  logicalTrolleySnapshotMap: LogicalTrolleySnapShot[];

  trackGraph: TrackGraph;
  trackNodes: TrackNode[];
  trackNodeMap: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      virtualFarmSnapShot: {
        virtualTrolleySnapShots: [],
      },
    };

    this.physicalFarm = buildPhysicalFarm(
      this.props.track.stacks.length,
      this.props.track.stacks[0].trackNodes.length,
      this.props.track.stacks
    );

    this.svgWidth = (this.props.track.stacks.length + 2) * NODE_WIDTH_MM;
    this.svgHeight =
      (this.props.track.stacks[0].trackNodes.length + 2) * NODE_HEIGHT_MM;

    this.trackNodes = [];
    this.trackNodeMap = {};
    for (let i = 0; i < this.props.track.stacks.length; i++) {
      for (let j = 0; j < this.props.track.stacks[i].trackNodes.length; j++) {
        const current = this.props.track.stacks[i].trackNodes[j];
        const trackNode: TrackNode = buildTrackNode(current);
        this.trackNodes.push(trackNode);
        this.trackNodeMap[current.id] = trackNode;
      }
    }

    this.trackGraph = buildTrackGraph(
      this.physicalFarm,
      TrackId.create(this.props.track.id),
      this.trackNodes
    );

    this.physicalTrolleys = [];
    this.virtualTrolleySnapshots = [];
    this.virtualTrolleySnapshotMap = [];

    this.logicalTrolleySnapshots = [];
    this.logicalTrolleySnapshotMap = [];

    this.props.trolleys.forEach(trolley => {
      this.initializeVirtualTrolley(trolley);
    });

    this.props.trolleys.forEach(trolley => {
      this.initializeLogicalTrolley(trolley);
    });
  }

  onUnlaunchedTrolleyClicked = (
    trolleyId: string,
    currentTrackNodeId: string
  ) => {
    const currentTrackNode = this.trackNodeMap[currentTrackNodeId];
    const launcherLocation = {
      x: currentTrackNode.x * NODE_WIDTH_MM + NODE_WIDTH_MM / 2,
      y: currentTrackNode.y * NODE_HEIGHT_MM + NODE_HEIGHT_MM / 2,
    };

    this.setState(prevState => {
      return {
        pathScaffolding: {
          trolleyId: trolleyId,
          startPoint: currentTrackNodeId,
          endPoint: currentTrackNodeId,
          allNodeIds: [currentTrackNodeId],
          allPoints: [launcherLocation],
          directions: [],
        },
      };
    });
  };

  onLauncherPressed = (trolleyLaunchSite: {
    id: string;
    x: number;
    y: number;
  }): void => {
    if (!this.state.pathScaffolding) {
      this.props.onTrolleyLaunch(trolleyLaunchSite);
    }
  };

  onMouseEnteredNode = (trackNodeId: string) => {
    if (this.state.pathScaffolding) {
      const path: {
        nodes: TrackNode[];
        directions: Direction[];
      } = determinePath(
        TrackNodeId.create(this.state.pathScaffolding.startPoint),
        TrackNodeId.create(trackNodeId),
        this.props.track,
        this.trackNodeMap
      );
      const startPoint = this.state.pathScaffolding.startPoint;
      const coordinatePath = path.nodes.map(node => {
        return {
          x: node.x * NODE_WIDTH_MM + NODE_WIDTH_MM / 2,
          y: node.y * NODE_HEIGHT_MM + NODE_HEIGHT_MM / 2,
        };
      });
      this.setState(prevState => {
        return {
          pathScaffolding: {
            trolleyId: prevState.pathScaffolding
              ? prevState.pathScaffolding.trolleyId
              : '',
            startPoint: startPoint,
            endPoint: trackNodeId,
            allNodeIds: path.nodes.map(node => {
              console.log('X node id ' + util.inspect(node, { depth: 14 }));
              return node.id.asString();
            }),
            allPoints: coordinatePath,
            directions: path.directions,
          },
        };
      });
    }
  };

  private initializeVirtualTrolley(trolley: any) {
    const physicalTrolley = buildPhysicalTrolley(trolley.id, trolley.name);
    this.physicalTrolleys[trolley.id] = physicalTrolley;
    this.virtualTrolleySnapshotMap[trolley.id] = {
      id: trolley.id,
      name: trolley.name,
      state: trolley.virtual.sensors.state,
      offset: trolley.virtual.offset,
      velocity: trolley.virtual.velocity,
      path: trolley.virtual.path,
      usedPath: trolley.virtual.usedPath,
      currentCommand: trolley.virtual.currentCommand,
      sensorsSnapshot: {
        state: trolley.virtual.sensors.state,
        front: {
          id: trolley.virtual.sensors.front.id,
          state: trolley.virtual.sensors.front.state,
          sensors: dimensionalize(trolley.virtual.sensors.front.sensors),
        },
        back: {
          id: trolley.virtual.sensors.back.id,
          state: trolley.virtual.sensors.back.state,
          sensors: dimensionalize(trolley.virtual.sensors.back.sensors),
        },
        left: {
          id: trolley.virtual.sensors.left.id,
          state: trolley.virtual.sensors.left.state,
          sensors: dimensionalize(trolley.virtual.sensors.left.sensors),
        },
        right: {
          id: trolley.virtual.sensors.right.id,
          state: trolley.virtual.sensors.right.state,
          sensors: dimensionalize(trolley.virtual.sensors.right.sensors),
        },
      },
      listeningForPosition: false,
    };
    this.virtualTrolleySnapshots.push(
      this.virtualTrolleySnapshotMap[trolley.id]
    );

    //console.log('trackGraph ' + util.inspect(this.trackGraph, { depth: 14 }));
  }

  private initializeLogicalTrolley(trolley: any) {
    this.logicalTrolleySnapshotMap[trolley.id] = {
      id: trolley.id,
      currentTrackNode: determineTrackNode(
        TrackGraphNodeId.create(trolley.logical.currentLocation),
        this.trackGraph
      ),
      currentLocation: determineLocation(
        TrackGraphNodeId.create(trolley.logical.currentLocation),
        this.trackGraph,
        this.trackNodeMap
      ),
      currentDirection: trolley.logical.currentDirection,
      currentOccupation: trolley.logical.currentOccupation,
    };
    this.logicalTrolleySnapshots.push(
      this.logicalTrolleySnapshotMap[trolley.id]
    );
  }

  onKeyPressed = event => {
    if (event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27) {
      this.setState(prevState => {
        return {
          pathScaffolding: undefined,
        };
      });
    }
  };

  render() {
    this.props.trolleys.forEach(trolley => {
      if (trolley.virtual) {
        if (!this.virtualTrolleySnapshotMap[trolley.id]) {
          this.initializeVirtualTrolley(trolley);
        }
        this.updateVirtualTrolleySnapshot(trolley);
      }
      if (trolley.logical) {
        if (!this.logicalTrolleySnapshotMap[trolley.id]) {
          this.initializeLogicalTrolley(trolley);
        }
        this.updateLogicalTrolleySnapshot(trolley);
      }
    });

    return (
      <Mutation mutation={updatePathMutation}>
        {(updatePath: any, { loading, error }: any) => {
          const svgFarmNodes: any[] = [];
          for (let i = 0; i < this.props.track.stacks.length; i++) {
            for (
              let j = 0;
              j < this.props.track.stacks[i].trackNodes.length;
              j++
            ) {
              const trackNode = this.props.track.stacks[i].trackNodes[j];
              svgFarmNodes.push(
                <SvgFarmNode
                  key={trackNode.id}
                  trackNode={trackNode}
                  x={i}
                  y={j}
                  onNodePressed={(trackNodeId: string) => {
                    if (this.state.pathScaffolding) {
                      console.log(
                        'SET NEW PATH FOR TROLLEY!!! ' +
                          util.inspect(this.state.pathScaffolding, {
                            depth: 14,
                          })
                      );

                      updatePath({
                        variables: {
                          updatePathInput: {
                            trolleyId: this.state.pathScaffolding.trolleyId,
                            trackNodeIds: this.state.pathScaffolding.allNodeIds,
                            directions: this.state.pathScaffolding.directions,
                          },
                        },
                      });

                      this.setState(prevState => {
                        return {
                          pathScaffolding: undefined,
                        };
                      });
                    }
                  }}
                  onLauncherPressed={this.onLauncherPressed}
                  onMouseEntered={this.onMouseEnteredNode}
                />
              );
            }
          }
          return (
            <SvgFarmHolder onKeyDown={this.onKeyPressed} tabIndex={0}>
              <svg
                width={this.svgWidth / MM_TO_PIXELS}
                height={this.svgHeight / MM_TO_PIXELS}
                viewBox={'0, 0, ' + this.svgWidth + ', ' + this.svgHeight}>
                <rect
                  x="0"
                  y="0"
                  width="100%"
                  height="100%"
                  fill="transparent"
                />
                {svgFarmNodes}
                {this.virtualTrolleySnapshots.map(
                  (virtualTrolleySnapShot: VirtualTrolleySnapShot) => {
                    return (
                      <SvgVirtualTrolley
                        key={virtualTrolleySnapShot.name}
                        name={virtualTrolleySnapShot.name}
                        physicalTrolley={
                          this.physicalTrolleys[virtualTrolleySnapShot.id]
                        }
                        clock={0}
                        virtualTrolleySnapShot={virtualTrolleySnapShot}
                      />
                    );
                  }
                )}
                {this.logicalTrolleySnapshots.map(
                  (logicalTrolleySnapshot: LogicalTrolleySnapShot) => {
                    return (
                      <SvgLogicalTrolley
                        key={logicalTrolleySnapshot.id}
                        physicalTrolley={
                          this.physicalTrolleys[logicalTrolleySnapshot.id]
                        }
                        logicalTrolleySnapShot={logicalTrolleySnapshot}
                        onUnlaunchedTrolleyClicked={
                          this.onUnlaunchedTrolleyClicked
                        }
                      />
                    );
                  }
                )}
                {false &&
                  this.trackGraph.nodes.map(
                    (trackGraphNode: TrackGraphNode) => {
                      return (
                        <SvgTrackGraphEdge
                          key={trackGraphNode.id.asString() + '_edges'}
                          trackGraphNode={trackGraphNode}
                          trackGraph={this.trackGraph}
                        />
                      );
                    }
                  )}
                {false &&
                  this.trackGraph.nodes.map(
                    (trackGraphNode: TrackGraphNode) => {
                      return (
                        <SvgTrackGraphNode
                          key={trackGraphNode.id.asString()}
                          trackGraphNode={trackGraphNode}
                        />
                      );
                    }
                  )}
                {this.state.pathScaffolding && (
                  <SvgPath path={this.state.pathScaffolding.allPoints} />
                )}
              </svg>
            </SvgFarmHolder>
          );
        }}
      </Mutation>
    );
  }

  private updateLogicalTrolleySnapshot(trolley: any) {
    //console.log('SvgFarm:updateLogicalTrolleySnapshot start');
    // if (trolley.logical.currentLocation) {
    //   this.logicalTrolleySnapshotMap[trolley.id].currentLocation =
    //     trolley.logical.currentLocation;
    // }
    // if (trolley.logical.currentDirection) {
    //   this.logicalTrolleySnapshotMap[trolley.id].currentDirection =
    //     trolley.logical.currentDirection;
    // }
    // if (trolley.logical.currentOccupation) {
    //   this.logicalTrolleySnapshotMap[trolley.id].currentOccupation =
    //     trolley.logical.currentOccupation;
    // }
    //console.log('SvgFarm:updateLogicalTrolleySnapshot end');
  }

  private updateVirtualTrolleySnapshot(trolley: any) {
    if (trolley.virtual.state) {
      this.virtualTrolleySnapshotMap[trolley.id].state = trolley.virtual.state;
      this.virtualTrolleySnapshotMap[trolley.id].sensorsSnapshot.state =
        trolley.virtual.state;
    }
    if (trolley.virtual.offset) {
      this.virtualTrolleySnapshotMap[trolley.id].offset =
        trolley.virtual.offset;
    }
    if (trolley.virtual.velocity) {
      this.virtualTrolleySnapshotMap[trolley.id].velocity =
        trolley.virtual.velocity;
    }
    if (trolley.virtual.sensors) {
      if (trolley.virtual.sensors.state) {
        this.virtualTrolleySnapshotMap[trolley.id].sensorsSnapshot.state =
          trolley.virtual.sensors.state;
      }
      copyPositionSensorArray(
        this.virtualTrolleySnapshotMap[trolley.id].sensorsSnapshot.front,
        trolley.virtual.sensors.front
      );
      copyPositionSensorArray(
        this.virtualTrolleySnapshotMap[trolley.id].sensorsSnapshot.back,
        trolley.virtual.sensors.back
      );
      copyPositionSensorArray(
        this.virtualTrolleySnapshotMap[trolley.id].sensorsSnapshot.left,
        trolley.virtual.sensors.left
      );
      copyPositionSensorArray(
        this.virtualTrolleySnapshotMap[trolley.id].sensorsSnapshot.right,
        trolley.virtual.sensors.right
      );
    }
  }
}

function copyPositionSensorArray(existingSensorArray, newSensorArray) {
  if (newSensorArray) {
    if (newSensorArray.state) {
      existingSensorArray.state = newSensorArray.state;
    }
    let sensorMap = {};
    existingSensorArray.sensors.forEach(column => {
      column.forEach(sensor => {
        sensorMap[sensor.id] = sensor;
      });
    });
    if (newSensorArray.sensors) {
      newSensorArray.sensors.forEach(sensor => {
        let existing = sensorMap[sensor.id];
        if (sensor.state) {
          existing.state = sensor.state;
        }
        if (sensor.level) {
          existing.level = sensor.level;
        }
      });
    }
  }
}

function dimensionalize(sensors: any[]): VirtualPositionSensorSnapShot[][] {
  let sensorSnapShots: VirtualPositionSensorSnapShot[][] = [];
  for (let i = 0; i < SCANNER_SENSOR_ARRAY_SIZE; i++) {
    sensorSnapShots[i] = [];
    for (let j = 0; j < SCANNER_SENSOR_ARRAY_SIZE; j++) {
      const index = i * SCANNER_SENSOR_ARRAY_SIZE + j;
      const sensor = sensors[index];
      sensorSnapShots[i][j] = {
        id: sensor.id,
        state: sensor.state,
        level: sensor.level,
      };
    }
  }
  return sensorSnapShots;
}

function determineTrackNode(
  trackGraphNodeId: TrackGraphNodeId,
  trackGraph: TrackGraph
): string {
  const trackGraphNode = trackGraph.getNodeById(trackGraphNodeId);
  return trackGraphNode.trackNodeId.asString();
}

function determineLocation(
  trackGraphNodeId: TrackGraphNodeId,
  trackGraph: TrackGraph,
  trackNodeMap: any
): { x: number; y: number } {
  const trackGraphNode = trackGraph.getNodeById(trackGraphNodeId);
  const trackNode: TrackNode =
    trackNodeMap[trackGraphNode.trackNodeId.asString()];
  return {
    x: trackNode.x * NODE_WIDTH_MM,
    y: trackNode.y * NODE_HEIGHT_MM,
  };
}

function determinePath(
  startPointId: TrackGraphNodeId,
  endPointId: TrackGraphNodeId,
  track: any,
  trackNodeMap: any
): { nodes: TrackNode[]; directions: Direction[] } {
  const graphGrid: any[][] = buildGraphGrid(track);
  const startPoint = trackNodeMap[startPointId.asString()];
  const endPoint = trackNodeMap[endPointId.asString()];
  const numStacks = track.stacks.length - 2;
  const stackSize = track.stacks[0].trackNodes.length - 3;

  const nodes: TrackNode[] = [startPoint];
  const directions: Direction[] = [];
  let current = startPoint;
  while (current.x !== endPoint.x || current.y !== endPoint.y) {
    if (isInUpperHighway(current.x, current.y, numStacks, stackSize)) {
      current = graphGrid[current.x + 1][current.y];
      directions.push(Direction.RIGHT);
    } else if (isInSideHighway(current.x, current.y, numStacks, stackSize)) {
      current = graphGrid[current.x][current.y + 1];
      directions.push(Direction.REVERSE);
    } else if (isInBottomHighway(current.x, current.y, numStacks, stackSize)) {
      if (isReadyToEnterStacks(current.x, endPoint.x)) {
        current = graphGrid[current.x][current.y - 1];
        directions.push(Direction.FORWARD);
      } else {
        current = graphGrid[current.x - 1][current.y];
        directions.push(Direction.LEFT);
      }
    } else if (isInStacks(current.x, current.y, numStacks, stackSize)) {
      current = graphGrid[current.x][current.y - 1];
      directions.push(Direction.FORWARD);
    } else if (isInHarvester(current.x, current.y, numStacks, stackSize)) {
      current = graphGrid[current.x - 1][current.y];
      directions.push(Direction.LEFT);
    } else if (isInLauncher(current.x, current.y, numStacks, stackSize)) {
      current = graphGrid[current.x][current.y - 1];
      directions.push(Direction.FORWARD);
    } else if (isInSuperJunction(current.x, current.y, numStacks, stackSize)) {
      if (isInHarvester(endPoint.x, endPoint.y, numStacks, stackSize)) {
        current = graphGrid[current.x + 1][current.y];
        directions.push(Direction.RIGHT);
      } else if (isInLauncher(endPoint.x, endPoint.y, numStacks, stackSize)) {
        current = graphGrid[current.x][current.y + 1];
        directions.push(Direction.REVERSE);
      } else {
        current = graphGrid[current.x - 1][current.y];
        directions.push(Direction.LEFT);
      }
    } else {
      throw new Error('Incomputable Path!');
    }
    nodes.push(current);
  }
  return { nodes: nodes, directions: directions };
}

function buildGraphGrid(track: any): any[][] {
  const graphGrid: any[][] = [];
  for (let i = 0; i < track.stacks.length; i++) {
    graphGrid[i] = [];
    const stack = track.stacks[i];
    for (let j = 0; j < stack.trackNodes.length; j++) {
      const trackNode = stack.trackNodes[j];
      graphGrid[i][j] = buildTrackNode(trackNode);
    }
  }
  return graphGrid;
}

function buildTrackNode(current: any): TrackNode {
  return {
    id: TrackNodeId.create(current.id),
    type: current.type,
    width: current.width,
    length: current.length,
    x: current.x,
    y: current.y,
  };
}

function isInUpperHighway(
  locationX: number,
  locationY: number,
  numStacks: number,
  stackSize: number
): boolean {
  if (locationX < numStacks && locationY === 0) {
    return true;
  }
  return false;
}

function isInSideHighway(
  locationX: number,
  locationY: number,
  numStacks: number,
  stackSize: number
): boolean {
  if (locationX === numStacks && locationY <= stackSize) {
    return true;
  }
  return false;
}

function isInBottomHighway(
  locationX: number,
  locationY: number,
  numStacks: number,
  stackSize: number
): boolean {
  if (locationX < numStacks && locationY === stackSize + 1) {
    return true;
  }
  return false;
}

function isReadyToEnterStacks(locationX: number, endPointX: number): boolean {
  if (endPointX >= locationX) {
    return true;
  }
  return false;
}

function isInStacks(
  locationX: number,
  locationY: number,
  numStacks: number,
  stackSize: number
): boolean {
  if (locationX < numStacks && locationY > 0 && locationY <= stackSize) {
    return true;
  }
  return false;
}

function isInSuperJunction(
  locationX: number,
  locationY: number,
  numStacks: number,
  stackSize: number
): boolean {
  if (locationX === numStacks && locationY === stackSize + 1) {
    return true;
  }
  return false;
}

function isInLauncher(
  locationX: number,
  locationY: number,
  numStacks: number,
  stackSize: number
): boolean {
  if (locationX === numStacks && locationY === stackSize + 2) {
    return true;
  }
  return false;
}

function isInHarvester(
  locationX: number,
  locationY: number,
  numStacks: number,
  stackSize: number
): boolean {
  if (locationX === numStacks + 1 && locationY === stackSize + 1) {
    return true;
  }
  return false;
}

export default SvgFarm;
