/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import * as THREE from 'three';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';

import { useGLTF } from '@react-three/drei';

import { RenderingType } from '../../RenderingType';
import materialFactory from '../../trolley/MaterialFactory';
import TierLightColumns_Model from './TierLightColumns.glb';

export default function TierLightColumns(props) {
  const group = useRef();
  const { nodes } = useGLTF(TierLightColumns_Model) as GLTF;

  const material: THREE.Material = materialFactory.generateMaterialObject(
    props.parentUnderInspection,
    props.parentHovered,
    props.online
  );

  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[78, 0, 24.64]} rotation={[0, 0, -Math.PI / 2]}>
        <group position={[0, 2.3, -0.5]}>
          <mesh
            geometry={nodes.Body1.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1.material
                : material
            }
          />
        </group>
        <group position={[0, 2.31, 0.41]}>
          <mesh
            geometry={nodes.Body1_1.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_1.material
                : material
            }
          />
        </group>
        <group
          position={[0, 2.31, 49.7]}
          rotation={[Math.PI / 2, 1.19, Math.PI]}>
          <mesh
            geometry={nodes.Body1_2.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_2.material
                : material
            }
          />
        </group>
        <group
          position={[0, 2.31, 49.95]}
          rotation={[-Math.PI / 2, 1.28, -Math.PI / 2]}>
          <mesh
            geometry={nodes.Body1_3.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_3.material
                : material
            }
          />
        </group>
        <group position={[0, 2.3, -0.3]} rotation={[Math.PI / 2, 0, 0]}>
          <mesh
            geometry={nodes.Body1_4.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_4.material
                : material
            }
          />
        </group>
        <group rotation={[0, 0, -Math.PI / 2]}>
          <mesh
            geometry={nodes.Body1_5.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_5.material
                : material
            }
          />
        </group>
        <group position={[0, 0.46, 0]}>
          <group position={[0, 1.85, 53]} rotation={[0, 0, -0.23]} />
          <group position={[0, 1.85, 54.11]} rotation={[Math.PI, 0, 2.36]} />
          <group position={[2.19, 1.85, 50.3]} rotation={[0, 0, 0.59]} />
          <group
            position={[-2.19, 1.85, 54.5]}
            rotation={[0, -Math.PI / 2, 0]}
          />
          <group position={[-2.19, 1.85, 50.3]} rotation={[0, 0, 0.55]} />
          <group position={[0, 4.04, 50.3]} rotation={[0, 0, -0.05]} />
          <group position={[0, 4.04, 54.5]} rotation={[0, -Math.PI / 2, 0]} />
          <group
            position={[2.19, 1.85, 54.5]}
            rotation={[0, -Math.PI / 2, 0]}
          />
        </group>
        <group position={[0, 2.31, 48.88]}>
          <mesh
            geometry={nodes.Body1_6.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_6.material
                : material
            }
          />
        </group>
        <group position={[0, 2.31, 0.3]} rotation={[Math.PI / 2, 1.04, 0]}>
          <mesh
            geometry={nodes.Body1_7.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_7.material
                : material
            }
          />
        </group>
        <group
          position={[0, 2.31, 0.05]}
          rotation={[Math.PI / 2, -1.28, Math.PI / 2]}>
          <mesh
            geometry={nodes.Body1_8.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_8.material
                : material
            }
          />
        </group>
        <group position={[0.9, 2.3, 50]}>
          <group
            position={[-0.9, 0, -0.3]}
            rotation={[-Math.PI / 2, 0, Math.PI]}
          />
          <mesh
            geometry={nodes.Worm_Gear_Motor_Bracket.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Worm_Gear_Motor_Bracket.material
                : material
            }
          />
        </group>
      </group>
      <group position={[-78, 0, 24.64]} rotation={[0, 0, Math.PI / 2]}>
        <group position={[0, 2.3, -0.5]}>
          <mesh
            geometry={nodes.Body1_10.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_10.material
                : material
            }
          />
        </group>
        <group position={[0, 2.31, 0.41]}>
          <mesh
            geometry={nodes.Body1_11.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_11.material
                : material
            }
          />
        </group>
        <group
          position={[0, 2.31, 49.7]}
          rotation={[Math.PI / 2, 1.19, Math.PI]}>
          <mesh
            geometry={nodes.Body1_12.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_12.material
                : material
            }
          />
        </group>
        <group
          position={[0, 2.31, 49.95]}
          rotation={[-Math.PI / 2, 1.28, -Math.PI / 2]}>
          <mesh
            geometry={nodes.Body1_13.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_13.material
                : material
            }
          />
        </group>
        <group position={[0, 2.3, -0.3]} rotation={[Math.PI / 2, 0, 0]}>
          <mesh
            geometry={nodes.Body1_14.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_14.material
                : material
            }
          />
        </group>
        <group rotation={[0, 0, -Math.PI / 2]}>
          <mesh
            geometry={nodes.Body1_15.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_15.material
                : material
            }
          />
        </group>
        <group position={[0, 0.46, 0]}>
          <group position={[0, 1.85, 53]} rotation={[0, 0, -0.23]} />
          <group position={[0, 1.85, 54.11]} rotation={[Math.PI, 0, 2.36]} />
          <group position={[2.19, 1.85, 50.3]} rotation={[0, 0, 0.59]} />
          <group
            position={[-2.19, 1.85, 54.5]}
            rotation={[0, -Math.PI / 2, 0]}
          />
          <group position={[-2.19, 1.85, 50.3]} rotation={[0, 0, 0.55]} />
          <group position={[0, 4.04, 50.3]} rotation={[0, 0, -0.05]} />
          <group position={[0, 4.04, 54.5]} rotation={[0, -Math.PI / 2, 0]} />
          <group
            position={[2.19, 1.85, 54.5]}
            rotation={[0, -Math.PI / 2, 0]}
          />
        </group>
        <group position={[0, 2.31, 48.88]}>
          <mesh
            geometry={nodes.Body1_16.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_16.material
                : material
            }
          />
        </group>
        <group position={[0, 2.31, 0.3]} rotation={[Math.PI / 2, 1.04, 0]}>
          <mesh
            geometry={nodes.Body1_17.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_17.material
                : material
            }
          />
        </group>
        <group
          position={[0, 2.31, 0.05]}
          rotation={[Math.PI / 2, -1.28, Math.PI / 2]}>
          <mesh
            geometry={nodes.Body1_18.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_18.material
                : material
            }
          />
        </group>
        <group position={[0.9, 2.3, 50]}>
          <group
            position={[-0.9, 0, -0.3]}
            rotation={[-Math.PI / 2, 0, -Math.PI]}
          />
          <mesh
            geometry={nodes.Worm_Gear_Motor_Bracket_1.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Worm_Gear_Motor_Bracket_1.material
                : material
            }
          />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload(TierLightColumns_Model);
