import {
  PhysicalSensor,
  PhysicalSensorState
} from 'model/src/farm/entities/trolley/physical/PhysicalSensor';
import { PhysicalSensorArray } from 'model/src/farm/entities/trolley/physical/PhysicalSensorArray';
import {
  FARM_LINE_WIDTH_MM,
  TROLLEY_GAP_MM
} from 'model/src/farm/entities/trolley/physical/PhysicalSize';
import { PhysicalTrolley } from 'model/src/farm/entities/trolley/physical/PhysicalTrolley';
import { VirtualTrolleyPositionSensorArrayPattern } from 'model/src/farm/entities/trolley/virtual/VirtualTrolleyPositionSensorArrayState';
import { VirtualTrolleyPositionSensorsPattern } from 'model/src/farm/entities/trolley/virtual/VirtualTrolleyPositionSensorsState';
import React, { Component } from 'react';
import {
  VirtualPositionSensorSnapShot,
  VirtualTrolleyPositionSensorArraySnapShot,
  VirtualTrolleySnapShot
} from 'services/src/virtualruntime/VirtualTrolleyRuntime';

type Props = {
  name: string;
  physicalTrolley: PhysicalTrolley;
  clock: number;
  virtualTrolleySnapShot: VirtualTrolleySnapShot;
};

type State = {};

class SvgVirtualTrolley extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
    this.onClick = this.onClick.bind(this);
  }

  onClick(event) {
    console.log('VT CLICKED: ' + this.props.name);
  }

  render() {
    const outlinePath = `
      M${this.props.physicalTrolley.leftExtent +
        this.props.virtualTrolleySnapShot.offset.cX} ${this.props
      .physicalTrolley.topExtent + this.props.virtualTrolleySnapShot.offset.cY} 
      v ${this.props.physicalTrolley.bottomExtent}
      h ${this.props.physicalTrolley.rightExtent} 
      v ${-this.props.physicalTrolley.bottomExtent}
      h ${-this.props.physicalTrolley.rightExtent}
    `;

    const linesPath = `
    M${this.props.physicalTrolley.sensorArrayLeft.centerX +
      this.props.virtualTrolleySnapShot.offset.cX} ${this.props.physicalTrolley
      .topExtent + this.props.virtualTrolleySnapShot.offset.cY} v ${
      this.props.physicalTrolley.bottomExtent
    }
    M${this.props.physicalTrolley.sensorArrayTop.centerX +
      this.props.virtualTrolleySnapShot.offset.cX} ${TROLLEY_GAP_MM +
      this.props.virtualTrolleySnapShot.offset.cY} v ${
      this.props.physicalTrolley.bottomExtent
    }
    M${this.props.physicalTrolley.sensorArrayRight.centerX +
      this.props.virtualTrolleySnapShot.offset.cX} ${TROLLEY_GAP_MM +
      this.props.virtualTrolleySnapShot.offset.cY} v ${
      this.props.physicalTrolley.bottomExtent
    }
    M${this.props.physicalTrolley.leftExtent +
      this.props.virtualTrolleySnapShot.offset.cX} ${this.props.physicalTrolley
      .sensorArrayTop.centerY +
      this.props.virtualTrolleySnapShot.offset.cY} h ${
      this.props.physicalTrolley.rightExtent
    } 
    M${this.props.physicalTrolley.leftExtent +
      this.props.virtualTrolleySnapShot.offset.cX} ${this.props.physicalTrolley
      .sensorArrayLeft.centerY +
      this.props.virtualTrolleySnapShot.offset.cY} h ${
      this.props.physicalTrolley.rightExtent
    }
    M${this.props.physicalTrolley.leftExtent +
      this.props.virtualTrolleySnapShot.offset.cX} ${this.props.physicalTrolley
      .sensorArrayBottom.centerY +
      this.props.virtualTrolleySnapShot.offset.cY} h ${
      this.props.physicalTrolley.rightExtent
    }
  `;

    let strokeColor: string = 'gray';
    switch (this.props.virtualTrolleySnapShot.state) {
      case VirtualTrolleyPositionSensorsPattern.FULL: {
        strokeColor = 'white';
        break;
      }
    }

    return (
      <>
        <path
          d={outlinePath}
          fill="magenta"
          fillOpacity={0}
          stroke={strokeColor}
          strokeWidth={20}
          onClick={this.onClick}
        />
        <path d={linesPath} strokeWidth={1} stroke="orange" />
        {this.renderArray(
          this.props.physicalTrolley.sensorArrayTop,
          this.props.virtualTrolleySnapShot.sensorsSnapshot.front
        )}
        {this.renderArray(
          this.props.physicalTrolley.sensorArrayBottom,
          this.props.virtualTrolleySnapShot.sensorsSnapshot.back
        )}
        {this.renderArray(
          this.props.physicalTrolley.sensorArrayLeft,
          this.props.virtualTrolleySnapShot.sensorsSnapshot.left
        )}
        {this.renderArray(
          this.props.physicalTrolley.sensorArrayRight,
          this.props.virtualTrolleySnapShot.sensorsSnapshot.right
        )}
        <text
          x={
            this.props.physicalTrolley.sensorArrayTop.centerX +
            this.props.virtualTrolleySnapShot.offset.cX
          }
          y={
            this.props.physicalTrolley.sensorArrayLeft.centerY +
            this.props.virtualTrolleySnapShot.offset.cY -
            125
          }
          textAnchor="middle"
          alignmentBaseline="central"
          fontSize="250"
          fill="white">
          {this.props.physicalTrolley.name}
        </text>
        <text
          x={
            this.props.physicalTrolley.sensorArrayTop.centerX +
            this.props.virtualTrolleySnapShot.offset.cX
          }
          y={
            this.props.physicalTrolley.sensorArrayLeft.centerY +
            this.props.virtualTrolleySnapShot.offset.cY +
            125
          }
          textAnchor="middle"
          alignmentBaseline="central"
          fontSize="250"
          fill="white">
          {this.props.virtualTrolleySnapShot.state}
        </text>
      </>
    );
  }

  renderArray(
    physicalSensorArray: PhysicalSensorArray,
    physicalSensorArraySnapshot: VirtualTrolleyPositionSensorArraySnapShot
  ): any {
    let scannerArray: any[] = [];
    for (let i = 0; i < physicalSensorArray.sensors.length; i++) {
      for (let j = 0; j < physicalSensorArray.sensors[i].length; j++) {
        const phyiscalSensorSnapShot: VirtualPositionSensorSnapShot =
          physicalSensorArraySnapshot.sensors[i][j];
        const physicalSensor: PhysicalSensor =
          physicalSensorArray.sensors[i][j];
        const sx =
          this.props.virtualTrolleySnapShot.offset.cX + physicalSensor.centerX;
        const sy =
          this.props.virtualTrolleySnapShot.offset.cY + physicalSensor.centerY;
        let strokeColor = 'black';
        switch (phyiscalSensorSnapShot.state) {
          case PhysicalSensorState.ON:
            strokeColor = 'green';
            break;
          case PhysicalSensorState.OFF:
            strokeColor = 'red';
            break;
          case PhysicalSensorState.MIDDLE:
            strokeColor = 'yellow';
            break;
        }
        scannerArray.push(
          <circle
            key={phyiscalSensorSnapShot.id}
            cx={sx}
            cy={sy}
            r={FARM_LINE_WIDTH_MM / 4.0}
            strokeWidth="1"
            stroke={strokeColor}
            fill="cyan"
            fillOpacity={1 - phyiscalSensorSnapShot.level}
            strokeOpacity={1.0}
          />
        );
      }
    }

    let strokeColor: string = 'black';
    switch (physicalSensorArraySnapshot.state) {
      case VirtualTrolleyPositionSensorArrayPattern.NONE: {
        strokeColor = 'black';
        break;
      }
      case VirtualTrolleyPositionSensorArrayPattern.DIAMOND: {
        strokeColor = 'yellow';
        break;
      }
      case VirtualTrolleyPositionSensorArrayPattern.VERTICAL_LINE: {
        strokeColor = 'green';
        break;
      }
      case VirtualTrolleyPositionSensorArrayPattern.HORIZONTAL_LINE: {
        strokeColor = 'purple';
        break;
      }
    }

    scannerArray.push(
      <rect
        key={physicalSensorArray.id}
        x={
          this.props.virtualTrolleySnapShot.offset.cX +
          physicalSensorArray.centerX -
          75
        }
        y={
          this.props.virtualTrolleySnapShot.offset.cY +
          physicalSensorArray.centerY -
          75
        }
        width="150"
        height="150"
        strokeWidth="5"
        stroke={strokeColor}
        fill="cyan"
        fillOpacity={0}
        strokeOpacity={1.0}
      />
    );

    return scannerArray;
  }
}

export default SvgVirtualTrolley;
