import { toAddress } from 'model/src/common/CloudProduceAddressUtility';
import {
  Axis,
  FarmSubsystem,
  TugSubsystem,
  urlCombine,
} from 'model/src/common/Systems';
import { Data } from 'model/src/dataflowprotocol/Datum';
import { ComputedPositionReading } from 'model/src/series/ComputedPositionReading';
import { NumericalReading } from 'model/src/series/NumericalReading';
import { SeriesType } from 'model/src/series/SeriesTypes';
import { StringReading } from 'model/src/series/StringReading';
import React, { Suspense, useContext, useMemo } from 'react';

import { cssVar } from '../../..';
import {
  ControllerContext,
  ControllerContextType,
} from '../../farmviewer/context/controllercontext/ControllerContext';
import {
  DesktopContext,
  DesktopContextType,
} from '../../farmviewer/context/desktopcontext/DesktopContext';
import TargetBox from '../../farmviewer/TargetBox';
import Box from '../common/Box';
import Tower from '../tower/Tower';
import Trolley from '../trolley/Trolley';
import Truss from '../truss/Truss';
import CommandCenterFloor from './CommandCenterFloor';
import CoordinateSpace from './CoordinateSpace';
import Floor from './Floor';
import LampsonDecals from './LampsonDecals';
import LampsonFloor from './LampsonFloor';
import Stack from './stack/Stack';

export default function Farm(props) {
  const towers: any = [];
  const tugs: any = [];
  const stacks: any = [];

  const controllerContext: ControllerContextType = useContext(
    ControllerContext
  );
  const desktopContext: DesktopContextType = useContext(DesktopContext);

  const farmObjectOnClick = useMemo(() => {
    return controllerContext.dragMute(
      url => props.onClick(url, desktopContext),
      controllerContext
    );
  }, [desktopContext, controllerContext]);

  props.dataMap.stackMap.forEach(stack => {
    const stackId = stack.id.replace('.' + SeriesType.NAME, '');

    stacks.push(
      <Stack
        key={stackId}
        url={stackId}
        onClick={farmObjectOnClick}
        showMenu={props.showMenu}
        inspectionContext={props.inspectionContext}
        objectMap={props.objectMap}
        dataMap={props.dataMap}
        connectionToServer={props.connectionToServer}
      />
    );
  });

  props.dataMap.towerMap.forEach(tower => {
    const towerId = tower.id.replace('.' + SeriesType.NAME, '');

    const towerPositionXUrl =
      towerId + urlCombine([Axis.X, SeriesType.POSITION]);
    const positionX: Data<NumericalReading> = props.dataMap.all.get(
      towerPositionXUrl
    );

    const towerPositionYUrl =
      towerId + urlCombine([Axis.Y, SeriesType.POSITION]);
    const positionY: Data<NumericalReading> = props.dataMap.all.get(
      towerPositionYUrl
    );

    const towerPositionZUrl =
      towerId + urlCombine([Axis.Z, SeriesType.POSITION]);
    const positionZ: Data<NumericalReading> = props.dataMap.all.get(
      towerPositionZUrl
    );

    towers.push(
      <Tower
        key={towerId}
        url={towerId}
        positionX={positionX}
        positionY={positionY}
        positionZ={positionZ}
        onClick={farmObjectOnClick}
        showMenu={props.showMenu}
        inspectionContext={props.inspectionContext}
        objectMap={props.objectMap}
        dataMap={props.dataMap}
        globals={props.globals}
      />
    );
  });

  props.dataMap.tugMap.forEach(tug => {
    const tugId = tug.id.replace('.' + SeriesType.NAME, '');
    const tugPositionXUrl = tugId + urlCombine([Axis.X, SeriesType.POSITION]);
    const positionX: Data<NumericalReading> = props.dataMap.all.get(
      tugPositionXUrl
    );

    const tugPositionYUrl = tugId + urlCombine([Axis.Y, SeriesType.POSITION]);
    const positionY: Data<NumericalReading> = props.dataMap.all.get(
      tugPositionYUrl
    );

    const tugPositionZUrl = tugId + urlCombine([Axis.Z, SeriesType.POSITION]);
    const positionZ: Data<NumericalReading> = props.dataMap.all.get(
      tugPositionZUrl
    );

    const tugComputedPositionUrl =
      tugId + urlCombine([SeriesType.COMPUTED_POSITION]);
    const computedPosition: Data<
      ComputedPositionReading
    > = props.dataMap.all.get(tugComputedPositionUrl);

    const tugAddress = toAddress(tugId);

    tugs.push(
      <Trolley
        key={tugId}
        url={tugAddress}
        positionX={positionX}
        positionY={positionY}
        positionZ={positionZ}
        computedPosition={computedPosition}
        onClick={farmObjectOnClick}
        showMenu={props.showMenu}
        inspectionContext={props.inspectionContext}
        objectMap={props.objectMap}
        dataMap={props.dataMap}
        globals={props.globals}
      />
    );
  });

  const floor: any = useMemo(() => {
    return (
      <Suspense fallback={<Box />}>
        <LampsonFloor />;
      </Suspense>
    );
  }, []);

  const decals: any = useMemo(() => {
    return (
      <Suspense fallback={<Box />}>
        <LampsonDecals
          color={cssVar('--floorMarkings')}
          opacity={1.0}
          transparent={false}
        />
      </Suspense>
    );
  }, []);

  const tugHomeUrl =
    '1' +
    urlCombine([
      FarmSubsystem.TUG,
      '1',
      SeriesType.HOME,
      SeriesType.GRID_SQUARE_URL,
    ]);
  const tug1HomeSquareDatum: Data<StringReading> = props.dataMap.all.get(
    tugHomeUrl
  );
  const tug1HomeSquareUrl = tug1HomeSquareDatum.data[0].reading.value;
  const grid = useMemo(() => {
    const floors: any = [];
    props.dataMap.floorMap.forEach(floor => {
      floors.push(floor);
    });
    return <Floor floor={floors} tug1HomeSquareUrl={tug1HomeSquareUrl} />;
  }, [tug1HomeSquareUrl]);

  const targetBox = useMemo(() => {
    return <TargetBox />;
  }, []);

  const truss = useMemo(() => {
    return (
      <Suspense fallback={<Box />}>
        <Truss />
      </Suspense>
    );
  }, []);

  const commandCenterFloor = useMemo(() => {
    return (
      <Suspense fallback={<Box />}>
        <CommandCenterFloor position={[225, 2537.5, 215]} />
      </Suspense>
    );
  }, []);

  return (
    <>
      {targetBox}
      <group>
        {grid}
        {floor}
        {decals}
        {/* <Suspense fallback={<Box />}>
        <WaterTank position={[200, 3087.5, 0]} />
      </Suspense> */}
        {props.truss && truss}
        {commandCenterFloor}
        {stacks}
        <CoordinateSpace tugs={tugs} towers={towers} />
        {/* <SubtractionTest /> */}
        {/* <Svg
        url={'/svg/fish.svg'}
        color={blue}
        depth={5}
        bevel={false}
        rotation={[0, 0, Math.PI]}
        scale={[5 / 58, 5 / 58, 1]}
      /> */}

        {/* <Svg
        url={'/svg/tug/base.svg'}
        color={blue}
        depth={10}
        bevel={false}
        rotation={[0, 0, 0]}
        position={[0, 0, 10]}
        scale={[1, 1, 1]}
      /> */}
      </group>
    </>
  );
}
