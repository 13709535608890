import React from 'react';
import styled from 'styled-components';

import ClassNames from '../../ClassNames';

export const DataListItems = styled.li`
  pointer-events: auto;
  list-style: none;
  margin: 0;
  padding: 0;
`;
const Item = styled.div`
  pointer-events: auto;
  display: block;
  position: relative;
  // top: calc(var(--treeItemPositionTop) - calc(var(--treeNodeLineWidth)));
  margin-left: calc(var(--treeNodeLineWidth) - var(--treeNodeSingleIndent));
  margin-bottom: var(--treeNodeMinIndent);
`;

export const ListItem = props => {
  return (
    <Item className={ClassNames.labelRegular}>
      <div
        style={{
          display: 'block',
          marginBottom: 'calc(var(--treeNodeMinIndent) * -1)',
          marginLeft: 'calc(var(--treeNodeMinIndent) * -2)',
        }}>
        {props.children}
      </div>
    </Item>
  );
};

export const ListDatum = props => {
  return (
    <Item className={ClassNames.labelRegular}>
      <div
        style={{
          display: 'block',
        }}>
        {props.children}
      </div>
    </Item>
  );
};

const List = styled.ul`
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
  padding-left: var(--treeNodeSingleIndent);
  padding-top: var(--treeTextBoxPositionTop);
`;

const DataList = props => {
  return <List>{props.children}</List>;
};

export default DataList;
