import { CloudProduceAddress } from 'model/src/common/CloudProduceAddress';
import {
  isFishTank,
  isFloorNode,
  isStack,
  isSuperBeacon,
  isTier,
  isTower,
  isTug,
  toAddress
} from 'model/src/common/CloudProduceAddressUtility';
import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';

import {
  DesktopContext,
  DesktopContextType
} from '../../three/farmviewer/context/desktopcontext/DesktopContext';
import UiButton from '../../utopia/desktop/ui-buttons/UiButton';
import UiSchecduleIcon from '../../utopia/desktop/ui-icons/UiScheduleIcon';
import UiTreeIcon from '../../utopia/desktop/ui-icons/UiTreeIcon';
import WindowType from '../../utopia/window/WindowTypes';

export type ContextualToolBarProps = {
  toggleWindow: (windowType: string) => void;
  isOpen: (windowType: string) => boolean;
};

const Bar = styled.div`
  pointer-events: auto;
  position: absolute;
  display: inline-grid;
  column-gap: var(--spacingSmall);
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-template-rows: 1;
  right: var(--spacingExtraLarge);
  bottom: var(--spacingExtraLarge);
  background-color: var(--tabBarBackground_Default);
  border-radius: var(--controlBarBorderRadius);
`;

const ContextualToolBarContainer = props => {
  return <Bar>{props.children}</Bar>;
};

const ContextualToolBar = (props: ContextualToolBarProps) => {
  const desktopContext: DesktopContextType = useContext(DesktopContext);
  const address: CloudProduceAddress = useMemo(() => {
    return toAddress(desktopContext.selectedUrl);
  }, [desktopContext.selectedUrl]);
  return (
    <ContextualToolBarContainer>
      {
        <>
          {/* TODO(austin): customize for farm URL */}
          <UiButton
            onClick={() => props.toggleWindow(WindowType.TREE)}
            selected={props.isOpen(WindowType.TREE)}>
            <UiTreeIcon />
          </UiButton>
          <UiButton
            onClick={() => props.toggleWindow(WindowType.SCHEDULER)}
            selected={props.isOpen(WindowType.SCHEDULER)}>
            <UiSchecduleIcon />
          </UiButton>
        </>
      }
      {/* TODO(austin): implement bar for each URL structure */}
      {isFishTank(address) && <></>}
      {isFloorNode(address) && <></>}
      {isStack(address) && <></>}
      {isSuperBeacon(address) && <></>}
      {isTier(address) && <></>}
      {isTower(address) && <></>}
      {isTug(address) && <></>}
    </ContextualToolBarContainer>
  );
};

export default ContextualToolBar;
