import { cssVar } from '../../..';

const HorizontalLineUrl = (color: string) => {
  return (
    'data:image/svg+xml,' +
    '<svg width="50" height="5" viewBox="0 0 50 5" xmlns="http://www.w3.org/2000/svg">' +
      '<line x1="2" y1="2" x2="50" y2="2" stroke="' + cssVar(color).replace('#', '%23') + '" stroke-width="2" stroke-linecap="round" stroke-dasharray="3 3"/>' +
    '</svg>'
  );
};

export default HorizontalLineUrl;
