import React, { Suspense } from 'react';

import Box from '../common/Box';
import CT290_425S from './CT290_425S';
import GT_CS50 from './GT_CS50';
import Spacer_105 from './Spacer_105';
import Spacer_170 from './Spacer_170';

export default function CommandCenterBeam(props) {
  return (
    <group position={props.position} rotation={props.rotation}>
      <Suspense fallback={<Box />}>
        <GT_CS50
          position={[5, 0, 0]}
          rotation={[0, 0, 0]}
          color={props.color}
          opacity={props.opacity}
          transparent={props.transparent}
        />
      </Suspense>
      <Suspense fallback={<Box />}>
        <Spacer_105
          position={[5, 0, 0]}
          rotation={[0, 0, 0]}
          color={props.color}
          opacity={props.opacity}
          transparent={props.transparent}
        />
      </Suspense>
      <Suspense fallback={<Box />}>
        <CT290_425S
          position={[5 + 10.5 + 250, 0, 0]}
          rotation={[0, 0, 0]}
          color={props.color}
          opacity={props.opacity}
          transparent={props.transparent}
        />
      </Suspense>
      <Suspense fallback={<Box />}>
        <Spacer_170
          position={[5 + 10.5 + 250, 0, 0]}
          rotation={[0, 0, 0]}
          color={props.color}
          opacity={props.opacity}
          transparent={props.transparent}
        />
      </Suspense>
      <Suspense fallback={<Box />}>
        <GT_CS50
          position={[5 + 10.5 + 250 + 17 + 5, 0, 0]}
          rotation={[0, 0, 0]}
          color={props.color}
          opacity={props.opacity}
          transparent={props.transparent}
        />
      </Suspense>
    </group>
  );
}

CommandCenterBeam.defaultProps = {
  position: [0, 0, 0],
  rotation: [0, 0, 0],
};
