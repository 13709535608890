import styled from 'styled-components';

const WindowContainer = styled.div`
  position: absolute;
  pointer-events: all;
  ${props =>
    props.initialWindowPosition.right
      ? 'right: ' + props.initialWindowPosition.right + 'px;'
      : ''}
  ${props =>
    props.initialWindowPosition.top
      ? 'top: ' + props.initialWindowPosition.top + 'px;'
      : ''}
  ${props =>
    props.initialWindowPosition.left
      ? 'left: ' + props.initialWindowPosition.left + 'px;'
      : ''}
  ${props =>
    props.initialWindowPosition.bottom
      ? 'bottom: ' + props.initialWindowPosition.bottom + 'px;'
      : ''}
  background-color: var(--windowBackground_Regular);
  border-radius: var(--windowBorderRadius);
`;

export default WindowContainer;
