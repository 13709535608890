import React from 'react';
import { Point } from 'model/src/common/Point';

export type GridPointOptions = {
  color: string;
  lineWidth: number;
  tickDimension: number;
  location: Point;
};

type Props = {
  options: GridPointOptions;
};

function GridPoint(props: Props) {
  return (
    <>
      <line
        x1={props.options.location.x - props.options.tickDimension}
        y1={props.options.location.y}
        x2={props.options.location.x + props.options.tickDimension}
        y2={props.options.location.y}
        stroke={props.options.color}
        strokeWidth={props.options.lineWidth}
      />
      <line
        x1={props.options.location.x}
        y1={props.options.location.y - props.options.tickDimension}
        x2={props.options.location.x}
        y2={props.options.location.y + props.options.tickDimension}
        stroke={props.options.color}
        strokeWidth={props.options.lineWidth}
      />
    </>
  );
}

export default GridPoint;
