import React, { useState } from 'react';
import styled from 'styled-components';

import { changeDarkMode, isDarkModeEnabled } from '../../../App';
import ClassNames from '../../../ClassNames';
import Toggle from '../../toggle/Toggle';

const TitleLine = styled.div`
  padding-top: var(--spacingLarge);
  padding-left: var(--spacingMedium);
  padding-bottom: var(--spacingMedium);
  display: inline-block;
  width: auto;
`;

const TextLine = styled.div`
  padding-top: var(--spacingLarge);
  padding-left: var(--spacingMedium);
  display: inline-block;
  white-space: nowrap;
`;

const ForceRight = styled.div`
  padding-top: 20px;
  display: inline-block;
  float: right;
  padding-right: 16px;
`;

const GeneralSettingsContent = props => {
  const [checked, setChecked] = useState(isDarkModeEnabled());
  const handleDarkModeChange = () => {
    changeDarkMode(!checked);
    setChecked(!checked);
  };

  return (
    <div>
      <TitleLine className={ClassNames.title3}>General Settings</TitleLine>
      <br />
      <TextLine className={ClassNames.bodyRegular}>Dark Mode:</TextLine>
      <ForceRight>
        <Toggle
          defaultPosition={isDarkModeEnabled()}
          onChange={() => handleDarkModeChange()}
        />
      </ForceRight>
    </div>
  );
};

export default GeneralSettingsContent;
