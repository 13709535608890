"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var LOCALHOST = 'localhost';
var VICTORIA_URL = '192.168.86.23';
var BELMONT_URL = '192.168.1.3';
var REAL_URL = 'farms.cloudproduce.com';
var SERVER_URL = REAL_URL;
exports.default = SERVER_URL;