import React, { useRef, useState } from 'react';
import * as THREE from 'three';

import { cssVar } from '../../..';

export default function Box(props) {
  const mesh = useRef<THREE.Mesh>(null!);
  const [hovered, setHover] = useState(false);
  const [active, setActive] = useState(false);
  // useFrame((state, delta) => (mesh.current.rotation.x += 0.01));
  const material = (
    <meshPhongMaterial
      attach="material"
      color={props.color ? props.color : cssVar('--white')}
      transparent={props.transparent}
      opacity={props.opacity}
    />
  );
  return (
    <mesh
      {...props}
      ref={mesh}
      scale={active ? 1.5 : 1}
      //onClick={event => setActive(!active)}
      onPointerOver={event => setHover(true)}
      onPointerOut={event => setHover(false)}>
      <boxGeometry args={[1, 1, 1]} />
      {material}
    </mesh>
  );
}
