import React from 'react';

import { cssVar } from '../../../..';
import IndicatorLightBox from '../../farm/indicatorLights/IndicatorLightBox';

const UmbilicalValveIndicatorLight = props => {
  const onColor = cssVar('--systemGreen');
  const offColor = cssVar('--systemRed');
  const transitionIndicator = cssVar('--systemBlue');
  return (
    <IndicatorLightBox
      color={
        props.isOn
          ? onColor
          : props.inTransition
          ? transitionIndicator
          : offColor
      }
      position={props.position}
    />
  );
};
export default UmbilicalValveIndicatorLight;
