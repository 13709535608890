import { CloudProduceAddress } from 'model/src/common/CloudProduceAddress';
import { AtomicReading } from 'model/src/dataflowprotocol/AtomicReading';
import { NumericalReading } from 'model/src/series/NumericalReading';
import { useCallback, useContext, useEffect, useState } from 'react';

import InjectionContext from '../../../../injection/InjectionContext';

export const useDataMap = (url: CloudProduceAddress) => {
  const injectionContext = useContext(InjectionContext);

  const [dataMapEntry, setDataMapEntry] = useState<
    AtomicReading<any> & NumericalReading
  >(injectionContext.pageKernel.getPageDataMap().get(url));

  const handleUpdate = useCallback((newValue: any) => {
    setDataMapEntry({ ...newValue });
  }, []);

  useEffect(() => {
    const registration = injectionContext.pageKernel
      .getPageDataMap()
      .addDataUpdateHandler(url, handleUpdate);
    return () => {
      registration.deregister();
    };
  }, []);

  return dataMapEntry;
};
