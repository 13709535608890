/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { Suspense, useRef } from 'react';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';

import { useGLTF } from '@react-three/drei';

import Box from '../common/Box';
import materialFactory from '../trolley/MaterialFactory';
import LiftPlateStaticModel from './LiftPlateStatic.glb';

export default function LiftPlateStatic({ ...props }) {
  const group = useRef();
  const { nodes } = useGLTF(LiftPlateStaticModel) as GLTF;

  const material: any = materialFactory.generateMaterial(
    props.parentUnderInspection,
    props.parentHovered,
    undefined,
    undefined,
    props.online
  );

  return (
    <Suspense fallback={<Box />}>
      <group ref={group} {...props} dispose={null}>
        <group name="LiftPlateStatic_v3" position={[0, 0, 4]}>
          <group
            name="Slider"
            position={[0, 0, 0.63]}
            rotation={[0, 0, -Math.PI / 2]}>
            <group name="HGRW15_v1" position={[-11.08, 35.23, -8.96]}>
              <group name="HGW15CC" position={[-3.92, 0.2, 26.55]}>
                <group name="HGW15CC2" position={[0, 0, 3.94]}>
                  <mesh name="mesh_0" geometry={nodes.mesh_0.geometry}>
                    {material}
                  </mesh>
                </group>
                <group name="HGW15CC2_1" rotation={[Math.PI, 0, Math.PI]}>
                  <mesh name="mesh_1" geometry={nodes.mesh_1.geometry}>
                    {material}
                  </mesh>
                </group>
                <group
                  name="HGW15CC3"
                  position={[0, 0, -0.8]}
                  rotation={[Math.PI, 0, Math.PI]}>
                  <mesh name="mesh_2" geometry={nodes.mesh_2.geometry}>
                    {material}
                  </mesh>
                </group>
                <group name="HGW15CC3_1" position={[0, 0, 4.74]}>
                  <mesh name="mesh_3" geometry={nodes.mesh_3.geometry}>
                    {material}
                  </mesh>
                </group>
                <mesh name="mesh_4" geometry={nodes.mesh_4.geometry}>
                  {material}
                </mesh>
              </group>
            </group>
            <group
              name="91294A210_Black-Oxide_Alloy_Steel_Hex_Drive_Flat_Head_Screw_v1"
              position={[-16.9, 38.03, 18.06]}
              rotation={[-Math.PI / 2, 0, 0]}>
              <mesh name="mesh_5" geometry={nodes.mesh_5.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="91294A210_Black-Oxide_Alloy_Steel_Hex_Drive_Flat_Head_Screw_v1_1"
              position={[-13.1, 38.03, 18.06]}
              rotation={[-Math.PI / 2, 0, 0]}>
              <mesh name="mesh_6" geometry={nodes.mesh_6.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="91294A210_Black-Oxide_Alloy_Steel_Hex_Drive_Flat_Head_Screw_v1_2"
              position={[-16.9, 38.03, 21.06]}
              rotation={[-Math.PI / 2, 0, 0]}>
              <mesh name="mesh_7" geometry={nodes.mesh_7.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="91294A210_Black-Oxide_Alloy_Steel_Hex_Drive_Flat_Head_Screw_v1_3"
              position={[-13.1, 38.03, 21.06]}
              rotation={[-Math.PI / 2, 0, 0]}>
              <mesh name="mesh_8" geometry={nodes.mesh_8.geometry}>
                {material}
              </mesh>
            </group>
            <mesh name="mesh_9" geometry={nodes.mesh_9.geometry}>
              {material}
            </mesh>
            <mesh name="mesh_10" geometry={nodes.mesh_10.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="Plate_Piston"
            position={[29.8, 30, -3.91]}
            rotation={[0, 0, Math.PI / 2]}>
            <group
              name="FA-OS-400-12-3_v1"
              position={[0.01, 5.94, 7.2]}
              rotation={[0, 0, -Math.PI / 2]}>
              <mesh name="mesh_11" geometry={nodes.mesh_11.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="MB1-P_v3"
              position={[-1.7, 3.1, 0]}
              rotation={[0, 0, -Math.PI / 2]}>
              <group name="Pin" position={[0, 5.18, 0]}>
                <mesh name="mesh_12" geometry={nodes.mesh_12.geometry}>
                  {material}
                </mesh>
              </group>
              <mesh name="mesh_13" geometry={nodes.mesh_13.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="90358A027_Ultra-Low-Profile_Socket_Head_Screw_v1"
              position={[0, 0, -0.9]}
              rotation={[0, 0, Math.PI / 2]}>
              <mesh name="mesh_14" geometry={nodes.mesh_14.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="90358A027_Ultra-Low-Profile_Socket_Head_Screw_v1_1"
              position={[0, 2.1, -0.9]}
              rotation={[0, 0, Math.PI / 2]}>
              <mesh name="mesh_15" geometry={nodes.mesh_15.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="97131A140_Medium-Strength_Nylon-Insert_Locknut_v2"
              position={[0, 0, 0.56]}
              rotation={[Math.PI / 2, -Math.PI / 2, 0]}>
              <mesh name="mesh_16" geometry={nodes.mesh_16.geometry}>
                {material}
              </mesh>
              <mesh name="mesh_17" geometry={nodes.mesh_17.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="97131A140_Medium-Strength_Nylon-Insert_Locknut_v2_1"
              position={[0, 2.1, 0.56]}
              rotation={[Math.PI / 2, -Math.PI / 2, 0]}>
              <mesh name="mesh_18" geometry={nodes.mesh_18.geometry}>
                {material}
              </mesh>
              <mesh name="mesh_19" geometry={nodes.mesh_19.geometry}>
                {material}
              </mesh>
            </group>
          </group>
          <group name="Slider_1" position={[0, 0, 0.63]}>
            <group name="HGRW15_v1_1" position={[-11.08, 35.23, -8.96]}>
              <group name="HGW15CC_1" position={[-3.92, 0.2, 26.55]}>
                <group name="HGW15CC2_2" position={[0, 0, 3.94]}>
                  <mesh name="mesh_20" geometry={nodes.mesh_20.geometry}>
                    {material}
                  </mesh>
                </group>
                <group name="HGW15CC2_3" rotation={[Math.PI, 0, Math.PI]}>
                  <mesh name="mesh_21" geometry={nodes.mesh_21.geometry}>
                    {material}
                  </mesh>
                </group>
                <group
                  name="HGW15CC3_2"
                  position={[0, 0, -0.8]}
                  rotation={[Math.PI, 0, Math.PI]}>
                  <mesh name="mesh_22" geometry={nodes.mesh_22.geometry}>
                    {material}
                  </mesh>
                </group>
                <group name="HGW15CC3_3" position={[0, 0, 4.74]}>
                  <mesh name="mesh_23" geometry={nodes.mesh_23.geometry}>
                    {material}
                  </mesh>
                </group>
                <mesh name="mesh_24" geometry={nodes.mesh_24.geometry}>
                  {material}
                </mesh>
              </group>
            </group>
            <group
              name="91294A210_Black-Oxide_Alloy_Steel_Hex_Drive_Flat_Head_Screw_v1_4"
              position={[-16.9, 38.03, 18.06]}
              rotation={[-Math.PI / 2, 0, 0]}>
              <mesh name="mesh_25" geometry={nodes.mesh_25.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="91294A210_Black-Oxide_Alloy_Steel_Hex_Drive_Flat_Head_Screw_v1_5"
              position={[-13.1, 38.03, 18.06]}
              rotation={[-Math.PI / 2, 0, 0]}>
              <mesh name="mesh_26" geometry={nodes.mesh_26.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="91294A210_Black-Oxide_Alloy_Steel_Hex_Drive_Flat_Head_Screw_v1_6"
              position={[-16.9, 38.03, 21.06]}
              rotation={[-Math.PI / 2, 0, 0]}>
              <mesh name="mesh_27" geometry={nodes.mesh_27.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="91294A210_Black-Oxide_Alloy_Steel_Hex_Drive_Flat_Head_Screw_v1_7"
              position={[-13.1, 38.03, 21.06]}
              rotation={[-Math.PI / 2, 0, 0]}>
              <mesh name="mesh_28" geometry={nodes.mesh_28.geometry}>
                {material}
              </mesh>
            </group>
            <mesh name="mesh_29" geometry={nodes.mesh_29.geometry}>
              {material}
            </mesh>
            <mesh name="mesh_30" geometry={nodes.mesh_30.geometry}>
              {material}
            </mesh>
          </group>
          <group name="Slider_2" position={[30, 0, 0.63]}>
            <group name="HGRW15_v1_2" position={[-11.08, 35.23, -8.96]}>
              <group name="HGW15CC_2" position={[-3.92, 0.2, 26.55]}>
                <group name="HGW15CC2_4" position={[0, 0, 3.94]}>
                  <mesh name="mesh_31" geometry={nodes.mesh_31.geometry}>
                    {material}
                  </mesh>
                </group>
                <group name="HGW15CC2_5" rotation={[Math.PI, 0, Math.PI]}>
                  <mesh name="mesh_32" geometry={nodes.mesh_32.geometry}>
                    {material}
                  </mesh>
                </group>
                <group
                  name="HGW15CC3_4"
                  position={[0, 0, -0.8]}
                  rotation={[Math.PI, 0, Math.PI]}>
                  <mesh name="mesh_33" geometry={nodes.mesh_33.geometry}>
                    {material}
                  </mesh>
                </group>
                <group name="HGW15CC3_5" position={[0, 0, 4.74]}>
                  <mesh name="mesh_34" geometry={nodes.mesh_34.geometry}>
                    {material}
                  </mesh>
                </group>
                <mesh name="mesh_35" geometry={nodes.mesh_35.geometry}>
                  {material}
                </mesh>
              </group>
            </group>
            <group
              name="91294A210_Black-Oxide_Alloy_Steel_Hex_Drive_Flat_Head_Screw_v1_8"
              position={[-16.9, 38.03, 18.06]}
              rotation={[-Math.PI / 2, 0, 0]}>
              <mesh name="mesh_36" geometry={nodes.mesh_36.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="91294A210_Black-Oxide_Alloy_Steel_Hex_Drive_Flat_Head_Screw_v1_9"
              position={[-13.1, 38.03, 18.06]}
              rotation={[-Math.PI / 2, 0, 0]}>
              <mesh name="mesh_37" geometry={nodes.mesh_37.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="91294A210_Black-Oxide_Alloy_Steel_Hex_Drive_Flat_Head_Screw_v1_10"
              position={[-16.9, 38.03, 21.06]}
              rotation={[-Math.PI / 2, 0, 0]}>
              <mesh name="mesh_38" geometry={nodes.mesh_38.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="91294A210_Black-Oxide_Alloy_Steel_Hex_Drive_Flat_Head_Screw_v1_11"
              position={[-13.1, 38.03, 21.06]}
              rotation={[-Math.PI / 2, 0, 0]}>
              <mesh name="mesh_39" geometry={nodes.mesh_39.geometry}>
                {material}
              </mesh>
            </group>
            <mesh name="mesh_40" geometry={nodes.mesh_40.geometry}>
              {material}
            </mesh>
            <mesh name="mesh_41" geometry={nodes.mesh_41.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="Slider_3"
            position={[0, 30, 0.63]}
            rotation={[0, 0, Math.PI / 2]}>
            <group name="HGRW15_v1_3" position={[-11.08, 35.23, -8.96]}>
              <group name="HGW15CC_3" position={[-3.92, 0.2, 26.55]}>
                <group name="HGW15CC2_6" position={[0, 0, 3.94]}>
                  <mesh name="mesh_42" geometry={nodes.mesh_42.geometry}>
                    {material}
                  </mesh>
                </group>
                <group name="HGW15CC2_7" rotation={[Math.PI, 0, Math.PI]}>
                  <mesh name="mesh_43" geometry={nodes.mesh_43.geometry}>
                    {material}
                  </mesh>
                </group>
                <group
                  name="HGW15CC3_6"
                  position={[0, 0, -0.8]}
                  rotation={[Math.PI, 0, Math.PI]}>
                  <mesh name="mesh_44" geometry={nodes.mesh_44.geometry}>
                    {material}
                  </mesh>
                </group>
                <group name="HGW15CC3_7" position={[0, 0, 4.74]}>
                  <mesh name="mesh_45" geometry={nodes.mesh_45.geometry}>
                    {material}
                  </mesh>
                </group>
                <mesh name="mesh_46" geometry={nodes.mesh_46.geometry}>
                  {material}
                </mesh>
              </group>
            </group>
            <group
              name="91294A210_Black-Oxide_Alloy_Steel_Hex_Drive_Flat_Head_Screw_v1_12"
              position={[-16.9, 38.03, 18.06]}
              rotation={[-Math.PI / 2, 0, 0]}>
              <mesh name="mesh_47" geometry={nodes.mesh_47.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="91294A210_Black-Oxide_Alloy_Steel_Hex_Drive_Flat_Head_Screw_v1_13"
              position={[-13.1, 38.03, 18.06]}
              rotation={[-Math.PI / 2, 0, 0]}>
              <mesh name="mesh_48" geometry={nodes.mesh_48.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="91294A210_Black-Oxide_Alloy_Steel_Hex_Drive_Flat_Head_Screw_v1_14"
              position={[-16.9, 38.03, 21.06]}
              rotation={[-Math.PI / 2, 0, 0]}>
              <mesh name="mesh_49" geometry={nodes.mesh_49.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="91294A210_Black-Oxide_Alloy_Steel_Hex_Drive_Flat_Head_Screw_v1_15"
              position={[-13.1, 38.03, 21.06]}
              rotation={[-Math.PI / 2, 0, 0]}>
              <mesh name="mesh_50" geometry={nodes.mesh_50.geometry}>
                {material}
              </mesh>
            </group>
            <mesh name="mesh_51" geometry={nodes.mesh_51.geometry}>
              {material}
            </mesh>
            <mesh name="mesh_52" geometry={nodes.mesh_52.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="Slider_4"
            position={[0, 0, 0.63]}
            rotation={[0, 0, Math.PI / 2]}>
            <group name="HGRW15_v1_4" position={[-11.08, 35.23, -8.96]}>
              <group name="HGW15CC_4" position={[-3.92, 0.2, 26.55]}>
                <group name="HGW15CC2_8" position={[0, 0, 3.94]}>
                  <mesh name="mesh_53" geometry={nodes.mesh_53.geometry}>
                    {material}
                  </mesh>
                </group>
                <group name="HGW15CC2_9" rotation={[Math.PI, 0, Math.PI]}>
                  <mesh name="mesh_54" geometry={nodes.mesh_54.geometry}>
                    {material}
                  </mesh>
                </group>
                <group
                  name="HGW15CC3_8"
                  position={[0, 0, -0.8]}
                  rotation={[Math.PI, 0, Math.PI]}>
                  <mesh name="mesh_55" geometry={nodes.mesh_55.geometry}>
                    {material}
                  </mesh>
                </group>
                <group name="HGW15CC3_9" position={[0, 0, 4.74]}>
                  <mesh name="mesh_56" geometry={nodes.mesh_56.geometry}>
                    {material}
                  </mesh>
                </group>
                <mesh name="mesh_57" geometry={nodes.mesh_57.geometry}>
                  {material}
                </mesh>
              </group>
            </group>
            <group
              name="91294A210_Black-Oxide_Alloy_Steel_Hex_Drive_Flat_Head_Screw_v1_16"
              position={[-16.9, 38.03, 18.06]}
              rotation={[-Math.PI / 2, 0, 0]}>
              <mesh name="mesh_58" geometry={nodes.mesh_58.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="91294A210_Black-Oxide_Alloy_Steel_Hex_Drive_Flat_Head_Screw_v1_17"
              position={[-13.1, 38.03, 18.06]}
              rotation={[-Math.PI / 2, 0, 0]}>
              <mesh name="mesh_59" geometry={nodes.mesh_59.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="91294A210_Black-Oxide_Alloy_Steel_Hex_Drive_Flat_Head_Screw_v1_18"
              position={[-16.9, 38.03, 21.06]}
              rotation={[-Math.PI / 2, 0, 0]}>
              <mesh name="mesh_60" geometry={nodes.mesh_60.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="91294A210_Black-Oxide_Alloy_Steel_Hex_Drive_Flat_Head_Screw_v1_19"
              position={[-13.1, 38.03, 21.06]}
              rotation={[-Math.PI / 2, 0, 0]}>
              <mesh name="mesh_61" geometry={nodes.mesh_61.geometry}>
                {material}
              </mesh>
            </group>
            <mesh name="mesh_62" geometry={nodes.mesh_62.geometry}>
              {material}
            </mesh>
            <mesh name="mesh_63" geometry={nodes.mesh_63.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="Slider_5"
            position={[-30, 0, 0.63]}
            rotation={[0, 0, Math.PI]}>
            <group name="HGRW15_v1_5" position={[-11.08, 35.23, -8.96]}>
              <group name="HGW15CC_5" position={[-3.92, 0.2, 26.55]}>
                <group name="HGW15CC2_10" position={[0, 0, 3.94]}>
                  <mesh name="mesh_64" geometry={nodes.mesh_64.geometry}>
                    {material}
                  </mesh>
                </group>
                <group name="HGW15CC2_11" rotation={[Math.PI, 0, Math.PI]}>
                  <mesh name="mesh_65" geometry={nodes.mesh_65.geometry}>
                    {material}
                  </mesh>
                </group>
                <group
                  name="HGW15CC3_10"
                  position={[0, 0, -0.8]}
                  rotation={[Math.PI, 0, Math.PI]}>
                  <mesh name="mesh_66" geometry={nodes.mesh_66.geometry}>
                    {material}
                  </mesh>
                </group>
                <group name="HGW15CC3_11" position={[0, 0, 4.74]}>
                  <mesh name="mesh_67" geometry={nodes.mesh_67.geometry}>
                    {material}
                  </mesh>
                </group>
                <mesh name="mesh_68" geometry={nodes.mesh_68.geometry}>
                  {material}
                </mesh>
              </group>
            </group>
            <group
              name="91294A210_Black-Oxide_Alloy_Steel_Hex_Drive_Flat_Head_Screw_v1_20"
              position={[-16.9, 38.03, 18.06]}
              rotation={[-Math.PI / 2, 0, 0]}>
              <mesh name="mesh_69" geometry={nodes.mesh_69.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="91294A210_Black-Oxide_Alloy_Steel_Hex_Drive_Flat_Head_Screw_v1_21"
              position={[-13.1, 38.03, 18.06]}
              rotation={[-Math.PI / 2, 0, 0]}>
              <mesh name="mesh_70" geometry={nodes.mesh_70.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="91294A210_Black-Oxide_Alloy_Steel_Hex_Drive_Flat_Head_Screw_v1_22"
              position={[-16.9, 38.03, 21.06]}
              rotation={[-Math.PI / 2, 0, 0]}>
              <mesh name="mesh_71" geometry={nodes.mesh_71.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="91294A210_Black-Oxide_Alloy_Steel_Hex_Drive_Flat_Head_Screw_v1_23"
              position={[-13.1, 38.03, 21.06]}
              rotation={[-Math.PI / 2, 0, 0]}>
              <mesh name="mesh_72" geometry={nodes.mesh_72.geometry}>
                {material}
              </mesh>
            </group>
            <mesh name="mesh_73" geometry={nodes.mesh_73.geometry}>
              {material}
            </mesh>
            <mesh name="mesh_74" geometry={nodes.mesh_74.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="Slider_6"
            position={[0, 0, 0.63]}
            rotation={[0, 0, Math.PI]}>
            <group name="HGRW15_v1_6" position={[-11.08, 35.23, -8.96]}>
              <group name="HGW15CC_6" position={[-3.92, 0.2, 26.55]}>
                <group name="HGW15CC2_12" position={[0, 0, 3.94]}>
                  <mesh name="mesh_75" geometry={nodes.mesh_75.geometry}>
                    {material}
                  </mesh>
                </group>
                <group name="HGW15CC2_13" rotation={[Math.PI, 0, Math.PI]}>
                  <mesh name="mesh_76" geometry={nodes.mesh_76.geometry}>
                    {material}
                  </mesh>
                </group>
                <group
                  name="HGW15CC3_12"
                  position={[0, 0, -0.8]}
                  rotation={[Math.PI, 0, Math.PI]}>
                  <mesh name="mesh_77" geometry={nodes.mesh_77.geometry}>
                    {material}
                  </mesh>
                </group>
                <group name="HGW15CC3_13" position={[0, 0, 4.74]}>
                  <mesh name="mesh_78" geometry={nodes.mesh_78.geometry}>
                    {material}
                  </mesh>
                </group>
                <mesh name="mesh_79" geometry={nodes.mesh_79.geometry}>
                  {material}
                </mesh>
              </group>
            </group>
            <group
              name="91294A210_Black-Oxide_Alloy_Steel_Hex_Drive_Flat_Head_Screw_v1_24"
              position={[-16.9, 38.03, 18.06]}
              rotation={[-Math.PI / 2, 0, 0]}>
              <mesh name="mesh_80" geometry={nodes.mesh_80.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="91294A210_Black-Oxide_Alloy_Steel_Hex_Drive_Flat_Head_Screw_v1_25"
              position={[-13.1, 38.03, 18.06]}
              rotation={[-Math.PI / 2, 0, 0]}>
              <mesh name="mesh_81" geometry={nodes.mesh_81.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="91294A210_Black-Oxide_Alloy_Steel_Hex_Drive_Flat_Head_Screw_v1_26"
              position={[-16.9, 38.03, 21.06]}
              rotation={[-Math.PI / 2, 0, 0]}>
              <mesh name="mesh_82" geometry={nodes.mesh_82.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="91294A210_Black-Oxide_Alloy_Steel_Hex_Drive_Flat_Head_Screw_v1_27"
              position={[-13.1, 38.03, 21.06]}
              rotation={[-Math.PI / 2, 0, 0]}>
              <mesh name="mesh_83" geometry={nodes.mesh_83.geometry}>
                {material}
              </mesh>
            </group>
            <mesh name="mesh_84" geometry={nodes.mesh_84.geometry}>
              {material}
            </mesh>
            <mesh name="mesh_85" geometry={nodes.mesh_85.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="Slider_7"
            position={[0, -30, 0.63]}
            rotation={[0, 0, -Math.PI / 2]}>
            <group name="HGRW15_v1_7" position={[-11.08, 35.23, -8.96]}>
              <group name="HGW15CC_7" position={[-3.92, 0.2, 26.55]}>
                <group name="HGW15CC2_14" position={[0, 0, 3.94]}>
                  <mesh name="mesh_86" geometry={nodes.mesh_86.geometry}>
                    {material}
                  </mesh>
                </group>
                <group name="HGW15CC2_15" rotation={[Math.PI, 0, Math.PI]}>
                  <mesh name="mesh_87" geometry={nodes.mesh_87.geometry}>
                    {material}
                  </mesh>
                </group>
                <group
                  name="HGW15CC3_14"
                  position={[0, 0, -0.8]}
                  rotation={[Math.PI, 0, Math.PI]}>
                  <mesh name="mesh_88" geometry={nodes.mesh_88.geometry}>
                    {material}
                  </mesh>
                </group>
                <group name="HGW15CC3_15" position={[0, 0, 4.74]}>
                  <mesh name="mesh_89" geometry={nodes.mesh_89.geometry}>
                    {material}
                  </mesh>
                </group>
                <mesh name="mesh_90" geometry={nodes.mesh_90.geometry}>
                  {material}
                </mesh>
              </group>
            </group>
            <group
              name="91294A210_Black-Oxide_Alloy_Steel_Hex_Drive_Flat_Head_Screw_v1_28"
              position={[-16.9, 38.03, 18.06]}
              rotation={[-Math.PI / 2, 0, 0]}>
              <mesh name="mesh_91" geometry={nodes.mesh_91.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="91294A210_Black-Oxide_Alloy_Steel_Hex_Drive_Flat_Head_Screw_v1_29"
              position={[-13.1, 38.03, 18.06]}
              rotation={[-Math.PI / 2, 0, 0]}>
              <mesh name="mesh_92" geometry={nodes.mesh_92.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="91294A210_Black-Oxide_Alloy_Steel_Hex_Drive_Flat_Head_Screw_v1_30"
              position={[-16.9, 38.03, 21.06]}
              rotation={[-Math.PI / 2, 0, 0]}>
              <mesh name="mesh_93" geometry={nodes.mesh_93.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="91294A210_Black-Oxide_Alloy_Steel_Hex_Drive_Flat_Head_Screw_v1_31"
              position={[-13.1, 38.03, 21.06]}
              rotation={[-Math.PI / 2, 0, 0]}>
              <mesh name="mesh_94" geometry={nodes.mesh_94.geometry}>
                {material}
              </mesh>
            </group>
            <mesh name="mesh_95" geometry={nodes.mesh_95.geometry}>
              {material}
            </mesh>
            <mesh name="mesh_96" geometry={nodes.mesh_96.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="Plate_Piston_1"
            position={[-30, -30, -3.91]}
            rotation={[0, 0, -Math.PI / 2]}>
            <group
              name="FA-OS-400-12-3_v1_1"
              position={[0.01, 5.94, 7.2]}
              rotation={[0, 0, -Math.PI / 2]}>
              <mesh name="mesh_97" geometry={nodes.mesh_97.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="MB1-P_v3_1"
              position={[-1.7, 3.1, 0]}
              rotation={[0, 0, -Math.PI / 2]}>
              <group name="Pin_1" position={[0, 5.18, 0]}>
                <mesh name="mesh_98" geometry={nodes.mesh_98.geometry}>
                  {material}
                </mesh>
              </group>
              <mesh name="mesh_99" geometry={nodes.mesh_99.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="90358A027_Ultra-Low-Profile_Socket_Head_Screw_v1_2"
              position={[0, 0, -0.9]}
              rotation={[0, 0, Math.PI / 2]}>
              <mesh name="mesh_100" geometry={nodes.mesh_100.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="90358A027_Ultra-Low-Profile_Socket_Head_Screw_v1_3"
              position={[0, 2.1, -0.9]}
              rotation={[0, 0, Math.PI / 2]}>
              <mesh name="mesh_101" geometry={nodes.mesh_101.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="97131A140_Medium-Strength_Nylon-Insert_Locknut_v2_2"
              position={[0, 0, 0.56]}
              rotation={[Math.PI / 2, -Math.PI / 2, 0]}>
              <mesh name="mesh_102" geometry={nodes.mesh_102.geometry}>
                {material}
              </mesh>
              <mesh name="mesh_103" geometry={nodes.mesh_103.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="97131A140_Medium-Strength_Nylon-Insert_Locknut_v2_3"
              position={[0, 2.1, 0.56]}
              rotation={[Math.PI / 2, -Math.PI / 2, 0]}>
              <mesh name="mesh_104" geometry={nodes.mesh_104.geometry}>
                {material}
              </mesh>
              <mesh name="mesh_105" geometry={nodes.mesh_105.geometry}>
                {material}
              </mesh>
            </group>
          </group>
          <group
            name="Plate_Piston_2"
            position={[-30, 30, -3.91]}
            rotation={[0, 0, -Math.PI / 2]}>
            <group
              name="FA-OS-400-12-3_v1_2"
              position={[0.01, 5.94, 7.2]}
              rotation={[0, 0, -Math.PI / 2]}>
              <mesh name="mesh_106" geometry={nodes.mesh_106.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="MB1-P_v3_2"
              position={[-1.7, 3.1, 0]}
              rotation={[0, 0, -Math.PI / 2]}>
              <group name="Pin_2" position={[0, 5.18, 0]}>
                <mesh name="mesh_107" geometry={nodes.mesh_107.geometry}>
                  {material}
                </mesh>
              </group>
              <mesh name="mesh_108" geometry={nodes.mesh_108.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="90358A027_Ultra-Low-Profile_Socket_Head_Screw_v1_4"
              position={[0, 0, -0.9]}
              rotation={[0, 0, Math.PI / 2]}>
              <mesh name="mesh_109" geometry={nodes.mesh_109.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="90358A027_Ultra-Low-Profile_Socket_Head_Screw_v1_5"
              position={[0, 2.1, -0.9]}
              rotation={[0, 0, Math.PI / 2]}>
              <mesh name="mesh_110" geometry={nodes.mesh_110.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="97131A140_Medium-Strength_Nylon-Insert_Locknut_v2_4"
              position={[0, 0, 0.56]}
              rotation={[Math.PI / 2, -Math.PI / 2, 0]}>
              <mesh name="mesh_111" geometry={nodes.mesh_111.geometry}>
                {material}
              </mesh>
              <mesh name="mesh_112" geometry={nodes.mesh_112.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="97131A140_Medium-Strength_Nylon-Insert_Locknut_v2_5"
              position={[0, 2.1, 0.56]}
              rotation={[Math.PI / 2, -Math.PI / 2, 0]}>
              <mesh name="mesh_113" geometry={nodes.mesh_113.geometry}>
                {material}
              </mesh>
              <mesh name="mesh_114" geometry={nodes.mesh_114.geometry}>
                {material}
              </mesh>
            </group>
          </group>
          <group
            name="Plate_Piston_3"
            position={[29.8, -30, -3.91]}
            rotation={[0, 0, Math.PI / 2]}>
            <group
              name="FA-OS-400-12-3_v1_3"
              position={[0.01, 5.94, 7.2]}
              rotation={[0, 0, -Math.PI / 2]}>
              <mesh name="mesh_115" geometry={nodes.mesh_115.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="MB1-P_v3_3"
              position={[-1.7, 3.1, 0]}
              rotation={[0, 0, -Math.PI / 2]}>
              <group name="Pin_3" position={[0, 5.18, 0]}>
                <mesh name="mesh_116" geometry={nodes.mesh_116.geometry}>
                  {material}
                </mesh>
              </group>
              <mesh name="mesh_117" geometry={nodes.mesh_117.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="90358A027_Ultra-Low-Profile_Socket_Head_Screw_v1_6"
              position={[0, 0, -0.9]}
              rotation={[0, 0, Math.PI / 2]}>
              <mesh name="mesh_118" geometry={nodes.mesh_118.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="90358A027_Ultra-Low-Profile_Socket_Head_Screw_v1_7"
              position={[0, 2.1, -0.9]}
              rotation={[0, 0, Math.PI / 2]}>
              <mesh name="mesh_119" geometry={nodes.mesh_119.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="97131A140_Medium-Strength_Nylon-Insert_Locknut_v2_6"
              position={[0, 0, 0.56]}
              rotation={[Math.PI / 2, -Math.PI / 2, 0]}>
              <mesh name="mesh_120" geometry={nodes.mesh_120.geometry}>
                {material}
              </mesh>
              <mesh name="mesh_121" geometry={nodes.mesh_121.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="97131A140_Medium-Strength_Nylon-Insert_Locknut_v2_7"
              position={[0, 2.1, 0.56]}
              rotation={[Math.PI / 2, -Math.PI / 2, 0]}>
              <mesh name="mesh_122" geometry={nodes.mesh_122.geometry}>
                {material}
              </mesh>
              <mesh name="mesh_123" geometry={nodes.mesh_123.geometry}>
                {material}
              </mesh>
            </group>
          </group>
        </group>
      </group>
    </Suspense>
  );
}

useGLTF.preload(LiftPlateStaticModel);
