import React from 'react';
import SvgPathNode from './SvgPathNode';
import { FARM_LINE_WIDTH_MM } from 'model/src/farm/entities/trolley/physical/PhysicalSize';
import { toRgbaString } from 'model/src/common/Color';
import { THEME } from '../../../globe/Theme';

type Props = {
  path: { x: number; y: number }[];
};
const SvgPath = (props: Props) => {
  const edges: any[] = [];
  for (let i = 1; i < props.path.length; i++) {
    const source = props.path[i - 1];
    const target = props.path[i];
    edges.push(
      <line
        key={
          'edge_' + source.x + '_' + source.y + '_' + target.x + '_' + target.y
        }
        x1={source.x}
        y1={source.y}
        x2={target.x}
        y2={target.y}
        stroke={toRgbaString(THEME.blues[0])}
        strokeWidth={2 * FARM_LINE_WIDTH_MM}
      />
    );
  }
  return (
    <>
      {edges}
      {props.path.map(coordinates => {
        return (
          <SvgPathNode
            key={'path_node_' + coordinates.x + '_' + coordinates.y}
            coordinates={coordinates}
          />
        );
      })}
    </>
  );
};
export default SvgPath;
