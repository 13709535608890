"use strict";

var _toConsumableArray = require("/usr/src/app/packages/commandcenter/node_modules/@babel/runtime/helpers/toConsumableArray");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deviceAddress = exports.isDriveTrain = exports.isFloorNode = exports.isUmbilical = exports.isTug = exports.fromTug = exports.isTower = exports.isTier = exports.isSump = exports.isSuperBeacon = exports.isStack = exports.isFishTank = exports.isRoot = exports.towerId = exports.tugId = exports.farmId = exports.split = exports.equals = exports.toAddress = exports.size = exports.fullString = exports.asString = exports.includesInOrder = exports.includes = exports.getSegment = exports.isDegenerate = exports.getAt = exports.last = exports.cutTo = exports.combine = exports.join = exports.create = exports.duplicate = void 0;

var Typescript_1 = require("model/src/typescript/Typescript");

var CloudProduceAddress_1 = require("./CloudProduceAddress");

var Systems_1 = require("./Systems");

function duplicate(address) {
  return create(address.segments);
}

exports.duplicate = duplicate;

function create(segments) {
  return new CloudProduceAddress_1.CloudProduceAddress(_toConsumableArray(segments));
}

exports.create = create;

function join(baseAddress, suffix) {
  return new CloudProduceAddress_1.CloudProduceAddress([].concat(_toConsumableArray(baseAddress.segments), [suffix]));
}

exports.join = join;

function combine(baseAddress, suffix) {
  return new CloudProduceAddress_1.CloudProduceAddress([].concat(_toConsumableArray(baseAddress.segments), _toConsumableArray(suffix.segments)));
}

exports.combine = combine;

function cutTo(address, i) {
  return new CloudProduceAddress_1.CloudProduceAddress(address.segments.slice(0, i));
}

exports.cutTo = cutTo;

function last(address) {
  return address.segments[address.segments.length - 1];
}

exports.last = last;

function getAt(address, index) {
  if (index < 0) {
    return address.segments[address.segments.length + index];
  } else {
    return address.segments[index];
  }
}

exports.getAt = getAt;

function isDegenerate(address) {
  return address.segments.length == 0;
}

exports.isDegenerate = isDegenerate;

function getSegment(address, i) {
  return address.segments[i];
}

exports.getSegment = getSegment;

function includes(address, inclusion) {
  for (var i = 0; i < address.segments.length; i++) {
    var segment = address.segments[i];

    if (segment == inclusion) {
      return true;
    }
  }

  return false;
}

exports.includes = includes;

function includesInOrder(address, inclusions) {
  var i = 0;
  var returnValue = false;

  if (inclusions.length === 0) {
    return true;
  }

  address.segments.forEach(function (segment) {
    if (segment === inclusions[i]) {
      i++;
    }

    if (i === inclusions.length) {
      returnValue = true;
    }
  });
  return returnValue;
}

exports.includesInOrder = includesInOrder;

function asString(toSegment, address) {
  return address.segments.slice(0, toSegment + 1).join('.');
}

exports.asString = asString;

function fullString(address) {
  return address.segments.slice(0, size(address)).join('.');
}

exports.fullString = fullString;

function size(address) {
  return address.segments.length;
}

exports.size = size;

function toAddress(address) {
  return new CloudProduceAddress_1.CloudProduceAddress(address.split('.'));
}

exports.toAddress = toAddress;

function equals(address1, address2) {
  return fullString(address1) === fullString(address2);
}

exports.equals = equals;

function split(rawAddress) {
  return rawAddress.split('.');
}

exports.split = split;

function farmId(rawAddress) {
  return split(rawAddress)[0];
}

exports.farmId = farmId;

function tugId(address) {
  return cutTo(address, 3);
}

exports.tugId = tugId;

function towerId(address) {
  return cutTo(address, 3);
}

exports.towerId = towerId;

function isRoot(address) {
  if (typeof address === Typescript_1.BuiltinType.STRING) {
    return /^\d+$/.test(address);
  } else {
    return address.segments.length === 1;
  }
}

exports.isRoot = isRoot;

function isFishTank(address) {
  return address.segments.length === 5 && address.segments[3] === Systems_1.StackSubsystem.FISH_TANK;
}

exports.isFishTank = isFishTank;

function isStack(address) {
  return address.segments.length === 3 && address.segments[1] === Systems_1.FarmSubsystem.STACK;
}

exports.isStack = isStack;

function isSuperBeacon(address) {
  return address.segments.length === 3 && address.segments[1] === Systems_1.FarmSubsystem.SUPER_BEACON;
}

exports.isSuperBeacon = isSuperBeacon;

function isSump(address) {
  return address.segments.length === 5 && address.segments[4] === Systems_1.TowerSubsystem.SUMP;
}

exports.isSump = isSump;

function isTier(address) {
  return address.segments.length === 5 && address.segments[3] === Systems_1.TowerSubsystem.TIER;
}

exports.isTier = isTier;

function isTower(address) {
  return address.segments.length === 3 && address.segments[1] === Systems_1.FarmSubsystem.TOWER;
}

exports.isTower = isTower;

function fromTug(address) {
  return address.segments[1] === Systems_1.FarmSubsystem.TUG;
}

exports.fromTug = fromTug;

function isTug(address) {
  return address.segments.length === 3 && address.segments[1] === Systems_1.FarmSubsystem.TUG;
}

exports.isTug = isTug;

function isUmbilical(address) {
  return address.segments.length === 4 && address.segments[3] === Systems_1.TowerSubsystem.UMBILICAL;
}

exports.isUmbilical = isUmbilical;

function isFloorNode(address) {
  return address.segments[1] === Systems_1.FarmSubsystem.FLOOR && address.segments.length === 4;
}

exports.isFloorNode = isFloorNode;

function isDriveTrain(urlSegments) {
  return urlSegments.length > 3 && (urlSegments[4] === Systems_1.TugSubsystem.RF || urlSegments[4] === Systems_1.TugSubsystem.LF || urlSegments[4] === Systems_1.TugSubsystem.RR || urlSegments[4] === Systems_1.TugSubsystem.LR);
}

exports.isDriveTrain = isDriveTrain;

function deviceAddress(address) {
  var newAddress;

  if (size(address) < 3) {
    throw new Error(address + ' is too short to be device address');
  } else if (address.segments.length === 3) {
    newAddress = cutTo(address, 3);
  } else {
    newAddress = cutTo(address, 3);
  }

  if (isStack(newAddress) || isTower(cutTo(newAddress, 3)) && size(newAddress) === 4 || isTug(cutTo(newAddress, 3)) && size(newAddress) === 4) {
    return newAddress;
  }

  throw new Error(address + ' does not contain a device address');
}

exports.deviceAddress = deviceAddress;