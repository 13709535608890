import { NumericalReading } from 'model/src/series/NumericalReading';
import React from 'react';
import styled from 'styled-components';

import { Label } from '../../neumorphic/Label';
import { BarChartDatum } from './PwmChart';

const Container = styled.div`
  box-sizing: border-box;
  padding: var(--neumorphic-grid-size);
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 100px;
`;

export default function DriveTrainPanel(props) {
  const chartData: BarChartDatum[] = [];
  let i = 0;
  props.pwm.pwm.forEach((pwmReading: NumericalReading) => {
    chartData.push({ id: i++ + '', datum: pwmReading.value });
  });
  return (
    <Container>
      <Label>ID: {props.url}</Label>
      <Label>Name: {props.datum.name}</Label>
      <Label>
        PWM:
        {props.url + ' ' + (chartData.length > 0)
          ? chartData[chartData.length - 1].datum
          : 0}
      </Label>
      <Label>RPS: {props.radiansPerSecond}</Label>
      {/* <PwmChart data={chartData} width={130} height={40} /> */}
    </Container>
  );
}
