import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

import ClassNames from '../../ClassNames';
import { InlineContainer } from './TabStyles';
import TabText from './TabText';

export type TabProps = {
  title: string;
};

const IndividualStyledTab = styled.div`
  white-space: nowrap;
  background-color: var(--windowBackground);
  height: var(--tabBarHeight);
  width: inherit;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--tabLabel_Active);

  &:active {
    background-color: var(--individualTabBackground_Active);
    color: var(--tabLabel_Active);
  }

  &:hover {
    background-color: var(--individualTabBackground_Hover);
  }
`;

export const SelectedTab = (props: PropsWithChildren<TabProps>) => {
  return (
    <InlineContainer>
      <IndividualStyledTab>
        <TabText className={ClassNames.bodyMedium}>{props.title}</TabText>
      </IndividualStyledTab>
    </InlineContainer>
  );
};

SelectedTab.defaultProps = {
  title: 'title text',
};
