import React, { Component } from 'react';
import FormItem from './FormItem';

type Props = {
  query: string;
  onChange: (data) => void;
};

class AddressSuggest extends Component<Props, {}> {
  render() {
    return (
      <FormItem
        label="Address"
        placeholder="Enter location..."
        readOnly={false}
        onChange={this.props.onChange}
        debounce={true}
        minLength={3}
      />
    );
  }
}

export default AddressSuggest;
