import styled from 'styled-components';

const TabText = styled.div`
  width: inherit;
  padding-left: 16px;
  padding-right: 16px;
  overflow: hidden;
  whitespace: nowrap;
  text-overflow: elipsis;
`;
export default TabText;
