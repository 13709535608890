import React from 'react';

export type GamepadBackgroundProps = {
  gamepadConnected: boolean;
};

GamepadBackground.defaultProps = {
  gamepadConnected: false,
};

export default function GamepadBackground(props: GamepadBackgroundProps) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="256"
      height="160"
      viewBox="0 0 1280.000000 799.000000"
      preserveAspectRatio="xMidYMid meet">
      <g
        transform="translate(0.000000,799.000000) scale(0.100000,-0.100000)"
        fill={props.gamepadConnected ? 'white' : 'var(--cloud-produce-grey)'}
        stroke="none">
        <path
          d="M2282 7979 c-163 -22 -360 -84 -507 -159 -90 -46 -90 -47 -93 -88 -3
-37 -12 -51 -80 -119 -247 -249 -601 -974 -855 -1748 -193 -589 -295 -1016
-442 -1845 -162 -917 -240 -1444 -287 -1955 -19 -212 -17 -672 5 -815 41 -276
101 -416 259 -615 215 -269 363 -402 543 -490 172 -84 375 -131 615 -142 512
-22 901 200 1099 628 89 194 193 517 361 1131 160 584 221 769 293 880 44 69
59 81 202 172 99 64 125 76 149 72 16 -3 81 -35 145 -71 280 -156 463 -198
770 -175 333 24 476 78 662 247 l91 83 1188 0 1188 0 91 -83 c186 -169 329
-223 662 -247 307 -23 478 16 760 171 70 38 140 72 155 75 24 4 50 -8 149 -72
151 -97 175 -120 224 -214 73 -140 121 -288 282 -875 162 -592 281 -961 364
-1125 150 -296 410 -489 765 -566 120 -26 467 -27 590 -1 283 59 460 158 686
382 310 307 409 486 461 835 21 141 24 614 5 815 -51 533 -125 1037 -288 1960
-142 807 -250 1257 -441 1840 -261 795 -642 1565 -879 1773 -49 43 -54 51 -54
89 0 41 -1 43 -65 78 -124 70 -306 133 -480 166 -91 18 -410 18 -530 0 -133
-19 -298 -56 -400 -88 l-90 -29 -5 -93 -5 -94 -130 -40 c-128 -39 -133 -40
-319 -45 -109 -2 -263 1 -360 8 -94 7 -186 14 -204 15 -18 1 -43 12 -55 23
-12 11 -35 25 -50 31 -41 16 -4020 15 -4050 0 -12 -6 -31 -20 -43 -30 -31 -29
-336 -52 -609 -46 -181 4 -220 8 -280 27 -38 12 -96 29 -127 38 l-57 16 -3 95
-3 95 -88 28 c-106 34 -231 62 -364 83 -122 19 -424 27 -521 14z"
        />
      </g>
    </svg>
  );
}
