import React, { Suspense } from 'react';

import Box from '../common/Box';
import BasePlate2x2 from './BasePlate2x2';
import CT290_420S from './CT290_420S';
import CT290_430S from './CT290_430S';
import F34_6Way from './F34_6Way';

export default function Pillar(props) {
  return (
    <group position={props.position}>
      <Suspense fallback={<Box />}>
        <BasePlate2x2
          position={[0, 0, 0]}
          rotation={[0, 0, 0]}
          color={props.color}
          opacity={props.opacity}
          transparent={props.transparent}
        />
      </Suspense>
      <Suspense fallback={<Box />}>
        <CT290_430S
          position={[0, 0, 0.635]}
          rotation={[0, Math.PI / 2, 0]}
          color={props.color}
          opacity={props.opacity}
          transparent={props.transparent}
        />
      </Suspense>
      <Suspense fallback={<Box />}>
        <CT290_420S
          position={[0, 0, 300.635]}
          rotation={[0, Math.PI / 2, 0]}
          color={props.color}
          opacity={props.opacity}
          transparent={props.transparent}
        />
      </Suspense>
      <Suspense fallback={<Box />}>
        <F34_6Way
          position={[0, 0, 525.635]}
          rotation={[0, 0, 0]}
          color={props.color}
          opacity={props.opacity}
          transparent={props.transparent}
        />
      </Suspense>
    </group>
  );
}

Pillar.defaultProps = {
  position: [0, 0, 0],
  rotation: [0, 0, 0],
  transparent: false,
  opacity: 1.0,
};
