/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import {
  combine,
  create,
  fullString,
  getAt,
} from 'model/src/common/CloudProduceAddressUtility';
import { TugSubsystem } from 'model/src/common/Systems';
import { SeriesType } from 'model/src/series/SeriesTypes';
import React, { useMemo, useRef } from 'react';
import * as THREE from 'three';

import { useFrame } from '@react-three/fiber';

import { useDataMap } from '../../../farmviewer/context/datamapcontext/useDataMap';
import LeftHandWheel from '../../tug/LeftHandWheel';
import RightHandWheel from '../../tug/RightHandWheel';
import { NEUTRAL_PWM, PWM_RANGE } from '../Trolley';

export default function Model({ ...props }) {
  //TODO(austin): rename
  const group = useRef<THREE.Group>();
  const wheelRotationRateReading = useDataMap(
    combine(props.url, create([SeriesType.ROTATION]))
  );
  const wheelRotationRate = wheelRotationRateReading.value;
  const wheel: {
    left?: boolean;
    right?: boolean;
    front?: boolean;
    rear?: boolean;
  } = useMemo(() => {
    const tugWheelPosition = getAt(props.url, -1);
    switch (tugWheelPosition) {
      case TugSubsystem.LF: {
        return { left: true, front: true };
      }
      case TugSubsystem.LR: {
        return { left: true, rear: true };
      }
      case TugSubsystem.RF: {
        return { right: true, front: true };
      }
      case TugSubsystem.RR: {
        return { right: true, rear: true };
      }
      default: {
        throw new Error('Tug wheel orientation unrecognized.');
      }
    }
  }, [props.url]);

  useFrame((state, delta) => {
    if (
      props.pwm &&
      props.pwm >= NEUTRAL_PWM - PWM_RANGE &&
      props.pwm <= NEUTRAL_PWM + PWM_RANGE &&
      props.pwm !== NEUTRAL_PWM
    ) {
      const radps = wheelRotationRate;
      const radpf = radps * delta;
      group.current!.rotation.x += radpf;
    }
  });
  const sidedness: number =
    (wheel.right && wheel.front) || (wheel.left && wheel.front) ? 0 : Math.PI;
  const rotation = [0, 0, sidedness];
  const position =
    wheel.left && wheel.front
      ? [46.6, -42.52, 4]
      : wheel.left && wheel.rear
      ? [46.6, 42.52, 4]
      : wheel.right && wheel.front
      ? [-46.6, -42.52, 4]
      : [-46.6, 42.52, 4];
  return (
    <group position={position}>
      <group ref={group} {...props} rotation={rotation} dispose={null}>
        {wheel.left && wheel.front && (
          <LeftHandWheel
            online={props.online}
            parentHovered={props.parentHovered}
            parentUnderInspection={props.parentUnderInspection}
          />
        )}
        {wheel.right && wheel.rear && (
          <LeftHandWheel
            online={props.online}
            parentHovered={props.parentHovered}
            parentUnderInspection={props.parentUnderInspection}
          />
        )}
        {wheel.left && wheel.rear && (
          <RightHandWheel
            online={props.online}
            parentHovered={props.parentHovered}
            parentUnderInspection={props.parentUnderInspection}
          />
        )}
        {wheel.right && wheel.front && (
          <RightHandWheel
            online={props.online}
            parentHovered={props.parentHovered}
            parentUnderInspection={props.parentUnderInspection}
          />
        )}
      </group>
    </group>
  );
}
