import React, { Context } from 'react';
import { DataMap } from 'services/src/pagedataintake/PageDataIntake';

import {
  InspectedContentProps,
  StaticInspectionProps,
} from '../../../../farm-ui/windows/InspectionWindow';
import {
  TowerOverlay,
  WaterTankOverlay,
} from '../../../../farm-ui/windows/ViewFiltersWindow';
import WindowType from '../../../../utopia/window/WindowTypes';
import { ControllerContextType } from '../controllercontext/ControllerContext';

export const DesktopContext = React.createContext<DesktopContextType>({
  dataMap: {} as DataMap,
  selectedUrl: 'UNSET',
  select: (url: string) => {},
  selectOverlay: (overlay: TowerOverlay | WaterTankOverlay | undefined) => {},
  openWindow: null,
  clearInspectionWindows: null,
  pushInspectionWindow: null,
  removeInspectionWindow: null,
  getParentUrl: (url: string, depth?: number) => '',
  updateContext: (
    newContextValue: DesktopContextType,
    currentContextValue: DesktopContextType
  ) => newContextValue,
  updateSelectedUrl: (url: string, contextValue: DesktopContextType) => {},
  setContext: contextValue => contextValue,
  modal: '',
  setModal: () => {},
  openInspectedContent: null,
  setOpenInspectedContent: null,
  openWindowMap: null,
  setOpenWindowMap: null,
  windowPropsMap: null,
  setWindowPropsMap: null,
}) as Context<DesktopContextType>;

export type DesktopContextType = {
  dataMap: DataMap;
  selectedUrl: string;
  selectedOverlay?: TowerOverlay | WaterTankOverlay;
  select: (
    url: string,
    controllerContext: ControllerContextType,
    desktopContext: DesktopContextType,
    operation?: string
  ) => void;
  selectOverlay: (
    overlay: TowerOverlay | WaterTankOverlay,
    currentContextValue: DesktopContextType
  ) => void;
  openWindow: ((id: WindowType, windowInfo: Object) => void) | null;
  clearInspectionWindows: ((desktopContext: DesktopContextType) => void) | null;
  pushInspectionWindow:
    | ((
        id: string,
        staticInspectionProps: StaticInspectionProps,
        inspectedContentProps: InspectedContentProps,
        currentContext: DesktopContextType
      ) => void)
    | null;
  removeInspectionWindow: ((id, currentContext) => void) | null;
  getParentUrl: (url: string, depth?: number) => string;
  updateContext: (
    newContextValue: DesktopContextType,
    currentContextValue: DesktopContextType
  ) => void;
  updateSelectedUrl: (
    selectedUrl: string,
    currentContextValue: DesktopContextType
  ) => void;
  setContext: (contextValue) => void;

  modal: string | null;
  setModal: any;
  openInspectedContent: any;
  setOpenInspectedContent: any;
  openWindowMap: any;
  setOpenWindowMap: any;
  windowPropsMap: any;
  setWindowPropsMap: any;
};
