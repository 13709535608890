import React from 'react';
import styled from 'styled-components';

import { cssVar } from '../../..';
import { Path } from './IconStyling';

const Svg = styled.svg`
  padding: 2.5px 0px 0px 1px;
  align: center;
  height: var(--controlBarIconHeight);
  width: var(--controlBarIconWidth);
`;

const UiHomeIcon = () => {
  return (
    <Svg
      viewBox={cssVar('--controlBarIconViewBox')}
      xmlns="http://www.w3.org/2000/svg">
      <Path
        id="Mask"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.9902 20H16.0002V13C16.0002 12.447 15.5522 12 15.0002 12H9.0002C8.4472 12 8.0002 12.447 8.0002 13V20H5.0002L5.0062 11.583L11.9982 4.43199L19.0002 11.624L18.9902 20ZM10.0002 20H14.0002V14H10.0002V20ZM20.4242 10.185L12.7152 2.30099C12.3382 1.91599 11.6622 1.91599 11.2852 2.30099L3.5752 10.186C3.2102 10.561 3.0002 11.085 3.0002 11.624V20C3.0002 21.103 3.8472 22 4.8882 22H9.0002H15.0002H19.1112C20.1522 22 21.0002 21.103 21.0002 20V11.624C21.0002 11.085 20.7902 10.561 20.4242 10.185Z"
      />
      <mask
        id="mask0_0_783"
        mask-type="luminance"
        maskUnits="userSpaceOnUse"
        x="3"
        y="2"
        width="19"
        height="20">
        <Path
          id="Mask_2"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18.9902 20H16.0002V13C16.0002 12.447 15.5522 12 15.0002 12H9.0002C8.4472 12 8.0002 12.447 8.0002 13V20H5.0002L5.0062 11.583L11.9982 4.43199L19.0002 11.624L18.9902 20ZM10.0002 20H14.0002V14H10.0002V20ZM20.4242 10.185L12.7152 2.30099C12.3382 1.91599 11.6622 1.91599 11.2852 2.30099L3.5752 10.186C3.2102 10.561 3.0002 11.085 3.0002 11.624V20C3.0002 21.103 3.8472 22 4.8882 22H9.0002H15.0002H19.1112C20.1522 22 21.0002 21.103 21.0002 20V11.624C21.0002 11.085 20.7902 10.561 20.4242 10.185Z"
        />
      </mask>
    </Svg>
  );
};

export default UiHomeIcon;
