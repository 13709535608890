import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

import WindowContent from './components/WindowContent';
import { HeaderButtonType } from './components/WindowHeaderButton';

export type StaticWindowProps = {
  className?: string;
  url?: string;
  windowType?: string;
  title: string;
  onClick: () => void;
  initialWindowPosition: any;
  headerButtons: HeaderButtonType[];
  headerButtonOnClicks: (() => void)[];
  minHeight: string;
  minWidth: string;
  selected: boolean;
};

const StaticWindowContainer = styled.div`
  position: absolute;
  pointer-events: all;
  z-index: 201;
  ${props =>
    props.initialWindowPosition.right
      ? 'right: ' + props.initialWindowPosition.right + 'px;'
      : ''}
  ${props =>
    props.initialWindowPosition.top
      ? 'top: ' + props.initialWindowPosition.top + 'px;'
      : ''}
  ${props =>
    props.initialWindowPosition.left
      ? 'left: ' + props.initialWindowPosition.left + 'px;'
      : ''}
  ${props =>
    props.initialWindowPosition.bottom
      ? 'bottom: ' + props.initialWindowPosition.bottom + 'px;'
      : ''}
  background-color: var(--windowBackground_Regular);
  border-radius: var(--windowBorderRadius);
`;

const StaticWindow = (props: PropsWithChildren<StaticWindowProps>) => {
  // const defaultPosition =
  //   props.initialWindowPosition.x && props.initialWindowPosition.y
  //     ? props.initialWindowPosition
  //     : undefined;

  return (
    <StaticWindowContainer initialWindowPosition={props.initialWindowPosition}>
      <WindowContent onClick={props.onClick}>{props.children}</WindowContent>
    </StaticWindowContainer>
  );
};

export default StaticWindow;

StaticWindow.defaultProps = {
  title: 'window title',
  onDrag: null,
  initialWindowPosition: { x: 0, y: 0 },
  headerButtons: [],
  headerButtonOnClicks: [() => {}],
  minHeight: '200px',
  minWidth: '200px',
  onClick: () => {},
  selected: false,
};
